import { APP_CONFIG } from '@constants';
import { encrypt, decrypt } from '@helpers/Crypto';
import { getTimezoneOffset } from '@helpers/Utility';

const upload = require('superagent');

export const UPDATE_FIELD = 'editProfile/UPDATE_FIELD';
export const UPDATE_ADDUSER_FIELD = 'editProfile/UPDATE_ADDUSER_FIELD';
export const UPDATE_PAGE = 'editProfile/UPDATE_PAGE';
export const CLEAR_FORM = 'editProfile/CLEAR_FORM';
export const CLEAR_STATE = 'editProfile/CLEAR_STATE';
export const UPDATE_USER_LIST_FIELD = 'editProfile/UPDATE_USER_LIST_FIELD';
export const UPDATE_ACCOUNT_LIST_FIELD = 'editProfile/UPDATE_ACCOUNT_LIST_FIELD';
export const UPDATE_LIST_FIELD = 'editProfile/UPDATE_LIST_FIELD';
export const UPDATE_LIST_FIELDS = 'editProfile/UPDATE_LIST_FIELDS';
export const UPDATE_INTEGRATION_FIELD = 'editProfile/UPDATE_INTEGRATION_FIELD';
export const UPDATE_INTEGRATION_FIELDS = 'editProfile/UPDATE_INTEGRATION_FIELDS';
export const UPDATE_ACCOUNTBALANCE_FIELDS = 'editProfile/UPDATE_ACCOUNTBALANCE_FIELDS';

export const CLEAR_INTEGRATION_FORM = 'editProfile/CLEAR_INTEGRATION_FORM';
export const CLEAR_WEBINTEGRATION_FORM = 'editProfile/CLEAR_WEBINTEGRATION_FORM';

export const UPLOAD_IMAGE_REQUESTED = 'upload/UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_IMAGE_SUCCESS = 'upload/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'upload/UPLOAD_IMAGE_FAILURE';

export const GET_USER_PROFILE_REQUESTED = 'GET_USER_PROFILE_REQUESTED';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILESUCCESS = 'GET_USER_PROFILESUCCESS';
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';

export const UPDATE_USER_PROFILE_REQUESTED = 'UPDATE_USER_PROFILE_REQUESTED';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE';

export const INDUSTRY_LIST_REQUESTED = 'INDUSTRY_LIST_REQUESTED';
export const INDUSTRY_LIST_SUCCESS = 'INDUSTRY_LIST_SUCCESS';
export const INDUSTRY_LIST_FAILURE = 'INDUSTRY_LIST_FAILURE';

export const GET_ACTIVE_PLAN_REQUESTED = 'GET_ACTIVE_PLAN_REQUESTED';
export const GET_ACTIVE_PLAN_SUCCESS = 'GET_ACTIVE_PLAN_SUCCESS';
export const GET_ACTIVE_PLAN_FAILURE = 'GET_ACTIVE_PLAN_FAILURE';

export const SAVE_INTEGRATION_DATA_REQUESTED = 'SAVE_INTEGRATION_DATA_REQUESTED';
export const SAVE_INTEGRATION_DATA_SUCCESS = 'SAVE_INTEGRATION_DATA_SUCCESS';
export const SAVE_INTEGRATION_DATA_FAILURE = 'SAVE_INTEGRATION_DATA_FAILURE';

export const GET_INTEGRATION_DATA_REQUESTED = 'GET_INTEGRATION_DATA_REQUESTED';
export const GET_INTEGRATION_DATA_SUCCESS = 'GET_INTEGRATION_DATA_SUCCESS';
export const GET_INTEGRATION_DATA_FAILURE = 'GET_INTEGRATION_DATA_FAILURE';
export const GET_FIREBASE_INTEGRATION_DATA_SUCCESS = 'GET_FIREBASE_INTEGRATION_DATA_SUCCESS';
export const GET_CLEVER_TAP_INTEGRATION_DATA_SUCCESS = 'GET_CLEVER_TAP_INTEGRATION_DATA_SUCCESS';
export const GET_XOXODAY_INTEGRATION_DATA_SUCCESS = 'GET_XOXODAY_INTEGRATION_DATA_SUCCESS';

export const GET_USER_REQUESTED = 'get/GET_USER_REQUESTED';
export const GET_USER_SUCCESS = 'get/GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'get/GET_USER_FAILURE';

export const DELETE_USER_REQUESTED = 'get/DELETE_USER_REQUESTED';
export const DELETE_USER_SUCCESS = 'get/DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'get/DELETE_USER_FAILURE';

export const UPDATE_FIELDS = 'editProfile/UPDATE_FIELDS';


export const GET_ACCOUNT_BALANCE_REQUESTED = 'GET_ACCOUNT_BALANCE_REQUESTED';
export const GET_ACCOUNT_BALANCE_SUCCESS = 'GET_ACCOUNT_BALANCE_SUCCESS';
export const GET_ACCOUNT_BALANCE_FAILURE = 'GET_ACCOUNT_BALANCE_FAILURE';


export const GET_AMOUNT_VALUES_REQUESTED = 'GET_AMOUNT_VALUES_REQUESTED';
export const GET_AMOUNT_VALUES_SUCCESS = 'GET_AMOUNT_VALUES_SUCCESS';
export const GET_AMOUNT_VALUES_FAILURE = 'GET_AMOUNT_VALUES_FAILURE';

export const SAVE_AMOUNT_REQUESTED = 'SAVE_AMOUNT_REQUESTED';
export const SAVE_AMOUNT_SUCCESS = 'SAVE_AMOUNT_SUCCESS';
export const SAVE_AMOUNT_FAILURE = 'SAVE_AMOUNT_FAILURE';

export const UPDATE_FIREBASE_INTEGRATION_FIELD = 'editProfile/UPDATE_FIREBASE_INTEGRATION_FIELD';
export const UPDATE_CLEVER_TAP_INTEGRATION_FIELD = 'editProfile/UPDATE_CLEVER_TAP_INTEGRATION_FIELD';
export const CLEAR_FIREBASE_INTEGRATION_FORM = 'editProfile/CLEAR_FIREBASE_INTEGRATION_FORM';
export const CLEAR_CLEVER_TAP_INTEGRATION_FORM = 'editProfile/CLEAR_CLEVER_TAP_INTEGRATION_FORM';
export const UPDATE_XOXODAY_INTEGRATION_FIELD = 'editProfile/UPDATE_XOXODAY_INTEGRATION_FIELD';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  initialData: {
    activeTab: 'edit_profile',
  },
  accountBalance: [],
  profileData: {
    fullName: '',
    fullNameError: '',
    orgName: '',
    orgNameError: '',
    emailId: '',
    emailIdError: '',
    orgSize: '',
    industryName: '',
    industryId: '',
    mobileNum: '',
    mobileNumCompany: '',
    mobileNumError: '',
    mobileNumCompanyError: '',
    profileUrl: '',
    updatedTS: '',
    orgAddress: '',
    orgAddressError: '',
    apiKey: '',
    countryCode: '+91',
    countryCodeCompany: '+91',
    country: '',
    state: '',
    stateError: '',
    timezone: '',
    changePassword: '',
    team: '',
    teamError: '',
  },
  isErrorOccur: '',
  userManagement: {
    addUser: {
      name: '',
      email: '',
      password: '',
      userTeam: '',
      // organizationName: '',
      // organisationNameError: '',
      nameError: '',
      emailError: '',
      passwordError: '',
      userTeamError: '',
      id: 0,
      countryCode: '+91',
      mobileNum: '',
      mobileNumError: '',
    }
  },
  accountBillingAmount: {
    addUser: {
      nameSegment: 0
    }
  },
  billingData: {
    name: '',
    price: '',
    trialAccount: 0,
    planDetails: [],
    updatedTS: '',
    amount: '',
    currency: ''
  },
  options: {
    enterpriseSize: [],
    industrySize: []
  },
  amountValuesData: {
    amount: 0,
    lastBalance: 0
  },
  amountSave: {
    offerBalanceId: 0
  },
  accountbalanceData: {
    pageNo: 0,
    itemsPerPage: 5,
    title: '',
    sortBy: 'updatedTS',
    orderBy: 'desc',
    searchKey: '',
    headers: [
      {
        title: 'Date',
        slug: 'date',
        isSortable: true,
        isVisible: true,
        tooltip: true,
        center: false,
        sortByOrder: 'desc',
        width: 120,
      },
      {
        title: 'Amount',
        slug: 'amount',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: false,
        sortByOrder: 'desc',
        width: 150,
      },
      {
        title: 'Method',
        slug: 'method',
        isSortable: false,
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: '',
        width: 70,
      },
      {
        title: 'Transaction id',
        slug: 'transactionid',
        isSortable: false,
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: '',
        width: 100,
      },
      {
        title: 'Transaction type credit',
        slug: 'transactiontypecredit',
        isSortable: false,
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: '',
        width: 190,
      },
    ]
  },
  transactionData: {
    pageNo: 0,
    itemsPerPage: 5,
    title: '',
    sortBy: 'updatedTS',
    orderBy: 'desc',
    searchKey: '',
    headers: [
      {
        title: 'Date',
        slug: 'date',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: false,
        sortByOrder: '',
        width: 180
      },
      {
        title: 'Amount',
        slug: 'amount',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: false,
        sortByOrder: '',
        width: 100,
      },
      {
        title: 'Plan Name',
        slug: 'planName',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: false,
        sortByOrder: 'desc',
        width: 150,
      },
      {
        title: 'Invoice',
        slug: 'actions',
        isSortable: false,
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: '',
        width: 100,
      },
    ]
  },
  userAccountList: {
    pageNo: 0,
    itemsPerPage: 6,
    sortBy: 'createdTS',
    orderBy: 'desc',
    searchKey: '',
    startDate: new Date().setDate(new Date().getDate() - 6),
    endDate: new Date().getTime(),
    activePage: 1,
    headers: [
      {
        title: 'Users Name',
        slug: 'userName',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: false,
        sortByOrder: '',
        width: 80
      },
      {
        title: 'E-mail Id',
        slug: 'emailId',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: false,
        sortByOrder: '',
        width: 120,
      },
      // {
      //   title: 'Date',
      //   slug: 'date',
      //   isSortable: true,
      //   isVisible: true,
      //   tooltip: true,
      //   center: false,
      //   sortByOrder: 'desc',
      //   width: 210,
      // },
      {
        title: 'Team',
        slug: 'team',
        isSortable: false,
        isVisible: true,
        tooltip: true,
        center: false,
        sortByOrder: 'desc',
        width: 80,
      },
      {
        title: 'Action',
        slug: 'actions',
        isSortable: false,
        isVisible: false,
        tooltip: true,
        center: false,
        sortByOrder: '',
        width: 120,
      },
      {
        title: 'Actions',
        slug: 'actions',
        isSortable: false,
        isVisible: false,
        tooltip: true,
        center: true,
        sortByOrder: '',
        width: 120,
      },
    ]
  },


  accountBalanceData: {
    crruentBalance: '',
    totalNumber: '',
    nameSegment: '',
    nameSegmentError: ''
  },
  integrationData: {
    accountSid: '',
    accountSidError: '',
    messagingServiceSid: '',
    messagingServiceSidError: '',
    mailId: '',
    mailIdError: '',
    authToken: '',
    authTokenError: '',
    phoneNum: '',
    phoneNumError: '',
    countryCode: '+91',
    sendGridApiKey: '',
    sendGridApiKeyError: '',
  },
  integrationDatas: {
    serverDescription: '',
    serverDescriptionError: '',
    serverName: '',
    serverNameError: '',
    Port: '',
    PortError: '',
    connectionSecurity: '',
    connectionSecurityError: '',
    authentication: '',
    authenticationError: '',
    userName: '',
    userNameError: '',
  },
  firebaseIntegrationData: {
    firebaseDynamicLink: '',
    webRedirectionLink: '',
    serverKey: '',
    serverKeyError: ''
  },
  cleverTapIntegrationData: {
    accountId: '',
    userPasscode: '',
  },
  xoxodayIntegrationData: {
    clientId: '',
    secretId: '',
    accessToken: {
    },
    tokenError: '',
    clientError: '',
    secretError: ''
  },
  tableData: {},
  accountList: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPLOAD_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_USER_PROFILE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_USER_PROFILE_SUCCESS: {
      console.log('action.result.value:', action.result.user);
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        // profileData: action.result.user
        profileData: {
          fullName: action.result.user.fullName,
          orgName: action.result.user.orgName,
          emailId: decrypt(action.result.user.emailId),
          orgSize: action.result.user.orgSize,
          industryName: action.result.user.industryName,
          industryId: action.result.user.industryId !== 0 ? action.result.user.industryId : '',
          mobileNum: action.result.user.mobileNum,
          mobileNumCompany: action.result.user.mobileNumCompany,
          profileUrl: action.result.user.profileUrl,
          updatedTS: action.result.user.updatedTS,
          orgAddress: action.result.user.orgAddress,
          apiKey: action.result.user.apiKey,
          countryCode: action.result.user.countryCode ? action.result.user.countryCode : '+91',
          countryCodeCompany: action.result.user.countryCodeCompany ? action.result.user.countryCodeCompany : '+91',
          country: action.result.user.country ? action.result.user.country : 'India',
          state: action.result.user.state,
          team: action.result.user.team,
          changePassword: action.result.user.changePassword,
          timezone: `${action.result.user.timezone} (UTC${getTimezoneOffset(action.result.user.timezone)})`
        }
      };
    }
    case GET_USER_PROFILESUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_USER_PROFILE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_USER_PROFILE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_USER_PROFILE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case INDUSTRY_LIST_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case INDUSTRY_LIST_SUCCESS:
    {
      const enterprise = action.result.companySize;
      const enterpriseSizeOptions = [];
      for (let i = 0; i < enterprise.length; i += 1) {
        enterpriseSizeOptions.push({ title: enterprise[i].lable, value: enterprise[i].value });
      }

      const industryList = action.result.industry;
      const industrySizeOptions = [];
      for (let i = 0; i < industryList.length; i += 1) {
        industrySizeOptions.push({ title: industryList[i].name, value: industryList[i].id });
      }

      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        options: { ...state.options, ...{ enterpriseSize: enterpriseSizeOptions, industrySize: industrySizeOptions } },
      };
    }
    case INDUSTRY_LIST_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_INTEGRATION_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_INTEGRATION_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        integrationData: {
          accountSid: action.result.integrationData.accountSid,
          messagingServiceSid: action.result.integrationData.messagingServiceSid,
          mailId: action.result.integrationData.mailId,
          authToken: action.result.integrationData.authToken,
          phoneNum: action.result.integrationData.phoneNum,
          countryCode: action.result.integrationData.countryCode ? action.result.integrationData.countryCode : '+91',
          sendGridApiKey: action.result.integrationData.sendGridApiKey,
        }
      };
    }
    case GET_FIREBASE_INTEGRATION_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        firebaseIntegrationData: {
          firebaseDynamicLink: action.result.integrationData.firebaseDynamicLink,
          webRedirectionLink: action.result.integrationData.webRedirectionLink,
          serverKey: action.result.integrationData.serverKey,
          serverKeyError: ''
        }
      };
    }
    case GET_CLEVER_TAP_INTEGRATION_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        cleverTapIntegrationData: {
          accountId: action.result.integrationData.accountId,
          userPasscode: action.result.integrationData.userPasscode
        }
      };
    }
    case GET_XOXODAY_INTEGRATION_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        xoxodayIntegrationData: {
          clientId: action.result.integrationData.xoxoClientId,
          secretId: action.result.integrationData.xoxoSecretId,
          accessToken: action.result.integrationData.accessToken
        }
      };
    }
    case GET_INTEGRATION_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case SAVE_INTEGRATION_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_INTEGRATION_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_INTEGRATION_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_FIELD: {
      const profileData = { ...state.profileData, ...action.payload };
      return { ...state, profileData };
    }

    case UPDATE_INTEGRATION_FIELD: {
      const integrationData = { ...state.integrationData, ...action.payload };
      return { ...state, integrationData };
    }

    case UPDATE_FIREBASE_INTEGRATION_FIELD: {
      const firebaseIntegrationData = { ...state.firebaseIntegrationData, ...action.payload };
      return { ...state, firebaseIntegrationData };
    }

    case UPDATE_CLEVER_TAP_INTEGRATION_FIELD: {
      const cleverTapIntegrationData = { ...state.cleverTapIntegrationData, ...action.payload };
      return { ...state, cleverTapIntegrationData };
    }

    case UPDATE_XOXODAY_INTEGRATION_FIELD: {
      const xoxodayIntegrationData = { ...state.xoxodayIntegrationData, ...action.payload };
      return { ...state, xoxodayIntegrationData };
    }

    case UPDATE_ACCOUNTBALANCE_FIELDS: {
      console.log('payload', action.payload);
      const accountBalanceData = { ...state.accountBalanceData, ...action.payload };
      return { ...state, accountBalanceData };
    }

    case UPDATE_INTEGRATION_FIELDS: {
      const integrationDatas = { ...state.integrationDatas, ...action.payload };
      return { ...state, integrationDatas };
    }

    case UPDATE_ADDUSER_FIELD: {
      const addUser = { ...state.userManagement.addUser, ...action.payload };
      const userManagement = { ...state.userManagement, addUser };
      return { ...state, userManagement };
    }
    case UPDATE_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case GET_ACTIVE_PLAN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_ACTIVE_PLAN_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        billingData: {
          name: action.result.plan.name,
          price: action.result.plan.planPrice,
          trialAccount: action.result.plan.noOfDaysLeft,
          planDetails: [
            `${action.result.plan.planDetails[0].maUsers} MAUs`,
            `${action.result.plan.planDetails[0].noOfEvents} Events`,
            action.result.plan.planDetails[0].description
          ],
          updatedTS: action.result.plan.updatedTS,
          amount: action.result.plan.amount,
          currency: action.result.plan.currency,
        }
      };
    }
    case GET_ACTIVE_PLAN_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case CLEAR_FORM: {
      const addUser = {
        name: '',
        email: '',
        password: '',
        userTeam: '',
        nameError: '',
        emailError: '',
        passwordError: '',
        userTeamError: '',
        id: 0,
        countryCode: '',
        mobileNum: '',
        mobileNumError: '',
      };
      const userManagement = { ...state.userManagement, addUser };
      return { ...state, userManagement };
    }
    case CLEAR_INTEGRATION_FORM: {
      const integrationData = {
        accountSid: '',
        accountSidError: '',
        messagingServiceSid: '',
        messagingServiceSidError: '',
        mailId: '',
        mailIdError: '',
        authToken: '',
        authTokenError: '',
        phoneNum: '',
        phoneNumError: '',
        countryCode: '+91',
        sendGridApiKey: '',
        sendGridApiKeyError: '',
      };
      return { ...state, integrationData };
    }
    case CLEAR_FIREBASE_INTEGRATION_FORM: {
      const firebaseIntegrationData = {
        firebaseDynamicLink: '',
        webRedirectionLink: '',
        serverKey: '',
        serverKeyError: ''
      };
      return { ...state, firebaseIntegrationData };
    }
    case CLEAR_CLEVER_TAP_INTEGRATION_FORM: {
      const cleverTapIntegrationData = {
        accountId: '',
        userPasscode: '',
      };
      return { ...state, cleverTapIntegrationData };
    }
    case CLEAR_WEBINTEGRATION_FORM: {
      const integrationDatas = {
        serverDescription: '',
        serverDescriptionError: '',
        serverName: '',
        serverNameError: '',
        Port: '',
        PortError: '',
        connectionSecurity: '',
        connectionSecurityError: '',
        authentication: '',
        authenticationError: '',
        userName: '',
        userNameError: '',
      };
      return { ...state, integrationDatas };
    }
    case UPDATE_USER_LIST_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }

    case UPDATE_ACCOUNT_LIST_FIELD: {
      const accountList = { ...state.accountList, ...action.payload };
      return { ...state, accountList };
    }

    case UPDATE_LIST_FIELD: {
      const userAccountList = { ...state.userAccountList, ...action.payload };
      return { ...state, userAccountList };
    }

    case UPDATE_LIST_FIELDS: {
      const accountbalanceData = { ...state.accountbalanceData, ...action.payload };
      return { ...state, accountbalanceData };
    }

    case DELETE_USER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DELETE_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_USER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        userManagement: {
          addUser: {
            name: action.result.accountDetails.name,
            email: decrypt(action.result.accountDetails.email),
            // password: decrypt(action.result.accountDetails.password),
            userTeam: action.result.accountDetails.organizationName,
            id: action.result.accountDetails.id,
            nameError: '',
            emailError: '',
            passwordError: '',
            userTeamError: '',
            countryCode: action.result.accountDetails.countryCode,
            mobileNum: action.result.accountDetails.mobileNum,
            mobileNumError: '',
          }
        },
      };
    }
    case GET_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_ACCOUNT_BALANCE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_ACCOUNT_BALANCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        transactionHistory: action.result && action.result.transactionHistoryDTO,
      };
    }
    case GET_ACCOUNT_BALANCE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_AMOUNT_VALUES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_AMOUNT_VALUES_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        // transactionHistory: action.result && action.result.transactionHistoryDTO,
      };
    }
    case GET_AMOUNT_VALUES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case SAVE_AMOUNT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_AMOUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        // transactionHistory: action.result && action.result.transactionHistoryDTO,
      };
    }
    case SAVE_AMOUNT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_FIELDS: {
      state = state
        ? {
          ...state,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case CLEAR_STATE: {
      // const addUser = {
      //   nameSegment: ''
      // };
      const accountBalanceData = { ...state.accountBalanceData, nameSegment: '' };
      console.log('accountBillingAmount', accountBalanceData, state);

      return { ...state, accountBalanceData };
    }
    default:
      return state;
  }
};

export const updateField = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updateIntegrationField = (payload) => {
  return {
    type: UPDATE_INTEGRATION_FIELD,
    payload,
  };
};

export const updateFirebaseIntegrationField = (payload) => {
  return {
    type: UPDATE_FIREBASE_INTEGRATION_FIELD,
    payload,
  };
};

export const updateCleverTapIntegrationField = (payload) => {
  return {
    type: UPDATE_CLEVER_TAP_INTEGRATION_FIELD,
    payload,
  };
};

export const updateXoxodayIntegrationField = (payload) => {
  return {
    type: UPDATE_XOXODAY_INTEGRATION_FIELD,
    payload,
  };
};

export const updateAccountBalanceField = (payload) => {
  return {
    type: UPDATE_ACCOUNTBALANCE_FIELDS,
    payload,
  };
};

export const updateIntegrationFields = (payload) => {
  return {
    type: UPDATE_INTEGRATION_FIELDS,
    payload,
  };
};

export const updateAddUserField = (payload) => {
  return {
    type: UPDATE_ADDUSER_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELDS,
    payload,
  };
};

export const uploadimage = (file, folderName) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/gamification/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
      .field('folderName', folderName),
  };
};

export const getUserProfile = () => {
  return {
    types: [GET_USER_PROFILE_REQUESTED, GET_USER_PROFILE_SUCCESS, GET_USER_PROFILE_FAILURE],
    promise: client => client.get('getUserProfile'),
  };
};

export const updateUserProfile = (data) => {
  data = {
    ...data,
    emailId: encrypt(data.emailId)
  };
  return {
    types: [UPDATE_USER_PROFILE_REQUESTED, UPDATE_USER_PROFILE_SUCCESS, UPDATE_USER_PROFILE_FAILURE],
    promise: client => client.post('updateUserProfile', {
      data,
    }),
  };
};

export const uploadSecretKey = (file) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_ACCOUNT_HOST_NAME}/OrgUser/upload/config/file`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('file', file)
      .field('folderName', 'firebase/'),
  };
};

export const saveIntegration = (data) => {
  return {
    types: [SAVE_INTEGRATION_DATA_REQUESTED, SAVE_INTEGRATION_DATA_SUCCESS, SAVE_INTEGRATION_DATA_FAILURE],
    promise: client => client.post('saveIntegration', {
      data,
    }),
  };
};

export const saveAmount = (data) => {
  return {
    types: [SAVE_AMOUNT_REQUESTED, SAVE_AMOUNT_SUCCESS, SAVE_AMOUNT_FAILURE],
    promise: client => client.post(`saveAmount?offerBalanceId=${data.offerBalanceId}`, {
      data,
    }),
  };
};

export const getIntegrationData = () => {
  return {
    types: [GET_INTEGRATION_DATA_REQUESTED, GET_INTEGRATION_DATA_SUCCESS, GET_INTEGRATION_DATA_FAILURE],
    promise: client => client.get('getIntegrationData'),
  };
};

export const getFirebaseIntegrationData = () => {
  return {
    types: [GET_INTEGRATION_DATA_REQUESTED, GET_FIREBASE_INTEGRATION_DATA_SUCCESS, GET_INTEGRATION_DATA_FAILURE],
    promise: client => client.get('getIntegrationData?type=3'),
  };
};

export const getCleverTapIntegrationData = () => {
  return {
    types: [GET_INTEGRATION_DATA_REQUESTED, GET_CLEVER_TAP_INTEGRATION_DATA_SUCCESS, GET_INTEGRATION_DATA_FAILURE],
    promise: client => client.get('getIntegrationData?type=4'),
  };
};

export const getXoxodayIntegrationData = () => {
  return {
    types: [GET_INTEGRATION_DATA_REQUESTED, GET_XOXODAY_INTEGRATION_DATA_SUCCESS, GET_INTEGRATION_DATA_FAILURE],
    promise: client => client.get('getIntegrationData?type=5'),
  };
};

export const getIndustryList = () => {
  return {
    types: [INDUSTRY_LIST_REQUESTED, INDUSTRY_LIST_SUCCESS, INDUSTRY_LIST_FAILURE],
    promise: client => client.get('getIndustryList')
  };
};

export const getActivePlan = () => {
  return {
    types: [GET_ACTIVE_PLAN_REQUESTED, GET_ACTIVE_PLAN_SUCCESS, GET_ACTIVE_PLAN_FAILURE],
    promise: client => client.get('getActivePlan'),
  };
};

export const createUser = (data) => {
  return {
    types: [UPDATE_USER_PROFILE_REQUESTED, UPDATE_USER_PROFILE_SUCCESS, UPDATE_USER_PROFILE_FAILURE],
    promise: client => client.post('createUser', {
      data,
    }),
  };
};

export const clearForm = () => {
  return {
    type: CLEAR_FORM,
  };
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const clearIntegrationForm = () => {
  return {
    type: CLEAR_INTEGRATION_FORM,
  };
};

export const clearFirebaseIntegrationForm = () => {
  return {
    type: CLEAR_FIREBASE_INTEGRATION_FORM,
  };
};

export const clearCleverTapIntegrationForm = () => {
  return {
    type: CLEAR_CLEVER_TAP_INTEGRATION_FORM,
  };
};

export const clearwebintegrationform = () => {
  return {
    type: CLEAR_WEBINTEGRATION_FORM,
  };
};


export const getUserManagementList = (data) => {
  return {
    types: [GET_USER_PROFILE_REQUESTED, GET_USER_PROFILESUCCESS, GET_USER_PROFILE_FAILURE],
    promise: client => client.get(
      `getUserManagementList/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&sortType=${
        data.orderBy}&startDate=${data.startDate}&endDate=${data.endDate}`
    ),
  };
};

export const updateUserList = (payload) => {
  return {
    type: UPDATE_USER_LIST_FIELD,
    payload
  };
};

export const updateAccountList = (payload) => {
  return {
    type: UPDATE_ACCOUNT_LIST_FIELD,
    payload
  };
};

export const updateUserPage = (payload) => {
  return {
    type: UPDATE_LIST_FIELD,
    payload
  };
};

export const updateAccountBalancePage = (payload) => {
  return {
    type: UPDATE_LIST_FIELDS,
    payload
  };
};

export const disableUserAccount = (data) => {
  return {
    types: [UPDATE_USER_PROFILE_REQUESTED, UPDATE_USER_PROFILE_SUCCESS, UPDATE_USER_PROFILE_FAILURE],
    promise: client => client.post(`disableUserAccount?userId=${data}`),
  };
};

export const deleteUser = (data) => {
  return {
    types: [DELETE_USER_REQUESTED, DELETE_USER_SUCCESS, DELETE_USER_FAILURE],
    promise: client => client.post(`deleteUser?userId=${data}`),
  };
};

export const getUserDetails = (data) => {
  return {
    types: [GET_USER_REQUESTED, GET_USER_SUCCESS, GET_USER_FAILURE],
    promise: client => client.get(`getUserDetails?userId=${data}`),
  };
};

export const editUser = (data) => {
  return {
    types: [UPDATE_USER_PROFILE_REQUESTED, UPDATE_USER_PROFILE_SUCCESS, UPDATE_USER_PROFILE_FAILURE],
    promise: client => client.post('editUser', {
      data,
    }),
  };
};

export const getAccountBalance = (data) => {
  return {
    types: [GET_ACCOUNT_BALANCE_REQUESTED, GET_ACCOUNT_BALANCE_SUCCESS, GET_ACCOUNT_BALANCE_FAILURE],
    promise: client => client.get(`getAccountBalance/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.orderBy}&sortType=${data.sortBy}&startDate=${data.startDate}&endDate=${data.endDate}`)
  };
};

export const getAmountValues = (data) => {
  return {
    types: [GET_ACCOUNT_BALANCE_REQUESTED, GET_ACCOUNT_BALANCE_SUCCESS, GET_ACCOUNT_BALANCE_FAILURE],
    promise: client => client.get(`getAmountValues?searchKey=${data.searchKey}&sortBy=${data.orderBy}&sortType=${data.sortBy}`)
  };
};
