/* eslint-disable keyword-spacing */
import { APP_CONFIG } from '@constants';
import { getTimezoneDate, getTimezoneOffset } from '@helpers/Utility';
import { DateTime } from 'luxon';

const upload = require('superagent');

export const UPDATE_CUSTOMIZE_FIELD = 'UPDATE_CUSTOMIZE_FIELD';
export const UPDATE_SCHEDULE_FIELD = 'UPDATE_SCHEDULE_FIELD';

export const SAVE_CONFIG_REQUESTED = 'save/SAVE_CONFIG_REQUESTED';
export const SAVE_CONFIG_SUCCESS = 'save/SAVE_CONFIG_SUCCESS';
export const SAVE_CONFIG_FAILURE = 'save/SAVE_CONFIG_FAILURE';

export const GET_CONFIG_REQUESTED = 'get/GET_CONFIG_REQUESTED';
export const GET_CONFIG_SUCCESS = 'get/GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'get/GET_CONFIG_FAILURE';

export const GET_CONFIG_RULE_REQUESTED = 'get/GET_CONFIG_RULE_REQUESTED';
export const GET_CONFIG_RULE_SUCCESS = 'get/GET_CONFIG_RULE_SUCCESS';
export const GET_CONFIG_RULE_FAILURE = 'get/GET_CONFIG_RULE_FAILURE';

export const GET_OFFER_REQUESTED = 'get/GET_OFFER_REQUESTED';
export const GET_OFFER_SUCCESS = 'get/GET_OFFER_SUCCESS';
export const GET_OFFER_FAILURE = 'get/GET_OFFER_FAILURE';

export const SAVE_RULE_REQUESTED = 'save/SAVE_RULE_REQUESTED';
export const SAVE_RULE_SUCCESS = 'save/SAVE_RULE_SUCCESS';
export const SAVE_RULE_FAILURE = 'save/SAVE_RULE_FAILURE';

export const SAVE_BADGE_CONFIG_REQUESTED = 'save/SAVE_BADGE_CONFIG_REQUESTED';
export const SAVE_BADGE_CONFIG_SUCCESS = 'save/SAVE_BADGE_CONFIG_SUCCESS';
export const SAVE_BADGE_CONFIG_FAILURE = 'save/SAVE_BADGE_CONFIG_FAILURE';

export const GET_BADGE_REQUESTED = 'GET_BADGE_REQUESTED';
export const GET_BADGE_SUCCESS = 'GET_BADGE_SUCCESS';
export const GET_BADGE_FAILURE = 'GET_BADGE_FAILURE';

export const GET_SEGEMENT_REQUESTED = 'GET_SEGEMENT_REQUESTED';
export const GET_SEGEMENT_SUCCESS = 'GET_SEGEMENT_SUCCESS';
export const GET_SEGEMENT_FAILURE = 'GET_SEGEMENT_FAILURE';

export const UPDATE_FIELD = 'badgesConfig/UPDATE_FIELD';
export const UPDATE_PAGE = 'badgesConfig/UPDATE_PAGE';
export const CLEAR_FORM = 'badgesConfig/CLEAR_FORM';
export const UPDATE_OPTIONS = 'badgesConfig/UPDATE_OPTIONS';
export const UPDATE_WINNING_QUADRANT = 'badgesConfig/UPDATE_WINNING_QUADRANT';
export const UPDATE_TEMPLATE_DATA = 'badgesConfig/UPDATE_TEMPLATE_DATA';
export const VALIDATE_CUSTOMIZE_DATA = 'badgesConfig/VALIDATE_CUSTOMIZE_DATA';
export const CREATE_OPTIONS = 'badgesConfig/CREATE_OPTIONS';


export const UPLOAD_IMAGE_REQUESTED = 'upload/UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_IMAGE_SUCCESS = 'upload/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'upload/UPLOAD_IMAGE_FAILURE';

export const GET_USER_EVENT_REQUEST = 'GET_USER_EVENT_REQUEST';
export const GET_USER_EVENT_SUCCESS = 'GET_USER_EVENT_SUCCESS';
export const GET_USER_EVENT_FAILURE = 'GET_USER_EVENT_FAILURE';

export const CHECK_RULE_NAME_REQUEST = 'CHECK_RULE_NAME_REQUEST';
export const CHECK_RULE_NAME_SUCCESS = 'CHECK_RULE_NAME_SUCCESS';
export const CHECK_RULE_NAME_FAILURE = 'CHECK_RULE_NAME_FAILURE';

export const GET_BADGE_CONFIG_REQUESTED = 'get/GET_BADGE_CONFIG_REQUESTED';
export const GET_BADGE_CONFIG_SUCCESS = 'get/GET_BADGE_CONFIG_SUCCESS';
export const GET_BADGE_CONFIG_FAILURE = 'get/GET_BADGE_CONFIG_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  configLoading: false,
  saveRuleLoading: false,
  activeNavTab: 'customize',
  options: {
    userSegment: [],
    gamificationTemplate: [],
    elements: [],
    quadrant: [],
    rewards: [],
    userEvents: [],
    string: [
      {
        title: '==',
        value: 'equal',
      },
      {
        title: '!=',
        value: 'not_equal',
      },
      {
        title: 'Like',
        value: 'like',
      },
      {
        title: 'is_empty',
        value: 'is_empty',
      },
      {
        title: 'is_null',
        value: 'is_null',
      }
    ],
    number: [
      {
        title: '==',
        value: 'equal',
      },
      {
        title: '!=',
        value: 'not_equal',
      },
      {
        title: '<',
        value: 'less',
      },
      {
        title: '>',
        value: 'greater',
      },
      {
        title: '<=',
        value: 'less_or_equal',
      },
      {
        title: '>=',
        value: 'greater_or_equal',
      }
    ],
    date_time: [
      {
        title: '==',
        value: 'equal',
      },
      {
        title: '!=',
        value: 'not_equal',
      },
      {
        title: '<',
        value: 'less',
      },
      {
        title: '>',
        value: 'greater',
      },
      {
        title: '<=',
        value: 'less_or_equal',
      },
      {
        title: '>=',
        value: 'greater_or_equal',
      },
      {
        title: 'between',
        value: 'between'
      }
    ],
    time: [
      {
        title: '==',
        value: 'equal',
      },
      {
        title: '!=',
        value: 'not_equal',
      },
      {
        title: '<',
        value: 'less',
      },
      {
        title: '>',
        value: 'greater',
      },
      {
        title: '<=',
        value: 'less_or_equal',
      },
      {
        title: '>=',
        value: 'greater_or_equal',
      },
      {
        title: 'between',
        value: 'between'
      }
    ],
    date: [
      {
        title: '==',
        value: 'equal',
      },
      {
        title: '!=',
        value: 'not_equal',
      },
      {
        title: '<',
        value: 'less',
      },
      {
        title: '>',
        value: 'greater',
      },
      {
        title: '<=',
        value: 'less_or_equal',
      },
      {
        title: '>=',
        value: 'greater_or_equal',
      },
      {
        title: 'between',
        value: 'between'
      }
    ],
    days: [],
    badgeTypeOptions: []
  },
  customize: {
    mode: 'save',
    ruleId: '',
    ruleName: '',
    userSegment: 'All User',
    status: 'active',
    template: '',
    templateChange: false,
    combination: false,
    rewardOption: '',
    winningQuadrant: [],
    lossingQuadrant: 0,
    quadrantReward: [],
    minCoins: '',
    maxCoins: '',
    targetScore: '',
    selectedBadgeFactor: 'activity',
    selectedScoreFormat: '',
    selectedBadgeType: '',
    selectedBadge: '',
    selectedEventCount: 1,
    timezone: window.localStorage.getItem('defaultTimezone'),
    localTimezoneStr: window.localStorage.getItem('timeZoneString'),
    scratchRewards: [
      {
        offerId: '',
        offerName: '',
        winning: '',
        score: '='
      }
    ],
    winningText: {
      img: '',
      imageName: '',
      text: '',
      color: '#000000',
      font: {
        size: '14',
        strength: '400',
        family: 'Arial, sans-serif',
        align: 'left',
      },
      error: '',
      errorImg: ''
    },
    lossingText: {
      img: '',
      imageName: '',
      text: '',
      color: '#000000',
      font: {
        size: '14',
        strength: '400',
        family: 'Arial, sans-serif',
        align: 'left',
      },
      error: '',
      errorImg: ''
    },
    iterationsPerFrequency: 1,
  },
  schedule: {
    offerStartPublish: 'now',
    offerStartDate: getTimezoneDate(),
    offerEndPublish: 'never',
    offerEndDate: getTimezoneDate(),
    selectedDays: [],
    selectedDaysTimings: [],
    howOftenSelected: 'everytime',
    trigger: false,
    frequency: false,
    events: [{
      id: 1,
      property: [],
      userEvent: '',
      operator: 'none',
      days: 1,
      userProperty: [{
        id: 1,
        name: '',
        type: '',
        operator: '',
        value: [],
        options: []
      }]
    }]
  },
  templates: [],
  quadrants: 0,
  quadrantSegment: [],
  quadrantsOptions: []
};
const getIndexedQuadrants = (index, winingQuadrant) => {
  return winingQuadrant.find(qud => qud.value === index);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPLOAD_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case CLEAR_FORM: {
      return {
        ...initialState
      };
    }
    case SAVE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CONFIG_SUCCESS: {
      const templates = action.result.templates.templateDTOs;
      const templateOptions = [];
      for (let i = 0; i < templates.length; i += 1) {
        if (templates[i].templateName != null) {
          templateOptions.push({ title: templates[i].templateName, value: templates[i].id, typeID: templates[i].typeId });
        } else {
          templateOptions.push({ title: templates[i].title, value: templates[i].id, typeID: templates[i].typeId });
        }
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        options: { ...state.options, ...{ gamificationTemplate: templateOptions } },
      };
    }
    case GET_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CONFIG_RULE_REQUESTED: {
      return {
        ...state,
        configLoading: true,
        loading: true,
        loaded: false,
        // quadrantSegment: [],
        quadrantsOptions: []
      };
    }
    case GET_CONFIG_RULE_SUCCESS: {
      const segments = action.result.spinWheelConfig.segments;
      const segmentsTitle = [];
      for (let i = 0; i < segments.length; i += 1) {
        segmentsTitle[i] = segments[i].text;
      }
      const segmentsUpdate = action.result.spinWheelConfig.segments.map((seg, index) => {
        return {
          ...seg,
          disabled: true,
          id: (index + 1)
        };
      });
      const winingQuadrant = state.customize.winningQuadrant;
      if(winingQuadrant instanceof Array) {
        segmentsUpdate.forEach((winQ, index) => {
          if(getIndexedQuadrants((index + 1), winingQuadrant)) {
            segmentsUpdate[index].disabled = false;
          }else {
            segmentsUpdate[index].disabled = true;
          }
        });
      }
      const quadrantsOptions = action.result.spinWheelConfig.segments.map((seg, index) => {
        return {
          label: (index + 1),
          value: (index + 1)
        };
      });
      state.customize.lossingQuadrant = segmentsUpdate.length;
      const quadrantReward = action.result.spinWheelConfig.segments.map((seg, index) => {
        const upReward = state.customize.quadrantReward.find(updatedReward => updatedReward.quadrant === (index + 1));
        if(upReward) {
          return upReward;
        }
        return {
          quadrant: (index + 1),
          text: seg.text,
          offerId: '',
          offerName: '',
          resultText: '',
          score: '=',
          winingQuadrant: false
        };
      });
      state.customize.lossingQuadrant = quadrantReward.filter(obj => obj.winingQuadrant === false).length;
      state.customize.quadrantReward = quadrantReward;
      const elementsOptions = [];
      const rewards = (state.customize.mode === 'save' && state.customize.typeID === 5) ? [] : state.customize.scratchRewards;
      if(state.customize.typeID === 5) {
        let elements = action.result.spinWheelConfig.slotMachineElements;
        elements = [...new Set(elements)];
        for (let i = 0; i < elements.length; i += 1) {
          elementsOptions.push({ type: 'image', title: elements[i], value: elements[i] });
          if(state.customize.mode === 'save' || state.customize.templateChange) {
            rewards.push({
              offerId: '',
              offerName: '',
              winning: '',
              score: '=',
              elements1: elements[i],
              elements2: elements[i],
              elements3: elements[i]
            });
          }
        }
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        configLoading: false,
        options: { ...state.options, ...{ quadrant: segmentsTitle, elements: elementsOptions } },
        quadrants: action.result.spinWheelConfig.segments.length,
        quadrantSegment: segmentsUpdate,
        quadrantsOptions,
        customize: { ...state.customize, ...{ scratchRewards: rewards, templateChange: false } },
      };
    }
    case GET_CONFIG_RULE_FAILURE: {
      return {
        ...state,
        configLoading: false,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_OFFER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_OFFER_SUCCESS: {
      const offers = action.result.rewardsList.offerConfig;
      const offerOptions = [];
      for (let i = 0; i < offers.length; i += 1) {
        offerOptions.push({ title: offers[i].offerName, value: offers[i].id });
      }
      if (state.customize.typeID !== 2 && state.customize.typeID !== undefined) {
        offerOptions.push({ title: 'No Offer', value: 'No Offer' });
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        options: { ...state.options, ...{ rewards: offerOptions } },
      };
    }
    case GET_OFFER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case SAVE_RULE_REQUESTED: {
      return {
        ...state,
        saveRuleLoading: true,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_RULE_SUCCESS: {
      return {
        ...state,
        saveRuleLoading: false,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_RULE_FAILURE: {
      return {
        ...state,
        saveRuleLoading: false,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case SAVE_BADGE_CONFIG_REQUESTED: {
      return {
        ...state,
        saveRuleLoading: true,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_BADGE_CONFIG_SUCCESS: {
      return {
        ...state,
        saveRuleLoading: false,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_BADGE_CONFIG_FAILURE: {
      return {
        ...state,
        saveRuleLoading: false,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_BADGE_REQUESTED: {
      return {
        ...state,
        saveRuleLoading: true,
        loading: true,
        loaded: false,
      };
    }
    case GET_BADGE_SUCCESS: {
      const { ruleEntity } = action.result;
      const customize = {
        mode: 'edit',
        ruleId: ruleEntity.id,
        ruleName: ruleEntity.name,
        userSegment: ruleEntity.userSegmentId === '' ? 'All User' : ruleEntity.userSegmentId,
        status: ruleEntity.ruleStatus,
        minCoins: ruleEntity.minCoins,
        maxCoins: ruleEntity.maxCoins,
        targetScore: ruleEntity.targetScore,
        selectedBadgeFactor: ruleEntity.badgeFactor,
        selectedScoreFormat: ruleEntity.scoreFormat,
        selectedBadgeType: ruleEntity.badgeType,
        selectedBadge: ruleEntity.badge,
        selectedEventCount: ruleEntity.userSegment.eventCount,
        timezone: ruleEntity.timezone,
        localTimezoneStr: `Set at ${ruleEntity.timezone} (UTC${getTimezoneOffset(ruleEntity.timezone)})`,
        iterationsPerFrequency: ruleEntity.iterationsPerFrequency,
      };
      let { events } = ruleEntity.userSegment;
      events = events && events.map((event) => {
        event.property = event.property.map((prop) => {
          return {
            ...prop,
            title: prop.name,
            value: prop.name
          };
        });
        event.userProperty = event.userProperty.map((userProp) => {
          const propObj = event.property.find(ele => ele.name === userProp.name);
          userProp.options = propObj.options;
          return userProp;
        });
        return event;
      });
      if(events.length === 0) {
        events = state.schedule.events;
      }
      const schedule = {
        offerStartPublish: 'later',
        offerStartDate: DateTime.fromMillis(ruleEntity.startDateTime, { zone: ruleEntity.timezone }),
        offerEndPublish: ruleEntity.endDateTime === 9999999999999 ? 'never' : 'later',
        offerEndDate: ruleEntity.endDateTime === 9999999999999 ? DateTime.now().setZone(ruleEntity.timezone) : DateTime.fromMillis(ruleEntity.endDateTime, { zone: ruleEntity.timezone }),
        howOftenSelected: ruleEntity.howOften,
        trigger: ruleEntity.trigger,
        frequency: ruleEntity.frequency,
        events
      };
      return {
        ...state,
        customize,
        schedule,
        quadrantSegment: customize.quadrantReward,
        saveRuleLoading: false,
        loading: false,
        error: false,
        loaded: true,
        options: { ...state.options },
      };
    }

    case GET_BADGE_FAILURE: {
      return {
        ...state,
        saveRuleLoading: false,
        loading: false,
        loaded: false,
        error: true,
      };
    }

    case UPDATE_TEMPLATE_DATA: {
      const customize = {
        ...state.customize,
        winningQuadrant: [],
        lossingQuadrant: 0,
        quadrantReward: []
      };
      return {
        ...state,
        customize
      };
    }

    case UPDATE_FIELD: {
      state = state
        ? {
          ...state,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }

    case UPDATE_CUSTOMIZE_FIELD: {
      state.customize = state.customize
        ? {
          ...state.customize,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }

    case UPDATE_OPTIONS: {
      state.options = state.options
        ? {
          ...state.options,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }

    case UPDATE_SCHEDULE_FIELD: {
      if (action.payload.selectedDays) {
        action.payload.selectedDays.sort((a, b) => {
          return a - b;
        });
      }
      const schedule = {
        ...state.schedule,
        ...action.payload
      };
      return { ...state, schedule };
    }

    case UPDATE_WINNING_QUADRANT: {
      const winingQuadrant = action.payload.winningQuadrant;
      if(winingQuadrant instanceof Array) {
        state.quadrantSegment.forEach((winQ, index) => {
          if(getIndexedQuadrants((index + 1), winingQuadrant)) {
            state.quadrantSegment[index].disabled = false;
          }else {
            state.quadrantSegment[index].disabled = true;
          }
        });
        const losingCount = (state.quadrantSegment.length - winingQuadrant.length);
        state.customize.lossingQuadrant = losingCount;
        state.customize.quadrantReward = state.customize.quadrantReward.map((rewQ) => {
          const winQindex = winingQuadrant.findIndex(winq => (winq && winq.value === rewQ.quadrant));
          if(winQindex === -1) {
            return {
              ...rewQ,
              offerId: '',
              offerName: '',
              score: '=',
              winingQuadrant: false
            };
          }
          return rewQ;
        });
      } else {
        const quadrant = [];
        for (let i = 0; i < action.payload.winningQuadrant; i += 1) {
          const data = {
            offerId: '',
            offerName: '',
            quadrant: i + 1,
            resultText: '',
            text: `Rank ${i + 1}`,
            winingQuadrant: true
          };
          quadrant.push(data);
        }
        state.customize.quadrantReward = quadrant;
        state.quadrantSegment = quadrant;
      }
      state.customize = state.customize
        ? {
          ...state.customize,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }

    case VALIDATE_CUSTOMIZE_DATA: {
      const {
        ruleName,
        minCoins,
        maxCoins,
        targetScore,
        selectedBadge,
        selectedBadgeFactor,
        selectedScoreFormat
      } = state.customize;
      let error = {
        validCustomize: true,
      };
      if(ruleName === '') {
        error = {
          ...error,
          validCustomize: false,
          ruleNameError: 'Rule name is Required'
        };
      }
      if(ruleName !== '' && (ruleName.length < 3 || ruleName.length > 50)) {
        error = {
          ...error,
          validCustomize: false,
          ruleNameError: 'RuleName should be of min 3 and max 50 characters'
        };
      }
      if(selectedBadge === '') {
        error = {
          ...error,
          validCustomize: false,
          badgeTypeError: 'Badge is Required'
        };
      }
      if(selectedBadgeFactor === '') {
        error = {
          ...error,
          validCustomize: false,
          badgeFactorError: 'Badge factor is Required'
        };
      }
      if (selectedBadgeFactor !== '') {
        if (selectedBadgeFactor === 'score') {
          if(selectedScoreFormat === '') {
            error = {
              ...error,
              validCustomize: false,
              scoreFormatError: 'Score format is Required'
            };
          }
          if(targetScore === '' || targetScore === undefined) {
            error = {
              ...error,
              validCustomize: false,
              targetScoreError: 'Target score is required'
            };
          }
          if(targetScore === '0') {
            error = {
              ...error,
              validCustomize: false,
              targetScoreError: 'Target score should be greater than zero'
            };
          }
        }
        if (selectedBadgeFactor === 'coins') {
          if(minCoins === '' || minCoins === undefined) {
            error = {
              ...error,
              validCustomize: false,
              minCoinsError: 'Min coins is required'
            };
          }
          if(maxCoins === '' || maxCoins === undefined) {
            error = {
              ...error,
              validCustomize: false,
              maxCoinsError: 'Max coins is required'
            };
          }
          if((maxCoins !== '') && (Number(maxCoins) <= Number(minCoins))) {
            error = {
              ...error,
              validCustomize: false,
              maxCoinsError: 'Max coins should be greater than min coins'
            };
          }
        }
      }

      const customize = {
        ...state.customize,
        ...error
      };
      return {
        ...state,
        customize
      };
    }
    case GET_SEGEMENT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SEGEMENT_SUCCESS: {
      const segement = action.result.segmentList;
      const segementOptions = [];
      for (let i = 0; i < segement.length; i += 1) {
        segementOptions.push({ title: segement[i].title, value: segement[i].value });
      }
      segementOptions.push({ title: 'All User', value: 'All User' });
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        options: { ...state.options, ...{ userSegment: segementOptions } },
      };
    }
    case GET_SEGEMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_USER_EVENT_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_USER_EVENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_USER_EVENT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case CREATE_OPTIONS: {
      const days = [];
      for (let i = 1; i <= 30; i += 1) {
        days.push({
          title: i,
          value: i
        });
      }
      let { options } = state;
      options = {
        ...options,
        days
      };
      return {
        ...state,
        options,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_BADGE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BADGE_CONFIG_SUCCESS: {
      const templates = action.result.Badges.badgesEntity;
      const templateOptions = [];
      for (let i = 0; i < templates.length; i += 1) {
        if (templates[i].templateName != null) {
          templateOptions.push({ title: templates[i].templateName, value: templates[i].id });
        } else {
          templateOptions.push({ title: templates[i].title, value: templates[i].id });
        }
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        options: { ...state.options, ...{ badgeTypeOptions: templateOptions } },
      };
    }
    case GET_BADGE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const createOptions = (type) => {
  if (type === 'days') {
    return {
      type: CREATE_OPTIONS,
    };
  }
  return {};
};

export const updateWinningQuadrant = (payload) => {
  return {
    type: UPDATE_WINNING_QUADRANT,
    payload,
  };
};

export const updateTemplateData = () => {
  return {
    type: UPDATE_TEMPLATE_DATA,
  };
};
export const validateCustomize = () => {
  return {
    type: VALIDATE_CUSTOMIZE_DATA,
  };
};

export const updateCustomize = (payload) => {
  return {
    type: UPDATE_CUSTOMIZE_FIELD,
    payload,
  };
};

export const updateSchedule = (payload) => {
  return {
    type: UPDATE_SCHEDULE_FIELD,
    payload,
  };
};

export const clearForm = () => {
  return {
    type: CLEAR_FORM,
  };
};

export const saveSpinWheelConfig = (data) => {
  return {
    types: [SAVE_CONFIG_REQUESTED, SAVE_CONFIG_SUCCESS, SAVE_CONFIG_FAILURE],
    promise: client => client.post('saveSpinWheelConfig', {
      data,
    }),
  };
};

export const getSpinWheelConfig = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(
      `getSpinWheelConfig/${data.itemsPerPage}/${data.pageNo}?title=${data.title}&sortBy=${data.sortBy}&orderBy=${
        data.orderBy
      }`
    ),
  };
};

export const getConfigRule = (gamificationId) => {
  return {
    types: [GET_CONFIG_RULE_REQUESTED, GET_CONFIG_RULE_SUCCESS, GET_CONFIG_RULE_FAILURE],
    promise: client => client.get(`getConfigRule?gamificationId=${gamificationId}`),
  };
};

// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const updateOptions = (payload) => {
  return {
    type: UPDATE_OPTIONS,
    payload,
  };
};

export const getMyOffers = (data) => {
  let reqParams = '';
  if (data.searchKey !== '') {
    reqParams = `${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&sortType=${
      data.orderBy
    }`;
  } else {
    reqParams = `${data.itemsPerPage}/${data.pageNo}?searchKey=&sortBy=${data.sortBy}&sortType=${data.orderBy}`;
  }
  return {
    types: [GET_OFFER_REQUESTED, GET_OFFER_SUCCESS, GET_OFFER_FAILURE],
    promise: client => client.get(`getMyOffersList/${reqParams}`),
  };
};

const getApplyOffers = (array) => {
  const timingsArray = [];
  array.forEach((time, index) => {
    if(time !== null) {
      timingsArray.push({
        day: (index + 1),
        startTime: new Date(time.start).getTime(),
        endTime: new Date(time.end).getTime()
      });
    }
  });
  return timingsArray;
};

export const getRuleEngineData = (ruleState, query) => {
  let operator = '';
  query.forEach((qu, index) => {
    operator += `Q${(index + 1)}`;
    if ((query[index + 1])) {
      operator += ` ${this.getOperator(qu.operator.toLowerCase())} `;
    }
  });
  const userSegment = {
    events: ruleState.customize.selectedBadgeFactor === 'activity' ? ruleState.schedule.events : [],
    query: ruleState.customize.selectedBadgeFactor === 'activity' ? query : [],
    operator: ruleState.customize.selectedBadgeFactor === 'activity' ? operator : '',
    eventCount: ruleState.customize.selectedBadgeFactor === 'activity' ? ruleState.customize.selectedEventCount : 0
  };
  const data = {
    badgesRuleId: ruleState.customize.ruleId ? ruleState.customize.ruleId : '',
    ruleId: ruleState.customize.ruleId ? ruleState.customize.ruleId : '',
    name: ruleState.customize.ruleName,
    templateId: ruleState.customize.template,
    type: ruleState.customize.typeID,
    ruleStatus: ruleState.customize.status,
    combination: ruleState.customize.combination,
    userSegmentId: ruleState.customize.userSegment === 'All User' ? '' : ruleState.customize.userSegment,
    howOften: ruleState.schedule.howOftenSelected,
    startDateTime: ruleState.schedule.offerStartDate.set({ second: 0 }).ts,
    endDateTime: ruleState.schedule.offerEndPublish === 'later' ? ruleState.schedule.offerEndDate.set({ second: 0 }).ts : 9999999999999,
    applyOffersOn: ruleState.schedule.frequency ? getApplyOffers(ruleState.schedule.selectedDaysTimings) : [],
    trigger: ruleState.schedule.trigger,
    frequency: ruleState.schedule.frequency,
    userSegment,
    rewardOption: ruleState.customize.rewardOption,
    ruleType: 1,
    badgeType: ruleState.customize.selectedBadgeType,
    badge: ruleState.customize.selectedBadge,
    badgeFactor: ruleState.customize.selectedBadgeFactor,
    minCoins: ruleState.customize.minCoins,
    maxCoins: ruleState.customize.maxCoins,
    scoreFormat: ruleState.customize.selectedScoreFormat,
    targetScore: ruleState.customize.targetScore,
    timezone: ruleState.customize.timezone,
    iterationsPerFrequency: ruleState.customize.iterationsPerFrequency,
  };
  return data;
};

export const getBadge = (ruleId) => {
  return {
    types: [GET_BADGE_REQUESTED, GET_BADGE_SUCCESS, GET_BADGE_FAILURE],
    promise: client => client.get(`getBadgeById?badgeId=${ruleId}`),
  };
};

export const uploadimage = (file, folderName) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/gamification/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
      .field('folderName', folderName)
  };
};

export const getSegment = () => {
  return {
    types: [GET_SEGEMENT_REQUESTED, GET_SEGEMENT_SUCCESS, GET_SEGEMENT_FAILURE],
    promise: client => client.get('getSegmentList'),
  };
};

export const getEvents = () => {
  return {
    types: [GET_USER_EVENT_REQUEST, GET_USER_EVENT_SUCCESS, GET_USER_EVENT_FAILURE],
    promise: client => client.get('getUserEvents')
  };
};

export const checkRuleNameDublicate = (name, id) => {
  return {
    types: [CHECK_RULE_NAME_REQUEST, CHECK_RULE_NAME_SUCCESS, CHECK_RULE_NAME_FAILURE],
    promise: client => client.get(`checkBadgeNameExists?name=${name}&id=${id}`),
  };
};

export const saveBadgeConfig = (ruleState, query) => {
  const data = getRuleEngineData(ruleState, query);
  return {
    types: [SAVE_BADGE_CONFIG_REQUESTED, SAVE_BADGE_CONFIG_SUCCESS, SAVE_BADGE_CONFIG_FAILURE],
    promise: client => client.post(`saveBadgeConfig?badgesRuleId=${data.badgesRuleId}`, {
      data,
    }),
  };
};

export const getGameMechanicsConfig = (data) => {
  return {
    types: [GET_BADGE_CONFIG_REQUESTED, GET_BADGE_CONFIG_SUCCESS, GET_BADGE_CONFIG_FAILURE],
    promise: client => client.get(`getGameMechanicsConfig/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.title}&sortBy=${data.sortBy}&sortType=${data.orderBy}`)
  };
};
