import { CONSTANTS } from '@constants';

export const REQUESTED = 'REQUESTED';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export const UPDATE_FIELD = 'offerreport/UPDATE_FIELD';
export const UPDATE_CUSTOM_FIELD = 'offerreport/UPDATE_CUSTOM_FIELD';
export const UPDATE_PAGE = 'offerreport/UPDATE_PAGE';
export const UPDATE_CUSTOM_PAGE = 'offerreport/UPDATE_CUSTOM_PAGE';

export const CLEAR_STATE = 'offerreport/CLEAR_STATE';

export const GET_SENDERLIST_REQUESTED = 'offerreport/GET_SENDERLIST_REQUESTED';
export const GET_SENDERLIST_SUCCESS = 'offerreport/GET_SENDERLIST_SUCCESS';
export const GET_SENDERLIST_FAILURE = 'offerreport/GET_SENDERLIST_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  campaignData: {
    id: '',
    ruleName: '',
    nameError: '',
    offerStatus: 'active',
    senderList: '',
    msg: '',
    ruleId: '',
    file: '',
    fileName: '',
    scheduleTime: 0,
    subject: '',
    segmentId: ''
  },
  tableData: {},
  initialData: {
    selectedReportIds: [],
    pageNo: 0,
    sortBy: 'updatedTS',
    orderBy: 'desc',
    activePage: 1,
    searchKey: '',
    searchCampaign: '',
    sendReport: '',
    selectedMethod: '',
    selectedPeriod: '',
    selectedPeriodFrom: '',
    selectedPeriodEnd: '',
    selectedPeriodYear: null,
    selectedPeriodMonth: null,
    selectedPeriodWeek: null,
    headers: [
      {
        title: 'Actions',
        slug: 'actions',
        isSortable: false,
        isVisible: false,
        tooltip: false,
        sortByOrder: '',
        width: 50,
      },
      {
        title: CONSTANTS.OFFER_NAME,
        slug: 'rule_name',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        width: 160,
      },
      {
        title: CONSTANTS.CALENDER_DURATION,
        slug: 'total_visits',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: true,
        width: 100,
      },
      {
        title: CONSTANTS.STATUS_FILTER,
        slug: 'total_plays',
        isSortable: false,
        isVisible: true,
        tooltip: true,
        center: true,
        width: 120,
      },
      {
        title: CONSTANTS.ACTIVITY_INTERACTIONS,
        slug: 'no_repeat_players',
        isSortable: false,
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: 'desc',
        width: 160,
      },
      {
        title: CONSTANTS.TOTAL_OFFERS,
        slug: 'total_offers',
        isSortable: false,
        isVisible: true,
        tooltip: true,
        center: true,
        width: 120,
      },
      {
        title: CONSTANTS.OFFER_DISTRIBUTED,
        slug: 'offer_redeem',
        isSortable: false,
        isVisible: true,
        tooltip: true,
        center: true,
        width: 115,
      },
      {
        title: CONSTANTS.OFFER_REDEEM,
        slug: 'offer_redeem',
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: 'desc',
        width: 115,
      },
      {
        title: CONSTANTS.OFFER_TYPE,
        slug: 'total_offers',
        isSortable: false,
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: 'desc',
        width: 120,
      }
    ]
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_CUSTOM_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case UPDATE_CUSTOM_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case GET_SENDERLIST_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_SENDERLIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_SENDERLIST_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    default:
      return state;
  }
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const getReport = (data) => {
  return {
    types: [REQUESTED, SUCCESS, FAILURE],
    promise: client => client.get(`getOfferReport/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&orderBy=${data.orderBy}&selectedPeriodFrom=${data.selectedPeriodFrom}&selectedPeriodEnd=${data.selectedPeriodEnd}`)
  };
};

export const downloadOffer = (data) => {
  return {
    types: [REQUESTED, SUCCESS, FAILURE],
    promise: client => client.get(`downloadReport?selectedPeriodFrom=${data.selectedPeriodFrom}&selectedPeriodEnd=${data.selectedPeriodEnd}&idList=${data.idList}`)
  };
};

// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatecustomFields = (payload) => {
  return {
    type: UPDATE_CUSTOM_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const updateCustomPage = (payload) => {
  return {
    type: UPDATE_CUSTOM_PAGE,
    payload,
  };
};

export const sendNowOfferReport = (data) => {
  console.log('date8', data);
  return {
    types: [GET_SENDERLIST_REQUESTED, GET_SENDERLIST_SUCCESS, GET_SENDERLIST_FAILURE],
    promise: client => client.get(`sendNowOfferReport?selectedPeriodFrom=${data.selectedPeriodFrom}&selectedPeriodEnd=${data.selectedPeriodEnd}&emailList=${data.emailList}&idList=${data.idList}`)
  };
};
