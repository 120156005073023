import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (window.localStorage.getItem('userData') === 'undefined' || typeof window.localStorage.getItem('userData') === 'undefined') {
      window.localStorage.removeItem('userData');
    }
  }

  componentDidMount() {
    const url = window.location.search;
    const params = new URLSearchParams(url);
    const token = (params.get('token')) ? params.get('token') : '';
    if (window.location.href.indexOf('/authenticate') !== -1 && window.localStorage.getItem('userData')) {
      window.localStorage.clear();
      this.props.history.push(`${window.location.pathname}?token=${token}`);
    }

    window.addEventListener('orientationchange', () => {
      this.forceUpdate();
    });
  }

  componentDidUpdate() {
    window.onpopstate = () => {
    };
  }

  getSecondaryUrl = (pathname) => {
    const metakey = pathname.split('/');
    if (metakey.length > 2) {
      metakey.splice(metakey.length - 1, 1);
    }
    metakey.push('**');
    return metakey.join('/');
  }

  render() {
    return this.props.children;
  }
}


const mapStateToProps = () => ({
});

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScrollToTop));
