/* eslint-disable react/jsx-one-expression-per-line */
import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import cn from 'classnames';
import { connect } from 'react-redux';
import SideMenu from '@components/common/SideMenu/navbar';
import contactButton from '@assets/images/ContactUs.png';

const PrivateRoute = (prop) => {
  const { component: Component, authed, ...rest } = prop;
  const trialUser = window.localStorage.getItem('trialUser');
  // const hasTrialExpired = window.localStorage.getItem('hasTrialExpired');
  return (
    <Route
      {...rest}
      render={props => (typeof window.localStorage.getItem('userData') !== 'undefined'
        && window.localStorage.getItem('userData') !== null
        && window.localStorage.getItem('userData') !== '' ? (
          <Fragment>
            {trialUser && trialUser === 'true' && (
              <div className={cn('trialFont', 'clearfix')}>
                &emsp;&emsp;
                  Your trial period has started and it will stay active for the next <span className={cn('trialDays')}>14 days</span>. Contact our sales team to get access to the full version.
                  &emsp;&emsp;&emsp;&emsp;&emsp;
                <a className={cn('gotoButton')} type="button" href="https://gamize.com/contact-us/">
                  <img src={contactButton} width="114px" height="38px" alt="BG" className={cn('imgBg')} />
                </a>
                <br />
              </div>
            )}
            <div className={trialUser && trialUser === 'true' ? 'app_body_trial' : 'app_body'}>
              <SideMenu {...props} />
              <div className={cn('app_content_body app-bg', rest.isMenuCollapsed && 'app-menu-collapsed')} id="app_content_body">
                <Component {...props} />
              </div>
            </div>
          </Fragment>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        ))
      }
    />
  );
};

const mapStateToProps = (state) => {
  const { isMenuCollapsed } = state.sidemenu;
  return {
    isMenuCollapsed: isMenuCollapsed || false,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
