import { APP_CONFIG, CONSTANTS } from '@constants';

import { getTimezoneDate, sort } from '@helpers/Utility';

const upload = require('superagent');

export const UPDATE_NOTIFICATION_FIELD = 'notifications/UPDATE_NOTIFICATION_FIELD';
export const UPDATE_PAGE = 'notifications/UPDATE_PAGE';
export const CLEAR_FORM = 'notifications/CLEAR_FORM';
export const UPDATE_FIELD = 'notifications/UPDATE_FIELD';

export const UPLOAD_IMAGE_REQUESTED = 'upload/UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_IMAGE_SUCCESS = 'upload/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'upload/UPLOAD_IMAGE_FAILURE';


export const GET_RULE_REQUESTED = 'notifications/GET_RULE_REQUESTED';
export const GET_RULE_SUCCESS = 'notifications/GET_RULE_SUCCESS';
export const GET_RULE_FAILURE = 'notifications/GET_RULE_FAILURE';

export const GET_SEGMENT_REQUESTED = 'notifications/GET_SEGMENT_REQUESTED';
export const GET_SEGMENT_SUCCESS = 'notifications/GET_SEGMENT_SUCCESS';
export const GET_SEGMENT_FAILURE = 'notifications/GET_SEGMENT_FAILURE';

export const GET_EVENT_REQUESTED = 'notifications/GET_EVENT_REQUESTED';
export const GET_EVENT_SUCCESS = 'notifications/GET_EVENT_SUCCESS';
export const GET_EVENT_FAILURE = 'notifications/GET_EVENT_FAILURE';

export const SAVE_RULE_REQUESTED = 'notifications/SAVE_RULE_REQUESTED';
export const SAVE_RULE_SUCCESS = 'notifications/SAVE_RULE_SUCCESS';
export const SAVE_RULE_FAILURE = 'notifications/SAVE_RULE_FAILURE';

export const GET_IDENTIFIERS_LIST_REQUESTED = 'notifications/GET_IDENTIFIERS_LIST_REQUESTED';
export const GET_IDENTIFIERS_LIST_SUCCESS = 'notifications/GET_IDENTIFIERS_LIST_SUCCESS';
export const GET_IDENTIFIERS_LIST_FAILURE = 'notifications/GET_IDENTIFIERS_LIST_FAILURE';

export const GET_NOTIFICATION_REQUESTED = 'notifications/GET_NOTIFICATION_REQUESTED';
export const GET_NOTIFICATION_SUCCESS = 'notifications/GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILURE = 'notifications/GET_NOTIFICATION_FAILURE';

export const CHECK_NOTIFICATION_REQUESTED = 'notifications/CHECK_NOTIFICATION_REQUESTED';
export const CHECK_NOTIFICATION_SUCCESS = 'notifications/CHECK_NOTIFICATION_SUCCESS';
export const CHECK_NOTIFICATION_FAILURE = 'notifications/CHECK_NOTIFICATION_FAILURE';

export const UPDATE_NOTIFICATION_LOADER = 'notifications/UPDATE_NOTIFICATION_LOADER';

export const UPDATE_TIMEZONE = 'notifications/UPDATE_TIMEZONE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  statusDropDown: [
    {
      title: CONSTANTS.ACTIVE,
      value: 'active',
    },
    {
      title: CONSTANTS.INACTIVE,
      value: 'inactive',
    }
  ],
  rules: [
  ],
  userSegment: [],
  eventList: [],
  notificationData: {
    id: '',
    type: 1,
    name: '',
    nameError: '',
    title: '',
    titleError: '',
    content: '',
    contentError: '',
    redirectionLink: '',
    linkError: '',
    notificationStatus: 'active',
    identifiersList: [],
    msg: '',
    ruleId: '',
    ruleError: '',
    eventId: '',
    file: '',
    fileName: '',
    imageName: '',
    imageURL: '',
    scheduleTime: 0,
    userSegmentId: '',
    userSegmentName: '',
    startDateOption: 'now',
    startDateTime: getTimezoneDate(),
    endDateOption: 'never',
    endDateTime: getTimezoneDate(),
    dateTimeError: '',
    notificationSendTime: getTimezoneDate(),
    ruleEntity: [
    ],
  },
  tableData: {},
  initialData: {
    itemsPerPage: 5,
    pageNo: 0,
    title: '',
    sortBy: 'updatedOn',
    orderBy: 'desc',
    ruleId: '',
    headers: [
      {
        title: CONSTANTS.NOTIFICATION_NAME,
        slug: 'notification_name',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: '',
        width: 150
      },
      {
        title: CONSTANTS.USER_SEGMENT,
        slug: 'user_segment',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: '',
        width: 150
      },
      {
        title: CONSTANTS.START_DATE_TIME,
        slug: 'start_date',
        isSortable: false,
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: 'desc',
        width: 110,
      },
      {
        title: CONSTANTS.END_DATE_TIME,
        slug: 'start_time',
        isSortable: false,
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: 'desc',
        width: 100,
      },
      {
        title: CONSTANTS.STATUS_FILTER,
        slug: 'status',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: 'desc',
        width: 55,
      },
      {
        title: CONSTANTS.SEND_IMPRESSIONS,
        slug: 'send_impressions',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: false,
        sortByOrder: 'desc',
        width: 100,
      },
      {
        title: CONSTANTS.CLICKS_OPEN,
        slug: 'clicks_open',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: false,
        sortByOrder: 'desc',
        width: 100,
      },
      {
        title: CONSTANTS.DATETIME,
        slug: 'date_time',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: false,
        sortByOrder: 'desc',
        width: 100,
      }
      /* ,
      {
        title: 'Actions',
        slug: 'actions',
        isSortable: false,
        isVisible: false,
        tooltip: true,
        sortByOrder: '',
        width: 180,
      } */
    ]
  },
  timezone: window.localStorage.getItem('defaultTimezone'),
  localTimezoneStr: window.localStorage.getItem('timeZoneString')
};

const formatList = (arr) => {
  return arr.map(obj => ({ title: obj.name, value: obj.id }));
};

const formatEventList = (arr) => {
  return arr.map(obj => ({ title: obj.eventName, value: obj.id }));
};


export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_FORM: {
      return {
        ...initialState
      };
    }
    case UPDATE_NOTIFICATION_FIELD: {
      const notificationData = state.notificationData ? { ...state.notificationData, ...action.payload } : { ...action.payload };
      return { ...state, notificationData };
    }
    case GET_RULE_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_RULE_SUCCESS: {
      const ruleEntity = action.result.ruleEntity;
      return {
        ...state,
        // loading: false,
        rules: sort(formatList(action.result.ruleEntity)),
        notificationData: {
          ...state.notificationData,
          ruleEntity
        }
      };
    }
    case GET_RULE_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    case GET_SEGMENT_REQUESTED: {
      return {
        ...state,
      };
    }
    case GET_SEGMENT_SUCCESS: {
      return {
        ...state,
        userSegment: action.result.segmentList
      };
    }
    case GET_SEGMENT_FAILURE: {
      return {
        ...state
      };
    }
    case GET_EVENT_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_EVENT_SUCCESS: {
      const eventList = action.result.CustomEvents.customEventsDTO;
      return {
        ...state,
        eventList: sort(formatEventList(eventList)),
      };
    }
    case GET_EVENT_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    case GET_NOTIFICATION_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_NOTIFICATION_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    case CHECK_NOTIFICATION_REQUESTED: {
      return {
        ...state,
        loading: false,
      };
    }
    case CHECK_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CHECK_NOTIFICATION_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    case SAVE_RULE_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case SAVE_RULE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case SAVE_RULE_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    case GET_IDENTIFIERS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_IDENTIFIERS_LIST_SUCCESS: {
      let identifiersList = state.notificationData.identifiersList;
      identifiersList = action.result.body.userData;
      return {
        ...state,
        loading: false,
        notificationData: {
          ...state.notificationData,
          senderError: '',
          identifiersList
        }
      };
    }
    case GET_IDENTIFIERS_LIST_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    case UPDATE_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case UPLOAD_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPLOAD_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_NOTIFICATION_LOADER: {
      return {
        ...state,
        ...action.payload
      };
    }
    case UPDATE_TIMEZONE: {
      return {
        ...state,
        timezone: window.localStorage.getItem('defaultTimezone'),
        localTimezoneStr: window.localStorage.getItem('timeZoneString')
      };
    }
    default:
      return state;
  }
};


// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const updateNotification = (payload) => {
  return {
    type: UPDATE_NOTIFICATION_FIELD,
    payload,
  };
};

export const updateLoader = (payload) => {
  return {
    type: UPDATE_NOTIFICATION_LOADER,
    payload,
  };
};

export const clearForm = () => {
  return {
    type: CLEAR_FORM,
  };
};

export const getRuleList = () => {
  return {
    types: [GET_RULE_REQUESTED, GET_RULE_SUCCESS, GET_RULE_FAILURE],
    promise: client => client.get('getRuleList'),
  };
};

export const getSegment = () => {
  return {
    types: [GET_SEGMENT_REQUESTED, GET_SEGMENT_SUCCESS, GET_SEGMENT_FAILURE],
    promise: client => client.get('getSegmentList'),
  };
};

export const getEventList = () => {
  return {
    types: [GET_EVENT_REQUESTED, GET_EVENT_SUCCESS, GET_EVENT_FAILURE],
    promise: client => client.get('getEventList/5/0?title=&sortBy=createdTS&orderBy=desc'),
  };
};

export const saveNotification = (data) => {
  data = {
    ...data,
    apiKey: window.localStorage.getItem('apiKey')
  };
  return {
    types: [SAVE_RULE_REQUESTED, SAVE_RULE_SUCCESS, SAVE_RULE_FAILURE],
    promise: client => client.post('saveNotificationsConfig', {
      data
    }),
  };
};

export const getIdentifiersList = (file, type) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [GET_IDENTIFIERS_LIST_REQUESTED, GET_IDENTIFIERS_LIST_SUCCESS, GET_IDENTIFIERS_LIST_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/notifications/upload/usersegment`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('file', file)
      .field('type', type)
  };
};

export const getNotificationsList = (data, type) => {
  let reqParams = '';
  if (data.searchKey && data.searchKey !== '') {
    reqParams = `${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&sortType=${data.orderBy}&type=${type}`;
  } else {
    reqParams = `${data.itemsPerPage}/${data.pageNo}?title=${data.title}&sortBy=${data.sortBy}&orderBy=${data.orderBy}`;
  }
  return {
    types: [GET_NOTIFICATION_REQUESTED, GET_NOTIFICATION_SUCCESS, GET_NOTIFICATION_FAILURE],
    promise: client => client.get(`getNotificationsList/${reqParams}`)
  };
};

export const checkNotificationsTitle = (name, type) => {
  return {
    types: [CHECK_NOTIFICATION_REQUESTED, CHECK_NOTIFICATION_SUCCESS, CHECK_NOTIFICATION_FAILURE],
    promise: client => client.get(`checkNotificationsTitle?name=${name}&type=${type}`),
  };
};

export const uploadimage = (file, folderName) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/gamification/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
      .field('folderName', folderName)
  };
};

export const updateTimezone = () => {
  return {
    type: UPDATE_TIMEZONE,
  };
};
