export const UPDATE_FIELD = 'plan/UPDATE_FIELD';
export const CLEAR_STATE = 'plan/CLEAR_STATE';

export const GET_PLANDETAIL_REQUESTED = 'plan/GET_PLANDETAIL_REQUESTED';
export const GET_PLANDETAIL_SUCCESS = 'plan/GET_PLANDETAIL_SUCCESS';
export const GET_PLANDETAIL_FAILURE = 'plan/GET_PLANDETAIL_FAILURE';

export const SUBMIT_PLANDETAIL_REQUESTED = 'plan/SUBMIT_PLANDETAIL_REQUESTED';
export const SUBMIT_PLANDETAIL_SUCCESS = 'plan/SUBMIT_PLANDETAIL_SUCCESS';
export const SUBMIT_PLANDETAIL_FAILURE = 'plan/SUBMIT_PLANDETAIL_FAILURE';

const initialState = {
  customPlan: {
    mav: '',
    events: '',
    emailId: '',
    mobileNumber: '',
    code: '+91',
  },
  planList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FIELD: {
      state.customPlan = state.customPlan
        ? {
          ...state.customPlan,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case GET_PLANDETAIL_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_PLANDETAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        planList: action.result.planList
      };
    }
    case GET_PLANDETAIL_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case SUBMIT_PLANDETAIL_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SUBMIT_PLANDETAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SUBMIT_PLANDETAIL_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

// action
export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const getPlanDetails = () => {
  return {
    types: [GET_PLANDETAIL_REQUESTED, GET_PLANDETAIL_SUCCESS, GET_PLANDETAIL_FAILURE],
    promise: client => client.get('getPlansDetail')
  };
};

export const submitPlan = (data) => {
  return {
    types: [SUBMIT_PLANDETAIL_REQUESTED, SUBMIT_PLANDETAIL_SUCCESS, SUBMIT_PLANDETAIL_FAILURE],
    promise: client => client.post('submitCustomPlanDetail', {
      data
    })
  };
};
