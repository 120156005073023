
export const GET_SIDEMENU_REQUESTED = 'sidemenu/GET_SIDEMENU_REQUESTED';
export const GET_SIDEMENU_SUCCESS = 'sidemenu/GET_SIDEMENU_REQUESTED';
export const GET_SIDEMENU_FAILURE = 'sidemenu/GET_SIDEMENU_REQUESTED';

export const UPDATE_ACTIVEPAGE_ACTIVE = 'sidemenu/UPDATE_ACTIVEPAGE_ACTIVE';
export const UPDATE_SUBMENU_COLLAPSE = 'sidemenu/UPDATE_SUBMENU_COLLAPSE';
export const UPDATE_ACTIVEPARENTPAGE_ACTIVE = 'sidemenu/UPDATE_ACTIVEPARENTPAGE_ACTIVE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorDesc: [],
  code: '',
  msg: '',
  status: '',
  activePage: '',
  activeParentPage: '',
  menuList: [],
  isMenuCollapsed: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SIDEMENU_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SIDEMENU_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        menuList: action.result.sidebarData
      };
    }
    case GET_SIDEMENU_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_ACTIVEPAGE_ACTIVE:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
        activePage: action.payload
      };
    }
    case UPDATE_SUBMENU_COLLAPSE:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
        isMenuCollapsed: action.payload
      };
    }
    case UPDATE_ACTIVEPARENTPAGE_ACTIVE:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
        activeParentPage: action.payload
      };
    }
    default:
      return state;
  }
};

export const getSideMenu = () => {
  return {
    types: [GET_SIDEMENU_REQUESTED, GET_SIDEMENU_SUCCESS, GET_SIDEMENU_FAILURE],
    promise: client => client.get('getSideMenu')
  };
};

export const updateMenuCollapse = (action) => {
  return {
    type: UPDATE_SUBMENU_COLLAPSE,
    payload: action
  };
};

export const updateActivePage = (pageId) => {
  return {
    type: UPDATE_ACTIVEPAGE_ACTIVE,
    payload: pageId
  };
};

export const updateActiveParentPage = (pageId) => {
  return {
    type: UPDATE_ACTIVEPARENTPAGE_ACTIVE,
    payload: pageId
  };
};
