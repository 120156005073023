import { APP_CONFIG } from '@constants';

const upload = require('superagent');

export const UPDATE_TITLE_FIELD = 'leadGenConfig/UPDATE_TITLE_FIELD';
export const UPDATE_SUBTITLE_FIELD = 'leadGenConfig/UPDATE_SUBTITLE_FIELD';
export const UPDATE_BUTTON_FIELD = 'leadGenConfig/UPDATE_BUTTON_FIELD';
export const UPDATE_BUTTON_FIELD_SCRATCH_CARD = 'leadGenConfig/UPDATE_BUTTON_FIELD_SCRATCH_CARD';
export const UPDATE_BACKGROUND_FIELD = 'leadGenConfig/UPDATE_BACKGROUND_FIELD';
export const UPDATE_LAYER_FIELD = 'leadGenConfig/UPDATE_LAYER_FIELD';
export const UPDATE_APPCONFIG_FIELD = 'leadGenConfig/UPDATE_APPCONFIG_FIELD';
export const UPDATE_BUTTON = 'leadGenConfig/UPDATE_BUTTON';
export const UPDATE_GRATIFY_BACKGROUND_FIELD = 'leadGenConfig/UPDATE_GRATIFY_BACKGROUND_FIELD';
export const UPDATE_CARDCONFIG = 'leadGenConfig/UPDATE_CARDCONFIG';
export const UPLOAD_IMAGE_REQUESTED = 'upload/UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_IMAGE_SUCCESS = 'upload/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'upload/UPLOAD_IMAGE_FAILURE';

export const SAVE_CONFIG_REQUESTED = 'save/SAVE_CONFIG_REQUESTED';
export const SAVE_CONFIG_SUCCESS = 'save/SAVE_CONFIG_SUCCESS';
export const SAVE_CONFIG_FAILURE = 'save/SAVE_CONFIG_FAILURE';

export const UPDATE_CONFIG_REQUESTED = 'update/UPDATE_CONFIG_REQUESTED';
export const UPDATE_CONFIG_SUCCESS = 'update/UPDATE_CONFIG_SUCCESS';
export const UPDATE_CONFIG_FAILURE = 'update/UPDATE_CONFIG_FAILURE';

export const GET_CONFIG_REQUESTED = 'get/GET_CONFIG_REQUESTED';
export const GET_CONFIG_SUCCESS = 'get/GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'get/GET_CONFIG_FAILURE';

export const DUPLICATE_CONFIG_REQUESTED = 'get/DUPLICATE_CONFIG_REQUESTED';
export const DUPLICATE_CONFIG_SUCCESS = 'get/DUPLICATE_CONFIG_SUCCESS';
export const DUPLICATE_CONFIG_FAILURE = 'get/DUPLICATE_CONFIG_FAILURE';

export const UPDATE_FIELD = 'leadGenConfig/UPDATE_FIELD';
export const UPDATE_PAGE = 'leadGenConfig/UPDATE_PAGE';
export const UPDATE_LOCALIZATION_FLAG = 'leadGenConfig/UPDATE_LOCALIZATION_FLAG';
export const UPDATE_ACTIVE_FLAG = 'leadGenConfig/UPDATE_ACTIVE_FLAG';
export const UPDATE_CONFIG_FIELD = 'leadGenConfig/UPDATE_CONFIG_FIELD';
export const REMOVE_CONFIG = 'leadGenConfig/REMOVE_CONFIG';
export const CLEAR_STATE = 'leadGenConfig/CLEAR_STATE';
export const UPDATE_BUTTON_CONFIG = 'leadGenConfig/UPDATE_BUTTON_CONFIG';
export const UPDATE_QUESTIONS_FIELD = 'leadGenConfig/UPDATE_QUESTIONS_FIELD';
export const UPDATE_COLLAPSE = 'leadGenConfig/UPDATE_COLLAPSE';

export const UPDATE_LEADGEN_FIELD = 'leadGenConfig/UPDATE_LEADGEN_FIELD';
export const UPDATE_FORMDISPLAY_CONFIG = 'leadGenConfig/UPDATE_FORMDISPLAY_CONFIG';
export const UPDATE_FORMBUTTON_CONFIG = 'leadGenConfig/UPDATE_FORMBUTTON_CONFIG';
export const UPDATE_ADDSETTINGS_CONFIG = 'leadGenConfig/UPDATE_ADDSETTINGS_CONFIG';
export const UPDATE_DISPLAYWIZARD = 'leadGenConfig/UPDATE_DISPLAYWIZARD';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  initialValues: {
    fontTitlePositions: [],
    buttonFormatWidth: [],
    buttonFormatRadius: [],
    buttonFormatShadow: [],
    outlineStyles: [],
    spinButtonWidth: [],
    spinButtonRadius: [],
    spinButtonShadow: [],
    fonts: [],
    fontStrength: [],
    fontSize: [],
    scratchType: [],
    fontPositions: [],
    buttonAction: [],
    themeList: [],
  },
  collapse: {
    displayWizard: false,
    formDisplayConfig: false,
    formFieldsConfig: false,
    formButtonConfig: false,
    additionalSettings: false,
  },
  localizationFlag: '',
  activeNavTab: 'English',
  config: {
    localizationconfig: [
      {
        language: 'English',
        resultHandler: 'ParentApp',
        title: {
          text: '',
          color: '#000000',
          font: {
            size: '14',
            strength: '400',
            family: 'Arial, sans-serif',
            align: 'left',
          },
          position: 'flex-start',
          titleError: '',
          disableSave: false,
          templateName: {
            text: ''
          },
          templateError: ''
        },
        subtitle: {
          text: '',
          color: '#000000',
          font: {
            size: '14',
            strength: '400',
            family: 'Arial, sans-serif',
            align: 'left',
          },
          position: 'left',
          titleError: '',
        },
        displayWizard: {
          themeId: '',
          themeIdError: '',
          displayType: 'popup',
          winningText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: '14',
              strength: '400',
              family: 'Arial, sans-serif',
              align: 'left',
            },
            error: '',
            errorImg: ''
          },
          losingText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: '14',
              strength: '400',
              family: 'Arial, sans-serif',
              align: 'left',
            },
            error: '',
            errorImg: ''
          },
        },
        formDisplayConfig: {
          labelPosition: 'aboveField',
          bannerImg: 'https://cdnstg.gamize.com/13/spinTheWheel/15/4260_black_friday.png',
          bannerImgName: 'blackFriday',
          bannerImgError: ''
        },
        libraries: {
          iconLibrary: [],
        },
        leadGeneration: {
          localCustomTitle: [],
          formFieldListSelected: [
            {
              title: 'E-mail ID',
              value: 'Text'
            },
            {
              title: 'Phone number',
              value: 'Number'
            }
          ],
          formFieldListSelectedError: '',
          formFieldList: [
            {
              title: 'E-mail ID',
              value: 'Text'
            },
            {
              title: 'Phone number',
              value: 'Number'
            },
            {
              title: 'Full name',
              value: 'Text'
            },
            {
              title: 'First name',
              value: 'Text'
            },
            {
              title: 'Middle name',
              value: 'Text'
            },
            {
              title: 'Last Name',
              value: 'Text'
            },
            {
              title: 'Country',
              value: 'Geolocation+dropdown'
            },
            {
              title: 'How did you hear about us?',
              value: 'List(dropdown)'
            },
            {
              title: 'Website',
              value: 'URL/Text'
            },
            {
              title: 'Postcode',
              value: 'Number'
            },
            {
              title: 'Company size',
              value: 'Number'
            },
            {
              title: 'Company name',
              value: 'Text'
            },
            {
              title: 'Job title',
              value: 'Text'
            },
            {
              title: 'Date of Birth',
              value: 'Date/Time'
            },
            {
              title: 'Location (city)',
              value: 'Geolocation+dropdown'
            },
            {
              title: 'Address',
              value: 'Text'
            },
            {
              title: 'Industry Vertical',
              value: 'Text'
            },
          ],
          optionalFields: [],
          customFields: [
            {
              title: '',
              value: '',
            },
          ],
          descriptionText: '',
          descriptionTextPosition: 'belowFields',
          lableFontStyle: 'Outfit, sans-serif',
          labelFontWt: '400',
          labelFontSize: '14',
          labelFontColor: '#272727',
          fieldFontStyle: 'Outfit, sans-serif',
          fieldFontWt: '400',
          fieldFontSize: '14',
          fieldFontColor: '#9B9A9A',
          defaultCardColor: '#2C2929',
          defaultBorderColor: '#FAF9F9',
          defaultThickness: '2px',
          defaultRadius: '10px',
          fieldCardColor: '#FAF9F9',
          fieldBorderColor: '#FAF9F9',
          fieldThickness: '2px',
          fieldRadius: '10px',
        },
        formButtonConfig: {
          submitButtonPlacement: 'insideForm',
          preFontStyle: 'Outfit, sans-serif',
          preFontWt: '400',
          preFontSize: '12',
          preFontColor: '#ffffff',
          postFontStyle: 'Outfit, sans-serif',
          postFontWt: '400',
          postFontSize: '12',
          postFontColor: '#ffffff',

          preButtonColor: '#EE1717',
          preBorderColor: '#ffffff',
          preThickness: '2px',
          preRadius: '10px',
          postButtonColor: '#EE1717',
          postBorderColor: '#ffffff',
          postThickness: '2px',
          postRadius: '10px',
        },

        additionalSettings: {
          titleFontStyle: 'Outfit, sans-serif',
          titleFontWt: '400',
          titleFontSize: '12',
          titleFontColor: '#ffffff',
          subtitleFontStyle: 'Outfit, sans-serif',
          subtitleFontWt: '400',
          subtitleFontSize: '12',
          subtitleFontColor: '#ffffff',
          buttonFontStyle: 'Outfit, sans-serif',
          buttonFontWt: '400',
          buttonFontSize: '12',
          buttonFontColor: '#ffffff',
          backgroundColor1: '#000000',
          backgroundColor2: '#000000',

          winningText: '',
          winningTextError: '',
          winningImg: '',
          winningImgName: '',
          winningImgError: '',
          losingText: '',
          losingTextError: '',
          losingImg: '',
          losingImgName: '',
          losingImgError: '',

          winningWebURL: '',
          winningAppURL: '',
          losingWebURL: '',
          losingAppURL: '',
          rewardIcon: '',
          rewardIconName: '',
          selectedRewardIconIndex: 1,
          offerDetailsTitle: {
            text: ''
          },
          instructionsTitle: {
            text: ''
          },
          winningMsgFontStyle: 'Outfit, sans-serif',
          winningMsgFontWt: '400',
          winningMsgFontSize: '12',
          winningMsgFontColor: '#ffffff',
          losingMsgFontStyle: 'Outfit, sans-serif',
          losingMsgFontWt: '400',
          losingMsgFontSize: '12',
          losingMsgFontColor: '#ffffff',
          offerDetailsFontStyle: 'Outfit, sans-serif',
          offerDetailsFontWt: '400',
          offerDetailsFontSize: '12',
          offerDetailsFontColor: '#ffffff',
          instructionsFontStyle: 'Outfit, sans-serif',
          instructionsFontWt: '400',
          instructionsFontSize: '12',
          instructionsFontColor: '#ffffff',
        },
        themeConfig: {},
      }
    ]
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPLOAD_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case SAVE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DUPLICATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case DUPLICATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DUPLICATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_FIELD: {
      state = state
        ? {
          ...state,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_TITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].title = state.config.localizationconfig[i].title
            ? {
              ...state.config.localizationconfig[i].title,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_SUBTITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].subtitle = state.config.localizationconfig[i].subtitle
            ? {
              ...state.config.localizationconfig[i].subtitle,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BACKGROUND_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].background = state.config.localizationconfig[i].background
            ? {
              ...state.config.localizationconfig[i].background,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_LAYER_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].scratchLayer = state.config.localizationconfig[i].scratchLayer
            ? {
              ...state.config.localizationconfig[i].scratchLayer,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BUTTON_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].buttonFormat = state.config.localizationconfig[i].buttonFormat
            ? {
              ...state.config.localizationconfig[i].buttonFormat,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BUTTON_FIELD_SCRATCH_CARD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].buttonFormatScratchCard = state.config.localizationconfig[i].buttonFormatScratchCard
            ? {
              ...state.config.localizationconfig[i].buttonFormatScratchCard,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BUTTON_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_QUESTIONS_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BUTTON: {
      console.log('ssssss', action.payload);
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_APPCONFIG_FIELD: {
      state.initialValues = state.initialValues
        ? {
          ...state.initialValues,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_LOCALIZATION_FLAG: {
      return { ...state, localizationFlag: action.payload };
    }
    case UPDATE_ACTIVE_FLAG: {
      return { ...state, activeNavTab: action.payload };
    }
    case UPDATE_CONFIG_FIELD: {
      const localizationconfig = [...state.config.localizationconfig, ...action.payload.localizationconfig];
      const config = {
        ...state.config,
        localizationconfig
      };
      return { ...state, config };
    }
    case REMOVE_CONFIG: {
      return { ...state, config: action.payload };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case UPDATE_COLLAPSE: {
      state.collapse = state.collapse
        ? {
          ...state.collapse,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_GRATIFY_BACKGROUND_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].gratifyBackground = state.config.localizationconfig[i].gratifyBackground
            ? {
              ...state.config.localizationconfig[i].gratifyBackground,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_CARDCONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].cardConfig = state.config.localizationconfig[i].cardConfig
            ? {
              ...state.config.localizationconfig[i].cardConfig,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_LEADGEN_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].leadGeneration = state.config.localizationconfig[i].leadGeneration
            ? {
              ...state.config.localizationconfig[i].leadGeneration,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_FORMDISPLAY_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].formDisplayConfig = state.config.localizationconfig[i].formDisplayConfig
            ? {
              ...state.config.localizationconfig[i].formDisplayConfig,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_FORMBUTTON_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].formButtonConfig = state.config.localizationconfig[i].formButtonConfig
            ? {
              ...state.config.localizationconfig[i].formButtonConfig,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_ADDSETTINGS_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].additionalSettings = state.config.localizationconfig[i].additionalSettings
            ? {
              ...state.config.localizationconfig[i].additionalSettings,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_DISPLAYWIZARD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].displayWizard = state.config.localizationconfig[i].displayWizard
            ? {
              ...state.config.localizationconfig[i].displayWizard,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    default:
      return state;
  }
};

export const updateTitle = (payload) => {
  return {
    type: UPDATE_TITLE_FIELD,
    payload,
  };
};

export const updateSubTitle = (payload) => {
  return {
    type: UPDATE_SUBTITLE_FIELD,
    payload,
  };
};

export const updateButtonFormat = (payload) => {
  return {
    type: UPDATE_BUTTON_FIELD,
    payload,
  };
};

export const updateButtonFormatScratchCard = (payload) => {
  return {
    type: UPDATE_BUTTON_FIELD_SCRATCH_CARD,
    payload,
  };
};

export const updateBackground = (payload) => {
  return {
    type: UPDATE_BACKGROUND_FIELD,
    payload,
  };
};
export const updateLayer = (payload) => {
  return {
    type: UPDATE_LAYER_FIELD,
    payload,
  };
};

export const uploadimage = (file, folderName) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/gamification/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
      .field('folderName', folderName)
  };
};
// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const saveScratchCardConfig = (data) => {
  return {
    types: [SAVE_CONFIG_REQUESTED, SAVE_CONFIG_SUCCESS, SAVE_CONFIG_FAILURE],
    promise: client => client.post('saveScratchCardConfig', {
      data,
    }),
  };
};

export const editScratchCardConfig = (data, gamificationId) => {
  return {
    types: [UPDATE_CONFIG_REQUESTED, UPDATE_CONFIG_SUCCESS, UPDATE_CONFIG_FAILURE],
    promise: client => client.post(`saveScratchCardConfig?gamificationId=${gamificationId}`, {
      data,
    }),
  };
};

export const getScratchCardConfig = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getSpinWheel?gamificationId=${data}`),
  };
};

export const duplicateSpinWheelTitle = (data, type, id) => {
  return {
    types: [DUPLICATE_CONFIG_REQUESTED, DUPLICATE_CONFIG_SUCCESS, DUPLICATE_CONFIG_FAILURE],
    promise: client => client.get(`duplicateSpinWheelTitle?title=${data}&type=${type}&id=${id}`)
  };
};

export const getTemplate = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getTemplate?templateId=${data}`),
  };
};

export const updateDropdown = (payload) => {
  return {
    type: UPDATE_APPCONFIG_FIELD,
    payload,
  };
};

export const getAppConfig = () => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get('getAppConfig'),
  };
};

export const updateLocalizationFlag = (payload) => {
  return {
    type: UPDATE_LOCALIZATION_FLAG,
    payload,
  };
};


export const updateActiveFlag = (payload) => {
  return {
    type: UPDATE_ACTIVE_FLAG,
    payload,
  };
};

export const updateConfig = (payload) => {
  return {
    type: UPDATE_CONFIG_FIELD,
    payload
  };
};

export const removeConfig = (payload) => {
  return {
    type: REMOVE_CONFIG,
    payload
  };
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const updateButton = (payload) => {
  return {
    type: UPDATE_BUTTON,
    payload
  };
};

export const updateButtonConfig = (payload) => {
  return {
    type: UPDATE_BUTTON_CONFIG,
    payload
  };
};


export const updateQuestions = (payload) => {
  return {
    type: UPDATE_QUESTIONS_FIELD,
    payload,
  };
};

export const updateCollapse = (payload) => {
  return {
    type: UPDATE_COLLAPSE,
    payload
  };
};

export const updateGratifyBackground = (payload) => {
  return {
    type: UPDATE_GRATIFY_BACKGROUND_FIELD,
    payload,
  };
};

export const updateCardConfig = (payload) => {
  return {
    type: UPDATE_CARDCONFIG,
    payload
  };
};

export const updateLeadGeneration = (payload) => {
  return {
    type: UPDATE_LEADGEN_FIELD,
    payload,
  };
};

export const updateFormDisplayConfig = (payload) => {
  return {
    type: UPDATE_FORMDISPLAY_CONFIG,
    payload,
  };
};

export const updateFormButtonConfig = (payload) => {
  return {
    type: UPDATE_FORMBUTTON_CONFIG,
    payload,
  };
};

export const updateAdditionalSettings = (payload) => {
  return {
    type: UPDATE_ADDSETTINGS_CONFIG,
    payload,
  };
};

export const updateDisplayWizard = (payload) => {
  return {
    type: UPDATE_DISPLAYWIZARD,
    payload,
  };
};
