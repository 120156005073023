const TITLE = '';

export default {

  CURRENT_ACCOUNT_HOST_NAME: 'https://api.gamize.com/GamificationAdminService',
  // CURRENT_ACCOUNT_HOST_NAME: 'http://localhost:8081/GamificationAdminService',
  CURRENT_GAMIFICATION_HOST_NAME: 'https://api.gamize.com/GamificationService',
  // CURRENT_GAMIFICATION_HOST_NAME: 'http://localhost:8082/GamificationService',
  GAMIFICATION_CAMPAIGN_HOST_NAME: 'https://api.gamize.com/GamificationCampaignService',
  // GAMIFICATION_CAMPAIGN_HOST_NAME: 'http://localhost:8084/GamificationCampaignService',
  LOGIN: `${TITLE} Login`,
  SIGNUP: `${TITLE} Sign up`,
  RESET_PASSWORD: `${TITLE} Reset password`,
  BASE_URL: process.env.PUBLIC_URL,
  PAGE_SIZE: 5,
  ITEM_PER_PAGE_5: 5,
  ITEM_PER_PAGE_10: 10,
  ORDER_ASC: 'asc',
  ORDER_DESC: 'desc',
  INTERVAL_TIME: 600000,
  HOST_TYPE: 'localhost',
  BROWSER: 'Browser',
  ERROR: 'ERROR',
  STUDENT: 'Student',
};
