
import CryptoJS from 'crypto-js';
import { Buffer } from 'buffer';

// key must be same as in server
const encryptedBase64Key = Buffer.from('gamelysecretkeys').toString('base64');
const parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);

export const encrypt = (value) => {
  // this is Base64-encoded encrypted data
  const encryptedData = CryptoJS.AES.encrypt(value, parsedBase64Key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return encryptedData.toString();
};

export const decrypt = (value) => {
  const decryptedData = CryptoJS.AES.decrypt(value, parsedBase64Key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
    // this is the decrypted data as a string
  return decryptedData.toString(CryptoJS.enc.Utf8);
};
