
export const GET_NOTIFICATION_REQUESTED = 'notification/GET_NOTIFICATION_REQUESTED';
export const GET_NOTIFICATION_SUCCESS = 'notification/GET_NOTIFICATION_REQUESTED';
export const GET_NOTIFICATION_FAILURE = 'notification/GET_NOTIFICATION_REQUESTED';

export const GET_NOTIFICATION_LIST_REQUESTED = 'notification/GET_NOTIFICATION_LIST_REQUESTED';
export const GET_NOTIFICATION_LIST_SUCCESS = 'notification/GET_NOTIFICATION_LIST_REQUESTED';
export const GET_NOTIFICATION_LIST_FAILURE = 'notification/GET_NOTIFICATION_LIST_REQUESTED';

export const UPDATE_FIELD = 'notification/UPDATE_FIELD';
export const UPDATE_TEMPLATE_PAGE = 'notification/UPDATE_TEMPLATE_PAGE';
export const UPDATE_COUNT = 'notification/UPDATE_COUNT';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorDesc: [],
  notificationCount: 0,
  notificationList: {
    count: 0,
    total: 0,
    list: []
  },
  initialData: {
    itemsPerPage: 13,
    total: 0,
    pageNo: 0,
    searchKey: '',
    sortBy: '',
    orderBy: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_NOTIFICATION_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        notificationCount: action.result.count
      };
    }
    case GET_NOTIFICATION_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_NOTIFICATION_LIST_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_NOTIFICATION_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_NOTIFICATION_LIST_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_FIELD: {
      state.notificationList = state.notificationList
        ? {
          ...state.notificationList,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_TEMPLATE_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case UPDATE_COUNT: {
      const notificationCount = action.payload;
      return { ...state, notificationCount };
    }
    default:
      return state;
  }
};

export const getNotification = () => {
  return {
    types: [GET_NOTIFICATION_REQUESTED, GET_NOTIFICATION_SUCCESS, GET_NOTIFICATION_FAILURE],
    promise: client => client.get('getNotification')
  };
};

export const getNotificationList = (data) => {
  return {
    types: [GET_NOTIFICATION_LIST_REQUESTED, GET_NOTIFICATION_LIST_SUCCESS, GET_NOTIFICATION_LIST_FAILURE],
    promise: client => client.get(`getNotificationList/${data.itemsPerPage}/${data.pageNo}?sortBy=${''}&sortType=${''}`)
  };
};

export const readNotification = (data) => {
  return {
    types: [GET_NOTIFICATION_LIST_REQUESTED, GET_NOTIFICATION_LIST_SUCCESS, GET_NOTIFICATION_LIST_FAILURE],
    promise: client => client.post(`readNotification?ids=${data}`)
  };
};

export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updateTemplatePage = (payload) => {
  return {
    type: UPDATE_TEMPLATE_PAGE,
    payload,
  };
};

export const updateCount = (payload) => {
  return {
    type: UPDATE_COUNT,
    payload,
  };
};
