import { CONSTANTS } from '@constants';

export const UPDATE_FIELD = 'usersegment/UPDATE_FIELD';
export const UPDATE_PAGE = 'usersegment/UPDATE_PAGE';
export const CLEAR_FORM = 'usersegment/CLEAR_FORM';
export const CREATE_OPTIONS = 'usersegment/CREATE_OPTIONS';
export const UPDATE_LIST_FIELD = 'usersegment/UPDATE_LIST_FIELD';
export const CLEAR_STATE = 'usersegment/CLEAR_STATE';

export const UPDATE_PROPERTY_FIELD = 'UPDATE_PROPERTY_FIELD';
export const REMOVE_PROPERTY_FIELD = 'REMOVE_PROPERTY_FIELD';

export const GET_USER_EVENT_REQUEST = 'GET_USER_EVENT_REQUEST';
export const GET_USER_EVENT_SUCCESS = 'GET_USER_EVENT_SUCCESS';
export const GET_USER_EVENT_FAILURE = 'GET_USER_EVENT_FAILURE';

export const GET_EVENT_PROPERTY_REQUEST = 'GET_EVENT_PROPERTY_REQUEST';
export const GET_EVENT_PROPERTY_SUCCESS = 'GET_EVENT_PROPERTY_SUCCESS';
export const GET_EVENT_PROPERTY_FAILURE = 'GET_EVENT_PROPERTY_FAILURE';

export const SAVE_USER_SEGMENT_REQUEST = 'SAVE_USER_SEGMENT_REQUEST';
export const SAVE_USER_SEGMENT_SUCCESS = 'SAVE_USER_SEGMENT_SUCCESS';
export const SAVE_USER_SEGMENT_FAILURE = 'SAVE_USER_SEGMENT_FAILURE';

export const GET_USER_SEGMENT_REQUEST = 'GET_USER_SEGMENT_REQUEST';
export const GET_USER_SEGMENT_SUCCESS = 'GET_USER_SEGMENT_SUCCESS';
export const GET_USER_SEGMENT_FAILURE = 'GET_USER_SEGMENT_FAILURE';

export const CHECK_SEGMENT_NAME_REQUEST = 'CHECK_SEGMENT_NAME_REQUEST';
export const CHECK_SEGMENT_NAME_SUCCESS = 'CHECK_SEGMENT_NAME_SUCCESS';
export const CHECK_SEGMENT_NAME_FAILURE = 'CHECK_SEGMENT_NAME_FAILURE';
export const GET_SEGMENT_LIST_REQUESTED = 'get/GET_SEGMENT_LIST_REQUESTED';
export const GET_SEGMENT_LIST_SUCCESS = 'get/GET_SEGMENT_LIST_SUCCESS';
export const GET_SEGMENT_LIST_FAILURE = 'get/GET_SEGMENT_LIST_FAILURE';

export const DUPLICATE_CONFIG_REQUESTED = 'get/DUPLICATE_CONFIG_REQUESTED';
export const DUPLICATE_CONFIG_SUCCESS = 'get/DUPLICATE_CONFIG_SUCCESS';
export const DUPLICATE_CONFIG_FAILURE = 'get/DUPLICATE_CONFIG_FAILURE';

export const DELETE_CONFIG_REQUESTED = 'get/DELETE_CONFIG_REQUESTED';
export const DELETE_CONFIG_SUCCESS = 'get/DELETE_CONFIG_SUCCESS';
export const DELETE_CONFIG_FAILURE = 'get/DELETE_CONFIG_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  popup: false,
  mode: 'save',
  options: {
    userEvents: [],
    property: [],
    string: [
      {
        title: CONSTANTS.EQUAL,
        value: 'equal',
      },
      {
        title: '!= (not_equal)',
        value: 'not_equal',
      },
      {
        title: 'Like',
        value: 'like',
      },
      {
        title: 'is_empty',
        value: 'is_empty',
      },
      {
        title: 'is_null',
        value: 'is_null',
      }
    ],
    number: [
      {
        title: CONSTANTS.EQUAL,
        value: 'equal',
      },
      {
        title: CONSTANTS.NOT_EQUAL,
        value: 'not_equal',
      },
      {
        title: '< (less)',
        value: 'less',
      },
      {
        title: CONSTANTS.GREATER,
        value: 'greater',
      },
      {
        title: CONSTANTS.LESSER_OR_EQUAL,
        value: 'less_or_equal',
      },
      {
        title: CONSTANTS.GREATER_OR_EQUAL,
        value: 'greater_or_equal',
      }
    ],
    date_time: [
      {
        title: CONSTANTS.EQUAL,
        value: 'equal',
      },
      {
        title: CONSTANTS.NOT_EQUAL,
        value: 'not_equal',
      },
      {
        title: '< (less)',
        value: 'less',
      },
      {
        title: CONSTANTS.GREATER,
        value: 'greater',
      },
      {
        title: CONSTANTS.LESSER_OR_EQUAL,
        value: 'less_or_equal',
      },
      {
        title: '>=(greater or equal)',
        value: 'greater_or_equal',
      },
      {
        title: 'between',
        value: 'between'
      }
    ],
    time: [
      {
        title: CONSTANTS.EQUAL,
        value: 'equal',
      },
      {
        title: CONSTANTS.NOT_EQUAL,
        value: 'not_equal',
      },
      {
        title: '< (less)',
        value: 'less',
      },
      {
        title: CONSTANTS.GREATER,
        value: 'greater',
      },
      {
        title: CONSTANTS.LESSER_OR_EQUAL,
        value: 'less_or_equal',
      },
      {
        title: CONSTANTS.GREATER_OR_EQUAL,
        value: 'greater_or_equal',
      },
      {
        title: 'between',
        value: 'between'
      }
    ],
    date: [
      {
        title: CONSTANTS.EQUAL,
        value: 'equal',
      },
      {
        title: CONSTANTS.NOT_EQUAL,
        value: 'not_equal',
      },
      {
        title: '< (less)',
        value: 'less',
      },
      {
        title: CONSTANTS.GREATER,
        value: 'greater',
      },
      {
        title: CONSTANTS.LESSER_OR_EQUAL,
        value: 'less_or_equal',
      },
      {
        title: CONSTANTS.GREATER_OR_EQUAL,
        value: 'greater_or_equal',
      },
      {
        title: 'between',
        value: 'between'
      }
    ],
    days: []
  },
  events: [{
    id: 1,
    property: [],
    userEvent: '',
    operator: 'none',
    days: 7,
    userProperty: [{
      id: 1,
      name: '',
      type: '',
      operator: '',
      value: [],
      options: []
    }]
  }],
  fields: {},
  uploadSegment: false,
  file: '',
  fileName: '',
  nameSegment: '',
  isErrorOccur: '',
  nameSegmentError: '',
  total: 0,
  male: 0,
  female: 0,
  tableData: {},
  initialData: {
    pageNo: 0,
    itemsPerPage: 5,
    title: '',
    sortBy: 'updatedTS',
    orderBy: 'desc',
    searchKey: '',
    deleteRuleList: {
      templateName: '',
      list: []
    },
    headers: [
      {
        title: CONSTANTS.SEGMENT_NAME_TITLE,
        slug: 'segment_name',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: false,
        sortByOrder: '',
        width: 110
      },
      // {
      //   title: 'Status',
      //   slug: 'status',
      //   isSortable: true,
      //   isVisible: true,
      //   tooltip: false,
      //   center: true,
      //   sortByOrder: 'desc',
      //   width: 150
      // },
      {
        title: CONSTANTS.COUNT,
        slug: 'segment_count',
        isSortable: true,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: 'desc',
        width: 200,
      },
      {
        title: CONSTANTS.CREATEDBY,
        slug: 'createdby',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: '',
        width: 90,
      },
      {
        title: CONSTANTS.START_DATE_TIME,
        slug: 'start_date',
        isSortable: true,
        isVisible: true,
        tooltip: true,
        center: false,
        sortByOrder: 'desc',
        width: 200,
      },
      {
        title: 'Actions',
        slug: 'actions',
        isSortable: false,
        isVisible: false,
        tooltip: true,
        sortByOrder: '',
        width: 180,
      },
    ]
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_FORM: {
      return {
        ...initialState,
      };
    }
    case UPDATE_FIELD: {
      state = state
        ? {
          ...state,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case GET_USER_EVENT_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_USER_EVENT_SUCCESS: {
      const eventList = action.result.eventList;
      let { options } = state;
      options = {
        ...options,
        userEvents: eventList
      };
      return {
        ...state,
        options,
        loading: false,
        error: false,
        loaded: true
      };
    } case GET_USER_EVENT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case SAVE_USER_SEGMENT_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false,
        isLoading: true,
      };
    }
    case SAVE_USER_SEGMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        isLoading: false
      };
    } case SAVE_USER_SEGMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        isLoading: false
      };
    }
    case GET_EVENT_PROPERTY_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_EVENT_PROPERTY_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    } case GET_EVENT_PROPERTY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case CREATE_OPTIONS: {
      const days = [];
      for (let i = 1; i <= 31; i += 1) {
        days.push({
          title: i,
          value: i
        });
      }
      let { options } = state;
      options = {
        ...options,
        days
      };
      return {
        ...state,
        options,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_SEGMENT_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        isLoading: true,
      };
    }
    case GET_SEGMENT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        isLoading: false
      };
    } case GET_SEGMENT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        isLoading: false
      };
    }
    case UPDATE_LIST_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case DUPLICATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DUPLICATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DUPLICATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DELETE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DELETE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const createOptions = (type) => {
  if (type === 'days') {
    return {
      type: CREATE_OPTIONS,
    };
  }
  return {};
};


export const clearForm = () => {
  return {
    type: CLEAR_FORM,
  };
};

// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updateListFields = (payload) => {
  return {
    type: UPDATE_LIST_FIELD,
    payload,
  };
};


export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const getEvents = () => {
  return {
    types: [GET_USER_EVENT_REQUEST, GET_USER_EVENT_SUCCESS, GET_USER_EVENT_FAILURE],
    promise: client => client.get('getUserEvents')
  };
};

export const getCustomEvents = () => {
  return {
    types: [GET_USER_EVENT_REQUEST, GET_USER_EVENT_SUCCESS, GET_USER_EVENT_FAILURE],
    promise: client => client.get('getCustomUserEvents')
  };
};

export const getEventProperty = (eventName) => {
  return {
    types: [GET_EVENT_PROPERTY_REQUEST, GET_EVENT_PROPERTY_SUCCESS, GET_EVENT_PROPERTY_FAILURE],
    promise: client => client.get(`getEventProperty?eventName=${eventName}`)
  };
};

export const getCustomEventProperty = (eventKey) => {
  console.log('event Nmae', eventKey);
  return {
    types: [GET_EVENT_PROPERTY_REQUEST, GET_EVENT_PROPERTY_SUCCESS, GET_EVENT_PROPERTY_FAILURE],
    promise: client => client.get(`getCustomEventProperty?eventKey=${eventKey}`)
  };
};

export const saveUserSegment = (userData, file) => {
  if (file !== '') {
    const data = new FormData();
    data.append('file', file);
    data.append('payload', JSON.stringify(userData));
    return {
      types: [SAVE_USER_SEGMENT_REQUEST, SAVE_USER_SEGMENT_SUCCESS, SAVE_USER_SEGMENT_FAILURE],
      promise: client => client.post('saveUserSegmentFile', {
        data
      })
    };
  }
  const data = userData;
  return {
    types: [SAVE_USER_SEGMENT_REQUEST, SAVE_USER_SEGMENT_SUCCESS, SAVE_USER_SEGMENT_FAILURE],
    promise: client => client.post('saveUserSegment', {
      data
    })
  };
};

export const getUserSegment = (segementId) => {
  return {
    types: [SAVE_USER_SEGMENT_REQUEST, SAVE_USER_SEGMENT_SUCCESS, SAVE_USER_SEGMENT_FAILURE],
    promise: client => client.get(`getUserSegment?segmentId=${segementId}`)
  };
};

export const viewDetail = (data) => {
  return {
    types: [SAVE_USER_SEGMENT_REQUEST, SAVE_USER_SEGMENT_SUCCESS, SAVE_USER_SEGMENT_FAILURE],
    promise: client => client.post('getUserSegmentDetail', {
      data
    })
  };
};

export const getUserSegmentList = (data) => {
  return {
    types: [GET_SEGMENT_LIST_REQUESTED, GET_SEGMENT_LIST_SUCCESS, GET_SEGMENT_LIST_FAILURE],
    promise: client => client.get(`getUserSegmentList/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&sortType=${data.orderBy}`)
  };
};

export const duplicateUserSegment = (data) => {
  return {
    types: [DUPLICATE_CONFIG_REQUESTED, DUPLICATE_CONFIG_SUCCESS, DUPLICATE_CONFIG_FAILURE],
    promise: client => client.post(`duplicateUserSegment?segementId=${data}`),
  };
};

export const deleteUserSegment = (data) => {
  return {
    types: [DELETE_CONFIG_REQUESTED, DELETE_CONFIG_SUCCESS, DELETE_CONFIG_FAILURE],
    promise: client => client.post(`deleteUserSegment?segementId=${data}`),
  };
};

export const checkNameDublicate = (name, id) => {
  return {
    types: [CHECK_SEGMENT_NAME_REQUEST, CHECK_SEGMENT_NAME_SUCCESS, CHECK_SEGMENT_NAME_FAILURE],
    promise: client => client.get(`checkUserSegmentName?name=${name}&id=${id}`)
  };
};

export const checkDeleteUserSegment = (data) => {
  return {
    types: [DELETE_CONFIG_REQUESTED, DELETE_CONFIG_SUCCESS, DELETE_CONFIG_FAILURE],
    promise: client => client.post(`checkDeleteUserSegment?segementId=${data}`),
  };
};
