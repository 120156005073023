import { APP_CONFIG } from '@constants';
import constants from '../constants/constants';


const upload = require('superagent');

export const UPDATE_TITLE_FIELD = 'slotmachineConfig/UPDATE_TITLE_FIELD';
export const UPDATE_SUBTITLE_FIELD = 'slotmachineConfig/UPDATE_SUBTITLE_FIELD';
export const UPDATE_SLOTCONFIG_FIELD = 'slotmachineConfig/UPDATE_SLOTCONFIG_FIELD';
export const UPDATE_RESULT_FIELD = 'slotmachineConfig/UPDATE_RESULT_FIELD';
export const UPDATE_APPCONFIG_FIELD = 'slotmachineConfig/UPDATE_APPCONFIG_FIELD';
export const UPDATE_BUTTON_CONFIG = 'slotmachineConfig/UPDATE_BUTTON_CONFIG';
export const UPDATE_BUTTON = 'slotmachineConfig/UPDATE_BUTTON';
export const UPDATE_QUESTIONS_FIELD = 'slotmachineConfig/UPDATE_QUESTIONS_FIELD';


export const UPLOAD_IMAGE_REQUESTED = 'upload/UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_IMAGE_SUCCESS = 'upload/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'upload/UPLOAD_IMAGE_FAILURE';

export const SAVE_CONFIG_REQUESTED = 'save/SAVE_CONFIG_REQUESTED';
export const SAVE_CONFIG_SUCCESS = 'save/SAVE_CONFIG_SUCCESS';
export const SAVE_CONFIG_FAILURE = 'save/SAVE_CONFIG_FAILURE';

export const UPDATE_CONFIG_REQUESTED = 'update/UPDATE_CONFIG_REQUESTED';
export const UPDATE_CONFIG_SUCCESS = 'update/UPDATE_CONFIG_SUCCESS';
export const UPDATE_CONFIG_FAILURE = 'update/UPDATE_CONFIG_FAILURE';

export const GET_CONFIG_REQUESTED = 'get/GET_CONFIG_REQUESTED';
export const GET_CONFIG_SUCCESS = 'get/GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'get/GET_CONFIG_FAILURE';

export const DUPLICATE_CONFIG_REQUESTED = 'get/DUPLICATE_CONFIG_REQUESTED';
export const DUPLICATE_CONFIG_SUCCESS = 'get/DUPLICATE_CONFIG_SUCCESS';
export const DUPLICATE_CONFIG_FAILURE = 'get/DUPLICATE_CONFIG_FAILURE';

export const UPDATE_FIELD = 'slotmachineConfig/UPDATE_FIELD';
export const UPDATE_PAGE = 'slotmachineConfig/UPDATE_PAGE';

export const UPDATE_ACTIVE_FLAG = 'slotmachineConfig/UPDATE_ACTIVE_FLAG';
export const UPDATE_CONFIG_FIELD = 'slotmachineConfig/UPDATE_CONFIG_FIELD';
export const UPDATE_LOCALIZATION_FLAG = 'slotmachineConfig/UPDATE_LOCALIZATION_FLAG';
export const REMOVE_CONFIG = 'slotmachineConfig/REMOVE_CONFIG';
export const CLEAR_STATE = 'slotmachineConfig/CLEAR_STATE';

const initialState = {
  loading: false,
  loaded: false,
  imageloading: false,
  imageloaded: false,
  error: false,
  initialValues: {
    fontTitlePositions: [],
    slotItems: [],
    noOfSpin: [],
    noOfElements: [],
    fonts: [],
    fontStrength: [],
    fontSize: [],
    spinButtonPosition: [],
    spinButtonWidth: [],
    spinButtonRadius: [],
    spinButtonShadow: [],
    fontPositions: [],
    outlineStyles: []
  },
  localizationFlag: '',
  activeNavTab: 'English',
  config: {
    localizationconfig: [
      {
        language: 'English',
        resultHandler: 'ParentApp',
        title: {
          text: '',
          color: '#000000',
          font: {
            size: '14',
            strength: '400',
            family: 'Arial, sans-serif',
            align: 'left',
          },
          position: 'flex-start',
          titleError: '',
          disableSave: false,
          templateName: {
            text: ''
          },
          templateError: ''
        },
        subtitle: {
          text: '',
          color: '#000000',
          font: {
            size: '14',
            strength: '400',
            family: 'Arial, sans-serif',
            align: 'left',
          },
          titleError: '',
        },
        buttonNeeded: true,
        buttonConfig: [
          {
            text: {
              text: '',
              color: '#000000',
              font: {
                size: '14',
                strength: '400',
                family: 'Arial, sans-serif',
                align: 'left',
              },
            },
            textColor: '#000000',
            image: {
              url: '',
              name: ''
            },
            imageError: '',
            width: 'auto',
            radius: '30px',
            shadow: '0px 0px',
            shadowColor: '#cdcdcd',
            position: 'left',
            bgColor: '#ffffff',
            border: '#000000',
            hoverBgColor: '#cdcdcd',
            buttonTextError: '',
            borderWidth: '1px',
            appLink: {
              text: ''
            },
            webLink: {
              text: ''
            },
            appLinkError: '',
            webLinkError: ''
          }
        ],
        slotConfig: {
          noelements: '6',
          spin: '1',
          slotImage: '',
          bgImage: '',
          bgImageName: '',
          bgColor: '#032AA7',
          elements: [],
          slotElements: [
            constants.URL,
            'https://www.googleapis.com/download/storage/v1/b/ec2-us-east-1-1b-dev-images-gamification/o/slotMachine%2Fupload%2F7996_yellow-diamond.png?alt=media',
            'https://www.googleapis.com/download/storage/v1/b/ec2-us-east-1-1b-dev-images-gamification/o/slotMachine%2Fupload%2F7756_blue-diamond.png?alt=media',
            'https://www.googleapis.com/download/storage/v1/b/ec2-us-east-1-1b-dev-images-gamification/o/slotMachine%2Fupload%2F6007_brown-diamond.png?alt=media',
            'https://www.googleapis.com/download/storage/v1/b/ec2-us-east-1-1b-dev-images-gamification/o/slotMachine%2Fupload%2F5208_pink-diamond.png?alt=media',
            'https://www.googleapis.com/download/storage/v1/b/ec2-us-east-1-1b-dev-images-gamification/o/slotMachine%2Fupload%2F7900_purple-diamond.png?alt=media'
          ],
          backgroundError: '',
          winElements: [
            constants.URL,
            constants.URL,
            constants.URL
          ],
          elementError: '',
          spinButtonImage: 'https://www.googleapis.com/download/storage/v1/b/ec2-us-east-1-1b-dev-images-gamification/o/slotMachine%2F7814_slot-machine-spinbutton-up.png?alt=media',
          textCrown: 'https://www.googleapis.com/download/storage/v1/b/ec2-us-east-1-1b-dev-images-gamification/o/slotMachine%2F2327_slot-machine-crown.png?alt=media'
        },
        resultText: {
          winningText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: '14',
              strength: '400',
              family: 'Arial, sans-serif',
              align: 'left',
            },
            error: '',
            errorImg: ''
          },
          losingText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: '14',
              strength: '400',
              family: 'Arial, sans-serif',
              align: 'left',
            },
            error: '',
            errorImg: ''
          }
        },
      }
    ]
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FIELD: {
      state = state
        ? {
          ...state,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_TITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].title = state.config.localizationconfig[i].title
            ? {
              ...state.config.localizationconfig[i].title,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_SUBTITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].subtitle = state.config.localizationconfig[i].subtitle
            ? {
              ...state.config.localizationconfig[i].subtitle,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_SLOTCONFIG_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].slotConfig = state.config.localizationconfig[i].slotConfig
            ? {
              ...state.config.localizationconfig[i].slotConfig,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_QUESTIONS_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case SAVE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DUPLICATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case DUPLICATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DUPLICATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_RESULT_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].resultText = state.config.localizationconfig[i].resultText
            ? {
              ...state.config.localizationconfig[i].resultText,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_APPCONFIG_FIELD: {
      state.initialValues = state.initialValues
        ? {
          ...state.initialValues,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_CONFIG_FIELD: {
      const localizationconfig = [...state.config.localizationconfig, ...action.payload.localizationconfig];
      const config = {
        ...state.config,
        localizationconfig
      };
      return { ...state, config };
    }
    case UPDATE_LOCALIZATION_FLAG: {
      return { ...state, localizationFlag: action.payload };
    }
    case UPDATE_ACTIVE_FLAG: {
      return { ...state, activeNavTab: action.payload };
    }
    case REMOVE_CONFIG: {
      return { ...state, config: action.payload };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case UPDATE_BUTTON_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BUTTON: {
      console.log('ssssss', action.payload);
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    default:
      return state;
  }
};

export const updateTitle = (payload) => {
  return {
    type: UPDATE_TITLE_FIELD,
    payload,
  };
};

export const updateSubTitle = (payload) => {
  return {
    type: UPDATE_SUBTITLE_FIELD,
    payload,
  };
};
export const updateSlot = (payload) => {
  return {
    type: UPDATE_SLOTCONFIG_FIELD,
    payload,
  };
};

export const uploadimage = (file, folderName) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/gamification/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
      .field('folderName', folderName),
  };
};
// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const saveSlotMachineConfig = (data) => {
  return {
    types: [SAVE_CONFIG_REQUESTED, SAVE_CONFIG_SUCCESS, SAVE_CONFIG_FAILURE],
    promise: client => client.post('saveScratchCardConfig', {
      data,
    }),
  };
};

export const getSlotMachineConfig = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getSpinWheel?gamificationId=${data}`),
  };
};

export const editSlotMachineConfig = (data, gamificationId) => {
  return {
    types: [UPDATE_CONFIG_REQUESTED, UPDATE_CONFIG_SUCCESS, UPDATE_CONFIG_FAILURE],
    promise: client => client.post(`saveScratchCardConfig?gamificationId=${gamificationId}`, {
      data,
    }),
  };
};

export const duplicateSlotMachineTitle = (data, type, id) => {
  return {
    types: [DUPLICATE_CONFIG_REQUESTED, DUPLICATE_CONFIG_SUCCESS, DUPLICATE_CONFIG_FAILURE],
    promise: client => client.get(`duplicateSpinWheelTitle?title=${data}&type=${type}&id=${id}`)
  };
};

export const updateButton = (payload) => {
  return {
    type: UPDATE_BUTTON,
    payload
  };
};


export const getTemplate = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getTemplate?templateId=${data}`),
  };
};

export const updateResult = (payload) => {
  return {
    type: UPDATE_RESULT_FIELD,
    payload,
  };
};

export const getAppConfig = () => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get('getAppConfig'),
  };
};

export const updateDropdown = (payload) => {
  return {
    type: UPDATE_APPCONFIG_FIELD,
    payload,
  };
};

export const updateActiveFlag = (payload) => {
  return {
    type: UPDATE_ACTIVE_FLAG,
    payload,
  };
};

export const updateConfig = (payload) => {
  return {
    type: UPDATE_CONFIG_FIELD,
    payload
  };
};

export const updateLocalizationFlag = (payload) => {
  return {
    type: UPDATE_LOCALIZATION_FLAG,
    payload,
  };
};

export const updateButtonConfig = (payload) => {
  return {
    type: UPDATE_BUTTON_CONFIG,
    payload
  };
};

export const removeConfig = (payload) => {
  return {
    type: REMOVE_CONFIG,
    payload
  };
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const updateQuestions = (payload) => {
  return {
    type: UPDATE_QUESTIONS_FIELD,
    payload,
  };
};
