import { APP_CONFIG } from '@constants';

const upload = require('superagent');

export const UPDATE_TITLE_FIELD = 'spinWheelConfig/UPDATE_TITLE_FIELD';
export const UPDATE_SUBTITLE_FIELD = 'spinWheelConfig/UPDATE_SUBTITLE_FIELD';
export const UPDATE_QUADRANTS_FIELD = 'spinWheelConfig/UPDATE_QUADRANTS_FIELD';
export const UPDATE_BACKGROUND_FIELD = 'spinWheelConfig/UPDATE_BACKGROUND_FIELD';
export const UPDATE_SPINBUTTON_FIELD = 'spinWheelConfig/UPDATE_SPINBUTTON_FIELD';
export const UPDATE_APPCONFIG_FIELD = 'spinWheelConfig/UPDATE_APPCONFIG_FIELD';
export const UPDATE_BUTTON_CONFIG = 'spinWheelConfig/UPDATE_BUTTON_CONFIG';
export const UPDATE_QUESTIONS_FIELD = 'spinWheelConfig/UPDATE_QUESTIONS_FIELD';
export const UPDATE_LEADGEN_FIELD = 'spinWheelConfig/UPDATE_LEADGEN_FIELD';


export const UPLOAD_IMAGE_REQUESTED = 'upload/UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_IMAGE_SUCCESS = 'upload/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'upload/UPLOAD_IMAGE_FAILURE';

export const SAVE_CONFIG_REQUESTED = 'save/SAVE_CONFIG_REQUESTED';
export const SAVE_CONFIG_SUCCESS = 'save/SAVE_CONFIG_SUCCESS';
export const SAVE_CONFIG_FAILURE = 'save/SAVE_CONFIG_FAILURE';

export const GET_CONFIG_REQUESTED = 'get/GET_CONFIG_REQUESTED';
export const GET_CONFIG_SUCCESS = 'get/GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'get/GET_CONFIG_FAILURE';

export const UPDATE_CONFIG_REQUESTED = 'update/UPDATE_CONFIG_REQUESTED';
export const UPDATE_CONFIG_SUCCESS = 'update/UPDATE_CONFIG_SUCCESS';
export const UPDATE_CONFIG_FAILURE = 'update/UPDATE_CONFIG_FAILURE';

export const DUPLICATE_CONFIG_REQUESTED = 'get/DUPLICATE_CONFIG_REQUESTED';
export const DUPLICATE_CONFIG_SUCCESS = 'get/DUPLICATE_CONFIG_SUCCESS';
export const DUPLICATE_CONFIG_FAILURE = 'get/DUPLICATE_CONFIG_FAILURE';
export const UPDATE_BUTTON = 'spinWheelConfig/UPDATE_BUTTON';


export const UPDATE_FIELD = 'spinWheelConfig/UPDATE_FIELD';
export const UPDATE_PAGE = 'spinWheelConfig/UPDATE_PAGE';
export const UPDATE_LANGUAGE = 'spinWheelConfig/UPDATE_LANGUAGE';
export const UPDATE_CONFIG_FIELD = 'spinWheelConfig/UPDATE_CONFIG_FIELD';
export const UPDATE_LOCALIZATION_FLAG = 'spinWheelConfig/UPDATE_LOCALIZATION_FLAG';
export const UPDATE_ACTIVE_FLAG = 'spinWheelConfig/UPDATE_ACTIVE_FLAG';
export const CLEAR_STATE = 'spinWheelConfig/CLEAR_STATE';
export const REMOVE_CONFIG = 'spinWheelConfig/REMOVE_CONFIG';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  initialValues: {
    fontTitlePositions: [],
    spinButtonPosition: [],
    spinButtonWidth: [],
    spinButtonRadius: [],
    spinButtonShadow: [],
    fontPositions: [],
    outlineStyles: [],
    fonts: [],
    fontStrength: [],
    fontSize: []
  },
  localizationFlag: '',
  activeNavTab: 'English',
  config: {
    localizationconfig: [
      {
        language: 'English',
        title: {
          text: '',
          color: '#000000',
          font: {
            size: '14',
            strength: '400',
            family: 'Arial, sans-serif',
            align: 'left',
          },
          position: 'flex-start',
          titleError: '',
          disableSave: false,
          templateName: {
            text: ''
          },
          templateError: ''
        },
        resultHandler: 'ParentApp',
        subtitle: {
          text: '',
          color: '#000000',
          font: {
            size: '14',
            strength: '400',
            family: 'Arial, sans-serif',
            align: 'left',
          },
          titleError: '',
        },
        quadrants: {
          totalNoOfQuadrants: 12,
          quadrantsType: false,
          numberOfQuadrants: 4,
          quadrantsList: [],
          quadrantsFont: {
            size: '14',
            strength: '400',
            family: 'Arial, sans-serif',
            align: 'left',
          },
          displaySegmentText: true
        },
        background: {
          currentImage: '',
          color: '#ffffff',
          image: '',
          imageName: '',
          backgroundError: '',
          showProgressBar: false,
          wheelImage: '',
          winningText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: '14',
              strength: '400',
              family: 'Arial, sans-serif',
              align: 'left',
            },
            error: '',
            errorImg: ''
          },
          losingText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: '14',
              strength: '400',
              family: 'Arial, sans-serif',
              align: 'left',
            },
            error: '',
            errorImg: ''
          }
        },
        buttonNeeded: true,
        buttonConfig: [
          {
            text: {
              text: '',
              color: '#000000',
              font: {
                size: '14',
                strength: '400',
                family: 'Arial, sans-serif',
                align: 'left',
              },
            },
            textColor: '#000000',
            image: {
              url: '',
              name: ''
            },
            imageError: '',
            width: 'auto',
            radius: '30px',
            shadow: '0px 0px',
            shadowColor: '#cdcdcd',
            position: 'left',
            bgColor: '#ffffff',
            border: '#000000',
            hoverBgColor: '#cdcdcd',
            buttonTextError: '',
            borderWidth: '1px',
            appLink: {
              text: ''
            },
            webLink: {
              text: ''
            },
            appLinkError: '',
            webLinkError: ''
          }
        ],
        spinButton: {
          type: 'separate_button',
          speed: 2,
          text: {
            text: '',
            color: '#000000',
            font: {
              size: '14',
              strength: '400',
              family: 'Arial, sans-serif',
              align: 'left',
            },
          },
          textColor: '#000000',
          image: '',
          imageName: '',
          width: 'auto',
          radius: '30px',
          shadow: '0px 0px',
          shadowColor: '#cdcdcd',
          position: 'left',
          bgColor: '#ffffff',
          border: '#000000',
          hoverBgColor: '#cdcdcd',
          buttonTextError: '',
          borderWidth: '1px'
        },
        leadGen: false,
        leadGeneration: {
          formFieldList: [
            {
              title: 'E-mail ID',
              value: 'Text'
            },
            {
              title: 'Phone number',
              value: 'Number'
            },
            {
              title: 'Full name',
              value: 'Text'
            },
            {
              title: 'First name',
              value: 'Text'
            },
            {
              title: 'Middle name',
              value: 'Text'
            },
            {
              title: 'Last Name',
              value: 'Text'
            },
            {
              title: 'Country',
              value: 'Geolocation+dropdown'
            },
            {
              title: 'How did you hear about us?',
              value: 'List(dropdown)'
            },
            {
              title: 'Website',
              value: 'URL/Text'
            },
            {
              title: 'Postcode',
              value: 'Number'
            },
            {
              title: 'Company size',
              value: 'Number'
            },
            {
              title: 'Company name',
              value: 'Text'
            },
            {
              title: 'Job title',
              value: 'Text'
            },
            {
              title: 'Date of Birth',
              value: 'Date/Time'
            },
            {
              title: 'Location (city)',
              value: 'Geolocation+dropdown'
            },
            {
              title: 'Address',
              value: 'Text'
            },
            {
              title: 'Industry Vertical',
              value: 'Text'
            },
          ],
          formFieldListSelected: [
            {
              title: 'E-mail ID',
              value: 'Text'
            },
            {
              title: 'Phone number',
              value: 'Number'
            }
          ],
          formFieldListSelectedError: '',
          optionalFields: [],
          descriptionText: '',
          descriptionTextPosition: 'belowFields',
          lableFontStyle: 'Outfit, sans-serif',
          labelFontWt: '400',
          labelFontSize: '12',
          labelFontColor: '#272727',
          fieldFontStyle: 'Outfit, sans-serif',
          fieldFontWt: '400',
          fieldFontSize: '16',
          fieldFontColor: '#9B9A9A',
          defaultCardColor: '#FAF9F9',
          defaultBorderColor: '#FAF9F9',
          defaultThickness: '2px',
          defaultRadius: '10px',
        }
      },
    ],
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_IMAGE_REQUESTED: {
      return {
        ...state,
        imageloading: true,
        imageloaded: false,
        imageWinLoading: true,
        imageWInLoaded: false,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        imageloading: false,
        error: false,
        imageloaded: true,
        imageWinLoading: false,
        imageWInLoaded: true,
      };
    }
    case UPLOAD_IMAGE_FAILURE: {
      return {
        ...state,
        imageloading: false,
        imageloaded: false,
        imageWinLoading: false,
        imageWInLoaded: false,
        error: true,
      };
    }
    case SAVE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DUPLICATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case DUPLICATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DUPLICATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_TITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].title = state.config.localizationconfig[i].title
            ? {
              ...state.config.localizationconfig[i].title,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_SUBTITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].subtitle = state.config.localizationconfig[i].subtitle
            ? {
              ...state.config.localizationconfig[i].subtitle,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_QUADRANTS_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].quadrants = state.config.localizationconfig[i].quadrants
            ? {
              ...state.config.localizationconfig[i].quadrants,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BACKGROUND_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].background = state.config.localizationconfig[i].background
            ? {
              ...state.config.localizationconfig[i].background,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_SPINBUTTON_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].spinButton = state.config.localizationconfig[i].spinButton
            ? {
              ...state.config.localizationconfig[i].spinButton,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_LEADGEN_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].leadGeneration = state.config.localizationconfig[i].leadGeneration
            ? {
              ...state.config.localizationconfig[i].leadGeneration,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_APPCONFIG_FIELD: {
      state.initialValues = state.initialValues
        ? {
          ...state.initialValues,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_LANGUAGE: {
      return { ...state, languages: action.payload };
    }
    case UPDATE_CONFIG_FIELD: {
      const localizationconfig = [...state.config.localizationconfig, ...action.payload.localizationconfig];
      const config = {
        ...state.config,
        localizationconfig
      };
      return { ...state, config };
    }
    case UPDATE_LOCALIZATION_FLAG: {
      return { ...state, localizationFlag: action.payload };
    }
    case UPDATE_ACTIVE_FLAG: {
      return { ...state, activeNavTab: action.payload };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case REMOVE_CONFIG: {
      return { ...state, config: action.payload };
    }
    case UPDATE_BUTTON: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BUTTON_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_QUESTIONS_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    default:
      return state;
  }
};

export const updateTitle = (payload) => {
  return {
    type: UPDATE_TITLE_FIELD,
    payload,
  };
};

export const updateSubTitle = (payload) => {
  return {
    type: UPDATE_SUBTITLE_FIELD,
    payload,
  };
};

export const updateQuadrants = (payload) => {
  return {
    type: UPDATE_QUADRANTS_FIELD,
    payload,
  };
};

export const updateBackground = (payload) => {
  return {
    type: UPDATE_BACKGROUND_FIELD,
    payload,
  };
};

export const updateSpinButton = (payload) => {
  return {
    type: UPDATE_SPINBUTTON_FIELD,
    payload,
  };
};

export const updateLeadGeneration = (payload) => {
  console.log('inside updateLeadGeneration method in reducer', payload);
  return {
    type: UPDATE_LEADGEN_FIELD,
    payload,
  };
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const uploadimage = (file, folderName) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/gamification/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
      .field('folderName', folderName)
  };
};

export const saveSpinWheelConfig = (data) => {
  return {
    types: [SAVE_CONFIG_REQUESTED, SAVE_CONFIG_SUCCESS, SAVE_CONFIG_FAILURE],
    promise: client => client.post('saveSpinWheelConfig', {
      data,
    }),
  };
};

export const getSpinWheel = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getSpinWheel?gamificationId=${data}`),
  };
};

// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const updateButton = (payload) => {
  return {
    type: UPDATE_BUTTON,
    payload
  };
};

export const updateLanguage = (payload) => {
  return {
    type: UPDATE_LANGUAGE,
    payload
  };
};

export const updateConfig = (payload) => {
  return {
    type: UPDATE_CONFIG_FIELD,
    payload
  };
};

export const updateDropdown = (payload) => {
  return {
    type: UPDATE_APPCONFIG_FIELD,
    payload,
  };
};

export const updateLocalizationFlag = (payload) => {
  return {
    type: UPDATE_LOCALIZATION_FLAG,
    payload,
  };
};

export const updateButtonConfig = (payload) => {
  return {
    type: UPDATE_BUTTON_CONFIG,
    payload
  };
};

export const updateActiveFlag = (payload) => {
  return {
    type: UPDATE_ACTIVE_FLAG,
    payload,
  };
};

export const updateQuestions = (payload) => {
  return {
    type: UPDATE_QUESTIONS_FIELD,
    payload,
  };
};

export const removeConfig = (payload) => {
  return {
    type: REMOVE_CONFIG,
    payload
  };
};

export const editSpinWheelConfig = (data, gamificationId) => {
  return {
    types: [UPDATE_CONFIG_REQUESTED, UPDATE_CONFIG_SUCCESS, UPDATE_CONFIG_FAILURE],
    promise: client => client.post(`saveSpinWheelConfig?gamificationId=${gamificationId}`, {
      data,
    }),
  };
};

export const duplicateSpinWheelTitle = (data, type, id) => {
  return {
    types: [DUPLICATE_CONFIG_REQUESTED, DUPLICATE_CONFIG_SUCCESS, DUPLICATE_CONFIG_FAILURE],
    promise: client => client.get(`duplicateSpinWheelTitle?title=${data}&type=${type}&id=${id}`)
  };
};

export const getTemplate = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getTemplate?templateId=${data}`),
  };
};

export const getAppConfig = () => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get('getAppConfig'),
  };
};
