/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Modal, ModalBody } from 'reactstrap';
import Button from '@inputs/Button';
import { updateMenuCollapse } from '@reducers/sidemenu';
import collapse from '@assets/images/collapse.svg';
import close from '@assets/images/close.svg';
import { APP_CONFIG, CONSTANTS } from '@constants';
import logo from '@assets/images/gamelogo.svg';
// import contactButton from '@assets/images/ContactUs.png';
import styles from './Navbar.module.css';


const Navbar = (props) => {
  const [menuSelected, setMenuSelected] = useState(null);
  const [subMenuParentSelected, setSubMenuParentSelected] = useState(null);
  const [modal, setModal] = useState(false);
  const [url, setUrl] = useState(null);
  const { match } = props;
  const SidebarData = JSON.parse(window.localStorage.getItem('sidemenuJson'));
  const trialUser = window.localStorage.getItem('trialUser');
  const hasTrialExpired = window.localStorage.getItem('hasTrialExpired');
  console.log('trialUser = ', trialUser);
  console.log('hasTrialExpired = ', hasTrialExpired);
  useEffect(
    () => {
      // fetch something here
      let path = match.path.split('/');
      path = `/${path[1]}`;
      Object.keys(SidebarData).forEach((key) => {
        const item = SidebarData[key];
        if (item.url === path) {
          setMenuSelected(item.slug);
        }
        const submenu = item.subMenu;
        if (submenu) {
          Object.keys(submenu).forEach((subkey) => {
            const sub = submenu[subkey];
            if (sub.url === path) {
              setMenuSelected(sub.slug);
              setSubMenuParentSelected(item.slug);
            }
          });
        }
      });
      if (path === '/gamemechanics' || path === '/progressBar') {
        setMenuSelected('game_mechanics');
        setSubMenuParentSelected('gamify_engagment');
      } else if (path === '/ruleengine' || path === '/smartCampaignsConfiguration') {
        setMenuSelected('rule_engine');
        setSubMenuParentSelected('rule_engine');
      }
    },
    [match]
  );
  const showSidebar = () => {
    props.updateMenuCollapse(!props.isMenuCollapsed);
  };

  const navClicked = (menu, urlLink, parent = null) => {
    if (hasTrialExpired && hasTrialExpired === 'true') {
      return;
    }
    if (!JSON.parse(window.localStorage.getItem('popupFlag'))) {
      if (urlLink.indexOf('http://') === 0 || urlLink.indexOf('https://') === 0) {
        window.open(urlLink, '_blank');
      } else {
        setMenuSelected(menu);
        setSubMenuParentSelected(parent);
        props.history.push(`${APP_CONFIG.BASE_URL}${urlLink}`);
      }
    } else if ((!urlLink.includes('http://35.227.107.26')) && (urlLink !== 'https://gamize.readme.io/reference/web-sdk-doc')) {
      setModal(true);
      setUrl(urlLink);
    } else {
      window.open(urlLink, '_blank');
    }
  };

  return (
    <Fragment>
      <Modal className={styles.center} isOpen={modal} toggle={() => setModal(false)}>
        <ModalBody className={styles.centerBody}>
          <p className={styles.textStyles}>
            {CONSTANTS.ARE_YOU_SURE_YOU_WANT_TO_DISCARD}
          </p>
          <div className={cn(styles.mb_20, 'all-center')}>
            <Button
              type="button"
              name="cancel"
              label={CONSTANTS.CANCEL}
              look="primary"
              class={styles.saveBtn}
              onClick={() => {
                setModal(false);
              }}
            />
            <Button
              type="button"
              name="ok"
              label={CONSTANTS.OK_1}
              look="primary"
              class={styles.saveBtnYes}
              onClick={() => {
                setModal(false);
                window.localStorage.setItem('popupFlag', false);
                props.history.push(url);
              }}
            />
          </div>
        </ModalBody>
      </Modal>
      {/* trialUser && (
      <div className={styles.trialFont}>
          Your trial period has started and it will stay active for the next 14 days. Contact our sales team to get access to the full version
          &emsp;&emsp;&emsp;&emsp;
        <a className={styles.gotoButton} type="button" href="https://gamize.com/contact-us/">
          <img src={contactButton} width="144px" height="38px" alt="BG" className={cn(styles.imgBg)} />
        </a>
      </div>
      )
      */}
      <div
        className={cn(
          styles.navbarContainer,
          'side-menu-navbar',
          props.isMenuCollapsed && [styles.navbarCollapsed, 'side-navbar-collapsed']
        )}
      >
        <div className={cn(styles.collapse_and_logo)}>
          <button type="button" className={cn(styles.collapse, 'bg-app-primary all-center')} onClick={showSidebar}>
            {!props.isMenuCollapsed && <img src={close} alt="Collapse Menu" width="19.64px" height="19.64px" className={cn(styles.cross_icon)} />}
            {props.isMenuCollapsed && <img src={collapse} alt="Collapse Menu" width="19px" height="18px" className={cn(styles.cross_icon)} />}
          </button>
          {!props.isMenuCollapsed && (
          <div className={cn(styles.brand_logo)}>
            <Link
              to={`${APP_CONFIG.BASE_URL}${'/dashboard'}`}
            >
              <img src={logo} alt="Brand Logo" className={cn(styles.brand_logo_img)} />
            </Link>
          </div>
          )}
        </div>
        <div className={styles.scrollStyles}>
          <div className={cn(styles.menu_links, styles.directionStyles)}>
            <ul className={styles.menu}>
              {SidebarData.map((item) => {
                const submenu = item.subMenu;
                return (
                  <li
                    key={item.id}
                    className={
                  menuSelected === item.slug || subMenuParentSelected === item.slug ? styles.menu_active : undefined
                }
                  >
                    <div
                      className={styles.link}
                      onClick={() => {
                        navClicked(item.slug, item.url);
                        if (props.isMenuCollapsed) {
                          showSidebar();
                        }
                      }}
                    >
                      <i className={cn(styles.nav_logo, styles[`nav_logo_${item.slug}`])} />
                      {!props.isMenuCollapsed && item.title}
                    </div>
                    {submenu
                  && !props.isMenuCollapsed && (
                    <ul className={styles.submenu}>
                      {submenu.map((sub) => {
                        return (
                          <li
                            key={sub.id}
                            className={
                              menuSelected === sub.slug && subMenuParentSelected === item.slug
                                ? styles.submenu_active
                                : undefined
                            }
                          >
                            <div
                              to={`${APP_CONFIG.BASE_URL}${sub.url}`}
                              className={styles.link}
                              onClick={() => {
                                navClicked(sub.slug, sub.url, item.slug);
                                if (props.isMenuCollapsed) {
                                  showSidebar();
                                }
                              }}
                            >
                              <i className={cn(styles.nav_logo, styles[`nav_logo_${item.slug}_${sub.slug}`])} />
                              {!props.isMenuCollapsed && sub.title}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  const { isMenuCollapsed } = state.sidemenu;
  return {
    isMenuCollapsed: isMenuCollapsed || false,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    updateMenuCollapse,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar);
