import { APP_CONFIG } from '@constants';

const upload = require('superagent');

export const UPDATE_TITLE_FIELD = 'quizConfig/UPDATE_TITLE_FIELD';
export const UPDATE_SUBTITLE_FIELD = 'quizConfig/UPDATE_SUBTITLE_FIELD';
export const UPDATE_QUESTIONS_FIELD = 'quizConfig/UPDATE_QUESTIONS_FIELD';
export const UPDATE_BACKGROUND_FIELD = 'quizConfig/UPDATE_BACKGROUND_FIELD';
export const UPDATE_BANNER_IMAGE = 'quizConfig/UPDATE_BANNER_IMAGE';
export const UPDATE_RESULT_FIELD = 'quizConfig/UPDATE_RESULT_FIELD';
export const UPDATE_APPCONFIG_FIELD = 'quizConfig/UPDATE_APPCONFIG_FIELD';
export const UPDATE_TRIVIA_CARD_FIELD = 'quizConfig/UPDATE_TRIVIA_CARD_FIELD';
// export const UPDATE_TEMPLATE_FIELD = 'quizConfig/UPDATE_TEMPLATE_FIELD';

export const UPLOAD_IMAGE_REQUESTED = 'upload/UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_IMAGE_SUCCESS = 'upload/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'upload/UPLOAD_IMAGE_FAILURE';

export const SAVE_CONFIG_REQUESTED = 'save/SAVE_CONFIG_REQUESTED';
export const SAVE_CONFIG_SUCCESS = 'save/SAVE_CONFIG_SUCCESS';
export const SAVE_CONFIG_FAILURE = 'save/SAVE_CONFIG_FAILURE';

export const GET_CONFIG_REQUESTED = 'get/GET_CONFIG_REQUESTED';
export const GET_CONFIG_SUCCESS = 'get/GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'get/GET_CONFIG_FAILURE';

export const UPDATE_CONFIG_REQUESTED = 'update/UPDATE_CONFIG_REQUESTED';
export const UPDATE_CONFIG_SUCCESS = 'update/UPDATE_CONFIG_SUCCESS';
export const UPDATE_CONFIG_FAILURE = 'update/UPDATE_CONFIG_FAILURE';

export const DUPLICATE_CONFIG_REQUESTED = 'get/DUPLICATE_CONFIG_REQUESTED';
export const DUPLICATE_CONFIG_SUCCESS = 'get/DUPLICATE_CONFIG_SUCCESS';
export const DUPLICATE_CONFIG_FAILURE = 'get/DUPLICATE_CONFIG_FAILURE';

export const UPDATE_FIELD = 'quizConfig/UPDATE_FIELD';
export const UPDATE_PAGE = 'quizConfig/UPDATE_PAGE';

export const UPDATE_ACTIVE_FLAG = 'quizConfig/UPDATE_ACTIVE_FLAG';
export const UPDATE_LOCALIZATION_FLAG = 'quizConfig/UPDATE_LOCALIZATION_FLAG';
export const UPDATE_CONFIG_FIELD = 'quizConfig/UPDATE_CONFIG_FIELD';
export const UPDATE_LANGUAGE = 'quizConfig/UPDATE_LANGUAGE';
export const REMOVE_CONFIG = 'quizConfig/REMOVE_CONFIG';
export const CLEAR_STATE = 'quizConfig/CLEAR_STATE';
export const UPDATE_BUTTON = 'quizConfig/UPDATE_BUTTON';
export const UPDATE_BUTTON_CONFIG = 'quizConfig/UPDATE_BUTTON_CONFIG';
export const UPDATE_CARD_FIELD = 'quizConfig/UPDATE_CARD_FIELD';
export const UPDATE_QUESTION_CARD_FIELD = 'quizConfig/UPDATE_QUESTION_CARD_FIELD';
export const UPDATE_OPTION_WRONG_FIELD = 'quizConfig/UPDATE_OPTION_WRONG_FIELD';
export const UPDATE_OPTION_CORRECT_FIELD = 'quizConfig/UPDATE_OPTION_CORRECT_FIELD';
export const UPDATE_OPTION_DEFAULT_FIELD = 'quizConfig/UPDATE_OPTION_DEFAULT_FIELD';
export const UPDATE_PLAY_BUTTON_FIELD = 'quizConfig/UPDATE_PLAY_BUTTON_FIELD';
export const UPDATE_PAUSE_BUTTON_FIELD = 'quizConfig/UPDATE_PAUSE_BUTTON_FIELD';
export const UPDATE_PLAY_SEEK_FIELD = 'quizConfig/UPDATE_PLAY_SEEK_FIELD';
export const UPDATE_SCHEDULE_FIELD = 'quizConfig/UPDATE_SCHEDULE_FIELD';

export const DELETE_CONFIG_REQUESTED = 'get/DELETE_CONFIG_REQUESTED';
export const DELETE_CONFIG_SUCCESS = 'get/DELETE_CONFIG_SUCCESS';
export const DELETE_CONFIG_FAILURE = 'get/DELETE_CONFIG_FAILURE';
export const UPDATE_TABLE_DATA = 'quizConfig/UPDATE_TABLE_DATA';
export const GET_QUESTIONS_REQUESTED = 'get/GET_QUESTIONS_REQUESTED';
export const GET_QUESTIONS_SUCCESS = 'get/GET_QUESTIONS_SUCCESS';
export const GET_QUESTIONS_FAILURE = 'get/GET_QUESTIONS_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  initialValues: {
    fontTitlePositions: [],
    questionType: [],
    quizQuestionImagePosition: [],
    fonts: [],
    fontStrength: [],
    fontSize: [],
    spinButtonPosition: [],
    spinButtonWidth: [],
    spinButtonRadius: [],
    spinButtonShadow: [],
    fontPositions: [],
    outlineStyles: [],
    buttonAction: []
  },
  localizationFlag: '',
  activeNavTab: 'English',
  config: {
    localizationconfig: [
      {
        language: 'English',
        questionsIntial: [],
        questionsOptionSelected: 'Use Trivia Form',
        title: {
          text: 'Answer to Win',
          color: '#FFFFFF',
          font: {
            size: '32',
            strength: '600',
            family: 'Poppins',
            align: 'center',
          },
          position: 'center',
          titleError: '',
          disableSave: false,
          templateName: {
            text: ''
          },
          templateError: '',
          titleImage: '',
          titleImageName: '',
          titleImageError: ''
        },
        subtitle: {
          text: 'Answer & Win Exciting Prize',
          color: '#FFFFFF',
          font: {
            size: '18',
            strength: '400',
            family: 'Poppins',
            align: 'center',
          },
          titleError: '',
        },
        samePointsForAllQuestions: false,
        samePoints: 0,
        samePointsError: '',
        allowEdit: true,
        questions: [
          {
            name: {
              text: '',
              color: '#FFFFFF',
              font: {
                size: '18',
                strength: '600',
                family: 'Poppins',
                align: 'left',
              },
            },
            type: 'none',
            imgPosition: 'bottom',
            imageError: '',
            questionError: '',
            image: {
              fileName: '',
              url: '',
            },
            audio: {
              fileName: '',
              url: '',
            },
            points: 0,
            correctOption: '0',
            optionsList: [
              {
                text: '',
                color: '#000000',
                font: {
                  size: '32',
                  strength: '400',
                  family: 'Poppins',
                  align: 'left',
                },
              },
            ],
          },
        ],
        // questionsOptionSelected: this.questions.length > 5 ? 'Upload Questions' : 'Use Trivia Form',
        questionStyles: {
          fontColor: {
            color: '#000000'
          },
          fontSize: '18',
          fontStrength: '600',
          fontFamily: 'Poppins',
          align: 'left',
        },
        optionStyles: {
          fontColor: {
            color: '#000000'
          },
          fontSize: '18',
          fontStrength: '600',
          fontFamily: 'Poppins',
          align: 'left',
        },
        schedule: {
          noOfDays: 'daily',
          noOfQuestions: 'all',
          customDays: 0,
          customTime: '00:00:00',
          customDaysOption: 'day',
          customQuestions: 0,
          questionSchedule: true,
          scheduleError: ''
        },
        background: {
          color: '#ffffff',
          image: 'https://www.googleapis.com/download/storage/v1/b/ec2-us-east-1-1b-dev-images-gamification/o/quiz%2F76%2F72%2F6464_quiz.png?alt=media',
          imageName: 'backgroundImage.png',
          backgroundError: '',
          showProgressBar: false,
          wheelImage: '',
        },
        questionStyle: {
          cardBox: {
            color: '#F4F4F4',
            outlineColor: '#EEEEEE',
            borderWidth: '1px',
            radius: '30px'
          },
          question: {
            color: '#FFFFFF',
            outlineColor: '#FFFFFF',
            borderWidth: '1px',
            radius: '30px'
          },
          option: {
            defaultStyles: {
              color: '#FFFFFF',
              outlineColor: '#FBCAB2',
              borderWidth: '1px',
              radius: '8px',
              iconColor: '',
            },
            correct: {
              color: '#E8F7F0',
              outlineColor: '#1CAF68',
              borderWidth: '1px',
              radius: '8px',
              iconColor: '',
              icon: 'https://www.googleapis.com/download/storage/v1/b/ec2-us-east-1-1b-dev-images-gamification/o/superQuiz137%2F131%2F2045_correct.png?alt=media',
            },
            wrong: {
              color: '#FFD5D5',
              outlineColor: '#FF5757',
              borderWidth: '1px',
              radius: '8px',
              iconColor: '',
              icon: 'https://www.googleapis.com/download/storage/v1/b/ec2-us-east-1-1b-dev-images-gamification/o/superQuiz137%2F131%2F4783_wrong.png?alt=media',
            }
          },
          playButton: {
            imageColor: '#F67A3E',
          },
          pauseButton: {
            imageColor: '#F67A3E',
          },
          playSeekLine: {
            defaultColor: '#9E9E9E',
            dragColor: '#F67A3E'
          }
        },
        bannerImage: {
          color: '#ffffff',
          bannerImage: '',
          bannerImageName: '',
          bannerImageError: '',
          showProgressBar: false,
          wheelImage: '',
        },
        resultText: {
          winningText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: '14',
              strength: '400',
              family: 'Arial, sans-serif',
              align: 'left',
            },
            error: '',
            errorImg: ''
          },
          losingText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: '14',
              strength: '400',
              family: 'Arial, sans-serif',
              align: 'left',
            },
            error: '',
            errorImg: ''
          }
        },
        buttonNeeded: true,
        buttonConfig: [
          {
            text: {
              text: '',
              color: '#000000',
              font: {
                size: '14',
                strength: '400',
                family: 'Arial, sans-serif',
                align: 'left',
              },
            },
            textColor: '#000000',
            image: {
              url: '',
              name: ''
            },
            imageError: '',
            width: 'auto',
            radius: '30px',
            shadow: '0px 0px',
            shadowColor: '#cdcdcd',
            position: 'left',
            bgColor: '#ffffff',
            border: '#000000',
            hoverBgColor: '#cdcdcd',
            buttonTextError: '',
            borderWidth: '1px',
            appLink: {
              text: ''
            },
            webLink: {
              text: ''
            },
            appLinkError: '',
            webLinkError: ''
          }
        ],
        questionIndex: 0,
        subType: 'Card',
        shuffle: true,
        shuffleQuestions: true,
        resultHandler: 'ParentApp',
        timerFlag: false,
        timeInMinutes: 0,
        timeInSeconds: 0,
        timerError: '',
        timerStyles: {
          text: '00:10',
          color: '#FFFFFF',
          font: {
            size: '18',
            strength: '400',
            family: 'Poppins',
            align: 'center',
          },
          iconColor: '#032AA7',
          image: '',
          imageName: '',
          titleError: '',
        },
      }
    ]
  },
  tableData: {},
  initialData: {
    pageNo: 1,
    title: '',
    sortBy: 'updatedTS',
    orderBy: 'desc',
    activeTab: 'my_tournaments',
    type: 0,
    activePage: 1,
    headers: [],
    selectedType: 1
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPLOAD_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case SAVE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DUPLICATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case DUPLICATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DUPLICATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_TITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].title = state.config.localizationconfig[i].title
            ? {
              ...state.config.localizationconfig[i].title,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_SUBTITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].subtitle = state.config.localizationconfig[i].subtitle
            ? {
              ...state.config.localizationconfig[i].subtitle,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_QUESTIONS_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BACKGROUND_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].background = state.config.localizationconfig[i].background
            ? {
              ...state.config.localizationconfig[i].background,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_SCHEDULE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].schedule = state.config.localizationconfig[i].schedule
            ? {
              ...state.config.localizationconfig[i].schedule,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_TRIVIA_CARD_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BANNER_IMAGE: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].bannerImage = state.config.localizationconfig[i].bannerImage
            ? {
              ...state.config.localizationconfig[i].bannerImage,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_RESULT_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].resultText = state.config.localizationconfig[i].resultText
            ? {
              ...state.config.localizationconfig[i].resultText,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }

      return { ...state };
    }
    case UPDATE_CARD_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].questionStyle.cardBox = state.config.localizationconfig[i].questionStyle.cardBox
            ? {
              ...state.config.localizationconfig[i].questionStyle.cardBox,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_QUESTION_CARD_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].questionStyle.question = state.config.localizationconfig[i].questionStyle.question
            ? {
              ...state.config.localizationconfig[i].questionStyle.question,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_OPTION_DEFAULT_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].questionStyle.option.defaultStyles = state.config.localizationconfig[i].questionStyle.option.defaultStyles
            ? {
              ...state.config.localizationconfig[i].questionStyle.option.defaultStyles,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_OPTION_CORRECT_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].questionStyle.option.correct = state.config.localizationconfig[i].questionStyle.option.correct
            ? {
              ...state.config.localizationconfig[i].questionStyle.option.correct,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_OPTION_WRONG_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].questionStyle.option.wrong = state.config.localizationconfig[i].questionStyle.option.wrong
            ? {
              ...state.config.localizationconfig[i].questionStyle.option.wrong,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_PLAY_BUTTON_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].questionStyle.playButton = state.config.localizationconfig[i].questionStyle.playButton
            ? {
              ...state.config.localizationconfig[i].questionStyle.playButton,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_PAUSE_BUTTON_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].questionStyle.pauseButton = state.config.localizationconfig[i].questionStyle.pauseButton
            ? {
              ...state.config.localizationconfig[i].questionStyle.pauseButton,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_PLAY_SEEK_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].questionStyle.playSeekLine = state.config.localizationconfig[i].questionStyle.playSeekLine
            ? {
              ...state.config.localizationconfig[i].questionStyle.playSeekLine,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_APPCONFIG_FIELD: {
      state.initialValues = state.initialValues
        ? {
          ...state.initialValues,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_LANGUAGE: {
      return { ...state, languages: action.payload };
    }
    case UPDATE_CONFIG_FIELD: {
      const localizationconfig = [...state.config.localizationconfig, ...action.payload.localizationconfig];
      const config = {
        ...state.config,
        localizationconfig
      };
      return { ...state, config };
    }
    case UPDATE_LOCALIZATION_FLAG: {
      return { ...state, localizationFlag: action.payload };
    }
    case UPDATE_ACTIVE_FLAG: {
      return { ...state, activeNavTab: action.payload };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case REMOVE_CONFIG: {
      return { ...state, config: action.payload };
    }
    case UPDATE_BUTTON_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BUTTON: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case DELETE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DELETE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_TABLE_DATA: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    default:
      return state;
  }
};

export const updateTitle = (payload) => {
  return {
    type: UPDATE_TITLE_FIELD,
    payload,
  };
};

// export const updateTemplateName = (payload) => {
//   return {
//     type: UPDATE_TEMPLATE_FIELD,
//     payload,
//   };
// };

export const updateSubTitle = (payload) => {
  return {
    type: UPDATE_SUBTITLE_FIELD,
    payload,
  };
};

export const updateQuestions = (payload) => {
  return {
    type: UPDATE_QUESTIONS_FIELD,
    payload,
  };
};
export const updateActiveFlag = (payload) => {
  return {
    type: UPDATE_ACTIVE_FLAG,
    payload,
  };
};

export const removeConfig = (payload) => {
  return {
    type: REMOVE_CONFIG,
    payload
  };
};

export const updateBackground = (payload) => {
  return {
    type: UPDATE_BACKGROUND_FIELD,
    payload,
  };
};

export const updateTriviaCard = (payload) => {
  return {
    type: UPDATE_TRIVIA_CARD_FIELD,
    payload,
  };
};

export const updateBannerImage = (payload) => {
  return {
    type: UPDATE_BANNER_IMAGE,
    payload,
  };
};

export const updateQuestionCard = (payload) => {
  return {
    type: UPDATE_CARD_FIELD,
    payload,
  };
};

export const uploadimage = (file, folderName) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/gamification/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
      .field('folderName', folderName),
  };
};

export const uploadExcel = (file, quizId, samePointsForAllQuestions, samePoints, quizEntity) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/quiz/upload/questions/file`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('file', file)
      .field('quizId', quizId)
      .field('samePointsForAllQuestions', samePointsForAllQuestions)
      .field('samePoints', samePoints)
      .field('quizEntity', JSON.stringify(quizEntity))
  };
};

export const uploadMediaZip = (file, quizId) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/quiz/upload/media/zip`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('file', file)
      .field('quizId', quizId),
  };
};

export const saveQuizConfig = (data) => {
  return {
    types: [SAVE_CONFIG_REQUESTED, SAVE_CONFIG_SUCCESS, SAVE_CONFIG_FAILURE],
    promise: client => client.post('saveQuizConfig', {
      data,
    }),
  };
};

export const getSpinWheel = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getSpinWheel?gamificationId=${data}`),
  };
};

export const getQuizUploaded = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getQuizUploaded?gamificationId=${data}`),
  };
};

// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const editQuizConfig = (data, gamificationId) => {
  return {
    types: [UPDATE_CONFIG_REQUESTED, UPDATE_CONFIG_SUCCESS, UPDATE_CONFIG_FAILURE],
    promise: client => client.post(`saveQuizConfig?quizId=${gamificationId}`, {
      data,
    }),
  };
};

export const duplicateSpinWheelTitle = (data, type, id) => {
  return {
    types: [DUPLICATE_CONFIG_REQUESTED, DUPLICATE_CONFIG_SUCCESS, DUPLICATE_CONFIG_FAILURE],
    promise: client => client.get(`duplicateSpinWheelTitle?title=${data}&type=${type}&id=${id}`),
  };
};

export const getTemplate = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getTemplate?templateId=${data}`),
  };
};

export const updateResult = (payload) => {
  return {
    type: UPDATE_RESULT_FIELD,
    payload,
  };
};

export const getAppConfig = () => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get('getAppConfig'),
  };
};

export const updateDropdown = (payload) => {
  return {
    type: UPDATE_APPCONFIG_FIELD,
    payload,
  };
};
export const updateLocalizationFlag = (payload) => {
  return {
    type: UPDATE_LOCALIZATION_FLAG,
    payload,
  };
};
export const updateConfig = (payload) => {
  return {
    type: UPDATE_CONFIG_FIELD,
    payload
  };
};
export const updateLanguage = (payload) => {
  return {
    type: UPDATE_LANGUAGE,
    payload
  };
};
export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const updateButton = (payload) => {
  return {
    type: UPDATE_BUTTON,
    payload
  };
};

export const updateButtonConfig = (payload) => {
  return {
    type: UPDATE_BUTTON_CONFIG,
    payload
  };
};

export const updateQuestionCardConfig = (payload) => {
  return {
    type: UPDATE_QUESTION_CARD_FIELD,
    payload
  };
};

export const updateOptionDefaultConfig = (payload) => {
  return {
    type: UPDATE_OPTION_DEFAULT_FIELD,
    payload
  };
};

export const updateOptionCorrectConfig = (payload) => {
  return {
    type: UPDATE_OPTION_CORRECT_FIELD,
    payload
  };
};

export const updateOptionWrongConfig = (payload) => {
  return {
    type: UPDATE_OPTION_WRONG_FIELD,
    payload
  };
};
export const updatePlaySeekLine = (payload) => {
  return {
    type: UPDATE_PLAY_SEEK_FIELD,
    payload
  };
};

export const updatePlayButton = (payload) => {
  return {
    type: UPDATE_PLAY_BUTTON_FIELD,
    payload
  };
};

export const updatePauseButton = (payload) => {
  return {
    type: UPDATE_PAUSE_BUTTON_FIELD,
    payload
  };
};

export const updateSchedule = (payload) => {
  return {
    type: UPDATE_SCHEDULE_FIELD,
    payload
  };
};

export const checkDeleteSpinWheelConfig = (data) => {
  return {
    types: [DELETE_CONFIG_REQUESTED, DELETE_CONFIG_SUCCESS, DELETE_CONFIG_FAILURE],
    promise: client => client.post(`checkDeleteSpinWheelConfig?id=${data}`),
  };
};

export const updateTableData = (payload) => {
  return {
    type: UPDATE_TABLE_DATA,
    payload,
  };
};

export const getQuestions = (data) => {
  return {
    types: [GET_QUESTIONS_REQUESTED, GET_QUESTIONS_SUCCESS, GET_QUESTIONS_FAILURE],
    promise: client => client.get(`getQuestions?quizId=${data.quizId}&language=${data.language}&itemsPerPage=${data.itemsPerPage}&pageNo=${data.pageNo}`),
  };
};

export const deleteQuestionByIndex = (data) => {
  return {
    types: [GET_QUESTIONS_REQUESTED, GET_QUESTIONS_SUCCESS, GET_QUESTIONS_FAILURE],
    promise: client => client.get(`deleteQuestion?quizId=${data.quizId}&questionIndex=${data.questionIndex}`),
  };
};

export const saveQuestion = (data, quizId, language, questionIndex) => {
  return {
    types: [SAVE_CONFIG_REQUESTED, SAVE_CONFIG_SUCCESS, SAVE_CONFIG_FAILURE],
    promise: client => client.post(`saveQuestion?quizId=${quizId}&language=${language}&questionIndex=${questionIndex}`, {
      data,
    }),
  };
};
