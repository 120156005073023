import { DateTime } from 'luxon';
import {
  EditorState, ContentState
} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

export const UPDATE_FIELD = 'offermanagerthirdparty/UPDATE_FIELD';
export const UPDATE_PAGE = 'offermanagerthirdparty/UPDATE_PAGE';
export const CLEAR_STATE = 'offermanagerthirdparty/CLEAR_STATE';

export const CLEAR_OFEER_THIRDPARTY = 'offermanagerthirdparty/CLEAR_OFEER_THIRDPARTY';


export const GET_OFFER_REQUESTED = 'GET_OFFER_REQUESTED';
export const GET_OFFER_SUCCESS = 'GET_OFFER_SUCCESS';
export const GET_OFFER_FAILURE = 'GET_OFFER_FAILURE';
export const UPDATE_TITLE_FIELD = 'offermanagerthirdparty/UPDATE_TITLE_FIELD';
export const UPDATE_TITLE_FIELDS = 'offermanagerthirdparty/UPDATE_TITLE_FIELDS';
export const UPDATE_BUTTON_TEXT = 'offermanagerthirdparty/UPDATE_BUTTON_TEXT';
export const UPDATE_VOUCHER_FIELD = 'offermanagerthirdparty/UPDATE_VOUCHER_FIELD';
export const UPDATE_VOUCHER_COUNT_FIELD = 'offermanagerthirdparty/UPDATE_VOUCHER_COUNT_FIELD';
export const UPDATE_OFFER_VALIDITY_FIELD = 'offermanagerthirdparty/UPDATE_OFFER_VALIDITY_FIELD';


export const DUPLICATE_OFFER_REQUESTED = 'DUPLICATE_OFFER_REQUESTED';
export const DUPLICATE_OFFER_SUCCESS = 'DUPLICATE_OFFER_SUCCESS';
export const DUPLICATE_OFFER_FAILURE = 'DUPLICATE_OFFER_FAILURE';

export const DELETE_OFFER_REQUESTED = 'DELETE_OFFER_REQUESTED';
export const DELETE_OFFER_SUCCESS = 'DELETE_OFFER_SUCCESS';
export const DELETE_OFFER_FAILURE = 'DELETE_OFFER_FAILURE';

export const GET_VOUCHERS_REQUEST = 'GET_VOUCHERS_REQUEST';
export const GET_VOUCHERS_SUCCESS = 'GET_VOUCHERS_SUCCESS';
export const GET_VOUCHERS_FAILURE = 'GET_VOUCHERS_FAILURE';

export const GET_VOUCHER_DETAILS_REQUEST = 'GET_VOUCHER_DETAILS_REQUEST';
export const GET_VOUCHER_DETAILS_SUCCESS = 'GET_VOUCHER_DETAILS_SUCCESS';
export const GET_VOUCHER_DETAILS_FAILURE = 'GET_VOUCHER_DETAILS_FAILURE';

export const SAVE_OFFER_REQUESTED = 'SAVE_OFFER_REQUESTED';
export const SAVE_OFFER_SUCCESS = 'SAVE_OFFER_SUCCESS';
export const SAVE_OFFER_FAILURE = 'SAVE_OFFER_FAILURE';

export const GET_CAMPAIGNS_REQUEST = 'GET_CAMPAIGNS_REQUEST';
export const GET_CAMPAIGNS_SUCCESS = 'GET_CAMPAIGNS_SUCCESS';
export const GET_CAMPAIGNS_FAILURE = 'GET_CAMPAIGNS_FAILURE';
export const CLEAR_FORM = 'offermanagerthirdparty/CLEAR_FORM';

export const OFFERMANAGER_GET_REQUESTED = 'offermanagerthirdparty/OFFERMANAGER_GET_REQUESTED';
export const OFFERMANAGER_GET_SUCCESS = 'offermanagerthirdparty/OFFERMANAGER_GET_SUCCESS';
export const OFFERMANAGER_GET_FAILURE = 'offermanagerthirdparty/OFFERMANAGER_GET_FAILURE';

export const REMOVE_CONFIG = 'offermanagerthirdparty/REMOVE_CONFIG';
export const UPDATE_ACTIVE_FLAG = 'offermanagerthirdparty/UPDATE_ACTIVE_FLAG';
export const UPDATE_CONFIG_FIELD = 'offermanagerthirdparty/UPDATE_CONFIG_FIELD';
export const UPDATE_LANGUAGE = 'offermanagerthirdparty/UPDATE_LANGUAGE';
export const UPDATE_LOCALIZATION_FLAG = 'offermanagerthirdparty/UPDATE_LOCALIZATION_FLAG';

export const CHECK_OFFER_NAME_REQUEST = 'CHECK_OFFER_NAME_REQUEST';
export const CHECK_OFFER_NAME_SUCCESS = 'CHECK_OFFER_NAME_SUCCESS';
export const CHECK_OFFER_NAME_FAILURE = 'CHECK_OFFER_NAME_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorDesc: [],
  code: '',
  msg: '',
  tableData: {},
  initialData: {
    itemsPerPage: 5,
    pageNo: 0,
    searchKey: '',
    sortBy: 'updatedTS',
    orderBy: 'desc',
    deleteRuleList: {
      templateName: '',
      list: []
    },
  },
  voucherList: [],
  denominationList: [],
  voucher: '',
  localizationFlag: '',
  activeNavTab: 'English',
  config: {
    localizationconfig: [
      {
        language: 'English',
        offerName: '',
        offerNameError: '',
        voucher: '',
        denomination: '',
        denominationError: '',
        noOfBrands: '',
        noOfVoucher: '',
        noOfVoucherError: '',
        mode: '',
        offerValidity: '',
        startDateTime: DateTime.now().setZone(window.localStorage.getItem('defaultTimezone')).ts,
        endDateTime: DateTime.now().setZone(window.localStorage.getItem('defaultTimezone')).ts,
        dateTimeError: '',
        selectedCampaignId: '',
        selectedCampaignData: undefined,
        editorState1: EditorState.createEmpty(),
        editorState4: EditorState.createEmpty(),
      },
    ],
  },
  campaignsList: [],
  campaignList: [],
  campaignsError: ''
};


export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_FORM: {
      return {
        ...initialState
      };
    }
    case UPDATE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case UPDATE_TITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].title = state.config.localizationconfig[i].title
            ? {
              ...state.config.localizationconfig[i].title,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_VOUCHER_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].voucher = state.config.localizationconfig[i].voucher
            ? {
              ...state.config.localizationconfig[i].voucher,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_VOUCHER_COUNT_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].noOfVoucher = state.config.localizationconfig[i].noOfVoucher
            ? {
              ...state.config.localizationconfig[i].noOfVoucher,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_OFFER_VALIDITY_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].offerValidity = state.config.localizationconfig[i].offerValidity
            ? {
              ...state.config.localizationconfig[i].offerValidity,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_TITLE_FIELDS: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].subtitle = state.config.localizationconfig[i].subtitle
            ? {
              ...state.config.localizationconfig[i].subtitle,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BUTTON_TEXT: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].spinButton = state.config.localizationconfig[i].spinButton
            ? {
              ...state.config.localizationconfig[i].spinButton,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case CLEAR_OFEER_THIRDPARTY: {
      const config = {
        localizationconfig: [
          {
            language: 'English',
            offerName: '',
            offerNameError: '',
            voucher: '',
            denomination: '',
            denominationError: '',
            noOfBrands: '',
            noOfVoucher: '',
            noOfVoucherError: '',
            mode: '',
            offerValidity: '',
            startDateTime: DateTime.now().setZone(window.localStorage.getItem('defaultTimezone')).ts,
            endDateTime: DateTime.now().setZone(window.localStorage.getItem('defaultTimezone')).ts,
            dateTimeError: '',
            selectedCampaignId: '',
            selectedCampaignData: undefined,
            editorState1: EditorState.createEmpty(),
            editorState4: EditorState.createEmpty(),
          },
        ],
      };
      state.config = config;
      state.loading = true;
      return { ...state, loading: true };
    }
    case GET_VOUCHERS_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_VOUCHERS_SUCCESS: {
      const voucherList = action.result.VoucherList;
      const vouchersList = [];
      for (let i = 0; i < voucherList.length; i += 1) {
        vouchersList.push({ title: voucherList[i].name, value: voucherList[i].productId });
      }
      return {
        ...state,
        voucherList: vouchersList,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_CAMPAIGNS_SUCCESS: {
      const campaignList = action.result.CampaignList;
      const campaignsList = [];
      let campaignsError = '';
      if (action.result.success === 0) {
        campaignsError = action.result.message;
      } else if (action.result.success === 1) {
        campaignsError = '';
      }
      for (let i = 0; i < campaignList.length; i += 1) {
        campaignsList.push({ title: campaignList[i].campaignName, value: campaignList[i].campaignId });
      }
      return {
        ...state,
        campaignsList,
        campaignList,
        campaignsError,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_VOUCHERS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CAMPAIGNS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        campaignsError: 'Technical difficulty in fetching campaigns list from Xoxo API'
      };
    }
    case GET_VOUCHER_DETAILS_REQUEST: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
    case GET_VOUCHER_DETAILS_SUCCESS: {
      const valueDenominations = action.result.VoucherDetails.valueDenominations;
      const denomination = [];
      for (let i = 0; i < valueDenominations.length; i += 1) {
        denomination.push({ title: valueDenominations[i], value: valueDenominations[i] });
      }
      return {
        ...state,
        denominationList: denomination,
        loading: false,
        error: false,
        loaded: true
      };
    } case GET_VOUCHER_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case SAVE_OFFER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_OFFER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_OFFER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case OFFERMANAGER_GET_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case OFFERMANAGER_GET_SUCCESS:
    {
      let config = {
        ...action.result.offer
      };
      const localizationconfig = [];
      const languages = [];
      let offerCoins = [{
        coins: ''
      }];
      const extraParameter = [];
      let offerVoucher = [
        {
          voucher: '',
          amount: '',
          currency: 'inr',
          count: '',
        },
      ];
      if (config.rewardType === 1 || config.rewardType === 3) {
        offerCoins = config.reward.map((obj) => {
          return {
            coins: obj.coins,
            count: obj.count
          };
        });
      }
      if (config.rewardType === 2) {
        offerVoucher = config.reward.map((obj) => {
          return {
            count: obj.count,
            voucher: (config.voucherType === 'static') ? obj.voucher : '',
            amount: obj.amount,
            currency: obj.currency
          };
        });
      }
      if (config.rewardType === 5) {
        offerCoins = config.reward.map((obj) => {
          return {
            points: obj.points,
            count: obj.count
          };
        });
      }
      const { campaignList } = state;
      let campaignData = {};
      for (let i = 0; i< campaignList.length; i += 1) {
        if (campaignList[i].campaignId === config.thirdPartyCampaignId) {
          campaignData = JSON.parse(JSON.stringify(campaignList[i]));
        }
      }
      config = {
        ...state.config,
        ...config,
        offerCoins,
        offerVoucher,
        selectedCampaignId: config.thirdPartyCampaignId,
        noOfVoucher: config.offerCount,
        denomination: config.reward[0].amount,
        noOfBrands: campaignData.product_count,
        selectedCampaignData: campaignData,
        mode: 'edit',
        extraParameter: config.rewardType !== 1 && config.extraParameter && config.extraParameter.length === 0 ? extraParameter : config.extraParameter
      };
      for (let i = 0; i < config.offerLocalization.length; i += 1) {
        let editorState1 = EditorState.createEmpty();
        let editorState4 = EditorState.createEmpty();
        const contentBlock1 = htmlToDraft(config.offerLocalization[i].offerDesc);
        const contentState1 = ContentState.createFromBlockArray(contentBlock1.contentBlocks, contentBlock1.entityMap);
        const contentEditorState1 = EditorState.push(editorState1, contentState1, 'insert-fragment');
        const newEditorState1 = EditorState.moveFocusToEnd(contentEditorState1);
        editorState1 = newEditorState1;
        const contentBlock4 = htmlToDraft(config.offerLocalization[i].howToRedeem);
        const contentState4 = ContentState.createFromBlockArray(contentBlock4.contentBlocks, contentBlock4.entityMap);
        const contentEditorState4 = EditorState.push(editorState4, contentState4, 'insert-fragment');
        const newEditorState4 = EditorState.moveFocusToEnd(contentEditorState4);
        editorState4 = newEditorState4;
        const data = {
          ...config,
          language: config.offerLocalization[i].language,
          offerDesc: config.offerLocalization[i].offerDesc,
          howToRedeem: config.offerLocalization[i].howToRedeem,
          editorState1,
          editorState4
        };
        localizationconfig.push(data);
        languages.push(config.offerLocalization[i].language);
      }
      config = {
        ...state.config,
        localizationconfig
      };
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        config,
        languages
      };
    }
    case OFFERMANAGER_GET_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case REMOVE_CONFIG: {
      return { ...state, configureOffer: action.payload };
    }
    case UPDATE_ACTIVE_FLAG: {
      return { ...state, activeNavTab: action.payload };
    }
    case UPDATE_CONFIG_FIELD: {
      const localizationconfig = [...state.config.localizationconfig, ...action.payload.localizationconfig];
      const config = {
        ...state.config,
        localizationconfig
      };
      return { ...state, config };
    }
    case UPDATE_LANGUAGE: {
      return { ...state, ...action.payload };
    }
    case UPDATE_LOCALIZATION_FLAG: {
      return { ...state, localizationFlag: action.payload };
    }
    default:
      return state;
  }
};

// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const clearOfferThirdpartyForm = () => {
  return {
    type: CLEAR_OFEER_THIRDPARTY,
  };
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const updateTitle = (payload) => {
  return {
    type: UPDATE_TITLE_FIELD,
    payload,
  };
};

export const updateVoucher = (payload) => {
  return {
    type: UPDATE_VOUCHER_FIELD,
    payload,
  };
};

export const updateVoucherCount = (payload) => {
  return {
    type: UPDATE_VOUCHER_COUNT_FIELD,
    payload,
  };
};

export const updateOfferValidity = (payload) => {
  return {
    type: UPDATE_OFFER_VALIDITY_FIELD,
    payload,
  };
};

export const updateTitleBangla = (payload) => {
  return {
    type: UPDATE_TITLE_FIELDS,
    payload,
  };
};

export const updateSpinButton = (payload) => {
  return {
    type: UPDATE_BUTTON_TEXT,
    payload,
  };
};

export const getVouchers = () => {
  return {
    types: [GET_VOUCHERS_REQUEST, GET_VOUCHERS_SUCCESS, GET_VOUCHERS_FAILURE],
    promise: client => client.get('getVouchers')
  };
};

export const getThirdPartyCampaigns = () => {
  return {
    types: [GET_VOUCHERS_REQUEST, GET_CAMPAIGNS_SUCCESS, GET_CAMPAIGNS_FAILURE],
    promise: client => client.get('getCampaigns')
  };
};

export const getVoucherDetails = (productId) => {
  return {
    types: [GET_VOUCHER_DETAILS_REQUEST, GET_VOUCHER_DETAILS_SUCCESS, GET_VOUCHER_DETAILS_FAILURE],
    promise: client => client.get(`getVoucherDetails?productId=${productId}`)
  };
};

export const saveOffer = (payload, productId, denomination) => {
  const data = new FormData();
  data.append('payload', JSON.stringify(payload));
  return {
    types: [SAVE_OFFER_REQUESTED, SAVE_OFFER_SUCCESS, SAVE_OFFER_FAILURE],
    promise: client => client.post(`saveOffer?productId=${productId}&denomination=${denomination}`, {
      data
    })
  };
};

export const saveOfferThirdParty = (payload) => {
  const data = new FormData();
  data.append('payload', JSON.stringify(payload));
  return {
    types: [SAVE_OFFER_REQUESTED, SAVE_OFFER_SUCCESS, SAVE_OFFER_FAILURE],
    promise: client => client.post('saveOfferThirdParty', {
      data
    })
  };
};

export const clearForm = () => {
  return {
    type: CLEAR_FORM,
  };
};

export const getOfferManger = (id) => {
  return {
    types: [OFFERMANAGER_GET_REQUESTED, OFFERMANAGER_GET_SUCCESS, OFFERMANAGER_GET_FAILURE],
    promise: client => client.get(`getOfferManagerThirdParty?id=${id}`)
  };
};

export const removeConfig = (payload) => {
  return {
    type: REMOVE_CONFIG,
    payload
  };
};

export const updateActiveFlag = (payload) => {
  return {
    type: UPDATE_ACTIVE_FLAG,
    payload,
  };
};

export const updateConfig = (payload) => {
  return {
    type: UPDATE_CONFIG_FIELD,
    payload
  };
};

export const updateLanguage = (payload) => {
  return {
    type: UPDATE_LANGUAGE,
    payload
  };
};

export const updateLocalizationFlag = (payload) => {
  return {
    type: UPDATE_LOCALIZATION_FLAG,
    payload,
  };
};
export const checkOfferNameDublicate = (name, id) => {
  return {
    types: [CHECK_OFFER_NAME_REQUEST, CHECK_OFFER_NAME_SUCCESS, CHECK_OFFER_NAME_FAILURE],
    promise: client => client.get(`checkOfferName?offerName=${name}&offerId=${id}`)
  };
};
