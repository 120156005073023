import React, { Fragment } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import styles from './Button.module.css';

const Button = (props) => {
  const onHandleClick = (event) => {
    if (props.onClick) {
      props.onClick(event);
    }
  };
  return (
    <Fragment>
      {props.type === 'button' && (
        <button
          type="button"
          className={cx(
            props.class,
            styles.button,
            props.look === 'primary' ? styles.button_primary : '',
            props.look === 'secondary' ? styles.button_secondary : '',
            props.look === 'danger' ? styles.button_danger : '',
            props.look === 'text_primary' ? styles.text_primary : '',
            props.look === 'text_danger' ? styles.text_danger : '',
            props.look === 'text_secondary' ? styles.text_secondary : '',
            props.disabled && props.disabled === true ? styles.btn_disable : ''
          )}
          name={props.name}
          id={props.id}
          disabled={props.disabled && props.disabled === true ? props.disabled : undefined}
          value={props.value}
          onClick={onHandleClick}
        >
          {props.label}
        </button>
      )}
      {props.type === 'link' && (
        <Link
          to={props.link}
          className={cx(
            props.class,
            styles.button,
            props.look === 'primary' ? styles.button_primary : '',
            props.look === 'secondary' ? styles.button_secondary : '',
            props.look === 'danger' ? styles.button_danger : '',
            props.look === 'text_primary' ? styles.text_primary : '',
            props.look === 'text_danger' ? styles.text_danger : '',
            props.look === 'text_secondary' ? styles.text_secondary : ''
          )}
          target={props.linkTarget}
          disabled={props.disabled}
          onClick={onHandleClick}
        >
          {props.label}
        </Link>
      )}
      {props.type === 'a' && (
        <a
          href={props.link}
          className={cx(
            props.class,
            styles.button,
            props.look === 'primary' ? styles.button_primary : '',
            props.look === 'secondary' ? styles.button_secondary : '',
            props.look === 'danger' ? styles.button_danger : '',
            props.look === 'text_primary' ? styles.text_primary : '',
            props.look === 'text_danger' ? styles.text_danger : '',
            props.look === 'text_secondary' ? styles.text_secondary : ''
          )}
          target={props.linkTarget}
          disabled={props.disabled}
          onClick={onHandleClick}
        >
          {props.label}
        </a>
      )}
    </Fragment>
  );
};
export default Button;
