export const UPDATE_FIELD = 'reportsGraph/UPDATE_FIELD';
export const UPDATE_PAGE = 'reportsGraph/UPDATE_PAGE';
export const CLEAR_STATE = 'reportsGraph/CLEAR_STATE';

export const GET_REPORT_GRAPH_REQUESTED = 'get/GET_REPORT_GRAPH_REQUESTED';
export const GET_REPORT_GRAPH_SUCCESS = 'get/GET_REPORT_GRAPH_SUCCESS';
export const GET_REPORT_GRAPH_FAILURE = 'get/GET_REPORT_GRAPH_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  dropDowns: {
    method: [],
    period: [
      {
        title: 'Daily',
        value: 'daily',
      },
      {
        title: 'Weekly',
        value: 'weekly',
      },
      {
        title: 'Monthly',
        value: 'monthly',
      },
    ],
  },
  selectedMethod: '',
  selectedPeriod: '',
  selectedPeriodFrom: new Date(),
  selectedPeriodEnd: new Date(),
  selectedPeriodYear: null,
  selectedPeriodMonth: null,
  selectedPeriodWeek: null,
  // selectedPeriodFrom: new Date(new Date().getFullYear(), 0, 1),
  // selectedPeriodEnd: new Date(new Date().getFullYear(), 11, 31),
  totalUniqueUsers: 0,
  totalUsersInteracted: 0,
  totalActivityDisplayed: 0,
  totalUniqueUsersInteracted: 0,
  totalUniqueActivityDisplayed: 0,
  totalOffersGiven: 0,
  totalOffersReedemed: 0,
  totalRepeatedUsers: 0,
  engagementPercentage: 0,
  repeatPercentage: 0,
  monthlyActiveUser: 0,
  activityDisplayed: {
    labels: [],
    data: [],
    uniqueData: [],
    options: {
      responsive: true,
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
          labels: {
            color: '#030229',
            backgroundColor: '#030229',
            pointStyle: 'circle',
            usePointStyle: true,
            boxWidth: 11,
          },
        },
        tooltip: {
          backgroundColor: '#303972',
          padding: 12,
          displayColors: false,
          titleFont: {
            size: 16,
            weight: 600,
          },
          titleColor: '#F5F8FF',
          bodyColor: '#F5F8FF',
          bodyFont: {
            size: 14,
          },
          callbacks: {},
        },
      },
      scales: {
        xAxis: {
          grid: {
            display: false,
            borderColor: '#C1BBEB',
          },
        },
        yAxis: {
          ticks: {
            display: true,
            beginAtZero: true,
            stepSize: 20,
          },
          grid: {
            borderColor: '#C1BBEB',
          },
        },
      },
    },
  },
  userInteracted: {
    labels: [],
    data: [],
    uniqueData: [],
    options: {
      responsive: true,
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
          labels: {
            color: '#030229',
            backgroundColor: '#030229',
            pointStyle: 'circle',
            usePointStyle: true,
            boxWidth: 11,
          },
        },
        tooltip: {
          backgroundColor: '#303972',
          padding: 12,
          displayColors: false,
          titleFont: {
            size: 16,
            weight: 600,
          },
          titleColor: '#F5F8FF',
          bodyColor: '#F5F8FF',
          bodyFont: {
            size: 14,
          },
          callbacks: {},
        },
      },
      scales: {
        xAxis: {
          grid: {
            display: false,
            borderColor: '#C1BBEB',
          },
        },
        yAxis: {
          ticks: {
            display: true,
            beginAtZero: true,
            stepSize: 20,
          },
          grid: {
            borderColor: '#C1BBEB',
          },
        },
      },
    },
  },
  offersCompare: {
    labels: [],
    givenData: [],
    redeemedData: [],
    options: {
      responsive: true,
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
          labels: {
            color: '#030229',
            pointStyle: 'circle',
            usePointStyle: true,
            boxWidth: 11,
          },
        },
        tooltip: {
          backgroundColor: '#303972',
          padding: 12,
          displayColors: false,
          titleFont: {
            size: 16,
            weight: 600,
          },
          titleColor: '#F5F8FF',
          bodyColor: '#F5F8FF',
          bodyFont: {
            size: 14,
          },
          callbacks: {},
        },
      },
      scales: {
        xAxis: {
          grid: {
            display: false,
            borderColor: '#C1BBEB',
          },
        },
        yAxis: {
          ticks: {
            display: true,
            beginAtZero: true,
            stepSize: 20,
          },
          grid: {
            borderColor: '#C1BBEB',
          },
        },
      },
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FIELD: {
      state = { ...state, ...action.payload };
      return { ...state };
    }
    case UPDATE_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case GET_REPORT_GRAPH_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_REPORT_GRAPH_SUCCESS: {
      const ruleList = action.result.reportGraphDto.ruleList;
      const methodOptions = [];
      for (let i = 0; i < ruleList.length; i += 1) {
        methodOptions.push({ title: ruleList[i].name, value: ruleList[i].id });
      }
      methodOptions.push({ title: 'All', value: '' });
      const activityDisplayed = action.result.reportGraphDto.activityDisplayed;
      const userInteracted = action.result.reportGraphDto.userInteracted;

      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        totalUniqueUsers: action && action.result && action.result.reportGraphDto.totalUniqueUsers,
        totalUsersInteracted: action && action.result && action.result.reportGraphDto.totalUsersInteracted,
        totalActivityDisplayed: action && action.result && action.result.reportGraphDto.totalActivityDisplayed,
        totalUniqueUsersInteracted: action && action.result && action.result.reportGraphDto.totalUniqueUsersInteracted,
        totalUniqueActivityDisplayed: action && action.result && action.result.reportGraphDto.totalUniqueActivityDisplayed,
        totalOffersGiven: action && action.result && action.result.reportGraphDto.totalOffersGiven,
        totalOffersReedemed: action && action.result && action.result.reportGraphDto.totalOffersReedemed,
        totalRepeatedUsers: action && action.result && action.result.reportGraphDto.totalRepeatedUsers,
        monthlyActiveUser: action && action.result && action.result.reportGraphDto.monthlyActiveUser,
        repeatPercentage: action && action.result && action.result.reportGraphDto.totalUniqueUsersInteracted === 0 ? 0 : (action.result.reportGraphDto.totalRepeatedUsers / action.result.reportGraphDto.totalUniqueUsersInteracted) * 100,
        engagementPercentage: action && action.result && action.result.reportGraphDto.totalUniqueActivityDisplayed === 0 ? 0 : (action.result.reportGraphDto.totalUniqueUsersInteracted / action.result.reportGraphDto.totalUniqueActivityDisplayed) * 100,
        dropDowns: { ...state.dropDowns, ...{ method: methodOptions } },
        activityDisplayed: { ...state.activityDisplayed, ...activityDisplayed },
        userInteracted: { ...state.userInteracted, ...userInteracted },
        generationTime: action && action.result && action.result.reportGraphDto.generationTime,
        orgCreatedTS: action && action.result && action.result.reportGraphDto.orgCreatedTS,
      };
    }
    case GET_REPORT_GRAPH_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const getReportGraphData = (data) => {
  console.log('data2', data);
  return {
    types: [GET_REPORT_GRAPH_REQUESTED, GET_REPORT_GRAPH_SUCCESS, GET_REPORT_GRAPH_FAILURE],
    promise: client => client.get(
      `getReportGraphData?selectedMethod=${data.selectedMethod}&selectedPeriod=${data.selectedPeriod}&selectedYear=${
        data.selectedYear
      }&selectedMonth=${data.selectedMonth}&selectedWeek=${data.selectedWeek}&selectedPeriodFrom=${
        data.selectedPeriodStart
      }&selectedPeriodEnd=${data.selectedPeriodEnd}`
    ),
  };
};
