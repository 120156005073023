import { DateTime } from 'luxon';
import { APP_CONFIG } from '@constants';


const upload = require('superagent');

export const UPDATE_APPCONFIG_FIELD = 'UPDATE_APPCONFIG_FIELD';
export const UPDATE_BADGE_FORMATTING_FIELD = 'UPDATE_BADGE_FORMATTING_FIELD';
export const UPDATE_BADGE_TEXT_FIELD = 'UPDATE_BADGE_TEXT_FIELD';

export const UPLOAD_IMAGE_REQUESTED = 'upload/UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_IMAGE_SUCCESS = 'upload/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'upload/UPLOAD_IMAGE_FAILURE';

export const GET_CONFIG_REQUESTED = 'get/GET_CONFIG_REQUESTED';
export const GET_CONFIG_SUCCESS = 'get/GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'get/GET_CONFIG_FAILURE';

export const GET_BADGE_REQUESTED = 'get/GET_BADGE_REQUESTED';
export const GET_BADGE_SUCCESS = 'get/GET_BADGE_SUCCESS';
export const GET_BADGE_FAILURE = 'get/GET_BADGE_FAILURE';

export const UPDATE_CONFIG_REQUESTED = 'update/UPDATE_CONFIG_REQUESTED';
export const UPDATE_CONFIG_SUCCESS = 'update/UPDATE_CONFIG_SUCCESS';
export const UPDATE_CONFIG_FAILURE = 'update/UPDATE_CONFIG_FAILURE';

export const DUPLICATE_CONFIG_REQUESTED = 'get/DUPLICATE_CONFIG_REQUESTED';
export const DUPLICATE_CONFIG_SUCCESS = 'get/DUPLICATE_CONFIG_SUCCESS';
export const DUPLICATE_CONFIG_FAILURE = 'get/DUPLICATE_CONFIG_FAILURE';

export const UPDATE_FIELD = 'badges/UPDATE_FIELD';
export const UPDATE_PAGE = 'badges/UPDATE_PAGE';
export const CLEAR_STATE = 'badges/CLEAR_STATE';


export const SAVE_BADGES_REQUESTED = 'save/SAVE_BADGES_REQUESTED';
export const SAVE_BADGES_SUCCESS = 'save/SAVE_BADGES_SUCCESS';
export const SAVE_BADGES_FAILURE = 'save/SAVE_BADGES_FAILURE';

export const GET_CATEGORY_REQUESTED = 'get/GET_CATEGORY_REQUESTED';
export const GET_CATEGORY_SUCCESS = 'get/GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAILURE = 'get/GET_CATEGORY_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  badgeFormatting: {
    templateName: '',
    templateNameError: '',
    badgeName: {
      text: '',
      color: '#000000',
      font: {
        size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontSize,
        strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontWt,
        family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFont,
        align: 'left',
      },
    },
    badgeNameError: '',
    badgeDescription: '',
    badgeDescriptionError: '',
    useBadgeForQuest: 'yes',
    badgeCategory: '',
    badgeCategoryValue: '',
    badgeCategoryIsDropdown: false,
    badgeCategoryError: '',
    badgeImage: '',
    badgeImageName: '',
    badgeImageError: '',
    badgeType: '',
    visibilitySequence: 'rank',
    visibilityCategory: 'private',
    visibilityTiming: 'all',
    visibilityStartTime: DateTime.now().setZone(window.localStorage.getItem('defaultTimezone')),
    visibilityEndTime: DateTime.now().setZone(window.localStorage.getItem('defaultTimezone')),
    dateTimeError: ''
  },
  badgeText: {
    textOnBadge: {
      text: '',
      color: '#000000',
      font: {
        size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
        strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
        family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
        align: 'left',
      },
    },
    textOnBadgeError: '',
    receivingMessage: {
      text: '',
      color: '#000000',
      font: {
        size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
        strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
        family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
        align: 'left',
      },
    },
    receivingMessageError: '',
    bgImage: '',
    bgImageName: '',
    bgImageError: '',
    disableSave: false
  },
  initialValues: {
    fonts: [],
    fontStrength: [],
    fontSize: [],
    badgesTab: [],
    badgesList: {},
    categoryList: []
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_BADGES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_BADGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_BADGES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPLOAD_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPLOAD_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DUPLICATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case DUPLICATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DUPLICATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_BADGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BADGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_BADGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case UPDATE_BADGE_FORMATTING_FIELD: {
      state.badgeFormatting = state.badgeFormatting
        ? {
          ...state.badgeFormatting,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_BADGE_TEXT_FIELD: {
      state.badgeText = state.badgeText
        ? {
          ...state.badgeText,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_APPCONFIG_FIELD: {
      state.initialValues = state.initialValues
        ? {
          ...state.initialValues,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case GET_CATEGORY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CATEGORY_SUCCESS: {
      const templates = action.result.categoryList;
      const templateOptions = [];
      for (let i = 0; i < templates.length; i += 1) {
        if (templates[i].label != null) {
          templateOptions.push({ title: templates[i].label, value: templates[i].value });
        }
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        initialValues: { ...state.initialValues, ...{ categoryList: templateOptions } },
      };
    }
    case GET_CATEGORY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const updateBadgeFormating = (payload) => {
  return {
    type: UPDATE_BADGE_FORMATTING_FIELD,
    payload,
  };
};

export const updateBadgeText = (payload) => {
  return {
    type: UPDATE_BADGE_TEXT_FIELD,
    payload,
  };
};

export const uploadimage = (file, folderName) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/gamification/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
      .field('folderName', folderName)
  };
};

export const updateDropdown = (payload) => {
  return {
    type: UPDATE_APPCONFIG_FIELD,
    payload,
  };
};

export const getTemplate = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getTemplate?templateId=${data}`),
  };
};

export const getAppConfig = () => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get('getAppConfig'),
  };
};
export const getBadges = () => {
  return {
    types: [GET_BADGE_REQUESTED, GET_BADGE_SUCCESS, GET_BADGE_FAILURE],
    promise: client => client.get('getBadge'),
  };
};

export const saveBadges = (data) => {
  return {
    types: [SAVE_BADGES_REQUESTED, SAVE_BADGES_SUCCESS, SAVE_BADGES_FAILURE],
    promise: client => client.post('saveGameMechanics', {
      data,
    }),
  };
};

export const uploadBadge = (file) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/badges/upload/badges`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
  };
};

export const editBadges = (data, id) => {
  return {
    types: [SAVE_BADGES_REQUESTED, SAVE_BADGES_SUCCESS, SAVE_BADGES_FAILURE],
    promise: client => client.post(`saveGameMechanics?badgesId=${id}`, {
      data,
    }),
  };
};

export const getBadgesConfig = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getBadgesConfig?badgeId=${data}`),
  };
};

export const duplicateBadgeTemplate = (name, id) => {
  return {
    types: [DUPLICATE_CONFIG_REQUESTED, DUPLICATE_CONFIG_SUCCESS, DUPLICATE_CONFIG_FAILURE],
    promise: client => client.get(`duplicateBadgeTemplate?name=${name}&id=${id}`),
  };
};

export const getCategoryList = () => {
  return {
    types: [GET_CATEGORY_REQUESTED, GET_CATEGORY_SUCCESS, GET_CATEGORY_FAILURE],
    promise: client => client.get('getCategoryList'),
  };
};
