import moment from 'moment-timezone';
import { DateTime } from 'luxon';
import { CONSTANTS } from '@constants';


export const convertMillisecondsToDateAndDate = (Milliseconds) => {
  const temp = new Date(Milliseconds);
  const date = (`0${temp.getDate()}`).slice(-2);
  const year = temp.getFullYear();
  const month = (`0${temp.getMonth() + 1}`).slice(-2);
  const convertedToDate = `${date}-${month}-${year}`;
  return convertedToDate;
};

export const goBackFromCurrectDate = (days) => {
  const ourDate = new Date();
  let retrunDate = {};
  const goBackFromCurrectDateArry = [];
  const monthNameAnddateArry = [];
  // Change it so that it is 7 days in the past.
  for (let i = 1; i < days; i += 1) {
    const date = (`0${ourDate.getDate()}`).slice(-2);
    const month = (`0${ourDate.getMonth() + 1}`).slice(-2);
    const year = ourDate.getFullYear();
    goBackFromCurrectDateArry.push(`${date}-${month}-${year}`);
    const monthArray = [];
    monthArray[0] = 'Jan';
    monthArray[1] = 'Feb';
    monthArray[2] = 'Mar';
    monthArray[3] = 'Apr';
    monthArray[4] = 'May';
    monthArray[5] = 'Jun';
    monthArray[6] = 'Jul';
    monthArray[7] = 'Aug';
    monthArray[8] = 'Sept';
    monthArray[9] = 'Oct';
    monthArray[10] = 'Nov';
    monthArray[11] = 'Dec';
    const month2 = monthArray[ourDate.getMonth()];
    monthNameAnddateArry.push(`${month2} ${date}`);
    const pastDate = ourDate.getDate() - 1;
    ourDate.setDate(pastDate);
  }
  retrunDate = {
    monthNameAnddateArry,
    goBackFromCurrectDateArry
  };
  return retrunDate;
};

export const initDownaload = ({ response, fileName = 'download' }) => {
  const blob = new Blob([response], { type: 'application/octet-stream' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('id', 'downloadRJ');
  document.body.appendChild(a);
  a.style = 'display: none';
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const getUrl = ({ response }) => {
  const blob = new Blob([response], { type: 'application/octet-stream' });
  const url = window.URL.createObjectURL(blob);
  return url;
};

export const convertTimingsToJSON = (data) => {
  const lines = `${data}\n`.split('\n');

  let delay = 0;
  const output = [];
  let buffer = {
    content: ''
  };

  /**
   * `reducer` is executed by `reduce()` and goes line by line in the multi-line string, allow us to
   * parse it and do any necessary modifications.
   *
   * @property {object} [accumulator] The accumulated callback's return values.
   * @property {number} [initialLine] The current value
   * @returns {object}
   */
  const reducer = (accumulator, initialLine) => {
    // The current line, minus any white space at the start or end
    const line = initialLine.trim();

    // Line by line, we go through and find out what type of content we have
    // Check if this `line` is the starting line number
    if (line && !buffer.id) {
      buffer.id = line;

      // Check if this `line` start/end timings
    } else if (!buffer.start) {
      // Split the start/end timings string by the ' --> '
      const range = line.split(' --> ');
      // Split start times (range[0]) into the separate timing values
      let start;
      if (range.length !== 1) {
        start = {
          minutes: range[0].split(':')[1],
          seconds: range[0].split(':')[2].split(',')[0],
          milliseconds: range[0].split(':')[2].split(',')[1]
        };

        // Convert the time to a `new Date`
        // The date here doesn't really matter and won't be used, we only want the time
        const startTotal = new Date(
          `2000-01-01T00:${start.minutes}:${start.seconds}.${start.milliseconds}`
        );

        // Convert the seconds processed above to a number
        const startTotalSeconds = parseInt(
          `${start.seconds}${start.milliseconds}`,
          10
        );

        // Split end times (range[1]) into the separate timing values
        const end = {
          minutes: range[1].split(':')[1],
          seconds: range[1].split(':')[2].split(',')[0],
          milliseconds: range[1].split(':')[2].split(',')[1]
        };

        // Convert the time to a `new Date`
        // The date here doesn't really matter and won't be used, we only want the time
        const endTotal = new Date(
          `2000-01-01T00:${end.minutes}:${end.seconds}.${end.milliseconds}`
        );

        // Convert the seconds processed above to a number
        const endTotalSeconds = parseInt(`${end.seconds}${end.milliseconds}`, 10);

        // The delay amount for how long the animation needs to wait before start
        // Used as between sentences, the narrator generally will leave a natural gap inbetween, but
        // we don't want animation to begin too early
        buffer.delay = startTotalSeconds - delay;

        // Set this loops delay to the `endTotalSeconds`
        delay = endTotalSeconds;

        // Set the start time
        buffer.start = startTotal.getTime() - new Date('2000-01-01T00:00:00.00').getTime();

        // Set the duration, which is the end time minus the start time
        buffer.duration = endTotal - startTotal;

        // Set the end time
        buffer.end = startTotalSeconds + buffer.duration;
      }

      // Check if this `line` is the sentence copy
    } else if (line !== '') {
      // The sentence with a space before if not the first
      const lineToAdd = buffer.content !== '' ? ` ${line}` : line;

      // Add each line as a sentence, with a space before if not the first
      buffer.content += lineToAdd;
    } else {
      // Add all the created content to the output
      output.push(buffer);

      // Reset the buffer for the next line
      buffer = {
        content: ''
      };
    }

    // Return this lines content
    return output;
  };

  // Return all the lines
  return lines.reduce(reducer, 0);
};

export const removePlus = (str) => {
  return str ? str.replace(/\+/gi, ' ') : str;
};

// video time format hh:mm:ss
export const getVideoTime = (videoSeconds) => {
  let hours = Math.floor(videoSeconds / (60 * 60));
  const secondsLeft = videoSeconds - Math.round(hours * (60 * 60));
  let minutes = Math.floor(secondsLeft / 60);
  let seconds = Math.floor(secondsLeft % 60);
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;
  const time = `${hours}:${minutes}:${seconds}`;
  return time;
};

export const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    const context = this;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

export const generateRandomAlphaNumeric = () => {
  return Math.random().toString(36).slice(2);
};

export const getLocalStorageData = () => {
  const userData = JSON.parse(atob(window.localStorage.getItem('userData')));
  const selectedSchoolId = JSON.parse(atob(window.localStorage.getItem('selectedSchool')));
  const studentId = JSON.parse(window.localStorage.getItem('studentId'));
  let localStorageData = {};
  if (userData) {
    localStorageData = {
      ...userData
    };
  }
  if (selectedSchoolId) {
    localStorageData = {
      ...localStorageData,
      ...selectedSchoolId
    };
  }
  if (studentId) {
    localStorageData = {
      ...localStorageData,
      studentId
    };
  }
  return localStorageData;
};

// method to convert camelCase
export const toCamelCase = (sentenceCase) => {
  let out = '';
  sentenceCase.split(' ').forEach((el, idx) => {
    const add = el.toLowerCase();
    out += (idx === 0 ? add : add[0].toUpperCase() + add.slice(1));
  });
  return out;
};

export const processFileData = (dataString) => {
  const dataStringLines = dataString.split(/\r\n|\n/);
  const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

  const list = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < dataStringLines.length; i++) {
    const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
    if (headers && row.length === headers.length) {
      const obj = {};
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < headers.length; j++) {
        let d = row[j];
        if (d.length > 0) {
          if (d[0] === '"') {
            d = d.substring(1, d.length - 1);
          }
          if (d[d.length - 1] === '"') {
            d = d.substring(d.length - 2, 1);
          }
        }
        if (headers[j]) {
          if (headers[j].includes(CONSTANTS.DD_MM_YYYY)) {
            const header = headers[j].replace(CONSTANTS.DD_MM_YYYY, '').trim();
            obj[toCamelCase(header)] = d.trim();
          } else {
            obj[toCamelCase(headers[j])] = d.trim();
          }
        }
      }

      // remove the blank rows
      if (Object.values(obj).filter(x => x).length > 0) {
        list.push(obj);
      }
    }
  }
  // prepare columns list from headers
  const columns = headers.map((c) => {
    if (c.includes(CONSTANTS.DD_MM_YYYY)) {
      const column = c.replace(CONSTANTS.DD_MM_YYYY, '').trim();
      return {
        name: column,
        field: toCamelCase(column),
        selector: column,
      };
    }
    return {
      name: c,
      field: toCamelCase(c),
      selector: c,
    };
  });

  return {
    columns,
    list
  };
};

export const getFileHeaders = (dataString) => {
  const dataStringLines = dataString.split(/\r\n|\n/);
  const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
  return headers;
};

// compare two arrays has equal values
export const checkEquals = (a, b) => {
  return a.length === b.length
  && a.every((v, i) => {
    return v === b[i];
  });
};


export const getFormattedDate = (date) => {
  let format = '';
  if (date.toString().includes('-')) {
    format = CONSTANTS.DD_MM_YYYY_HH_mm_SS;
  } else {
    format = CONSTANTS.DD_MM_YYYY_HH_mm_SS_1;
  }
  if (moment(date, format).isValid()) {
    if (date instanceof Date) {
      date = DateTime.fromJSDate(date, { zone: window.localStorage.getItem('defaultTimezone') });
    } else {
      date = moment(date, format).toDate();
      date = DateTime.fromJSDate(date);
    }
    const year = date.year;
    const time = date.toFormat('hh:mm a');
    const month = date.month;
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthName = months[month - 1];

    const day = date.day;

    return `${day} ${monthName}, ${year} ${time}`;
  }
  return date;
};

export const getFormatedDateWithSlash = (date) => {
  if (date instanceof DateTime) {
    return `${date.day}/${date.month}/${date.year}`;
  }
  return date;
};

export const getFormatedTimeWithSlash = (date) => {
  if (date instanceof DateTime) {
    return date.toLocaleString({
      hour: '2-digit', minute: '2-digit', second: '2-digit', hourCycle: 'h23'
    });
  }
  return date;
};

export const getFormattedFileDate = (date) => {
  const regx = new RegExp('^(((0[1-9]|[12][0-9]|30)[/]?(0[13-9]|1[012])|31[/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[/]?02)[/]?[0-9]{4}|29[-/]?02[/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$');
  const boolean = regx.test(date);
  if (!boolean) {
    return date;
  }
  if (moment(date, CONSTANTS.DD_MM_YYYY_1).isValid()) {
    date = moment(date, CONSTANTS.DD_MM_YYYY_1).toDate();
    date = new Date(date);
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : `0${month}`;
    let day = date.getDate().toString();
    day = day.length > 1 ? day : `0${day}`;
    return `${day}/${month}/${year}`;
  }
  return date;
};

export const getFormattedRuleDate = (date) => {
  date = DateTime.fromMillis(date, { zone: window.localStorage.getItem('defaultTimezone') });
  const time = date.toFormat('hh:mm a');
  const year = date.year;
  const month = date.month;
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const monthName = months[month - 1];

  const day = date.day;

  return `${day} ${monthName}, ${year} ${time}`;
};

export const getFormattedDateForUpload = (date) => {
  const formattedDate = moment(date, CONSTANTS.DD_MM_YYYY_1).format('YYYY-MM-DD');
  date = new Date(formattedDate);
  const offset = date.getTimezoneOffset();
  const offsetDate = new Date();
  offsetDate.setTime(date.getTime() + offset * 60000);
  const year = offsetDate.getFullYear();

  let month = (1 + offsetDate.getMonth()).toString();
  month = month.length > 1 ? month : `0${month}`;

  let day = offsetDate.getDate().toString();
  day = day.length > 1 ? day : `0${day}`;

  return `${year}-${month}-${day}`;
};

export const getFormattedDateStudent = (date) => {
  date = DateTime.fromMillis(date, { zone: window.localStorage.getItem('defaultTimezone') });
  const year = date.year;

  let month = date.month;
  month = month > 9 ? month : `0${month}`;

  let day = date.day;
  day = day > 9 ? day : `0${day}`;

  return `${day}/${month}/${year}`;
};

export const getFormattedDateSchedule = (date) => {
  const formattedDate = moment(date, CONSTANTS.DD_MM_YYYY_1).format('YYYY-MM-DD');
  date = new Date(formattedDate);
  const offset = date.getTimezoneOffset();
  const offsetDate = new Date();
  offsetDate.setTime(date.getTime() + offset * 60000);
  date = offsetDate;
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : `0${month}`;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : `0${day}`;

  return `${day}/${month}/${year}`;
};

export const addLocalStorageForPopUpRequired = (value) => {
  window.localStorage.setItem('popUpRequired', value);
};

export const getLocalStorageForPopUpRequired = () => {
  return JSON.parse(window.localStorage.getItem('popUpRequired'));
};

export const checkUrlIsVimeo = (url) => {
  if (url) {
    const regx = new RegExp(/(vimeo\.com)/);
    if (regx.test(url)) {
      return true;
    }
  }
  return false;
};

export const getPastDate = () => {
  const days = 8;
  const date = new Date();
  let past = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
  let dd = past.getDate();
  let mm = past.getMonth() + 1;
  const yyyy = past.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }
  past = `${yyyy}-${mm}-${dd}`;
  return past;
};

export const getTodayDate = () => {
  let date = new Date();
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }
  date = `${dd}_${mm}_${yyyy}`;
  return date;
};

export const getFutureDate = () => {
  const days = 8;
  const date = new Date();
  let future = new Date(date.getTime() + (days * 24 * 60 * 60 * 1000));
  let dd = future.getDate();
  let mm = future.getMonth() + 1;
  const yyyy = future.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }
  future = `${yyyy}-${mm}-${dd}`;
  return future;
};

export const getCurrentDate = () => {
  let date = new Date();
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }
  date = `${yyyy}-${mm}-${dd}`;
  return date;
};

export const getListFormattedDate = (date) => {
  const format = 'DD-MM-YYYY';
  date = moment(date, format).toDate();
  date = new Date(date);
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : `0${month}`;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : `0${day}`;

  return `${year}-${month}-${day}`;
};

export const getTimeStamp = (date) => {
  const myDate = date.split('-');
  const newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);

  return newDate.getTime();
};

export const getTime = (time) => {
  const d = new Date();
  const [hours, minutes] = time.split(':');
  d.setHours(+hours);
  d.setMinutes(minutes);
  const date = d.toString();
  return date;
};

export const cloneObject = (object) => {
  return JSON.parse(JSON.stringify(object));
};

export const getDatePickerDateFormat = (date) => {
  const format = 'MM/DD/YYYY';
  if (moment(date, format).isValid()) {
    date = moment(date, format).toDate();
    date = new Date(date);
    const year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : `${month}`;
    const months = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    const monthName = months[month - 1];

    let day = date.getDate().toString();
    day = day.length > 1 ? day : `0${day}`;

    return `${monthName} ${day}, ${year}`;
  }
  return date;
};

export const isLettersWithNum = (str) => {
  return /^[a-zA-Z0-9\s]+$/.test(str);
};

export const passwordCharacter = (value) => {
  return value && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&])\S{8,25}$/g.test(value);
};

export const stringCharacter = (value) => {
  return value && !/^[A-Za-z]{1}[A-Za-z ]{0,68}[A-Za-z ]{1}$/i.test(value);
};
export const onlyLettersAndSpaces = (str) => {
  return /^[A-Za-z\s]*$/.test(str);
};

export const sort = (arr) => {
  return arr && arr.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1));
};

export const getFormattedUserDate = (date) => {
  date = new Date(date);
  const year = date.getFullYear();
  const time = date.toLocaleTimeString('en-US');
  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : `${month}`;
  const months = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  const monthName = months[month - 1];

  let day = date.getDate().toString();
  day = day.length > 1 ? day : `0${day}`;

  return `${day} ${monthName}, ${year} ${time}`;
};

export const getTimezoneDate = () => {
  const dateFormatted = DateTime.now().setZone(window.localStorage.getItem('defaultTimezone'));
  return dateFormatted;
};

export const getTimezoneOffset = (timeZone) => {
  const now = moment.utc();
  let minutes = moment.tz.zone(timeZone).utcOffset(now);
  let isAhead = '';
  if (minutes <= 0) {
    isAhead = '+';
  } else if (minutes > 0) {
    isAhead = '-';
  }
  if (isAhead === '+') {
    minutes *= -1;
  }
  const m = minutes % 60;
  const h = (minutes - m) / 60;
  const HHMM = `${isAhead}${(h < 10 ? '0' : '')}${h}:${(m < 10 ? '0' : '')}${m}`;
  return HHMM;
};

export const otherTimeZoneDate = (date, timezone) => {
  const dateWithoutZone = moment.tz(date, timezone).format('YYYY-MM-DDTHH:mm:ss.SSS');
  const localZone = moment(dateWithoutZone).format('Z');
  const dateWithLocalZone = [dateWithoutZone, localZone].join('');
  return new Date(dateWithLocalZone);
};
