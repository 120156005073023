import { APP_CONFIG, CONSTANTS } from '@constants';


const upload = require('superagent');

export const UPDATE_FIELD = 'leaderboard/UPDATE_FIELD';
export const UPDATE_CUSTOMIZE_FIELD = 'leaderboard/UPDATE_CUSTOMIZE_FIELD';
export const UPDATE_PAGE = 'leaderboard/UPDATE_PAGE';
export const UPDATE_LEADERBOARD_ID = 'leaderboard/UPDATE_LEADERBOARD_ID';
export const CLEAR_STATE = 'leaderboard/CLEAR_STATE';
export const UPDATE_LEADERBOARD_FORMATING_FIELD = 'leaderboard/UPDATE_LEADERBOARD_FORMATING_FIELD';
export const UPDATE_OTHER_CONFIGURATION_FIELD = 'leaderboard/UPDATE_OTHER_CONFIGURATION_FIELD';
export const UPDATE_LEADERBOARD_DESIGN_FIELD = 'leaderboard/UPDATE_LEADERBOARD_DESIGN_FIELD';
export const UPDATE_TITLE_FIELD = 'leaderboard/UPDATE_TITLE_FIELD';
export const UPDATE_APPCONFIG_FIELD = 'leaderboard/UPDATE_APPCONFIG_FIELD';
export const UPDATE_TIMEPERIOD_FIELD = 'leaderboard/UPDATE_TIMEPERIOD_FIELD';
export const UPDATE_BACKGROUND_FIELD = 'leaderboard/UPDATE_BACKGROUND_FIELD';
export const UPDATE_LEADERBOARD_LIST_FIELD = 'leaderboard/UPDATE_LEADERBOARD_LIST_FIELD';

export const UPLOAD_IMAGE_REQUESTED = 'upload/UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_IMAGE_SUCCESS = 'upload/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'upload/UPLOAD_IMAGE_FAILURE';


export const GET_LEADERBOARD_REQUESTED = 'GET_LEADERBOARD_REQUESTED';
export const GET_LEADERBOARD_SUCCESS = 'GET_LEADERBOARD_SUCCESS';
export const GET_LEADERBOARD_FAILURE = 'GET_LEADERBOARD_FAILURE';

export const DUPLICATE_LEADERBOARD_REQUESTED = 'DUPLICATE_LEADERBOARD_REQUESTED';
export const DUPLICATE_LEADERBOARD_SUCCESS = 'DUPLICATE_LEADERBOARD_SUCCESS';
export const DUPLICATE_LEADERBOARD_FAILURE = 'DUPLICATE_LEADERBOARD_FAILURE';

export const DELETE_LEADERBOARD_REQUESTED = 'DELETE_LEADERBOARD_REQUESTED';
export const DELETE_LEADERBOARD_SUCCESS = 'DELETE_LEADERBOARD_SUCCESS';
export const DELETE_LEADERBOARD_FAILURE = 'DELETE_LEADERBOARD_FAILURE';

export const GET_CONFIG_REQUESTED = 'get/GET_CONFIG_REQUESTED';
export const GET_CONFIG_SUCCESS = 'get/GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'get/GET_CONFIG_FAILURE';

export const SAVE_LEADERBOARD_CONFIG_REQUESTED = 'save/SAVE_LEADERBOARD_CONFIG_REQUESTED';
export const SAVE_LEADERBOARD_CONFIG_SUCCESS = 'save/SAVE_LEADERBOARD_CONFIG_SUCCESS';
export const SAVE_LEADERBOARD_CONFIG_FAILURE = 'save/SAVE_LEADERBOARD_CONFIG_FAILURE';

export const GET_LEADERBOARD_BY_ID_REQUESTED = 'GET_LEADERBOARD_BY_ID_REQUESTED';
export const GET_LEADERBOARD_BY_ID_SUCCESS = 'GET_LEADERBOARD_BY_ID_SUCCESS';
export const GET_LEADERBOARD_BY_ID_FAILURE = 'GET_LEADERBOARD_BY_ID_FAILURE';

export const CHECK_LEADERBOARD_NAME_REQUEST = 'CHECK_LEADERBOARD_NAME_REQUEST';
export const CHECK_LEADERBOARD_NAME_SUCCESS = 'CHECK_LEADERBOARD_NAME_SUCCESS';
export const CHECK_LEADERBOARD_NAME_FAILURE = 'CHECK_LEADERBOARD_NAME_FAILURE';

export const UPDATE_LOCALIZATION_FLAG = 'leaderboard/UPDATE_LOCALIZATION_FLAG';
export const UPDATE_ACTIVE_FLAG = 'leaderboard/UPDATE_ACTIVE_FLAG';
export const UPDATE_CONFIG_FIELD = 'leaderboard/UPDATE_CONFIG_FIELD';
export const REMOVE_CONFIG = 'leaderboard/REMOVE_CONFIG';

const initialState = {
  customize: {
    actionColumn: '',
    type: 0,
    templateId: '',
  },
  loading: false,
  loaded: false,
  error: false,
  errorDesc: [],
  code: '',
  msg: '',
  tableData: {},
  localizationFlag: '',
  activeNavTab: 'English',
  config: {
    localizationconfig: [
      {
        language: 'English',
        leaderboardFormatting: {
          leaderboardId: '',
          type: '',
          templateName: '',
          templateNameError: '',
          leaderboardNameError: '',
          nameExistsError: '',
          leaderBoardTitle: {
            text: '',
            color: '#FFFFFF',
            font: {
              size: '18',
              strength: '600',
              family: 'Arial, sans-serif',
              align: 'left',
            },
          },
          position: 'center',
          leaderBoardTitleError: '',
          timeperiod: '',
          selectedTimePeriod: 'Daily',
          timePeriodDesc: '',
          profilepic: 'no',
          profilepicUrl: '',
          profilepicImageName: '',
          profilepicError: '',
          rankTextBox: '',
          nameTextBox: '',
          scoreTextBox: '',
          disableSave: false,
        },
        background: {
          color: '#100022',
          image: '',
          imageName: '',
          backgroundError: '',
          showProgressBar: false,
          wheelImage: '',
          winningText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: '14',
              strength: '400',
              family: 'Arial, sans-serif',
              align: 'left',
            },
            error: '',
            errorImg: ''
          },
          losingText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: '14',
              strength: '400',
              family: 'Arial, sans-serif',
              align: 'left',
            },
            error: '',
            errorImg: ''
          }
        },
        leaderboardDesign: {
          tableBgColorFlag: true,
          tableBgColor: '#032AA7',
          tableBgImage: '',
          tableBgImageName: '',
          tableBgImageError: '',
          timerText: 'Time',
          timerFlag: true,
          podium1: '',
          podium2: '',
          podium3: '',
          rank1PrimaryColor: '#032AA7',
          rank1SecondaryColor: '#032AA7',
          rank2PrimaryColor: '#032AA7',
          rank2SecondaryColor: '#032AA7',
          rank3PrimaryColor: '#032AA7',
          rank3SecondaryColor: '#032AA7',
          rowBackground: '#FFFFFF',
          userScoreHighlight: true,
          scoreHighlightColor: '#032AA7',
          scoreHighlightFillColor: '#032AA7',
          headingRowColor: '#032AA7',
          bodyRowColor: '#032AA7',
          rankTextColor: {
            color: '#000000',
          },
          headingRankTextColor: {
            color: '#000000',
          },
          bodyRankTextColor: {
            color: '#000000',
          },
          userScoreRankTextColor: {
            color: '#000000',
          },
          colorText: {
            text: '',
            color: '#000000',
            font: {
              size: '14',
              strength: '600',
              family: 'Arial, sans-serif',
              align: 'left',
            },
          },
          topThreePlayers: {
            text: '',
            color: '#ffffff',
            font: {
              size: '18',
              strength: '600',
              family: 'Arial, sans-serif',
              align: 'left',
            },
          },
          rankText: {
            size: '14',
            strength: '400',
            family: 'Arial, sans-serif',
            align: 'left',
            color: '#1E1A33'
          },
          headingRankText: {
            size: '14',
            strength: '400',
            family: 'Arial, sans-serif',
            align: 'left',
            color: '#1E1A33'
          },
          bodyRankText: {
            size: '14',
            strength: '400',
            family: 'Arial, sans-serif',
            align: 'left',
            color: '#1E1A33'
          },
          userScoreRankText: {
            size: '14',
            strength: '400',
            family: 'Arial, sans-serif',
            align: 'left',
          },
        },
        otherconfiguration: {
          scoringFormat: 'score',
          scoringOrder: 'highest',
          noOfPlayer: '5',
          playMore: '',
          maxPlayer: 'maxplays',
          maxPlaysOption: 'maxplays',
          maxPlays: 3,
          maxPlaysError: '',
        },
      }
    ]
  },
  initialData: {
    itemsPerPage: 6,
    pageNo: 0,
    searchKey: '',
    sortBy: 'updatedTS',
    orderBy: 'desc',
    deleteRuleList: {
      templateName: '',
      list: []
    },
    headers: [
      {
        title: 'Name',
        slug: 'leaderboard_name',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: false,
        sortByOrder: '',
        width: 180,
      },
      {
        title: 'Title',
        slug: 'title',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: false,
        sortByOrder: '',
        width: 180,
      },
      {
        title: 'Type',
        slug: 'gameMechanicsType',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: false,
        sortByOrder: '',
        width: 180,
      },
      {
        title: CONSTANTS.CREATEDBY,
        slug: 'createdby',
        isSortable: true,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: '',
        width: 90,
      },
      // {
      //   title: 'Leaderboard Time Period',
      //   slug: 'time_period',
      //   isSortable: false,
      //   isVisible: true,
      //   tooltip: false,
      //   center: false,
      //   sortByOrder: '',
      //   width: 200,
      // },
      {
        title: 'Date & Time',
        slug: 'date',
        isSortable: true,
        isVisible: true,
        tooltip: false,
        center: false,
        sortByOrder: 'desc',
        width: 200,
      },
      {
        title: 'Actions',
        slug: 'actions',
        isSortable: false,
        isVisible: false,
        tooltip: true,
        sortByOrder: '',
        width: 180,
      },
    ],
    fontTitlePositions: [],
    fontPositions: [],
    outlineStyles: [],
    fonts: [],
    fontStrength: [],
    fontSize: []
  },
  leaderboardlist: []
};


export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LEADERBOARD_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_LEADERBOARD_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_LEADERBOARD_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DUPLICATE_LEADERBOARD_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DUPLICATE_LEADERBOARD_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case DUPLICATE_LEADERBOARD_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DELETE_LEADERBOARD_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DELETE_LEADERBOARD_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case DELETE_LEADERBOARD_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_CUSTOMIZE_FIELD: {
      console.log(state.customize, 'payload js');
      state.customize = state.customize
        ? {
          ...state.customize,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    // case UPDATE_LEADERBOARD_FORMATING_FIELD: {
    //   state.leaderboardFormatting = state.leaderboardFormatting
    //     ? {
    //       ...state.leaderboardFormatting,
    //       ...action.payload
    //     }
    //     : { ...action.payload };
    //   return { ...state };
    // }
    case UPDATE_LEADERBOARD_FORMATING_FIELD: {
      console.log('eeeeeeeeeeeeeeeeeeeee', action.payload);
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].leaderboardFormatting = state.config.localizationconfig[i].leaderboardFormatting
            ? {
              ...state.config.localizationconfig[i].leaderboardFormatting,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_OTHER_CONFIGURATION_FIELD: {
      console.log('eeeeeeeeeeeeeeeeeeeee', action.payload);
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].otherconfiguration = state.config.localizationconfig[i].otherconfiguration
            ? {
              ...state.config.localizationconfig[i].otherconfiguration,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_LEADERBOARD_LIST_FIELD: {
      state = state
        ? {
          ...state,
          ...action.payload
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case UPDATE_LEADERBOARD_ID: {
      state.leaderboardFormatting.leaderboardId = state.leaderboardFormatting.leaderboardId
        ? {
          ...state.leaderboardFormatting.leaderboardId,
          ...action.payload
        }
        : { ...action.payload };
      return { ...state };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case UPDATE_TITLE_FIELD: {
      state.config.title = state.config.title
        ? {
          ...state.config.title,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_APPCONFIG_FIELD: {
      state.initialData = state.initialData
        ? {
          ...state.initialData,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_TIMEPERIOD_FIELD: {
      state.config.timeperiod = state.config.timeperiod
        ? {
          ...state.config.timeperiod,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_BACKGROUND_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].background = state.config.localizationconfig[i].background
            ? {
              ...state.config.localizationconfig[i].background,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPLOAD_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPLOAD_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_LEADERBOARD_DESIGN_FIELD: {
      console.log('eeeeeeeeeeeeeeeeeeeee', action.payload);
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].leaderboardDesign = state.config.localizationconfig[i].leaderboardDesign
            ? {
              ...state.config.localizationconfig[i].leaderboardDesign,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case SAVE_LEADERBOARD_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_LEADERBOARD_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_LEADERBOARD_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_LEADERBOARD_BY_ID_REQUESTED: {
      return {
        ...state,
        saveRuleLoading: true,
        loading: true,
        loaded: false,
      };
    }
    case GET_LEADERBOARD_BY_ID_SUCCESS: {
      // const leaderboardData = action.result.Campaign;
      // const localizationconfig = [];
      // const languages = [];
      // console.log('wwwwwwwwwwwwwwwwwwwww', leaderboardData);
      // const config = {
      //   ...action.result.Campaign,
      // };
      // console.log('wwwwwwwwwwwwwwwwwwwww1', config);
      // const leaderboardFormatting = {
      //   leaderboardId: config.id,
      //   templateName: config.leaderBoardName,
      //   leaderBoardTitle: {
      //     text: config.leaderBoardLocalizationDTO[0].title,
      //     color: config.leaderBoardLocalizationDTO[0].titleFontColor,
      //     font: {
      //       size: config.leaderBoardLocalizationDTO[0].titleFontSize,
      //       strength: config.leaderBoardLocalizationDTO[0].titleFontWeight,
      //       family: config.leaderBoardLocalizationDTO[0].titleFont,
      //       align: config.leaderBoardLocalizationDTO[0].titlePosition,
      //     },
      //   },
      //   timePeriodDesc: leaderboardData.timePeriod,
      //   selectedTimePeriod: leaderboardData.timePeriodSelected,
      //   profilepic: config.leaderBoardLocalizationDTO[0].includeProfilePicture,
      //   profilepicUrl: leaderboardData.profilePicture,
      //   profilepicImageName: leaderboardData.profilePictureName,
      //   position: config.leaderBoardLocalizationDTO[0].position
      // };
      // const leaderboardDesign = {
      //   rank1PrimaryColor: config.leaderBoardLocalizationDTO[0].rank1PrimaryColor,
      //   rank1SecondaryColor: config.leaderBoardLocalizationDTO[0].rank1SecondaryColor,
      //   rank2PrimaryColor: config.leaderBoardLocalizationDTO[0].rank2PrimaryColor,
      //   rank2SecondaryColor: config.leaderBoardLocalizationDTO[0].rank2SecondaryColor,
      //   rank3PrimaryColor: config.leaderBoardLocalizationDTO[0].rank3PrimaryColor,
      //   rank3SecondaryColor: config.leaderBoardLocalizationDTO[0].rank3SecondaryColor,
      //   rowBackground: '#FFFFFF',
      //   userScoreHighlight: config.leaderBoardLocalizationDTO[0].userScoreHighlight,
      //   scoreHighlightColor: config.leaderBoardLocalizationDTO[0].userScoreHighlightColor,
      //   headingRowColor: config.leaderBoardLocalizationDTO[0].headingRowColor,
      //   bodyRowColor: config.leaderBoardLocalizationDTO[0].bodyRowColor,
      //   rankTextColor: {
      //     color: '#000000',
      //   },
      //   headingRankTextColor: {
      //     color: '#000000',
      //   },
      //   bodyRankTextColor: {
      //     color: '#000000',
      //   },
      //   userScoreRankTextColor: {
      //     color: '#000000',
      //   },
      //   colorText: {
      //     text: '',
      //     color: '#000000',
      //     font: {
      //       size: '14',
      //       strength: '600',
      //       family: 'Arial, sans-serif',
      //       align: 'left',
      //     },
      //   },
      //   topThreePlayers: {
      //     text: '',
      //     color: '#ffffff',
      //     font: {
      //       size: '18',
      //       strength: '600',
      //       family: 'Arial, sans-serif',
      //       align: 'left',
      //     },
      //   },
      //   rankText: {
      //     size: config.leaderBoardLocalizationDTO[0].rankTextFontSize,
      //     strength: config.leaderBoardLocalizationDTO[0].rankTextFontWeight,
      //     family: config.leaderBoardLocalizationDTO[0].rankTextFont,
      //     align: 'left',
      //     color: config.leaderBoardLocalizationDTO[0].rankTextFontColor
      //   },
      //   headingRankText: {
      //     size: config.leaderBoardLocalizationDTO[0].headingRankTextSize,
      //     strength: config.leaderBoardLocalizationDTO[0].headingRankTextWeight,
      //     family: config.leaderBoardLocalizationDTO[0].headingRankTextFont,
      //     align: 'left',
      //     color: config.leaderBoardLocalizationDTO[0].headingRankTextColor
      //   },
      //   bodyRankText: {
      //     size: config.leaderBoardLocalizationDTO[0].bodyRankTextSize,
      //     strength: config.leaderBoardLocalizationDTO[0].bodyRankTextWeight,
      //     family: config.leaderBoardLocalizationDTO[0].bodyRankTextFont,
      //     align: 'left',
      //     color: config.leaderBoardLocalizationDTO[0].bodyRankTextColor
      //   },
      //   userScoreRankText: {
      //     size: config.leaderBoardLocalizationDTO[0].userScoreRankTextSize,
      //     strength: config.leaderBoardLocalizationDTO[0].userScoreRankTextWeight,
      //     family: config.leaderBoardLocalizationDTO[0].userScoreRankTextFont,
      //     align: 'left',
      //   },
      // };
      // const otherconfiguration = {
      //   scoringFormat: config.leaderBoardLocalizationDTO[0].scoringFormat,
      //   scoringOrder: config.leaderBoardLocalizationDTO[0].scoringOrder,
      //   noOfPlayer: config.leaderBoardLocalizationDTO[0].noOfPlayersInOnePage,
      //   playMore: config.leaderBoardLocalizationDTO[0].allowPlayersToPlayMoreThanOnce,
      //   maxPlayer: config.leaderBoardLocalizationDTO[0].maxPlayer,
      // };

      // const background = {
      //   color: config.leaderBoardLocalizationDTO[0].bgColor,
      //   image: config.leaderBoardLocalizationDTO[0].bgImage,
      //   imageName: config.leaderBoardLocalizationDTO[0].bgImageName,
      //   backgroundError: '',
      //   showProgressBar: false,
      //   wheelImage: '',
      //   winningText: {
      //     img: '',
      //     imageName: '',
      //     text: '',
      //     color: '#000000',
      //     font: {
      //       size: '14',
      //       strength: '400',
      //       family: 'Arial, sans-serif',
      //       align: 'left',
      //     },
      //     error: '',
      //     errorImg: ''
      //   },
      //   losingText: {
      //     img: '',
      //     imageName: '',
      //     text: '',
      //     color: '#000000',
      //     font: {
      //       size: '14',
      //       strength: '400',
      //       family: 'Arial, sans-serif',
      //       align: 'left',
      //     },
      //     error: '',
      //     errorImg: ''
      //   }
      // };
      // for (let i = 0; i < config.leaderBoardLocalizationDTO.length; i += 1) {
      //   // const data = {
      //   //   ...config,
      //   //   language: config.leaderBoardLocalizationDTO[i].language,
      //   // };
      //   // localizationconfig.push(data);
      //   languages.push(config.leaderBoardLocalizationDTO[i].language);
      // }
      // const obj = {
      //   leaderboardFormatting,
      //   leaderboardDesign,
      //   otherconfiguration,
      //   background
      // };
      // localizationconfig.push(obj);
      // const configs = {
      //   ...state.config,
      //   localizationconfig
      // };
      // console.log('wwwwwwwwwwwwwwwwwwwwwwwwwwwwww333', configs);
      // return {
      //   ...state,
      //   leaderboardFormatting,
      //   leaderboardDesign,
      //   otherconfiguration,
      //   loading: false,
      //   error: false,
      //   loaded: true,
      //   config: configs,
      //   options: { ...state.options },
      // };
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }

    case GET_LEADERBOARD_BY_ID_FAILURE: {
      return {
        ...state,
        saveRuleLoading: false,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_LOCALIZATION_FLAG: {
      return { ...state, localizationFlag: action.payload };
    }
    case UPDATE_ACTIVE_FLAG: {
      return { ...state, activeNavTab: action.payload };
    }
    case UPDATE_CONFIG_FIELD: {
      const localizationconfig = [...state.config.localizationconfig, ...action.payload.localizationconfig];
      const config = {
        ...state.config,
        localizationconfig
      };
      return { ...state, config };
    }
    case REMOVE_CONFIG: {
      return { ...state, config: action.payload };
    }
    default:
      return state;
  }
};

// action
export const updateLeaderboardFormating = (payload) => {
  return {
    type: UPDATE_LEADERBOARD_FORMATING_FIELD,
    payload,
  };
};

export const updateLeaderboardList = (payload) => {
  return {
    type: UPDATE_LEADERBOARD_LIST_FIELD,
    payload,
  };
};

export const updateOtherConfiguration = (payload) => {
  return {
    type: UPDATE_OTHER_CONFIGURATION_FIELD,
    payload,
  };
};

export const updateLeaderboardDesign = (payload) => {
  return {
    type: UPDATE_LEADERBOARD_DESIGN_FIELD,
    payload,
  };
};

export const updateTitle = (payload) => {
  return {
    type: UPDATE_TITLE_FIELD,
    payload,
  };
};

export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const updateLeaderboardId = (payload) => {
  return {
    type: UPDATE_LEADERBOARD_ID,
    payload,
  };
};

export const updateDropdown = (payload) => {
  return {
    type: UPDATE_APPCONFIG_FIELD,
    payload,
  };
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const updateCustomize = (payload) => {
  return {
    type: UPDATE_CUSTOMIZE_FIELD,
    payload,
  };
};

export const getMyLeaderboards = (data) => {
  let reqParams = '';
  if (data.searchKey !== '') {
    reqParams = `${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&sortType=${data.orderBy}`;
  } else {
    reqParams = `${data.itemsPerPage}/${data.pageNo}?searchKey=&sortBy=${data.sortBy}&sortType=${data.orderBy}`;
  }
  return {
    types: [GET_LEADERBOARD_REQUESTED, GET_LEADERBOARD_SUCCESS, GET_LEADERBOARD_FAILURE],
    promise: client => client.get(`getMyLeaderBoardList/${reqParams}`)
  };
};

export const getMyGameMechanics = (data) => {
  let reqParams = '';
  if (data.searchKey !== '') {
    reqParams = `${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&sortType=${data.orderBy}`;
  } else {
    reqParams = `${data.itemsPerPage}/${data.pageNo}?searchKey=&sortBy=${data.sortBy}&sortType=${data.orderBy}`;
  }
  return {
    types: [GET_LEADERBOARD_REQUESTED, GET_LEADERBOARD_SUCCESS, GET_LEADERBOARD_FAILURE],
    promise: client => client.get(`getGameMechanicsTemplates/${reqParams}`)
  };
};

export const duplicateMyLeaderboard = (id) => {
  return {
    types: [DUPLICATE_LEADERBOARD_REQUESTED, DUPLICATE_LEADERBOARD_SUCCESS, DUPLICATE_LEADERBOARD_FAILURE],
    promise: client => client.get(`duplicateMyLeaderboard?id=${id}`)
  };
};

export const deleteMyLeaderboard = (id) => {
  return {
    types: [DUPLICATE_LEADERBOARD_REQUESTED, DUPLICATE_LEADERBOARD_SUCCESS, DUPLICATE_LEADERBOARD_FAILURE],
    promise: client => client.post(`deleteMyLeaderboard?id=${id}`)
  };
};

export const checkDeleteMyLeaderboard = (id) => {
  return {
    types: [DUPLICATE_LEADERBOARD_REQUESTED, DUPLICATE_LEADERBOARD_SUCCESS, DUPLICATE_LEADERBOARD_FAILURE],
    promise: client => client.get(`checkDeleteMyLeaderboard?id=${id}`)
  };
};

export const getAppConfig = () => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get('getAppConfig'),
  };
};

export const updateTimePeriod = (payload) => {
  return {
    type: UPDATE_TIMEPERIOD_FIELD,
    payload,
  };
};
export const updateBackground = (payload) => {
  return {
    type: UPDATE_BACKGROUND_FIELD,
    payload,
  };
};
export const uploadimage = (file, folderName) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/gamification/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
      .field('folderName', folderName)
  };
};

export const saveLeaderboardConfig = (data, leaderboardId) => {
  return {
    types: [SAVE_LEADERBOARD_CONFIG_REQUESTED, SAVE_LEADERBOARD_CONFIG_SUCCESS, SAVE_LEADERBOARD_CONFIG_FAILURE],
    promise: client => client.post(`saveLeaderboardConfig?leaderboardId=${leaderboardId}`, {
      data,
    }),
  };
};

export const getLeaderboardById = (leaderboardId) => {
  return {
    types: [GET_LEADERBOARD_BY_ID_REQUESTED, GET_LEADERBOARD_BY_ID_SUCCESS, GET_LEADERBOARD_BY_ID_FAILURE],
    promise: client => client.get(`getLeaderboardById/${leaderboardId}`),
  };
};

export const checkLeaderBoardNameDublicate = (name, id) => {
  return {
    types: [CHECK_LEADERBOARD_NAME_REQUEST, CHECK_LEADERBOARD_NAME_SUCCESS, CHECK_LEADERBOARD_NAME_FAILURE],
    promise: client => client.get(`checkLeaderBoardNameDublicate?name=${name}&id=${id}`),
  };
};

export const updateLocalizationFlag = (payload) => {
  return {
    type: UPDATE_LOCALIZATION_FLAG,
    payload,
  };
};


export const updateActiveFlag = (payload) => {
  return {
    type: UPDATE_ACTIVE_FLAG,
    payload,
  };
};

export const updateConfig = (payload) => {
  return {
    type: UPDATE_CONFIG_FIELD,
    payload
  };
};

export const removeConfig = (payload) => {
  return {
    type: REMOVE_CONFIG,
    payload
  };
};

export const getDefaultLeaderboardById = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getDefaultLeaderboardById?templateId=${data}`),
  };
};
