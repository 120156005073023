export default {
  endpoints: {
    loginapi: '/accounts/login',
    logoutapi: '/userservice/account/logout',
    register: '/accounts/register',
    verifyEmail: '/accounts/verify/email',
    forgotPassword: '/account/resetpwd',
    getIndustryList: '/industry/get/list',
    uploadimage: '/gameservice/gamification/upload',
    saveSpinWheelConfig: '/gameservice/gamification/save/spin/wheel',
    getSideMenu: '/userservice/module/menulist',
    saveOfferManager: '/gameservice/gamification/save/offer',
    getOfferManager: '/gameservice/gamification/get/offer',
    validateOfferVoucher: '/gameservice/gamification/save/offer/excel',
    getSpinWheelConfig: '/gameservice/gamification/get/list',
    duplicateSpinWheelConfig: '/gameservice/gamification/duplicate/spin/wheel',
    getForgotPasswordLink: '/accounts/get/forgot/password/link',
    resetPassword: '/accounts/update/password',
    forgotPasswordVerifyLink: '/accounts/verify/forgot/password/link',
    getMyOffersList: '/gameservice/gamification/get/offer/list',
    deleteMyOffer: '/gameservice/gamification/delete/offer',
    deleteSpinWheelConfig: '/gameservice/gamification/delete/spin/wheel',
    getSpinWheel: '/gameservice/gamification/get/spin/wheel',
    getQuizUploaded: '/gameservice/gamification/get/quiz/uploaded',
    duplicateSpinWheelTitle: '/gameservice/gamification/spin/wheel/check',
    dublicateMyOffer: '/gameservice/gamification/duplicate/offer',
    getConfigRule: '/gameservice/gamification/get/config/rule',
    saveRule: '/gameservice/rules/save/rule',
    getRuleEngineList: '/gameservice/rules/get/rule/list',
    getDailyStreakList: '/gameservice/rules/get/strak/list',
    duplicateRuleEngine: '/gameservice/rules/duplicate/rule',
    deleteRuleEngine: '/gameservice/rules/delete/rule',
    getRule: '/gameservice/rules/get/rule',
    getReport: '/gameservice/report/get/user/transaction',
    getApiKey: '/userservice/accounts/get/apikey',
    getUserEvents: '/gameservice/user/segement/get/event/list',
    getCustomUserEvents: '/gameservice/user/segement/get/custom/event/list',
    getEventProperty: '/gameservice/user/segement/get/properties/list',
    getCustomEventProperty: '/gameservice/user/segement/get/custom/properties/list',
    downloadOffer: '/gameservice/report/download',
    saveUserSegment: '/gameservice/user/segement/save/user/segment',
    saveUserSegmentFile: '/gameservice/user/segement/save/user/segment/excel',
    getUserSegment: '/gameservice/user/segement/get/segment',
    saveScratchCardConfig: '/gameservice/gamification/save/scratch/card',
    getTemplateConfig: '/gameservice/templates/get/list',
    getTemplate: '/gameservice/templates/get/by/id',
    getSegmentList: '/gameservice/user/segement/get/segment/list',
    getUserSegmentDetail: '/gameservice/user/segement/get/user/segment/data',
    saveGamesConfig: '/gameservice/games/save/config',
    checkUserSegmentName: '/gameservice/user/segement/check/usersegments',
    checkRuleName: '/gameservice/rules/check/name',
    checkOfferName: '/gameservice/gamification/check/offer',
    getUserSegmentList: '/gameservice/user/segement/get/user/list',
    duplicateUserSegment: '/gameservice/user/segement/duplicate/segement',
    deleteUserSegment: '/gameservice/user/segement/delete/segement',
    getGameTemplate: '/gameservice/games/get/gametemplate/list',
    getGameTemplateById: '/gameservice/games/get/game',
    saveQuizConfig: '/gameservice/quiz/save/config',
    getGamesList: '/gameservice/games/get/games/list',
    duplicateGamesConfig: '/gameservice/games/duplicate/game',
    deleteGamesConfig: '/gameservice/games/delete/game',
    getGamesConfig: '/gameservice/games/get/game/config',
    savePollConfig: '/gameservice/poll/save/config',
    getNotification: '/gameservice/notification/count',
    getNotificationList: '/gameservice/notification/get/notification/list',
    readNotification: '/gameservice/notification/update/status',
    checkGamesName: '/gameservice/games/game/check',
    getTemplateList: '/gameservice/gamification/get/template/list',
    getAppConfig: '/gameservice/app/get/appconfig',
    checkDeleteMyOffer: '/gameservice/gamification/delete/offer/list',
    checkDeleteSpinWheelConfig: '/gameservice/gamification/delete/spin/wheel/list',
    checkDeleteGames: '/gameservice/games/check/delete',
    checkCustomEventDelete: '/gameservice/rules/customevent/check/delete',
    checkDeleteUserSegment: '/gameservice/user/segement/check/delete/usersegments',
    getReportGraphData: '/gameservice/report/get/report/graph/data',
    getUserProfile: '/userservice/OrgUser/get/user/profile',
    updateUserProfile: '/userservice/OrgUser/update/user',
    getActivePlan: '/userservice/plan/get/active/plan/list/orgId',
    getPlansDetail: '/userservice/plan/get/active/plan',
    submitCustomPlanDetail: '/userservice/plan/update/plan',
    getGameReport: '/gameservice/report/get/games/transaction',
    createOrder: '/userservice/razorpay/create/order',
    verifySignature: '/userservice/razorpay/verify/signature',
    createUser: '/userservice/accounts/create/account',
    getUserManagementList: '/userservice/accounts/get/created/account/list',
    disableUserAccount: '/userservice/accounts/disable/account',
    getTransactionHistory: '/userservice/razorpay/get/transaction/history',
    generateInvoicePdf: '/userservice/razorpay/invoice/pdf',
    getDailyStreakTemplate: '/gameservice/gamification/get/list',
    getRuleList: '/gameservice/rules/get/rule/list/status',
    getEventList: '/gameservice/customEvent/get/custom/events/list',
    saveCampaign: '/camp/campaign/save/campaign',
    getCampaignList: '/camp/campaign/get/campaign/list',
    saveIntegration: '/userservice/OrgUser/save/integration/data',
    uploadConfigFile: '/userservice/OrgUser/upload/config/file',
    getIntegrationData: '/userservice/OrgUser/get/integration/data',
    getCampaign: '/camp/campaign/get/campaign',
    getUserDetails: '/userservice/accounts/get/user/account',
    deleteUser: '/userservice/accounts/delete/user/account',
    editUser: '/userservice/accounts/edit/account',
    checkDeleteRule: '/camp/campaign/delete/campaign/list',
    getDailyStreakReportList: '/gameservice/report/get/daily/streak',
    checkCampaignDublicate: '/camp/campaign/check/campaign',
    checkScheduleDate: '/gameservice/rules/check/streak/active',
    saveGameMechanics: '/gameservice/badges/save/config',
    getBadgesReport: '/gameservice/report/get/badges',
    saveBadgeConfig: '/gameservice/badges/save/rule',
    getSenderListByUserSegment: '/gameservice/user/segement/usersegment/data',
    getBadgesRuleList: '/gameservice/badges/get/badges/rule/list',
    getBadge: '/gameservice/badges/get/badges',
    deleteCampaign: '/camp/campaign/delete/campaign',
    copyCampaign: '/camp/campaign/duplicate/campaign',
    duplicateBadgeRule: '/gameservice/badges/duplicate/badge/rule',
    deleteBadgeRule: '/gameservice/badges/delete/badge/rule',
    getBadgesConfig: '/gameservice/badges/get/badges/config',
    getGameMechanicsConfig: '/gameservice/badges/get/badges/list',
    duplicateGameMechanicsConfig: '/gameservice/badges/duplicate/badges',
    deleteGameMechanicsConfig: '/gameservice/badges/delete/badges',
    duplicateBadgeTemplate: '/gameservice/badges/check/badges',
    checkBadgeNameExists: '/gameservice/badges/check/badge/name',
    getCategoryList: '/gameservice/badges/get/category',
    getBadgeById: '/gameservice/badges/get/badge',
    checkDeleteGameMechanicsConfig: '/gameservice/badges/delete/badge/list',
    saveLeaderboardConfig: '/gameservice/leaderboard/save/config',
    getMyLeaderBoardList: '/gameservice/leaderboard/get/leaderboard/list',
    duplicateMyLeaderboard: '/gameservice/leaderboard/duplicate/leaderboard',
    deleteMyLeaderboard: '/gameservice/leaderboard/leaderboard/delete',
    getLeaderboardById: '/gameservice/leaderboard/get/leaderboard',
    checkLeaderBoardNameDublicate: '/gameservice/leaderboard/check/leaderboard',
    checkDeleteMyLeaderboard: '/gameservice/leaderboard/delete/list',
    getWebhookList: '/gameservice/webhook/get/list',
    saveWebhookConfig: '/gameservice/webhook/save/config',
    duplicateWebhookList: '/gameservice/webhook/duplicate/webhook',
    deleteWebHook: '/gameservice/webhook/delete/webhook',
    checkDeleteWebhookList: '/gameservice/webhook/delete/webhook/list',
    getWebhookById: '/gameservice/webhook/get/by/id',
    duplicateWebhookTitle: '/gameservice/webhook/check/webhook',
    testWebhookConfig: '/gameservice/webhook/test/config',
    getOfferReport: '/gameservice/report/get/offer/transaction',
    downloadReport: '/gameservice/report/offer/transaction/download',
    getUserReport: '/gameservice/report/get/transaction',
    downloadUserReport: '/gameservice/report/transaction/download',
    sendNowUserReport: '/gameservice/report/transaction/sendemail',
    sendNowOfferReport: '/gameservice/report/offer/transaction/sendemail',
    downloadActivityReport: '/gameservice/report/user/transaction/download',
    sendNowActivityReport: '/gameservice/report/user/transaction/sendemail',
    getCampaignReportList: '/camp/campaign/get/user/transaction/report',
    downloadCampaign: '/camp/campaign/get/user/campaign/report',
    sendNowCampaignReport: '/camp/campaign/get/campaign/report/email',

    getReferralReportList: '/gameservice/report/referral/get/user/transaction/report',
    // downloadReferral: '/gameservice/report/referral/transaction/download',   //earlier this API was used
    downloadReferral: '/gameservice/report/referral/report/transaction/download',
    // sendNow Api yet to develop.
    sendNowReferralReport: '/gameservice/report/referral/get/referral/report/email',

    sendNowGameReport: '/gameservice/report/games/transaction/sendemail',
    downloadGameReport: '/gameservice/report/games/transaction/download',
    downloadDailyStreakReport: '/gameservice/report/daily/streak/download',
    sendNowDailyStreakReport: '/gameservice/report/daily/streak/sendemail',
    getVouchers: '/offerservice/offer/get/voucher',
    getVoucherDetails: '/offerservice/offer/get/voucher/details',
    saveOffer: '/offerservice/offer/save/offer/static',
    getAccountBalance: '/offerservice/offer/get/offer/order/list',
    getAmountValues: '/offerservice/offer/get/offer/last/balance',
    saveAmount: '/offerservice/offer/save/offer/balance',
    getLeaderboardList: '/gameservice/leaderboard/get/list',
    getDefaultLeaderboardById: '/gameservice/leaderboard/get/by/id',
    getCampaignsWithLeaderboard: '/camp/campaign/getCampaignsWithLeaderboard',
    getLeaderboardRows: '/gameservice/report/get/leaderboardRows',
    downloadLeaderboardReport: '/gameservice/report/leaderboard/transaction/download',
    saveReferralConfig: '/gameservice/quiz/save/referral/config',
    saveCustomEventsConfig: '/gameservice/customEvent/save/custom/events',
    getCustomEventsConfig: '/gameservice/customEvent/get/custom/events/list',
    deleteCustomEvents: '/gameservice/customEvent/delete/custom/events',
    duplicateCustomEvents: '/gameservice/customEvent/duplicate/custom/events',
    getCustomEvent: '/gameservice/customEvent/get/custom/event',
    checkDuplicateName: '/gameservice/customEvent/check/duplicate/name',
    getTournamentList: '/gameservice/tournaments/get/tournament/list',
    getTournament: '/gameservice/tournaments/get/by/id',
    saveTournamentConfig: '/gameservice/tournaments/save/config',
    deleteTournamentConfig: '/gameservice/tournaments/delete/tournament',
    checkDeleteTournamentConfig: '/gameservice/tournaments/delete/tournament/list',
    checkTournamentTitle: '/gameservice/tournaments/tournament/check',
    duplicateTournamentConfig: '/gameservice/tournaments/duplicate/tournament',
    saveProgressBarConfig: '/gameservice/progressBar/save/config',
    getProgressBarTemplateConfig: '/gameservice/progressBar/get/list',
    getProgressBarConfigById: '/gameservice/progressBar/get/progressBar/config',
    checkProgressBarName: '/gameservice/progressBar/check/progressBars',
    deleteProgressBar: '/gameservice/progressBar/delete/progressBar',

    // new APIs for pagination and upload questions features in Super Quiz
    getQuestions: '/gameservice/quiz/get/quiz/questions',
    deleteQuestion: '/gameservice/quiz/deleteQuestion',
    saveQuestion: '/gameservice/quiz/saveQuestion',

    saveQuestConfig: '/gameservice/rules/save/quest',
    getQuestList: '/gameservice/quest/get/quest/list',
    duplicateQuestConfig: '/gameservice/quest/duplicate/quest',

    // Notifications
    getNotificationsList: '/gameservice/notifications/get/notifications/list',
    saveNotificationsConfig: '/gameservice/notifications/save/config',
    checkNotificationsTitle: '/gameservice/notifications/check/notification',

    // Game Mechanics
    getGameMechanicsTemplates: '/gameservice/gamemechanics/get/game/list',

    // getrulenames for leaderboard reports dropdown
    getRuleNames: '/gameservice/report/get/leaderboardRuleNames',

    // Memorygame
    saveMemoryGame: '/gameservice/gamification/save/memoryGame',

    // customEvents rule
    getCustomEventsRuleList: '/gameservice/rules/get/customEventRule/list',
    saveWalletTransactionPageConfig: '/gameservice/pages/save/walletTransaction',
    getWalletTransactionPageConfig: '/gameservice/pages/get/walletTransaction',

    // custom events template
    saveInfoScreenPageConfig: '/gameservice/pages/save/infoScreenPage',
    getInfoScreenPageConfig: '/gameservice/pages/get/infoScreenPage',
    getInfoScreenPageConfigsList: '/gameservice/pages/get/list',
    checkInfoScreenName: '/gameservice/pages/check/infoScreens',
    duplicateInfoScreenPageConfig: '/gameservice/pages/duplicate/infoScreenPage',
    deleteInfoScreenPageConfig: '/gameservice/pages/delete/infoScreenPage',

    saveBrandKit: '/gameservice/gamification/save/brandKit/config',
    getBrandKit: '/gameservice/gamification/get/brandKit/config',

    // smart campaigns
    getSmartCampaignsList: '/gameservice/rules/get/smartCampaigns/list',
    getSmartCampaign: '/gameservice/rules/get/smartCampaign',
    getThemeList: '/gameservice/gamification/get/theme/list',
    saveTheme: '/gameservice/gamification/save/theme',
    getThemeConfigList: '/gameservice/gamification/get/theme/config/list',
    getThemeConfig: '/gameservice/gamification/get/theme/config',
    deleteThemeConfig: '/gameservice/gamification/delete/theme/config',
    duplicateThemeConfig: '/gameservice/gamification/duplicate/theme/config',
    duplicateThemeCheck: '/gameservice/gamification/theme/config/check',

    getQuestRows: '/gameservice/report/get/questRows',
    getCampaigns: '/offerservice/offer/get/thirdPartyCampaigns',
    saveOfferThirdParty: '/offerservice/offer/save/offer/thirdParty',
    getMyOffersThirdPartyList: '/gameservice/gamification/get/offerthirdparty/list',
    getOfferManagerThirdParty: '/gameservice/gamification/get/offerthirdparty',
  },
};
