import { CONSTANTS } from '@constants';

export const REQUESTED = 'REQUESTED';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export const UPDATE_FIELD = 'reports/UPDATE_FIELD';
export const UPDATE_PAGE = 'reports/UPDATE_PAGE';
export const CLEAR_STATE = 'reports/CLEAR_STATE';

export const GET_SENDERLIST_REQUESTED = 'reports/GET_SENDERLIST_REQUESTED';
export const GET_SENDERLIST_SUCCESS = 'reports/GET_SENDERLIST_SUCCESS';
export const GET_SENDERLIST_FAILURE = 'reports/GET_SENDERLIST_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  tableData: {},
  initialData: {
    selectedReportIds: [],
    senderList: '',
    pageNo: 0,
    sortBy: 'updatedTS',
    orderBy: 'desc',
    activePage: 1,
    searchKey: '',
    searchCampaign: '',
    sendReport: '',
    selectedMethod: '',
    selectedPeriod: '',
    selectedPeriodFrom: '',
    selectedPeriodEnd: '',
    selectedPeriodYear: null,
    selectedPeriodMonth: null,
    selectedPeriodWeek: null,
    headers: [
      {
        title: 'Actions',
        slug: 'actions',
        isVisible: false,
        width: 50,
      },
      {
        title: CONSTANTS.CAMPAIGN_NAME,
        slug: 'campaign_name',
        isVisible: true,
        width: 150,
      },
      {
        title: CONSTANTS.CALENDER_DURATION,
        slug: 'duration',
        isVisible: true,
        width: 150,
      },
      {
        title: CONSTANTS.STATUS_FILTER,
        slug: 'status',
        isVisible: true,
        width: 150,
        centre: true
      },
      {
        title: CONSTANTS.SEGMENT_NAME,
        slug: 'segment_name',
        isVisible: true,
        width: 150,
      },
      {
        title: CONSTANTS.ACTIVITY_DISPLAY,
        slug: 'activity_display',
        isVisible: true,
        width: 150,
        centre: true
      },
      {
        title: CONSTANTS.USER_INTERACTED,
        slug: 'user_interacted',
        isVisible: true,
        width: 150,
        centre: true
      },
      {
        title: CONSTANTS.TOTAL_OFFER,
        slug: 'totalOffer',
        isVisible: true,
        width: 150,
        centre: true
      },
      {
        title: CONSTANTS.OFFER_DISTRIBUTED,
        slug: 'offer_distributed',
        isVisible: true,
        width: 150,
        centre: true
      },
      {
        title: CONSTANTS.TOTAL_OFFER_REDEEM,
        slug: 'totalOfferRedeem',
        isVisible: true,
        width: 150,
        centre: true
      },
    ]
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_SENDERLIST_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_SENDERLIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_SENDERLIST_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    case UPDATE_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const getReport = (data) => {
  return {
    types: [REQUESTED, SUCCESS, FAILURE],
    promise: client => client.get(`getReport/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&orderBy=${data.orderBy}&selectedFromDate=${data.selectedPeriodFrom}&selectedToDate=${data.selectedPeriodEnd}`)
  };
};

export const downloaActivity = (data) => {
  return {
    types: [REQUESTED, SUCCESS, FAILURE],
    promise: client => client.get(`downloadActivityReport?selectedPeriodFrom=${data.selectedPeriodFrom}&selectedPeriodEnd=${data.selectedPeriodEnd}&ruleIdList=${data.ruleIdList}`)
  };
};

// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const sendNowActivityReport = (data) => {
  return {
    types: [GET_SENDERLIST_REQUESTED, GET_SENDERLIST_SUCCESS, GET_SENDERLIST_FAILURE],
    promise: client => client.get(`sendNowActivityReport?selectedPeriodFrom=${data.selectedPeriodFrom}&selectedPeriodEnd=${data.selectedPeriodEnd}&emailList=${data.emailList}&ruleIdList=${data.ruleIdList}`)
  };
};
