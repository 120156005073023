import { CONSTANTS } from '@constants';

export const GET_REPORT_REQUESTED = 'get/GET_REPORT_REQUESTED';
export const GET_REPORT_SUCCESS = 'get/GET_REPORT_SUCCESS';
export const GET_REPORT_FAILURE = 'get/GET_REPORT_FAILURE';
export const DOWNLOAD_OFFER_REQUESTED = 'get/DOWNLOAD_OFFER_REQUESTED';
export const DOWNLOAD_OFFER_SUCCESS = 'get/DOWNLOAD_OFFER_SUCCESS';
export const DOWNLOAD_OFFER_FAILURE = 'get/DOWNLOAD_OFFER_FAILURE';

export const UPDATE_FIELD = 'badgesReport/UPDATE_FIELD';
export const UPDATE_PAGE = 'badgesReport/UPDATE_PAGE';
export const CLEAR_STATE = 'badgesReport/CLEAR_STATE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  tableData: {},
  initialData: {
    pageNo: 0,
    sortBy: 'updatedTS',
    orderBy: 'desc',
    activePage: 1,
    searchKey: '',
    offerList: {
      headers: [
        {
          title: CONSTANTS.OFFER_NAME,
          slug: 'offer_name',
          isVisible: true,
          width: 150,
        },
        {
          title: CONSTANTS.TOTAL_OFFER,
          slug: 'totalOffer',
          center: true,
          isVisible: true,
          width: 110,
        },
        {
          title: CONSTANTS.TOTAL_OFFER_WON,
          slug: 'totalOfferWon',
          center: true,
          isVisible: true,
          width: 190,
        },
        {
          title: CONSTANTS.TOTAL_OFFER_REDEEM,
          slug: 'totalOfferRedeem',
          center: true,
          isVisible: true,
          width: 200,
        },
        {
          title: 'Actions',
          slug: 'actions',
          isVisible: false,
          width: 20,
        }
      ]
    },
    headers: [
      {
        title: CONSTANTS.RULE_NAME,
        slug: 'rule_name',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        sortByOrder: 'desc',
        width: 160,
      },
      {
        title: CONSTANTS.SEGMENT_NAME_TITLE,
        slug: 'segment_name',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: false,
        sortByOrder: 'desc',
        width: 150,
      },
      {
        title: CONSTANTS.TOTAL_USERBADGES,
        slug: 'totalUserBadges',
        isSortable: true,
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: 'desc',
        width: 150,
      },
      {
        title: CONSTANTS.BADGETYPE,
        slug: 'badgeType',
        isSortable: true,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: 'desc',
        width: 100,
      },
      {
        title: CONSTANTS.BADGECRITERIA,
        slug: 'badgeCriteria',
        isSortable: true,
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: 'desc',
        width: 150,
      },
      {
        title: 'Actions',
        slug: 'actions',
        isSortable: false,
        isVisible: false,
        tooltip: false,
        sortByOrder: '',
        width: 40,
      },
    ]
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORT_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_REPORT_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_REPORT_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DOWNLOAD_OFFER_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DOWNLOAD_OFFER_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case DOWNLOAD_OFFER_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const getBadgesReport = (data) => {
  return {
    types: [GET_REPORT_REQUESTED, GET_REPORT_SUCCESS, GET_REPORT_FAILURE],
    promise: client => client.get(`getBadgesReport/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&orderBy=${data.orderBy}`)
  };
};

export const downloadOffer = (data) => {
  return {
    types: [DOWNLOAD_OFFER_REQUESTED, DOWNLOAD_OFFER_SUCCESS, DOWNLOAD_OFFER_FAILURE],
    promise: client => client.get(`downloadOffer?offerId=${data}`)
  };
};

// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};
