
import { APP_CONFIG } from '@constants';


const upload = require('superagent');

export const UPLOAD_IMAGE_REQUESTED = 'upload/UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_IMAGE_SUCCESS = 'upload/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'upload/UPLOAD_IMAGE_FAILURE';

export const SAVE_CONFIG_REQUESTED = 'save/SAVE_CONFIG_REQUESTED';
export const SAVE_CONFIG_SUCCESS = 'save/SAVE_CONFIG_SUCCESS';
export const SAVE_CONFIG_FAILURE = 'save/SAVE_CONFIG_FAILURE';

export const UPDATE_TITLE_FIELD = 'games/UPDATE_TITLE_FIELD';
export const UPDATE_SUBTITLE_FIELD = 'games/UPDATE_SUBTITLE_FIELD';
export const UPDATE_TEMPLATE_FIELD = 'games/UPDATE_TEMPLATE_FIELD';

export const GET_CONFIG_REQUESTED = 'games/GET_CONFIG_REQUESTED';
export const GET_CONFIG_SUCCESS = 'games/GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'games/GET_CONFIG_FAILURE';
export const GET_DUPLICATE_REQUESTED = 'games/GET_DUPLICATE_REQUESTED';
export const GET_DUPLICATE_SUCCESS = 'games/GET_DUPLICATE_SUCCESS';
export const GET_DUPLICATE_FAILURE = 'games/GET_DUPLICATE_FAILURE';
export const UPDATE_FIELD = 'games/UPDATE_FIELD';
export const UPDATE_PAGE = 'games/UPDATE_PAGE';
export const UPDATE_TABLE_FIELD = 'games/UPDATE_TABLE_FIELD';
export const UPDATE_RESULT_FIELD = 'games/UPDATE_RESULT_FIELD';
export const UPDATE_APPCONFIG_FIELD = 'games/UPDATE_APPCONFIG_FIELD';

export const UPDATE_LANGUAGE = 'games/UPDATE_LANGUAGE';
export const UPDATE_CONFIG_FIELD = 'games/UPDATE_CONFIG_FIELD';
export const UPDATE_LOCALIZATION_FLAG = 'games/UPDATE_LOCALIZATION_FLAG';
export const UPDATE_ACTIVE_FLAG = 'games/UPDATE_ACTIVE_FLAG';
export const CLEAR_STATE = 'games/CLEAR_STATE';
export const REMOVE_CONFIG = 'games/REMOVE_CONFIG';
export const UPDATE_BUTTON = 'games/UPDATE_BUTTON';

export const UPDATE_START_FIELD = 'games/UPDATE_START_FIELD';
export const UPDATE_END_FIELD = 'games/UPDATE_END_FIELD';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  initialValues: {
    fontTitlePositions: [],
    fonts: [],
    fontStrength: [],
    fontSize: []
  },
  localizationFlag: '',
  activeNavTab: 'English',
  config: {
    localizationconfig: [
      {
        language: 'English',
        title: {
          text: '',
          color: '#000000',
          font: {
            size: '14',
            strength: '400',
            family: 'Arial, sans-serif',
            align: 'left',
          },
          position: 'flex-start',
          titleError: '',
          disableSave: false,
          templateName: {
            text: ''
          },
          templateError: '',
        },
        subtitle: {
          text: '',
          color: '#000000',
          font: {
            size: '14',
            strength: '400',
            family: 'Arial, sans-serif',
            align: 'left',
          },
          titleError: '',
        },
        code: '',
        url: '',
        isPortrait: true,
        description: '',
        gamePreviews: '',
        assets: {
          cover: '',
          brick: '',
          thumb: '',
          wall: '',
          square: '',
          screens: {
            page: 1,
            perPage: 1,
            list: [],
          },
          coverTiny: '',
          brickTiny: ''
        },
        categories: '',
        tags: '',
        width: 0,
        height: 0,
        colorMuted: '',
        colorVibrant: '',
        privateAllowed: true,
        scoringFormat: 'best',
        finalUrl: '',
        playMore: false,
        maxPlaysOption: 'maxplays',
        maxPlays: 3,
        maxPlaysError: '',
        resultHandler: 'ParentApp',
        resultText: {
          winningText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: '14',
              strength: '400',
              family: 'Arial, sans-serif',
              align: 'left',
            },
            error: '',
            errorImg: ''
          },
          losingText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: '14',
              strength: '400',
              family: 'Arial, sans-serif',
              align: 'left',
            },
            error: '',
            errorImg: ''
          }
        }
      }
    ]
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_TITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].title = state.config.localizationconfig[i].title
            ? {
              ...state.config.localizationconfig[i].title,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_SUBTITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].subtitle = state.config.localizationconfig[i].subtitle
            ? {
              ...state.config.localizationconfig[i].subtitle,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_TEMPLATE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].assets.screens = state.config.localizationconfig[i].assets.screens
            ? {
              ...state.config.localizationconfig[i].assets.screens,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_TABLE_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_START_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].start = state.config.localizationconfig[i].start
            ? {
              ...state.config.localizationconfig[i].start,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_END_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].end = state.config.localizationconfig[i].end
            ? {
              ...state.config.localizationconfig[i].end,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case GET_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_DUPLICATE_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case GET_DUPLICATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_DUPLICATE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case UPDATE_RESULT_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].resultText = state.config.localizationconfig[i].resultText
            ? {
              ...state.config.localizationconfig[i].resultText,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_APPCONFIG_FIELD: {
      state.initialValues = state.initialValues
        ? {
          ...state.initialValues,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_LOCALIZATION_FLAG: {
      return { ...state, localizationFlag: action.payload };
    }
    case UPDATE_ACTIVE_FLAG: {
      return { ...state, activeNavTab: action.payload };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case REMOVE_CONFIG: {
      return { ...state, config: action.payload };
    }
    case UPDATE_CONFIG_FIELD: {
      const localizationconfig = [...state.config.localizationconfig, ...action.payload.localizationconfig];
      const config = {
        ...state.config,
        localizationconfig
      };
      return { ...state, config };
    }
    case UPDATE_BUTTON: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    default:
      return state;
  }
};


export const updateTitle = (payload) => {
  return {
    type: UPDATE_TITLE_FIELD,
    payload,
  };
};

export const updateSubTitle = (payload) => {
  return {
    type: UPDATE_SUBTITLE_FIELD,
    payload,
  };
};

export const updateTemplate = (payload) => {
  return {
    type: UPDATE_TEMPLATE_FIELD,
    payload,
  };
};

export const updateGameConfig = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};


// action
export const updateTableFields = (payload) => {
  return {
    type: UPDATE_TABLE_FIELD,
    payload,
  };
};
export const saveGamesConfig = (data) => {
  return {
    types: [SAVE_CONFIG_REQUESTED, SAVE_CONFIG_SUCCESS, SAVE_CONFIG_FAILURE],
    promise: client => client.post('saveGamesConfig', {
      data,
    }),
  };
};

export const getTemplate = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getGameTemplateById?id=${data}`),
  };
};

export const getGames = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getGamesConfig?id=${data}`),
  };
};

export const editGamesConfig = (data, gamificationId) => {
  return {
    types: [SAVE_CONFIG_REQUESTED, SAVE_CONFIG_SUCCESS, SAVE_CONFIG_FAILURE],
    promise: client => client.post(`saveGamesConfig?gameId=${gamificationId}`, {
      data,
    }),
  };
};

export const checkGamesName = (data, id) => {
  return {
    types: [GET_DUPLICATE_REQUESTED, GET_DUPLICATE_SUCCESS, GET_DUPLICATE_FAILURE],
    promise: client => client.get(`checkGamesName?templateName=${data}&id=${id}`),
  };
};

export const updateResult = (payload) => {
  return {
    type: UPDATE_RESULT_FIELD,
    payload,
  };
};

export const uploadimage = (file, folderName) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/gamification/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
      .field('folderName', folderName),
  };
};

export const getAppConfig = () => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get('getAppConfig'),
  };
};

export const updateDropdown = (payload) => {
  return {
    type: UPDATE_APPCONFIG_FIELD,
    payload,
  };
};

export const updateLocalizationFlag = (payload) => {
  return {
    type: UPDATE_LOCALIZATION_FLAG,
    payload,
  };
};


export const updateActiveFlag = (payload) => {
  return {
    type: UPDATE_ACTIVE_FLAG,
    payload,
  };
};

export const removeConfig = (payload) => {
  return {
    type: REMOVE_CONFIG,
    payload
  };
};

export const updateLanguage = (payload) => {
  return {
    type: UPDATE_LANGUAGE,
    payload
  };
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const updateConfig = (payload) => {
  return {
    type: UPDATE_CONFIG_FIELD,
    payload
  };
};

export const updateButton = (payload) => {
  return {
    type: UPDATE_BUTTON,
    payload
  };
};

export const updateStart = (payload) => {
  return {
    type: UPDATE_START_FIELD,
    payload,
  };
};

export const updateEnd = (payload) => {
  return {
    type: UPDATE_END_FIELD,
    payload,
  };
};
