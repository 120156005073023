/* eslint-disable */

import { CONSTANTS } from '@constants';

export const SAVE_CONFIG_REQUESTED = 'save/SAVE_CONFIG_REQUEST';
export const SAVE_CONFIG_SUCCESS = 'save/SAVE_CONFIG_SUCCES';
export const SAVE_CONFIG_FAILURE = 'save/SAVE_CONFIG_FAILURES';

export const UPDATE_CONFIG_REQUESTED = 'save/UPDATE_CONFIG_REQUEST';
export const UPDATE_CONFIG_SUCCESS = 'save/UPDATE_CONFIG_SUCCES';
export const UPDATE_CONFIG_FAILURE = 'save/UPDATE_CONFIG_FAILURES';

export const GET_CONFIG_REQUESTED = 'get/GET_CONFIG_REQUEST';
export const GET_CONFIG_SUCCESS = 'get/GET_CONFIG_SUCCES';
export const GET_CONFIG_FAILURE = 'get/GET_CONFIG_FAILURES';

export const UPDATE_EVENT_ID = 'customEvents/UPDATE_EVENT_ID';
export const UPDATE_EVENT_FIELD = 'customEvents/UPDATE_EVENT_FIELD';
export const UPDATE_EVENT_PROPERTIES = 'customEvents/UPDATE_EVENT_PROPERTIES'
export const UPDATE_EVENTKEY_FIELD = 'customEvents/UPDATE_EVENTKEY_FIELD';
export const UPDATE_EVENTVALUE_FIELD = 'customEvents/UPDATE_EVENTVALUE_FIELD';
export const UPDATE_ERROR = 'customEvents/UPDATE_ERROR';

export const GET_EVENT_REQUESTED = 'GET_EVENT_REQUESTED';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_FAILURE = 'GET_EVENT_FAILURE';

export const DELETE_CONFIG_REQUESTED = 'get/DELETE_CONFIG_REQUEST';
export const DELETE_CONFIG_SUCCESS = 'get/DELETE_CONFIG_SUCCES';
export const DELETE_CONFIG_FAILURE = 'get/DELETE_CONFIG_FAILURES';

export const CHECK_DUPLICATE_NAME_REQUESTED = 'check/CHECK_DUPLICATE_NAME_REQUESTED';
export const CHECK_DUPLICATE_NAME_SUCCESS = 'check/CHECK_DUPLICATE_NAME_SUCCESS';
export const CHECK_DUPLICATE_NAME_FAILURE = 'check/CHECK_DUPLICATE_NAME_FAILURE';

export const VALIDATE_CUSTOMIZE_DATA = 'customEvents/VALIDATE_CUSTOMIZE_DATA';
export const UPDATE_PAGE = 'customEvents/UPDATE_PAGE';

// export const DUPLICATE_CONFIG_REQUESTED = 'get/DUPLICATE_CONFIG_REQUESTED';
// export const DUPLICATE_CONFIG_SUCCESS = 'get/DUPLICATE_CONFIG_SUCCESS';
// export const DUPLICATE_CONFIG_FAILURE = 'get/DUPLICATE_CONFIG_FAILURE';

export const CLEAR_STATE = 'customEvents/CLEAR_STATE';
export const UPDATE_FIELD = 'customEvents/UPDATE_FIELD';

export const UPDATE_EDIT_PAGE_REQUESTED = 'customEvents/UPDATE_EDIT_PAGE_REQUESTED';
export const UPDATE_EDIT_PAGE_SUCCESS = 'customEvents/UPDATE_EDIT_PAGE_SUCCESS';
export const UPDATE_EDIT_PAGE_FAILURE = 'customEvents/UPDATE_EDIT_PAGE_FAILURE';

export const ADD_PROPERTY = 'customEvents/ADD_PROPERTY';
export const REMOVE_PROPERTY = 'customEvents/REMOVE_PROPERTY';
export const UPDATE_PROPERTY = 'customEvents/UPDATE_PROPERTY';
export const CLEAR_PROPERTY = 'customEvents/CLEAR_PROPERTY';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  configLoading: false,
  saveRuleLoading: false,
  tableData: {},
  editingValues: null,
  options: {
    customEventsTemplates: [],
  },
  initialData: {
    pageNo: 0,
    eventId: '',
    title: '',
    sortBy: 'createdTS',
    orderBy: 'desc',
    activeTab: 'my_events',
    activePage: 1,
    headers: [
      {
        title: CONSTANTS.EVENT_NAME,
        slug: 'event_name',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: '',
        width: 180
      },
      {
        title: CONSTANTS.EVENT_KEY,
        slug: 'event_key',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: '',
        width: 180
      },
      {
        title: 'Property Name',
        slug: 'property_name',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: '',
        width: 180
      },
      {
        title: 'Property Type',
        slug: 'property_type',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: '',
        width: 180
      },
      {
        title: 'Date & Time',
        slug: 'start_date',
        isSortable: true,
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: 'desc',
        width: 170,
      },
      {
        title: 'Actions',
        slug: 'actions',
        isSortable: false,
        isVisible: false,
        tooltip: true,
        sortByOrder: '',
        width: 180,
      },
    ],
    deleteEventList: {
      eventName: '',
      list: []
    },
    deleteRuleList: {
      templateName: '',
      list: []
    },
  },
  initialValues: {
    fonts: [],
    fontStrength: [],
    fontSize: [],
  },
  activeNavTab: 'customize',
  customize: {
    errors: {
      nameError: '',
      keyError: '',
    },
    mode: 'save',
    allowEdit: true,
    eventName: {
      text: '',
    },
    eventKey: {
      text: '',
    },
    eventValue: {
      text: '',
    },
    eventId: {
      text: '',
    },
    properties: [{propertyName: '', propertyType: 'number'}],
    disable: false
  },
  templates: [],
};

export const addProperty = () => {
  return {
    type: ADD_PROPERTY
  };
};

export const removeProperty = (index) => {
  return {
    type: REMOVE_PROPERTY,
    index
  };
};

export const updateProperty = (index, propertyName, propertyType) => {
  return {
    type: UPDATE_PROPERTY,
    index,
    propertyName,
    propertyType
  };
};

export const clearProperty = (index, propertyName, propertyType) => {
  console.log('clear Properties',index,propertyName,propertyType);
  return {
    type: CLEAR_PROPERTY,
    index,
    propertyName,
    propertyType
  }
}


export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CONFIG_SUCCESS: {
      console.log('State', state);
      // state.custom   
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_EVENT_ID: {
      state.customize.eventId = state.customize.eventId
        ? {
          ...state.customize.eventId,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_EVENT_FIELD: {
      state.customize.eventName = state.customize.eventName
        ? {
          ...state.customize.eventName,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_EVENT_PROPERTIES: {
      console.log('Update Event Property');
      state.customize.properties = [{propertyName:'', propertyType:'Number'}]
      ? {
        ...state.customize.properties,
        ...action.payload,
      }
      : { ...action.payload };
      console.log(state.customize.properties);
    return { ...state };
    }
    case UPDATE_ERROR: {
      state.customize.errors = state.customize.errors
        ? {
          ...state.customize.errors,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_EVENTKEY_FIELD: {
      state.customize.eventKey = state.customize.eventKey
        ? {
          ...state.customize.eventKey,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_EVENTVALUE_FIELD: {
      state.customize.eventValue = state.customize.eventValue
        ? {
          ...state.customize.eventValue,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case GET_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CONFIG_SUCCESS: {
      const customEvents = action.result.CustomEvents.customEventsDTO;
      const templateOptions = [];
      for (let i = 0; i < customEvents.length; i += 1) {
        if (customEvents[i].templateName != null) {
          templateOptions.push({ title: customEvents[i].templateName, value: customEvents[i].id, typeID: customEvents[i].typeId });
        } else {
          templateOptions.push({ title: customEvents[i].title, value: customEvents[i].id, typeID: customEvents[i].typeId });
        }
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        options: { ...state.options, ...{ customEventsTemplates: templateOptions } },
      };
    }
    case GET_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_EVENT_REQUESTED: {
      return {
        ...state,
        saveRuleLoading: true,
        loading: true,
        loaded: false,
      };
    }
    case GET_EVENT_SUCCESS: {
      return {
        ...state,
        saveRuleLoading: false,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_EVENT_FAILURE: {
      return {
        ...state,
        saveRuleLoading: false,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DELETE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DELETE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case CHECK_DUPLICATE_NAME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case CHECK_DUPLICATE_NAME_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case CHECK_DUPLICATE_NAME_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    // case DUPLICATE_CONFIG_REQUESTED:
    // {
    //   return {
    //     ...state,
    //     loading: true,
    //     loaded: false
    //   };
    // }
    // case DUPLICATE_CONFIG_SUCCESS:
    // {
    //   return {
    //     ...state,
    //     loading: false,
    //     error: false,
    //     loaded: true
    //   };
    // }
    // case DUPLICATE_CONFIG_FAILURE:
    // {
    //   return {
    //     ...state,
    //     loading: false,
    //     loaded: false,
    //     error: true,
    //   };
    // }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case UPDATE_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case UPDATE_EDIT_PAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_EDIT_PAGE_SUCCESS: {
      state.customize.mode = 'edit';
      const properties = action.result.CustomEvent.properties && action.result.CustomEvent.properties.length == 0 ? [{ propertyName: '', propertyType: 'number' }] : action.result.CustomEvent.properties;
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        // editingValues: { ...action.result.CustomEvent },
        customize: {
          ...state.customize,
          eventName: { ...state.customize.eventName, text: action.result.CustomEvent.eventName },
          eventKey: { ...state.customize.eventKey, text: action.result.CustomEvent.eventKey },
          eventValue: { ...state.customize.eventValue, text: action.result.CustomEvent.eventValue },
          eventId: { ...state.customize.eventValue, text: action.result.CustomEvent.id },
          properties : properties,
          disable : action.result.CustomEvent.disable
          // properties: {...state.customize.properties }
        }             
      };
    }
    case UPDATE_EDIT_PAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    case VALIDATE_CUSTOMIZE_DATA: {
      const {
        eventName,
        eventKey,
        eventValue,
        properties,
        errors,
      } = state.customize;
      let error = {
        validCustomize: true,
        propertyEnpty: false,
        eventNameError: errors.nameError,
        eventValueError: '',
        eventKeyError: errors.keyError
      };
      console.log('eventNameError:', errors.nameError);

      if (errors.nameError !== '') {
        error = {
          ...error,
          validCustomize: false,
        };
      }

      if (errors.keyError !== '') {
        error = {
          ...error,
          validCustomize: false,
        };
      }
      if (properties[0].propertyName === '') {
        error = {
          ...error,
          propertyEnpty: true,
        }
      }
      if (eventName.text === '') {
        error = {
          ...error,
          validCustomize: false,
          eventNameError: 'Event name is Required'
        };
      }
      if (eventName.text !== '' && (eventName.text.length < 3 || eventName.text.length > 50)) {
        error = {
          ...error,
          validCustomize: false,
          eventNameError: 'Event name should be of min 3 and max 50 characters'
        };
      }
      if (eventKey.text === '') {
        error = {
          ...error,
          validCustomize: false,
          eventKeyError: 'Event Key is Required'
        };
      }
      if (eventValue.text === '') {
        error = {
          ...error,
          validCustomize: false,
          eventValueError: 'Event Value is Required'
        };
      }
      const customize = {
        ...state.customize,
        ...error
      };
      return {
        ...state,
        customize
      };
    }

    case ADD_PROPERTY: {
      return {
        ...state,
        customize: {
          ...state.customize,
          properties: [...state.customize.properties, { propertyName: '', propertyType: 'number' }]
        }
      };
    }
    
    case REMOVE_PROPERTY: {
      return {
        ...state,
        customize: {
          ...state.customize,
          properties: state.customize.properties.filter((_, index) => index !== action.index)
        }
      };
    }
    
    case UPDATE_PROPERTY: {
      const updatedProperties = state.customize.properties.map((property, index) => {
        if (index === action.index) {
          return { ...property, propertyName: action.propertyName, propertyType: action.propertyType };
        }
        return property;
      });
      console.log('Update');
      console.log(state);
      return {
        ...state,
        customize: {
          ...state.customize,
          properties: updatedProperties
        }
      };
    }

    case CLEAR_PROPERTY: {
      // function deepCopy(obj) {
      //   if (typeof obj !== 'object' || obj === null) {
      //     return obj; // Primitives or null don't need copying
      //   }
      
      //   const result = Array.isArray(obj) ? [] : {};
      //   for (const key in obj) {
      //     result[key] = deepCopy(obj[key]);
      //   }
      //   return result;
      // }
      
      // function resetState() {
      //   return deepCopy(initialState);
      // }
      let obj= {
        ...state,
        customize: {
          ...state.customize,
          properties: [{propertyName : '' , propertyType :'number'}]
        },
      };
      console.log('Clear Property', obj);
      return obj;
    }


    default:
      return state;
  }
  
};

export const saveCustomEventsConfig = (data) => {
  console.log('data');
  console.log(data);
  //console.log(state.customize.properties);
  return {
    data: {
      ...data,
     // ...this.state.customize.properties,
    },
    types: [SAVE_CONFIG_REQUESTED, SAVE_CONFIG_SUCCESS, SAVE_CONFIG_FAILURE],
    promise: client => client.post('saveCustomEventsConfig', {
      data,
    }),
  };
};

export const editCustomEventsConfig = (data, id) => {
  return {
    types: [UPDATE_CONFIG_REQUESTED, UPDATE_CONFIG_SUCCESS, UPDATE_CONFIG_FAILURE],
    promise: client => client.post(`saveCustomEventsConfig?eventId=${id}`, {
      data,
    }),
  };
};

export const updateEventId = (payload) => {
  return {
    type: UPDATE_EVENT_ID,
    payload,
  };
};

export const updateEvent = (payload) => {
  return {
    type: UPDATE_EVENT_FIELD,
    payload,
  };
};

export const updateEventProperty = (payload) => {
  return {
    type: UPDATE_EVENT_PROPERTIES,
    payload,
  };
};

export const updateError = (payload) => {
  return {
    type: UPDATE_ERROR,
    payload,
  };
};

export const updateEventKey = (payload) => {
  return {
    type: UPDATE_EVENTKEY_FIELD,
    payload,
  };
};

export const updateEventValue = (payload) => {
  return {
    type: UPDATE_EVENTVALUE_FIELD,
    payload,
  };
};

// export const updateConfig = (payload) => {
//   return {
//     type: UPDATE_CONFIG_FIELD,
//     payload
//   };
// };

export const validateCustomize = () => {
  return {
    type: VALIDATE_CUSTOMIZE_DATA,
  };
};

// export const getCustomEventsConfig = (data) => {
//   return {
//     types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
//     promise: client => client.get(
//       `getCustomEventsConfig/${data.itemsPerPage}/${data.pageNo}?title=${data.title}&sortBy=${data.sortBy}&orderBy=${
//         data.orderBy
//       }`
//     ),
//   };
// };

export const getCustomEventsConfig  = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(
      `getCustomEventsConfig/${data.itemsPerPage}/${data.pageNo}?title=${data.title}&sortBy=${data.sortBy}&orderBy=${
        data.orderBy
      }`
    ),
  };
};



export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const deleteCustomEvents = (data) => {
  return {
    types: [DELETE_CONFIG_REQUESTED, DELETE_CONFIG_SUCCESS, DELETE_CONFIG_FAILURE],
    promise: client => client.post(`deleteCustomEvents?id=${data}`)
  };
};

export const checkDeleteCustomEvents = (data) => {
  // console.log('checkDeleteCustomEvents');
  return {
    types: [DELETE_CONFIG_REQUESTED, DELETE_CONFIG_SUCCESS, DELETE_CONFIG_FAILURE],
    promise: client => client.post(`checkCustomEventDelete?id=${data}`)
  };
};

export const checkDuplicateName = (data) => {
  console.log('Check duplicate ', data);
  return {
    types: [CHECK_DUPLICATE_NAME_REQUESTED, CHECK_DUPLICATE_NAME_SUCCESS, CHECK_DUPLICATE_NAME_FAILURE],
    promise: client => client.get(`checkDuplicateName?id=${data.eventId}&eventName=${data.eventName}&eventKey=${data.eventKey}`)
  };
};

// export const duplicateCustomEvents = (data) => {
//   return {
//     types: [DUPLICATE_CONFIG_REQUESTED, DUPLICATE_CONFIG_SUCCESS, DUPLICATE_CONFIG_FAILURE],
//     promise: client => client.get(`duplicateCustomEvents?ruleId=${data}`)
//   };
// };

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const propertiesReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_PROPERTY':
      return [...state, { propertyName: '', propertyType: '' }];
    case 'REMOVE_PROPERTY':
      return state.filter((_, index) => index !== action.index);
    case 'UPDATE_PROPERTY_NAME':
      return state.map((property, index) =>
        index === action.index ? { ...property, propertyName: action.propertyName } : property
      );
    case 'UPDATE_PROPERTY_TYPE':
      return state.map((property, index) =>
        index === action.index ? { ...property, propertyType: action.propertyType } : property
      );
    default:
      return state;
  }
};


export const updateEditPage = (data) => {
  return {
    types: [UPDATE_EDIT_PAGE_REQUESTED, UPDATE_EDIT_PAGE_SUCCESS, UPDATE_EDIT_PAGE_FAILURE],
    promise: client => client.get(`getCustomEvent?eventId=${data}`)
  };
};


