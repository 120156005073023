import { APP_CONFIG, CONSTANTS } from '@constants';

import { sort } from '@helpers/Utility';

const upload = require('superagent');

export const UPDATE_COMPAIGN_FIELD = 'compaign/UPDATE_COMPAIGN_FIELD';
export const UPDATE_PAGE = 'compaign/UPDATE_PAGE';
export const CLEAR_FORM = 'compaign/CLEAR_FORM';
export const UPDATE_FIELD = 'compaign/UPDATE_FIELD';

export const UPLOAD_IMAGE_REQUESTED = 'upload/UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_IMAGE_SUCCESS = 'upload/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'upload/UPLOAD_IMAGE_FAILURE';


export const GET_RULE_REQUESTED = 'compaign/GET_RULE_REQUESTED';
export const GET_RULE_SUCCESS = 'compaign/GET_RULE_SUCCESS';
export const GET_RULE_FAILURE = 'compaign/GET_RULE_FAILURE';

export const SAVE_RULE_REQUESTED = 'compaign/SAVE_RULE_REQUESTED';
export const SAVE_RULE_SUCCESS = 'compaign/SAVE_RULE_SUCCESS';
export const SAVE_RULE_FAILURE = 'compaign/SAVE_RULE_FAILURE';

export const UPDATE_CAMPAIGN_REQUESTED = 'compaign/UPDATE_CAMPAIGN_REQUESTED';
export const UPDATE_CAMPAIGN_SUCCESS = 'compaign/UPDATE_CAMPAIGN_SUCCESS';
export const UPDATE_CAMPAIGN_FAILURE = 'compaign/UPDATE_CAMPAIGN_FAILURE';

export const GET_SENDERLIST_REQUESTED = 'compaign/GET_SENDERLIST_REQUESTED';
export const GET_SENDERLIST_SUCCESS = 'compaign/GET_SENDERLIST_SUCCESS';
export const GET_SENDERLIST_FAILURE = 'compaign/GET_SENDERLIST_FAILURE';

export const GET_USER_SENDERLIST_REQUESTED = 'compaign/GET_USER_SENDERLIST_REQUESTED';
export const GET_USER_SENDERLIST_SUCCESS = 'compaign/GET_USER_SENDERLIST_SUCCESS';

export const GET_CAMPAIGN_REQUESTED = 'compaign/GET_CAMPAIGN_REQUESTED';
export const GET_CAMPAIGN_SUCCESS = 'compaign/GET_CAMPAIGN_SUCCESS';
export const GET_CAMPAIGN_FAILURE = 'compaign/GET_CAMPAIGN_FAILURE';

export const CHECK_CAMPAIGN_REQUESTED = 'compaign/CHECK_CAMPAIGN_REQUESTED';
export const CHECK_CAMPAIGN_SUCCESS = 'compaign/CHECK_CAMPAIGN_SUCCESS';
export const CHECK_CAMPAIGN_FAILURE = 'compaign/CHECK_CAMPAIGN_FAILURE';

export const GET_SEGEMENT_REQUESTED = 'compaign/GET_SEGEMENT_REQUESTED';
export const GET_SEGEMENT_SUCCESS = 'compaign/GET_SEGEMENT_SUCCESS';
export const GET_SEGEMENT_FAILURE = 'compaign/GET_SEGEMENT_FAILURE';

export const UPDATE_COMPAIGN_LOADER = 'compaign/UPDATE_COMPAIGN_LOADER';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  statusDropDown: [
    {
      title: CONSTANTS.ACTIVE,
      value: 'active',
    },
    {
      title: CONSTANTS.INACTIVE,
      value: 'inactive',
    }
  ],
  rules: [
  ],
  userSegment: [],
  campaignData: {
    id: '',
    name: '',
    nameError: '',
    campaignStatus: 'active',
    senderList: '',
    msg: '',
    ruleId: '',
    file: '',
    fileName: '',
    scheduleTime: 0,
    subject: '',
    segmentId: '',
    displayLeaderboard: 'None',
    leadeboardSelected: false,
    ruleEntity: [
    ],
  },
  tableData: {},
  initialData: {
    itemsPerPage: 5,
    pageNo: 0,
    title: '',
    sortBy: 'updatedTS',
    orderBy: 'desc',
    ruleId: '',
    headers: [
      {
        title: CONSTANTS.CAMPAIGN_NAME,
        slug: 'campaign_name',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: '',
        width: 140
      },
      {
        title: CONSTANTS.STATUS_FILTER,
        slug: 'status',
        isSortable: true,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: 'desc',
        width: 50,
      },
      {
        title: CONSTANTS.START_DATE,
        slug: 'start_date',
        isSortable: true,
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: 'desc',
        width: 140,
      },
      {
        title: CONSTANTS.START_TIME,
        slug: 'start_time',
        isSortable: true,
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: 'desc',
        width: 130,
      },
      {
        title: CONSTANTS.TARGETED_USERS,
        slug: 'targeted_user',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: false,
        sortByOrder: 'desc',
        width: 150,
      },
      {
        title: CONSTANTS.CREATEDBY,
        slug: 'createdby',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: '',
        width: 90,
      },
      {
        title: 'Actions',
        slug: 'actions',
        isSortable: false,
        isVisible: false,
        tooltip: true,
        sortByOrder: '',
        width: 180,
      }
    ]
  }
};

const formatList = (arr) => {
  return arr.map(obj => ({ title: obj.name, value: obj.id }));
};


export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_FORM: {
      return {
        ...initialState
      };
    }
    case UPDATE_COMPAIGN_FIELD: {
      const campaignData = state.campaignData ? { ...state.campaignData, ...action.payload } : { ...action.payload };
      return { ...state, campaignData };
    }
    case GET_RULE_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_RULE_SUCCESS: {
      const ruleEntity = action.result.ruleEntity;
      return {
        ...state,
        // loading: false,
        rules: sort(formatList(action.result.ruleEntity)),
        campaignData: {
          ...state.campaignData,
          ruleEntity
        }
      };
    }
    case GET_RULE_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    case GET_CAMPAIGN_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_CAMPAIGN_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    case CHECK_CAMPAIGN_REQUESTED: {
      return {
        ...state,
        loading: false,
      };
    }
    case CHECK_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CHECK_CAMPAIGN_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    case SAVE_RULE_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case SAVE_RULE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case SAVE_RULE_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    case GET_SENDERLIST_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_SENDERLIST_SUCCESS: {
      let senderList = state.campaignData.senderList;
      if (senderList !== '') {
        senderList = senderList.concat(',').concat(action.result.body.userData);
      } else {
        senderList = action.result.body.userData;
      }
      return {
        ...state,
        loading: false,
        campaignData: {
          ...state.campaignData,
          senderError: '',
          senderList
        }
      };
    }
    case GET_USER_SENDERLIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        campaignData: {
          ...state.campaignData,
          senderList: []
        }
      };
    }
    case GET_USER_SENDERLIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        campaignData: {
          ...state.campaignData,
          senderError: '',
          senderList: action.result.campaignList.join(', ')
        }
      };
    }
    case GET_SENDERLIST_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    case UPDATE_CAMPAIGN_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_CAMPAIGN_SUCCESS: {
      const { Campaign } = action.result;
      const {
        campaignStatus, id, name, scheduleTime, senderList, typeId, ruleId, msg, subject, userSegmentId
      } = Campaign;
      return {
        ...state,
        // loading: false,
        campaignData: {
          id,
          campaignStatus,
          name,
          scheduleTime,
          senderList: senderList.join(', '),
          typeId,
          ruleId,
          msg,
          subject,
          segmentId: userSegmentId
        }
      };
    }
    case UPDATE_CAMPAIGN_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    case UPDATE_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case UPLOAD_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPLOAD_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_SEGEMENT_REQUESTED: {
      return {
        ...state,
      };
    }
    case GET_SEGEMENT_SUCCESS: {
      return {
        ...state,
        userSegment: action.result.segmentList
      };
    }
    case GET_SEGEMENT_FAILURE: {
      return {
        ...state
      };
    }
    case UPDATE_COMPAIGN_LOADER: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
};


// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const updateCampaign = (payload) => {
  return {
    type: UPDATE_COMPAIGN_FIELD,
    payload,
  };
};

export const updateLoader = (payload) => {
  return {
    type: UPDATE_COMPAIGN_LOADER,
    payload,
  };
};

export const clearForm = () => {
  return {
    type: CLEAR_FORM,
  };
};

export const getRuleList = () => {
  return {
    types: [GET_RULE_REQUESTED, GET_RULE_SUCCESS, GET_RULE_FAILURE],
    promise: client => client.get('getRuleList'),
  };
};

export const saveCampaign = (data) => {
  data = {
    ...data,
    apiKey: window.localStorage.getItem('apiKey')
  };
  return {
    types: [SAVE_RULE_REQUESTED, SAVE_RULE_SUCCESS, SAVE_RULE_FAILURE],
    promise: client => client.post('saveCampaign', {
      data
    }),
  };
};

export const getSenderList = (file, type) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [GET_SENDERLIST_REQUESTED, GET_SENDERLIST_SUCCESS, GET_SENDERLIST_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.GAMIFICATION_CAMPAIGN_HOST_NAME}/campaign/save/excel`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('file', file)
      .field('type', type)
  };
};

export const getCampaignList = (data, type) => {
  let reqParams = '';
  if (data.searchKey && data.searchKey !== '') {
    reqParams = `${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&sortType=${data.orderBy}&type=${type}`;
  } else {
    reqParams = `${data.itemsPerPage}/${data.pageNo}?searchKey=&sortBy=${data.sortBy}&sortType=${data.orderBy}&type=${type}`;
  }
  return {
    types: [GET_CAMPAIGN_REQUESTED, GET_CAMPAIGN_SUCCESS, GET_CAMPAIGN_FAILURE],
    promise: client => client.get(`getCampaignList/${reqParams}`)
  };
};

export const getCampaign = (id) => {
  return {
    types: [UPDATE_CAMPAIGN_REQUESTED, UPDATE_CAMPAIGN_SUCCESS, UPDATE_CAMPAIGN_FAILURE],
    promise: client => client.get(`getCampaign/${id}`),
  };
};


export const checkCampaignDublicate = (name, type, id = '') => {
  return {
    types: [CHECK_CAMPAIGN_REQUESTED, CHECK_CAMPAIGN_SUCCESS, CHECK_CAMPAIGN_FAILURE],
    promise: client => client.get(`checkCampaignDublicate?name=${name}&type=${type}&id=${id}`),
  };
};

export const uploadimage = (file, folderName) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/gamification/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
      .field('folderName', folderName)
  };
};

export const getSegment = () => {
  return {
    types: [GET_SEGEMENT_REQUESTED, GET_SEGEMENT_SUCCESS, GET_SEGEMENT_FAILURE],
    promise: client => client.get('getSegmentList'),
  };
};

export const getSenderListByUser = (userSegmentId, type) => {
  return {
    types: [GET_USER_SENDERLIST_REQUESTED, GET_USER_SENDERLIST_SUCCESS, GET_SENDERLIST_FAILURE],
    promise: client => client.get(`getSenderListByUserSegment?segmentId=${userSegmentId}&type=${type}`),
  };
};

export const deleteCampaign = (id) => {
  return {
    types: [CHECK_CAMPAIGN_REQUESTED, CHECK_CAMPAIGN_SUCCESS, CHECK_CAMPAIGN_FAILURE],
    promise: client => client.get(`deleteCampaign?id=${id}`),
  };
};

export const copyCampaign = (id) => {
  return {
    types: [CHECK_CAMPAIGN_REQUESTED, CHECK_CAMPAIGN_SUCCESS, CHECK_CAMPAIGN_FAILURE],
    promise: client => client.get(`copyCampaign?id=${id}`),
  };
};
