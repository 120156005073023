import { CONSTANTS } from '@constants';
import { sort } from '@helpers/Utility';

export const REQUESTED = 'REQUESTED';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export const GET_QUEST_REPORT_REQUESTED = 'get/GET_QUEST_REPORT_REQUESTED';
export const GET_QUEST_REPORT_SUCCESS = 'get/GET_QUEST_REPORT_SUCCESS';
export const GET_QUEST_REPORT_FAILURE = 'get/GET_QUEST_REPORT_FAILURE';

export const UPDATE_CUSTOM_FIELD = 'questReport/UPDATE_CUSTOM_FIELD';
export const UPDATE_CUSTOM_PAGE = 'questReport/UPDATE_CUSTOM_PAGE';

export const UPDATE_FIELD = 'questReport/UPDATE_FIELD';
export const UPDATE_PAGE = 'questReport/UPDATE_PAGE';
export const CLEAR_STATE = 'questReport/CLEAR_STATE';

export const GET_SENDERLIST_REQUESTED = 'offerreport/GET_SENDERLIST_REQUESTED';
export const GET_SENDERLIST_SUCCESS = 'offerreport/GET_SENDERLIST_SUCCESS';
export const GET_SENDERLIST_FAILURE = 'offerreport/GET_SENDERLIST_FAILURE';

export const GET_RULE_REQUESTED = 'questreports/GET_RULE_REQUESTED';
export const GET_RULE_SUCCESS = 'questreports/GET_RULE_SUCCESS';
export const GET_RULE_FAILURE = 'quesreports/GET_RULE_FAILURE';

export const GET_QUEST_ROWS_REQUESTED = 'questreports/GET_QUEST_ROWS_REQUESTED';
export const GET_QUEST_ROWS_SUCCESS = 'questreports/GET_QUEST_ROWS_SUCCESS';
export const GET_QUEST_ROWS_FAILURE = 'questreports/GET_QUEST_ROWS_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  tableData: {},
  initialData: {
    selectedReportIds: [],
    pageNo: 0,
    sortBy: 'updatedTS',
    orderBy: 'desc',
    activePage: 1,
    searchKey: '',
    searchCampaign: '',
    sendReport: '',
    selectedMethod: '',
    selectedPeriod: '',
    selectedPeriodFrom: '',
    selectedPeriodEnd: '',
    selectedPeriodYear: null,
    selectedPeriodMonth: null,
    selectedPeriodWeek: null,
    offerList: {
      headers: [
        {
          title: CONSTANTS.CAMPAIGN_NAME,
          slug: 'offer_name',
          isVisible: true,
          width: 150,
        },
        {
          title: CONSTANTS.TOTAL_OFFER,
          slug: 'totalOffer',
          center: true,
          isVisible: true,
          width: 110,
        },
        {
          title: CONSTANTS.TOTAL_OFFER_WON,
          slug: 'totalOfferWon',
          center: true,
          isVisible: true,
          width: 190,
        },
        {
          title: CONSTANTS.TOTAL_OFFER_REDEEM,
          slug: 'totalOfferRedeem',
          center: true,
          isVisible: true,
          width: 200,
        },
        {
          title: 'Actions',
          slug: 'actions',
          isVisible: false,
          width: 20,
        }
      ],
    },
    headers: [],
    rulesList: []
  }
};

const formatList = (arr) => {
  return arr.map(obj => ({ title: obj.name, value: obj.id }));
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_QUEST_REPORT_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_QUEST_REPORT_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_QUEST_REPORT_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case UPDATE_CUSTOM_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_CUSTOM_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case GET_RULE_SUCCESS: {
      const ruleEntity = action.result.ruleEntity;
      return {
        ...state,
        // loading: false,
        rulesList: sort(formatList(action.result.ruleEntity)),
        campaignData: {
          ...state.campaignData,
          ruleEntity
        }
      };
    }
    default:
      return state;
  }
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const updatecustomFields = (payload) => {
  return {
    type: UPDATE_CUSTOM_FIELD,
    payload,
  };
};

export const updateCustomPage = (payload) => {
  return {
    type: UPDATE_CUSTOM_PAGE,
    payload,
  };
};

// export const getCampaignsWithLeaderboards = () => {
//   return {
//     types: [GET_QUEST_REPORT_REQUESTED, GET_QUEST_REPORT_SUCCESS, GET_QUEST_REPORT_FAILURE],
//     promise: client => client.get('getCampaignsWithLeaderboard')
//   };
// };

// export const getRulesList = () => {
//   return {
//     types: [GET_RULE_REQUESTED, GET_RULE_SUCCESS, GET_RULE_FAILURE],
//     promise: client => client.get('getRuleList'),
//   };
// };

export const getRuleNames = (data) => {
  return {
    types: [GET_RULE_REQUESTED, GET_RULE_SUCCESS, GET_RULE_FAILURE],
    promise: client => client.get(`getRuleNames?isViewDeleted=${data !== undefined ? data : false}&ruleType=${'15'}`),
  };
};

export const getQuestReportRows = (data) => {
  return {
    types: [GET_QUEST_ROWS_REQUESTED, GET_QUEST_ROWS_SUCCESS, GET_QUEST_ROWS_FAILURE],
    promise: client => client.get(`getQuestRows/${data.itemsPerPage}/${data.pageNo}?sortBy=${data.sortBy}&orderBy=${data.orderBy}&selectedFromDate=${data.selectedPeriodFrom}&selectedToDate=${data.selectedPeriodEnd}&ruleName=${data.ruleName}&ruleId=${data.ruleId}`)
  };
};

export const downloadLeaderboardReportExcel = (data) => { // API LEADERBOARD_USER_TRANSACTION_DOWNLOAD
  return {
    types: [REQUESTED, SUCCESS, FAILURE],
    // promise: client => client.get(`downloadLeaderboardReport?leaderboardReportIdList=${data}&ruleType=${'15'}`)
    promise: client => client.get(`downloadActivityReport?selectedPeriodFrom=${''}&selectedPeriodEnd=${''}&ruleIdList=${data}&ruleType=${'15'}`)
  };
};
