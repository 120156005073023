export default {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  LOGIN: 'Login',
  FORGOT_PASSWORD: 'Forgot Password',
  USERNAME: 'Username',
  PASSWORD: 'Password',
  REGISTER: 'Register',
  NO_ACCOUNT: 'No Account? ',
  USERNAME_VALIDATION: 'Username is required',
  PASS_VALIDATION: 'Password is required',
  USERAGENT: 'Browser',
  REGISTRATION: 'Registration code will be sent via SMS. You will be redirected  to registration page to reset password.',
  SEND: 'Send',
  BACK_TO: 'Back to',
  RESET_PASSWORDS: 'Reset Password',
  CODE: 'Code',
  CONFIRM_PASSWORD: 'Confirm Password',
  ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
  DASHBOARD: 'Dashboard',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  UPLOAD_YOUR_ICON: 'Upload your Icon',
  ADD: 'add',
  MINUS: 'minus',
  COINS: 1,
  VOUCHER: 2,
  CUSTOM: 3,
  POINT: 5,
  SCRATCH_CARD_TYPE: 3,
  SPIN_WHEEL_TYPE: 2,
  TREASURE_CHEST_TYPE: 4,
  SLOT_MACHINE_TYPE: 5,
  ROLL_DICE_TYPE: 8,
  SCRATCH_CARD_FOLDER: 'scratchCard/',
  SPIN_WHEEL_FOLDER: 'spinTheWheel/',
  TREASURE_CHEST_FOLDER: 'treasureChest/',
  RULE_FOLDER: 'rule/',
  SLOT_MACHINE_FOLDER: 'slotMachine/',
  QUIZ_FOLDER: 'quiz/',
  QUIZ_TYPE: 6,
  REFERRAL_TYPE: 12,
  OPINION_TYPE: 7,
  LEADERBOARD_TYPE: 17,
  OPINION_FOLDER: 'opinion/',
  GAMES_FOLDER: 'games/',
  SAMPLE_DOWNLOAD: 'https://storage.googleapis.com/ec2-us-east-1-1b-dev-images-gamification/usersegment/User%20Segment%20Sample.xlsx',
  MESSAGE: 'message',
  EMAIL: 'email',
  WHATAPP: 'whatsapp',
  BADGE_FOLDER: 'badge/',
  TREASURE_THEME_BACKGROUND: 'https://www.googleapis.com/download/storage/v1/b/ec2-us-east-1-1b-dev-images-gamification/o/treasureChest%2F6320_treasure-forest-background.png?alt=media',
  TREASURE_THEME_FOREGROUND: 'https://www.googleapis.com/download/storage/v1/b/ec2-us-east-1-1b-dev-images-gamification/o/treasureChest%2F2247_treasure-forest-foreground.png?alt=media',
  GREATER_OR_EQUAL: '>= (greater or equal)',
  LESSER_OR_EQUAL: '<= (less or equal)',
  GREATER: '> (greater)',
  NOT_EQUAL: '!= (not equal)',
  EQUAL: '== (equal)',
  ARIAL_SANSSERIF: 'Arial, sans-serif',
  URL: 'https://www.googleapis.com/download/storage/v1/b/ec2-us-east-1-1b-dev-images-gamification/o/slotMachine%2Fupload%2F6181_green-diamond.png?alt=media',
  COL_4_MT_20: 'col-4 mt-20',
  INVALID_FILE: 'invalid file',
  LEADERBOARD_IMG_SRC: 'https://www.googleapis.com/download/storage/v1/b/ec2-us-east-1-1b-dev-images-gamification/o/leaderBoard%2F3470_user.png?alt=media',
  LEADERBOARD_IMG_RANK_SHADOW: 'https://www.googleapis.com/download/storage/v1/b/ec2-us-east-1-1b-dev-images-gamification/o/leaderBoard%2F8923_rank-shadow.png?alt=media',
  SOLID_3CA6C1: '1px solid #3CA6C1',
  TEXT_ALIGN: '; text-align:',
  FONT_FAMILY: '; font-family: ',
  FONT_WEIGHT: 'font-weight: ',
  FONT_WEIGHT_1: 'Font Weight',
  FONT_SIZE: '; font-size:',
  MARGIN_BOTTOM: '<p style="margin-bottom: 6px; word-break: break-all; color:',
  DISPLAY: '<div style="display: grid;align-items: center;">',
  COL_AUTO: 'col-auto p-0',
  DIV_SUCCESS_MSG: '#div_sucess_msg',
  DIV_SUCCESS: '#div_sucess',
  ENTER_TEXT: 'Enter text',
  BOTTOM_RIGHT: 'bottom-right',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_CENTER: 'bottom-center',
  FA_CROSSHAIRS_ML_10: 'fa fa-crosshairs form-control-feedback ml-10',
  NO_FILE_SELECTED: 'No File Selected',
  COL_12_P_0: 'col-12 p-0',
  ALL_CENTER: 'all-center',
  CURSOR_POINTER: 'cursor-pointer',
  UNITED_STATES: 'United States',
  IMAGE_EXCEEDS_MAX_LENGTH: 'image exceeds max length',
  TEMPLATE_ALREADY_EXITS: 'Template already exists',
  COL_5_PR_10: 'col-5 pr-10',
  PLAY_WIN_EXCITING_PRIZES: 'Play and Win Exciting Prizes',
  TEMPLATES: '/templates/',
  ROW_ALIGN_CENTER: 'row m-0 align-center',
  IMAGE_JPEG: 'image/jpeg',
  NUMBER: '1,2,3,4,5,6,7',
  CENTER_TEXT: 'center-text',
  DEC_2021: '12 Dec, 2021',
  DD_MM_YYYY: '(DD/MM/YYYY)',
  DD_MM_YYYY_HH_mm_SS: 'DD-MM-YYYY HH:mm:ss',
  DD_MM_YYYY_HH_mm_SS_1: 'DD/MM/YYYY HH:mm:ss',
  DD_MM_YYYY_1: 'DD/MM/YYYY',
  TIME_ERROR_MGS: 'start date should not be greater or equal than end date',
  END_DATE_ERROR_MGS: 'End Date should not be less than start Date',
  END_DATE_TIME_ERROR_MGS: 'Select end date and time greater then current time',
  START_DATE_TIME_ERROR_MGS: 'Select Start date and time greater then or equal current time',
  END_DATETIME_ERROR_MGS: 'End Date/Time should not be less than start Date/Time',
  INVALID_IMAGE_FORMAT: 'Invalid image format',

  // Webhook Configuration
  SAVED_SUCCESSFULLY: 'Saved successfully',
  FAILED_TO_SAVE: 'Failed to save',
  INVALID_URL: 'Invalid url',
  CONFIGURE_THE_WEBHOOK: 'Configure the Webhook',
  WEBHOOK_NAME: 'Webhook Name',
  NAME_OF_WEBHOOK: 'Name of Webhook',
  WEBHOOK_URL: 'Webhook URL',
  WEBHOOK_REQUEST: 'Webhook Request',
  URL_PLACEHOLDER: 'http://www.abc.com/home',
  CUSTOM_PLACEHOLDERS_NEEDED: 'Custom Placeholders Needed',
  AUTHENTICATION_URL_NEEDED: 'Authentication URL Needed',
  AUTHENTICATION_URL: 'Authentication URL',
  AUTHENTICATION_REQUEST_TYPE: 'Authentication Request Type',
  AUTHENTICATION_REQUEST_PARAMS: 'Authentication Request Params',
  AUTHENTICATION_REQUEST_HEADERS: 'Authentication Request Headers',
  AUTHENTICATION_REQUEST_BODY_NEEDED: 'Authentication Request Body Needed',
  AUTHENTICATION_REQUEST_BODY_TYPE: 'Authentication Request Body Type',
  AUTHENTICATION_REQUEST_BODY: 'Authentication Request Body',
  AUTHENTICATION_RESPONSE_TYPE: 'Authentication Response Type',
  AUTHENTICATION_RESPONSE_TOKEN_KEY: 'Authentication Response Token Key',
  WEBHOOK_REQUEST_TYPE: 'Webhook Request Type',
  WEBHOOK_REQUEST_PARAMS: 'Webhook Request Params',
  WEBHOOK_REQUEST_HEADERS: 'Webhook Request Headers',
  WEBHOOK_REQUEST_BODY_NEEDED: 'Webhook Request Body Needed',
  WEBHOOK_REQUEST_BODY_TYPE: 'Webhook Request Body Type',
  WEBHOOK_REQUEST_BODY: 'Webhook Request Body',
  WEBHOOK_RESPONSE_TYPE: 'Webhook Response Type',
  WEBHOOK_SUCCESS_CONDITIONS: 'Webhook Response Success Conditions',
  TRANSACTION_ID_KEY: 'Transaction Id Key',
  TOKEN_PLACEHOLDER: 'Use #token as a placeholder to use the token in webhook request',
  TEST: 'Test',
  WEBHOOK_TEMPLATE: 'Webhook Template',

  // Webhook
  NO_WEBHOOK_LIST_CREATED: 'No Webhook List created yet',
  WEBHOOK_CREATED_SUCCESSFULLY: 'webhook Created Successfully',

  // UserSegment
  SOMETHING_WENT_WRONG: 'Something went wrong',
  USER_SEGMENT_SAVED_SUCCESSFULLY: 'Usersegment saved successfully',
  INVALID_FILE_FORMAT: 'Invalid file format',
  SELECT_EVENT: 'Select event',
  SELECT_PROPERTY: 'Select property',
  SELECT_OPERATOR: 'Select operator',
  IS_EMPTY: 'is_empty',
  IS_NULL: 'is_null',
  BETWEEN: 'between',
  VALUE_REQUIRED: 'value required',
  SELECT_FILE: 'Select file',
  SEGMENTNAME_REQUIRED: 'SegmentName Required',
  FROM: 'from',
  TO: 'to',
  GIVE_A_NAME_TO_YOUR_SEGMENT: 'Give a name to your segment',
  BIG_WIN: 'Big Win',
  SAVE_BUTTON: 'save',
  USER_SEGMENTATION_BASED_ON_THE_EVENTS: 'User segmentation Based on the events',
  USER_DOES: 'User Does',
  SELECT_EVENTS: 'Select Events',
  FROM_LAST_DAYS: 'From last days',
  EVENT_PROPERTY: 'Event Property',
  NONE: 'None',
  AND: 'AND',
  OR: 'OR',
  UPLOAD_SEGMENT: 'Upload Segment',
  FILE_TYPES_SUPPORTED: 'File types suported .xls / .csv',
  SAMPLE_DOWNLOAD_TITLE: 'Sample Download',
  TOTAL_USERS: 'Total Users:',
  DEMOGRAPHICS: 'Demographics',
  MALE: 'Male:',
  FEMALE: 'Female:',
  LOAD_DETAILS: 'Load Details',
  SAVE_AS: 'Save As',
  VIEW: 'View',
  SELECT_DAYS: 'Select Days',
  SELECT_PLACEHOLDER: 'Select',

  // BadgeContainer
  COPIED_SUCCESSFUL: 'Copied successfully',
  FAILED_TO_COPY: 'Failed to copy',
  NO_BADGES_CREATED_YET: 'No Badges created yet',
  COPY: 'copy',
  DELETE: 'delete',
  EDIT: 'edit',
  BADGES_LIBRARY: 'badges_library',
  TITLE: 'Title',
  COLUMN_DATE: 'date',
  UPDATED_TS: 'updatedTS',
  COLUMN_TYPE: 'type',
  ASC: 'asc',
  DESC: 'desc',
  ACTION: 'action',
  TEXT: 'Text',
  IMAGE: 'image',
  TOOLTIP_DUPLICATE: 'Duplicate',
  TOOLTIP_EDIT: 'Edit',
  TOOLTIP_DELETE: 'Delete',
  BADGES: 'Badges',
  LEADERBOARD: 'Leaderboard',
  BADGES_LIBRARY_LINK: '/badgelibrary',
  BADGESLIBRARY: 'badgeslibrary',
  ACTIVITY: 'Activity',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  BUTTON_OK: 'ok',
  GAME_MECHANICS: 'Game Mechanics',
  GAME_MECHANICS_LINK: '/gamemechanics/mygamemechanics',
  CONFIGURE_BADGES: 'Configure Badges',
  TITLE_MY_BADGES: 'My Badges',
  // BADGECONFIGURATION
  MY_BADGES: 'my_badges',
  NOW: 'now',
  ACTIVE_TAB_SCHEDULING: 'scheduling',
  BUTTON: 'button',
  ACTIVE_TAB_CUSTOMIZE: 'customize',

  // BadgeLibraryContainer

  DELETED_SUCCESSFULLY: 'Deleted successfully',
  FAILED_TO_DELETE: 'Failed to delete',
  BADGE_CONFIGURATION: 'badge_configuration',
  END_DATE: 'End date',
  DATE_TIME_END: 'endDateTime',
  COLUMN_START_DATE: 'start_date',
  COLUMN_START_DATE_TIME: 'startDateTime',
  COLUMN_STATUS: 'status',
  RULE_STATUS: 'ruleStatus',
  BADGES_CRITERIA: 'badge_criteria',
  BADGES_FACTOR: 'badgeFactor',
  COMPAIGN_ID: 'campaignId',
  MESSAGE_LINK: '/message',
  EMAIL_LINK: '/email',
  WHATSAPP_LINK: '/whatsapp',
  LINK: 'link',
  RULE: 'rule',
  COMPAIGNS: 'campaigns',
  BUTTON_CANCEL: 'cancel',
  RULE_ENGINE: 'Rule Engine',
  RULE_CONFIGURATION: 'Rule Configuration',
  MY_RULES: 'My Rules',

  // BADGES

  BADGE_FORMATTING: 'BADGE FORMATTING',
  CHOOSE_BADGE_FROM_LIBRARY: 'Choose Badge from Library',
  TEMPLATE_ALREADY_EXIST: 'Template already exists',
  TEMPLATE_IS_REQUIRED: 'Template is required',
  BADGE_NAME_IS_REQUIRED: 'Badge Name is required',
  TEMPLATE_SHPOULD_CONTAIN_ALTEAST: 'Template should contain atleast 3 characters',
  BADGE_NAME_SHOULD_CONTAIN_ATLEAST: 'Badge Name should contain atleast 3 characters',
  BADGE_DESC_SHOULD_CONTAIN_ATLEAST: 'Badge Description should contain atleast 3 characters',
  BADGE_CATEGORY_IS_REQUIRED: 'Badge Category is required',
  BADGE_CATEGORY_SHOULD_CONTAIN_ATLEAST: 'Badge Category should contain atleast 3 characters',
  RECEIVING_MESSAGE_IS_REQUIRES: 'Receiving Message is required',
  RECEIVING_MESSAGE_SHOULD_CONTAIN_ATLEAST: 'Receiving Message should contain atleast 3 characters',
  TEXT_ON_BADGE_IS_REQUIRED: 'Text On Badge is required',
  TEXT_ON_BADGE_SHOULD_CONTAIN_ATLEAST: 'Text On Badge should contain atleast 3 characters',
  BACKGROUND_IMAGE_IS_REQUIRED: 'Background Image is required',
  BADGE_IMAGE_IS_REQUIRED: 'Badge Image is required',
  END_DATE_LESS_START_DATE: 'End Date is should not be less than start Date',
  END_DATE_GREATER_CURRENT_TIME: 'Select end date and time greater then current time',
  IMAGE_PNG: 'image/png',
  IMAGE_JPG: 'image/jpg',
  DEMO_WRAPPER: 'demo-wrapper',
  DEMO_EDITOR: 'demo-editor',
  PRIVATE: 'Private',
  MODE_PRIVATE: 'private',
  PUBLIC: 'Public',
  MODE_PUBLIC: 'public',
  ALL: 'All',
  ALL_VALUE: 'all',
  CURRENT: 'Current',
  CURRENT_VALUE: 'current',
  VISIBILITY_SEQUENCE: 'Visibility Sequence',
  OLDEST: 'Oldest',
  OLDEST_VALUE: 'oldest',
  NEWEST: 'Newest',
  NEWEST_VALUE: 'newest',
  RANK_TEXT: 'Rank',
  RANK_VALUE: 'rank',
  OFFER_STATUS: 'offer_status',
  YES: 'Yes',
  YES_VALUE: 'yes',
  NO: 'No',
  NO_VALUE: 'no',
  BADGES_CATEGORY: 'Badge Category',
  CLOSE_POPUP: 'closePopup',
  FILE: 'file',
  MY_FILE: 'myfile',
  UNSELECT: 'Unselect',
  BADGE_TEMPLATE_NAME: 'Badge Template Name',

  // Badges Report
  NO_REPORTS_AVAILABLE: 'No Reports Available',
  TOTAL_USER_BADGES: 'totalUserBadges',
  BADGE_TYPE: 'badgeType',
  UNIQUE_USERS: 'uniqueUsers',
  BADGE_CRITERIA: 'badgeCriteria',
  TYPE_NUMBER: 'number',

  // CAMPAIGN
  CAMPAIGN_NAME_REQUIRED: 'Campaign name Required',
  CAMPAIGN_NAME_SHOULD_CONTAIN_ATLEAT: 'Campaign name should contain atleast 3 characters',
  CAMPAIGN_RULE_REQUIRED: 'Campaign Rule Required',
  SENDER_REQUIRED: 'Sender Required',
  MESSAGE_REQUIRED: 'Message Required',
  SUBJECT_REQUIRED: 'विषय आवश्यक',
  CAMPAIGN_COPY_CREATED_SUCCESSFULLY: 'Campaign copy created sucessfully',
  COMPAIGN_CONFIGURATION: 'Campaign Configuration',
  MY_CAMPAIGN: 'My Campaign',
  // CAMPAIN_REPORTS
  DOWNLOAD_SUCCESSFULLY: 'Downloaded successfully',
  FAILED_TO_DOWNLOAD: 'Failed to download',

  // ConfigureLeader
  LEADER_NAME_IS_REQUIRED: 'Leaderboard Name is required',
  LEADERBOARD_NAME_SHOULD_CONTAIN_ATLEAST: 'Leaderboard Name should contain atleast 3 characters',
  TITLE_IS_REQUIRED: 'Title is required',
  TITLE_SHOULD_CONTAIN_ATLEAST: 'Title should contain atleast 3 characters',
  PROFILE_PICTURE_IS_REQUIRED: 'Profile Picture is required',
  IMAGE_ECXXEDS_MAX_LENGTH: 'image exceeds max length',
  COINS_IS_REQUIRED: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Coins is required' : `${JSON.parse(window.localStorage.getItem('orgData')).currencyName} is required`,
  POINTS_IS_REQUIRED: 'Points is required',
  COINS_SHOULD_MAX_6_DIGITS: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Coins should be of max 6 digits' : `${JSON.parse(window.localStorage.getItem('orgData')).currencyName} should be of max 6 digits`,
  POINTS_SHOULD_MAX_6_DIGITS: 'Points should be of max 6 digits',
  COUNT_IS_REQUIRED: 'Count is required',
  COUNT_SHOULD_MAX_7_DIGITS: 'Count should be of max 7 digits',
  COUNT_SHOULD_MAX_12_DIGITS: 'Count should be of max 12 digits',
  VOUCHER_REQUIRED: 'Voucher is required',
  VOUCHER_NAME_MIN_3_MAX_50: 'Voucher name should be of min 3 and max 50 characters',
  AMOUNT_REQUIRED: 'Amount is required',
  AMOUNT_MAX_8_DIGITS: 'Amount should be of max 8 digits',
  PLEASE_SELECT_FILE: 'please select file',
  WEBHOOK_REQUIRED: 'Webhook is required',
  VALUE_CANNOT_BE_EMPTY: 'Value cannot be empty',
  OFFER_NAME_REQUIRED: 'Offer Name is required',
  OFFER_NAME_MIN_3_MAX_50: 'Offer name should be of min 3 and max 50 characters',
  SELECT_ENDDATE_TIME_GREATER_CURRENTTIME: 'Select end date and time greater then current time',
  ENDDATE_NOT_LESS_OR_EQUAL_STARTDATE: 'End Date is should not be less than or equal start Date',
  LEADERBOARD_NAME: 'Leaderboard Name',
  MEGA_WIN: 'Mega Win',
  SCRATCH_WIN_EX_PRIZES: 'Scratch & Win Exciting prizes',
  CUSTOMIZE_YOUR_LEADERBOARD: 'Customise your leaderboard',
  TEXTBOX_TITLE: 'Title',
  TIME_PERIOD: 'Time Period',
  INCLUDE_PROFILE_PICTURE: 'Include Profile Picture',
  POSITION_TITLE: 'Position',
  BACKGROUND_TITLE: 'BACKGROUND',
  COLOR: 'Color',
  IMAGE_TITLE: 'Image',
  SCORING_FORMAT: 'SCORING FORMAT',
  SCORING_ORDER: 'Scoring Order',
  HIGHEST: 'Highest',
  LOWEST: 'Lowest',
  NO_OF_PLAYERS_IN_ONE_PAGE: 'No. of Ranks in one page',
  OPTION_5: '5',
  OPTION_10: '10',
  OPTION_15: '15',
  LEADERBOARD_DESIGN: 'LEADERBOARD DESIGN',
  ROW_BACKGROUND_COLOR: 'Row Background Color',
  USER_SCORE_HIGHLIGHT: 'User Score Highlight',
  OUTLINE: 'Outline',
  FILL: 'Fill',
  HEADING_TEXT: 'Heading Text',
  BODY_TEXT: 'Body Text',
  TOP_3_PLAYERS: 'Top 3 Players',
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  CAMPAIGN: 'Campaign',
  // LeaderBoard
  CONFIGURE_LEADERBOARD: 'Configure Leaderboard',
  MY_LEADERBOARD: 'My Leaderboard',

  // Configure Offer
  INR: 'INR',
  USD: 'USD',
  SGD: 'SGD',
  PND: 'PND',
  STATIC: 'Static',
  DYNAMIC: 'Dynamic',

  // Confirm Password

  PASSWORD_SUCCESSFULLY_CHANGED: 'Password is successfully changed',

  // Daily Streak

  NO_RULES_CREATED_YET: 'No Rules created yet',
  NO_SEGEMENT_CREATED_YET: 'No Segment created yet',
  SEGEMENT_CREATED_SUCCESSFULLY: 'Segment Created Successfully',
  NEVER: 'NEVER',
  DAILY_STREAK: 'Daily Streak',
  STREAK_CONFIGURATION: 'Streak Configuration',
  MY_STREAKS: 'My Streaks',
  CUSTOMISE_YOUR_STREAKS: 'Customise your Streaks',

  // Daily Streak Reports
  DAILY_STREAK_REPORTS: 'Daily Streak Reports',

  // Data Management
  DATA_MANAGEMENT: 'Data Management',
  MY_SEGMENT: 'My Segment',

  // Demo Website
  YOU_WILL_BE_REDIRECTED_TO_OUR_DEMO_WEBSITE: 'You will be redirected to our demo website.',
  GAMIZE: 'Gamize',

  // Edit Profile

  NO_ACCOUNTS_CREATED_YET: 'No Accounts created yet',
  EMAIL_REQUIRED: 'Email is required',
  EMAIL_NOT_VALID: 'Email is not valid',
  NAME_REQUIRED: 'Name is required',
  PASSWORD_REQUIRED: 'Password is required',
  CONTAIN_8_CHARACTER_LETTER_LOWERCASE_UPPERCASE_SPECIALCHARACTER: ' must contain at least 8 characters including 1 number, 1 lowercase, 1 uppercase letter and 1 special character, White space is not allowed, Maximum 25 characters allowed',
  // ORGANAIZATION_NAME_REQUIRED: 'Organisation Name is required',
  NAME_SHOULDBE_MIN_3_MAX_50: 'Name should be of min 3 and max 50 characters',
  ENTERPRISE_NAME_REQUIRED: 'Enterprise name is required',
  ENTERPRISE_NAME_MIN_3_MAX_50: 'Enterprise name should be of min 3 and max 50 characters',
  PHONE_NUMBER_REQUIRED: 'Phone number is required',
  PHONE_NUMBER_NOT_VALID: 'Phone number is not valid',
  ENTERPRISE_ADDRESS_REQUIRED: 'Enterprise Address is required',
  ENTERPRISE_ADDRESS_MIN_3_MAX_50: 'Enterprise Address should be of min 3 and max 50 characters',
  STATE_REQUIRED: 'State is required',
  ACCOUNT_SID_REQUIRED: 'Account SID is required',
  ACCOUNT_SID_MIN_3_MAX_50: 'Account SID should be of min 3 and max 50 characters',
  MESSAGING_SERVICE_SID_REQUIRED: 'Messaging Service SID is required',
  MESSAGING_SERVICE_SID_MIN_3_MAX_50: 'Messaging Service SID should be of min 3 and max 50 characters',
  AUTH_TOKEN_REQUIRED: 'Auth Token is required',
  AUTH_TOKEN_SHOULD_MIN_3_MAX_50: 'Auth Token should be of min 3 and max 50 characters',
  SENDGRID_APIKEY_REQUIRED: 'SendGrid ApiKey is required',
  SENDGRID_APIKEY_SHOULD_MIN_3_MAX_100: 'Send Grid ApiKey should be of min 3 and max 100 characters',
  NAME: 'Name',
  E_MAIL_ADDRESS: 'E-mail Address',
  PHONE_NUMBER: 'Phone Number',
  COUNTRY: 'Country',
  ENTERPRISE_NAME: 'Enterprise Name',
  ENTERPRISE_SIZE: 'Enterprise Size',
  DEFAULT_TIMEZONE: 'Default timezone',
  ENTERPRISE_ADDRESS: 'Enterprise Address',
  STATE: 'State',
  API_KEY: 'API Key',
  BRAND_KIT: 'Brand Kit',
  ENTERPRISE_API_KEY: 'Enterprise API Key',
  COPIED: 'Copied!',
  BILLING: 'Billing',
  PAYMENT_DETAILS: 'Payment Details',
  TRAIL_ACCOUNT_DAYS_LEFT: 'Trail account days left :',
  PLAN_DETAILS: 'Plan Details :',
  PLAN_NAME: 'Plan Name :',
  PRICE: 'Price :',
  PAYBILL: 'payBill',
  PAY_BILL: 'Pay Bill',
  UPGRADEPLAN: 'upgradePlan',
  UPGRADE_PLAN: 'Upgrade Plan',
  FAILED_TO_DOWNLOAD_INVOICE: 'Failed to download invoice',
  USER_MANAGEMENT: 'User Management',
  ADD_USER: 'Add User',
  LIST: 'List',
  SEARCH: 'Search',
  ORGANISATION_NAME: 'Organisation Name',
  CLEAR: 'Clear',
  NOT_VALID: 'Not Valid',
  ACCOUNT_SID: 'Account SID',
  MESSAGING_SERVICE_SID: 'Messaging Service SID',
  MAIL_ID: 'Mail ID (for E-mails)',
  AUTH_TOKEN: 'Auth Token',
  PHONE_NUMBER_WHATSAPP: 'Phone Number (for WhatsApp)',
  SEND_GRID_API_KEY: 'Send Grid API key',
  INTEGRATION: 'Integration',
  ACCOUNT_BALANCE: 'Account Balance',
  ADD_FUNDS: 'Add Funds',
  CURRENT_BALANCE: 'Current Balance',
  TOTAL_AMOUNT_USED: 'Total Amount Used',
  SERVER_KEY: 'Server Key',
  ENTER_SERVER_KEY: 'Enter Server Key',
  SERVER_KEY_REQUIRED: 'Server Key is required',

  // Error Boundary
  SOMETHING_WENT_WRONG_ERROR: 'Something went wrong!!!',
  REFRESH_PAGE_CONTACT_ADMIN: 'refresh page/Contact Admin',

  // Game Mechanics
  COMPLETE_TASKS_TO_EARN_A_BADGE: 'Complete tasks to earn a badge.',
  COMPLETE_TO_WIN_TOP_PRIZES: 'Complete to win top prizes.',


  // GAMES

  NO_GAMES_CREATED_YET: 'No Games created yet',
  GAMES: 'Games',
  GAMES_LIBRARY: 'Games Library',
  LIBRARY: 'Library',
  MY_GAMES: 'My Games',

  // GAME TEMPLATE
  SUB_TITLE_CONTAIN_3_CHARACTER: 'Sub title should contain atleast 3 characters',
  WINNING_TEXT_REQUIRED: 'Winning text is Required',
  WINNING_TEXT_1: 'Winning Text',
  BACKGROUND_IMAGE_REQUIRED: 'Background image is Required',
  SIDE1_IMAGE_REQUIRED: 'Side1 image is Required',
  SIDE2_IMAGE_REQUIRED: 'Side2 image is Required',
  SIDE1_TEXT_REQUIRED: 'Side1 text is Required',
  SIDE2_TEXT_REQUIRED: 'Side2 text is Required',
  WINNING_TEXT_INCLUDE_SEGEMENTTEXT_OR_REWARD_OR_REWARDTYPE: 'Winning text should include #segmentText or #reward or #rewardType',
  WINNING_TEXT_SHOULD_MIN_3_MAX_50_CHARACTER: 'Winning text should be of min 3 and max 50 characters',
  LOSING_TEXT_1: 'Losing Text',
  LOSING_TEXT_IS_REQUIRED: 'Losing text is Required',
  LOSSING_TEXT_SHOULD_MIN_3_MAX_50: 'Lossing text should be of min 3 and max 50 characters',
  MAX_PLAYER_MORE_THAN_1: 'Maximum plays per user should be more than one',
  MAX_NO_PLAYERS_MORE_THAN_1: 'Maximum number of players should be more than one',
  NO_TEMPLATES_CREATED_YET: 'No Templates created yet',
  BEST: 'Best',
  TOTAL: 'Total',
  TEXT_TITLE: 'TITLE',
  CARD: 'Card',
  CARD_COLOR: 'Card Color',
  TITLE_CARD: 'TITLE CARD',
  SHOOT_WIN_AMAZING_REWARDS: 'Shoot & Win Amazing Rewards',
  SUBTITLE: 'Subtitle',
  ANSWER_WIN_EXCITING_PRICES: 'Answer & Win Exciting Prize',
  SCRATCH_WIN_EXCITING_PRICES: 'Scratch & Win Exciting Prize',
  SHORT_DESCRIPTION: 'Short Description',
  MAXIMUM_PLAYS_PER_USER: 'Maximum plays per user',
  ALLOW_PLAYERS_TO_PLAY_MORE_THAN_ONCE: 'Allow Players to play more than once',
  UNLIMITED: 'Unlimited',
  WINNING_MESSAGE: 'Winning Message',
  LOSING_MESSAGE: 'Losing Message',
  GAMIFY_ENGAGEMENT: 'Gamify Engagement',
  GAMES_URL: '/games',
  NAME_YOUR_TEMPLATE: 'Name your template',
  CUSTOMISE_YOUR: 'Customise your',
  GAME: 'Game',
  TEMPLATE_LIBRARY: 'Template Library',
  MY_TEMPLATES: 'My Templates',

  // LeaderBoard

  NO_LEADERBOARD_CREATED_YET: 'No Leaderboard created yet',
  LEADERBOARD_CREATED_SUCCESSFULLY: 'Leaderboard Created Successfully',

  // LOGIN
  EMAIL_NOT_VERIFIFED: 'Email is not verified',

  // MEGAWIN
  IMAGE_EXCCEDS_MAX_SIZE_UPLOAD_LESS_THAN_10MB: 'Image exceeds max size upload less than 10mb',
  FAILED_TO_UPDATE: 'Failed to update',
  SPIN_WIN: 'Spin & Win',
  QUADRANTS: 'QUADRANTS',
  TITLE_TEXT: 'Text',
  QUADRANTS_COUNT: 'Quadrants Count',
  BG_COLOR: 'Bg Color',
  QUADRANT_TEXT: 'Quadrant Text',
  Q: 'Q',
  SPIN_THE_BUTTON: 'SPIN NOW BUTTON',
  SPIN_SPEED_RANGE: 'Spin Speed Range',
  BUTTON_FORMATTING: 'Button Formatting',
  BUTTON_TEXT: 'Button text',
  WIDTH: 'Width',
  RADIUS: 'Radius',
  SHADOW: 'Shadow',
  DEFAULT_CARD: 'Default Card',
  DEFAULT_FIELD: 'Default Field',
  HOVEROVER: 'Hoverover',
  PREVIEW_WINDOW: 'PREVIEW WINDOW',
  SPIN_THE_WHEEL: 'Spin The Wheel',
  CUSTOMISE_YOUR_SPIN_WHEEL: 'Customise your spin wheel',
  UPLOAD_SPIN_WHEEL: 'Upload Spin Wheel',
  SHOW_TEXT_ON_QUADRANT: 'Show text on quadrant',

  // OFFERMANAGER

  NO_OFFER_CREATED_YET: 'No Offers created yet',
  OFFER_CREATED_SUCCESSFULLY: 'Offer Created Successfully',
  CONFIGURE_OFFERS: 'Configure Offers',

  // OPINIONPOLLS

  UPDATED_SUCCESSFULLY: 'Updated successfully',
  YOU_ARE_HEARD: 'You are heard!',
  QUESTIONS_TITLE: 'QUESTIONS',
  ADD_QUESTION: '+ Add Question',
  RATING_TYPE: 'Rating Type',
  SELECT_TYPE: 'Select Type',
  MEANING_OF_1: 'Meaning of 1',
  MEANING_OF_5: 'Meaning of 5',
  OPINION_POLL: 'Opinion Poll',
  CUSTOMISE_YOUR_OPINION_POLL: 'Customise your Opinion Poll',
  QUESTION_TITLE: 'Question',
  REFERRAL: 'Referral',


  // REPORTSGRAPGH
  CONTINUE: 'Hey! Let’s continue',
  TOTAL_USERS_INTERACTED: 'Total Users Interacted',
  REWARDS_GIVEN: 'Rewards Given',
  REWARDS_REDEEMED: 'Rewards Redeemed',
  USER_INTERACTED: 'User Interacted',
  OFFERS_GIVEN_VS_OFFER_REDEEMED: 'Offers Given Vs Offers Redeemed',
  TOTAL_UNIQUE_USERS: 'Unique Users',
  TOTAL_REPEAT_USERS: 'Repeat Users',
  ACTIVITY_DISPLAYED: 'Activity Display :',
  ACTIVITY_PLAYED: 'Activity Played :',
  UNIQUE_ACTIVITY_DISPLAY: 'Unique Activity Display :',
  UNIQUE_ACTIVITY_PLAYED: 'Unique Activity Played : ',
  MONTHLY_ACTIVE_USERS: 'MAU',
  ENGAGEMENT_PERCENTAGE: 'Engagement %',
  DASHBOARD_DETAILS: 'Dashboard Details',
  ENGAGEMENT: 'Engagement',
  MISSING_DATA: 'Dashboard data is not available for the selected date range',

  // SLOT MACHINE
  SAME_ELEMENTS_CANNOT_BE_SELECTED: 'Same Elements cannot be seletected. Select unique Element',
  SLOT_MACHINE: 'SLOT MACHINE',
  NO_OF_SPIN: 'No. of spin',
  NO_OF_ELEMENTS: 'No. of elements',
  CUSTOMISE_YOUR_SLOT_MACHINE: 'Customise your Slot Machine',

  // SUPER QUIZ
  EXCEEDS_MAX_SIZE: 'Exceeds max size',
  QUESTIO_ERROR: 'questionError',
  UPLOAD_IMAGE: 'Upload Image',
  UPLOAD_VIDEO_AUDIO: 'Upload Video/Audio',
  QUESTION_IS_REQUIRED: 'Question is required',
  QUESTION_SHOULD_CONTAIN_ATLEAST_3_CHARACTER: 'Question should contain atleast 3 characters',
  ATLEAST_TWO_OPTIONS_REQUIRED: 'Atleast two options required',
  CUSTOMIZE_YOUR_SUPER_QUIZ: 'Customise your Super Quiz',
  ANSWER_TO_WIN: 'Answer to Win',
  BANNER_IMAGE: 'Banner Image',
  IMAGE_POSITION: 'Image Position',
  IMAGE_POSITION_PLACEHOLDER: 'image_position',
  AUDIO_VIDEO_POSITION: 'Audio/Video Position',
  AUDIO_VIDEO_POSITION_PLACEHOLDER: 'audio_position',
  LOGO_IMAGE: 'Logo Image',
  CORRECT_ANSWER_TEXT: 'Correct Answer',
  SUPER_QUIZ: 'Super Quiz',
  OPTION: 'Option',
  YOU_CAN_ADD_PNG_JPEG_JPG: 'You can add png, jpeg, jpg',
  YOU_CAN_ADD_MP3_MP4: 'You can add mp3/mp4',
  YOU_CAN_ADD_MP3: 'You can add mp3',
  SAME_POINTS: 'Same score for all questions',
  SAME_QUESTION_ERROR: 'Score should be greator than 0',
  BRAND_LOGO_LIBRARY: 'Brand Logo  Library',
  // LIBRARY: 'Library',

  // TREASURE CHEST
  PLAY_AND_COMPETE: 'Play & Compete',
  ANSWER_AND_COMPETE: 'Answer & Compete',
  BACKGROUND_IMAGE: 'Background image',
  SELECT_YOUR_THEME: 'Select Your Theme',
  CUSTOMIZE_YOUR_TREASURE_CHEST: 'Customise your Treasure Chest',
  SEARCH_AND_WIN: 'Search & Win',
  TREASURE_CHEST: 'TREASURE CHEST',
  TREASURE_CHEST_SUBHEADING: 'Treasure Chest',
  TREASURE_CHEST_COUNT: 'Treasure Chest Count',

  // FORGOT PASSWORD
  INPUT_YOUR_NEW_DESIRED_PASSWORD: 'Input your new desired password in the input',
  FIELDS_BELOW_CREATE_NEW_PASSWORD: 'fields below to create a new password. We',
  STRONGLY_ADVISE_TO_STORE_SAFELY: 'strongly advise you to store it safely.',
  NO_WORRIES_FORGOT_YOUR_PASSWORD: ' No worries if you forgot your password',
  ENTER_EMAIL_TO_RESET_PASSWORD: 'Enter your email below to reset your password',
  CONTINUE_GAMIFYING: 'and continue gamifying',

  // SIGNUP
  WELCOME_GAMIZE: 'Welcome to Gamize!!!',
  REWARDING_WAY1: 'Let’s start your journey to engage your',
  REWARDING_WAY2: 'users in a Fun & Rewarding way',
  SIGN_UP: 'Sign Up to Get Started',
  NAME_COMPANY: 'Name of the Company',
  OFFICIAL_MAIL_ID: 'Official E-mail Id',
  COMPANY_SIZE: 'Company Size',
  INDUSTRY: 'Industry',
  START_GAMIFY_PRODUCT: 'We hope you are as excited as we are to start gamifying your product',
  ENJOY_DESIGNING_NEW_TEMPLATE: 'in a fun, easy, and quick way. Enjoy designing new templates and',
  ADDING_GAMES: 'adding games to attract and reward your users',
  START_DESIGINING_TEMPLATE: 'Why don’t you start by designing a template?',
  DESIGN_TEMPLATE: 'Design Template ',
  SET_BRANDKIT: 'Set Brand Kit',
  TIMEZONE: 'Timezone ',

  REFERRAL_EVENT: 'Referral Event',
  REWARD_ASSIGNMENT: 'Reward Assignment',
  EDIT_PROFILE: 'Edit Profile',
  TRANSCATION_HISTORY: 'Transaction History',
  TEMPLATE: '/templates',

  // OFFERS

  CUSTOMIZE_OFFER: 'Customise your Offers',
  OFFER_NAME: 'Offer Name',
  OFFER_DESCRIPTION: ' Offer Description',
  HOW_TO_REDEEM: ' How To Redeem',
  OFFER_TYPE: 'Offer Type',
  STATUS_FILTER: 'Status',
  VOUCHER_TYPE: 'Voucher Type',
  START_DATE_TIME: 'Start Date & Time',
  END_DATE_TIME: 'End Date & Time',
  OFFER_VALIDITY: 'Offer Validity',
  COIN_VALUE: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Coin Value' : `${JSON.parse(window.localStorage.getItem('orgData')).currencyName} Value`,
  NUMBER_OF_COINS: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'No.Of Coins' : `No.Of ${JSON.parse(window.localStorage.getItem('orgData')).currencyName}`,
  COIN_COUNT: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Coin Count' : `${JSON.parse(window.localStorage.getItem('orgData')).currencyName} Count`,
  ARE_YOU_SURE_YOU_WANT_TO_DELETE: 'Are you sure you want to delete?',
  OK_1: 'Ok',
  OFFER_MANAGER: 'Offer manager',
  TYPE: 'Type',
  OFFER_COUNT: 'Offer Count',
  DATE: 'Date',
  MY_OFFER: 'My Offers',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  COIN: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Coins' : JSON.parse(window.localStorage.getItem('orgData')).currencyName,
  VOUCHERS: 'Voucher',
  CUSTOMS: 'Custom',
  POINTS: 'Points',
  VOUCHER_CODE: 'Voucher Code',
  VOUCHER_CODE_NAME: 'Voucher Code Name',
  VOUCHER_VALUE: 'Voucher Value',
  VOUCHER_COUNT: 'Voucher Count',
  DO_YOU_NEED_WEBHOOK: 'Do you need Webhook?',
  WEBHOOK: 'Webhook',
  CONFIGURE_WEBHOOKS: 'Configure Webhooks',
  WEBHOOK_LIST: 'Webhooks List',
  SELECT: 'Select',
  KEY: 'Key',
  VALUE_1: 'Value',
  REMOVE: 'Remove',

  // CAMPAIGN
  CAMPAIGN_NAME: 'Campaign Name',
  RULES: 'Rules',
  SELECT_RULES: 'Select Rule',
  ENTER_CAMPAIGN_NAME: 'Enter Campaign Name',
  USER_SEGMENT: 'User Segment',
  SELECT_OPTION: 'Select Option',
  SENDER_LIST: 'Sender’s List',
  SUBJECT: 'Subject',
  SEND_NOW: 'Send Now',
  SCHEDULE_LATER: 'Schedule Later',
  MESSAGE_BOX: 'Message Box',
  TYPE_SOMETHING: 'Type something',
  START_DATE: 'Start Date',
  START_TIME: 'Start Time',
  TARGETED_USERS: 'Targeted Users',
  SMS_MESSAGE: 'SMS / Message',
  E_MAIL: 'E-mail',
  WHATSAPP: 'WhatsApp',
  // REPORTS

  REPORTS: 'Reports',
  RULE_NAME: 'Rule Name',
  SEGMENT_NAME: 'Segment Name',
  TOTAL_OFFER: 'Total Offers',
  OFFER_WON: 'Offer Won',
  OFFER_REDEEM: 'Offer Redeem',
  GAME_REPORTS: 'Game Reports',
  CAMPAIGN_REPORTS: 'Campaign Reports',
  REFERRAL_REPORTS: 'Referral Reports',
  CAMPAIGN_CHANNELS: 'Campaign Channels Report',
  EVENT_CHANNELS: 'Event Channels',
  EVENT_REPORTS: 'Custom Event Reports',
  REFERRAL_CHANNELS: 'Referral Reports',
  OPENED_MSGS: 'Opened Msgs',
  VIEWED_ACTIVITY: 'Viewed Activity',
  INTERACTED: 'Interacted',
  TOTAL_VISITS: 'Total Visits',
  TOTAL_PLAYS: 'Total Plays',
  NO_REPEAT_PLAYERS: 'No. of Repeat Players',
  TOTAL_OFFERS: 'Total Offers',
  MORE_DETAILS: 'More Details',
  DURATION: 'Duration',
  STATUS: 'Status',
  TOTAL_REFERS: 'Total Refers',
  TOTAL_SUCCESSFUL_REFERRALS: 'Total Successful Referrals',
  TOTAL_REWARDS_TO_REFERRER: 'Total Rewards to Referrer',
  TOTAL_REWARDS_TO_REFREE: 'Total Rewards to Referee',


  // Reports Graph
  TITLE_RULE: 'Campaign name',

  // Rule Configuration
  CUSTOMIZE_YOUR_RULES: 'Customise your Rules',

  // BADGE
  BADGES_NAME: 'Badge Name',
  CUSTOMIZE_YOUR_BADGES: 'Customise your Badges',
  SCHEDULING: 'Scheduling',
  TEMPLATE_NAME: 'Template Name',
  RECEIVING_MESSAGE: 'Receiving Message',
  CONGRATULATION: 'Congratulations',
  TEXT_ON_BADGES: 'Text on Badge',
  BEST_ACTOR: 'Best Actor',
  IMAGE_EXCEEDS_MAX_LENGHT: 'image exceeds max length',
  USE_BADGE_FOR_QUEST: 'Use Badge for Quest',
  VISIBILITY: 'Visibility',
  DATE_TIME: 'Date & Time',

  // Scratch Card
  SCRATCH_LAYER: 'Scratch layer',
  SCRATCH_CARD_LAYER: 'After scratch layer',
  SCRATCH_CARD_CONFIGURATION: 'Scratch Card Configuration',
  SCRATCH_TYPE: 'Scratch Type',
  CALL_TO_ACTION_BUTTON: 'Call to Action (CTA) Button',
  BUTTON_FORMATTING_TITLE: 'BUTTON FORMATTING',
  SCRATCH_CARD: 'Scratch card',
  CUSTOMISE_YOUR_SCRATCH_CARD: 'Customise Scratch Card',
  PLAY_TO_WIN: 'Play to Win',
  POPUP_FLAG: 'popupFlag',
  WINNING_TEXT: 'winningText',
  OFFER_NAMES: 'offerName',
  OFFER_ID: 'offerId',
  NO_OFFER: 'No Offer',
  ONCE_PER_CAMPAIGN_DURATION: 'oncePerCampaignDuration',
  SELECT_OPTIONS: 'Select Option',
  NUMBERS: 'number',
  WINNING_PERCENTAGE: 'Winning %',
  FIFTY: '50',
  WINNING_1: 'winning',
  ADD_SCRATCH_REWARD: 'Add scratch reward',
  REWARD_SCRATCH_REWARD: 'Remove scratch reward',
  SCORE_1: 'score',
  OFFER: 'Offers',
  COINS_VOUCHERS: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Coins/Vouchers' : `${JSON.parse(window.localStorage.getItem('orgData')).currencyName}/Vouchers`,
  SCORE_BASED_REWARD_ASSIGNMENT: 'Score Based Reward Assignment',
  ELEMENT1: 'elements1',
  ELEMENT2: 'elements2',
  ELEMENT3: 'elements3',
  SCHEDULINGS: 'scheduling',
  STRING: 'string',
  ENDDATE: 'End Date',
  DATES: 'date',
  TIME: 'time',
  ALL_SAVED_CHANGES_FOR_CURRENT: 'All saved changes for current Template will be lost.',
  DO_YOU_WANT_TO_CONTINUE: 'Do you want to continue?',
  BADGESFACTOR: 'Badge Factor',
  SELECT_PROPERTIES: 'Select Property',
  SELECT_OPERATORS: 'Select Operator',
  NO_OF_TIMES_OF_EVENT: 'No. of Times of Event',
  MINIMUM_NUMBER_OF_COINS: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Minimum number of Coins' : `Minimum number of ${JSON.parse(window.localStorage.getItem('orgData')).currencyName}`,
  MAXIMUM_NUMBER_OF_COINS: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Maximum number of Coins' : `Maximum number of ${JSON.parse(window.localStorage.getItem('orgData')).currencyName}/Vouchers`,
  SCORE_FORMAT: 'Score Format',
  SELECT_SCORE_FORMAT: 'Select Score Format',
  TARGET_SCORE: 'Target Score',
  NEXT: 'Next',
  ACTIVITIES: 'Activity',
  SCORE: 'Score',
  TARGET: 'Target',
  BADGE_CONTAINER: 'badge-container',
  LATER: 'Later',
  DAYS: 'days',
  RANGE: 'Date Range',
  START: 'start',
  PLEASE_SELECT_DAY: 'please select day',
  CUSTOMIZE: 'customize',
  GROUP: 'group',
  EVENT: 'Event',
  DAY: 'Day',
  EQUALS: 'equal',
  VALUES: 'value',
  ZEROS: '0000',
  STARTTIME: 'startTime',
  NOW_1: 'now',
  OFFER_START_DATE: 'offerStartDate',
  SAVE_1: 'save',
  ACTIVE_1: 'active',
  NEVER_1: 'never',
  MISSED_DAY_CONFIG: 'Missed Day Configuration',
  GRATIFICATION_POPUP: 'Gratification pop-up',

  // 404NOTFOUND

  FULL_IMAGE: 'fullimage',

  // CAMPAIGN
  ADD_MULTIPLE_PHONE_NUMBERS: 'Note : Add multiple phone numbers with country code  ex: +91XXXXXXXXXX',
  USE_PLACEHOLDER_RULE_LINK: 'Note : Use place holder #RULE_LINK to add the rule link',

  // confirm password
  NEW_PASSWORD: 'New Password',

  // DAILY STREAK CONFIGURATION
  TEMPLATE_1: 'Template',
  WINNING_MESSAGES: 'Winning Message',
  REDDEM_INSTRUCTION: 'Redeem Instructions',
  STREAK_NAME: 'Streak Name',
  SAME_TEMPLATE_FOR_ALL_DAYS: 'Same template for all days',
  NUMBER_OF_DAYS: 'Number of days',
  REPEAT_DAILY_STREAK: 'Repeat the Daily Streak',
  STREAK_START_DATE_TIME: 'Streak start date and time',
  STREAK_END_DATE_TIME: 'Streak end date and time',
  ONE: '1',
  TWO: '2',
  THREE: '3',
  FOUR: '4',
  FIVE: '5',
  SIX: '6',
  SEVEN: '7',
  EIGHT: '8',
  NINE: '9',
  TEN: '10',
  ELEVEN: '11',
  TWELVE: '12',
  THIRTEEN: '13',
  FOURTEEN: '14',
  FIFTEEN: '15',
  SIXTEEN: '16',
  SEVENTEEN: '17',
  EIGHTEEN: '18',
  NIGHTEEN: '19',
  TWENTY: '20',
  TWENTY_ONE: '21',
  TWENTY_TWO: '22',
  TWENTY_THREE: '23',
  TWENTY_FOUR: '24',
  TWETY_FIVE: '25',
  TWENTY_SIX: '26',
  TWENTY_SEVEN: '27',
  TWENTY_EIGHT: '28',
  TWENTY_NINE: '29',
  THIRTY: '30',
  THIRTY_ONE: '31',
  TITLE_BG: 'Tile Background',
  TITLE_CONTENT: 'Tile Content',
  TEMPLATE_TYPE: 'Template Type',
  CARD_CONFIGURATION: 'Card Configuration',
  CARD_BG_COLOUR: 'Card Background Color',
  CARD_CONTENT_COLOUR: 'Card Content Color',
  GRATIFICATION_CARD_COLOUR: 'Gratification Card Color',
  GRATIFICATION_CARD: 'Gratification Card',
  GRATIFICATION: 'Gratification',
  CONFIGURATION: 'Configuration',
  COMPLETED: 'Completed',
  SKIP: 'Skip',
  DEFAULT: 'Default',
  DESIGN_TITLE: 'Choose a Daily Streak design',
  DESIGN_1: 'Design 1',
  DESIGN_2: 'Design 2',
  DESIGN_1_TITLE: 'Streak appears on Template',
  DESIGN_2_TITLE: 'Streak shows upcoming rewards',
  STREAK_TEXT: 'Streak Text',

  // FORGOT PASSWORD

  EMAIL_ADDRESS: 'Email Address',
  INVALID_EMAIL_FORMAT: 'Invalid email format',

  // LOGIN
  HELLO_AGAIN: 'Hello Again! ',
  WELCOME_BACK: ' Welcome Back',
  REMEMBER_ME: 'Remember me',
  // NOTIFICATION
  NOTIFICATION: 'Notification',
  MARK_ALL_AS_READ: 'Mark all as read',
  COUNT: 'Count',

  // OFFER CUSTOM

  ADD_1: 'Add',

  // OFFER_VOUCHER
  CODE_NAME: 'Code name',

  // OPINION POLL PREVIEW
  HELLO: 'Hello',

  // PAYMENT POPUP
  PAYMENT_SUCCESS: 'Payment Success!',
  TRANSACTION_ID: ' Transaction Id',
  NVOICE_NUMBER: 'nvoice Number',
  AMOUNT: 'Amount',
  PAYMENT_TYPE: 'Payment Type',
  SUBSCRIPTION_PLAN: 'Subscription Plan',
  PAYEMENT_RECEIPT_IS_SENT: 'Payment receipt is sent to your registered',
  EMAIL_ADDRESS_1: 'email address',
  SORRY_PAYMENT_FAILED: 'Sorry Payment Failed!',
  YOUR_TRANSACTION_COULD: 'Your transaction could not be completed due',
  TO_SOME_REASONS: 'to some reasons',
  IF_MONEY_FROM_YOUR_ACCOUNT: 'If money from your account is deducted, it will be',
  REFUNDED_IN_NEXT_5_DAYS: 'refunded in the next 5-7 business days',
  TRY_AGAIN: 'Try Again',
  BACK_TO_HOME_PAGE: 'Back to Home page',
  CONTACT_SUPPORT: 'Contact Support',
  CLOSE: 'Close',
  UPGRADE_YOUR_PLAN: 'Upgrade your Plan',
  BUY: 'Buy',
  WANT_TO_CUSTOMIZE_YOUR_PLAN: 'Want to customize your plan?',
  CONTACT_SALES: ' Contact Sales',
  MAUS: 'MAU’s',
  EVENTS: 'Events',
  PRIMARY_CONRACT_NUMBER: 'Primary Contact Number',
  SUBMIT: 'Submit',
  MAUS_REQUIRED: 'MAU\'s required',
  EVENTS_REQUIRED: 'Events required',
  EMAIL_ID_REQUIRED: 'EmailId required',
  EMAILID_NOT_VALID: 'EmailId not valid',
  MOBILE_NUMBER_REQUIRED: 'MobileNumber Required',
  MOBILE_NUMBER_LENGTH_SHOULD_BE_10: 'mobileNumber length should be 10',

  // REGISTER FORM
  AT_LEAST_1_SPEACIAL_CASE: 'At least 1 special case, uppercase & number',
  EMAILID: 'Email Id ',
  I_AGREE_TO_TERMS: 'I agree to Terms & Conditions',

  // SPINWHEEL
  ENTER_SUB_TITLE: 'Enter Sub Title',
  ENTER_TITLE: 'Enter Title',
  CLICK_SPIN_BUTTON: 'Click the Spin button',
  YOU_HAVE_WON_COINS: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'You have Won #{result} Coins' : `You have Won #{result} ${JSON.parse(window.localStorage.getItem('orgData')).currencyName}/Vouchers`,

  // SPIN THE WHEEL
  SPIN_THE_WHEEL_CONFIG: 'Spin the Wheel Config',
  SCREEN_VIEW: 'Screen View',
  FULL_SCREEN: 'Full Screen',
  HALF_SCREEN: 'Half Screen',
  TERMS_CONDITION: 'Terms and Conditions',
  ENTER_TERMS_CONDITIONS: 'Enter Terms and Conditions',
  PRIVACY_POLICY: 'Privacy Policy',
  ENTER_PRIVACY_POLICY: 'Enter Privacy Policy',
  BACKGROUND_TYPE: 'Background Type',
  BACKGROUND_COLOR: 'Background Color',
  SELECT_COLOR: 'Select Color',
  PICTURES: 'Pictures',
  BLUR: 'Blur',
  ENTER_BLUR: 'Enter Blur',
  APPRAISAL_TEXT: 'Appriasal Text',
  LOSING_TEXT: 'Losing Text',
  ENTER_LOSING_TEXT: 'Enter Losing Text',
  SELECT_SPIN_TIME: 'Select Spin Time',
  SPIN_FREQUENCY: 'Spin Frequency',
  QUADRANTS_TYPE: 'Quadrants Type',
  NUMBER_OF_QUADRANTS: 'Number of Quadrants',
  ORG_ID: 'Org Id',
  ENTER_ORG_ID: 'Enter Org Id',
  EVENT_1: 'Event',
  ENTER_EVENT: 'Enter Event',
  SAME_SCREEN: 'Same Screen',
  YES_1: 'yes',
  NO_1: 'no',
  SHARE_RESULT: 'Share Result',
  REFER_TO_FRIEND: 'Refer to friend',
  SPIN_ACTION: 'Spin Action',
  SPIN_CLICK_WHEEL: 'Spin on click of wheel',
  SPIN_CLICK_BUTTON: 'Spin on click of button',
  // RULE ENGINE CUSTOMIZE
  BUTTON_PLACE: 'Button place',
  TEXT_ON_BUTTON: 'Text on Button',
  ENTER_TEXT_ON_BUTTON: 'Enter Text On Button',
  TEXT_1: 'Text',
  FONT_COLOR: 'Font Color',
  SELECT_BUTTON_COLOR: 'Select Button Color',
  FONT_FACE: 'Font Face',
  FONT_SIZE_1: 'Font size',
  SELECT_FONT_FACE: 'Select Font Face',
  SELECT_TEXT_SIZE: 'Select Text size',
  IMAGES: 'Images',
  GAMIFICATION_TEMPLATE: 'Gamification Template',
  GAMIFICATION_ACTIVITY: 'Gamification Activity',
  GAME_MECHANICS_TEMPLATE: 'Game Mechanics Template',
  ADD_LEADERBOARD: 'Add Leaderboard',
  REWARD_FOR_2_COMBINATION: 'Reward for 2 Combinations',
  REPEAT_LEADERBOARD: 'Repeat Leaderboard',
  EQUAL_1: 'Equal',
  BETWEEN_1: 'Between',
  PLEASE_CHECK_THE_RANK_RANGE: 'Please check the rank range',
  PLEASE_CHECK_THE_RANGE: 'Please check the range',
  RANK_BASED_REWARD_ASSIGNMENT: 'Rank Based Reward Assignment',
  TO_TEXTBOX: 'To',
  TOTAL_SCORE: 'Total Score',
  GREATOR_1: 'Greator',
  SPINS: 'How many times?',
  SELECT_TIMEZONE: 'Select Timezone',
  TIMEZONE_STRING: 'Set at ',

  //  RULE ENGINE SCHEDULE
  COPY_TIME_ALL: 'Copy Time To All',
  OFFER_END_DATE_TIME: 'Offer end date and time',
  TRIGGER_WHEN: 'Trigger when',
  HOW_OFTEN: 'How Often?',
  SET_FREQUENCY: 'Set Frequency',
  APPLY_THIS_OFFERS_ON: 'Apply this Offers on',
  EVERYTIME: ' everytime',
  ONCE_PER_TIME: 'Once per day',
  ONCE_PER_SESSION: 'Once per session',
  ONCE_PER_CAMPAIGN_DURATION_1: 'Once per campaign duration',
  SPINCOUNT_PER_TIME: ' per day',
  SPINCOUNT_PER_SESSION: ' per session',
  SPINCOUNT_PER_CAMPAIGN_DURATION_1: ' per campaign duration',

  // DATE_PICKER

  ON: 'On',
  TIME_1: 'Time',
  CHOOSE_TIME: 'Choose a Time',
  AM: 'AM',
  PM: 'PM',

  // DATE_TIME_PICKER
  JANUARY: 'January',
  FEBRUARY: 'February',
  MARCH: 'March',
  APRIL: 'April',
  MAY: 'May',
  JUNE: 'June',
  JULY: 'July',
  AGUEST: 'Augest',
  SEPTMEBER: 'September',
  OCTOBER: 'October',
  NOVEMBER: 'November',
  DECEMBER: 'December',
  AT: 'at',
  HH: 'HH',
  MM: 'MM',
  SCHEDULE: 'Schedule',
  COLON: ':',

  // DATE_TIME_RANGE

  CHOOSE_A_TIME_PERIOD_PLAN: 'Choose a time period to plan.',
  FROM_1: 'From',

  // DRAG DROP FILE
  CHOOSE_FILE: 'chooseFile',
  PERCENTAGE_IS_COMPLETE: ' % is complete',
  DRAG_CLICK_IMAGE_UPLOAD: 'Drag or Click Image to upload',

  // FILE
  UPLOAD: 'Upload',

  // OFFER_FILE
  ATTACH: 'Attach',
  TO_1: 'To',
  SUNDAY: 'Su',
  MONDAY: 'Mo',
  TUESDAY: 'Tu',
  WEDNESDAY: 'We',
  THURSDAY: 'Th',
  FRIDAY: 'Fr',
  SATURDAY: 'Sa',
  SELECT_START_DATE: 'Select Start date',
  SELECT_END_DATE: 'Select End date',

  // SELECT GRAPH DATE RANGE
  YEARS: 'Years',
  MONTHS: 'Months',
  WEEKS: 'Weeks',
  DAYS_1: 'Days',

  // TEXT_BOX_HASHTAG
  HASH: '#',

  // SIDEBAR
  ARE_YOU_SURE_YOU_WANT_TO_DISCARD: 'Are you sure you want to discard the changes?',
  TEMPLATES_1: 'Templates',
  GAMES_MECHANICS: 'Game Mechanics',
  OFFER_MANAGER_1: 'Offer Manager',

  // BOX
  NEW: 'NEW',
  PROCEED: 'Proceed',

  // DELETE_POP_UP
  IS_LINKED_WITH_FOLLOWING: 'is linked with following',
  TO_DELETE_THE: 'To delete the',
  PLEASE_UNLINK_IT_FROM_ABOVE: 'please unlink it from above',

  // FOOTER
  GAMIFICATION_GLOBAL_LIMITED: 'Gamification Global Limited, All Rights Reserved',
  COPY_RIGHT: 'Copyright',

  // HEADER
  PROFILE: 'Profile',
  LOGOUT: 'Logout',

  OFFER_START_DATE_AND_TIME: 'Offer start date and time',
  M: 'M',
  T: 'T',
  W: 'W',
  T_HURSDAY: 'T',
  F: 'F',
  S: 'S',
  S_UNDAY: 'S',
  GAME_TITLE: 'Game Title',
  SCORE_FORMATTING: 'Score Formatting',
  SEGMENT_NAME_TITLE: 'Segment Name',
  BADGECRITERIA: 'Badge Criteria',
  TOTAL_OFFER_WON: 'Total Offer Won',
  TOTAL_OFFER_REDEEM: 'Total Offer Redeem',
  TOTAL_USERBADGES: 'Total User Badges',
  BADGETYPE: 'Badge Type',
  STREAKNAME: 'Streak name',
  USERS_INTERACTED: 'Users Interacted',
  NO_OF_DAYS: 'No. of Days',
  TOTAL_OFFERS_REDEEMED: 'Total Offers Redeemed',
  SEARCH_CAMPAIGN: 'Search Campaign',
  SEARCH_REFERRAL: 'Search Referral',
  SEARCH_OFFER: 'Search Offer',
  SEARCH_USER: 'Search User',
  SEND_REPORT: 'Send Report',
  CALENDER_DURATION: 'Duration',
  DOWNLOAD: 'Download',


  // OFFER REPORTS
  OFFER_REPORTS: 'Offer Reports',
  ACTIVITY_INTERACTIONS: 'Activity Interaction',
  OFFER_DISTRIBUTED: 'Offer Distributed',
  TOTAL_OFFER_DISTRIBUTED: 'Total Offer Distributed',
  // userreport
  USER_REPORTS: 'User Reports',
  USER_ID: 'User Id',
  ACTIVITY_INTEGRATION: 'Activity Interaction',
  OFFER_REDEEMED: 'Offer Redeemed',
  CAMPIGN_REPORTS: 'Campaign Reports',
  ACTIVITY_DISPLAY: 'Activity Display',
  REPEAT_PLAYS: 'Repeat Plays',
  GAME_RULE_NAME: 'Game Rule Name',
  // Daily streak reports
  AVERAGE_STREAK_DAYS: 'Average Streak days',
  TRIVIA_CARD: 'Trivia Card',
  TRIVIA_CARD_BG_COLOR: 'Trivia Card BG Color',
  TRIVIA_CARD_BORDER_COLOR: 'Trivia Card Border Color',
  THICKNESS: 'Thickness',
  QUESTION_CARD: 'Question Card',
  QUESTION_CARD_BG_COLOR: 'Question Card BG Color',
  QUESTION_CARD_BORDER_COLOR: 'Question Card Border Color',
  OPTIOS: 'Options',
  DEFAULT_OPTION_BG_COLOR: 'Default Option BG Color',
  DEFAULT_OPTION_BORDER_COLOR: 'Default Option Border Color',
  RIGHT_OPTION_BG_COLOR: 'Right Option BG Color',
  RIGHT_OPTION_BORDER_COLOR: 'Right Option Border Color',
  WRONG_OPTION_BG_COLOR: 'Wrong Option BG Color',
  WRONG_OPTION_BORDER_COLOR: 'Wrong Option Border Color',
  MEDIA: 'Media',
  PLAY_BUTTON_COLOR: 'Play Button Color',
  BUTTON_COLOR: 'Button Color',
  SLEEK_LINE_COLOR: 'Sleek Line Color',
  SLEEK_LINE_COLOR_DEFAULT: 'Sleek Line Color Default',
  SLOT_MACHINE_HEADER: 'Slot Machine',

  // TOSS COIN
  CUSTOMIZE_YOUR_COIN: 'Customize your coin',
  COIN_COLOUR: 'Coin Color',
  COIN_CONFIGURATION: 'COIN CONFIGURATION',
  COIN_TOSS_SPEED: 'Coin toss speed',
  SIDE_ONE: 'Side 1',
  SIDE_TWO: 'Side 2',
  COIN_FLIP_ON: 'Coin Flip On',
  COIN_CONTENT: 'Coin Content',
  COIN_FLIP_AUDIO: 'Coin Flip Audio',
  TOSS_THE_COIN: 'Toss the Coin',


  // roll the dice
  CUSTOMIZE_YOUR_ROLL_THE_DICE: 'Customize your roll the dice',
  REFER_HERE: 'refer here',
  ROLL_THE_DICE: 'Roll the Dice',
  DICE_CONFIGURATION: 'DICE CONFIGURATION',
  NUMBER_OF_DICE: 'Number of Dice',
  NUMBER_OF_DICE_ROLLS: 'Number of Dice Rolls',
  DICE_COLOUR1: 'Dice Color 1',
  DICE_COLOUR2: 'Dice Color 2',
  DICE_COLOUR3: 'Dice Color 3',
  TAP: 'Tap',
  SWIPE: 'Swipe',
  BUTTON1: 'Button',
  DICE_ROLL_ON: 'Dice Roll On',
  DICE_CONTENT: 'Dice Content',
  DOTS: 'Dots',
  NUMBERS1: 'Numbers',
  NUMBERS_1: 'Numbers 1',
  BUTTON_TEXT1: 'Button text',
  DICE_ROLL_AUDIO: 'Dice Roll Audio',
  AUDIO: 'Audio 1',
  // SLOT_MACHINE_HEADER: 'Slot Machine',
  RESULT_HANDLER: 'Result Handler',
  SUB_TITLE: 'Sub-Title',
  // offer manager toss coin
  CHOOSE_VOUCHER: 'Choose Voucher',
  SELECT_DENOMINATION: 'Select Denomination',
  NUMBER_OF_VOUCHERS: 'Number Of Vouchers',
  // offer manager third party
  ENGLISH: 'English',

  // Mail server
  SERVER_DESCRIPTION: 'Server Description',
  DESCRIPTION: 'Description',
  SERVER_NAME: 'Server Name',
  PORT: 'Port',
  CONNECTION_SECURITY: 'Connection Security',
  AUHENTICATION: 'Authentication',
  USER_NAME: 'User Name',
  // Question schedule
  QUESTIONS_SCHEDULE: 'Question Schedule',
  NUMBER_OF_QUESTIONS: 'Number of Questions',
  ADD_TIMER: 'Add Timer',
  DENOMINATION_REQUIRED: 'Denomination is required',
  VOUCHER_COUNT_REQUIRED: 'Voucher count is required',
  CUSTOMIZE_YOUR_REFERRAL: 'Customize your Referral Screen',
  SECTION_REFER: 'Section : Refer',
  REFFERAL_SCREEN: 'Referral Screen',
  DISPLAY_LEADERBOARD: 'Display Leaderboard',
  LEADERBOARD_REQUIRED: 'Leaderboard Required',
  BORDER_COLOR: 'Border Color',
  BANGLA: 'Bangla',
  TABLE_BACKGROUND: 'Table Background',
  BG_IMAGE: 'Background Image',
  TABLE_BG_IMAGE_REQUIRED: 'Table Background Image is required',
  TOP_RANK: 'Top Rank',
  PRIMARY_COLOR: 'Primary Color',
  SECONDARY_COLOR: 'Secondary  Color',
  RANK_1: 'Rank 1',
  RANK_2: 'Rank 2',
  RANK_3: 'Rank 3',
  RANK_TEXTS: 'Rank Text',
  HEADING: 'Heading',
  RAW_COLOR: 'Row Color',
  BODY: 'Body',
  SCORE_TEXT: 'User score Text',
  LEADERBOARD_NAME_EXISTS: 'LeaderBoard Name already exist',

  // Leaderboards Reports
  LEADERBOARD_REPORTS: 'Leaderboard Reports',
  CAMPAIGN_HEADING: 'Campaign',
  SELECT_CAMPAIGN: 'Select Campaign',
  APP_LINK: 'App Link',
  CODE_PREFIX: 'Code Prefix',
  PREFIX: 'Prefix',
  // Custom Events
  EVENT_NAME: 'Event Name',
  EVENT_KEY: 'Event Key',
  EVENT_VALUE: 'Event Value',
  CUSTOMIZE_EVENT: 'Custom Events',
  CUSTOMEVENTS: '/customEvents',
  CONFIGURE_EVENTS: 'Event Configuration',
  MY_EVENTS: 'My Events',
  CREATION_DATE: 'Creation Date',
  REWARD_EACH_EVENT: 'Reward each event',
  EVENT_COMPLETION: 'Event Completion',
  ARE_YOU_SURE_TO_SAVE_WITHOUT_PROPERTIES: 'Are you sure you want to save without adding properties',
  // Tournament
  TOURNAMENT_CONFIGURATION: 'Tournament Library',
  MY_TOURNAMENTS: 'My Tournaments',
  TOURNAMENT: 'Tournament',
  TOURNAMENTS: '/tournament',
  QUIZ: 'Quiz',
  SCORING_FORMATS: 'Scoring Format',
  TOURNAMENT_NAME: 'Tournament Name',
  REQUIRED: ' is required',
  MAXIMUM_NUMBER_OF_PLAYERS: 'Maximum No. of Players',
  TOURNAMENT_START: 'Start Timer',
  TOURNAMENT_END: 'End Timer',
  TITLE_IMAGE: 'Title Image',
  TOURNAMENT_DESC: 'Tournament Description',
  TOURNAMENT_RULES: 'Tournament Rules',
  PRIZE_TEXT: 'Prize Text',
  CUSTOMIZE_YOUR_TOURNAMENT: 'Customise your Tournament',
  CTA_BUTTON: 'CTA BUTTON',
  TOURNAMENT_PLAY_BUTTON: 'TOURNAMENT PLAY BUTTON',
  TOURNAMENT_DESCRIPTION_REQUIRED: 'Tournament Description is required',
  TOURNAMENT_RULES_REQUIRED: 'Tournament Rules is required',
  // TNC
  DISPLAY_DOMAIN: 'Display Domain',
  HYPERLINK_CONFIGURATIONS: 'HYPERLINK CONFIGURATIONS',

  // Quest
  QUEST: 'Quest',
  CUSTOMIZE_YOUR_QUEST: 'Customise your Quest and Challenges',
  QUEST_DESC: 'Quest Description',
  PROGRESS_BAR: 'Progress Bar \u00A0\u00A0',
  PROGRESS_BAR_DESIGN: 'Progress Bar Design',
  PROGRESS_BAR_STYLE: 'Progress Bar Style',
  MILESTONE_DESC: 'Milestone Description',
  MILESTONE_START: 'Milestone Start Timer',
  QUEST_START: 'Quest Start Timer',
  MILESTONE_END: 'Milestone End Timer',
  QUEST_END: 'Quest End Timer',
  TIMER_ICON_COLOR: 'Timer Icon Color',
  REWARDS: 'Rewards',
  TASK: 'TASK',
  TASK_DESCRIPTION: 'Task Description',
  CUSTOM_EVENT: 'Custom Event',
  SCORING_CRITERIA: 'Scoring Criteria',
  CTA: 'CTA',
  REDIRECTION_LINK: 'Redirection Link',
  TASK_START: 'Task Start Timer',
  TASK_END: 'Task End Timer',
  QUEST_CONFIGURATION: 'Quest Configuration',
  MY_QUEST: 'My Quest',
  CUSTOMIZE_QUEST: 'Customize your Quest',
  QUESTURL: '/questRule',
  REPEAT_TASK: 'Repeat Task',
  CUSTOMEVENTS_ACTIVITY: 'CustomEvents/Activity',
  TASK_TYPE: 'Task Type',
  GAMIZE_ACTIVITIES: 'Gamize Activity',
  SUBTITLE_IS_REQ: 'Subtitle is required',
  CONFIGURE_QUEST: 'Configure Quest',
  opt: ' > ',
  STATUSQUEST: 'Status\u00A0\u00A0',
  UNORDERED: 'Unordered',
  ORDERED: 'Ordered',
  MILESTONE_COMP_FORMAT: 'Milestone Completion Format',
  TASK_COMP_FORMAT: 'Task Completion Format',
  MILESTONE_COMP_COLOR: 'Milestone Completion Color',
  TASK_COMP_COLOR: 'Task Completion Color',
  TITLE_CARD_BCG: 'Title Card Background',
  CARD_BCG: 'Card Background',
  TIMER_ICON: 'Timer Icon',
  TIMER_BACKGROUND: 'Timer Background',
  LEADGENERATION: 'Lead Generation',
  BACKGROUND: 'Background',
  MAX_70: '70px X 70px ',
  MAX_400_900: '400px X 900px',
  MAX_170: '170px X 170px',
  PROGRESS_BAR_COLOR_COMPLETED: 'Progress Bar Color - Completed',
  PROGRESS_BAR_COLOR_DEFAULT: 'Progress Bar Color - Default',
  FADE_REMANING_EVENT: 'Fade Remaning Event',
  LEADERBORD_TYPE: 'Leaderboard Type',
  LEADERBOARD_TEMPLATE: 'Leaderboard Template',
  PROGRESS_TASK_NAME: 'Progress Task Name',
  PROGRESS_TASK_IMAGE: 'Progress Task Image',
  PROGRESS_MS_NAME: 'Progress Milestone Name',
  PROGRESS_MS_IMAGE: 'Progress Milestone Image',
  GRATIFICATION_DISPLAY: 'Gratification Display',
  COMPLETED_CARD: 'Completed Card',
  EXPIRED_CARD: 'Expired Card',
  BORDER: 'Border',
  INPROGRESS: 'In Progress',
  CTA_TEXT: 'CTA Text',
  PROPERTY: 'Property',

  // MultiUser
  CHANGE_PWD: 'Change Password',
  TEAM: 'Team',
  TEAM_NAME_REQUIRED: 'Team name is required',
  TEAM_NAME_MIN_3_MAX_50: 'Team name should be of min 3 and max 50 characters',
  COMPANY_INFORMATION: 'Company Information',
  ADMIN_BASIC_INFORMATION: 'Admin Basic Information',
  USER_EMAIL_ID: 'User Email id',
  USER_TEAM: 'User Team',
  USER_PASSWORD: 'User Password',
  USER_TEAM_REQUIRED: 'User Team is required',
  CREATEDBY: 'Created By',

  // Notifications
  PUSH_NOTIFICATION: 'Push Notification',
  PUSH_NOTIFICATION_CONFIG: 'Push Notification Configuration',
  MY_PUSH_NOTIFICATION: 'My Push Notification',
  NOTIFICATION_NAME: 'Notification Name',
  NOTIFICATION_TITLE: 'Notification Title',
  NOTIFICATION_CONTENT: 'Notification Content',
  USERSEGMENT_UPLOAD: 'User Segment Upload',
  IMAGE_UPLOAD: 'Image Upload',
  NOTIFICATION_SEND_TIME: 'Notification Send Time',
  NOTIFICATION_CAMPAIGN_SCH: 'Notification Campaign Schedule',
  ENTER_NOTIFICATION_NAME: 'Enter Notification Name',
  NOTIFICATION_CAMPAIGN_SCHEDULE: 'Notification Campaign Schedule',
  START_DATE_AND_TIME: 'Start date and time',
  END_DATE_AND_TIME: 'End date and time',
  SEND_IMPRESSIONS: 'Send/Impressions',
  CLICKS_OPEN: 'Clicks/Open',
  DATETIME: 'Date/Time',
  NOTIFICATION_NAME_REQUIRED: 'Notification name Required',
  NOTIFICATION_TITLE_REQUIRED: 'Notification title Required',
  NOTIFICATION_CONTENT_REQUIRED: 'Notification content Required',
  NOTIFICATION_LINK_REQUIRED: 'Notification redirection link Required',
  NOTIFICATION_NAME_SHOULD_CONTAIN_ATLEAT: 'Notification name should contain atleast 3 characters',
  NOTIFICATION_RULE_REQUIRED: 'Notification Rule Required',
  GRATIFY_LEADERBOARD: 'Gratify Leaderboard',

  // MemoryGame
  CUSTOMISE_YOUR_MEMORY_GAME: 'Customise your Memory Game',
  MEMORY_GAME_CONFIGURATION: 'Game Configuration',
  GAME_TIMER: 'Game Timer',
  REVEAL_CARD: 'Reveal Card Before Game Play',
  REVEAL_CARD_TIME: 'Reveal Card Time',
  CARD_FLIP_SPEED: 'Card Flip Speed',
  CARD_DESIGN_CONFIGURATION: 'Card Design Configuration',
  NO_OF_CARD_PAIRS: 'No of Card Pairs',
  CARD_SIZES: 'Card Sizes',
  GRID_FORMAT: 'Grid Format',
  NO_OF_ROWS: 'No. of rows',
  NO_OF_COLS: 'No. of columns',
  CARD_BACK_LAYER: 'Card Back Layer',
  MEMORY_CARD_CONTENT: 'Card Content Configuration',
  PAIR: 'Pair',
  CARD1: 'CARD 1',
  CARD2: 'CARD 2',
  HEADER_CONFIGURATION: 'Header Configuration',
  TIMER_CONFIGURATION: 'Timer Configuration',
  REFERRAL_CODE: 'Referral Code',
  OUTER_BUBBLE_BG: 'Outer Bubble BG',
  INNER_BUBBLE_BG: 'Inner Bubble BG',
  SECTION_TITLE: 'Section Title',
  FONT_STYLE: 'Font Style',
  REFERRAL_TEXT: 'Referral Text',
  REFERRAL_IMAGE: 'Referral Image',
  FON_COLOR: 'Color',
  FON_SIZE: 'Font Size',
  FON_WEIGHT: 'Font Weight',
  FONT_WT: 'Font Wt',
  FONT_POSITION: 'Position',
  BACKGROUND_CONFIGURATION: 'Background Configuration',
  TEXT_CONFIGURATION: 'Text Configuration',
  MEMORY_GAME: 'Memory Game',
  USER_TIME_COLUMN: 'User Time Column',
  TIMER_TEXT: 'Timer Text',
  PRIZE_BREAKUP: 'Prize Breakup',
  SHUFFLE_OPT: 'SHUFFLE OPTIONS',
  RANDOMIZE_OPTIONS: 'RANDOMIZE OPTIONS',
  USER_ONBOARDING_TRIAL_EXPIRED: 'Your trial period of our gamification platform is over.',
  USER_ONBOARDING_TRIAL_EXPIRED_CONTACT: 'Please contact Gamize team to continue.',
  INFO_SCREEN: 'Information Screen',
  MEMORYGAME_TYPE: 16,
  MEMORYGAME: 'Memory Game',

  // polls
  POLLING_TYPE: 18,
  CUSTOMISE_YOUR_POLLS_TEMPLATE: 'Customise your Polls Template',
  POLLS: 'Polls',
  QUEST_AND_OPTION_CARD_DESIGN: 'Question and Option Card Design',
  POLLING_QUESTION_AND_OPTION_CONFIG: 'Polling Question and Option Configuration',
  PERCENTAGE_BAR_CONFIG: 'Percentage Bar Configuration',
  COLOR_CONFIG: 'Color Configuration',
  FONT_CONFIG: 'Font Configuration',
  STYLE: 'Style',
  SIZE: 'Size',
  WEIGHT: 'Weight',
  CHECKBOX_OPTION_SELECTION: 'Checkbox for Option Selection',
  DEFAULT_COLOR: 'Default Color',
  SELECTION_COLOR: 'Selection Color',
  TICK_MARK_COLOR: 'Tick Mark Color',
  SELECTED_OPT_TEXT_COLOR: 'Selected Option Text Color',
  PERCENTAGE_BAR_COLOR: 'Percentage Bar Color',
  SELECTED_OPTION: 'Selected Option',
  OTHER_OPTIONS: 'Other Options',
  PERCENTAGE_TEXT: 'Percentage Text',
  WINNING_IMG: 'Winning Img',
  LOOSING_IMG: 'Losing Img',
  HOVER: 'Hover',
  OPTIONS: 'Option\u00A0\u00A0',
  TIME_DURATION_NEXT_SCREEN: 'Time Duration fo Next Screen',
  POINTS_ERR: 'pointsError',
  OPTION_ERR: 'optionsError',
  TITLE_CONFIG: 'Title Configuration',

  HOW_OFTEN_CE: 'How often can user play the activity?',
  PAGES: 'Pages',
  ACTIVITY_CONFIGURATION: 'Activity Configuration',
  // Customise your Custom Event Screen
  CUSTOMISE_YOUR_CUSTOM_EVENT_SCREEN: 'Customise your Custom Event Screen',
  PAGE_NAME: 'Page Name',
  DISPLAY_WIZARD: 'Display Wizard',
  TRANSACTIONS: 'Transactions',
  TIMER_CARD_CONFIGURATION: 'Timer Card Configuration',
  RESULT_MESSAGE_CONFIGURATION: 'Result Message Configuration',
  DESCRIPTION_CARD_CONFIGURATION: 'Description Card Configuration',
  CTA_BUTTON_CONFIGURATION: 'CTA Button Configuration',
  POSITION_TITLE_NEW: 'Position',
  HEADER: 'Header',
  REWARD_HEADING: 'Reward Text',
  TRANSACTION_TABLE_CONFIGURATION: 'Transaction Table Design Configuration',
  HEADER_SECTIONS: 'Header Sections',
  USERNAME_IS_REQUIRED: 'User name is required',
  USERNAME_SHOULD_CONTAIN_ATLEAST: 'User name should contain atleast 3 characters',
  REWARD_TEXT_IS_REQUIRED: 'Reward text is required',
  REWARD_TEXT_SHOULD_CONTAIN_ATLEAST: 'Reward text should contain atleast 3 characters',
  TABLE_HEADING_IS_REQUIRED: 'Table heading is required',
  TABLE_HEADING_SHOULD_CONTAIN_ATLEAST: 'Table heading should contain atleast 3 characters',
  TABLE_HEADING_SECTIONS_ARE_REQUIRED: 'All 3 headings are required',
  TABLE_HEADING_SECTIONS_SHOULD_CONTAIN_ATLEAST: 'Each of the 3 headings should contain atleast 3 characters',
  CARD_DESIGN: 'Card Design',
  SHADOW_FILL: 'Shadow Fill',
  CARD_DESCRIPTION: 'Card Description',
  FONT_SIZE_2: 'Font Size',
  FONT_WT_1: 'Font wt',

  // Bandkit
  TITLE_TEXT_FORMAT: 'Title Text Format',
  FIELD: 'Field',
  FONT_SETTING_FOR_TITLE_FIELD: 'Font setting for title field',
  SUBTITLE_TEXT_FORMAT: 'Subtitle Text Format',
  FONT_SETTING_FOR_SUBTITLE_FIELD: 'Font setting for subtitle field',
  GENERAL_TEXT_BOX: 'General Text Box Format',
  FONT_SETTING_FOR_GENERAL_FIELDS: 'Font setting for general fields',
  BUTTON_TEXT_FORMAT: 'Button Text Format',
  FONT_SETTING_FOR_BUTTONS: 'Font setting for buttons',
  ADD_CUSTOM_FONT: 'Add Custom Font',
  MAX_SIZE_1MB: 'Max Size: 1mb | 250px X 150px',
  SUPPORTED_OTF_TFF: '*Supported : OTF, TFF',
  BRAND_COLORS: 'Brand Colors',
  VIRTUAL_CURRENCY: 'Virtual Currency',
  NAME_OF_CURRENCY: 'Name of currency',
  RENAME_REWARD_CURRENCY: 'Rename reward currency',
  ICON_LIBRARY: 'Icon  Library',
  OPEN_LIBRARY: 'Open Library',
  QUESTION_TEXT_BOX: 'Question Text Format',
  OPTION_TEXT_BOX: 'Option Text Format',
  BRAND_PALETTE: 'Brand Palette',
  GAMIZE_DEFAULT: 'Gamize Default',
  DASH_BOARD: 'dashboard',
  UNSUPPORTED_FONT: 'Unsupported Font File Type',
  UNSUPPORTED_IMG: 'Unsupported Image Type',
  WEBHOOK_CALLBACK: 'Webhook Callback',
  // Clever Tap
  ACCOUNT_ID: 'Account Id',
  USER_PASSCODE: 'User Passcode',

  // SMART CAMPAIGNS
  CAMPAIGN_MANAGER: 'Campaign Manager',
  CAMPAIGN_CONFIGURATION: 'Campaign Configurations',
  WEIGHTAGE: 'Weightage',
  CAMPAIGN_START_DATE_AND_TIME: 'Campaign Start Date And Time',
  CAMPAIGN_END_DATE_AND_TIME: 'Campaign End Date And Time',

  // Themes
  THEMES: 'Themes',
  THEME_CONFIG: 'Theme Configuration',
  THEMES_LIBRARY: 'Themes Library',
  MY_THEMES: 'My Themes',
  CUSTOMISE_ACTIVITY: 'Customise Activity',
  CUSTOMISE_ACTIVITY_DISPLAY: 'Customise Activity Display',
  CUSTOMISE_GRATIFICATION_DISPLAY: 'Customise Gratification Display',
  BUTTON_CONFIG: 'Button Configuration',
  THEME_1: 'Theme_1',
  LOGO: 'Logo',
  CLOSE_ICON: 'Close Icon',
  OUTLINE_FILL: 'Outline Fill',
  HOVER_FILL: 'Hover Fill',
  BUTTON_FILL: 'Button Fill',
  WINNING_IMAGE: 'Winning Image',
  LOSING_IMAGE: 'Losing Image',
  WINNING_BTN_TXT: 'Winning Button Text',
  LOSING_BTN_TXT: 'Losing Button Text',
  OFFER_DETAIL_TITLE: 'Offer Details Title',
  OFFER_DETAILS: 'Offer Details',
  INSTRUCTION_TITLE: 'Instructions Title',
  INSTRUCTIONS: 'Instructions',
  WIN_WEB_URL: 'Winning Web URL',
  WEB_URL: 'Web URL',
  WIN_APP_URL: 'Winning App URL',
  LOSE_WEB_URL: 'Losing Web URL',
  APP_URL: 'App URL',
  LOSE_APP_URL: 'Losing App URL',
  THEME_TYPE: 1,
  COPY_ICON: 'Copy Icon',
  REWARD_ICON: 'Reward Icon',
  THEMES_TYPE: 'Theme Type',
  LAST_EDITED: 'Last Edited',
  THEME: '/themes',
  THEME_NAME: 'Theme Name',
  THEME_DESIGN: 'Theme Design',
  DISPLAY_TYPE: 'Display Type',

  SEARCH_THROUGH_DURATION: 'Search Through Duration',
  SEARCH_REPORT: 'Search Report',

  RANDOMIZE_QUESTIONS: 'RANDOMIZE QUESTIONS',
  FORM_DISPLAY_CONFIG: 'Form Display Configuration',
  FORM_FIELD_CONFIG: 'Form Field Configuration',
  FORM_BUTTON_CONFIG: 'Form Button Configuration',
  ADDITIONAL_SETTINGS: 'Additional Settings',
  LABELS_POSITIONS: 'Labels Position',
  LABEL: 'Label',
  FORM_FIELD_LIST: 'Form Field List',
  DESCRIPTION_TEXT: 'Description Text',
  UNIQUE_IDENTIFIER: 'Unique Identifier',
  SUBMIT_BTN_PLACEMENT: 'Submit Button Placement',
  PRE_ACTION: 'Pre-Action',
  POST_ACTION: 'Post-Action',
  ENTER_YOUR_DETAILS: 'Enter your details',
  WINNING_MSG: 'Winning Msg',
  LOSING_MSG: 'Losing Msg',

  // leadGen
  LEAD_GEN: 'leadGeneration',
  SELECT_FIELDS: 'Select field (s)',
  MORE_FIELDS: 'More fields',
  TEXT_BOX_CONFIG: 'Text Box Configuration',
  TEXT_BOX: 'Text Box',
  FIELD_COLOR: 'Field Color',
  LEAD_GENERATION: 'Lead Generation',

  // XOXODAY
  ACCESS_TOKEN: 'Access Token',
  DENOMINATION: 'Denomination'
};
