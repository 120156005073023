import { APP_CONFIG } from '@constants';

const upload = require('superagent');

export const UPDATE_FIELD = 'rolldice/UPDATE_FIELD';
export const UPDATE_PAGE = 'rolldice/UPDATE_PAGE';
export const UPDATE_BACKGROUND_FIELD = 'rolldice/UPDATE_BACKGROUND_FIELD';
export const UPDATE_BACKGROUND_FIELD1 = 'rolldice/UPDATE_BACKGROUND_FIELD1';
export const UPDATE_BACKGROUND_FIELD2 = 'rolldice/UPDATE_BACKGROUND_FIELD2';
export const UPDATE_BACKGROUND_FIELD3 = 'rolldice/UPDATE_BACKGROUND_FIELD3';
export const UPDATE_BACKGROUND_FIELD4 = 'rolldice/UPDATE_BACKGROUND_FIELD4';
export const UPDATE_BACKGROUND_FIELD5 = 'rolldice/UPDATE_BACKGROUND_FIELD5';
export const UPDATE_BACKGROUND_FIELD6 = 'rolldice/UPDATE_BACKGROUND_FIELD6';
export const UPDATE_BUTTON_CONFIG = 'rolldice/UPDATE_BUTTON_CONFIG';
export const UPDATE_BUTTON_TEXT = 'rolldice/UPDATE_BUTTON_TEXT';
export const UPDATE_BUTTON = 'rolldice/UPDATE_BUTTON';
export const UPDATE_QUESTIONS_FIELD = 'rolldice/UPDATE_QUESTIONS_FIELD';


export const UPDATE_TITLE_FIELD = 'rolldice/UPDATE_TITLE_FIELD';
export const UPDATE_SUBTITLE_FIELD = 'rolldice/UPDATE_SUBTITLE_FIELD';
export const UPDATE_DICE_CONFIG = 'rolldice/UPDATE_DICE_CONFIG';
export const UPDATE_APPCONFIG_FIELD = 'rolldice/UPDATE_APPCONFIG_FIELD';

export const UPDATE_ACTIVE_FLAG = 'quizConfig/UPDATE_ACTIVE_FLAG';
export const UPDATE_LOCALIZATION_FLAG = 'quizConfig/UPDATE_LOCALIZATION_FLAG';
export const UPDATE_CONFIG_FIELD = 'quizConfig/UPDATE_CONFIG_FIELD';

export const UPLOAD_IMAGE_REQUESTED = 'upload/UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_IMAGE_SUCCESS = 'upload/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'upload/UPLOAD_IMAGE_FAILURE';
export const UPDATE_LANGUAGE = 'rolldice/UPDATE_LANGUAGE';

export const UPDATE_CONFIG_REQUESTED = 'update/UPDATE_CONFIG_REQUESTED';
export const UPDATE_CONFIG_SUCCESS = 'update/UPDATE_CONFIG_SUCCESS';
export const UPDATE_CONFIG_FAILURE = 'update/UPDATE_CONFIG_FAILURE';

export const SAVE_CONFIG_REQUESTED = 'save/SAVE_CONFIG_REQUESTED';
export const SAVE_CONFIG_SUCCESS = 'save/SAVE_CONFIG_SUCCESS';
export const SAVE_CONFIG_FAILURE = 'save/SAVE_CONFIG_FAILURE';

export const GET_CONFIG_REQUESTED = 'get/GET_CONFIG_REQUESTED';
export const GET_CONFIG_SUCCESS = 'get/GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'get/GET_CONFIG_FAILURE';
export const CLEAR_STATE = 'rolldice/CLEAR_STATE';

export const DUPLICATE_CONFIG_REQUESTED = 'get/DUPLICATE_CONFIG_REQUESTED';
export const DUPLICATE_CONFIG_SUCCESS = 'get/DUPLICATE_CONFIG_SUCCESS';
export const DUPLICATE_CONFIG_FAILURE = 'get/DUPLICATE_CONFIG_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  initialValues: {
    fontTitlePositions: [],
    spinButtonPosition: [],
    spinButtonWidth: [],
    spinButtonRadius: [],
    spinButtonShadow: [],
    fontPositions: [],
    outlineStyles: [],
    fonts: [],
    fontStrength: [],
    fontSize: []
  },
  localizationFlag: '',
  activeNavTab: 'English',
  config: {
    localizationconfig: [
      {
        language: 'English',
        resultHandler: 'ParentApp',
        title: {
          text: 'Mega Win',
          color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#ffffff' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
          font: {
            size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '16' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontSize,
            strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontWt,
            family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFont,
            align: 'center',
          },
          position: 'center',
          numbers: 'dots',
          button: 'tap',
          titleError: '',
          disableSave: false,
          templateName: {
            text: ''
          },
        },
        subtitle: {
          text: 'Roll and Win Exciting Prizes',
          color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#ffffff' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
          font: {
            size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFontSize,
            strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFontWt,
            family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFont,
            align: 'center',
          },
          titleError: '',
        },
        background: {
          currentImage: '',
          color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#02181C' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.primaryColour,
          image: '',
          imageName: '',
          backgroundError: '',
          showProgressBar: false,
          wheelImage: '',
          buttonText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontSize,
              strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontWt,
              family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFont,
              align: 'left',
            },
            error: '',
            errorImg: ''
          },
          winningText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
              strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
              family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
              align: 'left',
            },
            error: '',
            errorImg: ''
          },
          losingText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
              strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
              family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
              align: 'left',
            },
            error: '',
            errorImg: ''
          }
        },
        buttonNeeded: true,
        buttonConfig: [
          {
            text: {
              text: '',
              color: '#000000',
              font: {
                size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontSize,
                strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontWt,
                family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFont,
                align: 'left',
              },
            },
            textColor: '#000000',
            image: {
              url: '',
              name: ''
            },
            imageError: '',
            width: 'auto',
            radius: '30px',
            shadow: '0px 0px',
            shadowColor: '#cdcdcd',
            position: 'left',
            bgColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#ffffff' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
            border: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#000000' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
            hoverBgColor: '#cdcdcd',
            buttonTextError: '',
            borderWidth: '1px',
            appLink: {
              text: ''
            },
            webLink: {
              text: ''
            },
            appLinkError: '',
            webLinkError: ''
          }
        ],
        diceConfig: {
          numberOfDice: 3,
          numberOfDiceRolls: 2,
          diceColors: ['#F49937', '#F63236', '#9D54D9'],
          diceRollOn: 'tap',
          buttonConfig: {
            text: 'Roll The Dice',
            image: '',
            color: '#ffffff',
            font: {
              size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontSize,
              strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontWt,
              family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFont,
              align: 'left',
            },
            bgColor: '#7F49FD',
            // textColor: '',
            // fontWeight: '',
            // fontSize: '14',
            // font: '',
            imageName: '',
            position: '',
            errorImg: ''
          },
          diceContent: 'numbers',
          diceContentList: ['1', '2', '3', '4', '5', '6'],
          diceContentListError: '',
          diceRollAudio: 'audio',
          diceRollAudioFile: '',
          diceRollAudioFileName: ''
        },
      },
    ],
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BACKGROUND_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].background = state.config.localizationconfig[i].background
            ? {
              ...state.config.localizationconfig[i].background,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_TITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].title = state.config.localizationconfig[i].title
            ? {
              ...state.config.localizationconfig[i].title,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_SUBTITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].subtitle = state.config.localizationconfig[i].subtitle
            ? {
              ...state.config.localizationconfig[i].subtitle,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_DICE_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].diceConfig = state.config.localizationconfig[i].diceConfig
            ? {
              ...state.config.localizationconfig[i].diceConfig,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BUTTON_TEXT: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].spinButton = state.config.localizationconfig[i].spinButton
            ? {
              ...state.config.localizationconfig[i].spinButton,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BUTTON_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BUTTON: {
      console.log('ssssss', action.payload);
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_QUESTIONS_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_APPCONFIG_FIELD: {
      state.initialValues = state.initialValues
        ? {
          ...state.initialValues,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case SAVE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DUPLICATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case DUPLICATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DUPLICATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPLOAD_IMAGE_REQUESTED: {
      return {
        ...state,
        imageloading: true,
        imageloaded: false,
        imageWinLoading: true,
        imageWInLoaded: false,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        imageloading: false,
        error: false,
        imageloaded: true,
        imageWinLoading: false,
        imageWInLoaded: true,
      };
    }
    case UPLOAD_IMAGE_FAILURE: {
      return {
        ...state,
        imageloading: false,
        imageloaded: false,
        imageWinLoading: false,
        imageWInLoaded: false,
        error: true,
      };
    }
    case UPDATE_CONFIG_FIELD: {
      const localizationconfig = [...state.config.localizationconfig, ...action.payload.localizationconfig];
      const config = {
        ...state.config,
        localizationconfig
      };
      return { ...state, config };
    }
    case UPDATE_ACTIVE_FLAG: {
      return { ...state, activeNavTab: action.payload };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case UPDATE_LANGUAGE: {
      return { ...state, languages: action.payload };
    }
    case UPDATE_LOCALIZATION_FLAG: {
      return { ...state, localizationFlag: action.payload };
    }
    default:
      return state;
  }
};

export const getAppConfig = () => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get('getAppConfig'),
  };
};


export const uploadimage = (file, folderName) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/gamification/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
      .field('folderName', folderName)
  };
};


// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updateTitle = (payload) => {
  return {
    type: UPDATE_TITLE_FIELD,
    payload,
  };
};

export const updateSubTitle = (payload) => {
  return {
    type: UPDATE_SUBTITLE_FIELD,
    payload,
  };
};
export const updateDiceConfig = (payload) => {
  return {
    type: UPDATE_DICE_CONFIG,
    payload,
  };
};
export const updateActiveFlag = (payload) => {
  return {
    type: UPDATE_ACTIVE_FLAG,
    payload,
  };
};
export const updateLanguage = (payload) => {
  return {
    type: UPDATE_LANGUAGE,
    payload
  };
};


export const updateDropdown = (payload) => {
  return {
    type: UPDATE_APPCONFIG_FIELD,
    payload,
  };
};

export const updateSpinButton = (payload) => {
  return {
    type: UPDATE_BUTTON_TEXT,
    payload,
  };
};

export const updateBackground = (payload) => {
  return {
    type: UPDATE_BACKGROUND_FIELD,
    payload,
  };
};
export const updateBackground1 = (payload) => {
  return {
    type: UPDATE_BACKGROUND_FIELD1,
    payload,
  };
};
export const updateBackground2 = (payload) => {
  return {
    type: UPDATE_BACKGROUND_FIELD2,
    payload,
  };
};
export const updateBackground3 = (payload) => {
  return {
    type: UPDATE_BACKGROUND_FIELD3,
    payload,
  };
};
export const updateBackground4 = (payload) => {
  return {
    type: UPDATE_BACKGROUND_FIELD4,
    payload,
  };
};
export const updateBackground5 = (payload) => {
  return {
    type: UPDATE_BACKGROUND_FIELD5,
    payload,
  };
};
export const updateBackground6 = (payload) => {
  return {
    type: UPDATE_BACKGROUND_FIELD6,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};
export const updateLocalizationFlag = (payload) => {
  return {
    type: UPDATE_LOCALIZATION_FLAG,
    payload,
  };
};
export const updateConfig = (payload) => {
  return {
    type: UPDATE_CONFIG_FIELD,
    payload
  };
};
export const editDiceConfig = (data, gamificationId) => {
  return {
    types: [UPDATE_CONFIG_REQUESTED, UPDATE_CONFIG_SUCCESS, UPDATE_CONFIG_FAILURE],
    promise: client => client.post(`saveSpinWheelConfig?gamificationId=${gamificationId}`, {
      data,
    }),
  };
};
export const saveDiceConfig = (data) => {
  return {
    types: [SAVE_CONFIG_REQUESTED, SAVE_CONFIG_SUCCESS, SAVE_CONFIG_FAILURE],
    promise: client => client.post('saveSpinWheelConfig', {
      data,
    }),
  };
};

export const getDice = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getSpinWheel?gamificationId=${data}`),
  };
};
export const duplicateDiceTitle = (data, type, id) => {
  return {
    types: [DUPLICATE_CONFIG_REQUESTED, DUPLICATE_CONFIG_SUCCESS, DUPLICATE_CONFIG_FAILURE],
    promise: client => client.get(`duplicateSpinWheelTitle?title=${data}&type=${type}&id=${id}`)
  };
};

export const getTemplate = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getTemplate?templateId=${data}`),
  };
};

export const updateButtonConfig = (payload) => {
  return {
    type: UPDATE_BUTTON_CONFIG,
    payload
  };
};

export const updateButton = (payload) => {
  return {
    type: UPDATE_BUTTON,
    payload
  };
};

export const updateQuestions = (payload) => {
  return {
    type: UPDATE_QUESTIONS_FIELD,
    payload,
  };
};
