export const LOGOUT_REQUESTED = 'logout/LOGOUT_REQUESTED';
export const LOGOUT_SUCCESS = 'logout/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'logout/LOGOUT_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  code: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case LOGOUT_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        code: action && action.result && action.result.code,
      };
    }
    case LOGOUT_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    default:
      return state;
  }
};

export const logout = () => {
  return {
    types: [LOGOUT_REQUESTED, LOGOUT_SUCCESS, LOGOUT_FAILURE],
    promise: client => client.post('logoutapi', {
    })
  };
};
