import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import OnRouteChange from '@containers/common/OnRouteChange';
import App from '@containers/App';
import PageLoader from '@components/common/PageLoader';
import PrivateRoute from './PrivateRoute';
import { APP_CONFIG } from '@constants';
import 'font-awesome/css/font-awesome.min.css';
import EN from './localization/EN';


const LoadingComponent = ({ isLoading, isError }) => {
  if (isLoading) {
    return <PageLoader />;
  }
  if (isError) {
    return (
      <div>
        {EN.SORRY}
      </div>
    );
  }
  return null;
};

const Default = Loadable({
  loader: () => import('@containers/Default'),
  loading: LoadingComponent,
});

const notFound = Loadable({
  loader: () => import('@components/404NotFound'),
  loading: LoadingComponent,
});

const Login = Loadable({
  loader: () => import('@containers/Login'),
  loading: LoadingComponent,
});

const Register = Loadable({
  loader: () => import('@containers/Register'),
  loading: LoadingComponent,
});

const RegisterSuccess = Loadable({
  loader: () => import('@containers/Register/RegisterSuccess'),
  loading: LoadingComponent,
});

const RegisterVerify = Loadable({
  loader: () => import('@containers/Register/RegisterVerify'),
  loading: LoadingComponent,
});

const Logout = Loadable({
  loader: () => import('@containers/Logout'),
  loading: LoadingComponent,
});

const gamifyTemplates = Loadable({
  loader: () => import('@containers/GamifyTemplates'),
  loading: LoadingComponent,
});

const offerManager = Loadable({
  loader: () => import('@containers/OfferManager'),
  loading: LoadingComponent,
});

const megaWin = Loadable({
  loader: () => import('@containers/SpinWheel'),
  loading: LoadingComponent,
});

const SpinTheWheel = Loadable({
  loader: () => import('@containers/SpinTheWheel'),
  loading: LoadingComponent,
});

const OfferManager = Loadable({
  loader: () => import('@containers/OfferManager'),
  loading: LoadingComponent,
});

const ForgotPassword = Loadable({
  loader: () => import('@containers/ForgotPassword'),
  loading: LoadingComponent,
});

const ConfirmPassword = Loadable({
  loader: () => import('@containers/ConfirmPassword'),
  loading: LoadingComponent,
});

const RuleEngine = Loadable({
  loader: () => import('@containers/RuleEngine'),
  loading: LoadingComponent,
});

const QuestConfiguration = Loadable({
  loader: () => import('@containers/QuestConfiguration'),
  loading: LoadingComponent,
});

const MemoryGame = Loadable({
  loader: () => import('@containers/MemoryGame'),
  loading: LoadingComponent,
});

const Polling = Loadable({
  loader: () => import('@containers/PollingTemplate'),
  loading: LoadingComponent,
});

const Themes = Loadable({
  loader: () => import('@containers/Themes'),
  loading: LoadingComponent,
});
const ThemesT1 = Loadable({
  loader: () => import('@containers/ThemesT1'),
  loading: LoadingComponent,
});

const RuleGMList = Loadable({
  loader: () => import('@containers/RuleGMList'),
  loading: LoadingComponent,
});

const RuleCustomEventList = Loadable({
  loader: () => import('@containers/RuleCustomEventList'),
  loading: LoadingComponent,
});

const BadgeLibrary = Loadable({
  loader: () => import('@containers/BadgeLibrary'),
  loading: LoadingComponent,
});

const DailyStreak = Loadable({
  loader: () => import('@containers/DailyStreak'),
  loading: LoadingComponent,
});

const DataManagement = Loadable({
  loader: () => import('@containers/DataManagement'),
  loading: LoadingComponent,
});

const Reports = Loadable({
  loader: () => import('@containers/Reports'),
  loading: LoadingComponent,
});

const BadgesReports = Loadable({
  loader: () => import('@containers/BadgesReports'),
  loading: LoadingComponent,
});

const CampaignReports = Loadable({
  loader: () => import('@containers/CampaignReports'),
  loading: LoadingComponent,
});

const EventReports = Loadable({
  loader: () => import('@containers/EventReports'),
  loading: LoadingComponent,
});

const ReferralReports = Loadable({
  loader: () => import('@containers/ReferralReports'),
  loading: LoadingComponent,
});

const GameMechanics = Loadable({
  loader: () => import('@containers/GameMechanics'),
  loading: LoadingComponent,
});

const Badges = Loadable({
  loader: () => import('@containers/BadgeContainer'),
  loading: LoadingComponent,
});

const LeaderBoard = Loadable({
  loader: () => import('@containers/LeaderBoard'),
  loading: LoadingComponent,
});

const TournamentConfiguration = Loadable({
  loader: () => import('@containers/TournamentConfiguration'),
  loading: LoadingComponent,
});

const LeadGen = Loadable({
  loader: () => import('@containers/LeadGen'),
  loading: LoadingComponent,
});

const MyGameMechanics = Loadable({
  loader: () => import('@containers/MyGameMechanics'),
  loading: LoadingComponent,
});

const DailyStreakReports = Loadable({
  loader: () => import('@containers/DailyStreakReports'),
  loading: LoadingComponent,
});

const GameReports = Loadable({
  loader: () => import('@containers/GameReports'),
  loading: LoadingComponent,
});


const ScratchCard = Loadable({
  loader: () => import('@containers/ScratchCard'),
  loading: LoadingComponent,
});

const SuperQuiz = Loadable({
  loader: () => import('@containers/SuperQuiz'),
  loading: LoadingComponent,
});

const OpinionPoll = Loadable({
  loader: () => import('@containers/OpinionPoll'),
  loading: LoadingComponent,
});

const Referral = Loadable({
  loader: () => import('@containers/Referral'),
  loading: LoadingComponent,
});

// const CustomEvents = Loadable({
//   loader: () => import('@containers/CustomEvents'),
//   loading: LoadingComponent,
// });

const CustomEventsList = Loadable({
  loader: () => import('@containers/CustomEventsList'),
  loading: LoadingComponent,
});

const TreasureChest = Loadable({
  loader: () => import('@containers/TreasureChest'),
  loading: LoadingComponent,
});

const SlotMachine = Loadable({
  loader: () => import('@containers/SlotMachine'),
  loading: LoadingComponent,
});

const Games = Loadable({
  loader: () => import('@containers/Games'),
  loading: LoadingComponent,
});

const GameTemplate = Loadable({
  loader: () => import('@containers/GameTemplate'),
  loading: LoadingComponent,
});

const Welcome = Loadable({
  loader: () => import('@containers/WelcomeFirstTime'),
  loading: LoadingComponent,
});

const ReportsGraph = Loadable({
  loader: () => import('@containers/ReportsGraph'),
  loading: LoadingComponent,
});

const EditProfile = Loadable({
  loader: () => import('@containers/EditProfile'),
  loading: LoadingComponent,
});

const Campaign = Loadable({
  loader: () => import('@containers/Campaign'),
  loading: LoadingComponent,
});

const DemoWebsite = Loadable({
  loader: () => import('@containers/DemoWebsite'),
  loading: LoadingComponent,
});

const Webhook = Loadable({
  loader: () => import('@containers/Webhook'),
  loading: LoadingComponent,
});
const OfferReport = Loadable({
  loader: () => import('@containers/OfferReport'),
  loading: LoadingComponent,
});

const UserReports = Loadable({
  loader: () => import('@containers/UserReports'),
  loading: LoadingComponent,
});
const TossCoin = Loadable({
  loader: () => import('@containers/TossCoin'),
  loading: LoadingComponent,
});

const RollDice = Loadable({
  loader: () => import('@containers/RollDice'),
  loading: LoadingComponent,
});

const OfferManagerThirdPartyContainer = Loadable({
  loader: () => import('@containers/OfferManagerThirdPartyContainer'),
  loading: LoadingComponent,
});

const LeaderboardReports = Loadable({
  loader: () => import('@containers/LeaderboardReports'),
  loading: LoadingComponent,
});

const QuestReport = Loadable({
  loader: () => import('@containers/QuestReport'),
  loading: LoadingComponent,
});

const Tournament = Loadable({
  loader: () => import('@containers/Tournament'),
  loading: LoadingComponent,
});

const ProgressBarConfiguration = Loadable({
  loader: () => import('@containers/ProgressBarConfiguration'),
  loading: LoadingComponent,
});

const ProgressBar = Loadable({
  loader: () => import('@containers/ProgressBar'),
  loading: LoadingComponent,
});

const Notifications = Loadable({
  loader: () => import('@containers/Notifications'),
  loading: LoadingComponent,
});

const WelcomeTrialExpired = Loadable({
  loader: () => import('@containers/WelcomeTrialExpired'),
  loading: LoadingComponent,
});

const Pages = Loadable({
  loader: () => import('@containers/PagesConfiguration'),
  loading: LoadingComponent,
});

const WalletTransactions = Loadable({
  loader: () => import('@containers/WalletTransactions'),
  loading: LoadingComponent,
});

const CustomEventTemplate = Loadable({
  loader: () => import('@containers/CustomEventTemplate'),
  loading: LoadingComponent,
});

const CampaignTypeSelect = Loadable({
  loader: () => import('@containers/CampaignTypeSelect'),
  loading: LoadingComponent,
});

const SmartCampaignsList = Loadable({
  loader: () => import('@containers/SmartCampaignsList'),
  loading: LoadingComponent,
});

const SmartCampaignsConfiguration = Loadable({
  loader: () => import('@containers/SmartCampaignsConfiguration'),
  loading: LoadingComponent,
});

// const QuestSectionsTemplate = Loadable({
//   loader: () => import('@containers/QuestSectionsTemplate'),
//   loading: LoadingComponent,
// });

const RouteDom = (
  <Router>
    <Fragment>
      <App>
        <OnRouteChange>
          {/* <div> */}
          <Switch>
            <Route exact path={`${APP_CONFIG.BASE_URL}/`} component={Login} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/login`} component={Login} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/register`} component={Register} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/users/signup/successful`} component={RegisterSuccess} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/users/activate/:surrogateId`} component={RegisterVerify} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/logout`} component={Logout} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/game/invalid/user`} component={Default} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/forgotpassword`} component={ForgotPassword} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/confirmresetpassword/:id`} component={ConfirmPassword} />
            <PrivateRoute
              key="welcome"
              exact
              path={`${APP_CONFIG.BASE_URL}/welcome`}
              component={Welcome}
            />
            <PrivateRoute
              key="reportsgraph"
              exact
              path={`${APP_CONFIG.BASE_URL}/dashboard`}
              component={ReportsGraph}
            />
            <PrivateRoute
              key="editprofile"
              exact
              path={`${APP_CONFIG.BASE_URL}/editprofile`}
              component={EditProfile}
            />
            <PrivateRoute
              key="gamify_engagment_templates"
              exact
              path={`${APP_CONFIG.BASE_URL}/`}
              component={gamifyTemplates}
            />
            <PrivateRoute
              key="gamify_engagment_templates"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates`}
              component={gamifyTemplates}
            />
            <PrivateRoute
              key="gamify_engagment_spin_wheel"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/megawin/:id`}
              component={megaWin}
            />
            <PrivateRoute
              key="gamify_engagment_scratch_card"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/scratchcard/:id`}
              component={ScratchCard}
            />
            <PrivateRoute
              key="gamify_engagment_treasure_chest"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/treasurechest/:id`}
              component={TreasureChest}
            />
            <PrivateRoute
              key="gamify_engagment_slot_machine"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/slotmachine/:id`}
              component={SlotMachine}
            />
            <PrivateRoute
              key="offer_manager"
              exact
              path={`${APP_CONFIG.BASE_URL}/offermanager`}
              component={offerManager}
            />
            <PrivateRoute
              key="spinTheWheel"
              exact
              path={`${APP_CONFIG.BASE_URL}/spinTheWheel`}
              component={SpinTheWheel}
            />
            <PrivateRoute
              key="games"
              exact
              path={`${APP_CONFIG.BASE_URL}/games`}
              component={Games}
            />
            <PrivateRoute
              key="gameMechanichs"
              exact
              path={`${APP_CONFIG.BASE_URL}/gameMechanichs`}
              component={gamifyTemplates}
            />
            <PrivateRoute
              key="offer_management"
              exact
              path={`${APP_CONFIG.BASE_URL}/offermanagement`}
              component={OfferManager}
            />
            <PrivateRoute
              key="rule_engine"
              exact
              path={`${APP_CONFIG.BASE_URL}/ruleengine`}
              component={RuleEngine}
            />
            <PrivateRoute
              key="questRule"
              exact
              path={`${APP_CONFIG.BASE_URL}/questRule`}
              component={QuestConfiguration}
            />
            <PrivateRoute
              key="questRule"
              exact
              path={`${APP_CONFIG.BASE_URL}/questRule/edit/:id`}
              component={QuestConfiguration}
            />
            <PrivateRoute
              key="memorygame"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/memorygame`}
              component={MemoryGame}
            />
            <PrivateRoute
              key="memorygame_edit"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/memorygame/edit/:id`}
              component={MemoryGame}
            />
            <PrivateRoute
              key="Themes"
              exact
              path={`${APP_CONFIG.BASE_URL}/themes`}
              component={Themes}
            />
            <PrivateRoute
              key="ThemesT1"
              exact
              path={`${APP_CONFIG.BASE_URL}/themes/t1/edit/:id`}
              component={ThemesT1}
            />
            <PrivateRoute
              key="ThemesT1"
              exact
              path={`${APP_CONFIG.BASE_URL}/themes/t1`}
              component={ThemesT1}
            />
            <PrivateRoute
              key="ThemesT1"
              exact
              path={`${APP_CONFIG.BASE_URL}/themes/t2/edit/:id`}
              component={ThemesT1}
            />
            <PrivateRoute
              key="ThemesT1"
              exact
              path={`${APP_CONFIG.BASE_URL}/themes/t2`}
              component={ThemesT1}
            />
            <PrivateRoute
              key="ThemesT1"
              exact
              path={`${APP_CONFIG.BASE_URL}/themes/t3/edit/:id`}
              component={ThemesT1}
            />
            <PrivateRoute
              key="ThemesT1"
              exact
              path={`${APP_CONFIG.BASE_URL}/themes/t3`}
              component={ThemesT1}
            />
            <PrivateRoute
              key="ThemesT1"
              exact
              path={`${APP_CONFIG.BASE_URL}/themes/t4/edit/:id`}
              component={ThemesT1}
            />
            <PrivateRoute
              key="ThemesT1"
              exact
              path={`${APP_CONFIG.BASE_URL}/themes/t4`}
              component={ThemesT1}
            />
            <PrivateRoute
              key="ThemesT1"
              exact
              path={`${APP_CONFIG.BASE_URL}/themes/t5/edit/:id`}
              component={ThemesT1}
            />
            <PrivateRoute
              key="ThemesT1"
              exact
              path={`${APP_CONFIG.BASE_URL}/themes/t5`}
              component={ThemesT1}
            />
            <PrivateRoute
              key="ThemesT1"
              exact
              path={`${APP_CONFIG.BASE_URL}/themes/t6/edit/:id`}
              component={ThemesT1}
            />
            <PrivateRoute
              key="ThemesT1"
              exact
              path={`${APP_CONFIG.BASE_URL}/themes/t6`}
              component={ThemesT1}
            />
            <PrivateRoute
              key="ThemesT1"
              exact
              path={`${APP_CONFIG.BASE_URL}/themes/t7/edit/:id`}
              component={ThemesT1}
            />
            <PrivateRoute
              key="ThemesT1"
              exact
              path={`${APP_CONFIG.BASE_URL}/themes/t7`}
              component={ThemesT1}
            />
            <PrivateRoute
              key="ThemesT1"
              exact
              path={`${APP_CONFIG.BASE_URL}/themes/t8/edit/:id`}
              component={ThemesT1}
            />
            <PrivateRoute
              key="ThemesT1"
              exact
              path={`${APP_CONFIG.BASE_URL}/themes/t8`}
              component={ThemesT1}
            />
            <PrivateRoute
              key="polling"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/polling`}
              component={Polling}
            />
            <PrivateRoute
              key="polling_edit"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/polling/edit/:id`}
              component={Polling}
            />
            <PrivateRoute
              key="rulegmlist"
              exact
              path={`${APP_CONFIG.BASE_URL}/gamemechanicsrule`}
              component={RuleGMList}
            />
            <PrivateRoute
              key="rulegmlist"
              exact
              path={`${APP_CONFIG.BASE_URL}/customeventsrule`}
              component={RuleCustomEventList}
            />
            <PrivateRoute
              key="badge_library"
              exact
              path={`${APP_CONFIG.BASE_URL}/badgelibrary`}
              component={BadgeLibrary}
            />
            <PrivateRoute
              key="daily_streak"
              exact
              path={`${APP_CONFIG.BASE_URL}/dailystreak`}
              component={DailyStreak}
            />
            <PrivateRoute
              key="data_management"
              exact
              path={`${APP_CONFIG.BASE_URL}/datamanagement`}
              component={DataManagement}
            />
            <PrivateRoute
              key="gamify_engagment_spin_wheel_edit"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/megawin/edit/:id`}
              component={megaWin}
            />
            <PrivateRoute
              key="reports"
              exact
              path={`${APP_CONFIG.BASE_URL}/reports`}
              component={Reports}
            />
            <PrivateRoute
              key="gamemechanics"
              exact
              path={`${APP_CONFIG.BASE_URL}/gamemechanics`}
              component={GameMechanics}
            />
            <PrivateRoute
              key="badges"
              exact
              path={`${APP_CONFIG.BASE_URL}/gamemechanics/badges`}
              component={Badges}
            />
            <PrivateRoute
              key="mygamemechanics"
              exact
              path={`${APP_CONFIG.BASE_URL}/gamemechanics/mygamemechanics`}
              component={MyGameMechanics}
            />
            <PrivateRoute
              key="badges"
              exact
              path={`${APP_CONFIG.BASE_URL}/gamemechanics/badges/edit/:id`}
              component={Badges}
            />
            <PrivateRoute
              key="leaderboard"
              exact
              path={`${APP_CONFIG.BASE_URL}/gamemechanics/leaderboard/:id`}
              component={LeaderBoard}
            />
            <PrivateRoute
              key="tournament"
              exact
              path={`${APP_CONFIG.BASE_URL}/tournament`}
              component={TournamentConfiguration}
            />
            <PrivateRoute
              key="LeadGen"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/leadGeneration`}
              component={LeadGen}
            />
            <PrivateRoute
              key="LeadGen"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/leadGeneration/edit/:id`}
              component={LeadGen}
            />
            <PrivateRoute
              key="badgesreports"
              exact
              path={`${APP_CONFIG.BASE_URL}/badgesreports`}
              component={BadgesReports}
            />
            <PrivateRoute
              key="campaignreports"
              exact
              path={`${APP_CONFIG.BASE_URL}/campaignreports`}
              component={CampaignReports}
            />
            <PrivateRoute
              key="eventreports"
              exact
              path={`${APP_CONFIG.BASE_URL}/eventreports`}
              component={EventReports}
            />
            <PrivateRoute
              key="referralreports"
              exact
              path={`${APP_CONFIG.BASE_URL}/referralreports`}
              component={ReferralReports}
            />
            <PrivateRoute
              key="dailystreakreports"
              exact
              path={`${APP_CONFIG.BASE_URL}/dailystreakreports`}
              component={DailyStreakReports}
            />
            <PrivateRoute
              key="gamereports"
              exact
              path={`${APP_CONFIG.BASE_URL}/gamereports`}
              component={GameReports}
            />
            <PrivateRoute
              key="gamify_engagment_scratch_card_edit"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/scratchcard/edit/:id`}
              component={ScratchCard}
            />
            <PrivateRoute
              key="gamify_engagment_treasure_chest"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/treasurechest/edit/:id`}
              component={TreasureChest}
            />
            <PrivateRoute
              key="games_template"
              exact
              path={`${APP_CONFIG.BASE_URL}/games/:id`}
              component={GameTemplate}
            />
            <PrivateRoute
              key="gamify_engagment_slot_machine_edit"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/slotmachine/edit/:id`}
              component={SlotMachine}
            />
            <PrivateRoute
              key="gamify_engagment_super_quiz"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/superquiz/:id`}
              component={SuperQuiz}
            />
            <PrivateRoute
              key="gamify_engagment_super_quiz_edit"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/superquiz/edit/:id`}
              component={SuperQuiz}
            />
            <PrivateRoute
              key="games_template_edit"
              exact
              path={`${APP_CONFIG.BASE_URL}/games/edit/:id`}
              component={GameTemplate}
            />
            <PrivateRoute
              key="gamify_engagment_opinion_poll"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/opinionpoll`}
              component={OpinionPoll}
            />
            <PrivateRoute
              key="gamify_engagment_opinion_poll_edit"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/opinionpoll/edit/:id`}
              component={OpinionPoll}
            />
            <PrivateRoute
              key="gamify_engagment_referral"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/referral`}
              component={Referral}
            />
            <PrivateRoute
              key="gamify_engagment_customEvent"
              exact
              path={`${APP_CONFIG.BASE_URL}/customEvents`}
              component={CustomEventsList}
            />
            <PrivateRoute
              key="gamify_engagment_referral_edit"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/referral/edit/:id`}
              component={Referral}
            />
            <PrivateRoute
              key="campaign_message"
              exact
              path={`${APP_CONFIG.BASE_URL}/message`}
              component={Campaign}
            />
            <PrivateRoute
              key="campaign_email"
              exact
              path={`${APP_CONFIG.BASE_URL}/email`}
              component={Campaign}
            />
            <PrivateRoute
              key="campaign_whatsApp"
              exact
              path={`${APP_CONFIG.BASE_URL}/whatsapp`}
              component={Campaign}
            />
            <PrivateRoute
              key="demo_website"
              exact
              path={`${APP_CONFIG.BASE_URL}/demowebsite`}
              component={DemoWebsite}
            />
            <PrivateRoute
              key="webhook"
              exact
              path={`${APP_CONFIG.BASE_URL}/webhook`}
              component={Webhook}
            />
            <PrivateRoute
              key="offer_reports"
              exact
              path={`${APP_CONFIG.BASE_URL}/offerreport`}
              component={OfferReport}
            />
            <PrivateRoute
              key="user_reports"
              exact
              path={`${APP_CONFIG.BASE_URL}/userreports`}
              component={UserReports}
            />
            <PrivateRoute
              key="toss_coin"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/tosscoin`}
              component={TossCoin}
            />
            <PrivateRoute
              key="roll_dice"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/rolldice`}
              component={RollDice}
            />
            <PrivateRoute
              key="gamify_engagment_roll_dice_edit"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/rolldice/edit/:id`}
              component={RollDice}
            />
            <PrivateRoute
              key="gamify_engagment_toss_coin_edit"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/tosscoin/edit/:id`}
              component={TossCoin}
            />
            <PrivateRoute
              key="offermanager_thirdparty"
              exact
              path={`${APP_CONFIG.BASE_URL}/offermanagerthirdparty`}
              component={OfferManagerThirdPartyContainer}
            />
            <PrivateRoute
              key="leaderboardreports"
              exact
              path={`${APP_CONFIG.BASE_URL}/leaderboardreports`}
              component={LeaderboardReports}
            />
            <PrivateRoute
              key="qustReport"
              exact
              path={`${APP_CONFIG.BASE_URL}/questReport`}
              component={QuestReport}
            />
            <PrivateRoute
              key="gamify_engagment_tournament"
              exact
              path={`${APP_CONFIG.BASE_URL}/tournament/:id`}
              component={Tournament}
            />
            <PrivateRoute
              key="gamify_engagment_tournament_edit"
              exact
              path={`${APP_CONFIG.BASE_URL}/tournament/edit/:id`}
              component={Tournament}
            />
            <PrivateRoute
              key="tournament"
              exact
              path={`${APP_CONFIG.BASE_URL}/progressBar`}
              component={ProgressBarConfiguration}
            />
            <PrivateRoute
              key="gamify_engagment_progressBar"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/progressBar/:id`}
              component={ProgressBar}
            />
            <PrivateRoute
              key="gamify_engagment_progressBar_edit"
              exact
              path={`${APP_CONFIG.BASE_URL}/templates/progressBar/edit/:id`}
              component={ProgressBar}
            />
            <PrivateRoute
              key="pushnotifications"
              exact
              path={`${APP_CONFIG.BASE_URL}/pushnotifications`}
              component={Notifications}
            />
            <PrivateRoute
              key="trialExpired"
              exact
              path={`${APP_CONFIG.BASE_URL}/trialExpired`}
              component={WelcomeTrialExpired}
            />
            <PrivateRoute
              key="pages"
              exact
              path={`${APP_CONFIG.BASE_URL}/pages`}
              component={Pages}
            />
            <PrivateRoute
              key="walletTransactions"
              exact
              path={`${APP_CONFIG.BASE_URL}/pages/walletTransactions`}
              component={WalletTransactions}
            />
            <PrivateRoute
              key="customEventTemplate"
              exact
              path={`${APP_CONFIG.BASE_URL}/pages/customEventTemplate`}
              component={CustomEventTemplate}
            />
            <PrivateRoute
              key="customEventTemplate"
              exact
              path={`${APP_CONFIG.BASE_URL}/pages/customEventTemplate/edit/:id`}
              component={CustomEventTemplate}
            />
            <PrivateRoute
              key="campaignTypeSelect"
              exact
              path={`${APP_CONFIG.BASE_URL}/campaignTypeSelect`}
              component={CampaignTypeSelect}
            />
            <PrivateRoute
              key="smartCampaignslist"
              exact
              path={`${APP_CONFIG.BASE_URL}/smartCampaignsList`}
              component={SmartCampaignsList}
            />
            <PrivateRoute
              key="smartCampaignsConfiguration"
              exact
              path={`${APP_CONFIG.BASE_URL}/smartCampaignsConfiguration`}
              component={SmartCampaignsConfiguration}
            />
            {/* <PrivateRoute
              key="questSections"
              exact
              path={`${APP_CONFIG.BASE_URL}/questSections`}
              component={QuestSectionsTemplate}
            />
            <PrivateRoute
              key="questSections"
              exact
              path={`${APP_CONFIG.BASE_URL}/questSections/edit/:id`}
              component={QuestSectionsTemplate}
            /> */}
            <Route path="*" component={notFound} />
          </Switch>
          {/* </div> */}
        </OnRouteChange>
      </App>
    </Fragment>
  </Router>
);

export default RouteDom;
