import { APP_CONFIG } from '@constants';

const upload = require('superagent');

export const UPDATE_TITLE_FIELD = 'scratchCardConfig/UPDATE_TITLE_FIELD';
export const UPDATE_SUBTITLE_FIELD = 'scratchCardConfig/UPDATE_SUBTITLE_FIELD';
export const UPDATE_BUTTON_FIELD = 'scratchCardConfig/UPDATE_BUTTON_FIELD';
export const UPDATE_CLOSE_BUTTON_FIELD = 'scratchCardConfig/UPDATE_CLOSE_BUTTON_FIELD';
export const UPDATE_BUTTON_FIELD_SCRATCH_CARD = 'scratchCardConfig/UPDATE_BUTTON_FIELD_SCRATCH_CARD';
export const UPDATE_BACKGROUND_FIELD = 'scratchCardConfig/UPDATE_BACKGROUND_FIELD';
export const UPDATE_LAYER_FIELD = 'scratchCardConfig/UPDATE_LAYER_FIELD';
export const UPDATE_APPCONFIG_FIELD = 'scratchCardConfig/UPDATE_APPCONFIG_FIELD';
export const UPDATE_BUTTON = 'scratchCardConfig/UPDATE_BUTTON';
export const UPDATE_GRATIFY_BACKGROUND_FIELD = 'scratchCardConfig/UPDATE_GRATIFY_BACKGROUND_FIELD';
export const UPDATE_CARDCONFIG = 'scratchCardConfig/UPDATE_CARDCONFIG';
export const UPLOAD_IMAGE_REQUESTED = 'upload/UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_IMAGE_SUCCESS = 'upload/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'upload/UPLOAD_IMAGE_FAILURE';

export const SAVE_CONFIG_REQUESTED = 'save/SAVE_CONFIG_REQUESTED';
export const SAVE_CONFIG_SUCCESS = 'save/SAVE_CONFIG_SUCCESS';
export const SAVE_CONFIG_FAILURE = 'save/SAVE_CONFIG_FAILURE';

export const UPDATE_CONFIG_REQUESTED = 'update/UPDATE_CONFIG_REQUESTED';
export const UPDATE_CONFIG_SUCCESS = 'update/UPDATE_CONFIG_SUCCESS';
export const UPDATE_CONFIG_FAILURE = 'update/UPDATE_CONFIG_FAILURE';

export const GET_CONFIG_REQUESTED = 'get/GET_CONFIG_REQUESTED';
export const GET_CONFIG_SUCCESS = 'get/GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'get/GET_CONFIG_FAILURE';

export const DUPLICATE_CONFIG_REQUESTED = 'get/DUPLICATE_CONFIG_REQUESTED';
export const DUPLICATE_CONFIG_SUCCESS = 'get/DUPLICATE_CONFIG_SUCCESS';
export const DUPLICATE_CONFIG_FAILURE = 'get/DUPLICATE_CONFIG_FAILURE';

export const UPDATE_FIELD = 'scratchCardConfig/UPDATE_FIELD';
export const UPDATE_PAGE = 'scratchCardConfig/UPDATE_PAGE';
export const UPDATE_LOCALIZATION_FLAG = 'scratchCardConfig/UPDATE_LOCALIZATION_FLAG';
export const UPDATE_ACTIVE_FLAG = 'scratchCardConfig/UPDATE_ACTIVE_FLAG';
export const UPDATE_CONFIG_FIELD = 'scratchCardConfig/UPDATE_CONFIG_FIELD';
export const REMOVE_CONFIG = 'scratchCardConfig/REMOVE_CONFIG';
export const CLEAR_STATE = 'scratchCardConfig/CLEAR_STATE';
export const UPDATE_BUTTON_CONFIG = 'scratchCardConfig/UPDATE_BUTTON_CONFIG';
export const UPDATE_QUESTIONS_FIELD = 'scratchCardConfig/UPDATE_QUESTIONS_FIELD';
export const UPDATE_COLLAPSE = 'scratchCardConfig/UPDATE_COLLAPSE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  initialValues: {
    fontTitlePositions: [],
    buttonFormatWidth: [],
    buttonFormatRadius: [],
    buttonFormatShadow: [],
    outlineStyles: [],
    spinButtonWidth: [],
    spinButtonRadius: [],
    spinButtonShadow: [],
    fonts: [],
    fontStrength: [],
    fontSize: [],
    scratchType: [],
    fontPositions: [],
    buttonAction: []
  },
  collapse: {
    backgroundContainer: false,
    titleContainer: false,
    scratchContainer: false,
    gameCont: false,
    gratifyTextConfig: false,
    resultCont: false,
    gratifyButtonConfig: false,
  },
  localizationFlag: '',
  activeNavTab: 'English',
  config: {
    localizationconfig: [
      {
        language: 'English',
        resultHandler: 'ParentApp',
        title: {
          text: '',
          color: '#000000',
          font: {
            size: '14',
            strength: '400',
            family: 'Arial, sans-serif',
            align: 'left',
          },
          position: 'flex-start',
          titleError: '',
          disableSave: false,
          templateName: {
            text: ''
          },
          templateError: ''
        },
        subtitle: {
          text: '',
          color: '#000000',
          font: {
            size: '14',
            strength: '400',
            family: 'Arial, sans-serif',
            align: 'left',
          },
          position: 'left',
          titleError: '',
        },
        cardConfig: {
          cardPairs: '2',
          noOfRows: '2',
          noOfCols: '4',
          backgroundColor: '#FFFCF0',
          borderColor: '#285A65',
          thickness: '1',
          radius: '10%',
          cardBacklayer: 'library',
          cardColor: '#032AA7',
          cardImg: '',
          cardImgName: '',
          cardImgError: '',
          cardLib: 'https://cdnstg.gamize.com/13/opinion/15/2919_cards.png',
          cardAudio: '',
          cardAudioName: '',
          cardAudioError: '',
        },
        background: {
          backgroundRadio: true,
          backgroundRadio1: true,
          color: '#ffffff',
          color1: '#ffffff',
          image: '',
          imageName: '',
          scratchCardBgImg: '',
          scratchCardBgImgName: '',
          backgroundError: '',
          showProgressBar: false,
          winningText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: '14',
              strength: '400',
              family: 'Arial, sans-serif',
              align: 'left',
            },
            error: '',
            errorImg: ''
          },
          losingText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: '14',
              strength: '400',
              family: 'Arial, sans-serif',
              align: 'left',
            },
            error: '',
            errorImg: ''
          },
          scratchCardText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: '14',
              strength: '400',
              family: 'Arial, sans-serif',
              align: 'left',
            },
            error: '',
            errorImg: ''
          }
        },
        buttonNeeded: true,
        buttonConfig: [
          {
            text: {
              text: '',
              color: '#000000',
              font: {
                size: '14',
                strength: '400',
                family: 'Arial, sans-serif',
                align: 'left',
              },
            },
            textColor: '#000000',
            image: {
              url: '',
              name: ''
            },
            imageError: '',
            width: 'auto',
            radius: '30px',
            shadow: '0px 0px',
            shadowColor: '#cdcdcd',
            position: 'left',
            bgColor: '#ffffff',
            border: '#000000',
            hoverBgColor: '#cdcdcd',
            buttonTextError: '',
            borderWidth: '1px',
            buttonFill: '#000000',
            appLink: {
              text: ''
            },
            webLink: {
              text: ''
            },
            appLinkError: '',
            webLinkError: ''
          }
        ],
        scratchLayer: {
          outerColor: '#032AA7',
          innerColor: '#032AA7',
          image: '',
          type: 'tap',
          description: '',
          confettiImage: ''
        },
        buttonFormat: {
          text: {
            text: 'Copy to clipboard',
            color: '#000000',
            font: {
              size: '14',
              strength: '400',
              family: 'Arial, sans-serif',
              align: 'center',
            },
          },
          textColor: '#000000',
          image: '',
          imageName: '',
          width: 'auto',
          radius: '30px',
          shadow: '0px 0px',
          shadowColor: '#cdcdcd',
          position: 'center',
          bgColor: '#ffffff',
          border: '#000000',
          hoverBgColor: '#cdcdcd',
          buttonTextError: '',
          borderWidth: '1px',
          actionButton: 'true'
        },
        closeButtonNeeded: false,
        closeButton: {
          text: {
            text: 'Close',
            color: '#000000',
            font: {
              size: '14',
              strength: '400',
              family: 'Arial, sans-serif',
              align: 'center',
            },
          },
          image: '',
          imageName: '',
          width: 'auto',
          radius: '30px',
          shadow: '0px 0px',
          shadowColor: '#cdcdcd',
          position: 'center',
          bgColor: '#ffffff',
          border: '#000000',
          hoverBgColor: '#cdcdcd',
          buttonTextError: '',
          borderWidth: '1px',
          actionButton: 'true',
          webLink: '',
          appLink: ''
        },
        buttonFormatScratchCard: {
          text: {
            text: 'Copy to clipboard',
            color: '#000000',
            font: {
              size: '14',
              strength: '400',
              family: 'Arial, sans-serif',
              align: 'center',
            },
          },
          textColor: '#000000',
          image: '',
          imageName: '',
          width: 'auto',
          radius: '30px',
          shadow: '0px 0px',
          shadowColor: '#cdcdcd',
          outline: '0px 0px',
          outlineColor: '#cdcdcd',
          position: 'center',
          bgColor: '#ffffff',
          border: '#000000',
          hoverBgColor: '#cdcdcd',
          buttonTextError: '',
          borderWidth: '1px',
          actionButton: 'true'
        },
        gratifyBackground: {
          backgroundRadio: true,
          backgroundRadio1: true,
          bgColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#ffffff' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.primaryColour,
          bgImgUrl: null,
          bgImgName: null,
          color1: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#ffffff' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.primaryColour,
          color2: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#ffffff' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.primaryColour,
          iconposition: 'left',
          iconImage: null,
          iconImageName: null,
          backgroundError: '',
        },
      }
    ]
  },
  logo: '',
  logoPosition: 'center',
  shape: 'square',
  size: 'medium',
  type: 'scratch_half',
  scratchWidth: 300,
  scratchHeight: 300,
  scratchBG: true,
  scratchColor: '#032AA7',
  scratchBGImage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPLOAD_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case SAVE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DUPLICATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case DUPLICATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DUPLICATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_FIELD: {
      state = state
        ? {
          ...state,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_TITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].title = state.config.localizationconfig[i].title
            ? {
              ...state.config.localizationconfig[i].title,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_SUBTITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].subtitle = state.config.localizationconfig[i].subtitle
            ? {
              ...state.config.localizationconfig[i].subtitle,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BACKGROUND_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].background = state.config.localizationconfig[i].background
            ? {
              ...state.config.localizationconfig[i].background,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_LAYER_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].scratchLayer = state.config.localizationconfig[i].scratchLayer
            ? {
              ...state.config.localizationconfig[i].scratchLayer,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BUTTON_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].buttonFormat = state.config.localizationconfig[i].buttonFormat
            ? {
              ...state.config.localizationconfig[i].buttonFormat,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_CLOSE_BUTTON_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].closeButton = state.config.localizationconfig[i].closeButton
            ? {
              ...state.config.localizationconfig[i].closeButton,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BUTTON_FIELD_SCRATCH_CARD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].buttonFormatScratchCard = state.config.localizationconfig[i].buttonFormatScratchCard
            ? {
              ...state.config.localizationconfig[i].buttonFormatScratchCard,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BUTTON_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_QUESTIONS_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BUTTON: {
      console.log('ssssss', action.payload);
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_APPCONFIG_FIELD: {
      state.initialValues = state.initialValues
        ? {
          ...state.initialValues,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_LOCALIZATION_FLAG: {
      return { ...state, localizationFlag: action.payload };
    }
    case UPDATE_ACTIVE_FLAG: {
      return { ...state, activeNavTab: action.payload };
    }
    case UPDATE_CONFIG_FIELD: {
      const localizationconfig = [...state.config.localizationconfig, ...action.payload.localizationconfig];
      const config = {
        ...state.config,
        localizationconfig
      };
      return { ...state, config };
    }
    case REMOVE_CONFIG: {
      return { ...state, config: action.payload };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case UPDATE_COLLAPSE: {
      state.collapse = state.collapse
        ? {
          ...state.collapse,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_GRATIFY_BACKGROUND_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].gratifyBackground = state.config.localizationconfig[i].gratifyBackground
            ? {
              ...state.config.localizationconfig[i].gratifyBackground,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_CARDCONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].cardConfig = state.config.localizationconfig[i].cardConfig
            ? {
              ...state.config.localizationconfig[i].cardConfig,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    default:
      return state;
  }
};

export const updateTitle = (payload) => {
  return {
    type: UPDATE_TITLE_FIELD,
    payload,
  };
};

export const updateSubTitle = (payload) => {
  return {
    type: UPDATE_SUBTITLE_FIELD,
    payload,
  };
};

export const updateButtonFormat = (payload) => {
  return {
    type: UPDATE_BUTTON_FIELD,
    payload,
  };
};

export const updateCloseButtonFormat = (payload) => {
  return {
    type: UPDATE_CLOSE_BUTTON_FIELD,
    payload,
  };
};

export const updateButtonFormatScratchCard = (payload) => {
  return {
    type: UPDATE_BUTTON_FIELD_SCRATCH_CARD,
    payload,
  };
};

export const updateBackground = (payload) => {
  return {
    type: UPDATE_BACKGROUND_FIELD,
    payload,
  };
};
export const updateLayer = (payload) => {
  return {
    type: UPDATE_LAYER_FIELD,
    payload,
  };
};

export const uploadimage = (file, folderName) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/gamification/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
      .field('folderName', folderName)
  };
};
// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const saveScratchCardConfig = (data) => {
  return {
    types: [SAVE_CONFIG_REQUESTED, SAVE_CONFIG_SUCCESS, SAVE_CONFIG_FAILURE],
    promise: client => client.post('saveScratchCardConfig', {
      data,
    }),
  };
};

export const editScratchCardConfig = (data, gamificationId) => {
  return {
    types: [UPDATE_CONFIG_REQUESTED, UPDATE_CONFIG_SUCCESS, UPDATE_CONFIG_FAILURE],
    promise: client => client.post(`saveScratchCardConfig?gamificationId=${gamificationId}`, {
      data,
    }),
  };
};

export const getScratchCardConfig = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getSpinWheel?gamificationId=${data}`),
  };
};

export const duplicateScratchCardTitle = (data, type, id) => {
  return {
    types: [DUPLICATE_CONFIG_REQUESTED, DUPLICATE_CONFIG_SUCCESS, DUPLICATE_CONFIG_FAILURE],
    promise: client => client.get(`duplicateSpinWheelTitle?title=${data}&type=${type}&id=${id}`)
  };
};

export const getTemplate = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getTemplate?templateId=${data}`),
  };
};

export const updateDropdown = (payload) => {
  return {
    type: UPDATE_APPCONFIG_FIELD,
    payload,
  };
};

export const getAppConfig = () => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get('getAppConfig'),
  };
};

export const updateLocalizationFlag = (payload) => {
  return {
    type: UPDATE_LOCALIZATION_FLAG,
    payload,
  };
};


export const updateActiveFlag = (payload) => {
  return {
    type: UPDATE_ACTIVE_FLAG,
    payload,
  };
};

export const updateConfig = (payload) => {
  return {
    type: UPDATE_CONFIG_FIELD,
    payload
  };
};

export const removeConfig = (payload) => {
  return {
    type: REMOVE_CONFIG,
    payload
  };
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const updateButton = (payload) => {
  return {
    type: UPDATE_BUTTON,
    payload
  };
};

export const updateButtonConfig = (payload) => {
  return {
    type: UPDATE_BUTTON_CONFIG,
    payload
  };
};


export const updateQuestions = (payload) => {
  return {
    type: UPDATE_QUESTIONS_FIELD,
    payload,
  };
};

export const updateCollapse = (payload) => {
  return {
    type: UPDATE_COLLAPSE,
    payload
  };
};

export const updateGratifyBackground = (payload) => {
  return {
    type: UPDATE_GRATIFY_BACKGROUND_FIELD,
    payload,
  };
};

export const updateCardConfig = (payload) => {
  return {
    type: UPDATE_CARDCONFIG,
    payload
  };
};
