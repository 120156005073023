export const LOGIN_REQUESTED = 'login/LOGIN_REQUESTED';
export const LOGIN_SUCCESS = 'login/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'login/LOGIN_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorDesc: [],
  data: [],
  code: '',
  msg: '',
  status: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case LOGIN_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        data: action && action.result && action.result.userDetails,
        code: action && action.result && action.result.code,
        msg: action && action.result && action.result.msg,
        status: action && action.result && action.result.status
      };
    }
    case LOGIN_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        code: action && action.result && action.result.code,
        msg: action && action.result && action.result.msg,
        errorDesc: action && action.error && action.error.response.data,
        status: action && action.result && action.result.status
      };
    }
    default:
      return state;
  }
};

export const loginCall = (data) => {
  return {
    types: [LOGIN_REQUESTED, LOGIN_SUCCESS, LOGIN_FAILURE],
    promise: client => client.post('loginapi', {
      data
    })
  };
};
