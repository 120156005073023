import { CONSTANTS } from '@constants';

export const REQUESTED = 'REQUESTED';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export const GET_CAMPAIGN_REPORT_REQUESTED = 'get/GET_CAMPAIGN_REPORT_REQUESTED';
export const GET_CAMPAIGN_REPORT_SUCCESS = 'get/GET_CAMPAIGN_REPORT_SUCCESS';
export const GET_CAMPAIGN_REPORT_FAILURE = 'get/GET_CAMPAIGN_REPORT_FAILURE';

export const UPDATE_CUSTOM_FIELD = 'campaignReport/UPDATE_CUSTOM_FIELD';
export const UPDATE_CUSTOM_PAGE = 'campaignReport/UPDATE_CUSTOM_PAGE';

export const UPDATE_FIELD = 'campaignReport/UPDATE_FIELD';
export const UPDATE_PAGE = 'campaignReport/UPDATE_PAGE';
export const CLEAR_STATE = 'campaignReport/CLEAR_STATE';

export const GET_SENDERLIST_REQUESTED = 'offerreport/GET_SENDERLIST_REQUESTED';
export const GET_SENDERLIST_SUCCESS = 'offerreport/GET_SENDERLIST_SUCCESS';
export const GET_SENDERLIST_FAILURE = 'offerreport/GET_SENDERLIST_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  tableData: {},
  initialData: {
    selectedReportIds: [],
    pageNo: 0,
    sortBy: 'updatedTS',
    orderBy: 'desc',
    activePage: 1,
    searchKey: '',
    searchCampaign: '',
    sendReport: '',
    selectedMethod: '',
    selectedPeriod: '',
    selectedPeriodFrom: '',
    selectedPeriodEnd: '',
    selectedPeriodYear: null,
    selectedPeriodMonth: null,
    selectedPeriodWeek: null,
    offerList: {
      headers: [
        {
          title: CONSTANTS.CAMPAIGN_NAME,
          slug: 'offer_name',
          isVisible: true,
          width: 150,
        },
        {
          title: CONSTANTS.TOTAL_OFFER,
          slug: 'totalOffer',
          center: true,
          isVisible: true,
          width: 110,
        },
        {
          title: CONSTANTS.TOTAL_OFFER_WON,
          slug: 'totalOfferWon',
          center: true,
          isVisible: true,
          width: 190,
        },
        {
          title: CONSTANTS.TOTAL_OFFER_REDEEM,
          slug: 'totalOfferRedeem',
          center: true,
          isVisible: true,
          width: 200,
        },
        {
          title: 'Actions',
          slug: 'actions',
          isVisible: false,
          width: 20,
        }
      ],
    },
    headers: []
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_REPORT_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_CAMPAIGN_REPORT_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_CAMPAIGN_REPORT_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case UPDATE_CUSTOM_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_CUSTOM_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const getCampaignReport = (data) => {
  return {
    types: [GET_CAMPAIGN_REPORT_REQUESTED, GET_CAMPAIGN_REPORT_SUCCESS, GET_CAMPAIGN_REPORT_FAILURE],
    promise: client => client.get(`getCampaignReportList/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&orderBy=${data.orderBy}&selectedFromDate=${data.selectedPeriodFrom}&selectedToDate=${data.selectedPeriodEnd}`)
  };
};

export const downloadCampaign = (data) => {
  return {
    types: [REQUESTED, SUCCESS, FAILURE],
    promise: client => client.get(`downloadCampaign?selectedPeriodFrom=${data.selectedPeriodFrom}&selectedPeriodEnd=${data.selectedPeriodEnd}&idList=${data.idList}`)
  };
};

// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const updatecustomFields = (payload) => {
  return {
    type: UPDATE_CUSTOM_FIELD,
    payload,
  };
};

export const updateCustomPage = (payload) => {
  return {
    type: UPDATE_CUSTOM_PAGE,
    payload,
  };
};

export const sendNowCampaignReport = (data) => {
  console.log('date8', data);
  return {
    types: [GET_SENDERLIST_REQUESTED, GET_SENDERLIST_SUCCESS, GET_SENDERLIST_FAILURE],
    promise: client => client.get(`sendNowOfferReport?selectedPeriodFrom=${data.selectedPeriodFrom}&selectedPeriodEnd=${data.selectedPeriodEnd}&emailList=${data.emailList}&idList=${data.idList}`)
  };
};

export const getReport = (data) => {
  console.log('getReport', data);
  if (!data.type) {
    data.type = 0;
  }
  return {
    types: [GET_CAMPAIGN_REPORT_REQUESTED, GET_CAMPAIGN_REPORT_SUCCESS, GET_CAMPAIGN_REPORT_FAILURE],
    promise: client => client.get(`getReport/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&orderBy=${data.orderBy}&selectedFromDate=${data.selectedPeriodFrom}&selectedToDate=${data.selectedPeriodEnd}&type=${data.type}`)
  };
};

export const downloadActivityReport = (data) => {
  return {
    types: [REQUESTED, SUCCESS, FAILURE],
    promise: client => client.get(`downloadActivityReport?selectedPeriodFrom=${data.selectedPeriodFrom}&selectedPeriodEnd=${data.selectedPeriodEnd}&ruleIdList=${data.ruleIdList}`)
  };
};
