import { APP_CONFIG } from '@constants';

const upload = require('superagent');

export const CLEAR_FORM = 'offermanager/CLEAR_FORM';
export const RESTORE_STATE = 'offermanager/RESTORE_STATE';
export const UPDATE_FIELD = 'offermanager/UPDATE_FIELD';
export const REMOVE_FIELD = 'offermanager/UPDATE_FIELD';
export const ADD_COIN_FIELD = 'offermanager/ADD_COIN_FIELD';
export const ADD_VOUCHER_FIELD = 'offermanager/ADD_VOUCHER_FIELD';
export const UPDATE_WEBHOOK_FIELD = 'offermanager/UPDATE_WEBHOOK_FIELD';

export const OFFERMANAGER_SAVE_REQUESTED = 'offermanager/OFFERMANAGER_SAVE_REQUESTED';
export const OFFERMANAGER_SAVE_SUCCESS = 'offermanager/OFFERMANAGER_SAVE_SUCCESS';
export const OFFERMANAGER_SAVE_FAILURE = 'offermanager/OFFERMANAGER_SAVE_FAILURE';

export const OFFERMANAGER_GET_REQUESTED = 'offermanager/OFFERMANAGER_GET_REQUESTED';
export const OFFERMANAGER_GET_SUCCESS = 'offermanager/OFFERMANAGER_GET_SUCCESS';
export const OFFERMANAGER_GET_FAILURE = 'offermanager/OFFERMANAGER_GET_FAILURE';

export const OFFERMANAGER_FILE_REQUESTED = 'offermanager/OFFERMANAGER_FILE_REQUESTED';
export const OFFERMANAGER_FILE_SUCCESS = 'offermanager/OFFERMANAGER_FILE_SUCCESS';
export const OFFERMANAGER_FILE_FAILURE = 'offermanager/OFFERMANAGER_FILE_FAILURE';

export const CHECK_OFFER_NAME_REQUEST = 'CHECK_OFFER_NAME_REQUEST';
export const CHECK_OFFER_NAME_SUCCESS = 'CHECK_OFFER_NAME_SUCCESS';
export const CHECK_OFFER_NAME_FAILURE = 'CHECK_OFFER_NAME_FAILURE';

export const UPLOAD_IMAGE_REQUESTED = 'upload/UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_IMAGE_SUCCESS = 'upload/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'upload/UPLOAD_IMAGE_FAILURE';

export const GET_WEBHOOK_REQUESTED = 'offermanager/GET_WEBHOOK_REQUESTED';
export const GET_WEBHOOK_SUCCESS = 'offermanager/GET_WEBHOOK_SUCCESS';
export const GET_WEBHOOK_FAILURE = 'offermanager/GET_WEBHOOK_FAILURE';

export const UPDATE_LANGUAGE = 'offermanager/UPDATE_LANGUAGE';
export const UPDATE_CONFIG_FIELD = 'offermanager/UPDATE_CONFIG_FIELD';
export const UPDATE_LOCALIZATION_FLAG = 'offermanager/UPDATE_LOCALIZATION_FLAG';
export const UPDATE_ACTIVE_FLAG = 'offermanager/UPDATE_ACTIVE_FLAG';
export const REMOVE_CONFIG = 'offermanager/REMOVE_CONFIG';

export const UPDATE_APPCONFIG_FIELD = 'offermanager/UPDATE_APPCONFIG_FIELD';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorDesc: [],
  code: '',
  msg: '',
  status: '',
  webhookList: [],
  webhookPlaceholders: [],
  localizationFlag: '',
  activeNavTab: 'English',
  languages: [
    'English'
  ],
  fonts: [],
  configureOffer: {
    localizationconfig: [
      {
        language: 'English',
        mode: 'save',
        offerStatus: 'active',
        rewardType: 1,
        voucherType: 'static',
        offerName: '',
        offerDesc: '',
        howToRedeem: '',
        startDateTime: new Date().getTime(),
        endDateTime: new Date().getTime(),
        file: '',
        fileName: 'Paste your code/ attach .xls file',
        webHookRequired: true,
        webhookId: '',
        webhookIdError: '',
        extraParameterRequired: true,
        extraParameter: [],
        extraParameterError: '',
        offerVoucher: [
          {
            voucher: '',
            amount: '',
            currency: 'inr',
            count: '',
          },
        ],
        offerCoins: [
          {
            coins: '',
            count: '',
            points: '',
          }
        ],
        customTime: '',
        customType: 'day',
        customDays: '',
        dayOrTime: 'day',
        rewardCap: '',
        isRewardCapping: false,
      }
    ]
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_FORM: {
      return {
        ...initialState
      };
    }
    // case UPDATE_FIELD: {
    //   const configureOffer = {
    //     ...state.configureOffer,
    //     ...action.payload
    //   };
    //   return {
    //     ...state,
    //     configureOffer
    //   };
    // }

    case UPDATE_FIELD: {
      for (let i = 0; i < state.configureOffer.localizationconfig.length; i += 1) {
        if (state.configureOffer.localizationconfig[i].language === action.payload.language) {
          state.configureOffer.localizationconfig[i] = state.configureOffer.localizationconfig[i]
            ? {
              ...state.configureOffer.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }

    case UPDATE_WEBHOOK_FIELD: {
      for (let i = 0; i < state.configureOffer.localizationconfig.length; i += 1) {
        state.configureOffer.localizationconfig[i] = state.configureOffer.localizationconfig[i]
          ? {
            ...state.configureOffer.localizationconfig[i],
            ...action.payload,
          }
          : { ...action.payload };
      }
      return { ...state };
    }

    case REMOVE_FIELD: {
      return { ...state };
    }

    case ADD_COIN_FIELD: {
      const newObj = initialState.configureOffer.offerCoins[0];
      const offerCoins = [...state.configureOffer.offerCoins, newObj];
      const obj = {
        ...state.configureOffer,
        offerCoins
      };
      return { ...state, configureOffer: obj };
    }

    case ADD_VOUCHER_FIELD: {
      const newObj = initialState.configureOffer.offerVoucher[0];
      const offerVoucher = [...state.configureOffer.offerVoucher, newObj];
      const obj = {
        ...state.configureOffer,
        offerVoucher
      };
      return { ...state, configureOffer: obj };
    }

    case OFFERMANAGER_SAVE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case OFFERMANAGER_SAVE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case OFFERMANAGER_SAVE_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case OFFERMANAGER_GET_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case OFFERMANAGER_GET_SUCCESS:
    {
      let configureOffer = {
        ...action.result.offer
      };
      const localizationconfig = [];
      const languages = [];
      let offerCoins = [{
        coins: ''
      }];
      const extraParameter = [];
      let offerVoucher = [
        {
          voucher: '',
          amount: '',
          currency: 'inr',
          count: '',
        },
      ];
      if (configureOffer.rewardType === 1 || configureOffer.rewardType === 3) {
        offerCoins = configureOffer.reward.map((obj) => {
          return {
            coins: obj.coins,
            count: obj.count
          };
        });
      }
      if (configureOffer.rewardType === 2) {
        offerVoucher = configureOffer.reward.map((obj) => {
          return {
            count: obj.count,
            voucher: (configureOffer.voucherType === 'static') ? obj.voucher : '',
            amount: obj.amount,
            currency: obj.currency
          };
        });
      }
      if (configureOffer.rewardType === 5) {
        offerCoins = configureOffer.reward.map((obj) => {
          return {
            points: obj.points,
            count: obj.count
          };
        });
      }
      configureOffer = {
        ...state.configureOffer,
        ...configureOffer,
        offerCoins,
        offerVoucher,
        mode: 'edit',
        extraParameter: configureOffer.rewardType !== 1 && configureOffer.extraParameter && configureOffer.extraParameter.length === 0 ? extraParameter : configureOffer.extraParameter
      };
      for (let i = 0; i < configureOffer.offerLocalization.length; i += 1) {
        const data = {
          ...configureOffer,
          language: configureOffer.offerLocalization[i].language,
          offerDesc: configureOffer.offerLocalization[i].offerDesc,
          howToRedeem: configureOffer.offerLocalization[i].howToRedeem,
        };
        localizationconfig.push(data);
        languages.push(configureOffer.offerLocalization[i].language);
      }
      configureOffer = {
        ...state.configureOffer,
        localizationconfig
      };
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        configureOffer,
        languages
      };
    }
    case OFFERMANAGER_GET_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case OFFERMANAGER_FILE_REQUESTED:
    {
      return {
        ...state,
      };
    }
    case OFFERMANAGER_FILE_SUCCESS:
    {
      return {
        ...state,
      };
    }
    case OFFERMANAGER_FILE_FAILURE:
    {
      return {
        ...state,
        error: true,
      };
    }
    case UPLOAD_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPLOAD_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_WEBHOOK_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_WEBHOOK_SUCCESS: {
      const webhook = action.result.WebHookList.webHookEntity;
      const webhookOptions = [];
      const customPlaceHolders = [];
      for (let i = 0; i < webhook.length; i += 1) {
        webhookOptions.push({ title: webhook[i].name, value: webhook[i].id });
        const placeHoldersDefined = webhook[i].customPlaceHoldersDefined;
        const placeHolders = [];
        if (placeHoldersDefined) {
          for (let j = 0; j < webhook[i].customPlaceHolders.length; j += 1) {
            placeHolders.push(`#${webhook[i].customPlaceHolders[j]}`);
          }
        }
        customPlaceHolders.push({ placeHoldersDefined, placeHolders });
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        webhookList: webhookOptions,
        webhookPlaceholders: customPlaceHolders,
      };
    }
    case GET_WEBHOOK_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_LANGUAGE: {
      return { ...state, ...action.payload };
    }
    case UPDATE_CONFIG_FIELD: {
      const localizationconfig = [...state.configureOffer.localizationconfig, ...action.payload.localizationconfig];
      const configureOffer = {
        ...state.configureOffer,
        localizationconfig
      };
      return { ...state, configureOffer };
    }
    case UPDATE_LOCALIZATION_FLAG: {
      return { ...state, localizationFlag: action.payload };
    }
    case UPDATE_ACTIVE_FLAG: {
      return { ...state, activeNavTab: action.payload };
    }
    case REMOVE_CONFIG: {
      return { ...state, configureOffer: action.payload };
    }
    case UPDATE_APPCONFIG_FIELD: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};


// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updateWebhook = (payload) => {
  return {
    type: UPDATE_WEBHOOK_FIELD,
    payload,
  };
};

export const addTypedList = (field) => {
  if (field === 1) {
    return {
      type: ADD_COIN_FIELD,
      field,
    };
  }
  if (field === 2) {
    return {
      type: ADD_VOUCHER_FIELD,
      field,
    };
  }
  return {};
};

export const clearForm = () => {
  return {
    type: CLEAR_FORM,
  };
};

export const saveOfferManager = (file = null, payload) => {
  const data = new FormData();
  let voucherType = 'static';
  if (payload.voucherType === 'dynamic' && file) {
    data.append('file', file);
    voucherType = payload.voucherType;
  }
  data.append('payload', JSON.stringify(payload));
  return {
    types: [OFFERMANAGER_SAVE_REQUESTED, OFFERMANAGER_SAVE_SUCCESS, OFFERMANAGER_SAVE_FAILURE],
    promise: client => client.post(`saveOfferManager/${voucherType}`, {
      data
    })
  };
};

export const getOfferManger = (id) => {
  return {
    types: [OFFERMANAGER_GET_REQUESTED, OFFERMANAGER_GET_SUCCESS, OFFERMANAGER_GET_FAILURE],
    promise: client => client.get(`getOfferManager?id=${id}`)
  };
};

export const validateOfferFile = (file) => {
  const data = new FormData();
  data.append('file', file);
  return {
    types: [OFFERMANAGER_FILE_REQUESTED, OFFERMANAGER_FILE_SUCCESS, OFFERMANAGER_FILE_FAILURE],
    promise: client => client.post('validateOfferVoucher', { data })
  };
};

export const checkOfferNameDublicate = (name, id) => {
  return {
    types: [CHECK_OFFER_NAME_REQUEST, CHECK_OFFER_NAME_SUCCESS, CHECK_OFFER_NAME_FAILURE],
    promise: client => client.get(`checkOfferName?offerName=${name}&offerId=${id}`)
  };
};

export const uploadimage = (file, folderName) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/gamification/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
      .field('folderName', folderName)
  };
};

export const getWebhookList = (data) => {
  return {
    types: [GET_WEBHOOK_REQUESTED, GET_WEBHOOK_SUCCESS, GET_WEBHOOK_FAILURE],
    promise: client => client.get(
      `getWebhookList/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&sortType=${
        data.sortType
      }`
    ),
  };
};

export const updateLanguage = (payload) => {
  return {
    type: UPDATE_LANGUAGE,
    payload
  };
};

export const updateConfig = (payload) => {
  return {
    type: UPDATE_CONFIG_FIELD,
    payload
  };
};

export const updateActiveFlag = (payload) => {
  return {
    type: UPDATE_ACTIVE_FLAG,
    payload,
  };
};

export const updateLocalizationFlag = (payload) => {
  return {
    type: UPDATE_LOCALIZATION_FLAG,
    payload,
  };
};

export const removeConfig = (payload) => {
  return {
    type: REMOVE_CONFIG,
    payload
  };
};

export const getAppConfig = () => {
  return {
    types: [OFFERMANAGER_SAVE_REQUESTED, OFFERMANAGER_SAVE_SUCCESS, OFFERMANAGER_SAVE_FAILURE],
    promise: client => client.get('getAppConfig'),
  };
};

export const updateDropdown = (payload) => {
  return {
    type: UPDATE_APPCONFIG_FIELD,
    payload,
  };
};
