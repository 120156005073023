import { APP_CONFIG } from '@constants';
// import gamizeLogo from '@assets/images/gamizeLogo.svg';
// import fontOne from '@assets/images/font_1.png';
// import addImg from '@assets/images/add_color.svg';
import voucherKit from '@assets/images/voucher_brandkit.svg';
import closeGreyImg from '@assets/images/close_grey_img.svg';
import copyGreyImg from '@assets/images/copy_grey_img.svg';
import coinGold from '@assets/images/coinGold.svg';

const upload = require('superagent');

export const UPDATE_TEXTCONFIG = 'brandkit/UPDATE_TEXTCONFIG';
export const UPDATE_APPCONFIG_FIELD = 'brandkit/UPDATE_APPCONFIG_FIELD';
export const UPDATE_BUTTON_CONFIG = 'brandkit/UPDATE_BUTTON_CONFIG';


export const UPLOAD_IMAGE_REQUESTED = 'upload/UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_IMAGE_SUCCESS = 'upload/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'upload/UPLOAD_IMAGE_FAILURE';

export const SAVE_CONFIG_REQUESTED = 'save/SAVE_CONFIG_REQUESTED';
export const SAVE_CONFIG_SUCCESS = 'save/SAVE_CONFIG_SUCCESS';
export const SAVE_CONFIG_FAILURE = 'save/SAVE_CONFIG_FAILURE';

export const GET_CONFIG_REQUESTED = 'get/GET_CONFIG_REQUESTED';
export const GET_CONFIG_SUCCESS = 'get/GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'get/GET_CONFIG_FAILURE';

export const UPDATE_CONFIG_REQUESTED = 'update/UPDATE_CONFIG_REQUESTED';
export const UPDATE_CONFIG_SUCCESS = 'update/UPDATE_CONFIG_SUCCESS';
export const UPDATE_CONFIG_FAILURE = 'update/UPDATE_CONFIG_FAILURE';

export const DUPLICATE_CONFIG_REQUESTED = 'get/DUPLICATE_CONFIG_REQUESTED';
export const DUPLICATE_CONFIG_SUCCESS = 'get/DUPLICATE_CONFIG_SUCCESS';
export const DUPLICATE_CONFIG_FAILURE = 'get/DUPLICATE_CONFIG_FAILURE';

export const UPDATE_FIELD = 'brandkit/UPDATE_FIELD';
export const UPDATE_PAGE = 'brandkit/UPDATE_PAGE';
export const UPDATE_LOCALIZATION_FLAG = 'brandkit/UPDATE_LOCALIZATION_FLAG';
export const UPDATE_ACTIVE_FLAG = 'brandkit/UPDATE_ACTIVE_FLAG';
export const UPDATE_CONFIG_FIELD = 'brandkit/UPDATE_CONFIG_FIELD';
export const REMOVE_CONFIG = 'brandkit/REMOVE_CONFIG';
export const CLEAR_STATE = 'brandkit/CLEAR_STATE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  initialValues: {
    fontTitlePositions: [],
    questionType: [],
    quizQuestionImagePosition: [],
    opinionPollType: [],
    fonts: [],
    fontStrength: [],
    fontSize: [],
    spinButtonPosition: [],
    spinButtonWidth: [],
    spinButtonRadius: [],
    spinButtonShadow: [],
    fontPositions: [],
    outlineStyles: [],
  },
  activeNavTab: 'English',
  config: {
    localizationconfig: [
      {
        language: 'English',

        textConfig: {
          titleFontStyle: 'Outfit, sans-serif',
          titleFontWt: '400',
          titleFontSize: '32',
          subTitleFontStyle: 'Outfit, sans-serif',
          subTitleFontWt: '400',
          subTitleFontSize: '12',
          generalFontStyle: 'Outfit, sans-serif',
          generalFontWt: '400',
          generalFontSize: '16',
          buttonFontStyle: 'Outfit, sans-serif',
          buttonFontWt: '400',
          buttonFontSize: '16',
        },
        logosLibError: '',
        fontsLibError: '',
        colorslistLibError: '',
        iconLibraryError: '',
        iconLibError: '',
        currencyName: 'GCoins',
        iconIndexSelected: '0',
        coinsVoucherIndex: '1',
        brandLogoIndex: '0',

        logosLib: [
          // { imgUrl: gamizeLogo, imgName: 'gamizeLogo' },
        ],
        fontsLib: [
          // { fontUrl: fontOne, fontName: 'Montage' },
        ],
        colorslistLib: [
          { color: '#2731C3', title: 'Primary' },
          { color: '#FFFFFF', title: 'Font' },
          { color: '#FE9B2E', title: 'Buttons' },
        ],
        iconLibrary: [
          { imgUrl: coinGold, imgName: '' },
          { imgUrl: voucherKit, imgName: 'Voucher' },
          { imgUrl: closeGreyImg, imgName: 'Close' },
          { imgUrl: copyGreyImg, imgName: 'Copy' },
        ],
        iconLib: [
          { imgUrl: coinGold, imgName: 'GCoins' },
          { imgUrl: voucherKit, imgName: 'Voucher' },
          { imgUrl: closeGreyImg, imgName: 'Close' },
          { imgUrl: copyGreyImg, imgName: 'Copy' },
        ],
      }
    ]
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPLOAD_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case SAVE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DUPLICATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case DUPLICATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DUPLICATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_TEXTCONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].textConfig = state.config.localizationconfig[i].textConfig
            ? {
              ...state.config.localizationconfig[i].textConfig,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BUTTON_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_APPCONFIG_FIELD: {
      state.initialValues = state.initialValues
        ? {
          ...state.initialValues,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_LOCALIZATION_FLAG: {
      return { ...state, localizationFlag: action.payload };
    }
    case UPDATE_ACTIVE_FLAG: {
      return { ...state, activeNavTab: action.payload };
    }
    case UPDATE_CONFIG_FIELD: {
      const localizationconfig = [...state.config.localizationconfig, ...action.payload.localizationconfig];
      const config = {
        ...state.config,
        localizationconfig
      };
      return { ...state, config };
    }
    case REMOVE_CONFIG: {
      return { ...state, config: action.payload };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export const updateTextConfig = (payload) => {
  return {
    type: UPDATE_TEXTCONFIG,
    payload,
  };
};

export const uploadimage = (file, folderName) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/gamification/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
      .field('folderName', folderName),
  };
};

export const savePollConfig = (data) => {
  return {
    types: [SAVE_CONFIG_REQUESTED, SAVE_CONFIG_SUCCESS, SAVE_CONFIG_FAILURE],
    promise: client => client.post('saveBrandKit', {
      data,
    }),
  };
};

export const getBrandKit = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getBrandKit?orgId=${data}`),
  };
};

// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const editPollConfig = (data, gamificationId) => {
  return {
    types: [UPDATE_CONFIG_REQUESTED, UPDATE_CONFIG_SUCCESS, UPDATE_CONFIG_FAILURE],
    promise: client => client.post(`saveQuizConfig?quizId=${gamificationId}`, {
      data,
    }),
  };
};

export const duplicateSpinWheelTitle = (data, type, id) => {
  return {
    types: [DUPLICATE_CONFIG_REQUESTED, DUPLICATE_CONFIG_SUCCESS, DUPLICATE_CONFIG_FAILURE],
    promise: client => client.get(`duplicateSpinWheelTitle?title=${data}&type=${type}&id=${id}`),
  };
};

export const getTemplate = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getTemplate?templateId=${data}`),
  };
};

export const updateDropdown = (payload) => {
  return {
    type: UPDATE_APPCONFIG_FIELD,
    payload,
  };
};

export const updateButtonConfig = (payload) => {
  return {
    type: UPDATE_BUTTON_CONFIG,
    payload
  };
};

export const getAppConfig = () => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get('getAppConfig'),
  };
};

export const updateLocalizationFlag = (payload) => {
  return {
    type: UPDATE_LOCALIZATION_FLAG,
    payload,
  };
};

export const updateActiveFlag = (payload) => {
  return {
    type: UPDATE_ACTIVE_FLAG,
    payload,
  };
};

export const updateConfig = (payload) => {
  return {
    type: UPDATE_CONFIG_FIELD,
    payload
  };
};

export const removeConfig = (payload) => {
  return {
    type: REMOVE_CONFIG,
    payload
  };
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};
