/* eslint-disable prefer-template */
/* eslint-disable keyword-spacing */
import { APP_CONFIG, CONSTANTS } from '@constants';
import { getTimezoneDate, getTimezoneOffset } from '@helpers/Utility';
import { DateTime } from 'luxon';

const upload = require('superagent');

export const UPDATE_CUSTOMIZE_FIELD = 'rulesmartcampaignsconfig/UPDATE_CUSTOMIZE_FIELD';
export const UPDATE_SCHEDULE_FIELD = 'rulesmartcampaignsconfig/UPDATE_SCHEDULE_FIELD';
export const UPDATE_SCHEDULE_ERRORS = 'rulesmartcampaignsconfig/UPDATE_SCHEDULE_ERRORS';

export const SAVE_CONFIG_REQUESTED = 'save/SAVE_CONFIG_REQUESTED';
export const SAVE_CONFIG_SUCCESS = 'save/SAVE_CONFIG_SUCCESS';
export const SAVE_CONFIG_FAILURE = 'save/SAVE_CONFIG_FAILURE';

export const GET_CONFIG_REQUESTED = 'get/GET_CONFIG_REQUESTED';
export const GET_CONFIG_SUCCESS = 'get/GET_CONFIG_SUCCESS';
export const GET_PROGRESSBAR_CONFIG_SUCCESS = 'get/GET_PROGRESSBAR_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'get/GET_CONFIG_FAILURE';

export const DELETE_CONFIG_REQUESTED = 'get/DELETE_CONFIG_REQUESTED';
export const DELETE_CONFIG_SUCCESS = 'get/DELETE_CONFIG_SUCCESS';
export const DELETE_CONFIG_FAILURE = 'get/DELETE_CONFIG_FAILURE';

export const GET_CONFIG_RULE_REQUESTED = 'get/GET_CONFIG_RULE_REQUESTED';
export const GET_CONFIG_RULE_SUCCESS = 'get/GET_CONFIG_RULE_SUCCESS';
export const GET_CONFIG_RULE_FAILURE = 'get/GET_CONFIG_RULE_FAILURE';

export const GET_OFFER_REQUESTED = 'get/GET_OFFER_REQUESTED';
export const GET_OFFER_SUCCESS = 'get/GET_OFFER_SUCCESS';
export const GET_OFFER_FAILURE = 'get/GET_OFFER_FAILURE';

export const SAVE_RULE_REQUESTED = 'save/SAVE_RULE_REQUESTED';
export const SAVE_RULE_SUCCESS = 'save/SAVE_RULE_SUCCESS';
export const SAVE_RULE_FAILURE = 'save/SAVE_RULE_FAILURE';

export const GET_RULE_CE_REQUESTED = 'GET_RULE_CE_REQUESTED';
export const GET_RULE_CE_SUCCESS = 'GET_RULE_CE_SUCCESS';
export const GET_RULE_CE_FAILURE = 'GET_RULE_CE_FAILURE';

export const GET_SEGEMENT_REQUESTED = 'GET_SEGEMENT_REQUESTED';
export const GET_SEGEMENT_SUCCESS = 'GET_SEGEMENT_SUCCESS';
export const GET_SEGEMENT_FAILURE = 'GET_SEGEMENT_FAILURE';

export const GET_LEADERBOARD_SUCCESS = 'GET_LEADERBOARD_SUCCESS';

// export const GET_LEADERBOARD_DATA_REQUESTED = 'GET_LEADERBOARD_DATA_REQUESTED';
// export const GET_LEADERBOARD_DATA_SUCCESS = 'GET_LEADERBOARD_DATA_SUCCESS';
// export const GET_LEADERBOARD_DATA_FAILURE = 'GET_LEADERBOARD_DATA_FAILURE';

export const UPDATE_FIELD = 'rulesmartcampaignsconfig/UPDATE_FIELD';
export const UPDATE_PAGE = 'rulesmartcampaignsconfig/UPDATE_PAGE';
export const CLEAR_FORM = 'rulesmartcampaignsconfig/CLEAR_FORM';
export const UPDATE_OPTIONS = 'rulesmartcampaignsconfig/UPDATE_OPTIONS';
export const UPDATE_WINNING_QUADRANT = 'rulesmartcampaignsconfig/UPDATE_WINNING_QUADRANT';
export const UPDATE_TEMPLATE_DATA = 'rulesmartcampaignsconfig/UPDATE_TEMPLATE_DATA';
export const VALIDATE_CUSTOMIZE_DATA = 'rulesmartcampaignsconfig/VALIDATE_CUSTOMIZE_DATA';
export const CREATE_OPTIONS = 'rulesmartcampaignsconfig/CREATE_OPTIONS';


export const UPLOAD_IMAGE_REQUESTED = 'upload/UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_IMAGE_SUCCESS = 'upload/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'upload/UPLOAD_IMAGE_FAILURE';

export const GET_USER_EVENT_REQUEST = 'GET_USER_EVENT_REQUEST';
export const GET_USER_EVENT_SUCCESS = 'GET_USER_EVENT_SUCCESS';
export const GET_USER_EVENT_FAILURE = 'GET_USER_EVENT_FAILURE';

export const CHECK_RULE_NAME_REQUEST = 'CHECK_RULE_NAME_REQUEST';
export const CHECK_RULE_NAME_SUCCESS = 'CHECK_RULE_NAME_SUCCESS';
export const CHECK_RULE_NAME_FAILURE = 'CHECK_RULE_NAME_FAILURE';

export const GET_CUSTOMEVENT_CONFIG_REQUESTED = 'get/GET_CUSTOMEVENT_CONFIG_REQUESTED';
export const GET_CUSTOMEVENT_CONFIG_SUCCESS = 'get/GET_CUSTOMEVENT_CONFIG_SUCCESS';
export const GET_CUSTOMEVENT_CONFIG_FAILURE = 'get/GET_CUSTOMEVENT_CONFIG_FAILURE';

export const GET_CUSTOMEVENT_CONFIG_LIST_REQUESTED = 'get/GET_CUSTOMEVENT_CONFIG_REQUESTED';
export const GET_CUSTOMEVENT_CONFIG_LIST_SUCCESS = 'get/GET_CUSTOMEVENT_CONFIG_LIST_SUCCESS';
export const GET_CUSTOMEVENT_CONFIG_LIST_FAILURE = 'get/GET_CUSTOMEVENT_CONFIG_FAILURE';

export const GET_BADGE_CONFIG_REQUESTED = 'get/GET_BADGE_CONFIG_REQUEST';
export const GET_BADGE_CONFIG_SUCCESS = 'get/GET_BADGE_CONFIG_SUCCES';
export const GET_BADGE_CONFIG_FAILURE = 'get/GET_BADGE_CONFIG_FAILUR';

export const GET_RULE_ENGINE_LIST_REQUESTED = 'rulesmartcampaignsconfig/GET_RULE_ENGINE_LIST_REQUESTED';
export const GET_RULE_ENGINE_LIST_SUCCESS = 'rulesmartcampaignsconfig/GET_RULE_ENGINE_LIST_SUCCESS';
export const GET_RULE_ENGINE_LIST_FAILURE = 'rulesmartcampaignsconfig/GET_RULE_ENGINE_LIST_FAILURE';

export const UPDATE_BUTTON_CONFIG = 'rulesmartcampaignsconfig/UPDATE_BUTTON_CONFIG';
export const UPDATE_APPCONFIG_FIELD = 'rulesmartcampaignsconfig/UPDATE_APPCONFIG_FIELD';
export const UPDATE_TERMS_FIELD = 'rulesmartcampaignsconfig/UPDATE_TERMS_FIELD';

export const DUPLICATE_CONFIG_REQUESTED = 'get/DUPLICATE_CONFIG_REQUESTED';
export const DUPLICATE_CONFIG_SUCCESS = 'get/DUPLICATE_CONFIG_SUCCESS';
export const DUPLICATE_CONFIG_FAILURE = 'get/DUPLICATE_CONFIG_FAILURE';

console.log('Default timezone : ', window.localStorage.getItem('defaultTimezone'));
console.log('Timezone String : ', window.localStorage.getItem('timeZoneString'));
const initialState = {
  loading: false,
  loaded: false,
  error: false,
  configLoading: false,
  saveRuleLoading: false,
  activeNavTab: 'customize',
  referralLeaderboard: false,
  tableData: {},
  initialData: {
    pageNo: 0,
    title: '',
    sortBy: 'updatedTS',
    orderBy: 'desc',
    activeTab: 'my_rules',
    ruleId: '',
    deleteRuleList: {
      ruleName: '',
      list: []
    },
    headers: [
      {
        title: 'Campaign Name',
        slug: 'rule_name',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: '',
        width: 150
      },
      {
        title: 'Templates',
        slug: 'event_name',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: '',
        width: 200
      },
      {
        title: 'Rewards',
        slug: 'offer_name',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: '',
        width: 160
      },
      {
        title: CONSTANTS.STATUS_FILTER,
        slug: 'status',
        isSortable: true,
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: 'desc',
        width: 80,
      },
      {
        title: 'Created By',
        slug: 'status',
        isSortable: false,
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: 'desc',
        width: 120,
      },
      {
        title: 'Campaign Duration',
        slug: 'start_date',
        isSortable: true,
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: 'desc',
        width: 170,
      },
      {
        title: 'Test End Date',
        slug: 'end_date',
        isSortable: true,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: 'desc',
        width: 170,
      },
      {
        title: 'Actions',
        slug: 'actions',
        isSortable: false,
        isVisible: false,
        tooltip: true,
        sortByOrder: '',
        width: 300,
      },
    ]
  },
  options: {
    userSegment: [],
    gamificationTemplate: [],
    tournamentTemplate: [],
    infoPageTemplates: [],
    progressBarTemplate: [],
    leaderboardOptions: [],
    customEventsTemplates: [],
    badgeTypeOptions: [],
    elements: [],
    quadrant: [],
    rewards: [],
    userEvents: [],
    string: [
      {
        title: '==',
        value: 'equal',
      },
      {
        title: '!=',
        value: 'not_equal',
      },
      {
        title: 'Like',
        value: 'like',
      },
      {
        title: 'is_empty',
        value: 'is_empty',
      },
      {
        title: 'is_null',
        value: 'is_null',
      }
    ],
    number: [
      {
        title: '==',
        value: 'equal',
      },
      {
        title: '!=',
        value: 'not_equal',
      },
      {
        title: '<',
        value: 'less',
      },
      {
        title: '>',
        value: 'greater',
      },
      {
        title: '<=',
        value: 'less_or_equal',
      },
      {
        title: '>=',
        value: 'greater_or_equal',
      }
    ],
    date_time: [
      {
        title: '==',
        value: 'equal',
      },
      {
        title: '!=',
        value: 'not_equal',
      },
      {
        title: '<',
        value: 'less',
      },
      {
        title: '>',
        value: 'greater',
      },
      {
        title: '<=',
        value: 'less_or_equal',
      },
      {
        title: '>=',
        value: 'greater_or_equal',
      },
      {
        title: 'between',
        value: 'between'
      }
    ],
    time: [
      {
        title: '==',
        value: 'equal',
      },
      {
        title: '!=',
        value: 'not_equal',
      },
      {
        title: '<',
        value: 'less',
      },
      {
        title: '>',
        value: 'greater',
      },
      {
        title: '<=',
        value: 'less_or_equal',
      },
      {
        title: '>=',
        value: 'greater_or_equal',
      },
      {
        title: 'between',
        value: 'between'
      }
    ],
    date: [
      {
        title: '==',
        value: 'equal',
      },
      {
        title: '!=',
        value: 'not_equal',
      },
      {
        title: '<',
        value: 'less',
      },
      {
        title: '>',
        value: 'greater',
      },
      {
        title: '<=',
        value: 'less_or_equal',
      },
      {
        title: '>=',
        value: 'greater_or_equal',
      },
      {
        title: 'between',
        value: 'between'
      }
    ],
    days: []
  },
  customize: {
    gameMechanicsRuleType: '',
    testSchedulingDays: undefined,
    mode: 'save',
    allowEdit: true,
    ruleId: '',
    ruleName: '',
    customType: 'day',
    typeID: '',
    userSegment: '',
    status: 'active',
    rewardEachEvent: true,
    template: '',
    gamifyE: '',
    tournament: 'no',
    addLeaderboard: '',
    repeatLeaderBoard: 'yes',
    leaderboard: '',
    daily: false,
    weekly: false,
    monthly: false,
    campaign: false,
    offer: true,
    badges: false,
    templateChange: false,
    combination: false,
    rewardOption: 'rank',
    winningQuadrant: [],
    lossingQuadrant: 0,
    quadrantReward: [],
    eventSelect: [
      {
        title: 'Event 1',
        value: '',
        // id: '',
        // value1: '',
        // value2: ''
        // eventName: '',
        id: '',
        offerId: '',
        badgeId: '',
        offerName: '',
        badgeName: '',
        eventNo: ''
      }
    ],
    eventCompletion: {
      offerConfiguredId: '',
      offerConfiguredName: '',
      badgeConfiguredId: '',
      badgeConfiguredName: ''
    },
    initialValues: {
      fonts: [],
      fontStrength: [],
      fontSize: [],
      spinButtonShadow: [],
      spinButtonWidth: [],
      spinButtonRadius: [],
      outlineStyles: [],
    },
    scratchRewards: [
      {
        offerId: '',
        offerName: '',
        winning: '',
        score: '='
      }
    ],
    rankRewards: [
      {
        offerId: '',
        offerName: '',
        rank: '',
        rank1: '',
        rank2: ''
      }
    ],
    referralRewards: [],
    questionSchedule: {
      noOfDays: '',
      noOfQuestions: '',
      customDays: 0,
      customTime: '',
      customDaysOption: '',
      customQuestions: 0,
      questionSchedule: false
    },
    totalQuestions: 0,
    winningText: {
      img: '',
      imageName: '',
      text: '',
      color: '#000000',
      font: {
        size: '14',
        strength: '400',
        family: 'Arial, sans-serif',
        align: 'left',
      },
      error: '',
      errorImg: ''
    },
    lossingText: {
      img: '',
      imageName: '',
      text: '',
      color: '#000000',
      font: {
        size: '14',
        strength: '400',
        family: 'Arial, sans-serif',
        align: 'left',
      },
      error: '',
      errorImg: ''
    },
    iterationsPerFrequency: 1,
    timezone: window.localStorage.getItem('defaultTimezone'),
    localTimezoneStr: window.localStorage.getItem('timeZoneString'),
    rankRewardError: 'Please configure rewards for both referrer and referree',
    customActivityEvents: [
      {
        activity: '',
        events: '',
        property: '',
        operator: '',
        type: '',
        value: [],
        options: 0,
        completed: '',
        points: '',
        offerId: '',
        offerName: '',
        query: [],
      }
    ],
    properties: [],
    propertyOptions: [],
    tncContentBgColor: '#ffffff',
    redirectionUrl: '',
    domain: 'sdk',
    tncTitleError: '',
    urlError: '',
    imageError: '',
    contentTextError: '',
    titleObj: {
      titleBarColor: '#F67A3E',
      placement: 'template',
      position: 'Top Right',
    },
    tncContentText: '',
    // placement: 'template',
    // position: 'Top Right',
    bannerImageFlag: false,
    tncImageName: '',
    tncImageUrl: '',
    tncTitle: {
      text: '',
      color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#FFFFFF' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
      font: {
        size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '16' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
        strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
        family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
        align: 'center',
      },
    },
    buttonConfig: {
      buttonText: {
        text: 'I Agree',
        color: '#ffffff',
        font: {
          size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontSize,
          strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontWt,
          family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFont,
          align: 'centre',
        },
      },
      textColor: '#ffffff',
      width: '50%',
      radius: '8px',
      shadow: '0px 0px',
      shadowColor: '#cdcdcd',
      buttonPosition: 'center',
      bgColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#F67A3E' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
      border: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#000000' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
      hoverBgColor: '#cdcdcd',
      buttonTextError: '',
      borderWidth: '1px',
    }
  },
  customizeTnc: {
    initialValues: {
      fonts: [],
      fontStrength: [],
      fontSize: [],
      spinButtonShadow: [],
      spinButtonWidth: [],
      spinButtonRadius: [],
      outlineStyles: [],
    },
    tncContentBgColor: '#ffffff',
    redirectionUrl: '',
    domain: 'sdk',
    tncTitleError: '',
    urlError: '',
    imageError: '',
    contentTextError: '',
    titleObj: {
      titleBarColor: '#F67A3E',
      placement: 'template',
      position: 'Top Right',
    },
    tncContentText: '',
    // placement: 'template',
    // position: 'Top Right',
    bannerImageFlag: false,
    tncImageName: '',
    tncImageUrl: '',
    tncTitle: {
      text: '',
      color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#FFFFFF' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
      font: {
        size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '16' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
        strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
        family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
        align: 'center',
      },
    },
    buttonConfig: {
      buttonText: {
        text: 'I Agree',
        color: '#ffffff',
        font: {
          size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontSize,
          strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontWt,
          family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFont,
          align: 'centre',
        },
      },
      textColor: '#ffffff',
      width: '50%',
      radius: '8px',
      shadow: '0px 0px',
      shadowColor: '#cdcdcd',
      buttonPosition: 'center',
      bgColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#F67A3E' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
      border: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#000000' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
      hoverBgColor: '#cdcdcd',
      buttonTextError: '',
      borderWidth: '1px',
    }
  },
  schedule: {
    offerStartPublish: 'now',
    offerStartDate: getTimezoneDate(),
    offerEndPublish: 'never',
    offerEndDate: getTimezoneDate(),
    selectedDays: [],
    tncFlag: false,
    testEndedStatus: false,
    editRuleId: '',
    selectedDaysTimings: [],
    howOftenSelected: 'oncePerSession',
    trigger: false,
    frequency: false,
    events: [{
      id: 1,
      property: [],
      userEvent: '',
      operator: 'none',
      days: 1,
      userProperty: [{
        id: 1,
        name: '',
        type: '',
        operator: '',
        value: [],
        options: []
      }]
    }],
    customEventRuleRewardDTO: {
      eventId: '',
      eventName: '',
      property: '',
      type: '',
      eventKey: '',
      propertiesList: [],
      conditions: [
        {
          operator: '',
          query: [
            {
              event: '',
              name: '',
              operator: '',
              query: ''
            }
          ],
          rewards: [
            {
              offerId: '',
              offerName: '',
              rewardType: '',
              percentage: undefined
            }
          ],
          values: []
        }
      ]
    },
    smartTemplatesWeightageType: 'default',
    templateId: '',
    smartEditBlocked: false,
    smartTestEndEditAllowed: false,
    smartTemplates: [
      {
        templateId: '',
        templateType: 0,
        templateName: '',
        percentage: 0
      },
      {
        templateId: '',
        templateType: 0,
        templateName: '',
        percentage: 0
      }
    ],
    testUserSegmentSize: 0,
    testRewardAssignment: {
      regularWeightageType: 'default',
      regularRewardAssignment: [
        {
          offerId: '',
          offerName: '',
          percentage: 0,
          winingQuadrant: true,
        }
      ],
      spinwheelWeightageType: 'default'
    },
    separateFinalReward: false,
    finalRewardAssignment: {
      regularWeightageType: 'default',
      regularRewardAssignment: [
        {
          offerId: '',
          offerName: '',
          percentage: 0,
          winingQuadrant: true,
        }
      ],
      spinwheelWeightageType: 'default'
    },
    spinTemplatesDataTemplate: [
      {
        templateId: '',
        templateName: '',
        availableSegments: [],
        selectedSegments: [],
        segmentsDropDownOptions: [],
        spinWheelRewardAssignment: [
          {
            offerId: '',
            offerName: '',
            percentage: 0,
            quadrant: 0,
            text: '',
            winingQuadrant: true,
          }
        ]
      }
    ],
    spinTemplatesTestConfig: [],
    spinTemplatesFinalConfig: [],
    spinTemplatesCount: 0,
    regularTemplatesCount: 0,
    errors: {
      template: {
        name: '',
        templateSelect: '',
        weightSum: ''
      },
      segment: {
        segmentSelect: '',
        nonZeroCount: ''
      },
      rewards: {
        rewardSelectTest: '',
        rewardSelectFinal: '',
        weightSumTest: '',
        weightSumFinal: ''
      },
      scheduling: {},
      tnc: {}
    },
    resetSpinRewards: [
      {
        offerId: '',
        offerName: '',
        percentage: 0,
        quadrant: 0,
        text: ''
      }
    ],
    testStatus: 'active',
    finalTemplateType: 'custom',
    favouredTemplate: '',
    selectedFinalTemplate: '',
    templateSelection: ''
  },
  templates: [],
  quadrants: 0,
  quadrantSegment: [],
  quadrantsOptions: []
};

const backupCustomEventDTO = {
  eventId: '',
  eventName: '',
  property: '',
  type: '',
  eventKey: '',
  propertiesList: [],
  conditions: [
    {
      operator: '',
      query: [
        {
          event: '',
          name: '',
          operator: '',
          query: ''
        }
      ],
      rewards: [
        {
          offerId: '',
          offerName: '',
          rewardType: '',
          percentage: undefined
        }
      ],
      values: []
    }
  ]
};

const backUpErrors = {
  template: {
    name: '',
    templateSelect: '',
    weightSum: ''
  },
  segment: {
    segmentSelect: '',
    nonZeroCount: ''
  },
  rewards: {
    rewardSelectTest: '',
    rewardSelectFinal: '',
    weightSumTest: '',
    weightSumFinal: ''
  },
  scheduling: {},
  tnc: {}
};


const backUpCustomize = {
  gameMechanicsRuleType: '',
  mode: 'save',
  allowEdit: true,
  ruleId: '',
  initialValues: {
    fonts: [],
    fontStrength: [],
    fontSize: [],
    spinButtonShadow: [],
    spinButtonWidth: [],
    spinButtonRadius: [],
    outlineStyles: [],
  },
  tncContentBgColor: '#ffffff',
  redirectionUrl: '',
  domain: 'sdk',
  tncTitleError: '',
  urlError: '',
  imageError: '',
  contentTextError: '',
  titleObj: {
    titleBarColor: '#F67A3E',
    placement: 'template',
    position: 'Top Right',
  },
  tncContentText: '',
  // placement: 'template',
  // position: 'Top Right',
  bannerImageFlag: false,
  tncImageName: '',
  tncImageUrl: '',
  tncTitle: {
    text: '',
    color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#FFFFFF' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
    font: {
      size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '16' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
      strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
      family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
      align: 'center',
    },
  },
  buttonConfig: {
    buttonText: {
      text: 'I Agree',
      color: '#ffffff',
      font: {
        size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontSize,
        strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontWt,
        family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFont,
        align: 'centre',
      },
    },
    textColor: '#ffffff',
    width: '50%',
    radius: '8px',
    shadow: '0px 0px',
    shadowColor: '#cdcdcd',
    buttonPosition: 'center',
    bgColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#F67A3E' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
    border: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#000000' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
    hoverBgColor: '#cdcdcd',
    buttonTextError: '',
    borderWidth: '1px',
  },
  ruleName: '',
  customType: 'day',
  typeID: '',
  userSegment: 'All User',
  status: 'active',
  rewardEachEvent: true,
  template: '',
  gamifyE: '',
  tournament: 'no',
  addLeaderboard: '',
  repeatLeaderBoard: 'yes',
  leaderboard: '',
  daily: false,
  weekly: false,
  monthly: false,
  campaign: false,
  offer: true,
  badges: false,
  templateChange: false,
  combination: false,
  rewardOption: 'rank',
  winningQuadrant: [],
  lossingQuadrant: 0,
  quadrantReward: [],
  eventSelect: [
    {
      title: 'Event 1',
      value: '',
      // id: '',
      // value1: '',
      // value2: ''
      // eventName: '',
      id: '',
      offerId: '',
      badgeId: '',
      offerName: '',
      badgeName: '',
      eventNo: ''
    }
  ],
  eventCompletion: {
    offerConfiguredId: '',
    offerConfiguredName: '',
    badgeConfiguredId: '',
    badgeConfiguredName: ''
  },
  scratchRewards: [
    {
      offerId: '',
      offerName: '',
      winning: '',
      score: '='
    }
  ],
  rankRewards: [
    {
      offerId: '',
      offerName: '',
      rank: '',
      rank1: '',
      rank2: ''
    }
  ],
  referralRewards: [],
  questionSchedule: {
    noOfDays: '',
    noOfQuestions: '',
    customDays: 0,
    customTime: '',
    customDaysOption: '',
    customQuestions: 0,
    questionSchedule: false
  },
  totalQuestions: 0,
  winningText: {
    img: '',
    imageName: '',
    text: '',
    color: '#000000',
    font: {
      size: '14',
      strength: '400',
      family: 'Arial, sans-serif',
      align: 'left',
    },
    error: '',
    errorImg: ''
  },
  lossingText: {
    img: '',
    imageName: '',
    text: '',
    color: '#000000',
    font: {
      size: '14',
      strength: '400',
      family: 'Arial, sans-serif',
      align: 'left',
    },
    error: '',
    errorImg: ''
  },
  iterationsPerFrequency: 1,
  timezone: window.localStorage.getItem('defaultTimezone'),
  localTimezoneStr: window.localStorage.getItem('timeZoneString'),
  rankRewardError: 'Please configure rewards for both referrer and referree',
  customActivityEvents: [
    {
      activity: '',
      events: '',
      property: '',
      operator: '',
      type: '',
      value: [],
      options: 0,
      completed: '',
      points: '',
      offerId: '',
      offerName: '',
      query: [],
    }
  ],
  properties: [],
  propertyOptions: []
};

const backUpSchedule = {
  offerStartPublish: 'now',
  offerStartDate: getTimezoneDate(),
  offerEndPublish: 'never',
  offerEndDate: getTimezoneDate(),
  selectedDays: [],
  selectedDaysTimings: [],
  howOftenSelected: 'oncePerSession',
  finalTemplateType: 'custom',
  trigger: false,
  frequency: false,
  templateId: '',
  smartEditBlocked: false,
  smartTestEndEditAllowed: false,
  editRuleId: '',
  events: [{
    id: 1,
    property: [],
    userEvent: '',
    operator: 'none',
    days: 1,
    userProperty: [{
      id: 1,
      name: '',
      type: '',
      operator: '',
      value: [],
      options: []
    }]
  }],
  customEventRuleRewardDTO: {
    eventId: '',
    eventName: '',
    property: '',
    type: '',
    eventKey: '',
    propertiesList: [],
    conditions: [
      {
        operator: '',
        query: [
          {
            event: '',
            name: '',
            operator: '',
            query: ''
          }
        ],
        rewards: [
          {
            offerId: '',
            offerName: '',
            rewardType: '',
            percentage: undefined
          }
        ],
        values: []
      }
    ]
  },
  testEndedStatus: false
};

const getIndexedQuadrants = (index, winingQuadrant) => {
  return winingQuadrant.find(qud => qud.value === index);
};

const getUpdatedWinningQuadrants = (winingQuadrantArray, type) => {
  if (type !== 1 && type !== 6) {
    const winingQuadrant = winingQuadrantArray.split(',');
    return winingQuadrant.map((win) => {
      return {
        label: Number(win),
        value: Number(win)
      };
    });
  }
  return Number(winingQuadrantArray);
};

export const getCustomEvents = () => {
  return {
    types: [GET_USER_EVENT_REQUEST, GET_USER_EVENT_SUCCESS, GET_USER_EVENT_FAILURE],
    promise: client => client.get('getCustomUserEvents')
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPLOAD_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case CLEAR_FORM: {
      state.activeNavTab = 'customize';
      let customize = {
        ...state.customize,
      };
      let schedule = {
        ...state.schedule,
      };
      customize.ruleName = '';
      customize.allowEdit = true;
      customize.customType = 'day';
      customize.customDays = 0;
      customize.customTime = '00:00:00';
      schedule.customEventRuleRewardDTO = JSON.parse(JSON.stringify(backupCustomEventDTO));
      schedule.pageId = '';
      schedule.howOftenSelected = 'oncePerSession';
      customize = JSON.parse(JSON.stringify(backUpCustomize));
      schedule = JSON.parse(JSON.stringify(backUpSchedule));
      schedule.offerStartDate = getTimezoneDate();
      schedule.offerEndDate = getTimezoneDate();
      schedule.editRuleId = '';
      schedule.smartTemplatesWeightageType = 'default';
      schedule.smartTemplates = [
        {
          templateId: '',
          templateType: 0,
          percentage: 0
        },
        {
          templateId: '',
          templateType: 0,
          percentage: 0
        },
      ];
      schedule.testUserSegmentSize = 0;
      schedule.testRewardAssignment = {
        regularWeightageType: 'default',
        regularRewardAssignment: [
          {
            offerId: '',
            offerName: '',
            percentage: 0,
            winingQuadrant: true
          }
        ],
        spinwheelWeightageType: 'default'
      };
      schedule.separateFinalReward = false;
      schedule.finalRewardAssignment = {
        regularWeightageType: 'default',
        regularRewardAssignment: [
          {
            offerId: '',
            offerName: '',
            percentage: 0,
            winingQuadrant: true
          }
        ],
        spinwheelWeightageType: 'default'
      };
      schedule.spinTemplatesDataTemplate = [
        {
          templateId: '',
          templateName: '',
          availableSegments: [],
          selectedSegments: [],
          segmentsDropDownOptions: [],
          spinWheelRewardAssignment: [
            {
              offerId: '',
              offerName: '',
              percentage: 0,
              quadrant: 0,
              text: '',
              winingQuadrant: true
            }
          ]
        }
      ];
      schedule.spinTemplatesTestConfig = [];
      schedule.spinTemplatesFinalConfig = [];
      schedule.spinTemplatesCount = 0;
      schedule.regularTemplatesCount = 0;
      schedule.errors = {
        template: {
          name: '',
          templateSelect: '',
          weightSum: ''
        },
        segment: {
          segmentSelect: '',
          nonZeroCount: ''
        },
        rewards: {
          rewardSelectTest: '',
          rewardSelectFinal: '',
          weightSumTest: '',
          weightSumFinal: ''
        },
        scheduling: {},
        tnc: {}
      };
      schedule.resetSpinRewards = [
        {
          offerId: '',
          offerName: '',
          percentage: 0,
          quadrant: 0,
          text: '',
          winingQuadrant: true
        }
      ];
      schedule.tncFlag = false;
      schedule.finalTemplateType = 'custom';
      schedule.smartEditBlocked = false;
      schedule.smartTestEndEditAllowed = false;
      return { ...state, customize, schedule };
    }
    case SAVE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CONFIG_SUCCESS: {
      const templates = action.result.templates.templateDTOs;
      const allowedTemplateTypes = [2, 3, 4, 7, 8, 9, 18];
      const templateOptions = [];
      for (let i = 0; i < templates.length; i += 1) {
        if (templates[i].templateName != null && allowedTemplateTypes.indexOf(templates[i].typeId) !== -1) {
          templateOptions.push({
            title: templates[i].templateName,
            value: templates[i].id,
            typeID: templates[i].typeId,
            segments: templates[i].segments
          });
        } else if (allowedTemplateTypes.indexOf(templates[i].typeId) !== -1) {
          templateOptions.push({ title: templates[i].title, value: templates[i].id, typeID: templates[i].typeId });
        }
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        options: { ...state.options, ...{ gamificationTemplate: templateOptions } },
      };
    }
    case GET_PROGRESSBAR_CONFIG_SUCCESS: {
      const progressBar = action.result.ProgressBars.progressBarEntities;
      const progressBarOptions = [];
      for (let i = 0; i < progressBar.length; i += 1) {
        if (progressBar[i].progressBarName != null) {
          progressBarOptions.push({
            title: progressBar[i].templateName, value: progressBar[i].id, typeID: progressBar[i].typeId, numberOfEvents: progressBar[i].templates[0].numberOfEvents
          });
        } else {
          progressBarOptions.push({
            title: progressBar[i].templateName, value: progressBar[i].id, typeID: progressBar[i].typeId, numberOfEvents: progressBar[i].templates[0].numberOfEvents
          });
        }
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        options: { ...state.options, ...{ progressBarTemplate: progressBarOptions } },
      };
    }

    case GET_LEADERBOARD_SUCCESS: {
      const leaderboard = action.result.leaderBoardList.gameMechanicsList;
      const gameMechanics = [];
      for (let i = 0; i < leaderboard.length; i += 1) {
        if (leaderboard[i].name != null) {
          gameMechanics.push({
            title: leaderboard[i].name, value: leaderboard[i].id, gameMechanicsRuleType: leaderboard[i].gameMechanicsType
          });
        } else {
          gameMechanics.push({
            title: leaderboard[i].name, value: leaderboard[i].id, gameMechanicsRuleType: leaderboard[i].gameMechanicsType
          });
        }
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        options: { ...state.options, ...{ leaderboardOptions: gameMechanics } },
      };
    }

    case GET_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CONFIG_RULE_REQUESTED: {
      return {
        ...state,
        configLoading: true,
        loading: true,
        loaded: false,
        // quadrantSegment: [],
        quadrantsOptions: []
      };
    }
    case GET_CONFIG_RULE_SUCCESS: {
      const segments = action.result.spinWheelConfig.segments;
      let questionSchedule = state.customize.questionSchedule;
      const segmentsTitle = [];
      for (let i = 0; i < segments.length; i += 1) {
        segmentsTitle[i] = segments[i].text;
      }
      const segmentsUpdate = action.result.spinWheelConfig.segments.map((seg, index) => {
        return {
          ...seg,
          disabled: true,
          id: (index + 1)
        };
      });
      const winingQuadrant = state.customize.winningQuadrant;
      if(winingQuadrant instanceof Array) {
        segmentsUpdate.forEach((winQ, index) => {
          if(getIndexedQuadrants((index + 1), winingQuadrant)) {
            segmentsUpdate[index].disabled = false;
          }else {
            segmentsUpdate[index].disabled = true;
          }
        });
      }
      const quadrantsOptions = action.result.spinWheelConfig.segments.map((seg, index) => {
        return {
          label: (index + 1),
          value: (index + 1)
        };
      });
      state.customize.lossingQuadrant = segmentsUpdate.length;
      const quadrantReward = action.result.spinWheelConfig.segments.map((seg, index) => {
        const upReward = state.customize.quadrantReward.find(updatedReward => updatedReward.quadrant === (index + 1));
        if(upReward) {
          return upReward;
        }
        return {
          quadrant: (index + 1),
          text: seg.text,
          offerId: '',
          offerName: '',
          resultText: '',
          score: '=',
          winingQuadrant: false,
          percentage: 0
        };
      });
      state.customize.lossingQuadrant = quadrantReward.filter(obj => obj.winingQuadrant === false).length;
      state.customize.quadrantReward = quadrantReward;
      const elementsOptions = [];
      // eslint-disable-next-line prefer-const
      let allowEdit = true;
      if (new Date().getTime() > state.schedule.offerStartDate && state.customize.mode === 'edit') {
        allowEdit = false;
      }
      let totalQuestions = state.customize.totalQuestions;
      const rewards = (state.customize.mode === 'save' && (state.customize.typeID === 5 || state.customize.typeID === 9)) ? [] : state.customize.scratchRewards;
      if(state.customize.typeID === 5) {
        let elements = action.result.spinWheelConfig.slotMachineElements;
        elements = [...new Set(elements)];
        for (let i = 0; i < elements.length; i += 1) {
          elementsOptions.push({ type: 'image', title: elements[i], value: elements[i] });
          if(state.customize.mode === 'save' || state.customize.templateChange) {
            rewards.push({
              offerId: '',
              offerName: '',
              winning: '',
              score: '=',
              elements1: elements[i],
              elements2: elements[i],
              elements3: elements[i]
            });
          }
        }
      } else if(action.result.spinWheelConfig.type === 9 && (state.customize.mode === 'save' || state.customize.templateChange)) {
        const tossCoinReward1 = {
          text: '',
          offerId: '',
          offerName: '',
          resultText: '',
          score: '',
          winingQuadrant: false,
          percentage: 0
        };
        rewards.push(tossCoinReward1);
        rewards.push(tossCoinReward1);
      } else if(state.customize.typeID === 6) {
        if (action.result.spinWheelConfig.schedule !== null && action.result.spinWheelConfig.schedule !== undefined) {
          questionSchedule = action.result.spinWheelConfig.schedule;
          totalQuestions = action.result.spinWheelConfig.questions.length;
          if (new Date().getTime() > state.schedule.offerStartDate && state.customize.mode === 'edit') {
            allowEdit = false;
          }
        }
      }else if(state.customize.typeID === 12) {
        state.customize.daily = false;
        state.customize.weekly = false;
        state.customize.monthly = false;
        state.customize.campaign = true;
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        configLoading: false,
        options: { ...state.options, ...{ quadrant: segmentsTitle, elements: elementsOptions } },
        quadrants: action.result.spinWheelConfig.segments.length,
        quadrantSegment: segmentsUpdate,
        quadrantsOptions,
        customize: {
          ...state.customize,
          ...{
            scratchRewards: rewards,
            templateChange: false,
            questionSchedule,
            totalQuestions,
            allowEdit
          }
        },
        referralLeaderboard: action.result.spinWheelConfig.referralLeaderboard,
        leaderboardTemplate: action.result.spinWheelConfig.leaderboardTemplate
      };
    }
    case GET_CONFIG_RULE_FAILURE: {
      return {
        ...state,
        configLoading: false,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_OFFER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_OFFER_SUCCESS: {
      const offers = action.result.rewardsList.offerConfig;
      const offerOptions = [];
      for (let i = 0; i < offers.length; i += 1) {
        offerOptions.push({ title: offers[i].offerName, value: offers[i].id });
      }
      if (state.customize.typeID !== undefined) {
        offerOptions.push({ title: 'No Offer', value: 'No Offer' });
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        options: { ...state.options, ...{ rewards: offerOptions } },
      };
    }
    case GET_OFFER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case SAVE_RULE_REQUESTED: {
      return {
        ...state,
        saveRuleLoading: true,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_RULE_SUCCESS: {
      return {
        ...state,
        saveRuleLoading: false,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_RULE_FAILURE: {
      return {
        ...state,
        saveRuleLoading: false,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_RULE_CE_REQUESTED: {
      return {
        ...state,
        saveRuleLoading: true,
        loading: true,
        loaded: false,
      };
    }
    case GET_RULE_CE_SUCCESS: {
      const { ruleEntity } = action.result;
      const quadrantReward = [];
      const scratchRewards = [];
      const rankRewards = [];
      const referralRewards = [];
      if (((ruleEntity.type === 1 || ruleEntity.type === 6) && ruleEntity.rewardOption === 'rank') || ruleEntity.type === 8 || ruleEntity.type === 13) {
        ruleEntity.rewardAssigment.forEach((updateReward) => {
          const rewards = {
            offerId: updateReward.offerId !== '' ? updateReward.offerId : 'No Offer',
            offerName: updateReward.offerName !== '' ? updateReward.offerName : 'No Offer',
            rank: updateReward.rank !== '' ? updateReward.rank : '',
            rank1: updateReward.rank1 !== '' ? updateReward.rank1 : '',
            rank2: updateReward.rank2 !== '' ? updateReward.rank2 : '',
          };
          rankRewards.push(rewards);
        });
      } else if (ruleEntity.type === 2) {
        ruleEntity.rewardAssigment.forEach((updateReward) => {
          const rewards = {
            ...updateReward,
            offerId: updateReward.offerId !== '' ? updateReward.offerId : 'No Offer',
            offerName: updateReward.offerName !== '' ? updateReward.offerName : 'No Offer',
          };
          quadrantReward[updateReward.quadrant - 1] = rewards;
        });
      } else if (ruleEntity.type === 12) {
        ruleEntity.rewardAssigment.forEach((updateReward) => {
          const rewards = {
            offerId: updateReward.offerId !== '' ? updateReward.offerId : 'No Offer',
            offerName: updateReward.offerName !== '' ? updateReward.offerName : 'No Offer',
          };
          referralRewards.push(rewards);
        });
      } else {
        ruleEntity.rewardAssigment.forEach((updateReward) => {
          const rewards = {
            winning: updateReward.percentage,
            offerId: updateReward.offerId !== '' ? updateReward.offerId : 'No Offer',
            offerName: updateReward.offerName !== '' ? updateReward.offerName : 'No Offer',
            score: updateReward.score,
            elements1: updateReward.elements && updateReward.elements[0],
            elements2: updateReward.elements && updateReward.elements[1],
            elements3: updateReward.elements && updateReward.elements[2],
          };
          scratchRewards.push(rewards);
        });
      }
      const winningText = {
        img: ruleEntity.winingBackgroundImage,
        imageName: ruleEntity.winingBackgroundImageName,
        text: ruleEntity.winingText,
        color: ruleEntity.winingTextFontColor,
        font: {
          size: ruleEntity.winingTextFontSize,
          strength: ruleEntity.winingTextFontWeight,
          family: ruleEntity.winingTextFont,
          align: ruleEntity.winingTextPlace,
        },
        error: '',
        errorImg: ''
      };
      const lossingText = {
        img: ruleEntity.losingBackgroundImage,
        imageName: ruleEntity.losingBackgroundImageName,
        text: ruleEntity.losingText,
        color: ruleEntity.losingTextFontColor,
        font: {
          size: ruleEntity.losingTextFontSize,
          strength: ruleEntity.losingTextFontWeight,
          family: ruleEntity.losingTextFont,
          align: ruleEntity.losingTextPlace,
        },
        error: '',
        errorImg: ''
      };
      const propertyOptions = ruleEntity.customEventRuleRewardDTO.propertiesList.map((prop) => {
        return {
          ...prop,
          title: prop.name,
          value: prop.name
        };
      });
      const customize = {
        mode: 'edit',
        ruleId: ruleEntity.id,
        ruleName: ruleEntity.name,
        customType: ruleEntity.customType,
        customDays: ruleEntity.customDays,
        customTime: ruleEntity.customTime,
        userSegment: ruleEntity.userSegmentId === '' ? 'All User' : ruleEntity.userSegmentId,
        combination: ruleEntity.combination,
        status: ruleEntity.ruleStatus,
        rewardEachEvent: ruleEntity.rewardEachEvent,
        gamifyE: ruleEntity.gamifyE,
        customActivityEvents: ruleEntity.customActivityEvents,
        gameMechanicsRuleType: ruleEntity.gameMechanicsRuleType,
        eventSelect: ruleEntity.eventSelect,
        eventCompletion: ruleEntity.eventCompletion,
        offer: ruleEntity.offer,
        badges: ruleEntity.badges,
        template: ruleEntity.templateId,
        winningQuadrant: getUpdatedWinningQuadrants(ruleEntity.winningQuandrants, ruleEntity.type),
        lossingQuadrant: Number(ruleEntity.losingQuandrants),
        quadrantReward,
        scratchRewards,
        rankRewards,
        referralRewards,
        winningText,
        lossingText,
        typeID: ruleEntity.type,
        numberOfEvents: ruleEntity.numberOfEvents,
        rewardOption: ruleEntity.rewardOption,
        tournament: ruleEntity.type === 13 ? 'yes' : 'no',
        addLeaderboard: ruleEntity.leaderBoardSelected || ruleEntity.type === 13 || ruleEntity.type === 17 ? 'yes' : 'no',
        leaderboard: ruleEntity.leaderBoardId,
        repeatLeaderBoard: ruleEntity.repeatLeaderBoard ? 'yes' : 'no',
        questionSchedule: {
          noOfDays: '',
          noOfQuestions: '',
          customDays: 0,
          customTime: '',
          customDaysOption: '',
          customQuestions: 0,
          questionSchedule: false
        },
        daily: ruleEntity.leaderboardFrequency && ruleEntity.leaderboardFrequency.daily,
        weekly: ruleEntity.leaderboardFrequency && ruleEntity.leaderboardFrequency.weekly,
        monthly: ruleEntity.leaderboardFrequency && ruleEntity.leaderboardFrequency.monthly,
        campaign: ruleEntity.leaderboardFrequency && ruleEntity.leaderboardFrequency.campaign,
        iterationsPerFrequency: ruleEntity.iterationsPerFrequency,
        timezone: ruleEntity.timezone,
        localTimezoneStr: `Set at ${ruleEntity.timezone} (UTC${getTimezoneOffset(ruleEntity.timezone)})`,
        propertyOptions,
        userEventsCustomize: initialState.customize.userEventsCustomize,
        testSchedulingDays: ruleEntity.testSchedulingDays,
        testDaysError: ruleEntity.testSchedulingDays > 0 ? '' : 'Test scheduling days should be above zero',
        domain: ruleEntity.domain,
        redirectionUrl: ruleEntity.redirectionUrl,
        tncContentBgColor: ruleEntity.tncContentBgColor,
        titleObj: ruleEntity.titleObj,
        tncContentText: ruleEntity.tncContentText,
        bannerImageFlag: ruleEntity.bannerImageFlag,
        // tncImageName: ruleEntity.tncImageName,
        // tncImageUrl: ruleEntity.tncImageUrl,
        tncTitle: ruleEntity.tncTitle,
        buttonConfig: ruleEntity.buttonConfig,
        initialValues: state.customize.initialValues,
        tncImageName: ruleEntity.tncImageName,
        tncImageUrl: ruleEntity.tncImageUrl,
        /*
        tncTitle: {
          text: '',
          color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#FFFFFF' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
          font: {
            size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '16' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
            strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
            family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
            align: 'center',
          },
        },
        buttonConfig: {
          buttonText: {
            text: 'I Agree',
            color: '#ffffff',
            font: {
              size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontSize,
              strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontWt,
              family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFont,
              align: 'centre',
            },
          },
          textColor: '#ffffff',
          width: '50%',
          radius: '8px',
          shadow: '0px 0px',
          shadowColor: '#cdcdcd',
          buttonPosition: 'center',
          bgColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#F67A3E' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
          border: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#000000' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
          hoverBgColor: '#cdcdcd',
          buttonTextError: '',
          borderWidth: '1px',
        }
        */
      };
      let selectedDays = [];
      const selectedDaysTimings = [];
      ruleEntity.applyOffersOn.forEach((offer) => {
        selectedDays.push(offer.day - 1);
        selectedDaysTimings[offer.day - 1] = ({
          start: offer.startTime,
          end: offer.endTime
        });
      });
      selectedDays = selectedDays.sort((a, b) => {
        return a - b;
      });
      let { events } = ruleEntity.userSegment;
      events = events && events.map((event) => {
        event.property = event.property.map((prop) => {
          return {
            ...prop,
            title: prop.name,
            value: prop.name
          };
        });
        event.userProperty = event.userProperty.map((userProp) => {
          const propObj = event.property.find(ele => ele.name === userProp.name);
          userProp.options = propObj.options;
          return userProp;
        });
        return event;
      });
      if(events.length === 0) {
        events = state.schedule.events;
      }
      const schedule = {
        offerStartPublish: 'later',
        offerStartDate: DateTime.fromMillis(ruleEntity.startDateTime, { zone: ruleEntity.timezone }),
        offerEndPublish: ruleEntity.endDateTime === 9999999999999 ? 'never' : 'later',
        offerEndDate: ruleEntity.endDateTime === 9999999999999 ? DateTime.now().setZone(ruleEntity.timezone) : DateTime.fromMillis(ruleEntity.endDateTime, { zone: ruleEntity.timezone }),
        selectedDays,
        selectedDaysTimings,
        howOftenSelected: ruleEntity.howOften,
        trigger: ruleEntity.trigger,
        frequency: ruleEntity.frequency,
        events,
        editRuleId: ruleEntity.id,
        customEventRuleRewardDTO: ruleEntity.customEventRuleRewardDTO,
        pageId: ruleEntity.pageId ? ruleEntity.pageId : '',
        smartTemplatesWeightageType: ruleEntity.smartTemplatesWeightageType,
        smartTemplates: ruleEntity.smartTemplates,
        testUserSegmentSize: ruleEntity.testUserSegmentSize,
        testRewardAssignment: ruleEntity.testRewardAssignment,
        separateFinalReward: ruleEntity.separateFinalReward,
        finalRewardAssignment: ruleEntity.finalRewardAssignment,
        spinTemplatesTestConfig: ruleEntity.spinTemplatesTestConfig,
        spinTemplatesFinalConfig: ruleEntity.spinTemplatesFinalConfig,
        spinTemplatesCount: ruleEntity.spinTemplatesCount,
        regularTemplatesCount: ruleEntity.regularTemplatesCount,
        errors: backUpErrors,
        resetSpinRewards: [
          {
            offerId: '',
            offerName: '',
            percentage: 0,
            quadrant: 0,
            text: ''
          }
        ],
        testStatus: ruleEntity.testStatus,
        testEndedStatus: ruleEntity.testStatus === 'inactive',
        tncFlag: ruleEntity.tncFlag,
        favouredTemplate: ruleEntity.favouredTemplate,
        finalTemplateType: ruleEntity.finalTemplateType && ruleEntity.finalTemplateType !== '' ? ruleEntity.finalTemplateType : 'custom',
        selectedFinalTemplate: ruleEntity.selectedFinalTemplate,
        favouredTemplateName: ruleEntity.favouredTemplateName,
        templateId: ruleEntity.templateId,
        smartEditBlocked: ruleEntity.ruleStatus !== 'upcoming',
        smartTestEndEditAllowed: ruleEntity.ruleStatus === 'active' && ruleEntity.testStatus === 'active',
      };
      const rewards = state.options.rewards;
      rewards.push({ title: 'No Offer', value: 'No Offer' });
      return {
        ...state,
        customize,
        schedule,
        quadrantSegment: customize.quadrantReward,
        saveRuleLoading: false,
        loading: false,
        error: false,
        loaded: true,
        activeNavTab: 'customize',
        options: { ...state.options, ...{ rewards } },
      };
    }

    case GET_RULE_CE_FAILURE: {
      return {
        ...state,
        saveRuleLoading: false,
        loading: false,
        loaded: false,
        error: true,
      };
    }

    case UPDATE_TEMPLATE_DATA: {
      const customize = {
        ...state.customize,
        winningQuadrant: [],
        lossingQuadrant: 0,
        quadrantReward: []
      };
      return {
        ...state,
        customize
      };
    }
    /*
    case UPDATE_FIELD: {
      state = state
        ? {
          ...state,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    */
    case UPDATE_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_CUSTOMIZE_FIELD: {
      state.customize = state.customize
        ? {
          ...state.customize,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }

    case UPDATE_OPTIONS: {
      state.options = state.options
        ? {
          ...state.options,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }

    case UPDATE_SCHEDULE_FIELD: {
      if (action.payload.selectedDays) {
        action.payload.selectedDays.sort((a, b) => {
          return a - b;
        });
      }
      const schedule = {
        ...state.schedule,
        ...action.payload
      };
      return { ...state, schedule };
    }

    case UPDATE_WINNING_QUADRANT: {
      const winingQuadrant = action.payload.winningQuadrant;
      if(winingQuadrant instanceof Array) {
        state.quadrantSegment.forEach((winQ, index) => {
          if(getIndexedQuadrants((index + 1), winingQuadrant)) {
            state.quadrantSegment[index].disabled = false;
          }else {
            state.quadrantSegment[index].disabled = true;
          }
        });
        const losingCount = (state.quadrantSegment.length - winingQuadrant.length);
        state.customize.lossingQuadrant = losingCount;
        state.customize.quadrantReward = state.customize.quadrantReward.map((rewQ) => {
          const winQindex = winingQuadrant.findIndex(winq => (winq && winq.value === rewQ.quadrant));
          if(winQindex === -1) {
            return {
              ...rewQ,
              offerId: '',
              offerName: '',
              score: '=',
              winingQuadrant: false,
              percentage: 0
            };
          }
          return rewQ;
        });
      } else {
        const quadrant = [];
        for (let i = 0; i < action.payload.winningQuadrant; i += 1) {
          const data = {
            offerId: '',
            offerName: '',
            quadrant: i + 1,
            resultText: '',
            text: `Rank ${i + 1}`,
            winingQuadrant: true,
            percentage: 0
          };
          quadrant.push(data);
        }
        state.customize.quadrantReward = quadrant;
        state.quadrantSegment = quadrant;
      }
      state.customize = state.customize
        ? {
          ...state.customize,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }

    case VALIDATE_CUSTOMIZE_DATA: {
      const {
        ruleName,
        // template,
        // gamifyE,
        // winningQuadrant,
        // quadrantReward,
        // rankRewards,
        typeID,
        // scratchRewards,
        // gameMechanicsRuleType,
        // customActivityEvents
        // rewardOption,
        // leaderboard,
        // addLeaderboard,
        // referralRewards
      } = state.customize;
      const { customEventRuleRewardDTO } = state.schedule;
      let error = {
        validCustomize: true,
      };
      if(ruleName === '') {
        error = {
          ...error,
          validCustomize: false,
          ruleNameError: 'Rule name is Required'
        };
        console.log('typeID', typeID);
      }
      if(ruleName !== '' && (ruleName.length < 3 || ruleName.length > 50)) {
        error = {
          ...error,
          validCustomize: false,
          ruleNameError: 'RuleName should be of min 3 and max 50 characters'
        };
      }
      if(customEventRuleRewardDTO.eventName === '') {
        error = {
          ...error,
          validCustomize: false,
          rewardError: 'Event is needed'
        };
      } else if(customEventRuleRewardDTO.property === '') {
        error = {
          ...error,
          validCustomize: false,
          rewardError: 'Property is needed'
        };
      } else {
        customEventRuleRewardDTO.conditions.forEach((condition, index) => {
          if (condition.operator === '') {
            error = {
              ...error,
              validCustomize: false,
              rewardError: 'Operator is needed'
            };
          }
          if (condition.values.length === 0) {
            if (condition.operator !== 'is_empty' && condition.operator !== 'is_null') {
              error = {
                ...error,
                validCustomize: false,
                rewardError: 'Value is needed'
              };
            }
          }
          if (condition.rewards.length === 0) {
            error = {
              ...error,
              validCustomize: false,
              rewardError: 'reward is needed'
            };
          }
          let percentageSum = 0;
          condition.rewards.forEach((reward) => {
            if(reward.offerName === '') {
              error = {
                ...error,
                validCustomize: false,
                rewardError: 'Condition ' + (index + 1) + ' reward is needed'
              };
            }
            percentageSum += Number(reward.percentage);
          });
          if (percentageSum !== 100) {
            error = {
              ...error,
              validCustomize: false,
              rewardError: 'Condtion ' + (index + 1) + ' percentages should add up to 100'
            };
          }
        });
      }
      /*
      if(gamifyE === '') {
        error = {
          ...error,
          validCustomize: false,
          templateError: 'Gamification Activity is Required'
        };
      }
      if(gameMechanicsRuleType === 2 && (!state.customize.daily && !state.customize.weekly && !state.customize.monthly && !state.customize.campaign)) {
        error = {
          ...error,
          validCustomize: false,
          frequencyError: 'Select atleast one of Daily, weekly, monthly, campaign'
        };
      }
      if(gameMechanicsRuleType === 2) {
        for(let i = 0; i < customActivityEvents.length; i += 1) {
          if(customActivityEvents[i].activity === '') {
            error = {
              ...error,
              validCustomize: false,
              lbActivityError: 'Select Activity/Events'
            };
          }
          if(customActivityEvents[i].events === '') {
            error = {
              ...error,
              validCustomize: false,
              lbEventsError: 'select an event'
            };
          }
          if(customActivityEvents[i].offerId === '') {
            error = {
              ...error,
              validCustomize: false,
              lbOfferError: 'Offer is required'
            };
          }
          if(customActivityEvents[i].property === '') {
            error = {
              ...error,
              validCustomize: false,
              lbPropertyError: 'Property is Required'
            };
          }
          if (customActivityEvents[i].operator === '') {
            error = {
              ...error,
              validCustomize: false,
              lbOperatorError: 'Operator is Required'
            };
          }
          if(customActivityEvents[i].completed === '') {
            error = {
              ...error,
              validCustomize: false,
              lbTimesError: 'No. of times is Required'
            };
          }
          if(customActivityEvents[i].points === '') {
            error = {
              ...error,
              validCustomize: false,
              lbPointsError: 'points is Required'
            };
          }
          if(customActivityEvents[i].value.length === 0) {
            error = {
              ...error,
              validCustomize: false,
              lbValueError: 'value is Required'
            };
          }
        }
      }
      if(template === '') {
        error = {
          ...error,
          validCustomize: false,
          templateError: 'Template is Required'
        };
      }
      if(template !== '') {
        if(typeID !== 2) {
          const offer = [];
          for (let i = 0; i < scratchRewards.length; i += 1) {
            offer.push(scratchRewards[i].offerId);
          }
          const exists = (new Set(offer)).size !== offer.length;
          if (exists) {
            error = {
              ...error,
              validCustomize: false,
              rewardQError: 'Same Offers cannot be seletected. Select unique offer'
            };
          }
        }
      }
      */
      const customize = {
        ...state.customize,
        ...error
      };
      return {
        ...state,
        customize
      };
    }
    case GET_SEGEMENT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SEGEMENT_SUCCESS: {
      const segement = action.result.segmentList;
      const segementOptions = [];
      for (let i = 0; i < segement.length; i += 1) {
        segementOptions.push({ title: segement[i].title, value: segement[i].value });
      }
      segementOptions.push({ title: 'All User', value: 'All User' });
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        options: { ...state.options, ...{ userSegment: segementOptions } },
      };
    }
    case GET_SEGEMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_USER_EVENT_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_USER_EVENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_USER_EVENT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case CREATE_OPTIONS: {
      const days = [];
      for (let i = 1; i <= 30; i += 1) {
        days.push({
          title: i,
          value: i
        });
      }
      let { options } = state;
      options = {
        ...options,
        days
      };
      return {
        ...state,
        options,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CUSTOMEVENT_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CUSTOMEVENT_CONFIG_SUCCESS: {
      const customEvents = action.result.CustomEvents.customEventsDTO;
      const templateOptions = [];
      let i = 0;
      for (; i < customEvents.length; i += 1) {
        if (customEvents[i].eventName != null) {
          templateOptions.push({ title: customEvents[i].eventName, value: customEvents[i].eventKey, typeID: '14' });
        }
        //  else {
        //   templateOptions.push({ title: customEvents[i].title, value: customEvents[i].id, typeID: customEvents[i].typeId });
        // }
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        options: { ...state.options, ...{ customEventsTemplates: templateOptions } },
      };
    }
    case GET_CUSTOMEVENT_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_BADGE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BADGE_CONFIG_SUCCESS: {
      const templates = action.result.Badges.badgesEntity;
      const templateOptions = [];
      for (let i = 0; i < templates.length; i += 1) {
        if (templates[i].templateName != null) {
          templateOptions.push({ title: templates[i].templateName, value: templates[i].id });
        } else {
          templateOptions.push({ title: templates[i].title, value: templates[i].id });
        }
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        options: { ...state.options, ...{ badgeTypeOptions: templateOptions } },
      };
    }
    case GET_BADGE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CUSTOMEVENT_CONFIG_LIST_SUCCESS: {
      const infoPages = action.result.InfoScreens.infoScreenEntities;
      const tournamentOptions = [];
      for (let i = 0; i < infoPages.length; i += 1) {
        if (infoPages[i].pageName != null) {
          tournamentOptions.push({
            title: infoPages[i].pageName, value: infoPages[i].id, typeID: '13', type: infoPages[i].typeId, templateId: infoPages[i].templateId
          });
        } else {
          tournamentOptions.push({
            title: infoPages[i].title, value: infoPages[i].id, typeID: '13', type: infoPages[i].typeId, templateId: infoPages[i].templateId
          });
        }
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        options: { ...state.options, ...{ infoPageTemplates: tournamentOptions } },
      };
    }
    case DELETE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DELETE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_SCHEDULE_ERRORS: {
      const { schedule } = { ...state };
      schedule.errors = action.payload.errors;
      return {
        ...state,
        schedule
      };
    }
    case UPDATE_BUTTON_CONFIG: {
      state.customize.buttonConfig = state.customize.buttonConfig
        ? {
          ...state.customize.buttonConfig,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_APPCONFIG_FIELD: {
      console.log(state.initialValues, 'something');
      state.initialValues = state.initialValues
        ? {
          ...state.initialValues,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_TERMS_FIELD: {
      state.terms = state.terms
        ? {
          ...state.terms,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case DUPLICATE_CONFIG_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DUPLICATE_CONFIG_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case DUPLICATE_CONFIG_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const createOptions = (type) => {
  if (type === 'days') {
    return {
      type: CREATE_OPTIONS,
    };
  }
  return {};
};

export const updateWinningQuadrant = (payload) => {
  return {
    type: UPDATE_WINNING_QUADRANT,
    payload,
  };
};

export const updateTemplateData = () => {
  return {
    type: UPDATE_TEMPLATE_DATA,
  };
};
export const validateCustomize = () => {
  return {
    type: VALIDATE_CUSTOMIZE_DATA,
  };
};

export const updateCustomize = (payload) => {
  return {
    type: UPDATE_CUSTOMIZE_FIELD,
    payload,
  };
};

export const updateSchedule = (payload) => {
  return {
    type: UPDATE_SCHEDULE_FIELD,
    payload,
  };
};

export const clearForm = () => {
  return {
    type: CLEAR_FORM,
  };
};

export const saveSpinWheelConfig = (data) => {
  return {
    types: [SAVE_CONFIG_REQUESTED, SAVE_CONFIG_SUCCESS, SAVE_CONFIG_FAILURE],
    promise: client => client.post('saveSpinWheelConfig', {
      data,
    }),
  };
};

export const getSpinWheelConfig = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(
      `getSpinWheelConfig/${data.itemsPerPage}/${data.pageNo}?title=${data.title}&sortBy=${data.sortBy}&orderBy=${
        data.orderBy
      }`
    ),
  };
};

// export const getTournamentList = (data) => {
//   return {
//     types: [GET_CONFIG_REQUESTED, GET_TOURNAMENT_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
//     promise: client => client.get(
//       `getTournamentList/${data.itemsPerPage}/${data.pageNo}?title=${data.title}&sortBy=${data.sortBy}&orderBy=${
//         data.orderBy
//       }`
//     ),
//   };
// };

export const getProgressBarList = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_PROGRESSBAR_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(
      `getProgressBarTemplateConfig/${data.itemsPerPage}/${data.pageNo}?title=${data.title}&sortBy=${data.sortBy}&orderBy=${
        data.orderBy}`
    ),
  };
};

export const getMyGameMechanics = (data) => {
  let reqParams = '';
  if (data.searchKey !== '') {
    reqParams = `${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&sortType=${data.orderBy}`;
  } else {
    reqParams = `${data.itemsPerPage}/${data.pageNo}?searchKey=&sortBy=${data.sortBy}&sortType=${data.orderBy}`;
  }
  return {
    types: [GET_CONFIG_REQUESTED, GET_LEADERBOARD_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getGameMechanicsTemplates/${reqParams}`)
  };
};

export const getConfigRule = (gamificationId) => {
  return {
    types: [GET_CONFIG_RULE_REQUESTED, GET_CONFIG_RULE_SUCCESS, GET_CONFIG_RULE_FAILURE],
    promise: client => client.get(`getConfigRule?gamificationId=${gamificationId}`),
  };
};

// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const updateOptions = (payload) => {
  return {
    type: UPDATE_OPTIONS,
    payload,
  };
};

export const getMyOffers = (data) => {
  let reqParams = '';
  if (data.searchKey !== '') {
    reqParams = `${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&sortType=${
      data.orderBy
    }&templateId=${data.templateId}`;
  } else {
    reqParams = `${data.itemsPerPage}/${data.pageNo}?searchKey=&sortBy=${data.sortBy}&sortType=${data.orderBy}&templateId=${data.templateId}`;
  }
  return {
    types: [GET_OFFER_REQUESTED, GET_OFFER_SUCCESS, GET_OFFER_FAILURE],
    promise: client => client.get(`getMyOffersList/${reqParams}`),
  };
};

const getApplyOffers = (array) => {
  const timingsArray = [];
  array.forEach((time, index) => {
    if(time !== null) {
      timingsArray.push({
        day: (index + 1),
        startTime: time.start,
        endTime: time.end
      });
    }
  });
  return timingsArray;
};

const getWinningQuadrantsIds = (winningQuadrant, typeID) => {
  if (typeID !== 1 && typeID !== 6) {
    return winningQuadrant.map(e => e.value).join(',');
  }
  if (winningQuadrant.length === 0) {
    return '';
  }
  return winningQuadrant;
};

const getRewardAssignment = (rewards, winingText, losingText) => {
  return rewards.map((e) => {
    if(e.offerName !== 'No Offer') {
      return {
        ...e,
        resultText: winingText,
        winingQuadrant: true
      };
    }
    return {
      ...e,
      resultText: losingText,
      winingQuadrant: false,
      offerId: e.offerId !== 'No Offer' ? e.offerId : '',
      offerName: e.offerName !== 'No Offer' ? e.offerName : ''
    };
  });
};

const getRankReward = (rewards, winningText, lossingText) => {
  return rewards.map((e) => {
    if(e.offerName !== 'No Offer') {
      return {
        ...e,
        resultText: winningText,
        winingQuadrant: true
      };
    }
    return {
      ...e,
      resultText: lossingText,
      winingQuadrant: false,
      offerId: e.offerId !== 'No Offer' ? e.offerId : '',
      offerName: e.offerName !== 'No Offer' ? e.offerName : ''
    };
  });
};

const getRewardForRank = (rewards, winningText, lossingText, typeId, quadrantReward) => {
  if (typeId !== 2) {
    return getRankReward(rewards, winningText, lossingText);
  }
  return getRewardAssignment(quadrantReward, winningText, lossingText);
};

const getReward = (rewards, referralRewards, winningText, lossingText, typeID) => {
  if(typeID === 12) {
    return referralRewards;
  }
  let quadrant = 0;
  return rewards.map((e) => {
    const element = [];
    if (typeID === 5) {
      element.push(e.elements1);
      element.push(e.elements2);
      element.push(e.elements3);
    }
    if (typeID === 9) {
      quadrant += 1;
    }
    return {
      percentage: e.winning,
      offerId: e.offerId !== 'No Offer' ? e.offerId : '',
      offerName: e.offerName !== 'No Offer' ? e.offerName : '',
      resultText: e.offerName !== 'No Offer' ? winningText : lossingText,
      winingQuadrant: e.offerName !== 'No Offer',
      score: e.score,
      elements: element,
      quadrant
    };
  });
};

export const getRuleEngineData = (ruleState, query) => {
  /*
  let operator = '';
  query.forEach((qu, index) => {
    operator += `Q${(index + 1)}`;
    if ((query[index + 1])) {
      operator += ` ${this.getOperator(qu.operator.toLowerCase())} `;
    }
  });
  */
  const userSegment = {
    events: ruleState.schedule.trigger ? ruleState.schedule.events : [],
    query: ruleState.schedule.trigger ? query : [],
    // operator: ruleState.schedule.trigger ? operator : ''
  };
  const leaderboardFrequency = {
    daily: ruleState.customize.daily,
    weekly: ruleState.customize.weekly,
    monthly: ruleState.customize.monthly,
    campaign: ruleState.customize.campaign,
  };
  const startDate = ruleState.schedule.offerStartDate.set({ second: 0 }).ts;
  const endDate = ruleState.schedule.offerEndPublish === 'later' ? ruleState.schedule.offerEndDate.set({ second: 0 }).ts : 9999999999999;
  const data = {
    ruleId: ruleState.customize.ruleId ? ruleState.customize.ruleId : '',
    name: ruleState.customize.ruleName,
    templateId: ruleState.schedule.templateId ? ruleState.schedule.templateId : '',
    customType: ruleState.customize.customType,
    customDays: Number(ruleState.customize.customDays),
    customTime: ruleState.customize.customTime,
    type: ruleState.customize.typeID,
    userSegmentId: ruleState.customize.userSegment === 'All User' ? '' : ruleState.customize.userSegment,
    gameMechanicsRuleType: ruleState.customize.gameMechanicsRuleType,
    numberOfEvents: ruleState.customize.numberOfEvents,
    ruleStatus: ruleState.customize.status,
    combination: ruleState.customize.combination,
    winningQuandrants: getWinningQuadrantsIds(ruleState.customize.winningQuadrant, ruleState.customize.typeID),
    losingQuandrants: ruleState.customize.lossingQuadrant,
    winingText: ruleState.customize.winningText.text,
    winingTextFontColor: ruleState.customize.winningText.color,
    winingTextFontSize: ruleState.customize.winningText.font.size,
    winingTextFontWeight: ruleState.customize.winningText.font.strength,
    winingTextFont: ruleState.customize.winningText.font.family,
    winingTextPlace: ruleState.customize.winningText.font.align,
    winingBackgroundImage: ruleState.customize.winningText.img,
    winingBackgroundImageName: ruleState.customize.winningText.imageName,
    losingText: ruleState.customize.lossingText.text,
    losingTextFontColor: ruleState.customize.lossingText.color,
    losingTextFontSize: ruleState.customize.lossingText.font.size,
    losingTextFontWeight: ruleState.customize.lossingText.font.strength,
    losingTextFont: ruleState.customize.lossingText.font.family,
    losingTextPlace: ruleState.customize.lossingText.font.align,
    losingBackgroundImage: ruleState.customize.lossingText.img,
    losingBackgroundImageName: ruleState.customize.lossingText.imageName,
    howOften: ruleState.schedule.howOftenSelected,
    startDateTime: startDate,
    endDateTime: endDate,
    rewardAssigment: (ruleState.customize.typeID === 2 || ruleState.customize.typeID === 8 || ruleState.customize.typeID === 13 || ((ruleState.customize.typeID === 1 || ruleState.customize.typeID === 6) && ruleState.customize.rewardOption === 'rank'))
      ? getRewardForRank(ruleState.customize.rankRewards, ruleState.customize.winningText.text, ruleState.customize.lossingText.text, ruleState.customize.typeID, ruleState.customize.quadrantReward)
      : getReward(ruleState.customize.scratchRewards, ruleState.customize.referralRewards, ruleState.customize.winningText.text, ruleState.customize.lossingText.text, ruleState.customize.typeID),
    applyOffersOn: ruleState.schedule.frequency ? getApplyOffers(ruleState.schedule.selectedDaysTimings) : [],
    trigger: ruleState.schedule.trigger,
    frequency: ruleState.schedule.frequency,
    userSegment,
    rewardOption: ruleState.customize.rewardOption,
    ruleType: 5,
    leaderBoardSelected: ((ruleState.customize.typeID === 1 || ruleState.customize.typeID === 6 || ruleState.customize.typeID === 2) && ruleState.customize.addLeaderboard === 'yes') || ruleState.referralLeaderboard || ruleState.customize.typeID === 13 || ruleState.customize.typeID === 17,
    repeatLeaderBoard: ruleState.customize.repeatLeaderBoard === 'yes',
    leaderBoardId: (ruleState.customize.typeID === 12) ? ruleState.leaderboardTemplate : ruleState.customize.leaderboard,
    leaderboardFrequency,
    iterationsPerFrequency: ruleState.customize.iterationsPerFrequency,
    timezone: ruleState.customize.timezone,
    eventSelect: ruleState.customize.eventSelect,
    customActivityEvents: ruleState.customize.customActivityEvents,
    eventCompletion: ruleState.customize.eventCompletion,
    offer: ruleState.customize.offer,
    badges: ruleState.customize.badges,
    gamifyE: ruleState.customize.gamifyE,
    rewardEachEvent: ruleState.customize.rewardEachEvent,
    customEventRuleRewardDTO: ruleState.schedule.customEventRuleRewardDTO,
    pageId: ruleState.schedule.pageId,
    smartTemplates: ruleState.schedule.smartTemplates,
    smartTemplatesWeightageType: ruleState.schedule.smartTemplatesWeightageType,
    testUserSegmentSize: ruleState.schedule.testUserSegmentSize,
    testRewardAssignment: ruleState.schedule.testRewardAssignment,
    finalRewardAssignment: ruleState.schedule.finalRewardAssignment,
    spinTemplatesTestConfig: ruleState.schedule.spinTemplatesTestConfig,
    spinTemplatesFinalConfig: ruleState.schedule.spinTemplatesFinalConfig,
    separateFinalReward: ruleState.schedule.separateFinalReward,
    tncFlag: ruleState.schedule.tncFlag,
    testSchedulingDays: ruleState.customize.testSchedulingDays,
    testStatus: ruleState.schedule.testEndedStatus ? 'inactive' : 'active',
    domain: ruleState.customize.domain,
    titleObj: ruleState.customize.titleObj,
    titleBarColor: ruleState.customize.titleObj.titleBarColor,
    // tncFlag: ruleState.schedule.tncFlag,
    placement: ruleState.customize.placement,
    bannerImageFlag: ruleState.customize.bannerImageFlag,
    position: ruleState.customize.position,
    tncTitle: ruleState.customize.tncTitle,
    redirectionUrl: ruleState.customize.redirectionUrl,
    tncContentText: ruleState.customize.tncContentText,
    buttonConfig: ruleState.customize.buttonConfig,
    tncImageUrl: ruleState.customize.tncImageUrl,
    tncImageName: ruleState.customize.tncImageName,
    initialValues: ruleState.initialValues,
    tncContentBgColor: ruleState.customize.tncContentBgColor,
    tncTitleError: ruleState.customize.tncTitleError,
    finalTemplateType: ruleState.schedule.finalTemplateType,
    selectedFinalTemplate: ruleState.schedule.finalTemplateType === 'custom' ? ruleState.schedule.selectedFinalTemplate : ruleState.schedule.favouredTemplate
  };
  return data;
};

export const saveRule = (ruleState, query) => {
  const data = getRuleEngineData(ruleState, query);
  return {
    types: [SAVE_RULE_REQUESTED, SAVE_RULE_SUCCESS, SAVE_RULE_FAILURE],
    promise: client => client.post('saveRule', {
      data,
    }),
  };
};

export const getRuleCE = (ruleId, eventList) => {
  initialState.customize.userEventsCustomize = eventList;
  return {
    types: [GET_RULE_CE_REQUESTED, GET_RULE_CE_SUCCESS, GET_RULE_CE_FAILURE],
    promise: client => client.get(`getSmartCampaign?ruleId=${ruleId}`),
  };
};

export const uploadimage = (file, folderName) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/gamification/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
      .field('folderName', folderName)
  };
};

export const getSegment = () => {
  return {
    types: [GET_SEGEMENT_REQUESTED, GET_SEGEMENT_SUCCESS, GET_SEGEMENT_FAILURE],
    promise: client => client.get('getSegmentList'),
  };
};

export const getEvents = () => {
  return {
    types: [GET_USER_EVENT_REQUEST, GET_USER_EVENT_SUCCESS, GET_USER_EVENT_FAILURE],
    promise: client => client.get('getUserEvents')
  };
};

export const checkRuleNameDublicate = (name, id) => {
  return {
    types: [CHECK_RULE_NAME_REQUEST, CHECK_RULE_NAME_SUCCESS, CHECK_RULE_NAME_FAILURE],
    promise: client => client.get(`checkRuleName?name=${name}&id=${id}`),
  };
};

export const getCustomEventsConfig = (data) => {
  return {
    types: [GET_CUSTOMEVENT_CONFIG_REQUESTED, GET_CUSTOMEVENT_CONFIG_SUCCESS, GET_CUSTOMEVENT_CONFIG_FAILURE],
    promise: client => client.get(
      `getCustomEventsConfig/${data.itemsPerPage}/${data.pageNo}?title=${data.title}&sortBy=${data.sortBy}&orderBy=${
        data.orderBy
      }`
    ),
  };
};

export const getGameMechanicsConfig = (data) => {
  return {
    types: [GET_BADGE_CONFIG_REQUESTED, GET_BADGE_CONFIG_SUCCESS, GET_BADGE_CONFIG_FAILURE],
    promise: client => client.get(`getGameMechanicsConfig/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.title}&sortBy=${data.sortBy}&sortType=${data.orderBy}`)
  };
};

/*
export const getCustomEvents = () => {
  return {
    types: [GET_USER_EVENT_REQUEST, GET_USER_EVENT_SUCCESS, GET_USER_EVENT_FAILURE],
    promise: client => client.get('getCustomUserEvents')
  };
};


export const getCustomEventsRuleList = (data) => {
  return {
    types: [GET_RULE_ENGINE_LIST_REQUESTED, GET_RULE_ENGINE_LIST_SUCCESS, GET_RULE_ENGINE_LIST_FAILURE],
    promise: client => client.get(`getCustomEventsRuleList/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.title}&sortBy=${data.sortBy}&sortType=${data.orderBy}`)
  };
};
*/
export const getInfoPagesList = (data) => {
  return {
    types: [GET_CUSTOMEVENT_CONFIG_LIST_REQUESTED, GET_CUSTOMEVENT_CONFIG_LIST_SUCCESS, GET_CUSTOMEVENT_CONFIG_LIST_FAILURE],
    promise: client => client.get(
      `getInfoScreenPageConfigsList/${data.itemsPerPage}/${data.pageNo}?title=${data.title}&sortBy=${data.sortBy}&orderBy=${
        data.orderBy
      }`
    ),
  };
};

export const getSmartCampaignsList = (data) => {
  return {
    types: [GET_RULE_ENGINE_LIST_REQUESTED, GET_RULE_ENGINE_LIST_SUCCESS, GET_RULE_ENGINE_LIST_FAILURE],
    promise: client => client.get(`getSmartCampaignsList/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.title}&sortBy=${data.sortBy}&sortType=${data.orderBy}`)
  };
};

export const checkDeleteRule = (data) => {
  return {
    types: [DELETE_CONFIG_REQUESTED, DELETE_CONFIG_SUCCESS, DELETE_CONFIG_FAILURE],
    promise: client => client.post(`deleteRuleEngine?id=${data}`)
  };
};

export const clearSchedule = () => {
  return {
    type: CLEAR_FORM,
  };
};

export const updateScheduleErrors = (payload) => {
  return {
    type: UPDATE_SCHEDULE_ERRORS,
    payload
  };
};

export const getAppConfig = () => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get('getAppConfig'),
  };
};

export const updateButtonConfig = (payload) => {
  return {
    type: UPDATE_BUTTON_CONFIG,
    payload
  };
};

export const updateDropdown = (payload) => {
  return {
    type: UPDATE_APPCONFIG_FIELD,
    payload,
  };
};

export const updateTerms = (payload) => {
  console.log('updateterms======', payload);
  return {
    type: UPDATE_TERMS_FIELD,
    payload,
  };
};

export const duplicateRuleEngine = (data) => {
  return {
    types: [DUPLICATE_CONFIG_REQUESTED, DUPLICATE_CONFIG_SUCCESS, DUPLICATE_CONFIG_FAILURE],
    promise: client => client.get(`duplicateRuleEngine?ruleId=${data}`)
  };
};
