export const REGISTRATION_REQUESTED = 'registration/REGISTRATION_REQUESTED';
export const REGISTRATION_SUCCESS = 'registration/REGISTRATION_SUCCESS';
export const REGISTRATION_FAILURE = 'registration/REGISTRATION_FAILURE';

export const VERIFY_EMAIL_REQUESTED = 'registration/VERIFY_EMAIL_REQUESTED';
export const VERIFY_EMAIL_SUCCESS = 'registration/VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILURE = 'registration/VERIFY_EMAIL_FAILURE';

export const INDUSTRY_LIST_REQUESTED = 'registration/INDUSTRY_LIST_REQUESTED';
export const INDUSTRY_LIST_SUCCESS = 'registration/INDUSTRY_LIST_SUCCESS';
export const INDUSTRY_LIST_FAILURE = 'registration/INDUSTRY_LIST_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorDesc: [],
  isVerified: true,
  industryIdList: [],
  timeZoneIdList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REGISTRATION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case REGISTRATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case REGISTRATION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        errorDesc: action && action.error && action.error.response.data,
      };
    }
    case VERIFY_EMAIL_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
        isVerified: false
      };
    }
    case VERIFY_EMAIL_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        isVerified: true
      };
    }
    case VERIFY_EMAIL_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        isVerified: false
      };
    }
    case INDUSTRY_LIST_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case INDUSTRY_LIST_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        industryIdList: action && action.result && action.result.industry
      };
    }
    case INDUSTRY_LIST_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};


export const register = (data) => {
  const headers = { has_auth_token: 0 };
  return {
    types: [REGISTRATION_REQUESTED, REGISTRATION_SUCCESS, REGISTRATION_FAILURE],
    promise: client => client.post('register', {
      headers,
      data
    })
  };
};

export const verifyEmail = (data) => {
  return {
    types: [VERIFY_EMAIL_REQUESTED, VERIFY_EMAIL_SUCCESS, VERIFY_EMAIL_FAILURE],
    promise: client => client.post('verifyEmail', {
      data
    })
  };
};

export const getIndustryList = () => {
  return {
    types: [INDUSTRY_LIST_REQUESTED, INDUSTRY_LIST_SUCCESS, INDUSTRY_LIST_FAILURE],
    promise: client => client.get('getIndustryList')
  };
};
