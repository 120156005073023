export const TEMPORARY_PASSWORD_REQUESTED = 'password/TEMPORARY_PASSWORD_REQUESTED';
export const TEMPORARY_PASSWORD_SUCCESS = 'password/TEMPORARY_PASSWORD_SUCCESS';
export const TEMPORARY_PASSWORD_FAILURE = 'password/TEMPORARY_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUESTED = 'password/RESET_PASSWORD_REQUESTED';
export const RESET_PASSWORD_SUCCESS = 'password/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'password/RESET_PASSWORD_FAILURE';

export const VERIFY_LINK_REQUESTED = 'password/VERIFY_LINK_REQUESTED';
export const VERIFY_LINK_SUCCESS = 'password/VERIFY_LINK_SUCCESS';
export const VERIFY_LINK_FAILURE = 'password/VERIFY_LINK_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errMessage: '',
  code: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TEMPORARY_PASSWORD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case TEMPORARY_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        userdata: action && action.result && action.result.message,
        code: action && action.result && action.result.code
      };
    }
    case TEMPORARY_PASSWORD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    }
    case RESET_PASSWORD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true
      };
    }
    case VERIFY_LINK_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case VERIFY_LINK_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case VERIFY_LINK_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true
      };
    }
    default:
      return state;
  }
};


export const getForgotPasswordLink = (data) => {
  return {
    types: [TEMPORARY_PASSWORD_REQUESTED, TEMPORARY_PASSWORD_SUCCESS, TEMPORARY_PASSWORD_FAILURE],
    promise: client => client.post('getForgotPasswordLink', {
      data
    })
  };
};

export const resetPassword = (data) => {
  return {
    types: [RESET_PASSWORD_REQUESTED, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE],
    promise: client => client.post('resetPassword', {
      data
    })
  };
};

export const verifyLink = (data) => {
  return {
    types: [VERIFY_LINK_REQUESTED, VERIFY_LINK_SUCCESS, VERIFY_LINK_FAILURE],
    promise: client => client.post('forgotPasswordVerifyLink', {
      data
    })
  };
};
