import React from 'react';
import { ToastContainer } from 'react-toastify';
import cn from 'classnames';
import Styles from './App.module.css';

const AppBody = (props) => {
  return (
    <div className={cn(Styles.appContainer, 'zoom_75')}>
      {props.children}
      <ToastContainer position="bottom-right" />
    </div>
  );
};


export default AppBody;
