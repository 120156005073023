export const UPDATE_LIST_FIELD = 'webhook/UPDATE_LIST_FIELD';
export const UPDATE_FIELD = 'webhook/UPDATE_FIELD';
export const UPDATE_CUSTOMIZE_FIELD = 'webhook/UPDATE_CUSTOMIZE_FIELD';
export const VALIDATE_CUSTOMIZE_DATA = 'webhook/VALIDATE_CUSTOMIZE_DATA';
export const UPDATE_PAGE = 'webhook/UPDATE_PAGE';
export const CLEAR_STATE = 'webhook/CLEAR_STATE';
export const CLEAR_FORM = 'webhook/CLEAR_FORM';

export const CLEAR_WEBHOOK_FORM = 'webhook/CLEAR_WEBHOOK_FORM';

export const REQUESTED = 'REQUESTED';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export const GET_WEBHOOK_BY_ID_REQUESTED = 'GET_WEBHOOK_BY_ID_REQUESTED';
export const GET_WEBHOOK_BY_ID_SUCCESS = 'GET_WEBHOOK_BY_ID_SUCCESS';
export const GET_WEBHOOK_BY_ID_FAILURE = 'GET_WEBHOOK_BY_ID_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorDesc: [],
  code: '',
  msg: '',
  tableData: {},
  trigger: false,
  customize: {
    nameExistsError: false,
    webhookName: '',
    customPlaceHoldersDefined: 'no',
    customPlaceHolders: [''],
    authUrl: '',
    authDisplayUrl: '',
    authURLReq: 'no',
    authRequestBodyNeeded: 'no',
    requestBodyNeeded: 'no',
    authRequestParam: [
      {
        key: '',
        value: '',
      }
    ],
    authHeaderKey: [
      {
        key: '',
        value: '',
      }
    ],
    authParamKey: [
      {
        key: '',
        value: '',
      }
    ],
    authJsonKey: '',
    url: '',
    displayUrl: '',
    requestParam: [
      {
        key: '',
        value: '',
      }
    ],
    headerKey: [
      {
        key: '',
        value: '',
      }
    ],
    paramKey: [
      {
        key: '',
        value: '',
      }
    ],
    jsonKey: [
      {
        key: '',
        value: '',
      }
    ],
    webhookResponse: '',
    authRequestType: 'GET',
    authRequestBodyType: 'Text',
    authRequestBodyVal: '',
    authResponseType: 'Text',
    requestType: 'GET',
    requestBodyType: 'Text',
    requestBodyVal: '',
    responseType: 'Text',
    responseStringOper: 'equal',
    responseStringValue: '',
    transactionIdNeeded: false,
    transactionIdJsonKey: '',
    mode: 'save',
    allowEdit: true,
  },
  initialData: {
    itemsPerPage: 5,
    pageNo: 0,
    searchKey: '',
    sortBy: 'updatedTS',
    orderBy: 'desc',
    activeTab: 'webhooks_list',
    webhookId: '',
    headers: [
      {
        title: 'Webhook name',
        slug: 'webhook_name',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: false,
        sortByOrder: '',
        width: 200,
      },
      {
        title: 'Created By',
        slug: 'createdby',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: '',
        width: 90,
      },
      {
        title: 'Date Created',
        slug: 'date',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: false,
        sortByOrder: '',
        width: 170,
      },
      {
        title: 'Actions',
        slug: 'actions',
        isSortable: false,
        isVisible: false,
        tooltip: true,
        sortByOrder: '',
        width: 180,
      },
    ],
    deleteEntriesList: {
      name: '',
      list: []
    },
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_WEBHOOK_BY_ID_REQUESTED: {
      return {
        ...state,
        saveRuleLoading: true,
        loading: true,
        loaded: false,
      };
    }
    case GET_WEBHOOK_BY_ID_SUCCESS: {
      const { webhookdto, allowEdit } = action.result;
      const authRequestParam = [];
      const authHeaderKey = [];
      const authParamKey = [];
      const requestParam = [];
      const headerKey = [];
      const paramKey = [];
      const jsonKey = [];

      webhookdto.authRequest.reqParam.forEach((param) => {
        const authentication = {
          key: param.key,
          value: param.value,
        };
        authRequestParam.push(authentication);
      });

      webhookdto.authRequest.reqHeader.forEach((header) => {
        const authentication = {
          key: header.key,
          value: header.value,
        };
        authHeaderKey.push(authentication);
      });

      if (webhookdto.authRequest.requestBodyNeeded === true && webhookdto.authRequest.reqBodyType === 'JSON' && webhookdto.authRequest.reqBody) {
        webhookdto.authRequest.reqBody.forEach((param) => {
          const authentication = {
            key: param.key,
            value: param.value,
          };
          authParamKey.push(authentication);
        });
      } else {
        authParamKey.push({ key: '', value: '' });
      }

      webhookdto.webhookRequest.reqParam.forEach((param) => {
        const authentication = {
          key: param.key,
          value: param.value,
        };
        requestParam.push(authentication);
      });

      webhookdto.webhookRequest.reqHeader.forEach((header) => {
        const authentication = {
          key: header.key,
          value: header.value,
        };
        headerKey.push(authentication);
      });

      if (webhookdto.webhookRequest.requestBodyNeeded === true && webhookdto.webhookRequest.reqBodyType === 'JSON' && webhookdto.webhookRequest.reqBody) {
        webhookdto.webhookRequest.reqBody.forEach((param) => {
          const authentication = {
            key: param.key,
            value: param.value,
          };
          paramKey.push(authentication);
        });
      } else {
        paramKey.push({ key: '', value: '' });
      }

      if (webhookdto.webhookRequest.responseType === 'JSON' && webhookdto.webhookRequest.responseConditions) {
        webhookdto.webhookRequest.responseConditions.forEach((param) => {
          const authentication = {
            key: param.key,
            value: param.value,
          };
          jsonKey.push(authentication);
        });
      } else {
        jsonKey.push({ key: '', value: '' });
      }

      let authDisplayUrl = '';
      if (webhookdto.authRequest.url) {
        authDisplayUrl = webhookdto.authRequest.url;
        if (authRequestParam && (authRequestParam.length > 1 || authRequestParam[0].key !== '')) {
          authDisplayUrl += '?';
        }
        authRequestParam.forEach((param, ind) => {
          const key = param.key ? param.key : '';
          const value = param.value ? param.value : '';
          if (ind === 0) {
            authDisplayUrl += `${key}`;
          } else {
            authDisplayUrl += `&${key}`;
          }
          if (value !== '') {
            authDisplayUrl += `=${value}`;
          }
        });
      }

      let displayUrl = webhookdto.webhookRequest.url;
      if (requestParam && (requestParam.length > 1 || requestParam[0].key !== '')) {
        displayUrl += '?';
      }
      requestParam.forEach((param, ind) => {
        const key = param.key ? param.key : '';
        const value = param.value ? param.value : '';
        if (ind === 0) {
          displayUrl += `${key}`;
        } else {
          displayUrl += `&${key}`;
        }
        if (value !== '') {
          displayUrl += `=${value}`;
        }
      });

      const customize = {
        webhookName: webhookdto.name,
        customPlaceHoldersDefined: webhookdto.customPlaceHoldersDefined === true ? 'yes' : 'no',
        customPlaceHolders: webhookdto.customPlaceHoldersDefined === true ? webhookdto.customPlaceHolders : [''],
        authURLReq: webhookdto.authURLReq === true ? 'yes' : 'no',
        authUrl: webhookdto.authRequest.url,
        authDisplayUrl,
        authRequestType: webhookdto.authRequest.requestType,
        authRequestParam,
        authHeaderKey,
        authRequestBodyNeeded: webhookdto.authRequest.requestBodyNeeded === true ? 'yes' : 'no',
        authRequestBodyType: webhookdto.authRequest.reqBodyType,
        authParamKey,
        authRequestBodyVal: webhookdto.authRequest.reqBodyType === 'Text' ? webhookdto.authRequest.reqBodyString : '',
        authResponseType: webhookdto.authRequest.responseType,
        authJsonKey: webhookdto.authRequest.responseType === 'JSON' ? webhookdto.authRequest.responseUID : '',
        url: webhookdto.webhookRequest.url,
        displayUrl,
        requestType: webhookdto.webhookRequest.requestType,
        requestParam,
        headerKey,
        requestBodyNeeded: webhookdto.webhookRequest.requestBodyNeeded === true ? 'yes' : 'no',
        requestBodyType: webhookdto.webhookRequest.reqBodyType,
        paramKey,
        requestBodyVal: webhookdto.webhookRequest.reqBodyType === 'Text' ? webhookdto.webhookRequest.reqBodyString : '',
        responseType: webhookdto.webhookRequest.responseType,
        responseStringOper: webhookdto.webhookRequest.responseType === 'Text' ? webhookdto.webhookRequest.responseConditions[0].key : '',
        responseStringValue: webhookdto.webhookRequest.responseType === 'Text' ? webhookdto.webhookRequest.responseConditions[0].value : '',
        jsonKey,
        transactionIdNeeded: (webhookdto.webhookRequest.responseUID && webhookdto.webhookRequest.responseUID !== undefined && webhookdto.webhookRequest.responseUID !== ''),
        transactionIdJsonKey: webhookdto.webhookRequest.responseUID,
        mode: 'edit',
        allowEdit,
      };
      return {
        ...state,
        customize,
        loading: false,
        error: false,
        loaded: true,
      };
    }

    case GET_WEBHOOK_BY_ID_FAILURE: {
      return {
        ...state,
        saveRuleLoading: false,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_FIELD: {
      state = state
        ? {
          ...state,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_LIST_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case CLEAR_FORM: {
      return {
        ...initialState
      };
    }
    case CLEAR_WEBHOOK_FORM: {
      const customize = {
        nameExistsError: false,
        webhookName: '',
        url: '',
        authURLReq: 'no',
        authUrl: '',
        authRequestBodyNeeded: 'no',
        requestBodyNeeded: 'no',
        headerKey: [
          {
            key: '',
            value: '',
          }
        ],
      };
      return { ...state, customize };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case UPDATE_CUSTOMIZE_FIELD: {
      state.customize = state.customize
        ? {
          ...state.customize,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case VALIDATE_CUSTOMIZE_DATA: {
      const {
        url,
        webhookName,
        customPlaceHoldersDefined,
        authUrl,
        authRequestParam,
        authHeaderKey,
        authRequestBodyNeeded,
        authRequestBodyType,
        authRequestBodyVal,
        authParamKey,
        authResponseType,
        authJsonKey,
        requestParam,
        headerKey,
        authURLReq,
        requestBodyNeeded,
        requestBodyType,
        responseType,
        paramKey,
        jsonKey,
        responseStringValue,
        requestBodyVal,
        transactionIdNeeded,
        transactionIdJsonKey,
        customPlaceHolders
      } = state.customize;
      let error = {
        validCustomize: true,
      };
      if (webhookName === '') {
        error = {
          ...error,
          validCustomize: false,
          webhookNameError: 'Webhook Name is Required'
        };
      }
      if (webhookName !== '' && webhookName && (webhookName.trim().length < 3 || webhookName.length > 50)) {
        error = {
          ...error,
          validCustomize: false,
          webhookNameError: 'Webhook name should be of min 3 and max 50 characters'
        };
      }
      if (customPlaceHoldersDefined === 'yes' && customPlaceHolders.some(placeholder => placeholder === '')) {
        error = {
          ...error,
          validCustomize: false,
          customPlaceHoldersError: 'Custom Placeholders cannot be empty'
        };
      } else if (customPlaceHoldersDefined === 'yes') {
        const suggestionsList = ['segmentText', 'reward', 'rewardType', 'token', 'identifier', 'referrer', 'templateName', 'templateType', 'ruleName', 'ruleType', 'rewardName', 'timestamp', 'date', 'score', 'currencyName'];
        if (customPlaceHolders.some(placeholder => suggestionsList.some(str => str === placeholder.trim()))) {
          error = {
            ...error,
            validCustomize: false,
            customPlaceHoldersError: 'Custom Placeholders cannot be same as predefined placeholders'
          };
        }
      } else if ((new Set(customPlaceHolders)).size !== customPlaceHolders.length) {
        error = {
          ...error,
          validCustomize: false,
          customPlaceHoldersError: 'Custom Placeholders should be Unique'
        };
      } else {
        error = {
          ...error,
          customPlaceHoldersError: ''
        };
      }
      if (authURLReq === 'yes' && authUrl === '') {
        error = {
          ...error,
          validCustomize: false,
          authUrlError: 'Authentication Url is Required'
        };
      }
      let length = authRequestParam.length;
      let authParamKeyEmpty = false;
      const authParamKeyList = [];
      if (authURLReq === 'yes') {
        let authRequestParamError = false;
        authRequestParam.forEach((q) => {
          if (length > 1 && (q.key === '' || q.value === '')) {
            authRequestParamError = true;
          } else if (length === 1 && ((q.key === '' && q.value !== '') || (q.key !== '' && q.value === ''))) {
            authParamKeyEmpty = true;
          } else {
            authParamKeyList.push(q.key);
          }
        });
        if (authParamKeyEmpty) {
          error = {
            ...error,
            validCustomize: false,
            authRequestParamError: 'Authentication Param can either be empty or have both Key/Value'
          };
        }
        if (authRequestParamError) {
          error = {
            ...error,
            validCustomize: false,
            authRequestParamError: 'Authentication Param Key/Value cannot be empty'
          };
        }
        const authParamExists = (new Set(authParamKeyList)).size !== authParamKeyList.length;
        if (authParamExists) {
          error = {
            ...error,
            validCustomize: false,
            authRequestParamError: 'Authentication Param Key should be Unique'
          };
        }
      }

      length = authHeaderKey.length;
      let authHeaderKeyEmpty = false;
      let authHeaderRequired = false;
      const authListKey = [];
      authHeaderKey.forEach((q) => {
        if (length > 1 && (q.key === '' || q.value === '')) {
          authHeaderRequired = true;
        } else if (length === 1 && ((q.key === '' && q.value !== '') || (q.key !== '' && q.value === ''))) {
          authHeaderKeyEmpty = true;
        } else {
          authListKey.push(q.key);
        }
      });
      if (authURLReq === 'yes' && authHeaderKeyEmpty) {
        error = {
          ...error,
          validCustomize: false,
          authHeaderKeyError: 'Authentication Header can either be empty or have both Key/Value'
        };
      }
      if (authURLReq === 'yes' && authHeaderRequired) {
        error = {
          ...error,
          validCustomize: false,
          authHeaderKeyError: 'Authentication Header Key/Value cannot be empty'
        };
      }
      const authHeaderExists = (new Set(authListKey)).size !== authListKey.length;
      if (authHeaderExists) {
        error = {
          ...error,
          validCustomize: false,
          authHeaderKeyError: 'Authentication Header Key should be Unique'
        };
      }
      if (authURLReq === 'yes' && authRequestBodyNeeded === 'yes' && authRequestBodyType === 'Text' && (authRequestBodyVal === undefined || authRequestBodyVal === '')) {
        error = {
          ...error,
          validCustomize: false,
          authRequestBodyError: 'Authentication Request body cannot be empty'
        };
      }
      const AuthParamlistKey = [];
      for (let i = 0; i < authParamKey.length; i += 1) {
        AuthParamlistKey.push(authParamKey[i].key);
        if (authURLReq === 'yes' && authRequestBodyNeeded === 'yes' && authRequestBodyType === 'JSON' && (authParamKey[i].key === undefined || authParamKey[i].key === '' || authParamKey[i].value === undefined || authParamKey[i].vvalue === '')) {
          error = {
            ...error,
            validCustomize: false,
            authParamKeyError: 'Authentication Request body Key/Value cannot be empty'
          };
        }
      }

      const authParamExists = (new Set(AuthParamlistKey)).size !== AuthParamlistKey.length;
      if (authURLReq === 'yes' && authRequestBodyNeeded === 'yes' && authRequestBodyType === 'JSON' && authParamExists) {
        error = {
          ...error,
          validCustomize: false,
          authParamKeyError: 'Authentication Request body Key should be Unique'
        };
      }
      if (authURLReq === 'yes' && authResponseType === 'JSON' && (authJsonKey === undefined || authJsonKey === '')) {
        error = {
          ...error,
          validCustomize: false,
          authJsonKeyError: 'Authentication Response Token Key cannot be empty'
        };
      }

      if (url === '') {
        error = {
          ...error,
          validCustomize: false,
          webhookUrlError: 'Webhook Url is Required'
        };
      }
      length = requestParam.length;
      let requestParamError = false;
      let requestParamKeyEmpty = false;
      const requestParamKeyList = [];
      requestParam.forEach((q) => {
        if (length > 1 && (q.key === '' || q.value === '')) {
          requestParamError = true;
        } else if (length === 1 && ((q.key === '' && q.value !== '') || (q.key !== '' && q.value === ''))) {
          requestParamKeyEmpty = true;
        } else {
          requestParamKeyList.push(q.key);
        }
      });
      if (requestParamKeyEmpty) {
        error = {
          ...error,
          validCustomize: false,
          requestParamError: 'Webhook Param can either be empty or have both Key/Value'
        };
      }
      if (requestParamError) {
        error = {
          ...error,
          validCustomize: false,
          requestParamError: 'Webhook Param Key/Value cannot be empty'
        };
      }
      const paramKeyExists = (new Set(requestParamKeyList)).size !== requestParamKeyList.length;
      if (paramKeyExists) {
        error = {
          ...error,
          validCustomize: false,
          requestParamError: 'Webhook Param Key should be Unique'
        };
      }
      length = headerKey.length;
      let headerKeyEmptyError = false;
      let headerKeyError = false;
      const listKey = [];
      headerKey.forEach((q) => {
        if (length > 1 && (q.key === '' || q.value === '')) {
          headerKeyError = true;
        } else if (length === 1 && ((q.key === '' && q.value !== '') || (q.key !== '' && q.value === ''))) {
          headerKeyEmptyError = true;
        } else {
          listKey.push(q.key);
        }
      });
      if (headerKeyEmptyError) {
        error = {
          ...error,
          validCustomize: false,
          headerKeyError: 'WebHook Header can either be empty or have both Key/Value'
        };
      }
      if (headerKeyError) {
        error = {
          ...error,
          validCustomize: false,
          headerKeyError: 'WebHook Header Key/Value cannot be empty'
        };
      }

      const exists = (new Set(listKey)).size !== listKey.length;
      if (exists) {
        error = {
          ...error,
          validCustomize: false,
          headerKeyError: 'WebHook Header Key should be Unique'
        };
      }
      if (requestBodyNeeded === 'yes' && requestBodyType === 'Text' && (requestBodyVal === undefined || requestBodyVal === '')) {
        error = {
          ...error,
          validCustomize: false,
          requestBodyError: 'WebHook Request body cannot be empty'
        };
      }
      const paramlistKey = [];
      for (let i = 0; i < paramKey.length; i += 1) {
        paramlistKey.push(paramKey[i].key);
        if (requestBodyNeeded === 'yes' && requestBodyType === 'JSON' && (paramKey[i].key === undefined || paramKey[i].key === '' || paramKey[i].value === undefined || paramKey[i].value === '')) {
          error = {
            ...error,
            validCustomize: false,
            paramKeyError: 'WebHook Request body Key/Value cannot be empty'
          };
        }
      }

      const paramExists = (new Set(paramlistKey)).size !== paramlistKey.length;
      if (requestBodyNeeded === 'yes' && requestBodyType === 'JSON' && paramExists) {
        error = {
          ...error,
          validCustomize: false,
          paramKeyError: 'WebHook Request body Key should be Unique'
        };
      }

      if (responseType === 'Text' && (responseStringValue === undefined || responseStringValue === '')) {
        error = {
          ...error,
          validCustomize: false,
          responseStringError: 'WebHook Response value cannot be empty'
        };
      }

      const jsonlistKey = [];
      for (let i = 0; i < jsonKey.length; i += 1) {
        jsonlistKey.push(jsonKey[i].key);
        if (responseType === 'JSON' && (jsonKey[i].key === undefined || jsonKey[i].key === '' || jsonKey[i].value === undefined || jsonKey[i].value === '')) {
          error = {
            ...error,
            validCustomize: false,
            jsonKeyError: 'WebHook Response Key/Value cannot be empty'
          };
        }
      }

      const jsonExists = (new Set(jsonlistKey)).size !== jsonlistKey.length;
      if (responseType === 'JSON' && jsonExists) {
        error = {
          ...error,
          validCustomize: false,
          jsonKeyError: 'WebHook Response Key should be Unique'
        };
      }
      if (responseType === 'JSON' && transactionIdNeeded && (transactionIdJsonKey === undefined || transactionIdJsonKey === '')) {
        error = {
          ...error,
          validCustomize: false,
          transactionIdError: 'Transaction Id is Required'
        };
      }
      let usedPlaceHolders = 0;
      if (customPlaceHoldersDefined === 'yes') {
        customPlaceHolders.forEach((placeholder) => {
          if ((authURLReq === 'yes' && authUrl.includes(`#${placeholder}`))
          || (authURLReq === 'yes' && authRequestParam.length > 0 && authRequestParam.some(q => q.value.includes(`#${placeholder}`)))
          || (authURLReq === 'yes' && authHeaderKey.length > 0 && authHeaderKey.some(q => q.value.includes(`#${placeholder}`)))
          || (authURLReq === 'yes' && authRequestBodyNeeded === 'yes' && authRequestBodyType === 'JSON' && authParamKey.length > 0 && authParamKey.some(q => q.value.includes(`#${placeholder}`)))
          || (authURLReq === 'yes' && authRequestBodyNeeded === 'yes' && authRequestBodyType === 'Text' && authRequestBodyVal.includes(`#${placeholder}`))
          || (url.includes(`#${placeholder}`))
          || (requestParam.length > 0 && requestParam.some(q => q.value.includes(`#${placeholder}`)))
          || (headerKey.length > 0 && headerKey.some(q => q.value.includes(`#${placeholder}`)))
          || (requestBodyNeeded === 'yes' && requestBodyType === 'JSON' && paramKey.length > 0 && paramKey.some(q => q.value.includes(`#${placeholder}`)))
          || (requestBodyNeeded === 'yes' && requestBodyType === 'Text' && requestBodyVal.includes(`#${placeholder}`))) {
            usedPlaceHolders += 1;
          }
        });
        if (usedPlaceHolders === customPlaceHolders.length) {
          state.customize.customPlaceHoldersError = '';
        } else if (error.customPlaceHoldersError === undefined || error.customPlaceHoldersError === '') {
          error = {
            ...error,
            validCustomize: false,
            customPlaceHoldersError: 'All Custom Placeholders should be used'
          };
        }
      } else {
        state.customize.customPlaceHoldersError = '';
      }
      const customize = {
        ...state.customize,
        ...error
      };
      return {
        ...state,
        customize,
      };
    }
    default:
      return state;
  }
};

// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatelistFields = (payload) => {
  return {
    type: UPDATE_LIST_FIELD,
    payload,
  };
};

export const updateCustomize = (payload) => {
  return {
    type: UPDATE_CUSTOMIZE_FIELD,
    payload,
  };
};

export const clearWebhookForm = () => {
  return {
    type: CLEAR_WEBHOOK_FORM,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};
export const clearForm = () => {
  return {
    type: CLEAR_FORM,
  };
};
export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};
export const validateCustomize = () => {
  return {
    type: VALIDATE_CUSTOMIZE_DATA,
  };
};


export const getWebhooklist = (data) => {
  let reqParams = '';
  if (data.searchKey !== '') {
    reqParams = `${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&sortType=${data.orderBy}`;
  } else {
    reqParams = `${data.itemsPerPage}/${data.pageNo}?searchKey=&sortBy=${data.sortBy}&sortType=${data.orderBy}`;
  }
  return {
    types: [REQUESTED, SUCCESS, FAILURE],
    promise: client => client.get(`getWebhookList/${reqParams}`)
  };
};

export const dublicateWebHook = (id) => {
  return {
    types: [REQUESTED, SUCCESS, FAILURE],
    promise: client => client.get(`duplicateWebhookList?id=${id}`)
  };
};

export const saveWebhookConfig = (data) => {
  return {
    types: [REQUESTED, SUCCESS, FAILURE],
    promise: client => client.post(`saveWebhookConfig?webhookId=${data.webhookId}`, {
      data,
    }),
  };
};

export const deleteWebHook = (id) => {
  return {
    types: [REQUESTED, SUCCESS, FAILURE],
    promise: client => client.post(`deleteWebHook?id=${id}`),
  };
};

export const checkDeleteWebhookList = (data) => {
  return {
    types: [REQUESTED, SUCCESS, FAILURE],
    promise: client => client.post(`checkDeleteWebhookList?id=${data}`),
  };
};

export const getWebhookById = (webhookId) => {
  return {
    types: [GET_WEBHOOK_BY_ID_REQUESTED, GET_WEBHOOK_BY_ID_SUCCESS, GET_WEBHOOK_BY_ID_FAILURE],
    promise: client => client.get(`getWebhookById?webhookId=${webhookId}`),
  };
};

export const duplicateWebhookTitle = (data, id) => {
  return {
    types: [REQUESTED, SUCCESS, FAILURE],
    promise: client => client.get(`duplicateWebhookTitle?name=${data}&id=${id}`)
  };
};

export const testWebhookConfig = (data) => {
  return {
    types: [REQUESTED, SUCCESS, FAILURE],
    promise: client => client.post(`testWebhookConfig?webhookId=${data.webhookId}`, {
      data,
    }),
  };
};
