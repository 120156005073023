export const GET_CONFIG_REQUESTED = 'get/GET_CONFIG_REQUESTED';
export const GET_CONFIG_SUCCESS = 'get/GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'get/GET_CONFIG_FAILURE';

export const DUPLICATE_CONFIG_REQUESTED = 'get/DUPLICATE_CONFIG_REQUESTED';
export const DUPLICATE_CONFIG_SUCCESS = 'get/DUPLICATE_CONFIG_SUCCESS';
export const DUPLICATE_CONFIG_FAILURE = 'get/DUPLICATE_CONFIG_FAILURE';

export const DELETE_CONFIG_REQUESTED = 'get/DELETE_CONFIG_REQUESTED';
export const DELETE_CONFIG_SUCCESS = 'get/DELETE_CONFIG_SUCCESS';
export const DELETE_CONFIG_FAILURE = 'get/DELETE_CONFIG_FAILURE';

export const UPDATE_FIELD = 'template/UPDATE_FIELD';
export const UPDATE_TEMPLATE_FIELD = 'template/UPDATE_TEMPLATE_FIELD';
export const UPDATE_PAGE = 'template/UPDATE_PAGE';
export const UPDATE_TEMPLATE_PAGE = 'template/UPDATE_TEMPLATE_PAGE';
export const CLEAR_STATE = 'template/CLEAR_STATE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  predefinedTemplate: {
    type: '',
    title: '',
    page: 1,
    perPage: 3,
    list: [],
    deleteRuleList: {
      tournamentName: '',
      list: []
    }
  },
  template: {},
  tableData: {},
  initialData: {
    pageNo: 0,
    title: '',
    sortBy: 'updatedTS',
    orderBy: 'desc',
    activeTab: 'my_tournaments',
    type: 0,
    activePage: 1,
    headers: [],
    selectedType: 1
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DUPLICATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DUPLICATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DUPLICATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_TEMPLATE_FIELD: {
      state.predefinedTemplate = state.predefinedTemplate
        ? {
          ...state.predefinedTemplate,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case DELETE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DELETE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_TEMPLATE_PAGE: {
      const template = { ...state.template, ...action.payload };
      return { ...state, template };
    }
    case CLEAR_STATE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const getTournamentList = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(
      `getInfoScreenPageConfigsList/${data.itemsPerPage}/${data.pageNo}?title=${data.title}&sortBy=${data.sortBy}&orderBy=${
        data.orderBy
      }`
    ),
  };
};

export const duplicateTournamentConfig = (data) => {
  return {
    types: [DUPLICATE_CONFIG_REQUESTED, DUPLICATE_CONFIG_SUCCESS, DUPLICATE_CONFIG_FAILURE],
    promise: client => client.get(`duplicateInfoScreenPageConfig?pageId=${data}`),
  };
};

export const deleteTournamentConfig = (data) => {
  return {
    types: [DELETE_CONFIG_REQUESTED, DELETE_CONFIG_SUCCESS, DELETE_CONFIG_FAILURE],
    promise: client => client.post(`deleteTournamentConfig?tournamentId=${data}`),
  };
};

// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updateTemplate = (payload) => {
  return {
    type: UPDATE_TEMPLATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const updateTemplatePage = (payload) => {
  return {
    type: UPDATE_TEMPLATE_PAGE,
    payload,
  };
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const checkDeleteTournamentConfig = (data) => {
  return {
    types: [DELETE_CONFIG_REQUESTED, DELETE_CONFIG_SUCCESS, DELETE_CONFIG_FAILURE],
    promise: client => client.post(`deleteInfoScreenPageConfig?id=${data}`),
  };
};

export const checkTournamentTitle = (data, type, id) => {
  return {
    types: [DUPLICATE_CONFIG_REQUESTED, DUPLICATE_CONFIG_SUCCESS, DUPLICATE_CONFIG_FAILURE],
    promise: client => client.get(`checkTournamentTitle?title=${data}&type=${type}&id=${id}`),
  };
};
