export const CREATE_ORDER_REQUESTED = 'CREATE_ORDER_REQUESTED';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';

export const VERIFY_SIGNATURE_REQUESTED = 'VERIFY_SIGNATURE_REQUESTED';
export const VERIFY_SIGNATURE_SUCCESS = 'VERIFY_SIGNATURE_SUCCESS';
export const VERIFY_SIGNATURE_FAILURE = 'VERIFY_SIGNATURE_FAILURE';

export const GET_TRANSACTION_HISTORY_REQUESTED = 'GET_TRANSACTION_HISTORY_REQUESTED';
export const GET_TRANSACTION_HISTORY_SUCCESS = 'GET_TRANSACTION_HISTORY_SUCCESS';
export const GET_TRANSACTION_HISTORY_FAILURE = 'GET_TRANSACTION_HISTORY_FAILURE';

export const DOWNLOAD_INVOICE_PDF_REQUESTED = 'DOWNLOAD_INVOICE_PDF_REQUESTED';
export const DOWNLOAD_INVOICE_PDF_SUCCESS = 'DOWNLOAD_INVOICE_PDF_SUCCESS';
export const DOWNLOAD_INVOICE_PDF_FAILURE = 'DOWNLOAD_INVOICE_PDF_FAILURE';

const initialState = {
  transactionHistory: [],
  transactionSuccessDTO: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ORDER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case CREATE_ORDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case CREATE_ORDER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case VERIFY_SIGNATURE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case VERIFY_SIGNATURE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case VERIFY_SIGNATURE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_TRANSACTION_HISTORY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_TRANSACTION_HISTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        transactionHistory: action.result && action.result.transactionHistoryDTO,
      };
    }
    case GET_TRANSACTION_HISTORY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DOWNLOAD_INVOICE_PDF_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DOWNLOAD_INVOICE_PDF_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        transactionSuccessDTO: action.result.downloadInvoiceSuccessDTO
      };
    }
    case DOWNLOAD_INVOICE_PDF_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const createOrder = (data) => {
  return {
    types: [CREATE_ORDER_REQUESTED, CREATE_ORDER_SUCCESS, CREATE_ORDER_FAILURE],
    promise: client => client.post('createOrder', {
      data,
    }),
  };
};

export const verifySignature = (data) => {
  return {
    types: [VERIFY_SIGNATURE_REQUESTED, VERIFY_SIGNATURE_SUCCESS, VERIFY_SIGNATURE_FAILURE],
    promise: client => client.post('verifySignature', {
      data,
    }),
  };
};

export const getTransactionHistory = (data) => {
  return {
    types: [GET_TRANSACTION_HISTORY_REQUESTED, GET_TRANSACTION_HISTORY_SUCCESS, GET_TRANSACTION_HISTORY_FAILURE],
    promise: client => client.get(`getTransactionHistory/${data.itemsPerPage}/${data.pageNo}?startDate=${data.startDate}&endDate=${data.endDate}`)
  };
};

export const generateInvoicePdf = (data) => {
  return {
    types: [DOWNLOAD_INVOICE_PDF_REQUESTED, DOWNLOAD_INVOICE_PDF_SUCCESS, DOWNLOAD_INVOICE_PDF_FAILURE],
    promise: client => client.post('generateInvoicePdf', {
      data,
    }),
  };
};
