import React from 'react';
import { CONSTANTS } from '@constants';

// error boundary is to handle error in jsx component level
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      hasError: errorInfo
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <p>
            {CONSTANTS.SOMETHING_WENT_WRONG_ERROR}
            {this.state.error && this.state.error.toString()}
            <span>
              {CONSTANTS.REFRESH_PAGE_CONTACT_ADMIN}
            </span>
          </p>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
