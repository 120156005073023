import { APP_CONFIG } from '@constants';

const upload = require('superagent');

export const UPDATE_TITLE_FIELD = 'themes/UPDATE_TITLE_FIELD';
export const UPDATE_SUBTITLE_FIELD = 'themes/UPDATE_SUBTITLE_FIELD';
export const UPDATE_QUESTIONS_FIELD = 'themes/UPDATE_QUESTIONS_FIELD';
export const UPDATE_BACKGROUND_FIELD = 'themes/UPDATE_BACKGROUND_FIELD';
export const UPDATE_GRATIFY_BACKGROUND_FIELD = 'themes/UPDATE_GRATIFY_BACKGROUND_FIELD';
export const UPDATE_RESULT_FIELD = 'themes/UPDATE_RESULT_FIELD';
export const UPDATE_CTA = 'themes/UPDATE_CTA';
export const UPDATE_APPCONFIG_FIELD = 'themes/UPDATE_APPCONFIG_FIELD';
export const UPDATE_BUTTON_CONFIG = 'themes/UPDATE_BUTTON_CONFIG';
export const UPDATE_BUTTON = 'themes/UPDATE_BUTTON';
export const UPDATE_TEXTCONFIG = 'themes/UPDATE_TEXTCONFIG';
export const UPDATE_GRATIFY_TEXTCONFIG = 'themes/UPDATE_GRATIFY_TEXTCONFIG';
export const UPDATE_SELECTEDINDEX = 'themes/UPDATE_SELECTEDINDEX';


export const UPLOAD_IMAGE_REQUESTED = 'upload/UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_IMAGE_SUCCESS = 'upload/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'upload/UPLOAD_IMAGE_FAILURE';

export const SAVE_CONFIG_REQUESTED = 'save/SAVE_CONFIG_REQUESTED';
export const SAVE_CONFIG_SUCCESS = 'save/SAVE_CONFIG_SUCCESS';
export const SAVE_CONFIG_FAILURE = 'save/SAVE_CONFIG_FAILURE';

export const GET_CONFIG_REQUESTED = 'get/GET_CONFIG_REQUESTED';
export const GET_CONFIG_SUCCESS = 'get/GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'get/GET_CONFIG_FAILURE';

export const UPDATE_CONFIG_REQUESTED = 'update/UPDATE_CONFIG_REQUESTED';
export const UPDATE_CONFIG_SUCCESS = 'update/UPDATE_CONFIG_SUCCESS';
export const UPDATE_CONFIG_FAILURE = 'update/UPDATE_CONFIG_FAILURE';

export const DUPLICATE_CONFIG_REQUESTED = 'get/DUPLICATE_CONFIG_REQUESTED';
export const DUPLICATE_CONFIG_SUCCESS = 'get/DUPLICATE_CONFIG_SUCCESS';
export const DUPLICATE_CONFIG_FAILURE = 'get/DUPLICATE_CONFIG_FAILURE';

export const UPDATE_FIELD = 'themes/UPDATE_FIELD';
export const UPDATE_PAGE = 'themes/UPDATE_PAGE';
export const UPDATE_LOCALIZATION_FLAG = 'themes/UPDATE_LOCALIZATION_FLAG';
export const UPDATE_ACTIVE_FLAG = 'themes/UPDATE_ACTIVE_FLAG';
export const UPDATE_CONFIG_FIELD = 'themes/UPDATE_CONFIG_FIELD';
export const REMOVE_CONFIG = 'themes/REMOVE_CONFIG';
export const CLEAR_STATE = 'themes/CLEAR_STATE';
export const UPDATE_WIN_FIELD = 'themes/UPDATE_WIN_FIELD';
export const UPDATE_LOOSE_FIELD = 'themes/UPDATE_LOOSE_FIELD';

export const UPDATE_COLLAPSE = 'polling/UPDATE_COLLAPSE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  initialValues: {
    fontTitlePositions: [],
    questionType: [],
    quizQuestionImagePosition: [],
    opinionPollType: [],
    fonts: [],
    fontStrength: [],
    fontSize: [],
    spinButtonPosition: [],
    spinButtonWidth: [],
    spinButtonRadius: [],
    spinButtonShadow: [],
    fontPositions: [],
    outlineStyles: [],
    thicknessOptions: [
      {
        title: '1',
        value: '1'
      },
      {
        title: '2',
        value: '2'
      },
      {
        title: '3',
        value: '3'
      },
      {
        title: '4',
        value: '4'
      },
      {
        title: '5',
        value: '5'
      },
      {
        title: '10',
        value: '10'
      },
    ],
  },
  collapse: {
    backgroundCont: false,
    gratifyBackgroundCont: false,
    gameCont: false,
    gratifyTextConfig: false,
    resultCont: false,
    gratifyButtonConfig: false,
  },
  localizationFlag: 'English',
  activeNavTab: 'English',
  config: {
    localizationconfig: [
      {
        language: 'English',
        type: 1,
        title: {
          templateName: {
            text: null
          },
          templateError: ''
        },
        // backgroundRadio: true,
        addLogoCheckBox: true,
        allowEdit: true,
        selcetedIndex: {
          closeIconIndex: 2,
          gratifyCloseIconIndex: 2,
          rewardIconIndex: 0,
          copyIconIndex: 3,
          logoIconIndex: 0,
        },

        background: {
          backgroundRadio: true,
          bgColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#ffffff' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.primaryColour,
          bgImgUrl: null,
          bgImgName: null,
          overLayColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#ffffff' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.primaryColour,
          color1: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#ffffff' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.primaryColour,
          color2: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#ffffff' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.primaryColour,
          logoPosition: 'left',
          iconposition: 'left',
          logoImage: null,
          logoImageName: null,
          iconImage: null,
          iconImageName: null,
          backgroundError: '',
        },

        gratifyBackground: {
          backgroundRadio: true,
          bgColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#ffffff' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.primaryColour,
          bgImgUrl: null,
          bgImgName: null,
          color1: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#ffffff' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.primaryColour,
          color2: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#ffffff' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.primaryColour,
          iconposition: 'left',
          iconImage: null,
          iconImageName: null,
          backgroundError: '',
        },

        textConfig: {
          titleFontStyle: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFont,
          titleFontSize: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '24' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontSize,
          titleFontColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#ffffff' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
          titleFontWt: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontWt,

          subTitleFontStyle: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFont,
          subTitleFontSize: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '24' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFontSize,
          subTitleFontColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#242424' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
          subTitleFontWt: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFontWt,

          buttonFontStyle: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFont,
          buttonFontSize: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '24' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontSize,
          buttonFontColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#242424' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
          buttonFontWt: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontWt,
        },

        gratifyTextConfig: {
          titleFontStyle: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFont,
          titleFontSize: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '24' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontSize,
          titleFontColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#ffffff' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
          titleFontWt: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontWt,

          subTitleFontStyle: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFont,
          subTitleFontSize: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '24' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFontSize,
          subTitleFontColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#242424' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
          subTitleFontWt: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFontWt,

          buttonFontStyle: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFont,
          buttonFontSize: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '24' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontSize,
          buttonFontColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#242424' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
          buttonFontWt: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontWt,

          winningButtonText: null,
          losingBttonTextRadio: true,
          losingButtonText: null,
          offerDetailsTitle: null,
          instructionsTitle: null,

          winningText: {
            img: '',
            imageName: '',
            text: '',
            error: '',
            errorImg: ''
          },
          losingText: {
            img: '',
            imageName: '',
            text: '',
            error: '',
            errorImg: ''
          }
        },

        buttonConfig: [
          {
            width: '50%',
            radius: '10px',
            shadow: '0 14px 28px',
            shadowColor: '#0033CC',
            bgColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#0033CC' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
            border: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#0033CC' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
            hoverBgColor: '#0033CC',
            buttonTextError: '',
            borderWidth: '3px',
            hoverWidth: '3px',
          }
        ],

        gratifyButtonConfig: [
          {
            width: '50%',
            radius: '10px',
            shadow: '0 14px 28px',
            shadowColor: '#0033CC',
            bgColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#0033CC' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
            border: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#0033CC' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
            hoverBgColor: '#0033CC',
            buttonTextError: '',
            borderWidth: '3px',
            hoverWidth: '3px',
            copyIcon: '',
            copyIconImg: '',
            rewardIcon: '',
            rewardIconImg: '',
            winningWebURL: '',
            winningAppURL: '',
            losingWebURL: '',
            losingAppURL: '',
          }
        ],

        libraries: {
          logosLib: [],
          iconLibrary: [],
        }
        // resultHandler: 'ParentApp',
      }
    ]
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPLOAD_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case SAVE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DUPLICATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case DUPLICATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DUPLICATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_TITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].title = state.config.localizationconfig[i].title
            ? {
              ...state.config.localizationconfig[i].title,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }

    case UPDATE_BUTTON_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_QUESTIONS_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BACKGROUND_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].background = state.config.localizationconfig[i].background
            ? {
              ...state.config.localizationconfig[i].background,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_GRATIFY_BACKGROUND_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].gratifyBackground = state.config.localizationconfig[i].gratifyBackground
            ? {
              ...state.config.localizationconfig[i].gratifyBackground,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_RESULT_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].resultText = state.config.localizationconfig[i].resultText
            ? {
              ...state.config.localizationconfig[i].resultText,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_CTA: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].buttonConfig[0] = state.config.localizationconfig[i].buttonConfig[0]
            ? {
              ...state.config.localizationconfig[i].buttonConfig[0],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_APPCONFIG_FIELD: {
      state.initialValues = state.initialValues
        ? {
          ...state.initialValues,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_LOCALIZATION_FLAG: {
      return { ...state, localizationFlag: action.payload };
    }
    case UPDATE_ACTIVE_FLAG: {
      return { ...state, activeNavTab: action.payload };
    }
    case UPDATE_CONFIG_FIELD: {
      const localizationconfig = [...state.config.localizationconfig, ...action.payload.localizationconfig];
      const config = {
        ...state.config,
        localizationconfig
      };
      return { ...state, config };
    }
    case REMOVE_CONFIG: {
      return { ...state, config: action.payload };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case UPDATE_BUTTON: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_TEXTCONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].textConfig = state.config.localizationconfig[i].textConfig
            ? {
              ...state.config.localizationconfig[i].textConfig,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_GRATIFY_TEXTCONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].gratifyTextConfig = state.config.localizationconfig[i].gratifyTextConfig
            ? {
              ...state.config.localizationconfig[i].gratifyTextConfig,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_SELECTEDINDEX: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].selcetedIndex = state.config.localizationconfig[i].selcetedIndex
            ? {
              ...state.config.localizationconfig[i].selcetedIndex,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_COLLAPSE: {
      state.collapse = state.collapse
        ? {
          ...state.collapse,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    default:
      return state;
  }
};

export const updateTitle = (payload) => {
  return {
    type: UPDATE_TITLE_FIELD,
    payload,
  };
};

export const updateQuestions = (payload) => {
  return {
    type: UPDATE_QUESTIONS_FIELD,
    payload,
  };
};

export const updateBackground = (payload) => {
  return {
    type: UPDATE_BACKGROUND_FIELD,
    payload,
  };
};
export const updateGratifyBackground = (payload) => {
  return {
    type: UPDATE_GRATIFY_BACKGROUND_FIELD,
    payload,
  };
};

export const uploadimage = (file, folderName) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/gamification/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
      .field('folderName', folderName),
  };
};

export const saveThemeConfig = (data) => {
  return {
    types: [SAVE_CONFIG_REQUESTED, SAVE_CONFIG_SUCCESS, SAVE_CONFIG_FAILURE],
    promise: client => client.post('saveTheme', {
      data,
    }),
  };
};

export const getThemeConfig = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getThemeConfig?themeId=${data}`),
  };
};

// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const editThemeConfig = (data, gamificationId) => {
  return {
    types: [UPDATE_CONFIG_REQUESTED, UPDATE_CONFIG_SUCCESS, UPDATE_CONFIG_FAILURE],
    promise: client => client.post(`saveTheme?themeId=${gamificationId}`, {
      data,
    }),
  };
};

export const duplicateThemeCheck = (data, type, id) => {
  return {
    types: [DUPLICATE_CONFIG_REQUESTED, DUPLICATE_CONFIG_SUCCESS, DUPLICATE_CONFIG_FAILURE],
    promise: client => client.get(`duplicateThemeCheck?title=${data}&type=${type}&id=${id}`),
  };
};

export const getTemplate = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getTemplate?templateId=${data}`),
  };
};

export const updateResult = (payload) => {
  return {
    type: UPDATE_RESULT_FIELD,
    payload,
  };
};
export const updateCta = (payload) => {
  return {
    type: UPDATE_CTA,
    payload,
  };
};

export const updateDropdown = (payload) => {
  return {
    type: UPDATE_APPCONFIG_FIELD,
    payload,
  };
};

export const updateButtonConfig = (payload) => {
  return {
    type: UPDATE_BUTTON_CONFIG,
    payload
  };
};

export const getAppConfig = () => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get('getAppConfig'),
  };
};

export const updateLocalizationFlag = (payload) => {
  return {
    type: UPDATE_LOCALIZATION_FLAG,
    payload,
  };
};


export const updateActiveFlag = (payload) => {
  return {
    type: UPDATE_ACTIVE_FLAG,
    payload,
  };
};

export const updateConfig = (payload) => {
  return {
    type: UPDATE_CONFIG_FIELD,
    payload
  };
};

export const removeConfig = (payload) => {
  return {
    type: REMOVE_CONFIG,
    payload
  };
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const updateButton = (payload) => {
  return {
    type: UPDATE_BUTTON,
    payload
  };
};
export const updateTextConfig = (payload) => {
  return {
    type: UPDATE_TEXTCONFIG,
    payload
  };
};
export const updateGratifyTextConfig = (payload) => {
  return {
    type: UPDATE_GRATIFY_TEXTCONFIG,
    payload
  };
};
export const updateSelectedIndex = (payload) => {
  return {
    type: UPDATE_SELECTEDINDEX,
    payload
  };
};
export const updateCollapse = (payload) => {
  return {
    type: UPDATE_COLLAPSE,
    payload
  };
};
