import { CONSTANTS } from '@constants';

export const REQUESTED = 'REQUESTED';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export const UPDATE_FIELD = 'gamereport/UPDATE_FIELD';
export const UPDATE_CUSTOM_FIELD = 'gamereport/UPDATE_CUSTOM_FIELD';
export const UPDATE_PAGE = 'gamereport/UPDATE_PAGE';
export const CLEAR_STATE = 'gamereport/CLEAR_STATE';


export const UPDATE_CUTOM_PAGE = 'gamereport/UPDATE_CUTOM_PAGE';

export const GET_SENDERLIST_REQUESTED = 'gamereport/GET_SENDERLIST_REQUESTED';
export const GET_SENDERLIST_SUCCESS = 'gamereport/GET_SENDERLIST_SUCCESS';
export const GET_SENDERLIST_FAILURE = 'gamereport/GET_SENDERLIST_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  tableData: {},
  initialData: {
    pageNo: 0,
    sortBy: 'updatedTS',
    orderBy: 'desc',
    activePage: 1,
    searchKey: '',
    searchCampaign: '',
    sendReport: '',
    senderList: '',
    selectedMethod: '',
    selectedPeriod: '',
    selectedPeriodFrom: '',
    selectedPeriodEnd: '',
    selectedPeriodYear: null,
    selectedPeriodMonth: null,
    selectedPeriodWeek: null,
    selectedReportIds: [],
    headers: [
      {
        title: 'Actions',
        slug: 'actions',
        isVisible: false,
        width: 50,
      },
      {
        title: CONSTANTS.CAMPAIGN_NAME,
        slug: 'gamerule_name',
        isVisible: true,
        center: true,
        width: 160,
      },
      {
        title: CONSTANTS.CALENDER_DURATION,
        slug: 'duration',
        isVisible: true,
        center: true,
        width: 110,
      },
      {
        title: CONSTANTS.STATUS_FILTER,
        slug: 'status',
        isVisible: true,
        center: true,
        width: 110,
      },
      {
        title: CONSTANTS.TOTAL_VISITS,
        slug: 'total_visit',
        isVisible: true,
        center: true,
        width: 110,
      },
      {
        title: CONSTANTS.TOTAL_PLAYS,
        slug: 'total_plays',
        isVisible: true,
        center: true,
        width: 110,
      },
      {
        title: CONSTANTS.REPEAT_PLAYS,
        slug: 'repeat_plays',
        isVisible: true,
        center: true,
        width: 115,
      },
      {
        title: CONSTANTS.TOTAL_OFFER,
        slug: 'total_offer',
        isVisible: true,
        center: true,
        width: 115,
      },
      {
        title: CONSTANTS.OFFER_DISTRIBUTED,
        slug: 'offer_distributed',
        isVisible: true,
        center: true,
        width: 115,
      },
      {
        title: CONSTANTS.OFFER_REDEEMED,
        slug: 'offer_redeemed',
        isVisible: true,
        center: true,
        width: 115,
      },
    ]
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_FIELD: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case UPDATE_CUSTOM_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case UPDATE_CUTOM_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case GET_SENDERLIST_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_SENDERLIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_SENDERLIST_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const getGameReport = (data) => {
  return {
    types: [REQUESTED, SUCCESS, FAILURE],
    promise: client => client.get(`getGameReport/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&orderBy=${data.orderBy}&selectedFromDate=${data.selectedPeriodFrom}&selectedToDate=${data.selectedPeriodEnd}`)
  };
};

export const downloadOffer = (data) => {
  return {
    types: [REQUESTED, SUCCESS, FAILURE],
    promise: client => client.get(`downloadGameReport?selectedPeriodFrom=${data.selectedPeriodFrom}&selectedPeriodEnd=${data.selectedPeriodEnd}&ruleIdList=${data.ruleIdList}`)
  };
};

export const sendNowGameReport = (data) => {
  return {
    types: [GET_SENDERLIST_REQUESTED, GET_SENDERLIST_SUCCESS, GET_SENDERLIST_FAILURE],
    promise: client => client.get(`sendNowGameReport?selectedPeriodFrom=${data.selectedPeriodFrom}&selectedPeriodEnd=${data.selectedPeriodEnd}&emailList=${data.emailList}&ruleIdList=${data.ruleIdList}`)
  };
};

// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatecustomFields = (payload) => {
  return {
    type: UPDATE_CUSTOM_FIELD,
    payload,
  };
};


export const updateCustomPage = (payload) => {
  return {
    type: UPDATE_CUTOM_PAGE,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};
