import { APP_CONFIG } from '@constants';

const upload = require('superagent');

export const UPDATE_TITLE_FIELD = 'referral/UPDATE_TITLE_FIELD';
export const UPDATE_SUBTITLE_FIELD = 'referral/UPDATE_SUBTITLE_FIELD';
export const UPDATE_QUESTIONS_FIELD = 'referral/UPDATE_QUESTIONS_FIELD';
export const UPDATE_BACKGROUND_FIELD = 'referral/UPDATE_BACKGROUND_FIELD';
export const UPDATE_RESULT_FIELD = 'referral/UPDATE_RESULT_FIELD';
export const UPDATE_APPCONFIG_FIELD = 'referral/UPDATE_APPCONFIG_FIELD';
export const UPDATE_REFERRAL_TEXT_FIELD = 'referral/UPDATE_REFERRAL_TEXT_FIELD';
export const UPDATE_TERMS_CONDITION_TEXT_FIELD = 'referral/UPDATE_TERMS_CONDITION_TEXT_FIELD';
export const UPDATE_PRIZE_TEXT_FIELD = 'referral/UPDATE_PRIZE_TEXT_FIELD';
export const UPDATE_REFERRAL_MESSAGE_TEXT_FIELD = 'referral/UPDATE_REFERRAL_MESSAGE_TEXT_FIELD';
export const UPDATE_MSG_TEXT_FIELD = 'referral/UPDATE_MSG_TEXT_FIELD';

export const UPLOAD_IMAGE_REQUESTED = 'upload/UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_IMAGE_SUCCESS = 'upload/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'upload/UPLOAD_IMAGE_FAILURE';

export const SAVE_CONFIG_REQUESTED = 'save/SAVE_CONFIG_REQUESTED';
export const SAVE_CONFIG_SUCCESS = 'save/SAVE_CONFIG_SUCCESS';
export const SAVE_CONFIG_FAILURE = 'save/SAVE_CONFIG_FAILURE';

export const GET_CONFIG_REQUESTED = 'get/GET_CONFIG_REQUESTED';
export const GET_CONFIG_SUCCESS = 'get/GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'get/GET_CONFIG_FAILURE';

export const UPDATE_CONFIG_REQUESTED = 'update/UPDATE_CONFIG_REQUESTED';
export const UPDATE_CONFIG_SUCCESS = 'update/UPDATE_CONFIG_SUCCESS';
export const UPDATE_CONFIG_FAILURE = 'update/UPDATE_CONFIG_FAILURE';

export const DUPLICATE_CONFIG_REQUESTED = 'get/DUPLICATE_CONFIG_REQUESTED';
export const DUPLICATE_CONFIG_SUCCESS = 'get/DUPLICATE_CONFIG_SUCCESS';
export const DUPLICATE_CONFIG_FAILURE = 'get/DUPLICATE_CONFIG_FAILURE';

export const UPDATE_FIELD = 'referral/UPDATE_FIELD';
export const UPDATE_FIELD2 = 'referral/UPDATE_FIELD2';
export const UPDATE_PAGE = 'referral/UPDATE_PAGE';
export const UPDATE_LOCALIZATION_FLAG = 'referral/UPDATE_LOCALIZATION_FLAG';
export const UPDATE_ACTIVE_FLAG = 'referral/UPDATE_ACTIVE_FLAG';
export const UPDATE_CONFIG_FIELD = 'referral/UPDATE_CONFIG_FIELD';
export const REMOVE_CONFIG = 'referral/REMOVE_CONFIG';
export const CLEAR_STATE = 'referral/CLEAR_STATE';
export const UPDATE_APP_LINK = 'referral/UPDATE_APP_LINK';
export const UPDATE_CODE_PREFIX = 'referral/UPDATE_CODE_PREFIX';

export const GET_LEADERBOARD_DATA_REQUESTED = 'referral/GET_LEADERBOARD_DATA_REQUESTED';
export const GET_LEADERBOARD_DATA_SUCCESS = 'referral/GET_LEADERBOARD_DATA_SUCCESS';
export const GET_LEADERBOARD_DATA_FAILURE = 'referral/GET_LEADERBOARD_DATA_FAILURE';

export const UPDATE_BUTTON = 'referral/UPDATE_BUTTON';
export const UPDATE_BUTTON_CONFIG = 'referral/UPDATE_BUTTON_CONFIG';

export const UPDATE_CUSTOMIZE_FIELD = 'referral/UPDATE_CUSTOMIZE_FIELD';
export const UPDATE_INSTRUCTIONS_NEEDED = 'referral/UPDATE_INSTRUCTIONS_NEEDED';
export const UPDATE_INSTRUCTIONS_TITLE_FIELD = 'referral/UPDATE_INSTRUCTIONS_TITLE_FIELD';
export const UPDATE_INSTRUCTIONS_FIELD = 'referral/UPDATE_INSTRUCTIONS_FIELD';

export const ADD_INSTRUCTION_STEP = 'referral/ADD_INSTRUCTION_STEP';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  initialValues: {
    fontTitlePositions: [],
    opinionPollType: [],
    fonts: [],
    fontStrength: [],
    fontSize: [],
    thicknessOptions: [
      {
        title: '1',
        value: '1'
      },
      {
        title: '2',
        value: '2'
      },
      {
        title: '3',
        value: '3'
      },
      {
        title: '4',
        value: '4'
      },
      {
        title: '5',
        value: '5'
      },
    ],
    radiusOptions: [
      {
        title: '0%',
        value: '0%'
      },
      {
        title: '5%',
        value: '5%'
      },
      {
        title: '10%',
        value: '10%'
      },
      {
        title: '20%',
        value: '20%'
      },
      {
        title: '30%',
        value: '30%'
      },
    ],
    // fontSizeOptions: [
    //   { label: '15', value: 15 },
    //   { label: '20', value: 20 },
    //   { label: '25', value: 25 },
    //   { label: '30', value: 30 },
    //   { label: '35', value: 35 },
    //   { label: '40', value: 40 }
    // ],
    fontTitlePosition: [
      {
        title: 'Left',
        value: 'flex-start',
      },
      {
        title: 'Center',
        value: 'center',
      },
      {
        title: 'Right',
        value: 'flex-end',
      },
    ],
    spinButtonPosition: [],
    spinButtonWidth: [],
    spinButtonRadius: [],
    spinButtonShadow: [],
    fontPositions: [],
    outlineStyles: [],
  },
  localizationFlag: '',
  activeNavTab: 'English',
  config: {
    localizationconfig: [
      {
        language: 'English',
        title: {
          text: 'You are heard!',
          color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#032AA7' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
          font: {
            size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '32' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontSize,
            strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontWt,
            family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFont,
            align: 'center',
          },
          position: 'center',
          titleError: '',
          disableSave: false,
          templateName: {
            text: ''
          },
          templateError: ''
        },
        subtitle: {
          text: 'Answer & Win Exciting Prize',
          color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#032AA7' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
          font: {
            size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFontSize,
            strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFontWt,
            family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFont,
            align: 'center',
          },
          titleError: '',
        },
        refferalText: {
          text: '',
          color: '#1E1A33',
          font: {
            size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
            strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
            family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
            align: 'center',
          },
          refferalTextError: '',
        },
        termsConditionText: {
          text: '',
          color: '#1E1A33',
          font: {
            size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
            strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
            family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
            align: 'center',
          },
          termsConditionTextError: '',
        },
        prizeText: {
          text: '',
          color: '#1E1A33',
          font: {
            size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
            strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
            family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
            align: 'center',
          },
          prizeTextError: '',
        },
        referralMessageText: {
          text: '',
          color: '#1E1A33',
          font: {
            size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
            strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
            family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
            align: 'center',
          },
          referralMessageTextError: '',
        },
        msgText: {
          text: '',
          color: '#1E1A33',
          font: {
            size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
            strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
            family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
            align: 'center',
          },
          msgTextError: '',
        },
        appLink: {
          text: '',
          color: '#1E1A33',
          font: {
            size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
            strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
            family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
            align: 'center',
          },
          appLinkError: '',
        },
        codePrefix: {
          text: '',
          color: '#000000',
          font: {
            size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
            strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
            family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
            align: 'center',
          },
          codePrefixError: '',
        },
        questions: [
          {
            name: {
              text: '',
              color: '#000000',
              font: {
                size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
                strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
                family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
                align: 'left',
              },
            },
            type: 'slider',
            rangeStartText: 'I don’t like it',
            rangeEndText: 'I love it',
            questionError: ''
          },
        ],
        background: {
          color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#ffffff' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.primaryColour,
          image: '',
          imageName: '',
          backgroundError: '',
          showProgressBar: false,
          wheelImage: '',
        },
        resultText: {
          winningText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
              strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
              family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
              align: 'left',
            },
            error: '',
            errorImg: ''
          },
          losingText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
              strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
              family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
              align: 'left',
            },
            error: '',
            errorImg: ''
          }
        },
        updateField: {
          backgroundColor: '#032AA7',
          borderColor: '#032AA7',
          thickness: '1',
          radius: '10%',
        },

        updateField2: {
          backgroundColor: '#032AA7',
          borderColor: '#032AA7',
          thickness: '1',
          radius: '10%',
        },
        buttonNeeded: true,
        referralLink: 'custom',
        displayReferralCode: true,
        buttonConfig: [
          {
            text: {
              text: 'Refer Now',
              color: '#000000',
              font: {
                size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontSize,
                strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontWt,
                family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFont,
                align: 'left',
              },
            },
            textLeaderBoard: {
              text: '',
              color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#000000' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
              font: {
                size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontSize,
                strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontWt,
                family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFont,
                align: 'left',
              },
            },
            textTransaction: {
              text: '',
              color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#000000' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
              font: {
                size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontSize,
                strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontWt,
                family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFont,
                align: 'left',
              },
            },
            textWinningMessage: {
              text: '',
              color: '#000000',
              font: {
                size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
                strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
                family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
                align: 'left',
              },
            },
            textTimer: {
              text: 'Time',
              color: '#000000',
              font: {
                size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
                strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
                family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
                align: 'left',
              },
            },
            textPrefix: {
              text: 'refercopy',
              color: '#000000',
              font: {
                size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
                strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
                family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
                align: 'left',
              },
            },
            textColor: '#000000',
            image: {
              url: '',
              name: ''
            },
            imageError: '',
            outerBubbleBGImage: {
              url: '',
              name: ''
            },
            outerBubbleBGImageError: '',
            innerBubbleBGImage: {
              url: '',
              name: ''
            },
            innerBubbleBGImageError: '',
            width: '100%',
            widthTimer: '100%',
            widthOuterBubble: '100%',
            widthInnerBubble: '100%',
            radius: '10%',
            radiusTimer: '10%',
            radiusOuterBubble: '10%',
            radiusInnerBubble: '10%',
            shadow: '0px 0px',
            shadowTimer: '0px 0px',
            shadowOuterBubble: '10%',
            shadowInnerBubble: '10%',
            shadowColor: '#032AA7',
            shadowColorReferCode: '#cdcdcd',
            shadowColorTimer: '#032AA7',
            shadowReferCode: '0px 0px',
            position: 'center',
            bgColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#032AA7' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
            bgColorReferCode: '#8987E5',
            bgColorOuterBubble: '#8987E5',
            bgColorInnerBubble: '#8987E5',
            border: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#032AA7' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
            hoverBgColor: '#032AA7',
            buttonTextError: '',
            borderWidth: '1px',
            borderWidthOuterBubble: '1px',
            borderWidthInnerBubble: '1px',
            borderWidthTimer: '1px',
            positionTimer: 'center',
            bgColorTimer: '#032AA7',
            borderTimer: '#032AA7',
            borderOuterBubble: '#8987E5',
            borderOuterInner: '#8987E5',
            hoverBgColorTimer: '#032AA7',
            positionReferCode: 'center',
            borderReferCode: '#8987E5',
            hoverOverBgColorReferCode: '#8987E5',
            borderWidthReferCode: '1px',
            webLink: {
              text: ''
            },
            appLinkError: '',
            webLinkError: ''
          }
        ],
        instructionsNeeded: true,
        questionIndex: 1,
        customize: {
          mode: 'save',
          referralLeaderboard: true,
          repeatLeaderBoard: 'yes',
          leaderboard: '',
          leaderboardError: 'Must select leaderboard',
          daily: false,
          weekly: false,
          monthly: false,
          campaign: false,
          transactionScreen: true,
          fontFaceHeader: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
          headerPosition: 'center',
          headerColor: '#032AA7',
          headerSize: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
          instructions: {
            title: {
              text: 'How does it work?',
              color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#000000' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
              font: {
                size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontSize,
                strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontWt,
                family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFont,
                align: 'center'
              }
            },
            sectionTitle: {
              text: 'How does it work?',
              color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#000000' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
              font: {
                size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontSize,
                strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontWt,
                family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFont,
                align: 'center'
              }
            },
            titleLeaderBoard: {
              text: 'How does it work?',
              color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#000000' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
              font: {
                size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontSize,
                strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontWt,
                family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFont,
                align: 'center'
              }
            },
            stepsTextBox: '',
            steps: [
              {
                index: {
                  text: '1',
                  color: '#000000',
                  font: {
                    size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
                    strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
                    family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
                    align: 'center'
                  }
                },
                heading: {
                  text: 'invite your friends',
                  color: '#000000',
                  font: {
                    size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
                    strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
                    family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
                    align: 'center'
                  }
                },
                content: {
                  text: 'share link, copy code',
                  color: '#000000',
                  font: {
                    size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
                    strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
                    family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
                    align: 'center'
                  }
                }
              }
            ]
          },
          copyIcon: 'https://cdnstg.gamize.com/13/spinTheWheel/15/4536_Vector.png',
          copyImageName: 'copy_ic.png',
          referralImage: '',
          referralImageName: '',
          backgroundImage: '',
          backgroundImageName: '',
          rewardBackgroundImageName: '',
          rewardBackgroundImage: '',
          instructionImage: '',
          instructionImageName: '',
        },
        options: {
          leaderboardOptions: []
        },
        resultHandler: 'ParentApp',
      }
    ],
    options: {
      leaderboardOptions: []
    }
  },
  options: {
    leaderboardOptions: []
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPLOAD_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case SAVE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DUPLICATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case DUPLICATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DUPLICATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_TITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].title = state.config.localizationconfig[i].title
            ? {
              ...state.config.localizationconfig[i].title,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_INSTRUCTIONS_TITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].customize.instructions.title = state.config.localizationconfig[i].customize.instructions.title
            ? {
              ...state.config.localizationconfig[i].customize.instructions.title,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_INSTRUCTIONS_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].customize.instructions = state.config.localizationconfig[i].customize.instructions
            ? {
              ...state.config.localizationconfig[i].customize.instructions,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_APP_LINK: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].appLink = state.config.localizationconfig[i].appLink
            ? {
              ...state.config.localizationconfig[i].appLink,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_CODE_PREFIX: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].codePrefix = state.config.localizationconfig[i].codePrefix
            ? {
              ...state.config.localizationconfig[i].codePrefix,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_REFERRAL_TEXT_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].refferalText = state.config.localizationconfig[i].refferalText
            ? {
              ...state.config.localizationconfig[i].refferalText,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_TERMS_CONDITION_TEXT_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].termsConditionText = state.config.localizationconfig[i].termsConditionText
            ? {
              ...state.config.localizationconfig[i].termsConditionText,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_PRIZE_TEXT_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].prizeText = state.config.localizationconfig[i].prizeText
            ? {
              ...state.config.localizationconfig[i].prizeText,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_REFERRAL_MESSAGE_TEXT_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].referralMessageText = state.config.localizationconfig[i].referralMessageText
            ? {
              ...state.config.localizationconfig[i].referralMessageText,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_MSG_TEXT_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].msgText = state.config.localizationconfig[i].msgText
            ? {
              ...state.config.localizationconfig[i].msgText,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_SUBTITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].subtitle = state.config.localizationconfig[i].subtitle
            ? {
              ...state.config.localizationconfig[i].subtitle,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_QUESTIONS_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BACKGROUND_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].background = state.config.localizationconfig[i].background
            ? {
              ...state.config.localizationconfig[i].background,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_RESULT_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].resultText = state.config.localizationconfig[i].resultText
            ? {
              ...state.config.localizationconfig[i].resultText,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_APPCONFIG_FIELD: {
      state.initialValues = state.initialValues
        ? {
          ...state.initialValues,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_LOCALIZATION_FLAG: {
      return { ...state, localizationFlag: action.payload };
    }
    case UPDATE_ACTIVE_FLAG: {
      return { ...state, activeNavTab: action.payload };
    }
    case UPDATE_CONFIG_FIELD: {
      const localizationconfig = [...state.config.localizationconfig, ...action.payload.localizationconfig];
      const config = {
        ...state.config,
        localizationconfig
      };
      /*
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config = state.config ? { ...state.config, config } : { config };
        }
      }
      */
      state.config = config;
      return { ...state };
    }
    case REMOVE_CONFIG: {
      return { ...state, config: action.payload };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case UPDATE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].updateField = state.config.localizationconfig[i].updateField
            ? {
              ...state.config.localizationconfig[i].updateField,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_FIELD2: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].updateField2 = state.config.localizationconfig[i].updateField2
            ? {
              ...state.config.localizationconfig[i].updateField2,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BUTTON: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_INSTRUCTIONS_NEEDED: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case GET_LEADERBOARD_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_LEADERBOARD_DATA_SUCCESS: {
      const leaderboard = action.result.leaderBoardList.leaderBoard;
      const templateOptions = [];
      for (let i = 0; i < leaderboard.length; i += 1) {
        if (leaderboard[i].leaderBoardName != null) {
          templateOptions.push({ title: leaderboard[i].leaderBoardName, value: leaderboard[i].id });
        }
      }
      state.config.options = state.config.options ? { ...state.config.options, leaderboardOptions: templateOptions } : { leaderboardOptions: templateOptions };
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_LEADERBOARD_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_CUSTOMIZE_FIELD: {
      console.log(state);
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].customize = state.config.localizationconfig[i].customize
            ? {
              ...state.config.localizationconfig[i].customize,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BUTTON_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    default:
      return state;
  }
};

export const updateTitle = (payload) => {
  return {
    type: UPDATE_TITLE_FIELD,
    payload,
  };
};

export const updateInstructionsTitle = (payload) => {
  return {
    type: UPDATE_INSTRUCTIONS_TITLE_FIELD,
    payload,
  };
};
export const updateInstructions = (payload) => {
  return {
    type: UPDATE_INSTRUCTIONS_FIELD,
    payload,
  };
};

export const updateAppLink = (payload) => {
  return {
    type: UPDATE_APP_LINK,
    payload,
  };
};

export const updateCodePrefix = (payload) => {
  return {
    type: UPDATE_CODE_PREFIX,
    payload,
  };
};

export const updateReferralText = (payload) => {
  return {
    type: UPDATE_REFERRAL_TEXT_FIELD,
    payload,
  };
};

export const updatetermsConditionText = (payload) => {
  return {
    type: UPDATE_TERMS_CONDITION_TEXT_FIELD,
    payload,
  };
};

export const updatePrizeText = (payload) => {
  return {
    type: UPDATE_PRIZE_TEXT_FIELD,
    payload,
  };
};

export const updateReferralMessageText = (payload) => {
  return {
    type: UPDATE_REFERRAL_MESSAGE_TEXT_FIELD,
    payload,
  };
};

export const updateMsgText = (payload) => {
  return {
    type: UPDATE_MSG_TEXT_FIELD,
    payload,
  };
};

export const updateSubTitle = (payload) => {
  return {
    type: UPDATE_SUBTITLE_FIELD,
    payload,
  };
};

export const updateQuestions = (payload) => {
  return {
    type: UPDATE_QUESTIONS_FIELD,
    payload,
  };
};

export const updateBackground = (payload) => {
  return {
    type: UPDATE_BACKGROUND_FIELD,
    payload,
  };
};

export const uploadimage = (file, folderName) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/gamification/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
      .field('folderName', folderName),
  };
};
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};
export const updateFields2 = (payload) => {
  return {
    type: UPDATE_FIELD2,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const getTemplate = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getTemplate?referralId=${data}`),
  };
};

export const updateResult = (payload) => {
  return {
    type: UPDATE_RESULT_FIELD,
    payload,
  };
};

export const updateDropdown = (payload) => {
  return {
    type: UPDATE_APPCONFIG_FIELD,
    payload,
  };
};

export const getAppConfig = () => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get('getAppConfig'),
  };
};

export const updateLocalizationFlag = (payload) => {
  return {
    type: UPDATE_LOCALIZATION_FLAG,
    payload,
  };
};


export const updateActiveFlag = (payload) => {
  return {
    type: UPDATE_ACTIVE_FLAG,
    payload,
  };
};

export const updateConfig = (payload) => {
  return {
    type: UPDATE_CONFIG_FIELD,
    payload
  };
};

export const removeConfig = (payload) => {
  return {
    type: REMOVE_CONFIG,
    payload
  };
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};


export const saveReferralConfig = (data) => {
  return {
    types: [SAVE_CONFIG_REQUESTED, SAVE_CONFIG_SUCCESS, SAVE_CONFIG_FAILURE],
    promise: client => client.post('saveSpinWheelConfig', {
      data,
    }),
  };
};

export const editReferralConfig = (data, referralId) => {
  return {
    types: [UPDATE_CONFIG_REQUESTED, UPDATE_CONFIG_SUCCESS, UPDATE_CONFIG_FAILURE],
    promise: client => client.post(`saveSpinWheelConfig?gamificationId=${referralId}`, {
      data,
    }),
  };
};

export const duplicateSpinWheelTitle = (data, type, id) => {
  return {
    types: [DUPLICATE_CONFIG_REQUESTED, DUPLICATE_CONFIG_SUCCESS, DUPLICATE_CONFIG_FAILURE],
    promise: client => client.get(`duplicateSpinWheelTitle?title=${data}&type=${type}&id=${id}`),
  };
};

export const getSpinWheel = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getSpinWheel?gamificationId=${data}`),
  };
};


export const editPollConfig = (data, gamificationId) => {
  return {
    types: [UPDATE_CONFIG_REQUESTED, UPDATE_CONFIG_SUCCESS, UPDATE_CONFIG_FAILURE],
    promise: client => client.post(`saveQuizConfig?quizId=${gamificationId}`, {
      data,
    }),
  };
};

export const getMyLeaderboardList = (data) => {
  let reqParams = '';
  if (data.searchKey !== '') {
    reqParams = `${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&sortType=${data.orderBy}`;
  } else {
    reqParams = `${data.itemsPerPage}/${data.pageNo}?searchKey=&sortBy=${data.sortBy}&sortType=${data.orderBy}`;
  }
  return {
    types: [GET_LEADERBOARD_DATA_REQUESTED, GET_LEADERBOARD_DATA_SUCCESS, GET_LEADERBOARD_DATA_FAILURE],
    promise: client => client.get(`getMyLeaderBoardList/${reqParams}`)
  };
};

export const updateButton = (payload) => {
  return {
    type: UPDATE_BUTTON,
    payload
  };
};

export const updateInstructionsNeeded = (payload) => {
  return {
    type: UPDATE_INSTRUCTIONS_NEEDED,
    payload
  };
};

export const updateButtonConfig = (payload) => {
  return {
    type: UPDATE_BUTTON_CONFIG,
    payload
  };
};

export const updateOuterBubbleBGConfig = (payload) => {
  return {
    type: UPDATE_BUTTON_CONFIG,
    payload
  };
};

export const updateCustomize = (payload) => {
  return {
    type: UPDATE_CUSTOMIZE_FIELD,
    payload,
  };
};
