export const GET_REQUESTED = 'GET_REQUESTED';
export const GET_SUCCESS = 'GET_SUCCESS';
export const GET_FAILURE = 'GET_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorDesc: [],
  data: [],
  code: '',
  msg: '',
  status: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        apiKey: action && action.result && action.result.apikey,
        timezone: action && action.result && action.result.timezone
      };
    }
    case GET_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    default:
      return state;
  }
};

export const getApiKey = () => {
  return {
    types: [GET_REQUESTED, GET_SUCCESS, GET_FAILURE],
    promise: client => client.get('getApiKey')
  };
};
