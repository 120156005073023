import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import login from './login';
import forgetpassword from './forgetpassword';
import logoutaccount from './logout';
import registration from './register';
import sidemenu from './sidemenu';
import offermanager from './offermanager';
import spinwheelconfig from './spinwheelconfig';
import scratchcardconfig from './scratchcardconfig';
import leadgenconfig from './leadgenconfig';
import treasurechestconfig from './treasurechestconfig';
import slotmachineconfig from './slotmachineconfig';
import ruleengineconfig from './ruleengineconfig';
import superquizconfig from './superquizconfig';
import opinionpollconfig from './opinionpollconfig';
import template from './template';
import myoffers from './myoffers';
import ruleenginelist from './ruleenginelist';
import usersegments from './usersegments';
import reports from './reports';
import badgesreports from './badgesreports';
import gamereports from './gamereports';
import reportsgraph from './reportsgraph';
import header from './header';
import games from './games';
import badges from './badges';
import mygames from './mygames';
import notification from './notification';
import editprofile from './editprofile';
import plan from './plan';
import razorpay from './razorpay';
import dailystreaklist from './dailystreaklist';
import dailystreakconfig from './dailystreakconfig';
import campaign from './campaign';
import campaignreports from './campaignreports';
import referralreports from './referralreports';
import dailystreakreports from './dailystreakreports';
import badgelist from './badgelist';
import badgeconfig from './badgeconfig';
import gamemechanics from './gamemechanics';
import leaderboard from './leaderboard';
import webhook from './webhook';
import offerreports from './offerreports';
import userreports from './userreports';
import tosscoin from './tosscoin';
import rolldice from './rolldice';
import offermanagerthirdparty from './offermanagerthirdparty';
import referral from './referral';
import leaderboardreports from './leaderboardreports';
import customEvents from './customEvents';
import tournament from './tournament';
import tournamentconfig from './tournamentconfig';
import progressBar from './progressBar';
import progressbarconfig from './progressbarconfig';
import rulegmconfig from './rulegmconfig';
import rulegmlists from './rulegmlists';
import quest from './quest';
import questconfig from './questconfig';
import notifications from './notifications';
import memorygame from './memorygame';
import memoryGameConfig from './memoryGameConfig';
import ruleCustomEventslists from './ruleCustomEventslists';
import rulecustomeventsconfig from './rulecustomeventsconfig';
import polling from './polling';
import customeventdefault from './customeventdefault';
import walletTransactions from './walletTransactions';
import brandkit from './brandkit';
import pagesconfig from './pagesconfig';
import rulesmartcampaignsconfig from './rulesmartcampaignsconfig';
import themeTemplate from './themeTemplate';
import themes from './themes';
import questReport from './questReport';
import questsections from './questsections';
import myoffersthirdparty from './myoffersthirdparty';

const appReducer = combineReducers({
  router: routerReducer,
  form: formReducer,
  login,
  logoutaccount,
  forgetpassword,
  registration,
  sidemenu,
  offermanager,
  spinwheelconfig,
  scratchcardconfig,
  leadgenconfig,
  treasurechestconfig,
  slotmachineconfig,
  superquizconfig,
  opinionpollconfig,
  template,
  myoffers,
  ruleengineconfig,
  ruleenginelist,
  usersegments,
  reports,
  badgesreports,
  gamereports,
  reportsgraph,
  header,
  games,
  badges,
  mygames,
  notification,
  editprofile,
  plan,
  razorpay,
  dailystreaklist,
  dailystreakconfig,
  campaign,
  campaignreports,
  referralreports,
  dailystreakreports,
  badgelist,
  badgeconfig,
  gamemechanics,
  leaderboard,
  webhook,
  offerreports,
  userreports,
  tosscoin,
  rolldice,
  offermanagerthirdparty,
  referral,
  leaderboardreports,
  customEvents,
  tournament,
  tournamentconfig,
  progressBar,
  progressbarconfig,
  rulegmconfig,
  rulegmlists,
  quest,
  questconfig,
  notifications,
  memorygame,
  memoryGameConfig,
  ruleCustomEventslists,
  rulecustomeventsconfig,
  polling,
  walletTransactions,
  customeventdefault,
  brandkit,
  pagesconfig,
  rulesmartcampaignsconfig,
  themeTemplate,
  themes,
  questReport,
  questsections,
  myoffersthirdparty
});

// Setup root reducer
const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
