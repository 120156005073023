import { CONSTANTS } from '@constants';

export const GET_DAILY_STREAK_REPORT_REQUESTED = 'dailystreakReport/GET_DAILY_STREAK_REPORT_REQUESTED';
export const GET_DAILY_STREAK_REPORT_SUCCESS = 'dailystreakReport/GET_DAILY_STREAK_REPORT_SUCCESS';
export const GET_DAILY_STREAK_REPORT_FAILURE = 'dailystreakReport/GET_DAILY_STREAK_REPORT_FAILURE';

export const UPDATE_CUSTOM_PAGE = 'dailystreakReport/UPDATE_CUSTOM_PAGE';


export const UPDATE_FIELD = 'dailystreakReport/UPDATE_FIELD';
export const UPDATE_PAGE = 'dailystreakReport/UPDATE_PAGE';
export const CLEAR_STATE = 'dailystreakReport/CLEAR_STATE';
export const UPDATE_CUSTOM_FIELD = 'dailystreakReport/UPDATE_CUSTOM_FIELD';
export const UPDATE_CUTOM_PAGE = 'dailystreakReport/UPDATE_CUTOM_PAGE';

export const GET_SENDERLIST_REQUESTED = 'dailystreakReport/GET_SENDERLIST_REQUESTED';
export const GET_SENDERLIST_SUCCESS = 'dailystreakReport/GET_SENDERLIST_SUCCESS';
export const GET_SENDERLIST_FAILURE = 'dailystreakReport/GET_SENDERLIST_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  tableData: {},
  initialData: {
    selectedReportIds: [],
    pageNo: 0,
    sortBy: 'updatedTS',
    orderBy: 'desc',
    activePage: 1,
    searchKey: '',
    searchCampaign: '',
    sendReport: '',
    selectedMethod: '',
    selectedPeriod: '',
    selectedPeriodFrom: '',
    selectedPeriodEnd: '',
    selectedPeriodYear: null,
    selectedPeriodMonth: null,
    selectedPeriodWeek: null,
    headers: [
      {
        title: 'Actions',
        slug: 'actions',
        isVisible: false,
        width: 50,
      },
      {
        title: CONSTANTS.STREAK_NAME,
        slug: 'streak_name',
        isVisible: true,
        center: true,
        width: 110,
      },
      {
        title: CONSTANTS.CALENDER_DURATION,
        slug: 'duration',
        isVisible: true,
        center: true,
        width: 120,
      },
      {
        title: CONSTANTS.STATUS_FILTER,
        slug: 'status',
        isVisible: true,
        center: true,
        width: 130,
      },
      {
        title: CONSTANTS.NO_OF_DAYS,
        slug: 'no_of_days',
        isVisible: true,
        center: true,
        width: 110,
      },
      {
        title: CONSTANTS.AVERAGE_STREAK_DAYS,
        slug: 'averagestreak_days',
        isVisible: true,
        center: true,
        width: 130,
      },
      {
        title: CONSTANTS.TOTAL_OFFERS,
        slug: 'total_offers',
        isVisible: true,
        center: true,
        width: 150,
      },
      {
        title: CONSTANTS.OFFER_DISTRIBUTED,
        slug: 'offer_distributed',
        isVisible: true,
        center: true,
        width: 140,
      },
      {
        title: CONSTANTS.OFFER_REDEEM,
        slug: 'offer_redeem',
        isVisible: true,
        center: true,
        width: 140,
      },
    ]
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DAILY_STREAK_REPORT_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_DAILY_STREAK_REPORT_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_DAILY_STREAK_REPORT_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_SENDERLIST_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_SENDERLIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_SENDERLIST_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    case UPDATE_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case UPDATE_CUSTOM_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_CUSTOM_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const getDailyStreakReportList = (data) => {
  return {
    types: [GET_DAILY_STREAK_REPORT_REQUESTED, GET_DAILY_STREAK_REPORT_SUCCESS, GET_DAILY_STREAK_REPORT_FAILURE],
    promise: client => client.get(`getDailyStreakReportList/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&orderBy=${data.orderBy}&selectedFromDate=${data.selectedPeriodFrom}&selectedToDate=${data.selectedPeriodEnd}`)
  };
};

export const downloadDailyStreakReport = (data) => {
  return {
    types: [GET_DAILY_STREAK_REPORT_REQUESTED, GET_DAILY_STREAK_REPORT_SUCCESS, GET_DAILY_STREAK_REPORT_FAILURE],
    promise: client => client.get(`downloadDailyStreakReport?selectedPeriodFrom=${data.selectedPeriodFrom}&selectedPeriodEnd=${data.selectedPeriodEnd}&idList=${data.idList}`)
  };
};

export const sendNowDailyStreakReport = (data) => {
  return {
    types: [GET_SENDERLIST_REQUESTED, GET_SENDERLIST_SUCCESS, GET_SENDERLIST_FAILURE],
    promise: client => client.get(`sendNowDailyStreakReport?selectedPeriodFrom=${data.selectedPeriodFrom}&selectedPeriodEnd=${data.selectedPeriodEnd}&emailList=${data.emailList}&idList=${data.idList}`)
  };
};

// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updateCustomPage = (payload) => {
  return {
    type: UPDATE_CUSTOM_PAGE,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const updatecustomFields = (payload) => {
  return {
    type: UPDATE_CUSTOM_FIELD,
    payload,
  };
};
