import { APP_CONFIG } from '@constants';

const upload = require('superagent');

export const UPDATE_TITLE_FIELD = 'tournament/UPDATE_TITLE_FIELD';
export const UPDATE_SUBTITLE_FIELD = 'tournament/UPDATE_SUBTITLE_FIELD';
export const UPDATE_BACKGROUND_FIELD = 'tournament/UPDATE_BACKGROUND_FIELD';
export const UPDATE_BANNER_IMAGE = 'tournament/UPDATE_BANNER_IMAGE';
export const UPDATE_RESULT_FIELD = 'tournament/UPDATE_RESULT_FIELD';
export const UPDATE_APPCONFIG_FIELD = 'tournament/UPDATE_APPCONFIG_FIELD';
export const UPDATE_GAMES_LIST = 'tournament/UPDATE_GAMES_LIST';

export const UPLOAD_IMAGE_REQUESTED = 'upload/UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_IMAGE_SUCCESS = 'upload/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'upload/UPLOAD_IMAGE_FAILURE';

export const SAVE_CONFIG_REQUESTED = 'save/SAVE_CONFIG_REQUESTED';
export const SAVE_CONFIG_SUCCESS = 'save/SAVE_CONFIG_SUCCESS';
export const SAVE_CONFIG_FAILURE = 'save/SAVE_CONFIG_FAILURE';

export const GET_CONFIG_REQUESTED = 'get/GET_CONFIG_REQUESTED';
export const GET_CONFIG_SUCCESS = 'get/GET_CONFIG_SUCCESS';
export const GET_TOURNAMENT_SUCCESS = 'get/GET_TOURNAMENT_SUCCESS';
export const GET_SPINCONFIG_SUCCESS = 'get/GET_SPINCONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'get/GET_CONFIG_FAILURE';

export const UPDATE_CONFIG_REQUESTED = 'update/UPDATE_CONFIG_REQUESTED';
export const UPDATE_CONFIG_SUCCESS = 'update/UPDATE_CONFIG_SUCCESS';
export const UPDATE_CONFIG_FAILURE = 'update/UPDATE_CONFIG_FAILURE';

export const DUPLICATE_CONFIG_REQUESTED = 'get/DUPLICATE_CONFIG_REQUESTED';
export const DUPLICATE_CONFIG_SUCCESS = 'get/DUPLICATE_CONFIG_SUCCESS';
export const DUPLICATE_CONFIG_FAILURE = 'get/DUPLICATE_CONFIG_FAILURE';

export const UPDATE_FIELD = 'tournament/UPDATE_FIELD';
export const UPDATE_PAGE = 'tournament/UPDATE_PAGE';
export const UPDATE_TOURNAMENT_DESC_PARAMS = 'tournament/UPDATE_TOURNAMENT_DESC_PARAMS';
export const UPDATE_TOURNAMENT_RULES_PARAMS = 'tournament/UPDATE_TOURNAMENT_RULES_PARAMS';
export const UPDATE_PRIZE_TEXT_PARAMS = 'tournament/UPDATE_PRIZE_TEXT_PARAMS';

export const UPDATE_ACTIVE_FLAG = 'tournament/UPDATE_ACTIVE_FLAG';
export const UPDATE_LOCALIZATION_FLAG = 'tournament/UPDATE_LOCALIZATION_FLAG';
export const UPDATE_CONFIG_FIELD = 'tournament/UPDATE_CONFIG_FIELD';
export const UPDATE_LANGUAGE = 'tournament/UPDATE_LANGUAGE';
export const REMOVE_CONFIG = 'tournament/REMOVE_CONFIG';
export const CLEAR_STATE = 'tournament/CLEAR_STATE';
export const UPDATE_BUTTON = 'tournament/UPDATE_BUTTON';
export const UPDATE_BUTTON_CONFIG = 'tournament/UPDATE_BUTTON_CONFIG';
export const UPDATE_TIME_FIELD = 'tournament/UPDATE_TIME_FIELD';
export const UPDATE_TEMPLATE_PAGE = 'tournament/UPDATE_TEMPLATE_PAGE';
export const UPDATE_TOURNAMENT_DESC_FIELD = 'referral/UPDATE_TOURNAMENT_DESC_FIELD';
export const UPDATE_TOURNAMENT_RULES_FIELD = 'referral/UPDATE_TOURNAMENT_RULES_FIELD';
export const UPDATE_PRIZE_TEXT_FIELD = 'referral/UPDATE_PRIZE_TEXT_FIELD';
export const DELETE_CONFIG_REQUESTED = 'get/DELETE_CONFIG_REQUESTED';
export const DELETE_CONFIG_SUCCESS = 'get/DELETE_CONFIG_SUCCESS';
export const DELETE_CONFIG_FAILURE = 'get/DELETE_CONFIG_FAILURE';
export const UPDATE_TEMPLATE_FIELD = 'template/UPDATE_TEMPLATE_FIELD';
export const UPDATE_LOCAL_CONFIG = 'tournament/UPDATE_LOCAL_CONFIG';
export const UPDATE_MAX_PLAYERS = 'tournament/UPDATE_MAX_PLAYERS';
export const UPDATE_SELECTED_FIELD = 'template/UPDATE_SELECTED_FIELD';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  options: {
    gamificationTemplate: [],
  },
  templatesList: [],
  templateinitialValues: {
    itemsPerPage: 8,
    total: 0,
    pageNo: 0,
    searchKey: '',
    sortBy: '',
    orderBy: '',
  },
  initialValues: {
    orderBy: '',
    tableData: {},
    headers: [
      {
        title: 'Leaderboard Name',
        slug: 'leaderboard_name',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: false,
        sortByOrder: '',
        width: 137,
      },
      {
        title: 'Leaderboard Title',
        slug: 'title',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: false,
        sortByOrder: '',
        width: 140,
      },
      {
        title: 'Date & Time',
        slug: 'date',
        isSortable: true,
        isVisible: true,
        tooltip: false,
        center: false,
        sortByOrder: 'desc',
        width: 200,
      },
      {
        title: 'Actions',
        slug: 'actions',
        isSortable: false,
        isVisible: false,
        tooltip: true,
        sortByOrder: '',
        width: 180,
      },
    ],
    fontTitlePositions: [],
    questionType: [],
    quizQuestionImagePosition: [],
    fonts: [],
    fontStrength: [],
    fontSize: [],
    spinButtonPosition: [],
    spinButtonWidth: [],
    spinButtonRadius: [],
    spinButtonShadow: [],
    fontPositions: [],
    outlineStyles: [],
    buttonAction: [],
    thicknessOptions: [
      {
        title: '1',
        value: '1'
      },
      {
        title: '2',
        value: '2'
      },
      {
        title: '3',
        value: '3'
      },
      {
        title: '4',
        value: '4'
      },
      {
        title: '5',
        value: '5'
      },
    ],
    radiusOptions: [
      {
        title: '10%',
        value: '10%'
      },
      {
        title: '50%',
        value: '50%'
      },
      {
        title: '100%',
        value: '100%'
      },
    ],
  },
  localizationFlag: '',
  activeNavTab: 'English',
  selectedType: '',
  config: {
    localizationconfig: [
      {
        language: 'English',
        title: {
          text: 'Answer to Win',
          color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#FFFFFF' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
          font: {
            size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '32' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontSize,
            strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontWt,
            family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFont,
            align: 'center',
          },
          position: 'center',
          titleError: '',
          disableSave: false,
          tournamentName: {
            text: ''
          },
          templateError: '',
          titleImageUrl: '',
          titleImageName: '',
          titleImageError: '',
          selectError: '',
        },
        subtitle: {
          text: 'Answer & Win Exciting Prize',
          color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#FFFFFF' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
          font: {
            size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFontSize,
            strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFontWt,
            family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFont,
            align: 'center',
          },
          titleError: '',
        },
        allowEdit: true,
        tournamentDescription: {
          text: '',
          color: '#d35252',
          font: {
            size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
            strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
            family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
            align: 'left',
          },
          tournamentDescriptionError: '',
        },
        tournamentDescParams: {
          backgroundColor: '#ffffff',
          borderColor: '#ffffff',
          thickness: '1',
          radius: '8px',
        },
        tournamentRules: {
          text: '',
          color: '#d35252',
          font: {
            size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
            strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
            family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
            align: 'left',
          },
          tournamentRulesError: '',
        },
        tournamentRulesParams: {
          backgroundColor: '#ffffff',
          borderColor: '#ffffff',
          thickness: '1',
          radius: '8px',
        },
        prizeText: {
          text: '',
          color: '#d35252',
          font: {
            size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
            strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
            family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
            align: 'left',
          },
          prizeTextError: '',
        },
        prizeTextParams: {
          backgroundColor: '#ffffff',
          borderColor: '#ffffff',
          thickness: '1',
          radius: '8px',
        },
        maxPlayers: {
          maxPlaysOption: 'maxPlayers',
          maxPlayers: 3,
          maxPlaysError: '',
        },
        scoringFormat: 'best',
        background: {
          color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#d35252' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.primaryColour,
          image: '',
          imageName: '',
          backgroundError: '',
          showProgressBar: false,
          wheelImage: '',
        },
        bannerImage: {
          color: '#ffffff',
          bannerImage: '',
          bannerImageName: '',
          bannerImageError: '',
          showProgressBar: false,
          wheelImage: '',
        },
        resultText: {
          winningText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
              strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
              family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
              align: 'left',
            },
            error: '',
            errorImg: ''
          },
          losingText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
              strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
              family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
              align: 'left',
            },
            error: '',
            errorImg: ''
          }
        },
        buttonNeeded: true,
        buttonConfig: [
          {
            text: {
              text: '',
              color: '#000000',
              font: {
                size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontSize,
                strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontWt,
                family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFont,
                align: 'left',
              },
            },
            textColor: '#000000',
            image: {
              url: '',
              name: ''
            },
            imageError: '',
            width: 'auto',
            radius: '30px',
            shadow: '0px 0px',
            shadowColor: '#cdcdcd',
            position: 'left',
            color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#ffffff' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
            border: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#000000' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
            hoverBgColor: '#cdcdcd',
            buttonTextError: '',
            borderWidth: '1px',
            appLink: {
              text: ''
            },
            webLink: {
              text: ''
            },
            appLinkError: '',
            webLinkError: ''
          }
        ],
        tournamentButtonConfig: [
          {
            text: {
              text: 'PLAY NOW',
              color: '#ffffff',
              font: {
                size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '24' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontSize,
                strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontWt,
                family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFont,
                align: 'center',
              },
            },
            textColor: '#000000',
            image: {
              url: '',
              name: ''
            },
            imageError: '',
            width: '100%',
            radius: '8px',
            shadow: '0px 0px',
            shadowColor: '#cdcdcd',
            position: 'center',
            color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#07d1ed' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
            border: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#000000' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
            hoverBgColor: '#cdcdcd',
            buttonTextError: '',
            borderWidth: '0px'
          }
        ],
        templateId: '',
        resultHandler: 'ParentApp',
      }
    ]
  },

};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPLOAD_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case SAVE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DUPLICATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case DUPLICATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DUPLICATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_TOURNAMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_SPINCONFIG_SUCCESS: {
      const templates = action.result.templates.templateDTOs;
      const templateOptions = [];
      for (let i = 0; i < templates.length; i += 1) {
        if (templates[i].templateName != null) {
          templateOptions.push({ title: templates[i].templateName, value: templates[i].id, typeID: templates[i].typeId });
        } else {
          templateOptions.push({ title: templates[i].title, value: templates[i].id, typeID: templates[i].typeId });
        }
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        options: { ...state.options, ...{ gamificationTemplate: templateOptions } },
      };
    }
    case GET_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_TITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].title = state.config.localizationconfig[i].title
            ? {
              ...state.config.localizationconfig[i].title,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_SUBTITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].subtitle = state.config.localizationconfig[i].subtitle
            ? {
              ...state.config.localizationconfig[i].subtitle,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BACKGROUND_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].background = state.config.localizationconfig[i].background
            ? {
              ...state.config.localizationconfig[i].background,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BANNER_IMAGE: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].bannerImage = state.config.localizationconfig[i].bannerImage
            ? {
              ...state.config.localizationconfig[i].bannerImage,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_RESULT_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].resultText = state.config.localizationconfig[i].resultText
            ? {
              ...state.config.localizationconfig[i].resultText,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }

      return { ...state };
    }
    case UPDATE_APPCONFIG_FIELD: {
      state.initialValues = state.initialValues
        ? {
          ...state.initialValues,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_LANGUAGE: {
      return { ...state, languages: action.payload };
    }
    case UPDATE_CONFIG_FIELD: {
      const localizationconfig = [...state.config.localizationconfig, ...action.payload.localizationconfig];
      const config = {
        ...state.config,
        localizationconfig
      };
      return { ...state, config };
    }
    case UPDATE_LOCALIZATION_FLAG: {
      return { ...state, localizationFlag: action.payload };
    }
    case UPDATE_ACTIVE_FLAG: {
      return { ...state, activeNavTab: action.payload };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case REMOVE_CONFIG: {
      return { ...state, config: action.payload };
    }
    case UPDATE_BUTTON_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BUTTON: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_TEMPLATE_PAGE: {
      return { ...state, selectedType: action.payload };
    }
    case UPDATE_GAMES_LIST: {
      return { ...state, templatesList: action.payload };
    }
    case UPDATE_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_PAGE: {
      const initialValues = { ...state.initialValues, ...action.payload };
      return { ...state, initialValues };
    }
    case UPDATE_TOURNAMENT_DESC_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].tournamentDescription = state.config.localizationconfig[i].tournamentDescription
            ? {
              ...state.config.localizationconfig[i].tournamentDescription,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_TOURNAMENT_RULES_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].tournamentRules = state.config.localizationconfig[i].tournamentRules
            ? {
              ...state.config.localizationconfig[i].tournamentRules,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_PRIZE_TEXT_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].prizeText = state.config.localizationconfig[i].prizeText
            ? {
              ...state.config.localizationconfig[i].prizeText,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_TOURNAMENT_DESC_PARAMS: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].tournamentDescParams = state.config.localizationconfig[i].tournamentDescParams
            ? {
              ...state.config.localizationconfig[i].tournamentDescParams,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_TOURNAMENT_RULES_PARAMS: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].tournamentRulesParams = state.config.localizationconfig[i].tournamentRulesParams
            ? {
              ...state.config.localizationconfig[i].tournamentRulesParams,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_PRIZE_TEXT_PARAMS: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].prizeTextParams = state.config.localizationconfig[i].prizeTextParams
            ? {
              ...state.config.localizationconfig[i].prizeTextParams,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case DELETE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DELETE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_TEMPLATE_FIELD: {
      state.predefinedTemplate = state.predefinedTemplate
        ? {
          ...state.predefinedTemplate,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_LOCAL_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = action.payload
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_MAX_PLAYERS: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].maxPlayers = state.config.localizationconfig[i].maxPlayers
            ? {
              ...state.config.localizationconfig[i].maxPlayers,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }

      return { ...state };
    }
    case UPDATE_SELECTED_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].resultText = state.config.localizationconfig[i].resultText
            ? {
              ...state.config.localizationconfig[i].resultText,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_TIME_FIELD: {
      const time = {
        ...state.time,
        ...action.payload
      };
      return { ...state, time };
    }
    default:
      return state;
  }
};

export const updateTitle = (payload) => {
  return {
    type: UPDATE_TITLE_FIELD,
    payload,
  };
};

export const updateSubTitle = (payload) => {
  return {
    type: UPDATE_SUBTITLE_FIELD,
    payload,
  };
};

export const updateActiveFlag = (payload) => {
  return {
    type: UPDATE_ACTIVE_FLAG,
    payload,
  };
};

export const removeConfig = (payload) => {
  return {
    type: REMOVE_CONFIG,
    payload
  };
};

export const updateBackground = (payload) => {
  return {
    type: UPDATE_BACKGROUND_FIELD,
    payload,
  };
};

export const updateBannerImage = (payload) => {
  return {
    type: UPDATE_BANNER_IMAGE,
    payload,
  };
};

export const uploadimage = (file, folderName) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/gamification/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
      .field('folderName', folderName),
  };
};

export const saveTournamentConfig = (data) => {
  return {
    types: [SAVE_CONFIG_REQUESTED, SAVE_CONFIG_SUCCESS, SAVE_CONFIG_FAILURE],
    promise: client => client.post('saveTournamentConfig', {
      data,
    }),
  };
};

export const getTournament = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_TOURNAMENT_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getTournament?tournamentId=${data}`),
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const editTournamentConfig = (data, gamificationId) => {
  return {
    types: [UPDATE_CONFIG_REQUESTED, UPDATE_CONFIG_SUCCESS, UPDATE_CONFIG_FAILURE],
    promise: client => client.post(`saveTournamentConfig?tournamentId=${gamificationId}`, {
      data,
    }),
  };
};

export const checkTournamentTitle = (data, type, id) => {
  return {
    types: [DUPLICATE_CONFIG_REQUESTED, DUPLICATE_CONFIG_SUCCESS, DUPLICATE_CONFIG_FAILURE],
    promise: client => client.get(`checkTournamentTitle?title=${data}&type=${type}&id=${id}`),
  };
};

export const updateResult = (payload) => {
  return {
    type: UPDATE_RESULT_FIELD,
    payload,
  };
};

export const getAppConfig = () => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get('getAppConfig'),
  };
};

export const updateDropdown = (payload) => {
  return {
    type: UPDATE_APPCONFIG_FIELD,
    payload,
  };
};
export const updateLocalizationFlag = (payload) => {
  return {
    type: UPDATE_LOCALIZATION_FLAG,
    payload,
  };
};
export const updateConfig = (payload) => {
  return {
    type: UPDATE_CONFIG_FIELD,
    payload
  };
};
export const updateLanguage = (payload) => {
  return {
    type: UPDATE_LANGUAGE,
    payload
  };
};
export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const updateButton = (payload) => {
  return {
    type: UPDATE_BUTTON,
    payload
  };
};

export const updateButtonConfig = (payload) => {
  return {
    type: UPDATE_BUTTON_CONFIG,
    payload
  };
};

export const updateGamesList = (payload) => {
  return {
    type: UPDATE_GAMES_LIST,
    payload,
  };
};

export const updateTournamentDescription = (payload) => {
  return {
    type: UPDATE_TOURNAMENT_DESC_FIELD,
    payload,
  };
};

export const updateTournamentRules = (payload) => {
  return {
    type: UPDATE_TOURNAMENT_RULES_FIELD,
    payload,
  };
};

export const updatePrizeText = (payload) => {
  return {
    type: UPDATE_PRIZE_TEXT_FIELD,
    payload,
  };
};

export const updateSelectedType = (payload) => {
  return {
    type: UPDATE_TEMPLATE_PAGE,
    payload,
  };
};

export const updateTournamentDescParams = (payload) => {
  return {
    type: UPDATE_TOURNAMENT_DESC_PARAMS,
    payload,
  };
};

export const updateTournamentRulesParams = (payload) => {
  return {
    type: UPDATE_TOURNAMENT_RULES_PARAMS,
    payload,
  };
};

export const updatePrizeTextParams = (payload) => {
  return {
    type: UPDATE_PRIZE_TEXT_PARAMS,
    payload,
  };
};

export const updateLocalConfig = (payload) => {
  return {
    type: UPDATE_LOCAL_CONFIG,
    payload,
  };
};

export const updateMaxPlayersDetails = (payload) => {
  return {
    type: UPDATE_MAX_PLAYERS,
    payload,
  };
};

export const getQuizTemplate = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_SPINCONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(
      `getSpinWheelConfig/${data}`
    ),
  };
};

export const getGamesTemplate = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(
      `getGamesList/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&sortType=${
        data.orderBy
      }`
    ),
  };
};

export const updateTime = (payload) => {
  return {
    type: UPDATE_TIME_FIELD,
    payload,
  };
};
