/* eslint-disable keyword-spacing */
import { APP_CONFIG } from '@constants';
import { getTimezoneDate, getTimezoneOffset } from '@helpers/Utility';
import { DateTime } from 'luxon';

const upload = require('superagent');

export const UPDATE_CUSTOMIZE_FIELD = 'dailystreakConfig/UPDATE_CUSTOMIZE_FIELD';
export const UPDATE_SCHEDULE_FIELD = 'dailystreakConfig/UPDATE_SCHEDULE_FIELD';
export const UPDATE_APPCONFIG_FIELD = 'dailystreakConfig/UPDATE_APPCONFIG_FIELD';

export const UPDATE_FIELD = 'dailystreakConfig/UPDATE_FIELD';
export const UPDATE_PAGE = 'dailystreakConfig/UPDATE_PAGE';
export const CLEAR_FORM = 'dailystreakConfig/CLEAR_FORM';
export const UPDATE_OPTIONS = 'dailystreakConfig/UPDATE_OPTIONS';
export const UPDATE_WINNING_QUADRANT = 'dailystreakConfig/UPDATE_WINNING_QUADRANT';
export const UPDATE_TEMPLATE_DATA = 'dailystreakConfig/UPDATE_TEMPLATE_DATA';
export const VALIDATE_CUSTOMIZE_DATA = 'dailystreakConfig/VALIDATE_CUSTOMIZE_DATA';
export const CREATE_OPTIONS = 'dailystreakConfig/CREATE_OPTIONS';
export const UPDATE_BACKGROUND_FIELD = 'dailystreakConfig/UPDATE_BACKGROUND_FIELD';

export const SAVE_RULE_REQUESTED = 'save/SAVE_RULE_REQUESTED';
export const SAVE_RULE_SUCCESS = 'save/SAVE_RULE_SUCCESS';
export const SAVE_RULE_FAILURE = 'save/SAVE_RULE_FAILURE';

export const UPLOAD_IMAGE_REQUESTED = 'upload/UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_IMAGE_SUCCESS = 'upload/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'upload/UPLOAD_IMAGE_FAILURE';

export const GET_SEGEMENT_REQUESTED = 'GET_SEGEMENT_REQUESTED';
export const GET_SEGEMENT_SUCCESS = 'GET_SEGEMENT_SUCCESS';
export const GET_SEGEMENT_FAILURE = 'GET_SEGEMENT_FAILURE';

export const GET_USER_EVENT_REQUEST = 'GET_USER_EVENT_REQUEST';
export const GET_USER_EVENT_SUCCESS = 'GET_USER_EVENT_SUCCESS';
export const GET_USER_EVENT_FAILURE = 'GET_USER_EVENT_FAILURE';

export const CHECK_RULE_NAME_REQUEST = 'CHECK_RULE_NAME_REQUEST';
export const CHECK_RULE_NAME_SUCCESS = 'CHECK_RULE_NAME_SUCCESS';
export const CHECK_RULE_NAME_FAILURE = 'CHECK_RULE_NAME_FAILURE';

export const GET_CONFIG_REQUESTED = 'get/GET_CONFIG_REQUESTED';
export const GET_CONFIG_SUCCESS = 'get/GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'get/GET_CONFIG_FAILURE';

export const GET_OFFER_REQUESTED = 'get/GET_OFFER_REQUESTED';
export const GET_OFFER_SUCCESS = 'get/GET_OFFER_SUCCESS';
export const GET_OFFER_FAILURE = 'get/GET_OFFER_FAILURE';

export const GET_RULE_REQUESTED = 'dailystreakConfig/GET_RULE_REQUESTED';
export const GET_RULE_SUCCESS = 'dailystreakConfig/GET_RULE_SUCCESS';
export const GET_RULE_FAILURE = 'dailystreakConfig/GET_RULE_FAILURE';

export const GET_APP_CONFIG_REQUESTED = 'get/GET_APP_CONFIG_REQUESTED';
export const GET_APP_CONFIG_SUCCESS = 'get/GET_APP_CONFIG_SUCCESS';
export const GET_APP_CONFIG_FAILURE = 'get/GET_APP_CONFIG_FAILURE';

export const CHECK_SCHEDULE_DATE_REQUEST = 'CHECK_SCHEDULE_DATE_REQUEST';
export const CHECK_SCHEDULE_DATE_SUCCESS = 'CHECK_SCHEDULE_DATE_SUCCESS';
export const CHECK_SCHEDULE_DATE_FAILURE = 'CHECK_SCHEDULE_DATE_FAILURE';

export const GET_RULE_CONFIG_REQUESTED = 'GET_RULE_CONFIG_REQUESTED';
export const GET_RULE_CONFIG_SUCCESS = 'GET_RULE_CONFIG_SUCCESS';
export const GET_RULE_CONFIG_FAILURE = 'GET_RULE_CONFIG_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  configLoading: false,
  saveRuleLoading: false,
  activeNavTab: 'customize',
  streakType: '',
  initialValues: {
    fontTitlePositions: [],
    spinButtonPosition: [],
    spinButtonWidth: [],
    spinButtonRadius: [],
    spinButtonShadow: [],
    fontPositions: [],
    outlineStyles: [],
    fonts: [],
    fontStrength: [],
    fontSize: []
  },
  options: {
    activities: [],
    userSegment: [],
    gamificationTemplate: [],
    elements: [],
    quadrant: [],
    rewards: [],
    userEvents: [],
    string: [
      {
        title: '==',
        value: 'equal',
      },
      {
        title: '!=',
        value: 'not_equal',
      },
      {
        title: 'Like',
        value: 'like',
      },
      {
        title: 'is_empty',
        value: 'is_empty',
      },
      {
        title: 'is_null',
        value: 'is_null',
      }
    ],
    number: [
      {
        title: '==',
        value: 'equal',
      },
      {
        title: '!=',
        value: 'not_equal',
      },
      {
        title: '<',
        value: 'less',
      },
      {
        title: '>',
        value: 'greater',
      },
      {
        title: '<=',
        value: 'less_or_equal',
      },
      {
        title: '>=',
        value: 'greater_or_equal',
      }
    ],
    date_time: [
      {
        title: '==',
        value: 'equal',
      },
      {
        title: '!=',
        value: 'not_equal',
      },
      {
        title: '<',
        value: 'less',
      },
      {
        title: '>',
        value: 'greater',
      },
      {
        title: '<=',
        value: 'less_or_equal',
      },
      {
        title: '>=',
        value: 'greater_or_equal',
      },
      {
        title: 'between',
        value: 'between'
      }
    ],
    time: [
      {
        title: '==',
        value: 'equal',
      },
      {
        title: '!=',
        value: 'not_equal',
      },
      {
        title: '<',
        value: 'less',
      },
      {
        title: '>',
        value: 'greater',
      },
      {
        title: '<=',
        value: 'less_or_equal',
      },
      {
        title: '>=',
        value: 'greater_or_equal',
      },
      {
        title: 'between',
        value: 'between'
      }
    ],
    date: [
      {
        title: '==',
        value: 'equal',
      },
      {
        title: '!=',
        value: 'not_equal',
      },
      {
        title: '<',
        value: 'less',
      },
      {
        title: '>',
        value: 'greater',
      },
      {
        title: '<=',
        value: 'less_or_equal',
      },
      {
        title: '>=',
        value: 'greater_or_equal',
      },
      {
        title: 'between',
        value: 'between'
      }
    ],
    days: []
  },
  customize: {
    mode: 'save',
    ruleId: '',
    ruleName: '',
    streakName: '',
    userSegment: 'All User',
    status: 'active',
    template: '',
    templateChange: false,
    combination: false,
    rewardOption: '',
    winningQuadrant: [],
    lossingQuadrant: 0,
    quadrantReward: [],
    scratchRewards: [
      {
        offerId: '',
        offerName: '',
        winning: '',
        score: '=',
        templateId: '',
        templateType: 0,
        ruleId: '',
      }
    ],
    noOfDays: 1,
    sameTemplateForAllDays: 'no',
    winningMsg: '',
    winningText: {
      img: '',
      imageName: '',
      text: '',
      color: '#000000',
      font: {
        size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
        strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
        family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
        align: 'left',
      },
      error: '',
      errorImg: ''
    },
    lossingText: {
      img: '',
      imageName: '',
      text: '',
      color: '#000000',
      font: {
        size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
        strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
        family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
        align: 'left',
      },
      error: '',
      errorImg: ''
    },
    repeatDailyStreak: true,
    cardBackgrounfConfig: {
      completed: '#0DA05A',
      active: '#EE2737',
      default: '#C5C7CB'
    },
    cardContentConfig: {
      completed: '#B6E2CD',
      active: '#FFFFFF',
      default: '#919296'
    },
    gratificationCardColor: '#B8E986',
    streakText: {
      text: '',
      color: '#000000',
      font: {
        size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
        strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
        family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
        align: 'center',
      },
      error: '',
    },
    howToRedeemNeeded: false,
    howToRedeem: {
      text: 'how to redeem',
      color: '#000000',
      font: {
        size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
        strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
        family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
        align: 'center',
      },
      error: '',
    },
    timezone: window.localStorage.getItem('defaultTimezone'),
    localTimezoneStr: window.localStorage.getItem('timeZoneString'),
    iterationsPerFrequency: 1,
    dailyStreakConfig: {
      streakBasedOn: 'Activities',
      title: {
        text: 'Daily Streak',
        color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#FFFFFF' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
        font: {
          size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '20' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontSize,
          strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontWt,
          family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFont,
          align: 'center',
        },
      },
      subtitle: {
        text: 'Maintain a streak to win daily rewards!',
        color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#FFFFFF' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
        font: {
          size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '16' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFontSize,
          strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFontWt,
          family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFont,
          align: 'center',
        },
      },
      cardBackground: 'Color',
      backgroundColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#36DADA' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.primaryColour,
      backgroundImage: '',
      backgroundImageName: '',
      cardBackgroundConfig: {
        completedColor: '#0DA05A',
        activeColor: '#EE2737',
        defaultColor: '#C5C7CB',
        skipColor: '#ffffff'
      },
      cardContentConfig: {
        completedColor: '#B6E2CD',
        activeColor: '#FFFFFF',
        defaultColor: '#919296',
        skipColor: '#000000'
      },
      description: {
        text: 'win rewards everyday!',
        color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#FFFFFF' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
        font: {
          size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '16' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
          strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
          family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
          align: 'center',
        },
      },
      missedDayConfig: 'reset',
      ctaNeeded: false,
      buttonConfig: {
        text: {
          text: '',
          color: '#000000',
          font: {
            size: '14',
            strength: '400',
            family: 'Arial, sans-serif',
            align: 'left',
          },
        },
        bgImage: '',
        bgImageName: '',
        imageError: '',
        width: 'auto',
        radius: '30px',
        shadow: '0px 0px',
        shadowColor: '#cdcdcd',
        position: 'left',
        bgColor: '#ffffff',
        border: '#000000',
        hoverBgColor: '#cdcdcd',
        buttonTextError: '',
        borderWidth: '1px',
        appLink: '',
        webLink: '',
        appLinkError: '',
        webLinkError: '',
      }
    }
  },
  schedule: {
    offerStartPublish: 'now',
    offerStartDate: getTimezoneDate(),
    offerEndPublish: 'never',
    offerEndDate: getTimezoneDate(),
    selectedDays: [],
    selectedDaysTimings: [],
    howOftenSelected: 'oncePerDay',
    trigger: false,
    frequency: false,
    events: [{
      id: 1,
      property: [],
      userEvent: '',
      operator: 'none',
      days: 1,
      userProperty: [{
        id: 1,
        name: '',
        type: '',
        operator: '',
        value: [],
        options: []
      }]
    }]
  },
  background: {
    color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#ffffff' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.primaryColour,
    image: '',
    imageName: '',
    backgroundError: '',
    showProgressBar: false,
    wheelImage: '',
    winningText: {
      img: '',
      imageName: '',
      text: '',
      color: '#000000',
      font: {
        size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
        strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
        family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
        align: 'left',
      },
      error: '',
      errorImg: ''
    },
    lossingText: {
      img: '',
      imageName: '',
      text: '',
      color: '#000000',
      font: {
        size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
        strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
        family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
        align: 'left',
      },
      error: '',
      errorImg: ''
    }
  },
  templates: [],
  quadrants: 0,
  quadrantSegment: [],
  quadrantsOptions: []
};
const getIndexedQuadrants = (index, winingQuadrant) => {
  return winingQuadrant.find(qud => qud.value === index);
};

const getUpdatedWinningQuadrants = (winingQuadrantArray, type) => {
  if (type !== 1) {
    const winingQuadrant = winingQuadrantArray.split(',');
    return winingQuadrant.map((win) => {
      return {
        label: Number(win),
        value: Number(win)
      };
    });
  }
  return Number(winingQuadrantArray);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPLOAD_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_SEGEMENT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SEGEMENT_SUCCESS: {
      const segement = action.result.segmentList;
      const segementOptions = [];
      for (let i = 0; i < segement.length; i += 1) {
        segementOptions.push({ title: segement[i].title, value: segement[i].value });
      }
      segementOptions.push({ title: 'All User', value: 'All User' });
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        options: { ...state.options, ...{ userSegment: segementOptions } },
      };
    }
    case GET_SEGEMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_RULE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_RULE_CONFIG_SUCCESS: {
      const rules = action.result.rewardsList.rule;
      const rulesOptions = [];
      for (let i = 0; i < rules.length; i += 1) {
        if (rules[i].type === 2 || rules[i].type === 3 || rules[i].type === 5 || rules[i].type === 8 || rules[i].type === 4) {
          rulesOptions.push({
            title: rules[i].name, value: rules[i].id, typeId: rules[i].type, ruleReward: rules[i].rewardAssigment
          });
        }
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        options: { ...state.options, ...{ activities: rulesOptions } },
      };
    }
    case GET_RULE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_USER_EVENT_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_USER_EVENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_USER_EVENT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case SAVE_RULE_REQUESTED: {
      return {
        ...state,
        saveRuleLoading: true,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_RULE_SUCCESS: {
      return {
        ...state,
        saveRuleLoading: false,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_RULE_FAILURE: {
      return {
        ...state,
        saveRuleLoading: false,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_APP_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_APP_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_APP_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case CLEAR_FORM: {
      return {
        ...initialState
      };
    }

    case UPDATE_TEMPLATE_DATA: {
      const customize = {
        ...state.customize,
        winningQuadrant: [],
        lossingQuadrant: 0,
        quadrantReward: []
      };
      return {
        ...state,
        customize
      };
    }

    case UPDATE_FIELD: {
      state = state
        ? {
          ...state,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }

    case UPDATE_CUSTOMIZE_FIELD: {
      state.customize = state.customize
        ? {
          ...state.customize,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }

    case UPDATE_OPTIONS: {
      state.options = state.options
        ? {
          ...state.options,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }

    case UPDATE_SCHEDULE_FIELD: {
      if (action.payload.selectedDays) {
        action.payload.selectedDays.sort((a, b) => {
          return a - b;
        });
      }
      const schedule = {
        ...state.schedule,
        ...action.payload
      };
      return { ...state, schedule };
    }

    case UPDATE_WINNING_QUADRANT: {
      const winingQuadrant = action.payload.winningQuadrant;
      if (winingQuadrant instanceof Array) {
        state.quadrantSegment.forEach((winQ, index) => {
          if (getIndexedQuadrants((index + 1), winingQuadrant)) {
            state.quadrantSegment[index].disabled = false;
          } else {
            state.quadrantSegment[index].disabled = true;
          }
        });
        const losingCount = (state.quadrantSegment.length - winingQuadrant.length);
        state.customize.lossingQuadrant = losingCount;
        state.customize.quadrantReward = state.customize.quadrantReward.map((rewQ) => {
          const winQindex = winingQuadrant.findIndex(winq => (winq && winq.value === rewQ.quadrant));
          if (winQindex === -1) {
            return {
              ...rewQ,
              offerId: '',
              offerName: '',
              score: '=',
              winingQuadrant: false
            };
          }
          return rewQ;
        });
      } else {
        const quadrant = [];
        for (let i = 0; i < action.payload.winningQuadrant; i += 1) {
          const data = {
            offerId: '',
            offerName: '',
            quadrant: i + 1,
            resultText: '',
            text: `Rank ${i + 1}`,
            winingQuadrant: true
          };
          quadrant.push(data);
        }
        state.customize.quadrantReward = quadrant;
        state.quadrantSegment = quadrant;
      }
      state.customize = state.customize
        ? {
          ...state.customize,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }

    case UPDATE_BACKGROUND_FIELD: {
      state.background = state.background
        ? {
          ...state.background,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }

    case VALIDATE_CUSTOMIZE_DATA: {
      const { streakType } = state;
      const {
        ruleName,
        streakName,
        scratchRewards,
        howToRedeemNeeded,
        howToRedeem,
        dailyStreakConfig
        // winningText
      } = state.customize;
      const { lossingText } = state.background;
      const { ctaNeeded, buttonConfig } = dailyStreakConfig;
      let error = {
        validCustomize: true,
        // winningText,
        lossingText,
        howToRedeem
      };
      if (ruleName === '') {
        error = {
          ...error,
          validCustomize: false,
          ruleNameError: 'Rule name is Required'
        };
      }
      if (ruleName !== '' && (ruleName.trim().length < 3 || ruleName.length > 50)) {
        error = {
          ...error,
          validCustomize: false,
          ruleNameError: 'Rule name should be of min 3 and max 50 characters'
        };
      }
      if (streakName === '') {
        error = {
          ...error,
          validCustomize: false,
          streakNameError: 'Streak name is Required'
        };
      }
      if (streakName !== '' && streakName && (streakName.trim().length < 3 || streakName.length > 50)) {
        error = {
          ...error,
          validCustomize: false,
          streakNameError: 'Streak name should be of min 3 and max 50 characters'
        };
      }
      // if (winningText.text === '' || winningText.text === '<p> </p>') {
      //   winningText.error = 'Winning text is Required';
      //   error = {
      //     ...error,
      //     validCustomize: false,
      //     // winningText: {
      //     //   ...error.winningText,
      //     //   error: 'Winning text is Required'
      //     // }
      //   };
      // }
      if (state.background.winningText.img === '' || state.background.winningText.img === undefined) {
        state.background.winningText.errorImg = 'Background image is Required';
        error = {
          ...error,
          validCustomize: false,
          // winningText: {
          //   ...error.winningText,
          //   errorImg: 'Background image is Required'
          // }
        };
      }
      // if (winningText.text !== '' && winningText.text !== undefined && (winningText.text && (!(winningText.text.includes('#reward')
      //  || winningText.text.includes('#segmentText') || winningText.text.includes('#rewardType'))))) {
      //   winningText.error = 'Winning text should include #segmentText or #reward or #rewardType';
      //   error = {
      //     ...error,
      //     validCustomize: false,
      //     // winningText: {
      //     //   ...error.winningText,
      //     //   error: 'Winning text should include #segmentText or #reward or #rewardType'
      //     // }
      //   };
      // }
      // if (winningText.text !== '' && (winningText.text && (winningText.text.length < 3
      //   || winningText.text.length > 50))) {
      //   error = {
      //     ...error,
      //     validCustomize: false,
      //     winningText: {
      //       ...error.winningText,
      //       error: 'Winning text should be of min 3 and max 50 characters'
      //     }
      //   };
      // }
      if (streakType === 1) {
        // if (lossingText.text === '') {
        //   error = {
        //     ...error,
        //     validCustomize: false,
        //     lossingText: {
        //       ...error.lossingText,
        //       error: 'Lossing text is Required'
        //     }
        //   };
        // }
        // if (lossingText.img === '') {
        //   error = {
        //     ...error,
        //     validCustomize: false,
        //     lossingText: {
        //       ...error.lossingText,
        //       errorImg: 'Background image is Required'
        //     }
        //   };
        // }
        // if (lossingText.text !== '' && (lossingText.text && (!(lossingText.text.includes('#reward')
        //  || lossingText.text.includes('#segmentText') || lossingText.text.includes('#rewardType'))))) {
        //   error = {
        //     ...error,
        //     validCustomize: false,
        //     lossingText: {
        //       ...error.lossingText,
        //       error: 'Lossing text should include #segmentText or #reward or #rewardType'
        //     }
        //   };
        // }
        // if (lossingText.text !== '' && (lossingText.text && (lossingText.text.length < 3
        //   || lossingText.text.length > 50))) {
        //   error = {
        //     ...error,
        //     validCustomize: false,
        //     lossingText: {
        //       ...error.lossingText,
        //       error: 'Lossing text should be of min 3 and max 50 characters'
        //     }
        //   };
        // }
      }
      if ((streakType === 2 || howToRedeemNeeded) && howToRedeem.text === '') {
        error = {
          ...error,
          validCustomize: false,
          howToRedeem: {
            ...error.howToRedeem,
            error: 'Redeem Instructions is Required'
          }
        };
      }
      // let qErrorRequired = false;
      // const qIndexs = [];
      // scratchRewards.forEach((q, index) => {
      //   if (q.offerId === '') {
      //     qErrorRequired = true;
      //     qIndexs.push(index + 1);
      //   }
      // });
      // if (qErrorRequired) {
      //   error = {
      //     ...error,
      //     validCustomize: false,
      //     rewardQError: `Reward Assignment for Day ${qIndexs.join(',')} is Required`
      //   };
      // }
      if (ctaNeeded) {
        if (buttonConfig.text.text === '') {
          buttonConfig.buttonTextError = 'Text is Required';
          error = {
            ...error,
            validCustomize: false,
            // buttonTextError: 'Text is Required'
          };
        }
        if(buttonConfig.appLink === '') {
          buttonConfig.appLinkError = 'App Link is Required';
          error = {
            ...error,
            validCustomize: false,
          };
        }
        if(buttonConfig.webLink === '') {
          buttonConfig.webLinkError = 'Web Link is Required';
          error = {
            ...error,
            validCustomize: false,
          };
        }
      }
      let qTemplateErrorRequired = false;
      const qTemplateIndexs = [];
      scratchRewards.forEach((q, index) => {
        if (q.templateId === '') {
          qTemplateErrorRequired = true;
          qTemplateIndexs.push(index + 1);
        }
      });
      if (qTemplateErrorRequired) {
        error = {
          ...error,
          validCustomize: false,
          rewardQError: `Select Template for Day ${qTemplateIndexs.join(',')} is Required`
        };
      }
      const customize = {
        ...state.customize,
        ...error
      };
      const background = {
        ...state.background,
        ...error
      };
      return {
        ...state,
        customize,
        background
      };
    }
    case CREATE_OPTIONS: {
      const days = [];
      for (let i = 1; i <= 30; i += 1) {
        days.push({
          title: i,
          value: i
        });
      }
      let { options } = state;
      options = {
        ...options,
        days
      };
      return {
        ...state,
        options,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CONFIG_SUCCESS: {
      const templates = action.result.templates.templateDTOs;
      const templateOptions = [];
      for (let i = 0; i < templates.length; i += 1) {
        if (templates[i].templateName != null) {
          templateOptions.push({
            title: templates[i].templateName,
            value: templates[i].id,
            typeID: templates[i].typeId
          });
        } else {
          templateOptions.push({
            title: templates[i].title,
            value: templates[i].id,
            typeID: templates[i].typeId
          });
        }
      }
      if(state.streakType === 2) {
        templateOptions.push({ title: 'No Template', value: 'No Template' });
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        options: { ...state.options, ...{ gamificationTemplate: templateOptions } },
      };
    }
    case GET_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_OFFER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_OFFER_SUCCESS: {
      const offers = action.result.rewardsList.offerConfig;
      const offerOptions = [];
      for (let i = 0; i < offers.length; i += 1) {
        offerOptions.push({ title: offers[i].offerName, value: offers[i].id, rewardType: offers[i].rewardType });
      }
      offerOptions.push({ title: 'No offers', value: 'No Offer' });
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        options: { ...state.options, ...{ rewards: offerOptions } },
      };
    }
    case GET_OFFER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_RULE_REQUESTED: {
      return {
        ...state,
        saveRuleLoading: true,
        loading: true,
        loaded: false,
      };
    }
    case GET_RULE_SUCCESS: {
      const { ruleEntity } = action.result;
      const quadrantReward = [];
      const scratchRewards = [];
      if (ruleEntity.type === 2 || (ruleEntity.type === 1 && ruleEntity.rewardOption === 'rank')) {
        ruleEntity.rewardAssigment.forEach((updateReward) => {
          quadrantReward[updateReward.quadrant - 1] = updateReward;
        });
      } else {
        ruleEntity.rewardAssigment.forEach((updateReward) => {
          const rewards = {
            winning: updateReward.percentage,
            offerId: updateReward.offerId,
            offerName: updateReward.offerName,
            score: updateReward.score,
            elements1: updateReward.elements && updateReward.elements[0],
            elements2: updateReward.elements && updateReward.elements[1],
            elements3: updateReward.elements && updateReward.elements[2],
            templateId: updateReward.templateId !== '' ? updateReward.templateId : 'No Template',
            templateType: updateReward.templateType,
            rewardType: updateReward.rewardType ? Number(updateReward.rewardType) : 0,
            ruleId: updateReward.ruleId !== '' ? updateReward.ruleId : 'No Template',
          };
          scratchRewards.push(rewards);
        });
      }
      const winningText = {
        img: ruleEntity.winingBackgroundImage,
        imageName: ruleEntity.winingBackgroundImageName,
        text: ruleEntity.winingText,
        color: ruleEntity.winingTextFontColor,
        font: {
          size: ruleEntity.winingTextFontSize,
          strength: ruleEntity.winingTextFontWeight,
          family: ruleEntity.winingTextFont,
          align: ruleEntity.winingTextPlace,
        },
        error: '',
        errorImg: ''
      };
      const lossingText = {
        img: ruleEntity.losingBackgroundImage,
        imageName: ruleEntity.losingBackgroundImageName,
        text: ruleEntity.losingText,
        color: ruleEntity.losingTextFontColor,
        font: {
          size: ruleEntity.losingTextFontSize,
          strength: ruleEntity.losingTextFontWeight,
          family: ruleEntity.losingTextFont,
          align: ruleEntity.losingTextPlace,
        },
        error: '',
        errorImg: ''
      };
      const howToRedeemNeeded = ruleEntity.howToRedeemNeeded;
      const howToRedeem = {
        text: ruleEntity.howToRedeem,
        color: ruleEntity.howToRedeemFontColor,
        font: {
          size: ruleEntity.howToRedeemFontSize,
          strength: ruleEntity.howToRedeemFontWeight,
          family: ruleEntity.howToRedeemFont,
          align: ruleEntity.howToRedeemPlace,
        },
        error: '',
      };
      const cardBackgrounfConfig = {
        completed: ruleEntity.bgCompletedColor,
        active: ruleEntity.bgActiveColor,
        default: ruleEntity.bgDefaultColor
      };
      const cardContentConfig = {
        completed: ruleEntity.cardCompletedColor,
        active: ruleEntity.cardActiveColor,
        default: ruleEntity.cardDefaultColor
      };
      const gratificationCardColor = ruleEntity.gratificationCardColor;
      const streakText = {
        text: ruleEntity.streakText,
        color: ruleEntity.streakTextFontColor,
        font: {
          size: ruleEntity.streakTextFontSize,
          strength: ruleEntity.streakTextFontWeight,
          family: ruleEntity.streakTextFont,
          align: ruleEntity.streakTextPlace,
        },
        error: ''
      };
      const customize = {
        mode: 'edit',
        ruleId: ruleEntity.id,
        ruleName: ruleEntity.name,
        streakName: ruleEntity.streakName,
        userSegment: ruleEntity.userSegmentId === '' ? 'All User' : ruleEntity.userSegmentId,
        combination: ruleEntity.combination,
        status: ruleEntity.ruleStatus,
        dailyStreakConfig: ruleEntity.dailyStreakDTO,
        // streakBasedOn: ruleEntity.streakBasedOn,
        template: ruleEntity.templateId,
        winningQuadrant: getUpdatedWinningQuadrants(ruleEntity.winningQuandrants, ruleEntity.type),
        lossingQuadrant: Number(ruleEntity.losingQuandrants),
        quadrantReward,
        scratchRewards,
        winningText,
        lossingText,
        typeID: ruleEntity.type,
        rewardOption: ruleEntity.rewardOption,
        noOfDays: ruleEntity.noOfDays,
        sameTemplateForAllDays: ruleEntity.sameTemplateForAllDays,
        howToRedeemNeeded,
        howToRedeem,
        repeatDailyStreak: ruleEntity.repeatDailyStreak,
        timezone: ruleEntity.timezone,
        localTimezoneStr: `Set at ${ruleEntity.timezone} (UTC${getTimezoneOffset(ruleEntity.timezone)})`,
        iterationsPerFrequency: ruleEntity.iterationsPerFrequency,
        cardBackgrounfConfig,
        cardContentConfig,
        gratificationCardColor,
        streakText
      };
      const streakType = ruleEntity.streakType;
      const background = {
        color: ruleEntity.streakBackgroundColor,
        image: ruleEntity.streakBackgroundImage,
        imageName: ruleEntity.streakBackgroundImageName,
        backgroundError: '',
        showProgressBar: ruleEntity.streakBackgroundImageName !== '',
        wheelImage: '',
        winningText,
        lossingText
      };
      let selectedDays = [];
      const selectedDaysTimings = [];
      ruleEntity.applyOffersOn.forEach((offer) => {
        selectedDays.push(offer.day - 1);
        selectedDaysTimings[offer.day - 1] = ({
          start: new Date(offer.startTime),
          end: new Date(offer.endTime)
        });
      });
      selectedDays = selectedDays.sort((a, b) => {
        return a - b;
      });
      let { events } = ruleEntity.userSegment;
      events = events && events.map((event) => {
        event.property = event.property.map((prop) => {
          return {
            ...prop,
            title: prop.name,
            value: prop.name
          };
        });
        event.userProperty = event.userProperty.map((userProp) => {
          const propObj = event.property.find(ele => ele.name === userProp.name);
          userProp.options = propObj.options;
          return userProp;
        });
        return event;
      });
      if (events.length === 0) {
        events = state.schedule.events;
      }
      const schedule = {
        offerStartPublish: 'later',
        offerStartDate: DateTime.fromMillis(ruleEntity.startDateTime, { zone: ruleEntity.timezone }),
        offerEndPublish: ruleEntity.endDateTime === 9999999999999 ? 'never' : 'later',
        offerEndDate: ruleEntity.endDateTime === 9999999999999 ? DateTime.now().setZone(ruleEntity.timezone) : DateTime.fromMillis(ruleEntity.endDateTime, { zone: ruleEntity.timezone }),
        selectedDays,
        selectedDaysTimings,
        howOftenSelected: ruleEntity.howOften,
        trigger: ruleEntity.trigger,
        frequency: ruleEntity.frequency,
        events
      };
      const rewards = state.options.rewards;
      return {
        ...state,
        streakType,
        customize,
        schedule,
        background,
        quadrantSegment: customize.quadrantReward,
        saveRuleLoading: false,
        loading: false,
        error: false,
        loaded: true,
        options: { ...state.options, ...{ rewards } },
      };
    }

    case GET_RULE_FAILURE: {
      return {
        ...state,
        saveRuleLoading: false,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_APPCONFIG_FIELD: {
      state.initialValues = state.initialValues
        ? {
          ...state.initialValues,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case CHECK_SCHEDULE_DATE_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case CHECK_SCHEDULE_DATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case CHECK_SCHEDULE_DATE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const createOptions = (type) => {
  if (type === 'days') {
    return {
      type: CREATE_OPTIONS,
    };
  }
  return {};
};


export const updateWinningQuadrant = (payload) => {
  return {
    type: UPDATE_WINNING_QUADRANT,
    payload,
  };
};

export const updateTemplateData = () => {
  return {
    type: UPDATE_TEMPLATE_DATA,
  };
};
export const validateCustomize = () => {
  return {
    type: VALIDATE_CUSTOMIZE_DATA,
  };
};

export const updateCustomize = (payload) => {
  return {
    type: UPDATE_CUSTOMIZE_FIELD,
    payload,
  };
};

export const updateSchedule = (payload) => {
  return {
    type: UPDATE_SCHEDULE_FIELD,
    payload,
  };
};

export const clearForm = () => {
  return {
    type: CLEAR_FORM,
  };
};

// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const updateDropdown = (payload) => {
  return {
    type: UPDATE_APPCONFIG_FIELD,
    payload,
  };
};

export const updateOptions = (payload) => {
  return {
    type: UPDATE_OPTIONS,
    payload,
  };
};

export const uploadimage = (file, folderName) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/gamification/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
      .field('folderName', folderName)
  };
};

export const getSegment = () => {
  return {
    types: [GET_SEGEMENT_REQUESTED, GET_SEGEMENT_SUCCESS, GET_SEGEMENT_FAILURE],
    promise: client => client.get('getSegmentList'),
  };
};

export const getEvents = () => {
  return {
    types: [GET_USER_EVENT_REQUEST, GET_USER_EVENT_SUCCESS, GET_USER_EVENT_FAILURE],
    promise: client => client.get('getUserEvents')
  };
};

const getWinningQuadrantsIds = (winningQuadrant, typeID) => {
  if (typeID !== 1) {
    return winningQuadrant.map(e => e.value).join(',');
  }
  if (winningQuadrant.length === 0) {
    return '';
  }
  return winningQuadrant;
};

const getApplyOffers = (array) => {
  const timingsArray = [];
  array.forEach((time, index) => {
    if (time !== null) {
      timingsArray.push({
        day: (index + 1),
        startTime: new Date(time.start).getTime(),
        endTime: new Date(time.end).getTime()
      });
    }
  });
  return timingsArray;
};

const getRewardAssignment = (rewards, winingText, losingText) => {
  return rewards.map((e) => {
    if (e.winingQuadrant) {
      return {
        ...e,
        resultText: winingText
      };
    }
    return {
      ...e,
      resultText: losingText
    };
  });
};

const getReward = (rewards, winningText, lossingText, typeID) => {
  return rewards.map((e) => {
    const element = [];
    if (typeID === 5) {
      element.push(e.elements1);
      element.push(e.elements2);
      element.push(e.elements3);
    }
    return {
      percentage: e.winning,
      offerId: e.offerId !== 'No Offer' ? e.offerId : '',
      offerName: e.offerName !== 'No Offer' ? e.offerName : '',
      resultText: e.offerName !== 'No Offer' ? winningText : lossingText,
      winingQuadrant: e.offerName !== 'No Offer',
      score: e.score,
      elements: element,
      templateId: e.templateId !== 'No Template' ? e.templateId : '',
      templateType: e.templateType,
      ruleId: e.ruleId,
    };
  });
};

export const getRuleEngineData = (ruleState, query) => {
  let operator = '';
  query.forEach((qu, index) => {
    operator += `Q${(index + 1)}`;
    if ((query[index + 1])) {
      operator += ` ${this.getOperator(qu.operator.toLowerCase())} `;
    }
  });
  const userSegment = {
    events: ruleState.schedule.trigger ? ruleState.schedule.events : [],
    query: ruleState.schedule.trigger ? query : [],
    operator: ruleState.schedule.trigger ? operator : ''
  };
  const data = {
    ruleId: ruleState.customize.ruleId ? ruleState.customize.ruleId : '',
    name: ruleState.customize.ruleName,
    streakName: ruleState.customize.streakName,
    streakType: ruleState.streakType,
    templateId: ruleState.customize.template,
    type: ruleState.customize.typeID,
    ruleStatus: ruleState.customize.status,
    streakBasedOn: ruleState.customize.streakBasedOn,
    combination: ruleState.customize.combination,
    winningQuandrants: getWinningQuadrantsIds(ruleState.customize.winningQuadrant, ruleState.customize.typeID),
    losingQuandrants: ruleState.customize.lossingQuadrant,
    userSegmentId: ruleState.customize.userSegment === 'All User' ? '' : ruleState.customize.userSegment,
    howOften: ruleState.schedule.howOftenSelected,
    startDateTime: ruleState.schedule.offerStartDate.set({ second: 0 }).ts,
    endDateTime: ruleState.schedule.offerEndPublish === 'later'
      ? ruleState.schedule.offerEndDate.set({ second: 0 }).ts : 9999999999999,
    rewardAssigment: (ruleState.customize.typeID === 2
      || (ruleState.customize.typeID === 1 && ruleState.customize.rewardOption === 'rank'))
      ? getRewardAssignment(ruleState.customize.quadrantReward,
        ruleState.customize.winningText.text, ruleState.customize.lossingText.text)
      : getReward(ruleState.customize.scratchRewards, ruleState.customize.winningText.text,
        ruleState.customize.lossingText.text, ruleState.customize.typeID),
    applyOffersOn: ruleState.schedule.frequency ? getApplyOffers(ruleState.schedule.selectedDaysTimings) : [],
    trigger: ruleState.schedule.trigger,
    frequency: ruleState.schedule.frequency,
    userSegment,
    rewardOption: ruleState.customize.rewardOption,
    ruleType: 2,
    noOfDays: ruleState.customize.noOfDays,
    sameTemplateForAllDays: ruleState.customize.sameTemplateForAllDays,
    winingText: ruleState.customize.winningText.text,
    // winingTextFontColor: ruleState.background.winningText.color,
    // winingTextFontSize: ruleState.background.winningText.font.size,
    // winingTextFontWeight: ruleState.background.winningText.font.strength,
    // winingTextFont: ruleState.background.winningText.font.family,
    // winingTextPlace: ruleState.background.winningText.font.align,
    winingBackgroundImage: ruleState.background.winningText.img,
    winingBackgroundImageName: ruleState.background.winningText.imageName,
    losingText: ruleState.background.lossingText.text,
    losingTextFontColor: ruleState.background.lossingText.color,
    losingTextFontSize: ruleState.background.lossingText.font.size,
    losingTextFontWeight: ruleState.background.lossingText.font.strength,
    losingTextFont: ruleState.background.lossingText.font.family,
    losingTextPlace: ruleState.background.lossingText.font.align,
    losingBackgroundImage: ruleState.background.lossingText.img,
    losingBackgroundImageName: ruleState.background.lossingText.imageName,
    streakBackgroundColor: ruleState.background.color,
    streakBackgroundImage: ruleState.background.image,
    streakBackgroundImageName: ruleState.background.imageName,
    howToRedeemNeeded: ruleState.streakType === 2 ? true : ruleState.customize.howToRedeemNeeded,
    howToRedeem: ruleState.customize.howToRedeem.text,
    howToRedeemFont: ruleState.customize.howToRedeem.font.family,
    howToRedeemFontSize: ruleState.customize.howToRedeem.font.size,
    howToRedeemFontColor: ruleState.customize.howToRedeem.color,
    howToRedeemFontWeight: ruleState.customize.howToRedeem.font.strength,
    howToRedeemPlace: ruleState.customize.howToRedeem.font.align,
    timezone: ruleState.customize.timezone,
    iterationsPerFrequency: ruleState.customize.iterationsPerFrequency,
    bgCompletedColor: ruleState.customize.cardBackgrounfConfig.completed,
    bgActiveColor: ruleState.customize.cardBackgrounfConfig.active,
    bgDefaultColor: ruleState.customize.cardBackgrounfConfig.default,
    cardCompletedColor: ruleState.customize.cardContentConfig.completed,
    cardActiveColor: ruleState.customize.cardContentConfig.active,
    cardDefaultColor: ruleState.customize.cardContentConfig.default,
    gratificationCardColor: ruleState.customize.gratificationCardColor,
    streakText: ruleState.customize.streakText.text,
    streakTextFontColor: ruleState.customize.streakText.color,
    streakTextFontSize: ruleState.customize.streakText.font.size,
    streakTextFontWeight: ruleState.customize.streakText.font.strength,
    streakTextFont: ruleState.customize.streakText.font.family,
    streakTextPlace: ruleState.customize.streakText.font.align,
    repeatDailyStreak: ruleState.customize.repeatDailyStreak,
    dailyStreakDTO: ruleState.customize.dailyStreakConfig,
  };
  return data;
};

export const saveRule = (ruleState, query) => {
  const data = getRuleEngineData(ruleState, query);
  return {
    types: [SAVE_RULE_REQUESTED, SAVE_RULE_SUCCESS, SAVE_RULE_FAILURE],
    promise: client => client.post('saveRule', {
      data,
    }),
  };
};

export const checkRuleNameDuplicate = (name, id) => {
  return {
    types: [CHECK_RULE_NAME_REQUEST, CHECK_RULE_NAME_SUCCESS, CHECK_RULE_NAME_FAILURE],
    promise: client => client.get(`checkRuleName?name=${name}&id=${id}`),
  };
};

export const getSpinWheelConfig = () => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(
      'getDailyStreakTemplate'
    ),
  };
};

export const getRuleConfig = (data) => {
  return {
    types: [GET_RULE_CONFIG_REQUESTED, GET_RULE_CONFIG_SUCCESS, GET_RULE_CONFIG_FAILURE],
    promise: client => client.get(`getRuleEngineList/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.title}&sortBy=${data.sortBy}&sortType=${data.orderBy}`)
  };
};

export const getMyOffers = (data) => {
  let reqParams = '';
  if (data.searchKey !== '') {
    reqParams = `${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&sortType=${data.orderBy
    }`;
  } else {
    reqParams = `${data.itemsPerPage}/${data.pageNo}?searchKey=&sortBy=${data.sortBy}&sortType=${data.orderBy}`;
  }
  return {
    types: [GET_OFFER_REQUESTED, GET_OFFER_SUCCESS, GET_OFFER_FAILURE],
    promise: client => client.get(`getMyOffersList/${reqParams}`),
  };
};

export const getRule = (streakId) => {
  return {
    types: [GET_RULE_REQUESTED, GET_RULE_SUCCESS, GET_RULE_FAILURE],
    promise: client => client.get(`getRule?ruleId=${streakId}`),
  };
};

export const updateBackground = (payload) => {
  return {
    type: UPDATE_BACKGROUND_FIELD,
    payload,
  };
};

export const getAppConfig = () => {
  return {
    types: [GET_APP_CONFIG_REQUESTED, GET_APP_CONFIG_SUCCESS, GET_APP_CONFIG_FAILURE],
    promise: client => client.get('getAppConfig'),
  };
};

export const checkScheduleDate = (startDateTime, endDateTime) => {
  return {
    types: [CHECK_SCHEDULE_DATE_REQUEST, CHECK_SCHEDULE_DATE_SUCCESS, CHECK_SCHEDULE_DATE_FAILURE],
    promise: client => client.get(`checkScheduleDate?startDateTime=${startDateTime.ts}&endDateTime=${endDateTime.ts}`),
  };
};
