// import { DateTime } from 'luxon';
import { APP_CONFIG } from '@constants';
import { getTimezoneDate } from '@helpers/Utility';

const upload = require('superagent');

export const UPDATE_TITLE_FIELD = 'quest/UPDATE_TITLE_FIELD';
export const UPDATE_SUBTITLE_FIELD = 'quest/UPDATE_SUBTITLE_FIELD';
export const UPDATE_BACKGROUND_FIELD = 'quest/UPDATE_BACKGROUND_FIELD';
export const UPDATE_TITLE_BACKGROUND_FIELD = 'quest/UPDATE_TITLE_BACKGROUND_FIELD';
export const UPDATE_QUEST_TIMER_FIELD = 'quest/UPDATE_QUEST_TIMER_FIELD';
export const UPDATE_BANNER_IMAGE = 'quest/UPDATE_BANNER_IMAGE';
export const UPDATE_RESULT_FIELD = 'quest/UPDATE_RESULT_FIELD';
export const UPDATE_APPCONFIG_FIELD = 'quest/UPDATE_APPCONFIG_FIELD';
export const UPDATE_GAMES_LIST = 'quest/UPDATE_GAMES_LIST';

export const UPLOAD_IMAGE_REQUESTED = 'upload/UPLOAD_IMAG_REQUESTED';
export const UPLOAD_IMAGE_SUCCESS = 'upload/UPLOAD_IMAG_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'upload/UPLOAD_IMAG_FAILURE';

export const SAVE_CONFIG_REQUESTED = 'save/SAVE_CONFG_REQUESTED';
export const SAVE_CONFIG_SUCCESS = 'save/SAVE_CONFG_SUCCESS';
export const SAVE_CONFIG_FAILURE = 'save/SAVE_CONFG_FAILURE';

export const GET_CONFIG_REQUESTED = 'get/GET_CONFG_REQUESTED';
export const GET_CONFIG_SUCCESS = 'get/GET_CONFG_SUCCESS';
export const GET_QUEST_SUCCESS = 'get/GET_QUEST_SUCCESS';
export const GET_CONFIG_FAILURE = 'get/GET_CONFIG_FAILURE';

export const UPDATE_CONFIG_REQUESTED = 'update/UPDATE_CONFG_REQUESTED';
export const UPDATE_CONFIG_SUCCESS = 'update/UPDATE_CONFG_SUCCESS';
export const UPDATE_CONFIG_FAILURE = 'update/UPDATE_CONFG_FAILURE';

export const DUPLICATE_CONFIG_REQUESTED = 'get/DUPLICATE_CONFG_REQUESTED';
export const DUPLICATE_CONFIG_SUCCESS = 'get/DUPLICATE_CONFG_SUCCESS';
export const DUPLICATE_CONFIG_FAILURE = 'get/DUPLICATE_CONFG_FAILURE';

export const UPDATE_FIELD = 'quest/UPDATE_FIELD';
export const UPDATE_PAGE = 'quest/UPDATE_PAGE';
export const UPDATE_TOURNAMENT_RULES_PARAMS = 'quest/UPDATE_TOURNAMENT_RULES_PARAMS';
export const UPDATE_PRIZE_TEXT_PARAMS = 'quest/UPDATE_PRIZE_TEXT_PARAMS';

export const UPDATE_ACTIVE_FLAG = 'quest/UPDATE_ACTIVE_FLAG';
export const UPDATE_LOCALIZATION_FLAG = 'quest/UPDATE_LOCALIZATION_FLAG';
export const UPDATE_CONFIG_FIELD = 'quest/UPDATE_CONFIG_FIELD';
export const UPDATE_LANGUAGE = 'quest/UPDATE_LANGUAGE';
export const REMOVE_CONFIG = 'quest/REMOVE_CONFIG';
export const CLEAR_STATE = 'quest/CLEAR_STATE';
export const UPDATE_BUTTON = 'quest/UPDATE_BUTTON';
export const UPDATE_BUTTON_CONFIG = 'quest/UPDATE_BUTTON_CONFIG';
export const UPDATE_BUTTON_CONFIG2 = 'quest/UPDATE_BUTTON_CONFIG2';
export const UPDATE_TIME_FIELD = 'quest/UPDATE_TIME_FIELD';
export const UPDATE_TEMPLATE_PAGE = 'quest/UPDATE_TEMPLATE_PAGE';
export const UPDATE_QUEST_DESC_FIELD = 'quest/UPDATE_QUEST_DESC_FIELD';
export const UPDATE_TOURNAMENT_RULES_FIELD = 'quest/UPDATE_TOURNAMENT_RULES_FIELD';
export const UPDATE_PRIZE_TEXT_FIELD = 'quest/UPDATE_PRIZE_TEXT_FIELD';
export const DELETE_CONFIG_REQUESTED = 'get/DELETE_CONFIG_REQUESTED';
export const DELETE_CONFIG_SUCCESS = 'get/DELETE_CONFIG_SUCCESS';
export const DELETE_CONFIG_FAILURE = 'get/DELETE_CONFIG_FAILURE';
export const UPDATE_TEMPLATE_FIELD = 'template/UPDATE_TEMPLATE_FIELD';
export const UPDATE_LOCAL_CONFIG = 'quest/UPDATE_LOCAL_CONFIG';
export const UPDATE_SELECTED_FIELD = 'template/UPDATE_SELECTED_FIELD';

export const GET_SEGEMENT_REQUESTED = 'quest/GET_SEGEMENT_REQUESTED';
export const GET_SEGEMENT_SUCCESS = 'quest/GET_SEGEMENT_SUCCESS';
export const GET_SEGEMENT_FAILURE = 'quest/GET_SEGEMENT_FAILURE';

export const UPDATE_BADGES_LIST = 'quest/UPDATE_BADGES_LIST';
export const UPDATE_REWARDS_LIST = 'quest/UPDATE_REWARDS_LIST';
export const UPDATE_PROGRESS_BAR_LIST = 'quest/UPDATE_PROGRESS_BAR_LIST';
export const UPDATE_USERSEGMENT_LIST = 'quest/UPDATE_USERSEGMENT_LIST';
export const UPDATE_CUSTOMEVENTS_LIST = 'quest/UPDATE_CUSTOMEVENTS_LIST';
export const UPDATE_ACTIVITY_LIST = 'quest/UPDATE_ACTIVITY_LIST';
export const UPDATE_LB_LIST = 'quest/UPDATE_LB_LIST';

export const CLEAR_FORM = 'quest/CLEAR_FORM';
export const UPDATE_QUEST_LOADER = 'quest/UPDATE_QUEST_LOADER';

export const UPDATE_MSVALID = 'quest/UPDATE_MSVALID';
export const UPDATE_TASKVALID = 'quest/UPDATE_TASKVALID';
export const UPDATE_MSTITIE_VALID = 'quest/UPDATE_MSTITIE_VALID';
export const UPDATE_TASKTITLE_VALID = 'quest/UPDATE_TASKTITLE_VALID';
export const CLEAR_CONFIG = 'quest/CLEAR_CONFIG';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  options: {
    gamificationTemplate: [],
  },
  templatesList: [],
  userSegmentList: [],
  progressBarList: [],
  badgesList: [],
  rewardsList: [],
  customEventList: [],
  activityList: [],
  leaderBoardList: [],
  templateinitialValues: {
    itemsPerPage: 8,
    total: 0,
    pageNo: 0,
    searchKey: '',
    sortBy: '',
    orderBy: '',
  },
  initialValues: {
    ruleId: '',
    orderBy: '',
    tableData: {},
    fontTitlePositions: [],
    questionType: [],
    quizQuestionImagePosition: [],
    fonts: [],
    fontStrength: [],
    fontSize: [],
    spinButtonPosition: [],
    spinButtonWidth: [],
    spinButtonRadius: [],
    spinButtonShadow: [],
    fontPositions: [],
    outlineStyles: [],
    buttonAction: [],
    thicknessOptions: [
      {
        title: '1',
        value: '1'
      },
      {
        title: '2',
        value: '2'
      },
      {
        title: '3',
        value: '3'
      },
      {
        title: '4',
        value: '4'
      },
      {
        title: '5',
        value: '5'
      },
    ],
    radiusOptions: [
      {
        title: '10%',
        value: '10%'
      },
      {
        title: '50%',
        value: '50%'
      },
      {
        title: '100%',
        value: '100%'
      },
    ],
    status: 'active',
  },
  progressBarTypeLibrary: [
    {
      imgUrl: 'https://cdnstg.gamize.com/13/opinion/15/9259_Group 2609535 (1).png',
      type: 1
    },
    {
      imgUrl: 'https://cdnstg.gamize.com/13/opinion/15/5652_Group 2609535.png',
      type: 2
    },
    {
      imgUrl: 'https://cdnstg.gamize.com/13/opinion/15/4058_Group 2609535 (2).png',
      type: 3
    },
    {
      imgUrl: 'https://cdnstg.gamize.com/13/opinion/15/9065_Group 2609537.png',
      type: 4
    },
    {
      imgUrl: 'https://cdnstg.gamize.com/13/opinion/15/4935_Group 2609537 (1).png',
      type: 5
    },
  ],
  localizationFlag: '',
  activeNavTab: 'English',
  mode: 'save',
  time: {
    startDateTime: getTimezoneDate(),
    endDateTime: getTimezoneDate(),
    now: true,
    never: true,
    // startDateTime: DateTime.now().setZone(window.localStorage.getItem('defaultTimezone')),
    // endDateTime: DateTime.now().setZone(window.localStorage.getItem('defaultTimezone')),
    dateTimeError: ''
  },
  languages: ['Engilsh'],
  config: {
    localizationconfig: [
      {
        language: 'English',
        title: {
          text: '',
          color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#000000' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
          font: {
            size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '32' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontSize,
            strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontWt,
            family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFont,
            align: 'center',
          },
          // position: 'center',
          titleError: '',
          disableSave: false,
          questName: {
            text: ''
          },
          templateError: '',
          titleImageUrl: '',
          titleImageName: '',
          titleImageError: '',
          selectError: '',
        },
        subtitle: {
          text: '',
          color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#000000' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
          font: {
            size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFontSize,
            strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFontWt,
            family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFont,
            align: 'center',
          },
          titleError: '',
        },
        allowEdit: true,
        questDescription: {
          text: '',
          color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#000000' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
          font: {
            size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
            strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
            family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
            align: 'center',
          },
          tournamentDescriptionError: '',
        },
        titleBackgroundRadio: true,
        titleBackground: {
          color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#FFFFFF' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.primaryColour,
          image: '',
          imageName: '',
          backgroundError: '',
        },
        timerIconColor: '#ffffff',
        timerBackgroundColor: '#ffffff',
        questTimer: {
          family: 'Outfit, sans-serif',
          strength: '400',
          size: '20',
          color: '#ffffff',
        },
        progressBarDesignType: '1',
        progressBarColorCompleted: '#ffffff',
        progressBarColorDefault: '#ffffff',
        fadeRemaningEvent: true,
        userSegmentId: 'All User',
        background: {
          color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#FFFFFF' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.primaryColour,
          image: 'https://www.googleapis.com/download/storage/v1/b/ec2-us-east-1-1b-dev-images-gamification/o/quiz%2F76%2F72%2F6464_quiz.png?alt=media',
          imageName: 'backgroundImage.png',
          backgroundError: '',
        },
        bannerImage: {
          color: '#ffffff',
          bannerImage: '',
          bannerImageName: '',
          bannerImageError: '',
          showProgressBar: false,
          wheelImage: '',
        },
        resultText: {
          winningText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: '14',
              strength: '400',
              family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
              align: 'left',
            },
            error: '',
            errorImg: ''
          },
          losingText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: '14',
              strength: '400',
              family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
              align: 'left',
            },
            error: '',
            errorImg: ''
          }
        },
        milestoneNeeded: true,
        milestonesConfig: [
          {
            title: {
              text: '',
              color: '#000000',
              font: {
                size: '32',
                strength: '600',
                family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFont,
                align: 'center',
              },
              titleError: '',
              disableSave: false,
              titleImageUrl: '',
              titleImageName: '',
              titleImageError: '',
              selectError: '',
            },
            subtitle: {
              text: '',
              color: '#000000',
              font: {
                size: '18',
                strength: '400',
                family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFont,
                align: 'center',
              },
              titleError: '',
            },
            allowEdit: true,
            milestoneDescription: {
              text: '',
              color: '#000000',
              font: {
                size: '18',
                strength: '400',
                family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
                align: 'center',
              },
              milestoneDescriptionError: '',
            },
            progressMsName: null,
            progressMsImg: null,
            progressMsImgName: null,
            progressMsImgError: '',
            // milestoneTimer: true,
            // milestoneStartDate: getTimezoneDate(),
            // milestoneEndDate: getTimezoneDate(),
            // msDateTimeError: '',
            // milestoneTimerIconColor: '#ffffff',
            msRewardId: '',
            msBadgeId: '',
            backgroundColorBefore: '#FFFFFF',
            borderColorBefore: '#FFFFFF',
            thicknessBefore: '1',
            radiusBefore: '30px',
            backgroundColorAfter: '#FFFFFF',
            borderColorAfter: '#FFFFFF',
            thicknessAfter: '1',
            radiusAfter: '30px',
            msWinningMsg: {
              text: '',
              color: '#000000',
              font: {
                size: '18',
                strength: '400',
                family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
                align: 'center',
              },
            },
            msWinningImg: '',
            msWinningImgName: '',
            msWinningImageError: '',
            questionIndex: 0,
            tasksConfig: [
              {
                title: {
                  text: '',
                  color: '#000000',
                  font: {
                    size: '32',
                    strength: '600',
                    family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFont,
                    align: 'center',
                  },
                  titleError: '',
                  disableSave: false,
                  titleImageUrl: '',
                  titleImageName: '',
                  titleImageError: '',
                  selectError: '',
                },
                subtitle: {
                  text: '',
                  color: '#000000',
                  font: {
                    size: '18',
                    strength: '400',
                    family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFont,
                    align: 'center',
                  },
                  titleError: '',
                },
                allowEdit: true,
                taskDescription: {
                  text: '',
                  color: '#000000',
                  font: {
                    size: '18',
                    strength: '400',
                    family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
                    align: 'center',
                  },
                  taskDescriptionError: '',
                },
                progressTaskName: null,
                progressTaskImg: null,
                progressTaskImgName: null,
                progressTaskImgError: '',
                activity: 'customEvents',
                customEventPropertyList: [],
                customEventKey: '',
                customEventProperty: '',
                customEventOperator: '',
                operatorType: 'string',
                customEventValue: '',
                customEventDate: getTimezoneDate(),
                activityError: '',
                ruleId: '',
                ruleName: '',
                scoringCriteria: 'best',
                // taskStartDate: getTimezoneDate(),
                // taskEndDate: getTimezoneDate(),
                // dateTimeError: '',
                // taskTimerIconColor: '#ffffff',
                repeatTask: 1,
                taskRewardId: '',
                taskBadgeId: '',
                backgroundColorBefore: '#FFFFFF',
                borderColorBefore: '#FFFFFF',
                thicknessBefore: '1',
                radiusBefore: '30px',
                backgroundColorAfter: '#FFFFFF',
                borderColorAfter: '#FFFFFF',
                thicknessAfter: '1',
                radiusAfter: '30px',
                textBefore: {
                  text: '',
                  color: '#000000',
                  font: {
                    size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontSize,
                    strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontWt,
                    family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFont,
                    align: 'left',
                  },
                  textError: '',
                },
                widthBefore: 'auto',
                radiusCtaBefore: '30px',
                borderBefore: '#FFFFFF',
                borderWidthBefore: '1px',
                colorBefore: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#FFFFFF' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
                shadowBefore: '0px 0px',
                shadowColorBefore: '#FFFFFF',
                hoverBgColorBefore: '#FFFFFF',
                buttonTextErrorBefore: '',
                webLinkBefore: '',

                textAfter: {
                  text: '',
                  color: '#000000',
                  font: {
                    size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontSize,
                    strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontWt,
                    family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFont,
                    align: 'left',
                  },
                  textError: '',
                },
                widthAfter: 'auto',
                radiusCtaAfter: '30px',
                borderAfter: '#FFFFFF',
                borderWidthAfter: '1px',
                colorAfter: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#FFFFFF' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
                shadowAfter: '0px 0px',
                shadowColorAfter: '#FFFFFF',
                hoverBgColorAfter: '#FFFFFF',
                buttonTextErrorAfter: '',

                ctaButtonNeeded: true,
                taskWinningText: {
                  text: '',
                  color: '#000000',
                  font: {
                    size: '14',
                    strength: '400',
                    family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
                    align: 'left',
                  },
                },
                taskWinningImg: '',
                taskWinningImgName: '',
                taskWinningImageError: '',
              }
            ],
          }
        ],
        rewardId: '',
        badgeId: '',
        lbCheckBox: true,
        leaderBoardType: '',
        leaderBoardId: '',
        resultHandler: 'ParentApp',
        repeatQuest: 'noRepeat',
        // weekly: false,
        // monthly: false,
        // campaign: false,
        status: 'active',
        taskFormat: 'unordered',
        timezone: window.localStorage.getItem('defaultTimezone'),
        localTimezoneStr: window.localStorage.getItem('timeZoneString'),
      }
    ]
  },

};

const backupConfig = {
  config: {
    localizationconfig: [
      {
        language: 'English',
        title: {
          text: '',
          color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#000000' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
          font: {
            size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '32' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontSize,
            strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontWt,
            family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFont,
            align: 'center',
          },
          // position: 'center',
          titleError: '',
          disableSave: false,
          questName: {
            text: ''
          },
          templateError: '',
          titleImageUrl: '',
          titleImageName: '',
          titleImageError: '',
          selectError: '',
        },
        subtitle: {
          text: '',
          color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#000000' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
          font: {
            size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFontSize,
            strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFontWt,
            family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFont,
            align: 'center',
          },
          titleError: '',
        },
        allowEdit: true,
        questDescription: {
          text: '',
          color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#000000' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
          font: {
            size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
            strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
            family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
            align: 'center',
          },
          tournamentDescriptionError: '',
        },
        titleBackgroundRadio: true,
        titleBackground: {
          color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#FFFFFF' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.primaryColour,
          image: '',
          imageName: '',
          backgroundError: '',
        },
        timerIconColor: '#ffffff',
        timerBackgroundColor: '#ffffff',
        questTimer: {
          family: 'Outfit, sans-serif',
          strength: '400',
          size: '20',
          color: '#ffffff',
        },
        progressBarDesignType: '1',
        progressBarColorCompleted: '#ffffff',
        progressBarColorDefault: '#ffffff',
        fadeRemaningEvent: true,
        userSegmentId: 'All User',
        background: {
          color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#FFFFFF' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.primaryColour,
          image: 'https://www.googleapis.com/download/storage/v1/b/ec2-us-east-1-1b-dev-images-gamification/o/quiz%2F76%2F72%2F6464_quiz.png?alt=media',
          imageName: 'backgroundImage.png',
          backgroundError: '',
        },
        bannerImage: {
          color: '#ffffff',
          bannerImage: '',
          bannerImageName: '',
          bannerImageError: '',
          showProgressBar: false,
          wheelImage: '',
        },
        resultText: {
          winningText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: '14',
              strength: '400',
              family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
              align: 'left',
            },
            error: '',
            errorImg: ''
          },
          losingText: {
            img: '',
            imageName: '',
            text: '',
            color: '#000000',
            font: {
              size: '14',
              strength: '400',
              family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
              align: 'left',
            },
            error: '',
            errorImg: ''
          }
        },
        milestoneNeeded: true,
        milestonesConfig: [
          {
            title: {
              text: '',
              color: '#000000',
              font: {
                size: '32',
                strength: '600',
                family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFont,
                align: 'center',
              },
              titleError: '',
              disableSave: false,
              titleImageUrl: '',
              titleImageName: '',
              titleImageError: '',
              selectError: '',
            },
            subtitle: {
              text: '',
              color: '#000000',
              font: {
                size: '18',
                strength: '400',
                family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFont,
                align: 'center',
              },
              titleError: '',
            },
            allowEdit: true,
            milestoneDescription: {
              text: '',
              color: '#000000',
              font: {
                size: '18',
                strength: '400',
                family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
                align: 'center',
              },
              milestoneDescriptionError: '',
            },
            progressMsName: null,
            progressMsImg: null,
            progressMsImgName: null,
            progressMsImgError: '',
            // milestoneTimer: true,
            // milestoneStartDate: getTimezoneDate(),
            // milestoneEndDate: getTimezoneDate(),
            // msDateTimeError: '',
            // milestoneTimerIconColor: '#ffffff',
            msRewardId: '',
            msBadgeId: '',
            backgroundColorBefore: '#FFFFFF',
            borderColorBefore: '#FFFFFF',
            thicknessBefore: '1',
            radiusBefore: '30px',
            backgroundColorAfter: '#FFFFFF',
            borderColorAfter: '#FFFFFF',
            thicknessAfter: '1',
            radiusAfter: '30px',
            msWinningMsg: {
              text: '',
              color: '#000000',
              font: {
                size: '18',
                strength: '400',
                family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
                align: 'center',
              },
            },
            msWinningImg: '',
            msWinningImgName: '',
            msWinningImageError: '',
            questionIndex: 0,
            tasksConfig: [
              {
                title: {
                  text: '',
                  color: '#000000',
                  font: {
                    size: '32',
                    strength: '600',
                    family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFont,
                    align: 'center',
                  },
                  titleError: '',
                  disableSave: false,
                  titleImageUrl: '',
                  titleImageName: '',
                  titleImageError: '',
                  selectError: '',
                },
                subtitle: {
                  text: '',
                  color: '#000000',
                  font: {
                    size: '18',
                    strength: '400',
                    family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFont,
                    align: 'center',
                  },
                  titleError: '',
                },
                allowEdit: true,
                taskDescription: {
                  text: '',
                  color: '#000000',
                  font: {
                    size: '18',
                    strength: '400',
                    family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
                    align: 'center',
                  },
                  taskDescriptionError: '',
                },
                progressTaskName: null,
                progressTaskImg: null,
                progressTaskImgName: null,
                progressTaskImgError: '',
                activity: 'customEvents',
                customEventPropertyList: [],
                customEventKey: '',
                customEventProperty: '',
                customEventOperator: '',
                operatorType: 'string',
                customEventValue: '',
                customEventDate: getTimezoneDate(),
                activityError: '',
                ruleId: '',
                ruleName: '',
                scoringCriteria: 'best',
                // taskStartDate: getTimezoneDate(),
                // taskEndDate: getTimezoneDate(),
                // dateTimeError: '',
                // taskTimerIconColor: '#ffffff',
                repeatTask: 1,
                taskRewardId: '',
                taskBadgeId: '',
                backgroundColorBefore: '#FFFFFF',
                borderColorBefore: '#FFFFFF',
                thicknessBefore: '1',
                radiusBefore: '30px',
                backgroundColorAfter: '#FFFFFF',
                borderColorAfter: '#FFFFFF',
                thicknessAfter: '1',
                radiusAfter: '30px',
                textBefore: {
                  text: '',
                  color: '#000000',
                  font: {
                    size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontSize,
                    strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontWt,
                    family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFont,
                    align: 'left',
                  },
                },
                widthBefore: 'auto',
                radiusCtaBefore: '30px',
                borderBefore: '#FFFFFF',
                borderWidthBefore: '1px',
                colorBefore: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#FFFFFF' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
                shadowBefore: '0px 0px',
                shadowColorBefore: '#FFFFFF',
                hoverBgColorBefore: '#FFFFFF',
                buttonTextErrorBefore: '',
                webLinkBefore: '',

                textAfter: {
                  text: '',
                  color: '#000000',
                  font: {
                    size: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontSize,
                    strength: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontWt,
                    family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFont,
                    align: 'left',
                  },
                },
                widthAfter: 'auto',
                radiusCtaAfter: '30px',
                borderAfter: '#FFFFFF',
                borderWidthAfter: '1px',
                colorAfter: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#FFFFFF' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
                shadowAfter: '0px 0px',
                shadowColorAfter: '#FFFFFF',
                hoverBgColorAfter: '#FFFFFF',
                buttonTextErrorAfter: '',

                ctaButtonNeeded: true,
                taskWinningText: {
                  text: '',
                  color: '#000000',
                  font: {
                    size: '14',
                    strength: '400',
                    family: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
                    align: 'left',
                  },
                },
                taskWinningImg: '',
                taskWinningImgName: '',
                taskWinningImageError: '',
              }
            ],
          }
        ],
        rewardId: '',
        badgeId: '',
        lbCheckBox: true,
        leaderBoardType: '',
        leaderBoardId: '',
        resultHandler: 'ParentApp',
        repeatQuest: 'noRepeat',
        // weekly: false,
        // monthly: false,
        // campaign: false,
        status: 'active',
        taskFormat: 'unordered',
        timezone: window.localStorage.getItem('defaultTimezone'),
        localTimezoneStr: window.localStorage.getItem('timeZoneString'),
      }
    ]
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPLOAD_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case SAVE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DUPLICATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DUPLICATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DUPLICATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_QUEST_SUCCESS: {
      // const { tournaments } = action.result.tournament;
      // const time = {
      //   tournamentStartDate: new Date(tournaments[0].startDateTime),
      //   tournamentEndDate: new Date(tournaments[0].endDateTime)
      // };
      return {
        ...state,
        // time,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_CONFIG_SUCCESS: {
      return {
        ...state,
        // loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_TITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].title = state.config.localizationconfig[i].title
            ? {
              ...state.config.localizationconfig[i].title,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_SUBTITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].subtitle = state.config.localizationconfig[i].subtitle
            ? {
              ...state.config.localizationconfig[i].subtitle,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BACKGROUND_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].background = state.config.localizationconfig[i].background
            ? {
              ...state.config.localizationconfig[i].background,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_TITLE_BACKGROUND_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].titleBackground = state.config.localizationconfig[i].titleBackground
            ? {
              ...state.config.localizationconfig[i].titleBackground,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_QUEST_TIMER_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].questTimer = state.config.localizationconfig[i].questTimer
            ? {
              ...state.config.localizationconfig[i].questTimer,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BANNER_IMAGE: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].bannerImage = state.config.localizationconfig[i].bannerImage
            ? {
              ...state.config.localizationconfig[i].bannerImage,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_RESULT_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].resultText = state.config.localizationconfig[i].resultText
            ? {
              ...state.config.localizationconfig[i].resultText,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }

      return { ...state };
    }
    case UPDATE_APPCONFIG_FIELD: {
      state.initialValues = state.initialValues
        ? {
          ...state.initialValues,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_LANGUAGE: {
      return { ...state, languages: action.payload };
    }
    case UPDATE_CONFIG_FIELD: {
      const localizationconfig = [...state.config.localizationconfig, ...action.payload.localizationconfig];
      const config = {
        ...state.config,
        localizationconfig
      };
      return { ...state, config };
    }
    case UPDATE_LOCALIZATION_FLAG: {
      return { ...state, localizationFlag: action.payload };
    }
    case UPDATE_ACTIVE_FLAG: {
      return { ...state, activeNavTab: action.payload };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case REMOVE_CONFIG: {
      return { ...state, config: action.payload };
    }
    case UPDATE_BUTTON_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BUTTON_CONFIG2: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_MSVALID: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        for (let j = 0; j < state.config.localizationconfig[i].milestonesConfig.length; j += 1) {
          if (state.config.localizationconfig[i].language === action.payload.language) {
            state.config.localizationconfig[i].milestonesConfig[j] = state.config.localizationconfig[i].milestonesConfig[j]
              ? {
                ...state.config.localizationconfig[i].milestonesConfig[j],
                ...action.payload,
              }
              : { ...action.payload };
          }
        }
      }
      return { ...state };
    }
    case UPDATE_MSTITIE_VALID: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        for (let j = 0; j < state.config.localizationconfig[i].milestonesConfig.length; j += 1) {
          if (state.config.localizationconfig[i].language === action.payload.language) {
            state.config.localizationconfig[i].milestonesConfig[j].title = state.config.localizationconfig[i].milestonesConfig[j].title
              ? {
                ...state.config.localizationconfig[i].milestonesConfig[j].title,
                ...action.payload,
              }
              : { ...action.payload };
          }
        }
      }
      return { ...state };
    }
    case UPDATE_TASKVALID: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        for (let j = 0; j < state.config.localizationconfig[i].milestonesConfig.length; j += 1) {
          for (let k = 0; k < state.config.localizationconfig[i].milestonesConfig[j].tasksConfig.length; k += 1) {
            if (state.config.localizationconfig[i].language === action.payload.language) {
              state.config.localizationconfig[i].milestonesConfig[j].tasksConfig[k] = state.config.localizationconfig[i].milestonesConfig[j].tasksConfig[k]
                ? {
                  ...state.config.localizationconfig[i].milestonesConfig[j].tasksConfig[k],
                  ...action.payload,
                }
                : { ...action.payload };
            }
          }
        }
      }
      return { ...state };
    }
    case UPDATE_TASKTITLE_VALID: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        for (let j = 0; j < state.config.localizationconfig[i].milestonesConfig.length; j += 1) {
          for (let k = 0; k < state.config.localizationconfig[i].milestonesConfig[j].tasksConfig.length; k += 1) {
            if (state.config.localizationconfig[i].language === action.payload.language) {
              state.config.localizationconfig[i].milestonesConfig[j].tasksConfig[k].title = state.config.localizationconfig[i].milestonesConfig[j].tasksConfig[k].title
                ? {
                  ...state.config.localizationconfig[i].milestonesConfig[j].tasksConfig[k].title,
                  ...action.payload,
                }
                : { ...action.payload };
            }
          }
        }
      }
      return { ...state };
    }
    case UPDATE_BUTTON: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_TEMPLATE_PAGE: {
      return { ...state, selectedType: action.payload };
    }
    case UPDATE_GAMES_LIST: {
      return { ...state, templatesList: action.payload };
    }
    case UPDATE_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_PAGE: {
      const initialValues = { ...state.initialValues, ...action.payload };
      return { ...state, initialValues };
    }
    case UPDATE_QUEST_DESC_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].questDescription = state.config.localizationconfig[i].questDescription
            ? {
              ...state.config.localizationconfig[i].questDescription,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_TOURNAMENT_RULES_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].tournamentRules = state.config.localizationconfig[i].tournamentRules
            ? {
              ...state.config.localizationconfig[i].tournamentRules,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_PRIZE_TEXT_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].prizeText = state.config.localizationconfig[i].prizeText
            ? {
              ...state.config.localizationconfig[i].prizeText,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_TOURNAMENT_RULES_PARAMS: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].tournamentRulesParams = state.config.localizationconfig[i].tournamentRulesParams
            ? {
              ...state.config.localizationconfig[i].tournamentRulesParams,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_PRIZE_TEXT_PARAMS: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].prizeTextParams = state.config.localizationconfig[i].prizeTextParams
            ? {
              ...state.config.localizationconfig[i].prizeTextParams,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case DELETE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DELETE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_TEMPLATE_FIELD: {
      state.predefinedTemplate = state.predefinedTemplate
        ? {
          ...state.predefinedTemplate,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_LOCAL_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = action.payload
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_SELECTED_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].resultText = state.config.localizationconfig[i].resultText
            ? {
              ...state.config.localizationconfig[i].resultText,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_TIME_FIELD: {
      const time = {
        ...state.time,
        ...action.payload
      };
      return { ...state, time };
    }
    case GET_SEGEMENT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SEGEMENT_SUCCESS: {
      const segement = action.result.segmentList;
      const segementOptions = [];
      for (let i = 0; i < segement.length; i += 1) {
        segementOptions.push({ title: segement[i].title, value: segement[i].value });
      }
      segementOptions.push({ title: 'All User', value: 'All User' });
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        options: { ...state.options, ...{ userSegment: segementOptions } },
      };
    }
    case GET_SEGEMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_BADGES_LIST: {
      return { ...state, badgesList: action.payload };
    }
    case UPDATE_REWARDS_LIST: {
      return { ...state, rewardsList: action.payload };
    }
    case UPDATE_PROGRESS_BAR_LIST: {
      return { ...state, progressBarList: action.payload };
    }
    case UPDATE_USERSEGMENT_LIST: {
      return { ...state, userSegmentList: action.payload };
    }
    case UPDATE_CUSTOMEVENTS_LIST: {
      return { ...state, customEventList: action.payload };
    }
    // case UPDATE_CUSTOMEVENTS_PROPERTY_LIST: {
    //   return { ...state, customEventPropertyList: action.payload };
    // }
    case UPDATE_ACTIVITY_LIST: {
      return { ...state, activityList: action.payload };
    }
    case UPDATE_LB_LIST: {
      return { ...state, leaderBoardList: action.payload };
    }
    case CLEAR_FORM: {
      return {
        ...initialState
      };
    }
    case UPDATE_QUEST_LOADER: {
      return {
        ...state,
        ...action.payload
      };
    }
    case CLEAR_CONFIG: {
      // state.config.localizationconfig = JSON.parse(JSON.stringify(backupConfig.config.localizationconfig));
      return {
        ...state,
        config: {
          ...state.config,
          localizationconfig: JSON.parse(JSON.stringify(backupConfig.config.localizationconfig))
        }
      };
    }
    default:
      return state;
  }
};

export const updateTitle = (payload) => {
  return {
    type: UPDATE_TITLE_FIELD,
    payload,
  };
};

export const updateSubTitle = (payload) => {
  return {
    type: UPDATE_SUBTITLE_FIELD,
    payload,
  };
};

export const updateActiveFlag = (payload) => {
  return {
    type: UPDATE_ACTIVE_FLAG,
    payload,
  };
};

export const removeConfig = (payload) => {
  return {
    type: REMOVE_CONFIG,
    payload
  };
};

export const updateBackground = (payload) => {
  return {
    type: UPDATE_BACKGROUND_FIELD,
    payload,
  };
};
export const updateTitleBackground = (payload) => {
  return {
    type: UPDATE_TITLE_BACKGROUND_FIELD,
    payload,
  };
};
export const updateQuestTimer = (payload) => {
  return {
    type: UPDATE_QUEST_TIMER_FIELD,
    payload,
  };
};

export const updateBannerImage = (payload) => {
  return {
    type: UPDATE_BANNER_IMAGE,
    payload,
  };
};

export const uploadimage = (file, folderName) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/gamification/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
      .field('folderName', folderName),
  };
};

export const saveQuestConfig = (data) => {
  return {
    types: [SAVE_CONFIG_REQUESTED, SAVE_CONFIG_SUCCESS, SAVE_CONFIG_FAILURE],
    promise: client => client.post('saveQuestConfig', {
      data,
    }),
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const editQuestConfig = (data) => {
  return {
    types: [UPDATE_CONFIG_REQUESTED, UPDATE_CONFIG_SUCCESS, UPDATE_CONFIG_FAILURE],
    promise: client => client.post('saveQuestConfig', {
      data,
    }),
  };
};

export const checkRuleName = (data, type, id) => {
  return {
    types: [DUPLICATE_CONFIG_REQUESTED, DUPLICATE_CONFIG_SUCCESS, DUPLICATE_CONFIG_FAILURE],
    promise: client => client.get(`checkRuleName?name=${data}&id=${id}`),
  };
};

export const updateResult = (payload) => {
  return {
    type: UPDATE_RESULT_FIELD,
    payload,
  };
};

export const getAppConfig = () => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get('getAppConfig'),
  };
};

export const updateDropdown = (payload) => {
  return {
    type: UPDATE_APPCONFIG_FIELD,
    payload,
  };
};
export const updateLocalizationFlag = (payload) => {
  return {
    type: UPDATE_LOCALIZATION_FLAG,
    payload,
  };
};
export const updateConfig = (payload) => {
  return {
    type: UPDATE_CONFIG_FIELD,
    payload
  };
};
export const updateLanguage = (payload) => {
  return {
    type: UPDATE_LANGUAGE,
    payload
  };
};
export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const clearLocalizationConfig = () => {
  return {
    type: CLEAR_CONFIG,
  };
};

export const updateButton = (payload) => {
  return {
    type: UPDATE_BUTTON,
    payload
  };
};

export const updateButtonConfig = (payload) => {
  return {
    type: UPDATE_BUTTON_CONFIG,
    payload
  };
};
export const updateButtonConfig2 = (payload) => {
  return {
    type: UPDATE_BUTTON_CONFIG2,
    payload
  };
};

export const updateGamesList = (payload) => {
  return {
    type: UPDATE_GAMES_LIST,
    payload,
  };
};

export const updateQuesttDescription = (payload) => {
  return {
    type: UPDATE_QUEST_DESC_FIELD,
    payload,
  };
};

export const updateTournamentRules = (payload) => {
  return {
    type: UPDATE_TOURNAMENT_RULES_FIELD,
    payload,
  };
};

export const updatePrizeText = (payload) => {
  return {
    type: UPDATE_PRIZE_TEXT_FIELD,
    payload,
  };
};

export const updateSelectedType = (payload) => {
  return {
    type: UPDATE_TEMPLATE_PAGE,
    payload,
  };
};

export const updateTournamentRulesParams = (payload) => {
  return {
    type: UPDATE_TOURNAMENT_RULES_PARAMS,
    payload,
  };
};

export const updatePrizeTextParams = (payload) => {
  return {
    type: UPDATE_PRIZE_TEXT_PARAMS,
    payload,
  };
};

export const updateLocalConfig = (payload) => {
  return {
    type: UPDATE_LOCAL_CONFIG,
    payload,
  };
};

export const getGamesTemplate = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(
      `getGamesList/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&sortType=${data.orderBy
      }`
    ),
  };
};

export const updateTime = (payload) => {
  return {
    type: UPDATE_TIME_FIELD,
    payload,
  };
};

export const getRewardsTemplate = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(
      `getMyOffersList/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&sortType=${data.orderBy
      }`
    ),
  };
};

export const updateRewardsList = (payload) => {
  return {
    type: UPDATE_REWARDS_LIST,
    payload,
  };
};

export const getBadgesTemplate = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(
      `getGameMechanicsConfig/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&sortType=${data.orderBy
      }`
    ),
  };
};

export const updateBadgesList = (payload) => {
  return {
    type: UPDATE_BADGES_LIST,
    payload,
  };
};

export const getProgressBarTemplate = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(
      `getProgressBarTemplateConfig/${data.itemsPerPage}/${data.pageNo}?title=${data.searchKey}&sortBy=${data.sortBy}&orderBy=${data.orderBy
      }`
    ),
  };
};

export const updateProgressBarList = (payload) => {
  return {
    type: UPDATE_PROGRESS_BAR_LIST,
    payload,
  };
};

export const getUserSegment = () => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_SEGEMENT_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(
      'getSegmentList'
    ),
  };
};

export const updateUserSegmentList = (payload) => {
  return {
    type: UPDATE_USERSEGMENT_LIST,
    payload,
  };
};

export const getCustomEvents = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(
      `getCustomEventsConfig/${data.itemsPerPage}/${data.pageNo}?title=${data.searchKey}&sortBy=${data.sortBy}&orderBy=${data.orderBy
      }`
    ),
  };
};

export const updateCustomEventsList = (payload) => {
  return {
    type: UPDATE_CUSTOMEVENTS_LIST,
    payload,
  };
};

export const getActivity = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(
      `getSpinWheelConfig/${data.itemsPerPage}/${data.pageNo}?title=${data.searchKey}&sortBy=${data.sortBy}&orderBy=${data.orderBy
      }`
    ),
  };
};

export const updateActivityList = (payload) => {
  return {
    type: UPDATE_ACTIVITY_LIST,
    payload,
  };
};

export const getLeaderBoard = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(
      `getMyLeaderBoardList/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&sortType=${data.orderBy
      }`
    ),
  };
};

export const updateLeaderBoardList = (payload) => {
  return {
    type: UPDATE_LB_LIST,
    payload,
  };
};

export const getQuest = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_QUEST_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getRule?ruleId=${data}`),
  };
};

export const clearForm = () => {
  return {
    type: CLEAR_FORM,
  };
};

export const updateLoader = (payload) => {
  return {
    type: UPDATE_QUEST_LOADER,
    payload,
  };
};
export const updateTaskvalid = (payload) => {
  return {
    type: UPDATE_TASKVALID,
    payload,
  };
};
export const updateMsValid = (payload) => {
  return {
    type: UPDATE_MSVALID,
    payload,
  };
};
export const updateMsTitleValid = (payload) => {
  return {
    type: UPDATE_MSTITIE_VALID,
    payload,
  };
};
export const updateTaskTitleValid = (payload) => {
  return {
    type: UPDATE_TASKTITLE_VALID,
    payload,
  };
};
export const getRuleEngineList = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getRuleEngineList/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.title}&sortBy=${data.sortBy}&sortType=${data.orderBy}`)
  };
};
