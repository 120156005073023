import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import ErrorBoundary from '@containers/ErrorBoundary';
import store, { history } from './store';
import routes from './routes';
import './include/bootstrap';
import './scss/bootstrap_ext/progressBar.css';

const target = document.querySelector('#root');

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ErrorBoundary>
        <div className="height100">
          {routes}
        </div>
      </ErrorBoundary>
    </ConnectedRouter>
  </Provider>,
  target
);
