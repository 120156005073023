import { APP_CONFIG } from '@constants';

const upload = require('superagent');

export const UPDATE_TITLE_FIELD = 'polling/UPDATE_TITLE_FIELD';
export const UPDATE_SUBTITLE_FIELD = 'polling/UPDATE_SUBTITLE_FIELD';
export const UPDATE_QUESTIONS_FIELD = 'polling/UPDATE_QUESTIONS_FIELD';
export const UPDATE_BACKGROUND_FIELD = 'polling/UPDATE_BACKGROUND_FIELD';
export const UPDATE_RESULT_FIELD = 'polling/UPDATE_RESULT_FIELD';
export const UPDATE_CTA = 'polling/UPDATE_CTA';
export const UPDATE_APPCONFIG_FIELD = 'polling/UPDATE_APPCONFIG_FIELD';
export const UPDATE_BUTTON_CONFIG = 'polling/UPDATE_BUTTON_CONFIG';
export const UPDATE_BUTTON = 'polling/UPDATE_BUTTON';
export const UPDATE_BUTTON1 = 'polling/UPDATE_BUTTON1';
// export const UPDATE_CARDCONFIG = 'polling/UPDATE_CARDCONFIG';
export const UPDATE_CARDCONTENT = 'polling/UPDATE_CARDCONTENT';


export const UPLOAD_IMAGE_REQUESTED = 'upload/UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_IMAGE_SUCCESS = 'upload/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'upload/UPLOAD_IMAGE_FAILURE';

export const SAVE_CONFIG_REQUESTED = 'save/SAVE_CONFIG_REQUESTED';
export const SAVE_CONFIG_SUCCESS = 'save/SAVE_CONFIG_SUCCESS';
export const SAVE_CONFIG_FAILURE = 'save/SAVE_CONFIG_FAILURE';

export const GET_CONFIG_REQUESTED = 'get/GET_CONFIG_REQUESTED';
export const GET_CONFIG_SUCCESS = 'get/GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'get/GET_CONFIG_FAILURE';

export const UPDATE_CONFIG_REQUESTED = 'update/UPDATE_CONFIG_REQUESTED';
export const UPDATE_CONFIG_SUCCESS = 'update/UPDATE_CONFIG_SUCCESS';
export const UPDATE_CONFIG_FAILURE = 'update/UPDATE_CONFIG_FAILURE';

export const DUPLICATE_CONFIG_REQUESTED = 'get/DUPLICATE_CONFIG_REQUESTED';
export const DUPLICATE_CONFIG_SUCCESS = 'get/DUPLICATE_CONFIG_SUCCESS';
export const DUPLICATE_CONFIG_FAILURE = 'get/DUPLICATE_CONFIG_FAILURE';

export const UPDATE_FIELD = 'polling/UPDATE_FIELD';
export const UPDATE_PAGE = 'polling/UPDATE_PAGE';
export const UPDATE_LOCALIZATION_FLAG = 'polling/UPDATE_LOCALIZATION_FLAG';
export const UPDATE_ACTIVE_FLAG = 'polling/UPDATE_ACTIVE_FLAG';
export const UPDATE_CONFIG_FIELD = 'polling/UPDATE_CONFIG_FIELD';
export const REMOVE_CONFIG = 'polling/REMOVE_CONFIG';
export const CLEAR_STATE = 'polling/CLEAR_STATE';
export const UPDATE_WIN_FIELD = 'polling/UPDATE_WIN_FIELD';
export const UPDATE_LOOSE_FIELD = 'polling/UPDATE_LOOSE_FIELD';

export const UPDATE_COLLAPSE = 'polling/UPDATE_COLLAPSE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  initialValues: {
    fontTitlePositions: [],
    questionType: [],
    quizQuestionImagePosition: [],
    opinionPollType: [],
    fonts: [],
    fontStrength: [],
    fontSize: [],
    spinButtonPosition: [],
    spinButtonWidth: [],
    spinButtonRadius: [],
    spinButtonShadow: [],
    fontPositions: [],
    outlineStyles: [],
    thicknessOptions: [
      {
        title: '1',
        value: '1'
      },
      {
        title: '2',
        value: '2'
      },
      {
        title: '3',
        value: '3'
      },
      {
        title: '4',
        value: '4'
      },
      {
        title: '5',
        value: '5'
      },
      {
        title: '10',
        value: '10'
      },
    ],
    radiusOptions: [
      {
        title: '0%',
        value: '0%'
      },
      {
        title: '10%',
        value: '10%'
      },
      {
        title: '50%',
        value: '50%'
      },
      {
        title: '100%',
        value: '100%'
      },
    ],
  },
  collapse: {
    titleCont: false,
    backgroundCont: false,
    gameCont: false,
    cardDesignCont: false,
    cardContentCont: false,
    resultCont: false,
  },
  localizationFlag: '',
  activeNavTab: 'English',
  config: {
    localizationconfig: [
      {
        language: 'English',
        title: {
          text: '',
          fontColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#1E1A33' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
          fontSize: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '20' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontSize,
          fontWeight: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontWt,
          fontStyle: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFont,
          fontPosition: 'center',
          titleError: '',
          disableSave: false,
          templateName: {
            text: ''
          },
          templateError: ''
        },
        subtitle: {
          text: '',
          fontColor1: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#1E1A33' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
          fontSize1: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '20' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFontSize,
          fontWeight1: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFontWt,
          fontStyle1: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFont,
          fontPosition1: 'center',
          titleError: '',
        },
        samePointsForAllQuestions: false,
        backgroundRadio: true,
        samePoints: 0,
        samePointsError: '',
        allowEdit: true,
        playButton: {
          imageColor: '#F67A3E',
        },
        pauseButton: {
          imageColor: '#F67A3E',
        },
        playSeekLine: {
          defaultColor: '#9E9E9E',
          dragColor: '#F67A3E'
        },
        quesOptCardDesign: {
          questionBackgroundColor: '#096BFF',
          questionBorderColor: '#096BFF',
          questionThickness: '10',
          questionRadius: '10px',
          questionFontStyle: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
          questionFontSize: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '24' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
          questionFontColor: '#FFFFFF',
          questionFontWt: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,

          optionFontStyle: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
          optionFontSize: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '24' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
          optionFontColor: '#242424',
          optionFontWt: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontWt,
          optionBackgroundColor: '#A0A0A0',
          optionBorderColor: '#A0A0A0',
          optionThickness: '10',
          optionRadius: '10px',

          checkBoxPresent: true,
          checkBoxDefaultColor: '#FFFFFF',
          checkBoxSelColor: '#FFFFFF',
          checkBoxTickColor: '#282828',
        },
        pollQuestions: [
          {
            name: {
              text: 'Which of the following leisure activities do you enjoy the most?',
            },
            type: 'none',
            imgPosition: 'bottom',
            imageError: '',
            questionError: '',
            image: {
              fileName: '',
              url: '',
            },
            points: '',
            pointsError: '',
            optionsList: [
              {
                text: 'Reading',
                optionsError: '',
              },
              {
                text: 'Watching Movies/TV Shows',
                optionsError: '',
              },
              {
                text: 'Playing Sports',
                optionsError: '',
              },
              {
                text: 'Cooking',
                optionsError: '',
              },
            ],
          },
        ],
        percentBarConfig: {
          selectedOptTextColor: '#000000',
          percentBarSelectedOptColor: '#F3BAE3',
          percentBarOtherOptColor: '#A0A0A0',
          percentTextFontStyle: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
          percentTextFontColor: '#BAD1F3',
          percentTextFontSize: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '16' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
          timerStyles: {
            text: '',
            timerError: '',
          },
          timeInMS: '',
        },
        background: {
          color: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#ffffff' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.primaryColour,
          image: '',
          imageName: '',
          backgroundError: '',
          showProgressBar: false,
          wheelImage: '',
        },
        buttonConfig: [
          {
            text: {
              text: '',
            },
            fontColor: '#0033CC',
            fontSize: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '20' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontSize,
            fontWeight: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFontWt,
            fontStyle: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.buttonFont,
            textColor: '#0033CC',
            image: {
              url: '',
              name: ''
            },
            imageError: '',
            width: '50%',
            radius: '10px',
            shadow: '0 14px 28px',
            shadowColor: '#0033CC',
            // position: 'left',
            bgColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#0033CC' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
            border: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#0033CC' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.buttonColour,
            hoverBgColor: '#0033CC',
            buttonTextError: '',
            borderWidth: '3px',
          }
        ],
        resultText: {
          winningText: {
            img: '',
            imageName: '',
            text: '',
            fontColor: '#0033CC',
            fontSize: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
            fontStyle: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
            fontPosition: 'center',
            error: '',
            errorImg: ''
          },
          losingText: {
            img: '',
            imageName: '',
            text: '',
            fontColor1: '#0033CC',
            fontSize1: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFontSize,
            fontStyle1: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.generalFont,
            fontPosition1: 'center',
            error: '',
            errorImg: ''
          }
        },
        questionIndex: 0,
        resultHandler: 'ParentApp',
        buttonNeeded: true,
      }
    ]
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPLOAD_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case SAVE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DUPLICATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case DUPLICATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DUPLICATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_TITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].title = state.config.localizationconfig[i].title
            ? {
              ...state.config.localizationconfig[i].title,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_WIN_FIELD: {
      console.log('WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW');
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].resultText.winningText = state.config.localizationconfig[i].resultText.winningText
            ? {
              ...state.config.localizationconfig[i].resultText.winningText,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_LOOSE_FIELD: {
      console.log('LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL');
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].resultText.losingText = state.config.localizationconfig[i].resultText.losingText
            ? {
              ...state.config.localizationconfig[i].resultText.losingText,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_SUBTITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].subtitle = state.config.localizationconfig[i].subtitle
            ? {
              ...state.config.localizationconfig[i].subtitle,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BUTTON_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_QUESTIONS_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BACKGROUND_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].background = state.config.localizationconfig[i].background
            ? {
              ...state.config.localizationconfig[i].background,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_RESULT_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].resultText = state.config.localizationconfig[i].resultText
            ? {
              ...state.config.localizationconfig[i].resultText,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_CTA: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].buttonConfig[0] = state.config.localizationconfig[i].buttonConfig[0]
            ? {
              ...state.config.localizationconfig[i].buttonConfig[0],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_APPCONFIG_FIELD: {
      state.initialValues = state.initialValues
        ? {
          ...state.initialValues,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_LOCALIZATION_FLAG: {
      return { ...state, localizationFlag: action.payload };
    }
    case UPDATE_ACTIVE_FLAG: {
      return { ...state, activeNavTab: action.payload };
    }
    case UPDATE_CONFIG_FIELD: {
      const localizationconfig = [...state.config.localizationconfig, ...action.payload.localizationconfig];
      const config = {
        ...state.config,
        localizationconfig
      };
      return { ...state, config };
    }
    case REMOVE_CONFIG: {
      return { ...state, config: action.payload };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case UPDATE_BUTTON: {
      console.log('ssssss', action.payload);
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_BUTTON1: {
      console.log('ssssss', action.payload);
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].quesOptCardDesign = state.config.localizationconfig[i].quesOptCardDesign
            ? {
              ...state.config.localizationconfig[i].quesOptCardDesign,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    // case UPDATE_CARDCONFIG: {
    //   for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
    //     if (state.config.localizationconfig[i].language === action.payload.language) {
    //       state.config.localizationconfig[i].cardConfig = state.config.localizationconfig[i].cardConfig
    //         ? {
    //           ...state.config.localizationconfig[i].cardConfig,
    //           ...action.payload,
    //         }
    //         : { ...action.payload };
    //     }
    //   }
    //   return { ...state };
    // }
    case UPDATE_CARDCONTENT: {
      console.log('ssssss', action.payload);
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].percentBarConfig = state.config.localizationconfig[i].percentBarConfig
            ? {
              ...state.config.localizationconfig[i].percentBarConfig,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_COLLAPSE: {
      state.collapse = state.collapse
        ? {
          ...state.collapse,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    default:
      return state;
  }
};

export const updateTitle = (payload) => {
  return {
    type: UPDATE_TITLE_FIELD,
    payload,
  };
};
export const updateWin = (payload) => {
  console.log('updateWin payload', payload);
  return {
    type: UPDATE_WIN_FIELD,
    payload,
  };
};
export const updateLoose = (payload) => {
  return {
    type: UPDATE_LOOSE_FIELD,
    payload,
  };
};

export const updateSubTitle = (payload) => {
  return {
    type: UPDATE_SUBTITLE_FIELD,
    payload,
  };
};

export const updateQuestions = (payload) => {
  return {
    type: UPDATE_QUESTIONS_FIELD,
    payload,
  };
};

export const updateBackground = (payload) => {
  return {
    type: UPDATE_BACKGROUND_FIELD,
    payload,
  };
};

export const uploadimage = (file, folderName) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/gamification/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
      .field('folderName', folderName),
  };
};

export const savePollConfig = (data) => {
  return {
    types: [SAVE_CONFIG_REQUESTED, SAVE_CONFIG_SUCCESS, SAVE_CONFIG_FAILURE],
    promise: client => client.post('saveQuizConfig', {
      data,
    }),
  };
};

export const getSpinWheel = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getSpinWheel?gamificationId=${data}`),
  };
};

// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const editPollConfig = (data, gamificationId) => {
  return {
    types: [UPDATE_CONFIG_REQUESTED, UPDATE_CONFIG_SUCCESS, UPDATE_CONFIG_FAILURE],
    promise: client => client.post(`saveQuizConfig?quizId=${gamificationId}`, {
      data,
    }),
  };
};

export const duplicateSpinWheelTitle = (data, type, id) => {
  return {
    types: [DUPLICATE_CONFIG_REQUESTED, DUPLICATE_CONFIG_SUCCESS, DUPLICATE_CONFIG_FAILURE],
    promise: client => client.get(`duplicateSpinWheelTitle?title=${data}&type=${type}&id=${id}`),
  };
};

export const getTemplate = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getTemplate?templateId=${data}`),
  };
};

export const updateResult = (payload) => {
  return {
    type: UPDATE_RESULT_FIELD,
    payload,
  };
};
export const updateCta = (payload) => {
  return {
    type: UPDATE_CTA,
    payload,
  };
};

export const updateDropdown = (payload) => {
  return {
    type: UPDATE_APPCONFIG_FIELD,
    payload,
  };
};

export const updateButtonConfig = (payload) => {
  return {
    type: UPDATE_BUTTON_CONFIG,
    payload
  };
};

export const getAppConfig = () => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get('getAppConfig'),
  };
};

export const updateLocalizationFlag = (payload) => {
  return {
    type: UPDATE_LOCALIZATION_FLAG,
    payload,
  };
};


export const updateActiveFlag = (payload) => {
  return {
    type: UPDATE_ACTIVE_FLAG,
    payload,
  };
};

export const updateConfig = (payload) => {
  return {
    type: UPDATE_CONFIG_FIELD,
    payload
  };
};

export const removeConfig = (payload) => {
  return {
    type: REMOVE_CONFIG,
    payload
  };
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const updateButton = (payload) => {
  return {
    type: UPDATE_BUTTON,
    payload
  };
};
export const updateButton1 = (payload) => {
  return {
    type: UPDATE_BUTTON1,
    payload
  };
};
// export const updateCardConfig = (payload) => {
//   return {
//     type: UPDATE_CARDCONFIG,
//     payload
//   };
// };
export const updateCardContent = (payload) => {
  return {
    type: UPDATE_CARDCONTENT,
    payload
  };
};
export const updateCollapse = (payload) => {
  return {
    type: UPDATE_COLLAPSE,
    payload
  };
};
