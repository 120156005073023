import { CONSTANTS } from '@constants';

export const UPDATE_FIELD = 'myoffersthirdparty/UPDATE_FIELD';
export const UPDATE_PAGE = 'myoffersthirdparty/UPDATE_PAGE';
export const CLEAR_STATE = 'myoffersthirdparty/CLEAR_STATE';

export const GET_OFFER_REQUESTED = 'GET_OFFER_REQUESTED';
export const GET_OFFER_SUCCESS = 'GET_OFFER_SUCCESS';
export const GET_OFFER_FAILURE = 'GET_OFFER_FAILURE';

export const DUPLICATE_OFFER_REQUESTED = 'DUPLICATE_OFFER_REQUESTED';
export const DUPLICATE_OFFER_SUCCESS = 'DUPLICATE_OFFER_SUCCESS';
export const DUPLICATE_OFFER_FAILURE = 'DUPLICATE_OFFER_FAILURE';

export const DELETE_OFFER_REQUESTED = 'DELETE_OFFER_REQUESTED';
export const DELETE_OFFER_SUCCESS = 'DELETE_OFFER_SUCCESS';
export const DELETE_OFFER_FAILURE = 'DELETE_OFFER_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorDesc: [],
  code: '',
  msg: '',
  tableData: {},
  initialData: {
    itemsPerPage: 5,
    pageNo: 0,
    searchKey: '',
    sortBy: 'updatedTS',
    orderBy: 'desc',
    deleteRuleList: {
      templateName: '',
      list: []
    },
    headers: [
      // {
      //   title: 'Actions',
      //   slug: 'actions',
      //   isSortable: false,
      //   isVisible: false,
      //   tooltip: true,
      //   sortByOrder: '',
      //   width: -30,
      // },
      {
        title: 'Reward Name',
        slug: 'offer_name',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: false,
        sortByOrder: '',
        width: 130,
      },
      {
        title: 'Requested',
        slug: 'offer_count',
        isSortable: false,
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: 'desc',
        width: 120,
      },
      {
        title: 'Created',
        slug: 'offer_count',
        isSortable: false,
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: 'desc',
        width: 120,
      },
      {
        title: 'Available',
        slug: 'available_count',
        isSortable: false,
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: 'desc',
        width: 120,
      },
      {
        title: 'Distributed',
        slug: 'offer_count',
        isSortable: false,
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: 'desc',
        width: 120,
      },
      {
        title: 'Redeemed',
        slug: 'offer_count',
        isSortable: false,
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: 'desc',
        width: 120,
      },
      {
        title: CONSTANTS.CREATEDBY,
        slug: 'createdby',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: '',
        width: 90,
      },
      {
        title: 'Last Edited',
        slug: 'date',
        isSortable: true,
        isVisible: true,
        tooltip: false,
        center: false,
        sortByOrder: 'desc',
        width: 200,
      },
      {
        title: 'Actions',
        slug: 'actions',
        isSortable: false,
        isVisible: false,
        tooltip: true,
        sortByOrder: '',
        width: 180,
      },
    ]
  },
  localizationFlag: '',
  activeNavTab: 'English',
  config: {
    localizationconfig: [
      {
        language: 'English',
        title: {
          text: '',
          font: {
            size: '14',
            strength: '400',
            family: 'Arial, sans-serif',
            align: 'left',
          },
          position: 'flex-start',
          titleError: '',
          disableSave: false,
          templateName: {
            text: ''
          },
          templateError: ''
        },
        subtitle: {
          text: '',
          font: {
            size: '14',
            strength: '400',
            family: 'Arial, sans-serif',
            align: 'left',
          },
          titleError: '',
        },
        textColor: '#000000',
        image: '',
        imageName: '',
        width: 'auto',
        radius: '30px',
        shadow: '0px 0px',
        shadowColor: '#cdcdcd',
        position: 'left',
        bgColor: '#ffffff',
        border: '#000000',
        hoverBgColor: '#cdcdcd',
        buttonTextError: '',
        borderWidth: '1px',
      }
    ],
  }
};


export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFER_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_OFFER_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_OFFER_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DUPLICATE_OFFER_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DUPLICATE_OFFER_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case DUPLICATE_OFFER_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DELETE_OFFER_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DELETE_OFFER_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case DELETE_OFFER_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

// action
export const updateFields1 = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const getMyOffers = (data) => {
  let reqParams = '';
  if (data.searchKey !== '') {
    reqParams = `${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&sortType=${data.orderBy}`;
  } else {
    reqParams = `${data.itemsPerPage}/${data.pageNo}?searchKey=&sortBy=${data.sortBy}&sortType=${data.orderBy}`;
  }
  return {
    types: [GET_OFFER_REQUESTED, GET_OFFER_SUCCESS, GET_OFFER_FAILURE],
    promise: client => client.get(`getMyOffersThirdPartyList/${reqParams}`)
  };
};

export const dublicateMyOffer = (id) => {
  return {
    types: [DUPLICATE_OFFER_REQUESTED, DUPLICATE_OFFER_SUCCESS, DUPLICATE_OFFER_FAILURE],
    promise: client => client.get(`dublicateMyOffer?offerId=${id}`)
  };
};

export const deleteMyOffer = (id) => {
  return {
    types: [DUPLICATE_OFFER_REQUESTED, DUPLICATE_OFFER_SUCCESS, DUPLICATE_OFFER_FAILURE],
    promise: client => client.post(`deleteMyOffer?id=${id}`)
  };
};

export const checkDeleteMyOffer = (id) => {
  return {
    types: [DUPLICATE_OFFER_REQUESTED, DUPLICATE_OFFER_SUCCESS, DUPLICATE_OFFER_FAILURE],
    promise: client => client.post(`checkDeleteMyOffer?id=${id}`)
  };
};
