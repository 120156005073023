import { CONSTANTS } from '@constants';

export const GET_CONFIG_REQUESTED = 'get/GET_CONFIG_REQUESTED';
export const GET_CONFIG_SUCCESS = 'get/GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'get/GET_CONFIG_FAILURE';

export const DUPLICATE_CONFIG_REQUESTED = 'get/DUPLICATE_CONFIG_REQUESTED';
export const DUPLICATE_CONFIG_SUCCESS = 'get/DUPLICATE_CONFIG_SUCCESS';
export const DUPLICATE_CONFIG_FAILURE = 'get/DUPLICATE_CONFIG_FAILURE';

export const DELETE_CONFIG_REQUESTED = 'get/DELETE_CONFIG_REQUESTED';
export const DELETE_CONFIG_SUCCESS = 'get/DELETE_CONFIG_SUCCESS';
export const DELETE_CONFIG_FAILURE = 'get/DELETE_CONFIG_FAILURE';

export const UPDATE_FIELD = 'badgesList/UPDATE_FIELD';
export const UPDATE_PAGE = 'badgesList/UPDATE_PAGE';
export const CLEAR_STATE = 'badgesList/CLEAR_STATE';
export const UPDATE_ACTIVE_TAB = 'badgesList/UPDATE_ACTIVE_TAB';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  tableData: {},
  initialData: {
    pageNo: 0,
    title: '',
    sortBy: 'updatedTS',
    orderBy: 'desc',
    activeTab: 'my_badges',
    ruleId: '',
    deleteRuleList: {
      ruleName: '',
      list: []
    },
    headers: [
      {
        title: CONSTANTS.RULE_NAME,
        slug: 'rule_name',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: '',
        width: 150
      },
      {
        title: CONSTANTS.BADGES_NAME,
        slug: 'badge_name',
        isSortable: false,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: '',
        width: 150
      },
      {
        title: CONSTANTS.BADGECRITERIA,
        slug: 'badge_criteria',
        isSortable: true,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: '',
        width: 100,
      },
      {
        title: CONSTANTS.STATUS_FILTER,
        slug: 'status',
        isSortable: true,
        isVisible: true,
        tooltip: true,
        center: false,
        sortByOrder: 'desc',
        width: 90,
      },
      {
        title: CONSTANTS.CREATEDBY,
        slug: 'createdby',
        isSortable: true,
        isVisible: true,
        tooltip: false,
        center: true,
        sortByOrder: '',
        width: 90,
      },
      {
        title: CONSTANTS.START_DATE,
        slug: 'start_date',
        isSortable: true,
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: 'desc',
        width: 150,
      },
      {
        title: CONSTANTS.ENDDATE,
        slug: 'end_date',
        isSortable: true,
        isVisible: true,
        tooltip: true,
        center: true,
        sortByOrder: 'desc',
        width: 150,
      },
      {
        title: 'Actions',
        slug: 'actions',
        isSortable: false,
        isVisible: false,
        tooltip: true,
        sortByOrder: '',
        width: 180,
      },
    ]
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFIG_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_CONFIG_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_CONFIG_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DUPLICATE_CONFIG_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DUPLICATE_CONFIG_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case DUPLICATE_CONFIG_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_FIELD: {
      const tableData = { ...state.tableData, ...action.payload };
      return { ...state, tableData };
    }
    case UPDATE_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case UPDATE_ACTIVE_TAB: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case DELETE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DELETE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const getBadgesRuleList = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getBadgesRuleList/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.title}&sortBy=${data.sortBy}&sortType=${data.orderBy}`)
  };
};

export const duplicateBadgeRule = (data) => {
  return {
    types: [DUPLICATE_CONFIG_REQUESTED, DUPLICATE_CONFIG_SUCCESS, DUPLICATE_CONFIG_FAILURE],
    promise: client => client.get(`duplicateBadgeRule?ruleId=${data}`)
  };
};

export const deleteBadgeRule = (data) => {
  return {
    types: [DELETE_CONFIG_REQUESTED, DELETE_CONFIG_SUCCESS, DELETE_CONFIG_FAILURE],
    promise: client => client.post(`deleteBadgeRule?id=${data}`)
  };
};

// action
export const updateFields = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};

export const updateActiveTab = (payload) => {
  return {
    type: UPDATE_ACTIVE_TAB,
    payload,
  };
};

export const checkDeleteRule = (data) => {
  return {
    types: [DELETE_CONFIG_REQUESTED, DELETE_CONFIG_SUCCESS, DELETE_CONFIG_FAILURE],
    promise: client => client.get(`checkDeleteRule?id=${data}`)
  };
};
