import { APP_CONFIG } from '@constants';
import { getTimezoneDate } from '@helpers/Utility';

const upload = require('superagent');

export const UPLOAD_IMAGE_REQUESTED = 'upload/UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_IMAGE_SUCCESS = 'upload/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'upload/UPLOAD_IMAGE_FAILURE';

export const SAVE_CONFIG_REQUESTED = 'questsections/SAVE_CONFIG_REQUESTED';
export const SAVE_CONFIG_SUCCESS = 'questsections/SAVE_CONFIG_SUCCESS';
export const SAVE_CONFIG_FAILURE = 'questsections/SAVE_CONFIG_FAILURE';

export const UPDATE_TITLE_FIELD = 'questsections/UPDATE_TITLE_FIELD';
export const UPDATE_SUBTITLE_FIELD = 'questsections/UPDATE_SUBTITLE_FIELD';
export const UPDATE_QUEST_CONFIG = 'questsections/UPDATE_QUEST_CONFIG';
export const UPDATE_QUESTDESIGN_CONFIG = 'questsections/UPDATE_QUESTDESIGN_CONFIG';
export const UPDATE_PROGRESSBAR_DESIGN_CONFIG = 'questsections/UPDATE_PROGRESSBAR_DESIGN_CONFIG';
export const UPDATE_MILESTONE_DESIGN_CONFIG = 'questsections/UPDATE_MILESTONE_DESIGN_CONFIG';
export const UPDATE_TASK_DESIGN_CONFIG = 'questsections/UPDATE_TASK_DESIGN_CONFIG';
export const UPDATE_TASKCTA_DESIGN_CONFIG = 'questsections/UPDATE_TASKCTA_DESIGN_CONFIG';
export const UPDATE_HEADER_CONFIG = 'questsections/UPDATE_HEADER_CONFIG';
export const UPDATE_RESULTHANDLER_CONFIG = 'questsections/UPDATE_RESULTHANDLER_CONFIG';
export const UPDATE_SCHEDULING_CONFIG = 'questsections/UPDATE_SCHEDULING_CONFIG';

export const GET_CONFIG_REQUESTED = 'questsections/GET_CONFIG_REQUESTED';
export const GET_CONFIG_SUCCESS = 'questsections/GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'questsections/GET_CONFIG_FAILURE';
export const UPDATE_FIELD = 'questsections/UPDATE_FIELD';
export const UPDATE_PAGE = 'questsections/UPDATE_PAGE';
export const UPDATE_RESULT_FIELD = 'questsections/UPDATE_RESULT_FIELD';
export const UPDATE_APPCONFIG_FIELD = 'questsections/UPDATE_APPCONFIG_FIELD';

export const UPDATE_LANGUAGE = 'questsections/UPDATE_LANGUAGE';
export const UPDATE_CONFIG_FIELD = 'questsections/UPDATE_CONFIG_FIELD';
export const UPDATE_LOCALIZATION_FLAG = 'questsections/UPDATE_LOCALIZATION_FLAG';
export const UPDATE_ACTIVE_FLAG = 'questsections/UPDATE_ACTIVE_FLAG';
export const CLEAR_STATE = 'questsections/CLEAR_STATE';
export const REMOVE_CONFIG = 'questsections/REMOVE_CONFIG';
export const UPDATE_BUTTON = 'questsections/UPDATE_BUTTON';
// export const UPDATE_COLLAPSE = 'questsections/UPDATE_COLLAPSE';
export const UPDATE_COLLAPSE_CONFIG = 'questsections/UPDATE_COLLAPSE_CONFIG';

export const GET_QUEST_SUCCESS = 'questsections/GET_QUEST_SUCCESS';
export const GET_SEGEMENT_REQUESTED = 'questsections/GET_SEGEMENT_REQUESTED';
export const GET_SEGEMENT_SUCCESS = 'questsections/GET_SEGEMENT_SUCCESS';
export const GET_SEGEMENT_FAILURE = 'questsections/GET_SEGEMENT_FAILURE';

export const UPDATE_BADGES_LIST = 'questsections/UPDATE_BADGES_LIST';
export const UPDATE_REWARDS_LIST = 'questsections/UPDATE_REWARDS_LIST';
export const UPDATE_PROGRESS_BAR_LIST = 'questsections/UPDATE_PROGRESS_BAR_LIST';
export const UPDATE_USERSEGMENT_LIST = 'questsections/UPDATE_USERSEGMENT_LIST';
export const UPDATE_CUSTOMEVENTS_LIST = 'questsections/UPDATE_CUSTOMEVENTS_LIST';
export const UPDATE_ACTIVITY_LIST = 'questsections/UPDATE_ACTIVITY_LIST';
export const UPDATE_LB_LIST = 'questsections/UPDATE_LB_LIST';

export const DUPLICATE_CONFIG_REQUESTED = 'get/DUPLICATE_CONFG_REQUESTED';
export const DUPLICATE_CONFIG_SUCCESS = 'get/DUPLICATE_CONFG_SUCCESS';
export const DUPLICATE_CONFIG_FAILURE = 'get/DUPLICATE_CONFG_FAILURE';

export const UPDATE_CONFIG_REQUESTED = 'update/UPDATE_CONFG_REQUESTED';
export const UPDATE_CONFIG_SUCCESS = 'update/UPDATE_CONFG_SUCCESS';
export const UPDATE_CONFIG_FAILURE = 'update/UPDATE_CONFG_FAILURE';
export const CLEAR_CONFIG = 'questsections/CLEAR_CONFIG';
export const UPDATE_QUEST_LOADER = 'questsections/UPDATE_QUEST_LOADER';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  badgesList: [],
  rewardsList: [],
  userSegmentList: [],
  customEventList: [],
  activityList: [],
  leaderBoardList: [],
  initialValues: {
    // ruleId: '',
    fontTitlePositions: [],
    fonts: [],
    fontStrength: [],
    fontSize: [],
    fontFaceOptions: [
      { title: 'Poppins', value: 'poppins' },
      { title: 'Serif', value: 'Serif' },
      { title: 'Sans-serif', value: 'Sans-serif' },
      { title: 'Monospace', value: 'Monospace' },
      { title: 'Cursive', value: 'Cursive' }
    ],
    fontSizeOptions: [
      { title: '15', value: '15' },
      { title: '20', value: '20' },
      { title: '25', value: '25' },
      { title: '30', value: '30' },
      { title: '35', value: '35' },
      { title: '40', value: '40' }
    ],
    fontTitlePosition: [
      {
        title: 'Left',
        value: 'left',
      },
      {
        title: 'Center',
        value: 'center',
      },
      {
        title: 'Right',
        value: 'right',
      },
    ],
    strength: [
      {
        title: 'Regular',
        value: '400',
      },
      {
        title: 'Bold',
        value: '600',
      },
    ],
    thicknessOptions: [
      {
        title: '1',
        value: '1'
      },
      {
        title: '2',
        value: '2'
      },
      {
        title: '3',
        value: '3'
      },
      {
        title: '4',
        value: '4'
      },
      {
        title: '5',
        value: '5'
      },
    ],
    radiusOptions: [
      {
        title: '0%',
        value: '0%'
      },
      {
        title: '5%',
        value: '5%'
      },
      {
        title: '10%',
        value: '10%'
      },
      {
        title: '20%',
        value: '20%'
      },
      {
        title: '30%',
        value: '30%'
      },
    ],
    shadowOptions: [
      {
        title: '0%',
        value: '0%'
      },
      {
        title: '5%',
        value: '5%'
      },
      {
        title: '10%',
        value: '10%'
      },
      {
        title: '15%',
        value: '15%'
      },
      {
        title: '20%',
        value: '20%'
      },
    ],
    spinButtonWidth: [],
    spinButtonRadius: [],
    spinButtonShadow: [],
    fontPositions: [],
    outlineStyles: [],
  },
  progressBarTypeLibrary: [
    {
      imgUrl: 'https://cdnstg.gamize.com/13/spinTheWheel/15/6945_pro_4.png',
      type: 1
    },
    {
      imgUrl: 'https://cdnstg.gamize.com/13/spinTheWheel/15/9987_pro_1.png',
      type: 2
    },
    {
      imgUrl: 'https://cdnstg.gamize.com/13/spinTheWheel/15/9506_pro_3.png',
      type: 3
    },
    {
      imgUrl: 'https://cdnstg.gamize.com/13/spinTheWheel/15/6599_pro_2.png',
      type: 4
    },
  ],
  // collapse: {
  //   questConfigCont: false,
  //   designConfigCont: false,
  //   questDesignCont: false,
  //   progressBarCont: false,
  //   taskDesignCont: false,
  //   msDesignCont: false,
  //   CTAConfigCont: false,
  //   headerConfigCont: false,
  //   resultHandlerCard: false,
  //   activityCard: false,
  //   schedulingCard: false
  // },
  id: '',
  localizationFlag: '',
  activeNavTab: 'English',
  languages: ['English'],
  config: {
    localizationconfig: [
      {
        collapse: {
          questConfigCont: false,
          designConfigCont: false,
          questDesignCont: false,
          progressBarCont: false,
          taskDesignCont: false,
          msDesignCont: false,
          CTAConfigCont: false,
          headerConfigCont: false,
          resultHandlerCard: false,
          activityCard: false,
          schedulingCard: false
        },
        language: 'English',
        questError: '',
        questConfig: {
          leaderBoardNeeded: true,
          leaderBoardType: 'rewards',
          leaderBoardId: '',
          lbError: '',
          taskFormat: 'unordered',
          milestoneTimer: true,
          taskTimer: true,
        },
        questDesignConfig: {
          questBackgroundRadio: true,
          questBackgroundColor: '#000000',
          questBackgroundImg: '',
          questBackgroundImgName: '',
          questBackgroundImgError: '',

          headerBackgroundRadio: true,
          headerBackgroundColor: '#474D4D',
          headerBackgroundImg: '',
          headerBackgroundImgName: '',
          headerBackgroundImgError: '',

          headerImg: '',
          headerImgName: '',
          headerImgError: '',

          questTimerFontSize: '16',
          questTimerFontColor: '#ffffff',
          timerIconRadio: true,
          timerIconColor: '#ffffff',
          timerIconImg: '',
          timerIconImgName: '',
          timerIconImgError: '',

          lbButtonBackgroundColor: '#000000',
          lbButtonBordercolor: '#ffffff',
          lbButtonThickness: '2px',
          lbButtonRadius: '10px',

          lbIconRadio: true,
          lbIconColor: '#ffffff',
          lbIconImg: '',
          lbIconImgName: '',
          lbIconImgError: '',

          titleFontStyle: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFont,
          titleFontWt: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontWt,
          titleFontSize: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontSize,
          titleFontPosition: 'center',
          titleFontColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#000000' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
          subTitleFontStyle: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFont,
          subTitleFontWt: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFontWt,
          subTitleFontSize: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFontSize,
          subTitleFontPosition: 'center',
          subTitleFontColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#000000' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
          lbButtonFontStyle: 'Outfit, sans-serif',
          lbButtonFontWt: '400',
          lbButtonFontSize: '14',
          lbButtonFontPosition: 'center',
          lbButtonFontColor: '#ffffff',
        },
        progressBarDesignConfig: {
          fontStyle: 'Outfit, sans-serif',
          fontSize: '16',
          fontColor: '#ffffff',
          progressBarDesignType: 1,
          progressBarTitle: '1 of 7 activity completed',
          progressBarDefaultColor: '#ffffff',
          progressBarCompletedColor: '#000000',
        },
        milestoneDesignConfig: {
          defaultCardBackgroundColor: '#000000',
          defaultCardBorderColor: '#ffffff',
          defaultCardThickness: '2px',
          defaultCardRadius: '5px',
          completedCardBackgroundColor: '#000000',
          completedCardBorderColor: '#ffffff',
          completedCardThickness: '2px',
          completedCardRadius: '5px',
          expiredCardBackgroundColor: '#000000',
          expiredCardBorderColor: '#ffffff',
          expiredCardThickness: '2px',
          expiredCardRadius: '5px',

          timerFontStyle: 'Outfit, sans-serif',
          timerFontSize: '16',
          timerFontColor: '#ffffff',
          timerIconRadio: true,
          timerIconColor: '#ffffff',
          timerIconImg: '',
          timerIconImgName: '',
          timerIconImgError: '',

          taskCountFontStyle: 'Outfit, sans-serif',
          taskCountFontSize: '16',
          taskCountFontColor: '#ffffff',
          taskCountIconRadio: true,
          taskCountIconColor: '#ffffff',
          taskCountIconImg: '',
          taskCountIconImgName: '',
          taskCountIconImgError: '',

          dropdownDefaultColor: '#000000',
          dropdownCompletedColor: '#ffffff',

          titleFontStyle: 'Outfit, sans-serif',
          titleFontWt: '400',
          titleFontSize: '14',
          titleFontPosition: 'center',
          titleFontColor: '#ffffff',
          subTitleFontStyle: 'Outfit, sans-serif',
          subTitleFontWt: '400',
          subTitleFontSize: '14',
          subTitleFontPosition: 'center',
          subTitleFontColor: '#ffffff'
        },
        taskDesignConfig: {
          defaultCardBackgroundColor: '#000000',
          defaultCardBorderColor: '#ffffff',
          defaultCardThickness: '2px',
          defaultCardRadius: '5px',
          completedCardBackgroundColor: '#000000',
          completedCardBorderColor: '#ffffff',
          completedCardThickness: '2px',
          completedCardRadius: '5px',
          expiredCardBackgroundColor: '#000000',
          expiredCardBorderColor: '#ffffff',
          expiredCardThickness: '2px',
          expiredCardRadius: '5px',

          cardShadowColor: '#ffffff',

          timerFontStyle: 'Outfit, sans-serif',
          timerFontSize: '16',
          timerFontColor: '#ffffff',
          timerIconRadio: true,
          timerIconColor: '#ffffff',
          timerIconImg: '',
          timerIconImgName: '',
          timerIconImgError: '',

          taskCountFontStyle: 'Outfit, sans-serif',
          taskCountFontSize: '16',
          taskCountFontColor: '#ffffff',
          taskCountIconRadio: true,
          taskCountIconColor: '#ffffff',
          taskCountIconImg: '',
          taskCountIconImgName: '',
          taskCountIconImgError: '',

          titleFontStyle: 'Outfit, sans-serif',
          titleFontWt: '400',
          titleFontSize: '14',
          titleFontPosition: 'center',
          titleFontColor: '#ffffff',
          subTitleFontStyle: 'Outfit, sans-serif',
          subTitleFontWt: '400',
          subTitleFontSize: '14',
          subTitleFontPosition: 'center',
          subTitleFontColor: '#ffffff'
        },
        taskCTADesignConfig: {
          defaultCardBackgroundColor: '#ffffff',
          defaultCardBorderColor: '#000000',
          defaultCardThickness: '2px',
          defaultCardRadius: '5px',
          defaultCardFontColor: '#000000',
          inProgressBackgroundColor: '#ffffff',
          inProgressBorderColor: '#000000',
          inProgressThickness: '2px',
          inProgressRadius: '5px',
          inProgressFontColor: '#000000',
          completedCardBackgroundColor: '#ffffff',
          completedCardBorderColor: '#000000',
          completedCardThickness: '2px',
          completedCardRadius: '5px',
          completedCardFontColor: '#000000',

          textFontStyle: 'Outfit, sans-serif',
          textFontWt: '400',
          textFontSize: '14',
          textFontPosition: 'center',
        },
        activityConfig: [],

        headerConfig: {
          title: {
            text: 'daily mission',
          },
          titleError: '',
          subTitle: {
            text: 'Discover unique challengesawaiting you everyday',
          },
          lbCTAText: {
            text: 'Leaderboard',
          },
          editTextFormat: true,
        },
        resultHandlerConfig: {
          gratificationDisplay: 'SDK',
          taskText: '',
          taskImg: '',
          taskImgName: '',
          taskImgError: '',
          taskWebURL: '',
          taskAppURL: '',
          msText: '',
          msImg: '',
          msImgName: '',
          msImgError: '',
          msWebURL: '',
          msAppURL: '',
          questText: '',
          questImg: '',
          questImgName: '',
          questImgError: '',
          questWebURL: '',
          questAppURL: '',

          winningMsgFontStyle: 'Outfit, sans-serif',
          winningMsgFontWt: '400',
          winningMsgFontSize: '14',
          winningMsgFontColor: '#ffffff',
        },
        schedulingConfig: {
          userSegmentId: 'All User',
          gratifyMode: 'rewards',
          questRewardId: '',
          questBadgeId: '',
          status: 'active',
          startDateTime: getTimezoneDate(),
          endDateTime: getTimezoneDate(),
          dateTimeError: '',
          repeatQuest: 'daily',
          repeatQuestSelected: true,
          customRepeatDays: '',
        },
        timezone: window.localStorage.getItem('defaultTimezone'),
        localTimezoneStr: window.localStorage.getItem('timeZoneString'),
        title: {
          text: '',
          templateName: {
            text: ''
          },
          templateError: '',
        },
      }
    ]
  },
};

const backupConfig = {
  config: {
    localizationconfig: [
      {
        collapse: {
          questConfigCont: false,
          designConfigCont: false,
          questDesignCont: false,
          progressBarCont: false,
          taskDesignCont: false,
          msDesignCont: false,
          CTAConfigCont: false,
          headerConfigCont: false,
          resultHandlerCard: false,
          activityCard: false,
          schedulingCard: false
        },
        language: 'English',
        questError: '',
        questConfig: {
          leaderBoardNeeded: true,
          leaderBoardType: 'rewards',
          leaderBoardId: '',
          taskFormat: 'unordered',
          milestoneTimer: true,
          lbError: '',
          taskTimer: true,
        },
        questDesignConfig: {
          questBackgroundRadio: true,
          questBackgroundColor: '#000000',
          questBackgroundImg: '',
          questBackgroundImgName: '',
          questBackgroundImgError: '',

          headerBackgroundRadio: true,
          headerBackgroundColor: '#474D4D',
          headerBackgroundImg: '',
          headerBackgroundImgName: '',
          headerBackgroundImgError: '',

          headerImg: '',
          headerImgName: '',
          headerImgError: '',

          questTimerFontSize: '16',
          questTimerFontColor: '#ffffff',
          timerIconRadio: true,
          timerIconColor: '#ffffff',
          timerIconImg: '',
          timerIconImgName: '',
          timerIconImgError: '',

          lbButtonBackgroundColor: '#000000',
          lbButtonBordercolor: '#ffffff',
          lbButtonThickness: '2px',
          lbButtonRadius: '10px',

          lbIconRadio: true,
          lbIconColor: '#ffffff',
          lbIconImg: '',
          lbIconImgName: '',
          lbIconImgError: '',

          titleFontStyle: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFont,
          titleFontWt: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '600' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontWt,
          titleFontSize: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '18' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.primaryFontSize,
          titleFontPosition: 'center',
          titleFontColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#000000' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
          subTitleFontStyle: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? 'Outfit, sans-serif' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFont,
          subTitleFontWt: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '400' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFontWt,
          subTitleFontSize: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '14' : JSON.parse(window.localStorage.getItem('orgData')).defaultFonts.secondaryFontSize,
          subTitleFontPosition: 'center',
          subTitleFontColor: (window.localStorage.getItem('orgData') === null || window.localStorage.getItem('orgData') === 'undefined') ? '#000000' : JSON.parse(window.localStorage.getItem('orgData')).defaultColours.fontColour,
          lbButtonFontStyle: 'Outfit, sans-serif',
          lbButtonFontWt: '400',
          lbButtonFontSize: '14',
          lbButtonFontPosition: 'center',
          lbButtonFontColor: '#ffffff',
        },
        progressBarDesignConfig: {
          fontStyle: 'Outfit, sans-serif',
          fontSize: '16',
          fontColor: '#ffffff',
          progressBarTitle: '1 of 7 activity completed',
          progressBarDesignType: 1,
          progressBarDefaultColor: '#ffffff',
          progressBarCompletedColor: '#000000',
        },
        milestoneDesignConfig: {
          defaultCardBackgroundColor: '#000000',
          defaultCardBorderColor: '#ffffff',
          defaultCardThickness: '2px',
          defaultCardRadius: '5px',
          completedCardBackgroundColor: '#000000',
          completedCardBorderColor: '#ffffff',
          completedCardThickness: '5px',
          completedCardRadius: '2px',
          expiredCardBackgroundColor: '#000000',
          expiredCardBorderColor: '#ffffff',
          expiredCardThickness: '2px',
          expiredCardRadius: '5px',

          timerFontStyle: 'Outfit, sans-serif',
          timerFontSize: '16',
          timerFontColor: '#ffffff',
          timerIconRadio: true,
          timerIconColor: '#ffffff',
          timerIconImg: '',
          timerIconImgName: '',
          timerIconImgError: '',

          taskCountFontStyle: 'Outfit, sans-serif',
          taskCountFontSize: '16',
          taskCountFontColor: '#ffffff',
          taskCountIconRadio: true,
          taskCountIconColor: '#ffffff',
          taskCountIconImg: '',
          taskCountIconImgName: '',
          taskCountIconImgError: '',

          dropdownDefaultColor: '#000000',
          dropdownCompletedColor: '#ffffff',

          titleFontStyle: 'Outfit, sans-serif',
          titleFontWt: '400',
          titleFontSize: '14',
          titleFontPosition: 'center',
          titleFontColor: '#ffffff',
          subTitleFontStyle: 'Outfit, sans-serif',
          subTitleFontWt: '400',
          subTitleFontSize: '14',
          subTitleFontPosition: 'center',
          subTitleFontColor: '#ffffff'
        },
        taskDesignConfig: {
          defaultCardBackgroundColor: '#000000',
          defaultCardBorderColor: '#ffffff',
          defaultCardThickness: '2px',
          defaultCardRadius: '5px',
          completedCardBackgroundColor: '#000000',
          completedCardBorderColor: '#ffffff',
          completedCardThickness: '2px',
          completedCardRadius: '5px',
          expiredCardBackgroundColor: '#000000',
          expiredCardBorderColor: '#ffffff',
          expiredCardThickness: '2px',
          expiredCardRadius: '5px',

          cardShadowColor: '#ffffff',

          timerFontStyle: 'Outfit, sans-serif',
          timerFontSize: '16',
          timerFontColor: '#ffffff',
          timerIconRadio: true,
          timerIconColor: '#ffffff',
          timerIconImg: '',
          timerIconImgName: '',
          timerIconImgError: '',

          taskCountFontStyle: 'Outfit, sans-serif',
          taskCountFontSize: '16',
          taskCountFontColor: '#ffffff',
          taskCountIconRadio: true,
          taskCountIconColor: '#ffffff',
          taskCountIconImg: '',
          taskCountIconImgName: '',
          taskCountIconImgError: '',

          titleFontStyle: 'Outfit, sans-serif',
          titleFontWt: '400',
          titleFontSize: '14',
          titleFontPosition: 'center',
          titleFontColor: '#ffffff',
          subTitleFontStyle: 'Outfit, sans-serif',
          subTitleFontWt: '400',
          subTitleFontSize: '14',
          subTitleFontPosition: 'center',
          subTitleFontColor: '#ffffff'
        },
        taskCTADesignConfig: {
          defaultCardBackgroundColor: '#ffffff',
          defaultCardBorderColor: '#000000',
          defaultCardThickness: '2px',
          defaultCardRadius: '5px',
          defaultCardFontColor: '#000000',
          inProgressBackgroundColor: '#ffffff',
          inProgressBorderColor: '#000000',
          inProgressThickness: '2px',
          inProgressRadius: '5px',
          inProgressFontColor: '#000000',
          completedCardBackgroundColor: '#ffffff',
          completedCardBorderColor: '#000000',
          completedCardThickness: '2px',
          completedCardRadius: '5px',
          completedCardFontColor: '#000000',

          textFontStyle: 'Outfit, sans-serif',
          textFontWt: '400',
          textFontSize: '14',
          textFontPosition: 'center',
        },
        activityConfig: [],

        headerConfig: {
          title: {
            text: 'daily mission',
          },
          titleError: '',
          subTitle: {
            text: 'Discover unique challenges awaiting you everyday',
          },
          lbCTAText: {
            text: 'Leaderboard',
          },
          editTextFormat: true,
        },
        resultHandlerConfig: {
          gratificationDisplay: 'SDK',
          taskText: '',
          taskImg: '',
          taskImgName: '',
          taskImgError: '',
          taskWebURL: '',
          taskAppURL: '',
          msText: '',
          msImg: '',
          msImgName: '',
          msImgError: '',
          msWebURL: '',
          msAppURL: '',
          questText: '',
          questImg: '',
          questImgName: '',
          questImgError: '',
          questWebURL: '',
          questAppURL: '',

          winningMsgFontStyle: 'Outfit, sans-serif',
          winningMsgFontWt: '400',
          winningMsgFontSize: '14',
          winningMsgFontColor: '#ffffff',
        },
        schedulingConfig: {
          userSegmentId: 'All User',
          gratifyMode: 'rewards',
          questRewardId: '',
          questBadgeId: '',
          status: 'active',
          startDateTime: getTimezoneDate(),
          endDateTime: getTimezoneDate(),
          dateTimeError: '',
          repeatQuest: 'daily',
          repeatQuestSelected: true,
          customRepeatDays: '',
        },
        timezone: window.localStorage.getItem('defaultTimezone'),
        localTimezoneStr: window.localStorage.getItem('timeZoneString'),
        title: {
          text: '',
          templateName: {
            text: ''
          },
          templateError: '',
        },
      }
    ]
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_COLLAPSE_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].collapse = state.config.localizationconfig[i].collapse
            ? {
              ...state.config.localizationconfig[i].collapse,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_TITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].title = state.config.localizationconfig[i].title
            ? {
              ...state.config.localizationconfig[i].title,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state, ...state.config };
    }
    case UPDATE_SUBTITLE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].subtitle = state.config.localizationconfig[i].subtitle
            ? {
              ...state.config.localizationconfig[i].subtitle,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_QUEST_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].questConfig = state.config.localizationconfig[i].questConfig
            ? {
              ...state.config.localizationconfig[i].questConfig,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_QUESTDESIGN_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].questDesignConfig = state.config.localizationconfig[i].questDesignConfig
            ? {
              ...state.config.localizationconfig[i].questDesignConfig,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_PROGRESSBAR_DESIGN_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].progressBarDesignConfig = state.config.localizationconfig[i].progressBarDesignConfig
            ? {
              ...state.config.localizationconfig[i].progressBarDesignConfig,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_MILESTONE_DESIGN_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].milestoneDesignConfig = state.config.localizationconfig[i].milestoneDesignConfig
            ? {
              ...state.config.localizationconfig[i].milestoneDesignConfig,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_TASK_DESIGN_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].taskDesignConfig = state.config.localizationconfig[i].taskDesignConfig
            ? {
              ...state.config.localizationconfig[i].taskDesignConfig,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_TASKCTA_DESIGN_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].taskCTADesignConfig = state.config.localizationconfig[i].taskCTADesignConfig
            ? {
              ...state.config.localizationconfig[i].taskCTADesignConfig,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }

    case UPDATE_HEADER_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].headerConfig = state.config.localizationconfig[i].headerConfig
            ? {
              ...state.config.localizationconfig[i].headerConfig,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_RESULTHANDLER_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].resultHandlerConfig = state.config.localizationconfig[i].resultHandlerConfig
            ? {
              ...state.config.localizationconfig[i].resultHandlerConfig,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_SCHEDULING_CONFIG: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].schedulingConfig = state.config.localizationconfig[i].schedulingConfig
            ? {
              ...state.config.localizationconfig[i].schedulingConfig,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case GET_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_QUEST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_SEGEMENT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SEGEMENT_SUCCESS: {
      const segement = action.result.segmentList;
      const segementOptions = [];
      for (let i = 0; i < segement.length; i += 1) {
        segementOptions.push({ title: segement[i].title, value: segement[i].value });
      }
      segementOptions.push({ title: 'All User', value: 'All User' });
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        options: { ...state.options, ...{ userSegment: segementOptions } },
      };
    }
    case GET_SEGEMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DUPLICATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DUPLICATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DUPLICATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_PAGE: {
      const initialData = { ...state.initialData, ...action.payload };
      return { ...state, initialData };
    }
    case UPDATE_RESULT_FIELD: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i].resultText = state.config.localizationconfig[i].resultText
            ? {
              ...state.config.localizationconfig[i].resultText,
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    case UPDATE_APPCONFIG_FIELD: {
      state.initialValues = state.initialValues
        ? {
          ...state.initialValues,
          ...action.payload,
        }
        : { ...action.payload };
      return { ...state };
    }
    case UPDATE_LOCALIZATION_FLAG: {
      return { ...state, localizationFlag: action.payload };
    }
    case UPDATE_ACTIVE_FLAG: {
      return { ...state, activeNavTab: action.payload };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    case REMOVE_CONFIG: {
      return { ...state, config: action.payload };
    }
    case UPDATE_LANGUAGE: {
      return { ...state, languages: action.payload };
    }
    case UPDATE_CONFIG_FIELD: {
      const localizationconfig = [...state.config.localizationconfig, ...action.payload.localizationconfig];
      const config = {
        ...state.config,
        localizationconfig
      };
      return { ...state, config };
    }
    case UPDATE_BUTTON: {
      for (let i = 0; i < state.config.localizationconfig.length; i += 1) {
        if (state.config.localizationconfig[i].language === action.payload.language) {
          state.config.localizationconfig[i] = state.config.localizationconfig[i]
            ? {
              ...state.config.localizationconfig[i],
              ...action.payload,
            }
            : { ...action.payload };
        }
      }
      return { ...state };
    }
    // case UPDATE_COLLAPSE: {
    //   state.collapse = state.collapse
    //     ? {
    //       ...state.collapse,
    //       ...action.payload,
    //     }
    //     : { ...action.payload };
    //   return { ...state };
    // }
    case UPDATE_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }

    case UPDATE_BADGES_LIST: {
      return { ...state, badgesList: action.payload };
    }
    case UPDATE_REWARDS_LIST: {
      return { ...state, rewardsList: action.payload };
    }
    case UPDATE_USERSEGMENT_LIST: {
      return { ...state, userSegmentList: action.payload };
    }
    case UPDATE_CUSTOMEVENTS_LIST: {
      return { ...state, customEventList: action.payload };
    }
    case UPDATE_ACTIVITY_LIST: {
      return { ...state, activityList: action.payload };
    }
    case UPDATE_LB_LIST: {
      return { ...state, leaderBoardList: action.payload };
    }
    case CLEAR_CONFIG: {
      return {
        ...state,
        config: {
          ...state.config,
          localizationconfig: JSON.parse(JSON.stringify(backupConfig.config.localizationconfig))
        }
      };
    }
    case UPDATE_QUEST_LOADER: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
};


export const updateTitle = (payload) => {
  return {
    type: UPDATE_TITLE_FIELD,
    payload,
  };
};

export const updateSubTitle = (payload) => {
  return {
    type: UPDATE_SUBTITLE_FIELD,
    payload,
  };
};
export const updateQuestConfig = (payload) => {
  return {
    type: UPDATE_QUEST_CONFIG,
    payload,
  };
};
export const updateQuestDesignConfig = (payload) => {
  return {
    type: UPDATE_QUESTDESIGN_CONFIG,
    payload,
  };
};
export const updateProgressBarDesignConfig = (payload) => {
  return {
    type: UPDATE_PROGRESSBAR_DESIGN_CONFIG,
    payload,
  };
};
export const updateMilestoneDesignConfig = (payload) => {
  return {
    type: UPDATE_MILESTONE_DESIGN_CONFIG,
    payload,
  };
};
export const updateTaskDesignConfig = (payload) => {
  return {
    type: UPDATE_TASK_DESIGN_CONFIG,
    payload,
  };
};
export const updateTaskCTADesignConfig = (payload) => {
  return {
    type: UPDATE_TASKCTA_DESIGN_CONFIG,
    payload,
  };
};
export const updateHeaderConfig = (payload) => {
  return {
    type: UPDATE_HEADER_CONFIG,
    payload,
  };
};
export const updateResultHandlerConfig = (payload) => {
  return {
    type: UPDATE_RESULTHANDLER_CONFIG,
    payload,
  };
};
export const updateSchedulingConfig = (payload) => {
  return {
    type: UPDATE_SCHEDULING_CONFIG,
    payload,
  };
};

export const updateFieldConfig = (payload) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const updatePage = (payload) => {
  return {
    type: UPDATE_PAGE,
    payload,
  };
};


// export const saveGamesConfig = (data) => {
//   return {
//     types: [SAVE_CONFIG_REQUESTED, SAVE_CONFIG_SUCCESS, SAVE_CONFIG_FAILURE],
//     promise: client => client.post('saveGamesConfig', {
//       data,
//     }),
//   };
// };

export const getTemplate = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getGameTemplateById?id=${data}`),
  };
};

// export const getGames = (data) => {
//   return {
//     types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
//     promise: client => client.get(`getGamesConfig?id=${data}`),
//   };
// };

// export const editGamesConfig = (data, gamificationId) => {
//   return {
//     types: [SAVE_CONFIG_REQUESTED, SAVE_CONFIG_SUCCESS, SAVE_CONFIG_FAILURE],
//     promise: client => client.post(`saveGamesConfig?gameId=${gamificationId}`, {
//       data,
//     }),
//   };
// };

// export const checkGamesName = (data, id) => {
//   return {
//     types: [GET_DUPLICATE_REQUESTED, GET_DUPLICATE_SUCCESS, GET_DUPLICATE_FAILURE],
//     promise: client => client.get(`checkInfoScreenName?name=${data}&id=${id}`),
//   };
// };

export const updateResult = (payload) => {
  return {
    type: UPDATE_RESULT_FIELD,
    payload,
  };
};

export const uploadimage = (file, folderName) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_IMAGE_REQUESTED, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_GAMIFICATION_HOST_NAME}/gamification/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
      .field('folderName', folderName),
  };
};

export const getAppConfig = () => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get('getAppConfig'),
  };
};

export const updateDropdown = (payload) => {
  return {
    type: UPDATE_APPCONFIG_FIELD,
    payload,
  };
};

export const updateLocalizationFlag = (payload) => {
  return {
    type: UPDATE_LOCALIZATION_FLAG,
    payload,
  };
};


export const updateActiveFlag = (payload) => {
  console.log('inside reducer activeFlag', payload);
  return {
    type: UPDATE_ACTIVE_FLAG,
    payload,
  };
};

export const removeConfig = (payload) => {
  return {
    type: REMOVE_CONFIG,
    payload
  };
};

export const updateLanguage = (payload) => {
  return {
    type: UPDATE_LANGUAGE,
    payload
  };
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};

export const updateConfig = (payload) => {
  return {
    type: UPDATE_CONFIG_FIELD,
    payload
  };
};

export const updateButton = (payload) => {
  return {
    type: UPDATE_BUTTON,
    payload
  };
};

export const saveCustomEventConfig = (data) => {
  return {
    types: [SAVE_CONFIG_REQUESTED, SAVE_CONFIG_SUCCESS, SAVE_CONFIG_FAILURE],
    promise: client => client.post('saveInfoScreenPageConfig', {
      data,
    }),
  };
};

export const getCustomEventConfig = (id) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getInfoScreenPageConfig?pageId=${id}`),
  };
};

export const updateCollapse = (payload) => {
  return {
    type: UPDATE_COLLAPSE_CONFIG,
    payload
  };
};

// NEW
export const getRewardsTemplate = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(
      `getMyOffersList/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&sortType=${data.orderBy
      }`
    ),
  };
};

export const updateRewardsList = (payload) => {
  return {
    type: UPDATE_REWARDS_LIST,
    payload,
  };
};

export const getBadgesTemplate = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(
      `getGameMechanicsConfig/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&sortType=${data.orderBy
      }`
    ),
  };
};

export const updateBadgesList = (payload) => {
  return {
    type: UPDATE_BADGES_LIST,
    payload,
  };
};

export const getUserSegment = () => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_SEGEMENT_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(
      'getSegmentList'
    ),
  };
};

export const updateUserSegmentList = (payload) => {
  return {
    type: UPDATE_USERSEGMENT_LIST,
    payload,
  };
};

export const getCustomEvents = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(
      `getCustomEventsConfig/${data.itemsPerPage}/${data.pageNo}?title=${data.searchKey}&sortBy=${data.sortBy}&orderBy=${data.orderBy
      }`
    ),
  };
};

export const updateCustomEventsList = (payload) => {
  return {
    type: UPDATE_CUSTOMEVENTS_LIST,
    payload,
  };
};

export const getRuleEngineList = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getRuleEngineList/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.title}&sortBy=${data.sortBy}&sortType=${data.orderBy}`)
  };
};

export const updateActivityList = (payload) => {
  return {
    type: UPDATE_ACTIVITY_LIST,
    payload,
  };
};

export const getLeaderBoard = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(
      `getMyLeaderBoardList/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&sortBy=${data.sortBy}&sortType=${data.orderBy
      }`
    ),
  };
};

export const updateLeaderBoardList = (payload) => {
  return {
    type: UPDATE_LB_LIST,
    payload,
  };
};

export const getQuest = (data) => {
  return {
    types: [GET_CONFIG_REQUESTED, GET_QUEST_SUCCESS, GET_CONFIG_FAILURE],
    promise: client => client.get(`getRule?ruleId=${data}`),
  };
};

export const checkRuleName = (data, type, id) => {
  return {
    types: [DUPLICATE_CONFIG_REQUESTED, DUPLICATE_CONFIG_SUCCESS, DUPLICATE_CONFIG_FAILURE],
    promise: client => client.get(`checkRuleName?name=${data}&id=${id}`),
  };
};

export const saveQuestConfig = (data) => {
  return {
    types: [SAVE_CONFIG_REQUESTED, SAVE_CONFIG_SUCCESS, SAVE_CONFIG_FAILURE],
    promise: client => client.post('saveQuestConfig', {
      data,
    }),
  };
};

export const editQuestConfig = (data) => {
  return {
    types: [UPDATE_CONFIG_REQUESTED, UPDATE_CONFIG_SUCCESS, UPDATE_CONFIG_FAILURE],
    promise: client => client.post('saveQuestConfig', {
      data,
    }),
  };
};
export const clearLocalizationConfig = () => {
  return {
    type: CLEAR_CONFIG,
  };
};
export const updateLoader = (payload) => {
  return {
    type: UPDATE_QUEST_LOADER,
    payload,
  };
};
